<template>
    <div class="indexMain">
        <el-alert v-if="show" style="z-index: 999;height: 40px;"
                  title="网络异常已中断"
                  type="warning"
                  center
                  :closable="false">
        </el-alert>
        <div id="index" ref="appRef">
            <div class="bg">
                <dv-loading v-if="loading">Loading...</dv-loading>
                <div v-else class="host-body">
                    <div class="d-flex jc-center">
                        <dv-decoration-10 class="dv-dec-10"/>
                        <div class="d-flex jc-center">
                            <dv-decoration-8 class="dv-dec-8" :color="['#568aea', '#568aea']"/>
                            <div class="title">
                                <dv-decoration-11 class="title-text" style="height: 70px">智慧停车监控中心</dv-decoration-11>
                                <dv-decoration-6
                                        class="dv-dec-6 dv-decor-6"
                                        :reverse="true"
                                        :color="['#50e3c2', '#67a1e5']"
                                />
                            </div>
                            <dv-decoration-8
                                    class="dv-dec-8"
                                    :reverse="true"
                                    :color="['#568aea', '#568aea']"
                            />
                        </div>
                        <dv-decoration-10 class="dv-dec-10-s"/>
                    </div>

                    <!-- 第二行 -->
                    <div class="d-flex jc-between px-2">
                        <div class="d-flex aside-width">
                            <div class="react-left react-l-s">
                                <span class="react-before"></span>
                                <router-link :to="{path:'/parkingLot/homePage'}"><span class="text">返回</span>
                                </router-link>
                            </div>
                            <div class="react-left ml-33" style="cursor: pointer;">
                                <span class="text" @click="handleFullScreen">全屏切换</span>
                            </div>
                        </div>
                        <div class="d-flex d-flex1 aside-width">
                            <!--                            <div class="react-right mr-4 react-mls">-->
                            <!--                                <span class="react-after"></span>-->
                            <!--                                <span class="text">{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span>-->
                            <!--                            </div>-->
                            <div class="react-right react-l-s">
                                <span class="react-after"></span>
                                <span class="text"
                                >{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span
                                >
                            </div>
                        </div>
                    </div>

                    <div class="body-box">
                        <!-- 第三行数据 -->
                        <div class="content-box">
                            <dv-border-box-11 title="进出车辆统计" class="boderbox fontcolor">
                                <dv-border-box-8 class="box">
                                    <div class="operate">
                                        <div>
                                            <span>{{entrance ? entrance : 0}}</span>
                                            <p>入场车辆</p>
                                        </div>
                                        <div>
                                            <span>{{appearance ? appearance : 0}}</span>
                                            <p>离场车辆</p>
                                        </div>
                                        <div>
                                            <span>{{presentNum ? presentNum : 0}}</span>
                                            <p>在场车辆</p>
                                        </div>
                                        <div class="operate-div">
                                            <span>{{remainder ? remainder : 0}}</span>
                                            <p>车场余位</p>
                                        </div>
                                    </div>
                                </dv-border-box-8>
                            </dv-border-box-11>
                            <dv-border-box-11 title="入场车辆" class="boderbox1">
                                <div class="div-box-11">
                                    <!--                                    v-for="(item, index) in arrlistresult1" :key="index"-->
                                    <el-card :body-style="{ padding: '0px', width: '180px', height: '260px', }">
                                        <div class="div-img">
                                            <div class="div-img">
                                                <el-image v-if="arrlistresult1 === undefined" class="image1"
                                                          :src="arrlistresult1?arrlistresult1.passImg:require('../assets/images/car.png')"
                                                          :preview-src-list="[arrlistresult1?arrlistresult1.passImg:require('../assets/images/car.png')]">
                                                </el-image>
                                                <el-image v-else-if="arrlistresult1 != undefined"
                                                          :class="arrlistresult1.passImg?'image':'image1'"
                                                          :src="arrlistresult1.passImg?arrlistresult1.passImg:require('../assets/images/car.png')"
                                                          :preview-src-list="[arrlistresult1.passImg?arrlistresult1.passImg:require('../assets/images/car.png')]">
                                                </el-image>
                                            </div>
                                        </div>
                                        <div class="dv-box-info" v-if="arrlistresult1 != undefined">
                                            <span>{{arrlistresult1.passTime?arrlistresult1.passTime.substring(5, 20):''}}</span>
                                            <span>{{arrlistresult1.parkingType == 1 ? "临停车" : arrlistresult1.parkingType == 2 ? "长租车" : arrlistresult1.parkingType == 3 ? "会员车"
                                                : arrlistresult1.parkingType == 4 || arrlistresult1.parkingType == 8 ? "长租过期车" : arrlistresult1.parkingType == 5 ? "长租超员车" : arrlistresult1.parkingType == 6 ? "储值车" : arrlistresult1.parkingType == 7 ? "预约车" : ""}}</span>
                                            <span>{{arrlistresult1?arrlistresult1.licensePlate:'车牌号'}}</span>
                                            <span>{{arrlistresult1?arrlistresult1.roadName:'道口名称'}}</span>
                                        </div>
                                        <div style="color: #8a8a8a" class="dv-box-info"
                                             v-else-if="arrlistresult1 === undefined">
                                            <span>日期 时间</span>
                                            <span>车类型</span>
                                            <span>车牌号</span>
                                            <span>道口名称</span>
                                        </div>
                                    </el-card>
                                    <el-card :body-style="{ padding: '0px', width: '180px', height: '260px', }">
                                        <div class="div-img">
                                            <el-image :class="arrlistresult2.passImg?'image':'image1'"
                                                      v-if="arrlistresult2 != undefined"
                                                      :src="arrlistresult2.passImg?arrlistresult2.passImg:require('../assets/images/car.png')"
                                                      :preview-src-list="[arrlistresult2.passImg?arrlistresult2.passImg:require('../assets/images/car.png')]">
                                            </el-image>
                                            <el-image class="image1" v-else-if="arrlistresult2 === undefined"
                                                      :src="arrlistresult2?arrlistresult2.passImg:require('../assets/images/car.png')"
                                                      :preview-src-list="[arrlistresult2?arrlistresult2.passImg:require('../assets/images/car.png')]">
                                            </el-image>
                                        </div>
                                        <div class="dv-box-info" v-if="arrlistresult2 != undefined">
                                            <span>{{arrlistresult2.passTime?arrlistresult2.passTime.substring(5, 20):''}}</span>
                                            <span>{{arrlistresult2.parkingType == 1 ? "临停车" : arrlistresult2.parkingType == 2 ? "长租车" : arrlistresult2.parkingType == 3 ? "会员车"
                                                : arrlistresult2.parkingType == 4 || arrlistresult2.parkingType == 8 ? "长租过期车" : arrlistresult2.parkingType == 5 ? "长租超员车" : arrlistresult2.parkingType == 6 ? "储值车" : arrlistresult2.parkingType == 7 ? "预约车" : ""}}</span>
                                            <span>{{arrlistresult2.licensePlate}}</span>
                                            <span>{{arrlistresult2.roadName}}</span>
                                        </div>
                                        <div style="color: #8a8a8a" class="dv-box-info"
                                             v-else-if="arrlistresult2 === undefined">
                                            <span>日期 时间</span>
                                            <span>车类型</span>
                                            <span>车牌号</span>
                                            <span>道口名称</span>
                                        </div>
                                    </el-card>
                                    <el-card :body-style="{ padding: '0px', width: '180px', height: '260px', }">
                                        <div class="div-img">
                                            <el-image :class="arrlistresult3.passImg?'image':'image1'"
                                                      v-if="arrlistresult3 != undefined"
                                                      :src="arrlistresult3.passImg?arrlistresult3.passImg:require('../assets/images/car.png')"
                                                      :preview-src-list="[arrlistresult3.passImg?arrlistresult3.passImg:require('../assets/images/car.png')]">
                                            </el-image>
                                            <el-image class="image1" v-else-if="arrlistresult3 === undefined"
                                                      :src="arrlistresult3?arrlistresult3.passImg:require('../assets/images/car.png')"
                                                      :preview-src-list="[arrlistresult3?arrlistresult3.passImg:require('../assets/images/car.png')]">
                                            </el-image>
                                        </div>
                                        <div class="dv-box-info" v-if="arrlistresult3 != undefined">
                                            <span>{{arrlistresult3.passTime?arrlistresult3.passTime.substring(5, 20):''}}</span>
                                            <span>{{arrlistresult3.parkingType == 1 ? "临停车" : arrlistresult3.parkingType == 2 ? "长租车" : arrlistresult3.parkingType == 3 ? "会员车"
                                                : arrlistresult3.parkingType == 4 || arrlistresult3.parkingType == 8 ? "长租过期车" : arrlistresult3.parkingType == 5 ? "长租超员车" : arrlistresult3.parkingType == 6 ? "储值车" : arrlistresult3.parkingType == 7 ? "预约车" : ""}}</span>
                                            <span>{{arrlistresult3.licensePlate}}</span>
                                            <span>{{arrlistresult3.roadName}}</span>
                                        </div>
                                        <div style="color: #8a8a8a" class="dv-box-info"
                                             v-else-if="arrlistresult3 === undefined">
                                            <span>日期 时间</span>
                                            <span>车类型</span>
                                            <span>车牌号</span>
                                            <span>道口名称</span>
                                        </div>
                                    </el-card>
                                </div>
                            </dv-border-box-11>
                            <dv-border-box-11 title="出场车辆" class="boderbox1">
                                <!--列表-->
                                <div class="div-box-exit11">
                                    <!--                                    <div class="div-exit-info" v-for="(item, index) in exitlistresult" :key="index">-->
                                    <!--                                    </div>-->
                                    <el-card :body-style="{ padding: '0px', width: '180px', height: '260px', }">
                                        <div class="div-img">
                                            <el-image v-if="exitlistresult1 === undefined" class="image1"
                                                      :src="exitlistresult1?exitlistresult1.passImg:require('../assets/images/car.png')"
                                                      :preview-src-list="[exitlistresult1?exitlistresult1.passImg:require('../assets/images/car.png')]">
                                            </el-image>
                                            <el-image v-else-if="exitlistresult1 != undefined"
                                                      :class="exitlistresult1.passImg?'image':'image1'"
                                                      :src="exitlistresult1.passImg?exitlistresult1.passImg:require('../assets/images/car.png')"
                                                      :preview-src-list="[exitlistresult1.passImg?exitlistresult1.passImg:require('../assets/images/car.png')]">
                                            </el-image>
                                        </div>
                                        <div class="dv-box-info" v-if="exitlistresult1 != undefined">
                                            <span>{{exitlistresult1.passTime?exitlistresult1.passTime.substring(5, 20):''}}</span>
                                            <span>{{exitlistresult1.parkingType == 1 ? "临停车" : exitlistresult1.parkingType == 2 ? "长租车" : exitlistresult1.parkingType == 3 ? "会员车"
                                                : exitlistresult1.parkingType == 4 || exitlistresult1.parkingType == 8 ? "长租过期车" : exitlistresult1.parkingType == 5 ? "长租超员车" : exitlistresult1.parkingType == 6 ? "储值车" : exitlistresult1.parkingType == 7 ? "预约车" : ""}}</span>
                                            <span>{{exitlistresult1.licensePlate}}</span>
                                            <span>{{exitlistresult1.roadName}}</span>
                                        </div>
                                        <div style="color: #8a8a8a" class="dv-box-info"
                                             v-else-if="exitlistresult1 === undefined">
                                            <span>日期 时间</span>
                                            <span>车类型</span>
                                            <span>车牌号</span>
                                            <span>道口名称</span>
                                        </div>
                                    </el-card>
                                    <el-card :body-style="{ padding: '0px', width: '180px', height: '260px', }">
                                        <div class="div-img">
                                            <el-image v-if="exitlistresult2 === undefined" class="image1"
                                                      :src="exitlistresult2?exitlistresult2.passImg:require('../assets/images/car.png')"
                                                      :preview-src-list="[exitlistresult2?exitlistresult2.passImg:require('../assets/images/car.png')]">
                                            </el-image>
                                            <el-image v-else-if="exitlistresult2 != undefined"
                                                      :class="exitlistresult2.passImg?'image':'image1'"
                                                      :src="exitlistresult2.passImg?exitlistresult2.passImg:require('../assets/images/car.png')"
                                                      :preview-src-list="[exitlistresult2.passImg?exitlistresult2.passImg:require('../assets/images/car.png')]">
                                            </el-image>
                                        </div>
                                        <div class="dv-box-info" v-if="exitlistresult2 != undefined">
                                            <span>{{exitlistresult2.passTime?exitlistresult2.passTime.substring(5, 20):''}}</span>
                                            <span>{{exitlistresult2.parkingType == 1 ? "临停车" : exitlistresult2.parkingType == 2 ? "长租车" : exitlistresult2.parkingType == 3 ? "会员车"
                                                : exitlistresult2.parkingType == 4 || exitlistresult2.parkingType == 8 ? "长租过期车" : exitlistresult2.parkingType == 5 ? "长租超员车" : exitlistresult2.parkingType == 6 ? "储值车" : exitlistresult2.parkingType == 7 ? "预约车" : ""}}</span>
                                            <span>{{exitlistresult2.licensePlate}}</span>
                                            <span>{{exitlistresult2.roadName}}</span>
                                        </div>
                                        <div style="color: #8a8a8a" class="dv-box-info"
                                             v-else-if="exitlistresult2 === undefined">
                                            <span>日期 时间</span>
                                            <span>车类型</span>
                                            <span>车牌号</span>
                                            <span>道口名称</span>
                                        </div>
                                    </el-card>
                                    <el-card :body-style="{ padding: '0px', width: '180px', height: '260px', }">
                                        <div class="div-img">
                                            <el-image v-if="exitlistresult3 === undefined" class="image1"
                                                      :src="exitlistresult3?exitlistresult3.passImg:require('../assets/images/car.png')"
                                                      :preview-src-list="[exitlistresult3?exitlistresult3.passImg:require('../assets/images/car.png')]">
                                            </el-image>
                                            <el-image v-if="exitlistresult3 != undefined"
                                                      :class="exitlistresult3.passImg?'image':'image1'"
                                                      :src="exitlistresult3.passImg?exitlistresult3.passImg:require('../assets/images/car.png')"
                                                      :preview-src-list="[exitlistresult3.passImg?exitlistresult3.passImg:require('../assets/images/car.png')]">
                                            </el-image>
                                        </div>
                                        <div class="dv-box-info" v-if="exitlistresult3 != undefined">
                                            <span>{{exitlistresult3.passTime?exitlistresult3.passTime.substring(5, 20):''}}</span>
                                            <span>{{exitlistresult3.parkingType == 1 ? "临停车" : exitlistresult3.parkingType == 2 ? "长租车" : exitlistresult3.parkingType == 3 ? "会员车"
                                                : exitlistresult3.parkingType == 4 || exitlistresult3.parkingType == 8 ? "长租过期车" : exitlistresult3.parkingType == 5 ? "长租超员车" : exitlistresult3.parkingType == 6 ? "储值车" : exitlistresult3.parkingType == 7 ? "预约车" : ""}}</span>
                                            <span>{{exitlistresult3.licensePlate}}</span>
                                            <span>{{exitlistresult3.roadName}}</span>
                                        </div>
                                        <div style="color: #8a8a8a" class="dv-box-info"
                                             v-else-if="exitlistresult3 === undefined">
                                            <span>日期 时间</span>
                                            <span>车类型</span>
                                            <span>车牌号</span>
                                            <span>道口名称</span>
                                        </div>
                                    </el-card>
                                </div>
                            </dv-border-box-11>
                        </div>
                        <div class="content-box">
                            <!-- 中间 -->
                            <dv-border-box-13 class="boderbox">
                                <div class="content">
                                    <div class="el-tit">
                                        <!--                  <span class="el-icon-help"></span>-->
                                        <span>车场总收入</span>
                                        <span style="float: right">单位(元)</span>
                                    </div>
                                    <div class="item-box">
                                        <div v-for="(item,index) in shadow" :key="index">
                                            <span>{{item}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="content-charts">
                                    <div style="width: 100%;margin-bottom: 10px">
                                        <div style="width: 85%;float: left">
                                            <div style="font-size: 17px;float: left">电子支付占比</div>
                                            <div style="float: right;width: 78%;margin-top: 6px;">
                                                <el-progress :stroke-width="20" :text-inside="true"
                                                             :percentage="incomeData.totalPay ? parseFloat((incomeData.onlinePay / incomeData.totalPay*100).toFixed(2)) : 0">
                                                </el-progress>
                                            </div>
                                        </div>
                                        <div class="yuan">
                                            <p>{{incomeData.onlinePay ? incomeData.onlinePay : 0}} 元</p>
                                        </div>
                                    </div>
                                    <div style="width: 100%;margin-bottom: 10px">
                                        <div style="width: 85%;float: left">
                                            <div style="font-size: 17px;float: left">现金支付占比</div>
                                            <div style="float: right;width: 78%;margin-top: 6px;">
                                                <el-progress :stroke-width="20" :text-inside="true"
                                                             :percentage="incomeData.totalPay ? parseFloat((incomeData.offlinePay / incomeData.totalPay*100).toFixed(2)) : 0">
                                                </el-progress>
                                            </div>
                                        </div>
                                        <div class="yuan">
                                            <p>{{incomeData.offlinePay ? incomeData.offlinePay : 0}} 元</p>
                                        </div>
                                    </div>
                                    <div style="width: 100%;margin-bottom: 10px">
                                        <div style="width: 85%;float: left">
                                            <div style="font-size: 17px;float: left">减免金额占比</div>
                                            <div style="float: right;width: 78%;margin-top: 6px;">
                                                <el-progress :stroke-width="20" :text-inside="true"
                                                             :percentage="incomeData.totalPay ? parseFloat((incomeData.discountAmount / incomeData.totalPay*100).toFixed(2)) : 0">
                                                </el-progress>
                                            </div>
                                        </div>
                                        <div class="yuan">
                                            <p>{{incomeData.discountAmount ? incomeData.discountAmount : 0}} 元</p>
                                        </div>
                                    </div>
                                </div>
                            </dv-border-box-13>
                            <dv-border-box-13 class="boderbox2">
                                <div class="content">
                                    <div class="tit">
                                        <span class="el-icon-help"></span>
                                        <span style="font-weight: bold;font-size: 24px">泊位概况</span>
                                        <dv-decoration-10
                                                style="width:100%;height:.0625rem;margin-top: 10px;margin-bottom: 20px"/>
                                    </div>
                                </div>
                                <div class="content-chart">
                                    <div style="font-size: 22px;">总数(个)：<span
                                            style="font-size: 20px;font-weight: bold">{{placeInfo.total ? placeInfo.total : 0}}</span>
                                    </div>
                                    <div style="margin: 5px">
                                        <div style="font-size: 20px;float: left">已用：<span
                                                style="font-size: 20px;font-weight: bold">{{placeInfo.user ? placeInfo.user : 0}}</span>
                                        </div>
                                        <div style="float: right;width: 78%;margin-top: 12px;">
                                            <el-progress :stroke-width="22" :text-inside="true"
                                                         :percentage="placeInfo.total ? parseFloat((placeInfo.user / placeInfo.total*100).toFixed(2)) : 0">
                                            </el-progress>
                                        </div>
                                    </div>
                                    <div style="margin: 5px">
                                        <div style="font-size: 20px;float: left">
                                            空闲：<span style="font-size: 20px;font-weight: bold"
                                                     v-if="(placeInfo.total - placeInfo.user) > 0">{{placeInfo.total ? placeInfo.total - placeInfo.user : 0}}</span>
                                            <span style="font-size: 20px;font-weight: bold" v-else>0</span>
                                        </div>
                                        <div style="float: right;width: 78%;margin-top: 12px;">
                                            <el-progress
                                                    v-if="(placeInfo.total - placeInfo.user) / placeInfo.total * 100 > 0"
                                                    :stroke-width="22" :text-inside="true"
                                                    :percentage="placeInfo.total ? parseFloat(((placeInfo.total - placeInfo.user) / placeInfo.total*100).toFixed(2)) : 0">
                                            </el-progress>
                                            <el-progress :stroke-width="22" :text-inside="true" :percentage="0"
                                                         v-else></el-progress>
                                        </div>
                                    </div>
                                </div>
                                <center :parkingDistribution="parkingDistribution"/>
                            </dv-border-box-13>
                        </div>
                        <div class="content-box">
                            <dv-border-box-11 title="今日收费列表" class="boderbox">
                                <!--列表-->
                                <dv-scroll-board :config="config3" class="charge-scroll-board"/>
                            </dv-border-box-11>
                            <dv-border-box-11 title="设备状态" class="boderbox1">
                                <!--列表-->
                                <dv-scroll-board @click="scrollBoard" class="equipment" :config="config4"/>
                            </dv-border-box-11>
                            <dv-border-box-11 title="异常处理" class="boderbox1">
                                <!--列表-->
                                <dv-scroll-board :config="config5"/>
                            </dv-border-box-11>
                        </div>
                    </div>
                </div>
                <!--            <dv-full-screen-container>-->
                <!--            </dv-full-screen-container>-->
            </div>
        </div>
        <el-dialog v-draggable
                :title="title"
                :visible.sync="centerDialogVisible"
                width="30%"
                center>
            <div style="display: flex;justify-content: center;flex-direction: column;align-items: center;">
                <img style="width: 350px;height: 250px;"
                     :src="roadImg ? roadImg : require('../assets/images/default.jpg')"/>
                <div style="margin-top: 10px;">
                    <el-button style="padding: 9px 10px;margin: 0 5px;" size="small" type="primary"
                               @click="handleSearch">
                        <p>刷新(R键)</p>
                    </el-button>
                    <span>{{doorwayCarDesc}}</span>
                </div>
            </div>
            <div style="display: flex;justify-content: center;margin-top: 15px;" v-show="roadType == 2">
                <el-button style="width: 350px;padding: 15px" type="warning" @click="handleClick3">
                    <p>现金放行（A键）</p>
                </el-button>
            </div>
            <div style="display: flex;justify-content: center;margin-top: 15px;">
                <div style="display: flex;justify-content: space-around;width: 350px">
                    <el-button style="width: 100%;padding: 15px" type="primary" @click="handleClick2">
                        <p>直接开闸（回车enter）</p>
                    </el-button>
                    <el-button style="width: 100%;padding: 15px" type="success" @click="handleClick1">
                        <p> {{roadType == 2 ? '免费放行（空格）' : '确认放行（空格）'}}</p>
                    </el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import center from "@/components/echart/center/centerChartRate";
    import {
        open,
        shutoff,
        equipmentOperate,
        loginrefresh,
        parkingOperate,
        pass,
        chargeOpen,
        parkingAreaRoadInfo
    } from "../api/bigscreen";
    import {formatTime} from '@/utils/index.js'
    import drawMixin from "@/utils/drawMixin";
    import {isNumber} from "../utils";

    export default {
        mixins: [drawMixin],
        name: "smartScreen",
        data() {
            return {
                timing: null,
                dateDay: null,
                dateYear: null,
                dateWeek: null,
                weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
                loading: true,
                config1: {},
                config2: {},
                config3: {},
                config4: {},
                config5: {},
                appearance: "",
                entrance: "",
                presentNum: "",
                remainder: "",
                date: "",
                incomeData: {},
                placeInfo: {},
                arrlistresult1: {},
                arrlistresult2: {},
                arrlistresult3: {},
                exitlistresult1: {},
                exitlistresult2: {},
                exitlistresult3: {},

                shadow: new Array(10),
                timer: null,
                dateTimer: null,
                show: false,
                onLine: navigator.onLine,
                percentageNum: 0,
                parkingDistribution: '',

                centerDialogVisible: false,
                title: "",
                roadId: "",
                roadType: "",
                roadImg: "",
                doorwayCarDesc: "",
                mytimer: "",
                equipmentArr: [],
                fullscreen: false, // 是否全屏
            };
        },
        components: {
            center,
        },
        mounted() {
            this.cancelLoading();
            this.timeFn();
            this.timer = setInterval(() => {
                this.getParkingOperate();
            }, 10000);
            //3600000
            this.dateTimer = setInterval(() => {
                this.loginToken();
            }, 3600000);
            window.addEventListener('online', this.updateOnlineStatus);
            window.addEventListener('offline', this.updateOnlineStatus);
            this.getEquipmentOperate();
        },
        beforeDestroy() {
            clearInterval(this.timer);
            clearInterval(this.dateTimer);
            clearInterval(this.timing);
            clearTimeout(this.mytimer);
            window.removeEventListener('online', this.updateOnlineStatus);
            window.removeEventListener('offline', this.updateOnlineStatus);
            window.onkeydown = '';
            document.onkeydown = '';
        },
        methods: {
            // 全屏事件
            handleFullScreen() {
                let element = document.documentElement;
                // 判断是否已经是全屏
                // 如果是全屏，退出
                if (this.fullscreen) {
                    if (document.exitFullscreen) {
                        document.exitFullscreen();
                    } else if (document.webkitCancelFullScreen) {
                        document.webkitCancelFullScreen();
                    } else if (document.mozCancelFullScreen) {
                        document.mozCancelFullScreen();
                    } else if (document.msExitFullscreen) {
                        document.msExitFullscreen();
                    }
                    console.log("已还原！");
                } else {
                    // 否则，进入全屏
                    if (element.requestFullscreen) {
                        element.requestFullscreen();
                    } else if (element.webkitRequestFullScreen) {
                        element.webkitRequestFullScreen();
                    } else if (element.mozRequestFullScreen) {
                        element.mozRequestFullScreen();
                    } else if (element.msRequestFullscreen) {
                        // IE11
                        element.msRequestFullscreen();
                    }
                    console.log("已全屏！");
                }
                // 改变当前全屏状态
                this.fullscreen = !this.fullscreen;
            },
            updateOnlineStatus(e) {
                const {type} = e;
                this.onLine = type === 'online';
                if (type === 'online') {
                    this.show = false;
                } else if (type === 'offline') {
                    this.show = true;
                }
            },
            loginToken() {
                loginrefresh().then((res) => {
                    localStorage.setItem('token', res.data.data.token);
                })
            },
            timeFn() {
                this.timing = setInterval(() => {
                    this.dateDay = formatTime(new Date(), 'HH: mm: ss')
                    this.dateYear = formatTime(new Date(), 'yyyy年MM月dd日')
                    this.dateWeek = this.weekday[new Date().getDay()]
                }, 1000)
            },
            cancelLoading() {
                setTimeout(() => {
                    this.loading = false;
                    this.getParkingOperate();
                    this.testing();
                }, 500);
            },
            getParkingOperate() {
                parkingOperate().then((res) => {
                    // 车场收入\
                    let num = isNumber(res.data.data.income.totalPay);
                    let y = String(num).indexOf(".") + 1;//获取小数点的位置
                    let count = String(num).length - y;//获取小数点后的个数
                    let shadow = [];
                    if (y > 0) {
                        let digitsq = num.toString().split('');
                        shadow = digitsq;
                    } else {
                        let digits = num.toString().split('');
                        shadow = digits.map(Number);
                    }
                    let len = shadow.length;
                    for (let i = 0; i < 10 - len; i++) {
                        shadow.unshift('0');
                    }
                    this.shadow = shadow;
                    this.incomeData.totalPay = isNumber(res.data.data.income.totalPay);
                    this.incomeData.onlinePay = isNumber(res.data.data.income.onlinePay);
                    this.incomeData.offlinePay = isNumber(res.data.data.income.offlinePay);
                    this.incomeData.discountAmount = isNumber(res.data.data.income.discountAmount);


                    // 车位使用情况
                    this.placeInfo.total = res.data.data.parkingPlaceInfo.total;
                    this.placeInfo.user = res.data.data.parkingPlaceInfo.user;
                    sessionStorage.setItem('total', res.data.data.parkingPlaceInfo.total);

                    //泊位使用率时间分布
                    this.parkingDistribution = res.data.data.parkingDistribution;

                    //进出车辆统计
                    this.appearance = isNumber(res.data.data.passRecord.appearance);
                    this.entrance = isNumber(res.data.data.passRecord.entrance);
                    this.presentNum = isNumber(res.data.data.passRecord.presentNum);
                    this.remainder = isNumber(res.data.data.passRecord.remainder);

                    //出场车辆
                    this.exitlistresult1 = res.data.data.appearanceRecord[0];
                    this.exitlistresult2 = res.data.data.appearanceRecord[1];
                    this.exitlistresult3 = res.data.data.appearanceRecord[2];

                    //入场车辆
                    this.arrlistresult1 = res.data.data.entranceRecord[0];
                    this.arrlistresult2 = res.data.data.entranceRecord[1];
                    this.arrlistresult3 = res.data.data.entranceRecord[2];

                    //收费排名
                    let arrlistresult = [];
                    res.data.data.chargeRanking.map(item => {
                        if (item.licensePlate === null) {
                            item.licensePlate = "无牌车";
                        }
                        if (item.payType === 1) {
                            item.payType = '电子支付';
                        } else if (item.payType === 2) {
                            item.payType = '现金支付';
                        } else if (item.payType === 4) {
                            item.payType = 'ETC缴费';
                        }
                        let arrlist = [item.payTime.substring(5, 20), item.licensePlate, isNumber(item.parkingTime), isNumber(item.discountAmount), isNumber(item.realAmount), item.payType];
                        arrlistresult.push(arrlist);
                    });
                    this.config3 = {
                        header: ["收费时间", "车牌号", "停车时长", "减免金额", "实收金额", "收费方式"],
                        data: arrlistresult,
                        rowNum: 3, //表格行数
                        headerHeight: 35,
                        headerBGC: "#0f1325", //表头
                        oddRowBGC: "#0f1325", //奇数行
                        evenRowBGC: "#171c33", //偶数行
                        align: ['center', 'center', 'center', 'center', 'center', 'center'],
                        carousel: 'page',
                    }
                })
            },
            testing() {
                var that = this;
                let temp = [];
                that.equipmentArr.map(item => {
                    if (item)
                        if (item.id) {
                            temp.push(item);
                        }
                });
                clearTimeout(that.mytimer);
                // 调用轮询
                if (temp.length) {
                    that.getEquipmentOperate(temp.map(item => item.id));
                }
            },
            //设备运营
            getEquipmentOperate() {
                equipmentOperate().then((res) => {
                    //设备信息
                    let equipment = [];
                    let equipmentresult = [];
                    let e = [];
                    let arrlist;
                    this.equipmentArr = res.data.data.equipment;
                    this.equipmentArr.map((item, index) => {
                        if (item.equipmentType === 1) {
                            let arrlist = [item.equipmentName, item.equipmentType === 1 ? '车牌相机' : item.equipmentType === 2 ? '液晶显示控制器' : '车道监控相机',
                                item.roadName, item.status === 1 ? '在线' : '离线',
                                `<span style="background-color: ${item.equipmentType === 1 ? '#67c23a' : ''};padding: 8px 20px;border-radius: 10px; font-size: 16px;">${item.equipmentType === 1 ? '开闸' + (item.num != null ? '[' + item.num + ']' : '') : ''}</span>`,
                                `<span style="background-color: ${item.equipmentType === 1 ? '#f08519' : ''};padding: 8px 16px;border-radius: 10px;font-size: 16px;">${item.equipmentType === 1 ? '关闸' : ''}</span>`,
                                item.roadId, item.roadType, index];
                            equipmentresult.push(arrlist);
                            if (item.equipmentType === 1) {
                                window.onkeydown = event => {
                                    /* 解决兼容性问题 */
                                    e = event || window.event;
                                    if (e.key === '1') {
                                        this.scrollBoard(equipmentresult[0])
                                    } else if (e.key === '2') {
                                        this.scrollBoard(equipmentresult[1])
                                    } else if (e.key === '3') {
                                        this.scrollBoard(equipmentresult[2])
                                    } else if (e.key === '4') {
                                        this.scrollBoard(equipmentresult[3])
                                    } else if (e.key === '5') {
                                        this.scrollBoard(equipmentresult[4])
                                    } else if (e.key === '6') {
                                        this.scrollBoard(equipmentresult[5])
                                    } else if (e.key === '7') {
                                        this.scrollBoard(equipmentresult[6])
                                    } else if (e.key === '8') {
                                        this.scrollBoard(equipmentresult[7])
                                    } else if (e.key === '9') {
                                        this.scrollBoard(equipmentresult[8])
                                    } else if (e.key === 'q' && e.key === 'Q') {
                                        this.scrollBoard(equipmentresult[9])
                                    } else if (e.key === 'w' && e.key === 'W') {
                                        this.scrollBoard(equipmentresult[10])
                                    } else if (e.key === 'e' && e.key === 'E') {
                                        this.scrollBoard(equipmentresult[11])
                                    } else if (e.key === 'r' && e.key === 'R') {
                                        this.scrollBoard(equipmentresult[12])
                                    } else if (e.key === 't' && e.key === 'T') {
                                        this.scrollBoard(equipmentresult[13])
                                    } else if (e.key === 'y' && e.key === 'Y') {
                                        this.scrollBoard(equipmentresult[14])
                                    } else if (e.key === 'u' && e.key === 'U') {
                                        this.scrollBoard(equipmentresult[15])
                                    } else if (e.key === 'i' && e.key === 'I') {
                                        this.scrollBoard(equipmentresult[16])
                                    } else if (e.key === 'o' && e.key === 'O') {
                                        this.scrollBoard(equipmentresult[17])
                                    } else if (e.key === 'p' && e.key === 'P') {
                                        this.scrollBoard(equipmentresult[18])
                                    } else if (e.key === '0') {
                                        this.scrollBoard(equipmentresult[19])
                                    }
                                }
                            }
                        }
                        e.push(item);
                        e.forEach((a, i) => {
                            let arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 'q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', 0];
                            a['num'] = arr[i];
                            arrlist = [item.equipmentName, item.equipmentType === 1 ? '车牌相机' : item.equipmentType === 2 ? '液晶显示控制器' : '车道监控相机',
                                item.roadName, `<span style="color: ${item.status === 1 ? '' : 'red'};">${item.status === 1 ? '在线' : '离线'}</span>`,
                                `<span style="background-color: ${item.equipmentType === 1 ? '#67c23a' : ''};padding: 8px 20px;border-radius: 10px; font-size: 16px;">${item.equipmentType === 1 ? '开闸' + (a.num != null ? '[' + a.num + ']' : '') : ''}</span>`,
                                `<span style="background-color: ${item.equipmentType === 1 ? '#f08519' : ''};padding: 8px 16px;border-radius: 10px;font-size: 16px;">${item.equipmentType === 1 ? '关闸' : ''}</span>`,
                                item.roadId, item.roadType, index];
                        });
                        if (arrlist) {
                            equipment.push(arrlist);
                        }
                    });
                    this.config4 = {
                        header: ["设备名称", "设备类型", "道口名称", "状态", "操作", "操作"],
                        data: equipment,
                        headerHeight: 35,
                        headerBGC: "#0f1325", //表头
                        oddRowBGC: "#0f1325", //奇数行
                        evenRowBGC: "#171c33", //偶数行
                        align: ['center', 'center', 'center', 'center', 'center', 'center'],
                        // carousel: 'page',
                        // waitTime: 5000, // 轮播时间间隔(ms)
                    };
                    this.mytimer = setTimeout(() => {
                        clearTimeout(this.mytimer);
                        this.testing();
                    }, 10000 * 5000);

                    //异常处理
                    let arrlistresult = [];
                    res.data.data.exceptionRecord.map(item => {
                        if (item.handingMethod === 1) {
                            item.handingMethod = '修改车牌';
                        } else if (item.handingMethod === 2) {
                            item.handingMethod = '开闸';
                        } else if (item.handingMethod === 3) {
                            item.handingMethod = '匹配入场记录';
                        } else if (item.handingMethod === 4) {
                            item.handingMethod = '重新抓拍';
                        } else if (item.handingMethod === 5) {
                            item.handingMethod = '关闸';
                        }
                        if (item.discernLicensePlate === "" || item.discernLicensePlate === null) {
                            item.discernLicensePlate = "无牌车";
                        }
                        let arrlist = [item.discernLicensePlate, item.createTime.substring(5, 20),
                            item.roadName, item.handingMethod, item.operator];
                        arrlistresult.push(arrlist);
                    });
                    //, "处理时间"
                    this.config5 = {
                        header: ["识别车牌", "处理时间", "道口名称", "处理方式", "处理人"],
                        data: arrlistresult,
                        // rowNum: 5, //表格行数
                        headerHeight: 35,
                        headerBGC: "#0f1325", //表头
                        oddRowBGC: "#0f1325", //奇数行
                        evenRowBGC: "#171c33", //偶数行
                        align: ['center', 'center', 'center', 'center', 'center'],
                        // carousel: 'single',
                        // waitTime: 30000, // 轮播时间间隔(ms)
                    }
                })
            },
            //开关闸
            scrollBoard(val) {
                if (val) {
                    let open = val[4] ? val[4].substring(val[4].indexOf('开'), val[4].indexOf('闸')) : val.row[4].substring(val.row[4].indexOf('开'), val.row[4].indexOf('闸'));
                    if (val.ceil === "<span style=\"background-color: #f08519;padding: 8px 16px;border-radius: 10px;font-size: 16px;\">关闸</span>") {
                        shutoff(val[6] || val.row[6]).then((res) => {
                            if (res.data.code === "SUCCESS") {
                                this.$message.success("关闸" + res.data.message);
                            } else {
                                this.$message.error(res.data.message);
                            }
                        }).catch(() => {
                            this.$message.error("关闸失败，请稍后再试！");

                        });
                    } else if (open === '开') {
                        this.centerDialogVisible = true;
                        this.title = val[2] || val.row[2];
                        this.roadId = val[6] || val.row[6];
                        this.roadType = val[7] || val.row[7];
                        parkingAreaRoadInfo(this.roadId).then(res => {
                            if (res.data.data) {
                                this.roadImg = res.data.data.img;
                                this.doorwayCarDesc = res.data.data.doorwayCarDesc;
                            } else {
                                this.roadImg = "";
                                this.doorwayCarDesc = "";
                            }
                        });
                        this.keyupEnter();
                    }
                }
            },
            handleSearch() {
                parkingAreaRoadInfo(this.roadId).then(res => {
                    if (res.data.code === "SUCCESS") {
                        if (res.data.data) {
                            this.roadImg = res.data.data.img;
                            this.doorwayCarDesc = res.data.data.doorwayCarDesc;
                        } else {
                            this.roadImg = "";
                            this.doorwayCarDesc = "";
                        }
                        this.$message.success("刷新成功");
                    }
                });
            },
            keyupEnter() {
                // 为document绑定一个按键按下的事件
                document.onkeydown = event => {
                    /* 解决兼容性问题 */
                    event = event || window.event;
                    if (event.keyCode === 65 && this.roadType === 2) {
                        //现金放行
                        console.log('A');
                        this.handleClick3();
                    } else if (event.keyCode === 13) {
                        console.log('enter');
                        //直接开闸
                        this.handleClick2();
                    } else if (event.keyCode === 32) {
                        //确认放行
                        console.log("空格");
                        this.handleClick1();
                    } else if (event.keyCode === 82) {
                        this.handleSearch()
                    }
                }
            },
            handleClick1() {
                //确认放行
                this.centerDialogVisible = false;
                pass(this.roadId).then((res) => {
                    if (res.data.code === "SUCCESS") {
                        this.$message.success("开闸" + res.data.message);
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
            },
            handleClick2() {
                //直接开闸
                this.centerDialogVisible = false;
                open(this.roadId).then((res) => {
                    if (res.data.code === "SUCCESS") {
                        this.$message.success("开闸" + res.data.message);
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
            },
            handleClick3() {
                //现金放行
                this.centerDialogVisible = false;
                chargeOpen(this.roadId).then((res) => {
                    if (res.data.code === "SUCCESS") {
                        this.$message.success("开闸" + res.data.message);
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
            }

        }
    }
</script>

<style lang="scss" scoped>
    @import "src/assets/scss/index";

    .indexMain {
        width: 100%;
        height: 100%;
        background-color: #020308;
    }

    /deep/ .dv-scroll-board {
        width: 92%;
        height: 270px;
        left: 24px;
        top: 60px;
    }

    /deep/ .header-item:nth-child(1) {
        width: 130px !important;
    }

    /deep/ .header-item:nth-child(2) {
        width: 115px !important;
    }

    /deep/ .header-item:nth-child(3) {
        width: 115px !important;
    }

    /deep/ .header-item:nth-child(4) {
        width: 85px !important;
    }

    /deep/ .header-item:nth-child(5) {
        width: 85px !important;
    }

    /deep/ .header-item:nth-child(6) {
        width: 85px !important;
    }

    /deep/ .header-item:nth-child(7) {
        display: none;
    }

    /deep/ .header-item:nth-child(8) {
        display: none;
    }

    /*font-size: 20px;*/
    /deep/ .ceil:nth-child(1) {
        width: 130px !important;
    }

    /deep/ .ceil:nth-child(2) {
        width: 115px !important;
    }

    /deep/ .ceil:nth-child(3) {
        width: 115px !important;
    }

    /deep/ .ceil:nth-child(4) {
        width: 85px !important;
    }

    /deep/ .ceil:nth-child(5) {
        width: 85px !important;
        cursor: pointer;
    }

    /deep/ .ceil:nth-child(6) {
        width: 85px !important;
        cursor: pointer;
    }

    /deep/ .ceil:nth-child(7) {
        display: none;
    }

    /deep/ .ceil:nth-child(8) {
        display: none;
    }

    .equipment /deep/ .rows {
        width: 100%;
    }

    .equipment /deep/ .ceil {
        padding: 0 !important;
    }

    .equipment /deep/ .header-item:nth-child(1) {
        width: 20% !important;
    }

    .equipment /deep/ .header-item:nth-child(2) {
        width: 15% !important;
    }

    .equipment /deep/ .header-item:nth-child(3) {
        width: 20% !important;
    }

    .equipment /deep/ .header-item:nth-child(4) {
        width: 10% !important;
    }

    .equipment /deep/ .header-item:nth-child(5) {
        width: 20% !important;
    }

    .equipment /deep/ .header-item:nth-child(6) {
        width: 15% !important;
    }

    .equipment /deep/ .ceil:nth-child(1) {
        width: 20% !important;
    }

    .equipment /deep/ .ceil:nth-child(2) {
        width: 15% !important;
    }

    .equipment /deep/ .ceil:nth-child(3) {
        width: 20% !important;
    }

    .equipment /deep/ .ceil:nth-child(4) {
        width: 10% !important;
    }

    .equipment /deep/ .ceil:nth-child(5) {
        width: 20% !important;
        cursor: pointer;
    }

    .equipment /deep/ .ceil:nth-child(6) {
        width: 15% !important;
        cursor: pointer;
    }

    .equipment /deep/ .ceil:nth-child(9) {
        display: none !important;
    }

    /deep/ .el-progress-bar__outer {
        background-color: #11173a !important;
    }

    /deep/ .el-progress-bar__inner {
        background-color: #225e97 !important;
    }

    /deep/ .dv-border-box-11 text.dv-border-box-11-title[fill] {
        fill: #5589e9 !important;
        font-weight: bold;
        font-size: 24px;
    }

    /deep/ .el-card {
        background-color: transparent !important;
        border: none !important;
    }

    .image {
        width: 180px;
        height: 130px;
    }

    .image1 {
        /*width: 180px;*/
        /*height: 130px;*/
        padding: 12px;
    }

    /deep/ .el-image__inner--center {
        top: auto;
        left: auto;
        transform: none;
    }


    .div-img {
        background: rgb(15, 19, 37);
        width: 180px;
        height: 130px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .clearfix:before,
    .clearfix:after {
        display: table;
        content: "";
    }

    .clearfix:after {
        clear: both
    }

    .dv-decor-6 {
        width: 390px;
        height: 10px;
        margin: 20px 0 0 0;
    }

    .react-ml {
        background-color: #0f1325;
        margin-top: -20px
    }

    .d-flex1 {
        width: 40%;
        justify-content: flex-end;
    }

    .react-mls {
        background-color: #0f1325;
        margin-top: -20px
    }

    .div-box-11 {
        width: 90%;
        height: 260px;
        margin: 0 30px;
        position: absolute;
        top: 65px;
        display: flex;
        flex-direction: row;
        justify-content: space-around
    }

    .dv-box-11 {
        margin: 0 10px;
    }

    .dv-box-info {
        margin-top: 10px;
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        font-size: 18px;
        line-height: 30px;
        color: #fff;
        background: rgb(15, 19, 37);
    }

    .div-box-exit11 {
        width: 90%;
        height: 260px;
        margin: 0 30px;
        position: absolute;
        top: 65px;
        display: flex;
        flex-direction: row;
        justify-content: space-around
    }

    .div-exit-info {
        margin: 0 10px;
    }

    .div-exit-infos {
        margin-top: 10px;
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        font-size: 18px;
        line-height: 30px;
        color: #fff;
    }

    .ml-33 {
        margin-left: 20px;
    }

</style>
