<template>
    <div>
        <div v-if="show">
            <el-button size="mini" icon="el-icon-plus" type="primary" @click="addArea">添加区域</el-button>
            <el-button size="mini" icon="el-icon-download" @click="downloadQR">下载二维码</el-button>
            <el-table
                    size="small"
                    :data="treeData"
                    highlight-current-row
                    border style="width: 100%"
                    :row-style="{height:'45px'}"
                    :cell-style="{padding:'0px'}"
                    :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                    stripe height="500" max-height="500">
                <el-table-column type="index" label="序号" align="center"></el-table-column>
                <el-table-column prop="name" label="区域名称" align="center"></el-table-column>
                <el-table-column prop="areaType" label="区域属性" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.areaType == 1">固定长租</span>
                        <span v-else>临停/固定长租</span>
                    </template>
                </el-table-column>
                <el-table-column prop="chargeType" label="收费方式" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.chargeType == 1 || scope.row.chargeType == null">出口收费</span>
                        <span v-else>入口收费</span>
                    </template>
                </el-table-column>
                <el-table-column prop="totalNum" label="总车位数" align="center"></el-table-column>
                <el-table-column prop="fixedNum" label="长租车位数" align="center"></el-table-column>
                <el-table-column width="250" align="center" label="操作">
                    <template slot-scope="scope">
                        <el-button size="mini" type="warning"
                                   @click="handlePlace(scope.$index, scope.row)"
                        >车位
                        </el-button>
                        <el-button size="mini" @click="handleEdit(scope.$index, scope.row)" icon="el-icon-setting"
                                   type="primary">编辑
                        </el-button>
                        <!--                        <el-button size="mini" type="danger" class="el-icon-delete"-->
                        <!--                                   @click="handleDelete(scope.$index, scope.row)"-->
                        <!--                        >删除-->
                        <!--                        </el-button>-->
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div v-if="hidden">
            <el-button size="mini" icon="el-icon-plus" type="primary" @click="addTo">添加</el-button>
            <el-button size="mini" type="success" @click="batchAdd">批量添加</el-button>
            <el-button size="mini" @click="goback">返回</el-button>
            <el-table
                    size="small"
                    :data="parkingData"
                    highlight-current-row
                    border
                    style="width: 100%"
                    :row-style="{height:'45px'}"
                    :cell-style="{padding:'0px'}"
                    :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                    stripe
                    height="500"
                    max-height="470"
            >
                <el-table-column prop="id" label="序号"></el-table-column>
                <el-table-column prop="parkingArea.name" label="区域名称" align="center"></el-table-column>
                <el-table-column prop="parkingPlaceNumber" label="车位编号" align="center"></el-table-column>
                <el-table-column align="center" label="操作">
                    <template slot-scope="scope">
                        <el-button size="mini" type="danger" class="el-icon-delete"
                                   @click="deleteData(scope.$index, scope.row)"
                        >删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination background
                           @size-change="handleSizeChange"
                           @current-change="handleCurrentChange"
                           :current-page="filters.current"
                           :page-sizes="[10, 50, 200, 1000]"
                           :page-size="filters.size"
                           layout="total, sizes, prev, pager, next, jumper"
                           :total="total">
            </el-pagination>
        </div>
        <!--    区域管理-->
        <el-dialog v-draggable :title="title" :visible.sync="addDialog">
            <el-form :model="editForm" ref="editForm" size="small" :rules="rules">
                <el-row>
                    <el-col :span="11">
                        <el-form-item label="区域名称" prop="name" :label-width="formLabelWidth">
                            <el-input size="small" v-model="editForm.name" placeholder="请输入内容"/>
                        </el-form-item>
                        <el-form-item label="区域属性" prop="areaType" :label-width="formLabelWidth">
                            <el-select style="width: 100%" size="small" v-model="editForm.areaType" placeholder="请选择区域属性">
                                <el-option label="固定长租" :value="1"></el-option>
                                <el-option label="临停/固定长租" :value="2"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="收费方式" prop="chargeType" :label-width="formLabelWidth">
                            <el-select style="width: 100%" size="small" v-model="editForm.chargeType" placeholder="请选择收费方式">
                                <el-option label="出口收费" :value="1"></el-option>
                                <el-option label="入口收费" :value="2"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11">
                        <el-form-item label="总车位数" prop="totalNum" :label-width="formLabelWidth">
                            <el-input size="small" v-model="editForm.totalNum"
                                      oninput="value=value.replace(/[^\d]/g,'')" placeholder="请输入内容"/>
                        </el-form-item>
                        <el-form-item label="固定/长租车位数" prop="fixedNum" :label-width="formLabelWidth">
                            <el-input size="small" v-model="editForm.fixedNum"
                                      oninput="value=value.replace(/[^\d]/g,'')" placeholder="请输入内容"/>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button @click="resetForm('editForm')">取 消</el-button>
        <el-button type="primary" @click="submitForm('editForm')">确 定</el-button>
      </span>
        </el-dialog>
        <!--    车位管理-->
        <el-dialog v-draggable :title="title" :visible.sync="parkingDialog" width="40%">
            <el-form :model="parkingForm" ref="parkingForm" size="small" label-width="80px">
                <el-form-item label="车位编号">
                    <el-input size="small" v-model="parkingForm.parkingPlaceNumber" placeholder="请输入内容"/>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button @click="parkingDialog = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit('parkingForm')">确 定</el-button>
      </span>
        </el-dialog>
        <!--    车位批量添加-->
        <el-dialog v-draggable :title="title" :visible.sync="batchDialog" width="40%">
            <el-form :model="parkingForm" ref="parkingForm" size="small" label-width="80px">
                <el-form-item label="起始编号">
                    <el-input size="small" v-model="parkingForm.startPlaceNumber" placeholder="请输入内容"/>
                </el-form-item>
                <el-form-item label="截止编号">
                    <el-input size="small" v-model="parkingForm.endPlaceNumber" placeholder="请输入内容"/>
                </el-form-item>
                <el-form-item label="编号前缀">
                    <el-input size="small" v-model="parkingForm.prefix" placeholder="请输入内容"/>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button @click="batchDialog = false">取 消</el-button>
        <el-button type="primary" @click="confirm('parkingForm')">确 定</el-button>
      </span>
        </el-dialog>
    </div>
</template>

<script>
    import {
        areaDel, areaSave,
        areaUpdate,
        parkingDataList,
        parkingDel, parkingDown,
        parkingSave,
        parkingSaveBatch,
    } from "../../../../../api/yardSetting";
    import {login} from "../../../../../api/login";
    import JSZip from "jszip";
    import FileSaver from "file-saver";
    import axios from 'axios'

    export default {
        name: "first",
        props: ["treeData"],
        data() {
            var validate = (rule, value, callback) => {
                if (value === "") {
                    callback(new Error("请输入"));
                } else if (Number(value) > Number(this.editForm.totalNum)) {
                    callback(new Error("长租车位数不能大于总车位数!"));
                } else {
                    callback();
                }
            };
            return {
                show: true,
                hidden: false,
                title: "",
                addDialog: false,
                formLabelWidth: '140px',
                editForm: {
                    areaType: "",
                    chargeType: "1",
                    name: "",
                    fixedNum: "",
                    totalNum: "",
                    id: "",
                },
                rules: {
                    name: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ],
                    areaType: [
                        {required: true, message: '请选择', trigger: 'change'}
                    ],
                    fixedNum: [
                        {required: true, message: '请输入活', trigger: 'blur'},
                        {validator: validate, trigger: "blur", required: true},
                    ],
                    totalNum: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ],
                },
                total: 0,
                filters: {
                    parkingAreaId: '',
                    current: 1,
                    size: 10,
                },
                parkingForm: {
                    areaId: "",
                    endPlaceNumber: "",
                    parkingId: "",
                    prefix: "",
                    startPlaceNumber: "",
                    parkingPlaceNumber: ""
                },
                parkingDialog: false,
                batchDialog: false,
                parkingData: [],
                qrFile: [],
                parkingName: "",
            }
        },
        mounted() {
            this.id = localStorage.getItem('subSysId');
            this.getParkingDataList();
        },
        methods: {
            addArea() {
                this.title = '区域管理';
                this.addDialog = true;
                Object.keys(this.editForm).map(key => {
                    this.editForm[key] = '';
                });
                this.editForm.chargeType = 1;
            },
            handleEdit(index, row) {
                this.title = '编辑区域管理';
                this.addDialog = true;
                Object.keys(this.editForm).map(key => {
                    this.editForm[key] = row[key];
                });
                this.editForm.id = row.id;
                if (!this.editForm.chargeType) {
                    this.editForm.chargeType = 1;
                }
            },
            submitForm(editForm) {
                this.$refs[editForm].validate((valid) => {
                    if (valid) {
                        let param = {
                            id: this.editForm.id,
                            parkingId: this.id,
                            areaType: this.editForm.areaType + '',
                            chargeType: this.editForm.chargeType,
                            fixedNum: this.editForm.fixedNum,
                            name: this.editForm.name,
                            totalNum: this.editForm.totalNum,
                        };
                        if (this.editForm.id) {
                            areaUpdate(param).then((res) => {
                                this.addDialog = false;
                                this.$refs[editForm].resetFields();
                                this.$emit('handleTreeData', 'first');
                                if (res.data.code == "SUCCESS") {
                                    this.$message({
                                        type: "success",
                                        message: "编辑成功！",
                                    });
                                } else {
                                    this.$message({
                                        type: "info",
                                        message: res.data.message,
                                    });
                                }
                            })
                        } else {
                            areaSave(param).then((res) => {
                                this.addDialog = false;
                                this.$emit('handleTreeData', 'first');
                                if (res.data.code == "SUCCESS") {
                                    this.$message({
                                        type: "success",
                                        message: "保存成功！",
                                    });
                                } else {
                                    this.$message({
                                        type: "info",
                                        message: res.data.message,
                                    });
                                }
                            })
                        }
                    }
                })
            },
            resetForm(formName) {
                this.addDialog = false;
                this.$refs[formName].resetFields();
            },
            handleDelete(index, row) {
                this.$prompt('确定要删除吗?请输入登录密码以确认删除。', '温馨提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass: "reset-message-box",
                    type: "warning",
                    inputType: "password",
                    inputPlaceholder: "请输入密码",
                    inputErrorMessage: "请输入正确密码",
                    inputValidator: (value) => {       // 点击按钮时，对文本框里面的值进行验证
                        if (!value) {
                            return '请输入正确密码';
                        }
                    },
                }).then(({value}) => {
                    let user = window.localStorage.getItem('userdata');
                    user = JSON.parse(user);
                    let param = {
                        username: user.username,
                        password: value,
                    };
                    login(param).then((res) => {
                        if (res.data.code === 'SUCCESS') {
                            // 缓存token
                            localStorage.setItem('token', res.data.data.token);
                            // 缓存用户个人信息
                            localStorage.setItem('userdata', JSON.stringify(res.data.data.partnerUserDTO));
                            areaDel(row.id).then((res) => {
                                if (res.data.code == "SUCCESS") {
                                    this.$emit('handleTreeData', 'first');
                                    this.$message.success("删除成功!");
                                } else {
                                    this.$message.info(res.data.message);
                                    return;
                                }
                            })
                        } else {
                            this.$message.error(res.data.message);
                            return false;
                        }
                    })
                }).catch(() => {
                    this.$message.info('已取消删除!');
                });
            },
            downloadQR() {
                parkingDown(this.id).then((res) => {
                    if (res.data.data) {
                        //遍历数据
                        Object.keys(res.data.data).forEach((key) => {
                            if (key === "centerPayQrCode") {
                                this.qrFile.push({
                                    name: res.data.data[key].parkingName + "-" + res.data.data[key].qrCodeType,
                                    url: res.data.data[key].qrCode
                                })
                            } else {
                                res.data.data[key].forEach((v) => {
                                    this.qrFile.push({
                                        name: v.parkingName + "-" + v.areaName + "-" + v.roadName + "-" + v.qrCodeType,
                                        url: v.qrCode
                                    })
                                })
                            }
                        });
                        this.getUrl(this.qrFile);
                    } else {
                        this.$message.error('当前没有可下载的二维码')
                    }
                })
            },
            getUrl(qrFile) {
                let zip = new JSZip();
                let len = qrFile.length - 1;
                let axiosArray = [];
                qrFile.forEach((item, inde) => {
                    axiosArray.push(axios({
                        url: item.url,
                        responseType: 'blob',
                    }))
                });
                axios.all(axiosArray).then((resList) => {
                    let i = 0;
                    resList.forEach(async (item, index) => {
                        let reader = new FileReader();
                        reader.readAsDataURL(item.data);
                        reader.onload = (e) => {
                            let base64 = e.target.result;
                            let data = base64.replace(/^data:image\/(png|jpg|jpeg);base64,/, "");
                            let obj = qrFile.find((x) => x.url == item.config.url);
                            zip.file(obj.name + '.png', data, {base64: true}); //向zip中添加文件
                            ++i
                            if (i >= resList.length) {
                                zip.generateAsync({type: "blob"}).then((content) => {
                                    FileSaver.saveAs(content, this.parkingName); // 利用file-saver保存文件
                                    this.qrFile = [];
                                });
                            }
                        }
                    })

                })
            },
            handlePlace(index, row) {
                this.show = false;
                this.hidden = true;
                this.parkingForm.areaId = row.id;
                this.filters.parkingAreaId = row.id;
                this.getParkingDataList();
            },
            goback() {
                this.show = true;
                this.hidden = false;
            },
            getParkingDataList() {
                let param = {
                    current: this.filters.current,
                    size: this.filters.size,
                    parkingAreaId: this.filters.parkingAreaId
                }
                parkingDataList(param).then((res) => {
                    this.parkingData = res.data.data.records;
                    this.total = res.data.data.total;
                    this.filters.current = res.data.data.current;
                    this.filters.size = res.data.data.size;
                })
            },
            handleSizeChange(val) {
                this.filters.size = val;
                this.filters.current = 1;
                this.getParkingDataList();
            },
            handleCurrentChange(val) {
                this.filters.current = val;
                this.getParkingDataList();
            },
            addTo() {
                this.title = '车位管理';
                this.parkingDialog = true;
                this.parkingForm.parkingPlaceNumber = "";
            },
            onSubmit(parkingForm) {
                this.$refs[parkingForm].validate((valid) => {
                    if (valid) {
                        let param = {
                            areaId: this.parkingForm.areaId,
                            parkingId: this.id,
                            parkingPlaceNumber: this.parkingForm.parkingPlaceNumber,
                        };
                        parkingSave(param).then((res) => {
                            this.parkingDialog = false;
                            this.getParkingDataList();
                            if (res.data.code == "SUCCESS") {
                                this.$message({
                                    type: "success",
                                    message: "添加成功！",
                                });
                            } else {
                                this.$message({
                                    type: "info",
                                    message: res.data.message,
                                });
                            }
                        })
                    }
                })
            },
            batchAdd() {
                this.title = '车位批量添加';
                this.batchDialog = true;
                this.parkingForm.endPlaceNumber = "";
                this.parkingForm.startPlaceNumber = "";
            },
            confirm(parkingForm) {
                this.$refs[parkingForm].validate((valid) => {
                    if (valid) {
                        let param = {
                            parkingId: this.id,
                            areaId: this.parkingForm.areaId,
                            prefix: this.parkingForm.prefix,
                            endPlaceNumber: this.parkingForm.endPlaceNumber,
                            startPlaceNumber: this.parkingForm.startPlaceNumber,
                        };
                        parkingSaveBatch(param).then((res) => {
                            this.batchDialog = false;
                            this.getParkingDataList();
                            if (res.data.code == "SUCCESS") {
                                this.$message({
                                    type: "success",
                                    message: "添加成功！",
                                });
                            } else {
                                this.$message({
                                    type: "info",
                                    message: res.data.message,
                                });
                            }
                        })
                    }
                })
            },
            deleteData(index, row) {
                this.$confirm("确定要删除吗?", "温馨提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    parkingDel(row.id).then((res) => {
                        if (res.data.code == "SUCCESS") {
                            this.getParkingDataList();
                            this.$message({
                                type: "success",
                                message: "删除成功!",
                            });
                        } else {
                            this.$message({
                                type: "info",
                                message: res.data.message,
                            });
                        }
                    })
                        .catch((err) => {
                            this.$message.error("删除失败，请稍后再试！");
                        });
                }).catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
            },
        }
    }
</script>

<style scoped>
    .el-table {
        margin-top: 10px;
    }

</style>
