<template>
    <div>
        <div style="margin-bottom: 10px">
            <span>缴费金额：{{totalPayMoney}}元，</span>
            <span>出场车次：{{tempAppearanceNum}}，</span>
            <span>入场车次：{{tmpEntranceNum}}</span>
        </div>
        <!--列表-->
        <el-table
                size="small"
                :data="tableData"
                highlight-current-row
                border
                v-loading="loading"
                element-loading-text="拼命加载中"
                style="width: 100%"
                :row-style="{height:'45px'}"
                :cell-style="{padding:'0px'}"
                :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                stripe
                height="500"
                max-height="500"
        >
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column prop="date" label="日期" align="center"></el-table-column>
            <el-table-column prop="payMoney" label="缴费金额(元)" align="center"></el-table-column>
            <el-table-column prop="tempAppearance" label="出场车次" align="center"></el-table-column>
            <el-table-column prop="tmpEntrance" label="入场车次" align="center"></el-table-column>
        </el-table>
    </div>
</template>

<script>
    import {formatDate, isNumber} from "../../../utils";
    import {tempIncome} from "../../../api/statistics";

    export default {
        name: "temporaryStop",
        data() {
            return {
                loading: false,
                tableData: [],
                tempAppearanceNum: "",
                tmpEntranceNum: "",
                totalPayMoney: "",
                months: "",
            }
        },
        props: {
            month: [Number, String],
        },
        watch: {
            month(val) { //month即为父组件的值，val参数为值
                this.months = val //将父组件的值赋给months 子组件的值
            }
        },
        created() {
            this.months = this.month
        },
        mounted() {
            this.getTmpIncomeData();
        },
        methods: {
            getTmpIncomeData() { //获取数据
                let that = this;
                let param = {
                    year: formatDate(this.months, "yyyy"),
                    month: formatDate(this.months, "MM")
                }
                that.loading = true;
                tempIncome(param).then(function (res) {
                    that.loading = false;
                    that.tableData = res.data.data.incomeCountList;
                    that.tempAppearanceNum = isNumber(res.data.data.tempAppearanceNum);
                    that.tmpEntranceNum = isNumber(res.data.data.tmpEntranceNum);
                    that.totalPayMoney = isNumber(res.data.data.totalPayMoney);
                }).catch(function (error) {
                    that.loading = false;
                    console.log(error);
                });
            },
        }
    }
</script>

<style scoped>

</style>
