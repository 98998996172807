<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>商家优惠</el-breadcrumb-item>
            <el-breadcrumb-item>优惠统计</el-breadcrumb-item>
        </el-breadcrumb>
        <!--    <div style="float: right">当前车场:xxx停车场</div>-->
        <!-- 搜索筛选 -->
        <el-form :inline="true" :model="filters" class="user-search">
            <el-form-item>
                <el-select size="small" v-model="filters.businessId" clearable filterable placeholder="请选择商家">
                    <el-option v-for="item in couponBusinessData" :key="item.id" :label="item.businessName"
                               :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-select size="small" v-model="filters.couponType" clearable filterable placeholder="请选择优惠类型">
                    <el-option label="现金券" value="1"></el-option>
                    <el-option label="折扣券" value="2"></el-option>
                    <el-option label="全免" value="3"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-input size="small" v-model="filters.couponName" clearable placeholder="请输入优惠名称"
                          @keyup.enter.native="fetchData"></el-input>
            </el-form-item>
            <el-form-item label="发放时间">
                <el-date-picker
                        size="small"
                        v-model="date"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-search" type="primary" size="small" @click="fetchData">查询</el-button>
                <el-button icon="el-icon-download" type="primary" size="small" @click="handleExportExcel">导出</el-button>
            </el-form-item>
        </el-form>
        <!--列表-->
        <el-table
                id="out-table"
                size="small"
                :data="tableData"
                highlight-current-row
                v-loading="loading"
                element-loading-text="拼命加载中"
                border
                show-summary
                :summary-method="getSummaries"
                :row-style="{height:'45px'}"
                :cell-style="{padding:'0px'}"
                :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                stripe
                style="width: 100%;">
            <el-table-column min-width="30" type="index" label="序号"></el-table-column>
            <el-table-column prop="businessName" label="商家名称" align="center"></el-table-column>
            <el-table-column prop="couponName" label="优惠名称" align="center"></el-table-column>
            <el-table-column prop="couponType" label="优惠类型" align="center">
                <template slot-scope="scope">
                    <el-tag type="success" v-if="scope.row.couponType == 1" align="center">现金券</el-tag>
                    <el-tag type="primary" v-else-if="scope.row.couponType == 2" align="center">折扣券</el-tag>
                    <el-tag type="danger" v-else-if="scope.row.couponType == 3" align="center">全免</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="giveOutNum" label="总数量" align="center"></el-table-column>
            <el-table-column prop="businessUseNum" label="发放数量" align="center"></el-table-column>
            <el-table-column prop="userUserNum" label="实际使用" align="center"></el-table-column>
            <el-table-column prop="totalPrice" label="发放总价(元)" align="center"></el-table-column>
            <el-table-column prop="totalDiscount" label="实际抵扣(元)" align="center"></el-table-column>
        </el-table>
        <el-pagination background
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="filters.current"
                       :page-sizes="[10, 50, 200, 1000]"
                       :page-size="filters.size"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
        <!--        选择导出文件类型-->
        <el-dialog v-draggable  title="文件类型" :visible.sync="selectDialog" width="250px">
            <el-form :model="editForm" ref="editForm" size="small" :rules="rules">
                <el-form-item prop="radio" label-width="50px">
                    <el-radio v-model="editForm.radio" label="xls">xls</el-radio>
                    <el-radio v-model="editForm.radio" label="xlsx">xlsx</el-radio>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="selectDialog = false">取 消</el-button>
        <el-button size="small" type="primary" @click="onExportExcel('editForm')">确 定</el-button>
        </span>
        </el-dialog>
    </div>
</template>

<script>
    import {statisticsList, couponBusinessList} from "@/api/merchantDiscount/coupon";
    import {formatDate} from "@/utils";
    import vue from "vue";

    export default {
        name: "preferential",
        data() {
            return {
                loading: false,
                total: 0,
                tableData: [],
                couponBusinessData: [],
                filters: {
                    businessId: '',
                    couponName: '',
                    couponType: '',
                    endTime: '',
                    startTime: '',
                    status: '',
                    current: 1,
                    size: 10,
                },
                selectDialog: false,
                editForm:{
                    radio: "",
                },
                rules: {
                    radio: [
                        {required: true, message: '请选择导出类型', trigger: 'change'}
                    ],
                },
                date: [],

            }
        },
        mounted() {
            this.fetchData();  //调用接口获取动态数据
            this.getCouponBusinessList();
        },
        methods: {
            fetchData() { //获取数据
                let that = this;
                that.filters.startTime =
                    that.date && that.date.length
                        ? formatDate(that.date[0], "yyyy-MM-dd")
                        : "";
                that.filters.endTime =
                    that.date && that.date.length
                        ? formatDate(that.date[1], "yyyy-MM-dd")
                        : "";
                let params = {
                    current: that.filters.current,
                    size: that.filters.size,
                    businessId: that.filters.businessId,
                    couponName: that.filters.couponName,
                    couponType: that.filters.couponType,
                    startTime: that.filters.startTime,
                    endTime: that.filters.endTime,
                };
                that.loading = true;
                statisticsList(params).then((res) => {
                    that.loading = false;
                    that.tableData = res.data.data.records;
                    that.total = res.data.data.total;
                    that.filters.current = res.data.data.current;
                    that.filters.size = res.data.data.size;
                }).catch(error => {
                    that.loading = false;
                    console.log(error);
                });
            },
            getCouponBusinessList() {
                couponBusinessList().then((res) => {
                    this.couponBusinessData = res.data.data;
                }).catch(error => {
                    console.log(error);
                });
            },
            handleSizeChange(val) {
                this.filters.size = val;
                this.filters.current = 1;
                this.fetchData();
            },
            handleCurrentChange(val) {
                this.filters.current = val;
                this.fetchData();
            },
            getSummaries(param) {
                const {columns, data} = param;
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = '合计';
                        return;
                    } else if (index === 3) {
                        sums[index] = '';
                        return;
                    } else if (index > 3) {
                        const values = data.map(item => Number(item[column.property]));
                        if (!values.every(value => isNaN(value))) {
                            sums[index] = values.reduce((prev, curr) => {
                                const value = Number(curr);
                                if (!isNaN(value)) {
                                    return prev + curr;
                                } else {
                                    return prev;
                                }
                            }, 0);
                        } else {
                            sums[index] = '0';
                        }
                    }
                });
                return sums;
            },
            //选择导出类型
            handleExportExcel() {
                this.selectDialog = true;
                this.editForm.radio = "";
            },
            //导出列表
            onExportExcel(editForm) {
                this.$refs[editForm].validate((valid) => {
                    if (valid) {
                        this.$nextTick(function () {
                            let tables = document.querySelector("#out-table");
                            let table_book = this.$XLSX.utils.table_to_book(tables);
                            var table_write = this.$XLSX.write(table_book, {
                                bookType: this.editForm.radio,
                                bookSST: true,
                                type: "array"
                            });
                            try {
                                this.$FileSaver.saveAs(
                                    new Blob([table_write], {type: "application/octet-stream"}),
                                    "优惠统计列表."+this.editForm.radio
                                );
                            } catch (e) {
                                if (typeof console !== "undefined") console.log(e, table_write);
                            }
                            this.selectDialog = false;
                            return table_write;
                        });
                    } else {
                        return false;
                    }
                });
            }
        }
    }
</script>

<style scoped>
    .el-table th {
        text-align: center;
    }

    .el-table tbody tr td:first-child {
        text-align: center;
    }

    .user-search {
        margin-top: 20px;
    }

    .el-dialog__wrapper {
        overflow: hidden;
    }
</style>
