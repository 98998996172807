<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>人行管理</el-breadcrumb-item>
            <el-breadcrumb-item>人员通行记录</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 搜索筛选 -->
        <el-form :inline="true" :model="filters" class="user-search">
            <el-form-item>
                <el-input v-model="filters.areaName" clearable size="small" placeholder="请输入区域名称"
                          @keyup.enter.native="handleSearch"/>
            </el-form-item>
            <el-form-item>
                <el-input v-model="filters.personName" clearable size="small" placeholder="请输入人员名称"
                          @keyup.enter.native="handleSearch"/>
            </el-form-item>
            <el-form-item>
                <el-input v-model="filters.personPhone" clearable size="small" placeholder="请输入手机号码"
                          @keyup.enter.native="handleSearch"/>
            </el-form-item>
            <el-form-item>
                <el-select size="small" v-model="filters.personType" clearable filterable placeholder="请选择人员类型">
                    <el-option label="内部人员" value="1"></el-option>
                    <el-option label="访客" value="2"></el-option>
                    <el-option label="未知" value="3"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-date-picker size="small" v-model="date" type="datetimerange" range-separator="至"
                                start-placeholder="开始日期" end-placeholder="结束日期"
                                value-format="yyyy-MM-dd HH:mm:ss">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-search" type="primary" size="small" @click="handleSearch">查询</el-button>
                <el-button icon="el-icon-download" type="primary" size="small" @click="handleExportExcel">导出</el-button>
            </el-form-item>
        </el-form>
        <el-table
                id="out-table"
                v-loading="loading"
                element-loading-text="拼命加载中"
                size="small"
                :data="tableData"
                highlight-current-row
                border stripe
                style="width: 100%;"
                :row-style="{height:'45px'}"
                :cell-style="{padding:'0px'}"
                :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                height="500" max-height="500"
                :row-class-name="tableRowClassName">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column prop="personName" label="人员名称" align="center"></el-table-column>
            <el-table-column prop="personType" label="人员类型" align="center">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.personType != null"
                            :type="scope.row.personType == 1 ? '' : scope.row.personType == 2 ? 'success' : 'info'">
                        {{scope.row.personType == 1 ? '内部人员' : scope.row.personType == 2 ? '访客' :
                        scope.row.personType == 3 ? '未知' : ''}}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="temperature" label="体温(℃)" align="center">
                <template slot-scope="scope">
                    {{scope.row.temperature == 0 ? '-' : scope.row.temperature}}
                </template>
            </el-table-column>
            <el-table-column prop="antiepidemic" label="防疫状态" align="center"></el-table-column>
            <el-table-column prop="personPhone" label="手机号码" align="center"></el-table-column>
            <el-table-column prop="idCardNo" label="身份证号" align="center"></el-table-column>
            <el-table-column prop="areaName" label="区域名称" align="center"></el-table-column>
            <el-table-column prop="doorwayName" label="通道名称" align="center"></el-table-column>
            <el-table-column prop="deviceName" label="设备名称" align="center"></el-table-column>
            <el-table-column prop="direction" label="出入类型" align="center">
                <template slot-scope="scope">
                    {{scope.row.direction == 1 ? '入口' : scope.row.direction == 2 ? '出口' : ''}}
                </template>
            </el-table-column>
            <el-table-column prop="passageTime" label="通行时间" align="center"></el-table-column>
            <el-table-column prop="passageImg" label="通行照片" align="center">
                <template slot-scope="scope">
                    <el-image style="width: 50px; height: 50px"
                              :src="scope.row.passageImg?scope.row.passageImg:require('../../../assets/images/defaultImg.png')"
                              :preview-src-list="[scope.row.passageImg?scope.row.passageImg:require('../../../assets/images/defaultImg.png')]">
                        <div slot="error">
                            <el-image :src="require('../../../assets/images/defaultImg.png')">
                            </el-image>
                        </div>
                    </el-image>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="filters.current"
                       :page-sizes="[10, 50, 200, 1000]"
                       :page-size="filters.size"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
        <!--        选择导出文件类型-->
        <el-dialog v-draggable  title="文件类型" :visible.sync="selectDialog" width="250px">
            <el-form :model="editForm" ref="editForm" size="small" :rules="rules">
                <el-form-item prop="radio" label-width="50px">
                    <el-radio v-model="editForm.radio" label="xls">xls</el-radio>
                    <el-radio v-model="editForm.radio" label="xlsx">xlsx</el-radio>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="selectDialog = false">取 消</el-button>
        <el-button size="small" type="primary" @click="onExportExcel('editForm')">确 定</el-button>
        </span>
        </el-dialog>
    </div>
</template>

<script>
    import {getRecordPage} from "../../../api/organizationApi";

    export default {
        name: "personnelPassageRecord",
        data() {
            return {
                loading: false,
                total: 0,
                tableData: [],
                filters: {
                    areaName: '',
                    personName: '',
                    personPhone: '',
                    personType: '',
                    startTime: '',
                    endTime: '',
                    current: 1,
                    size: 10,
                },
                date: [],
                selectDialog: false,
                editForm: {
                    radio: "",
                },
                rules: {
                    radio: [
                        {required: true, message: '请选择导出类型', trigger: 'change'}
                    ],
                },
            }
        },
        mounted() {
            this.fetchData();
        },
        methods: {
            fetchData() {
                this.filters.startTime = this.date && this.date.length ? this.date[0] : "";
                this.filters.endTime = this.date && this.date.length ? this.date[1] : "";
                this.loading = true;
                getRecordPage(this.filters).then((res) => {
                    this.loading = false;
                    if (res.data.code == "SUCCESS") {
                        this.tableData = res.data.data.records;
                        this.total = res.data.data.total;
                        this.filters.current = res.data.data.current;
                        this.filters.size = res.data.data.size;
                    } else {
                        this.$message.info(res.data.message);
                    }
                })
            },
            handleSearch() {
                this.filters.current = 1;
                this.fetchData();
            },
            handleSizeChange(val) {
                this.filters.size = val;
                this.filters.current = 1;
                this.fetchData();
            },
            handleCurrentChange(val) {
                this.filters.current = val;
                this.fetchData();
            },
            tableRowClassName({row, rowIndex}) {
                if (row.tempAbnormal === true) {
                    return 'warning-row';
                }
                return '';
            },
            //选择导出类型
            handleExportExcel() {
                this.selectDialog = true;
                this.editForm.radio = "";
            },
            //导出列表
            onExportExcel(editForm) {
                this.$refs[editForm].validate((valid) => {
                    if (valid) {
                        this.$nextTick(function () {
                            let tables = document.querySelector("#out-table");
                            let table_book = this.$XLSX.utils.table_to_book(tables);
                            var table_write = this.$XLSX.write(table_book, {
                                bookType: this.editForm.radio,
                                bookSST: true,
                                type: "array"
                            });
                            try {
                                this.$FileSaver.saveAs(
                                    new Blob([table_write], {type: "application/octet-stream"}),
                                    "人员通行记录." + this.editForm.radio
                                );
                            } catch (e) {
                                if (typeof console !== "undefined") console.log(e, table_write);
                            }
                            this.selectDialog = false;
                            return table_write;
                        });
                    } else {
                        return false;
                    }
                });
            }
        }
    }
</script>

<style scoped>
    .user-search {
        margin-top: 15px;
    }

    /deep/ .el-table .warning-row td {
        background: #F8ECDA !important;
        color: red
    }
</style>
