import request from '@/utils/request'
import config from '@/config'

// 收入统计(优惠券)
export function coupon(data) {
    return request({
        url: `${config.bpi}/analysis/coupon`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}

// 收入统计(长租车)
export function longIncome(data) {
    return request({
        url: `${config.bpi}/analysis/longIncome`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}

// 收入统计(临停车)
export function tempIncome(data) {
    return request({
        url: `${config.bpi}/analysis/tempIncome`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}

// (进出流量分析)
export function passFlow(data) {
    return request({
        url: `${config.bpi}/analysis/passFlow`,
        method: 'get',
        params: {date:data},
        headers: {'showLoading': false}
    })
}
// 临停流量分析
export function tempFlow(data) {
    return request({
        url: `${config.bpi}/analysis/tempFlow`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}
// 长租流量分析
export function longFlow(data) {
    return request({
        url: `${config.bpi}/analysis/longFlow`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}
