<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>车场管理</el-breadcrumb-item>
            <el-breadcrumb-item>中央缴费</el-breadcrumb-item>
        </el-breadcrumb>
        <el-row :gutter="20">
            <el-col :span="10">
                <el-card class="box-card card">
                    <template #header>
                        <div class="clearfix" style="text-align: center;font-size: 18px;">
                            <span>进场图片</span>
                        </div>
                    </template>
                    <el-image style="height: 500px" :src="img ? img:require('../../../assets/images/defaultImg.png')"
                              :preview-src-list="[img ? img:require('../../../assets/images/defaultImg.png')]">
                    </el-image>
                </el-card>
            </el-col>
            <el-col :span="8">
                <el-card class="box-card">
                    <el-form :model="editForm" ref="editForm" size="small">
                        <el-form-item label="车牌号码" prop="" :label-width="formLabelWidth">
                            <el-input size="small" v-model="editForm.licensePlate" readonly/>
                        </el-form-item>
                        <el-form-item label="进场时间" prop="" :label-width="formLabelWidth">
                            <el-input size="small" v-model="editForm.entranceTime" readonly/>
                        </el-form-item>
                        <el-form-item label="停车时长" prop="" :label-width="formLabelWidth">
                            <el-input size="small" v-model="editForm.parkingDuration" readonly/>
                        </el-form-item>
                        <el-form-item label="优惠内容" prop="" :label-width="formLabelWidth">
                            <el-input size="small" v-model="editForm.couponType" readonly/>
                        </el-form-item>
                        <el-form-item label="费用减免" prop="" :label-width="formLabelWidth">
                            <el-input size="small" v-model="editForm.discountAmount" readonly/>
                        </el-form-item>
                        <el-form-item label="应收金额" prop="" :label-width="formLabelWidth">
                            <el-input size="small" v-model="editForm.amount" readonly/>
                        </el-form-item>
                        <el-form-item label="实收金额" prop="" :label-width="formLabelWidth">
                            <el-input size="small" v-model="editForm.realAmount" readonly/>
                        </el-form-item>
                        <!--                        <el-form-item label="备注说明" prop="" :label-width="formLabelWidth">-->
                        <!--                            <el-input type="textarea" size="small" v-model="editForm.materialName" placeholder="请输入内容"/>-->
                        <!--                        </el-form-item>-->
                        <el-form-item style="text-align: center" :label-width="formLabelWidth">
                            <!--                            <el-button type="primary">中心全免</el-button>-->
                            <el-button type="primary" @click="onSubmit">现金收费</el-button>
                            <!--                            <el-button>取 消</el-button>-->
                        </el-form-item>
                    </el-form>
                </el-card>
            </el-col>
            <el-col :span="6">
                <el-card class="box-card">
                    <template #header>
                        <div class="clearfix" style="text-align: center;font-size: 18px;">
                            <span>备选车辆</span>
                        </div>
                    </template>
                    <el-input size="small" v-model="licensePlate" placeholder="可查询车牌号码" clearable @input="handleSearch"
                              prefix-icon="el-icon-search" style="margin-bottom: 10px;"/>
                    <div class="list">
                        <div v-for="o in tableData" :key="o.id" class="text item">
                            <span @click="handleClick(o)">{{o.licensePlate}}</span>
                        </div>
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import {needCharge} from "../../../api/record/vehiclelist";
    import {chargeCenter, tempPayCenter} from "../../../api/bigscreen";
    import {getCurrentParking} from "../../../api/login";

    export default {
        name: "centralCharge",

        data() {
            return {
                licensePlate: "",
                formLabelWidth: '70px',
                editForm: {
                    id: "",
                    roadId: "",
                    licensePlate: "",
                    realAmount: "",
                    amount: "",
                    discountAmount: "",
                    parkingDuration: "",
                    entranceTime: "",
                    couponType: ""
                },
                tableData: [],
                parkingId: "",
                img: ""
            }
        },
        mounted() {
            this.licensePlate = this.$route.query.licensePlate ? this.$route.query.licensePlate : '';
            this.parkingId = this.$route.query.parkingId ? this.$route.query.parkingId : '';
            this.fetchData();  //调用接口获取动态数据
            this.getCurrentParking();
        },
        methods: {
            fetchData() { //获取数据
                let params = {
                    current: 1,
                    size: 1000000,
                    licensePlate: this.licensePlate,
                    parkingType: 1,
                };
                needCharge(params).then((res) => {
                    this.tableData = res.data.data.records;
                    if (this.$route.query.licensePlate) {
                        this.handleClick(this.tableData[0]);
                    }
                })
            },
            handleSearch() {
                this.fetchData();
            },
            getCurrentParking() {
                getCurrentParking().then((res) => {
                    this.parkingId = res.data.data.id;
                })
            },
            handleClick(item) {
                this.img = item.entranceImageUrl;
                let param = {
                    carNumber: item.licensePlate,
                    parkingId: this.parkingId
                };
                tempPayCenter(param).then(res => {
                    if (res.data.code === 'SUCCESS') {
                        this.editForm = res.data.data;
                        this.editForm.couponType = this.editForm.couponType === 1 ? '现金优惠' :
                            this.editForm.couponType === 2 ? '折扣优惠' :
                                this.editForm.couponType === 3 ? '全免优惠' : '';
                        // let hour = parseInt(this.editForm.parkingDuration / 60 % 24);
                        // let min = parseInt(this.editForm.parkingDuration % 60);
                        // console.log(hour + '' + min)
                        this.editForm.parkingDuration = this.editForm.parkingDuration ? Math.floor(this.editForm.parkingDuration / 60) + "小时" + (this.editForm.parkingDuration % 60) + "分钟" : 0;
                    }
                })
            },
            onSubmit() {
                if (this.editForm.id) {
                    this.$confirm("是否确定要收费?", "温馨提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    }).then(() => {
                        chargeCenter({tempOrderId: this.editForm.id}).then(res => {
                            if (res.data.code === 'SUCCESS') {
                                this.$message.success(res.data.message);
                            }
                        })
                    }).catch(() => {
                        this.$message.info("已取消!");
                    });
                } else {
                    this.$message.warning("请选择车牌号收费");
                }
            },
        }
    }
</script>

<style scoped>
    .el-row {
        margin-top: 30px;
    }

    .el-form {
        margin: 30px 0;
    }

    .card /deep/ .el-card__body {
        position: relative;
        top: 45%;
        transform: translateY(-50%);
    }

    .box-card {
        height: 600px;
    }

    .list {
        width: 100%;
        height: 475px;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .list :nth-child(even) {
        background-color: #fafafa;
    }

    .text {
        font-size: 16px;
    }

    .item {
        text-align: center;
        padding: 15px 0;
        cursor: pointer;
    }

</style>
