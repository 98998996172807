<template>
    <div>
        <el-button size="mini" icon="el-icon-plus" type="primary" @click="addChannel">添加通道</el-button>
        <el-table
                size="small"
                :data="treeData"
                highlight-current-row
                border stripe
                style="width: 100%;margin-top: 10px;"
                :row-style="{height:'45px'}"
                :cell-style="{padding:'0px'}"
                :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                height="500" max-height="500">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column prop="name" label="通道名称" align="center"></el-table-column>
            <el-table-column prop="doorwayNo" label="通道编号" align="center"></el-table-column>
            <el-table-column prop="type" label="通道类型" align="center">
                <template slot-scope="scope">
                    <el-tag v-show="scope.row.doorwayDirection != null"
                            :type="scope.row.doorwayDirection == 1 ? '' : 'success'">
                        {{scope.row.doorwayDirection == 1 ? '入口' : scope.row.doorwayDirection == 2 ? '出口' : ''}}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="deviceName" label="通道设备" align="center"></el-table-column>
            <el-table-column prop="deviceStatus" label="通道设备状态" align="center">
                <template slot-scope="scope">
                    <el-tag v-show="scope.row.deviceStatus != null" :type="scope.row.deviceStatus == 1 ? '' : 'info'">
                        {{scope.row.deviceStatus == 1 ? '在线' : scope.row.deviceStatus == 2 ? '离线' : ''}}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="deviceType" label="通道设备类型" align="center">
                <template slot-scope="scope">
                    <el-tag v-show="scope.row.deviceType != null" :type="scope.row.deviceType == 4 ? '' : 'warning'">
                        {{scope.row.deviceType == 4 ? '人脸机' : scope.row.deviceType == 5 ?'门禁控制器' : ''}}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column width="300" align="center" label="操作">
                <template slot-scope="scope">
                    <el-button size="mini" @click="handleEdit(scope.$index, scope.row)" icon="el-icon-setting"
                               type="primary">编辑
                    </el-button>
                    <el-button size="mini" type="danger" class="el-icon-delete"
                               @click="handleDelete(scope.$index, scope.row)">删除
                    </el-button>
                    <el-button size="mini" @click="handleRemoteOpening(scope.$index, scope.row)" type="warning">
                        远程开闸
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <!--    通道管理-->
        <el-dialog v-draggable :title="title" :visible.sync="visibleDialog">
            <el-form :model="visibleForm" ref="visibleForm" size="small" :rules="rules">
                <el-row>
                    <el-col :span="11">
                        <el-form-item label="通道名称" prop="doorwayName" :label-width="labelWidth">
                            <el-input size="small" v-model="visibleForm.doorwayName" placeholder="请输入通道名称"/>
                        </el-form-item>
                        <el-form-item label="通道编号" prop="doorwayNo" :label-width="labelWidth">
                            <el-input disabled size="small" v-model="visibleForm.doorwayNo" placeholder="通道编号"/>
                        </el-form-item>
                        <el-form-item label="设备编号" :label-width="labelWidth">
                            <el-input readonly size="small" v-model="visibleForm.value1" placeholder="设备编号"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11">
                        <el-form-item label="通道类型" prop="direction" :label-width="labelWidth">
                            <el-select size="small" v-model="visibleForm.direction" placeholder="请选择通道类型">
                                <el-option label="入口" :value="1"></el-option>
                                <el-option label="出口" :value="2"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="设备名称" prop="deviceName" :label-width="labelWidth">
                            <el-select value-key="deviceName" v-model="visibleForm.deviceName" placeholder="请选择设备名称"
                                       @change="handleChange">
                                <el-option v-for="item in deviceData" :key="item.deviceName" :label="item.deviceName"
                                           :value="item"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="设备类型" :label-width="labelWidth">
                            <el-input readonly size="small" v-if="visibleForm.value2 == 4" value="人脸机"/>
                            <el-input readonly size="small" v-else-if="visibleForm.value2 == 5" value="门禁控制器"/>
                            <el-input readonly size="small" v-else placeholder="设备类型"/>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="visibleDialog = false">取 消</el-button>
                <el-button type="primary" @click="onSubmit('visibleForm')">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog v-draggable title="删除通道" :visible.sync="dialogVisible " width="35%">
            <div style="display: flex;flex-direction:column;justify-content: center;align-items: center;line-height: 40px;">
                <p style="font-size: 16px;">确定要删除通道吗？</p>

                <el-checkbox v-model="checked">是否强制删除授权数据
                    （不管设备授权是否撤销成功都删除授权数据）
                </el-checkbox>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="dialogVisible  = false">取 消</el-button>
                <el-button size="small" type="primary" @click="confirm()">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import {AddDoorway, delDoorway, EditDoorway} from "../../../../api/spaceApi";
    import {getUnboundList, openGate} from "../../../../api/equipmentApi";

    export default {
        name: "second",
        props: {
            areaId: [Number, String],
            treeData: Array,
        },
        data() {
            return {
                title: "",
                visibleDialog: false,
                dialogVisible: false,
                checked: false,
                visibleForm: {
                    doorwayName: "",
                    direction: "",
                    doorwayNo: "",
                    deviceName: "",
                    value1: "",
                    value2: "",
                },
                deviceId: "",
                rules: {
                    doorwayName: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ],
                    // deviceName: [
                    //     {required: true, message: '请选择', trigger: 'change'},
                    // ],
                    direction: [
                        {required: true, message: '请选择', trigger: 'change'},
                    ],
                },
                labelWidth: '120px',
                deviceData: [],
            }
        },
        methods: {
            handleGetUnboundList() {
                getUnboundList().then(res => {
                    this.deviceData = res.data.data;
                })
            },
            handleChange(row) {
                if (row == null) {
                    return;
                }
                this.deviceId = row.id;
                this.visibleForm.deviceName = row.deviceName;
                this.visibleForm.value1 = row.deviceNo;
                this.visibleForm.value2 = row.type;
            },
            //添加通道
            addChannel() {
                this.title = "通道管理";
                this.visibleDialog = true;
                Object.keys(this.visibleForm).map(key => {
                    this.visibleForm[key] = '';
                });
                this.deviceName = "";
                this.handleGetUnboundList();
            },
            //编辑
            handleEdit(index, row) {
                this.title = "编辑通道管理";
                this.visibleDialog = true;
                this.visibleForm.id = row.id;
                this.visibleForm.doorwayName = row.name;
                this.deviceId = row.deviceId;
                this.visibleForm.deviceName = row.deviceName;
                this.visibleForm.direction = row.doorwayDirection;
                this.visibleForm.doorwayNo = row.doorwayNo;
                this.visibleForm.value1 = row.deviceNo;
                this.visibleForm.value2 = row.deviceType;
                this.handleGetUnboundList();
            },
            onSubmit(visibleForm) {
                this.$refs[visibleForm].validate((valid) => {
                    if (valid) {
                        let params = {
                            doorwayName: this.visibleForm.doorwayName,
                            deviceId: this.deviceId,
                            direction: this.visibleForm.direction,
                            id: this.visibleForm.id,
                            areaId: this.areaId
                        }
                        if (this.visibleForm.id) {
                            EditDoorway(params).then((res) => {
                                this.visibleDialog = false;
                                if (res.data.code == "SUCCESS") {
                                    this.$emit('handleTreeData', 'second', this.areaId);
                                    this.$message.success("编辑成功！");
                                } else {
                                    this.$message.info(res.data.message);
                                }
                            })
                        } else {
                            AddDoorway(params).then((res) => {
                                this.visibleDialog = false;
                                if (res.data.code == "SUCCESS") {
                                    this.$emit('handleTreeData', 'second', this.areaId);
                                    this.$message.success("保存成功！");
                                } else {
                                    this.$message.info(res.data.message);
                                }
                            })
                        }
                    }
                })
            },
            //删除
            handleDelete(index, row) {
                this.dialogVisible = true;
                this.visibleForm.id = row.id;
            },
            confirm() {
                const loading = this.$loading({
                    lock: true,
                    text: '删除中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                delDoorway(this.visibleForm.id, this.checked).then((res) => {
                    loading.close();
                    if (res.data.code == "SUCCESS") {
                        this.dialogVisible = false;
                        this.$emit('handleTreeData', 'second', this.areaId);
                        this.$message.success("删除成功！");
                    } else {
                        this.$message.info(res.data.message);
                    }
                })
            },
            //远程开闸
            handleRemoteOpening(index, row) {
                this.$confirm("确定要远程开闸吗?", "温馨提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    openGate(row.deviceId).then((res) => {
                        if (res.data.code == "SUCCESS") {
                            this.$emit('handleTreeData', 'second', this.areaId);
                            this.$message.success(res.data.message);
                        } else {
                            this.$message.info(res.data.message);
                        }
                    })
                }).catch(() => {
                    this.$message.info("已取消!");
                });

            }
        }
    }
</script>

<style scoped>

</style>
