<template>
    <div  class="context" v-show="show">
        <img src="../assets/images/not_found.png">
        <p>{{msg}}</p>
        <div>
            <el-button type="primary" plain @click="handleBack">返 回</el-button>
        </div>
    </div>
</template>

<script>
    import {getCurrentProject, userLogin} from "../api/login";

    export default {
        name: "dashboard",

        data() {
            return {
                show: false,
                msg: "",
                projectType: []
            }
        },
        mounted() {
            let param = {
                username: this.$route.query.username,
                sign: this.$route.query.sign,
                app_id: this.$route.query.app_id,
                // username: "xjwy",
                // sign: "e97fd82e31648eabe73b8d9feb85c507",
                // app_id: "FTUPrTKS",
            }
            userLogin(param).then((res) => {
                this.show = false;
                sessionStorage.setItem('outside', 'outside');
                localStorage.setItem('token', res.data.data.token);
                localStorage.setItem('userdata', JSON.stringify(res.data.data.customerDTO));
                getCurrentProject().then((res) => {
                    window.localStorage.setItem('projectName', res.data.data.projectName);
                    window.localStorage.setItem('projectId', res.data.data.id);
                    let projectType = res.data.data.projectType;
                    this.projectType = [];
                    projectType.forEach((e) => {
                        this.projectType.push(e.type);
                        if (e.type == 1) {
                            window.localStorage.setItem('subSysId', e.subSysId);
                        }
                    })
                    window.localStorage.setItem('projectType1', this.projectType.filter(item => item === 1).toString());
                    window.localStorage.setItem('projectType2', this.projectType.filter(item => item === 2).toString());
                    if (this.projectType.filter(item => item === 1).toString() == 1) {
                        this.$router.push({path: '/parkingLot/homePage'});
                        return;
                    } else if (this.projectType.filter(item => item === 2).toString() == 2) {
                        this.$router.push({path: '/personnel/indexPage'});
                        return;
                    }
                })
            }).catch((err) => {
                console.log(err)
                this.show = true;
                // this.msg = res.data.message;
            });
        },
        methods: {
            handleBack() {
                this.$router.back();
            }
        }
    }
</script>

<style scoped>
    html, body {
        height: 100%;
    }

    #app {
        height: 100%;
        margin-top: 0;
    }

    .context {
        position: absolute;
        top: 20%;
        width: 100%;
        text-align: center;
    }

    .context img {
        max-width: 100%;
        max-height: 100%;
    }

    .context p {
        margin: 20px;
        font-size: 16px;
        color: #0d3349;
    }
</style>
