<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>雷达测速</el-breadcrumb-item>
            <el-breadcrumb-item>测速设备管理</el-breadcrumb-item>
        </el-breadcrumb>
        <el-button style="margin: 15px 0" icon="el-icon-plus" type="primary" size="small" @click="handleAdd">添加
        </el-button>
        <el-button style="margin: 15px 10px" type="primary" size="small" @click="handleRefresh">刷 新</el-button>

        <!--列表-->
        <el-table
                size="small"
                :data="listData"
                highlight-current-row
                v-loading="loading"
                element-loading-text="拼命加载中"
                border stripe
                style="width: 100%;"
                :row-style="{height:'45px'}"
                :cell-style="{padding:'0px'}"
                :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                height="500" max-height="500">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column prop="deviceName" label="设备名称" align="center"></el-table-column>
            <el-table-column prop="deviceNo" label="设备编号" align="center"></el-table-column>
            <el-table-column prop="deviceSn" label="设备序列号" align="center"></el-table-column>
            <el-table-column prop="deviceType" label="设备类型" align="center">
                <template slot-scope="scope">
                    {{scope.row.deviceType === 11 ? '测速相机' : ''}}
                </template>
            </el-table-column>
            <el-table-column prop="status" label="设备状态" align="center">
                <template slot-scope="scope">
                    <el-tag type="primary" v-if="scope.row.status === 1">在线</el-tag>
                    <el-tag type="info" v-if="scope.row.status === 2">离线</el-tag>
                </template>
            </el-table-column>
            <el-table-column width="260" align="center" label="操作">
                <template slot-scope="scope">
                    <el-button size="mini" type="primary" icon="el-icon-edit-outline"
                               @click="handleEdit(scope.row)">编辑
                    </el-button>
                    <el-button size="mini" type="danger" class="el-icon-delete"
                               @click="handleDelete(scope.row)">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog v-draggable  title="测速设备管理" :visible.sync="visibleDialog" width="40%">
            <el-form :model="visibleForm" ref="visibleForm" size="small" :rules="rules" label-width="100px">
                <el-form-item label="设备名称" prop="deviceName">
                    <el-input v-model="visibleForm.deviceName" placeholder="请输入设备名称"/>
                </el-form-item>
                <el-form-item label="设备序列号" prop="deviceSn">
                    <el-input style="width: 100%" placeholder="请输入设备序列号" v-model="visibleForm.deviceSn"/>
                </el-form-item>
                <el-form-item label="设备编号" prop="deviceNo">
                    <el-input style="width: 100%" v-model="visibleForm.deviceNo" disabled/>
                </el-form-item>
                <el-form-item label="设备IP" prop="innerIp">
                    <el-input v-model="visibleForm.innerIp" placeholder="请输入设备IP"/>
                </el-form-item>
                <!--                <el-form-item label="相机类型" prop="">-->
                <!--                    <el-select size="small" v-model="visibleForm.value3" placeholder="请选择相机类型">-->
                <!--                        <el-option label="Q款" value="0"></el-option>-->
                <!--                        <el-option label="Z款" value="1"></el-option>-->
                <!--                    </el-select>-->
                <!--                </el-form-item>-->
                <!--                <el-form-item label="相机SN" prop="">-->
                <!--                    <el-input size="small" v-model="visibleForm.deviceNo"/>-->
                <!--                </el-form-item>-->
                <!--                <el-form-item label="相机IP" prop="">-->
                <!--                    <el-input size="small" v-model="visibleForm.status"/>-->
                <!--                </el-form-item>-->
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="visibleDialog = false">取 消</el-button>
                <el-button type="primary" @click="submitForm('visibleForm')">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import {addSpeedDevice, delSpeedDevice, editSpeedPDevice, getSpeedDevice} from "../../api/speed";

    export default {
        name: "deviceManagement",
        data() {
            return {
                loading: false,
                //数据

                visibleDialog: false,
                visibleForm: {
                    id: "",
                    deviceName: "",
                    deviceSn: "",
                    deviceNo: "",
                    innerIp: ""
                },
                rules: {
                    deviceName: [{required: true, message: "请输入", trigger: "blur"}],
                    innerIp: [{required: true, message: "请输入", trigger: "blur"}]
                },
            }
        },
        mounted() {
            this.fetchData();
        },
        methods: {
            fetchData() {
                this.loading = true;
                getSpeedDevice().then(res => {
                    this.loading = false;
                    if (res.data.code == "SUCCESS") {
                        this.listData = res.data.data;
                    }
                })
            },
            handleRefresh() {
                this.fetchData();
            },
            handleAdd() {
                this.visibleDialog = true;
                Object.keys(this.visibleForm).map(key => {
                    this.visibleForm[key] = '';
                })
            },
            handleEdit(row) {
                this.visibleDialog = true;
                Object.keys(this.visibleForm).map(key => {
                    this.visibleForm[key] = row[key];
                });
            },
            submitForm(visibleForm) {
                this.$refs[visibleForm].validate((valid) => {
                    if (valid) {
                        let param = {
                            id: this.visibleForm.id,
                            deviceName: this.visibleForm.deviceName,
                            deviceSn: this.visibleForm.deviceSn,
                            innerIp: this.visibleForm.innerIp,
                            deviceType: 11
                        }
                        if (this.visibleForm.id) {
                            editSpeedPDevice(param).then(res => {
                                this.visibleDialog = false;
                                this.fetchData();
                                if (res.data.code == "SUCCESS") {
                                    this.$message.success("编辑成功！");
                                } else {
                                    this.$message.info(res.data.message);
                                }
                            })
                        } else {
                            addSpeedDevice(param).then(res => {
                                this.visibleDialog = false;
                                this.fetchData();
                                if (res.data.code == "SUCCESS") {
                                    this.$message.success("保存成功！");
                                } else {
                                    this.$message.info(res.data.message);
                                }
                            })
                        }
                    }
                })
            },
            handleDelete(row) {
                this.$confirm("是否确认删除数据?", "温馨提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    delSpeedDevice(row.id).then(res => {
                        this.fetchData();
                        if (res.data.code == "SUCCESS") {
                            this.$message.success("删除成功！");
                        } else {
                            this.$message.info(res.data.message);
                        }
                    })
                })

            }
        }
    }
</script>

<style scoped>

</style>
