<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right" style="margin-bottom: 10px">
            <el-breadcrumb-item>统计分析</el-breadcrumb-item>
            <el-breadcrumb-item>进出流量分析</el-breadcrumb-item>
        </el-breadcrumb>
        <el-row :gutter="20">
            <el-col :span="24">
                <el-card header="星期流量分析" style="font-size: 15px;">
                    <el-form ref="filters" :inline="true" label-position="right">
                        <el-form-item label="请选择日期" label-width="100px">
                            <el-date-picker
                                    size="mini"
                                    id="getWeekData"
                                    v-model="weekData"
                                    type="week"
                                    :picker-options="{'firstDayOfWeek': 1}"
                                    format="yyyy 第 WW 周"
                                    placeholder="选择周">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-search" size="mini" @click="search()">查询</el-button>
                        </el-form-item>
                    </el-form>
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <div ref="myEchart1" style="height: 280px;">
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <div ref="myEchart2" style="height: 280px;">
                            </div>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :span="24">
                <el-card header="分时段流量分析" style="font-size: 15px;margin-top: 10px">
                    <el-form ref="filters" :inline="true" label-position="right">
                        <el-form-item label="请选择日期" label-width="100px">
                            <el-date-picker
                                    size="mini"
                                    v-model="date"
                                    type="date"
                                    placeholder="选择日期"
                                    format="yyyy 年 MM 月 dd 日"
                                    value-format="yyyy-MM-dd"
                                    :clearable="false">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-searcemptyQueryh" size="mini"
                                       @click="querySearch()">查询
                            </el-button>
                        </el-form-item>
                    </el-form>
                    <div ref="myEchart3" style="height: 300px;">
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import * as echarts from "echarts";
    import {passFlow, tempFlow, longFlow} from "../../../api/statistics";
    import {formatDate} from "../../../utils";

    export default {
        name: "flowAnalysis",
        data() {
            return {
                weekData: null,
                weekDataNow: null,
                weekTime: "",
                year: "",
                date: formatDate(new Date(), "yyyy-MM-dd"),
            }
        },
        watch: {
            weekData() {
                if (this.weekData != null) {
                    this.$nextTick(() => {
                        this.weekDataNow = document.querySelector("#getWeekData").value;
                        this.year = this.weekDataNow.substr(0,4);
                        let str2 = this.weekDataNow.substr(6,3);//截取从第6个开始往后数3位之间的字符
                        this.weekTime = str2.trim();
                    });
                }
            }
        },

        mounted() {
            this.$nextTick(() => {
                this.initChart1();
                this.initChart2();
                this.initChart3();
            });
        },
        created() {
            this.getDay();
        },
        methods: {
            querySearch() {
                this.initChart3();
            },
            initChart1() {
                let year;
                if(this.year != ""){
                    year = this.year
                }else{
                    year = formatDate(new Date(), "yyyy")
                }
                let param = {
                    year: year,
                    week: this.weekTime
                }
                tempFlow(param).then((res) => {
                    let xAxisData = [];
                    let seriesData1 = [];
                    let seriesData2 = [];
                    if (res.data.data) {
                        res.data.data.tempAppearance.forEach(e => {
                            xAxisData.push('周' + e.time);
                            seriesData1.push(e.value);
                        });
                        res.data.data.tempEntrance.forEach(e => {
                            seriesData2.push(e.value);
                        });
                    }
                    let myChart = echarts.init(this.$refs.myEchart1);
                    let option = {
                        tooltip: {
                            trigger: 'axis'
                        },
                        legend: {
                            data: ['临停入场', '临停出场']
                        },
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '3%',
                            containLabel: true
                        },
                        xAxis: {
                            type: 'category',
                            boundaryGap: false,
                            data: xAxisData
                        },
                        yAxis: {
                            type: 'value'
                        },
                        series: [
                            {
                                name: '临停入场',
                                type: 'line',
                                data: seriesData2
                            },
                            {
                                name: '临停出场',
                                type: 'line',
                                data: seriesData1
                            }
                        ]
                    };
                    myChart.setOption(option);
                })
            },
            initChart2() {
                let year;
                if(this.year != ""){
                    year = this.year
                }else{
                    year = formatDate(new Date(), "yyyy")
                }
                let param = {
                    year: year,
                    week: this.weekTime
                }
                longFlow(param).then((res) => {
                    let xAxisData = [];
                    let seriesData1 = [];
                    let seriesData2 = [];
                    if (res.data.data) {
                        res.data.data.longAppearance.forEach(e => {
                            xAxisData.push('周' + e.time);
                            seriesData1.push(e.value);
                        });
                        res.data.data.longEntrance.forEach(e => {
                            seriesData2.push(e.value);
                        });
                    }
                    let myChart = echarts.init(this.$refs.myEchart2);
                    let option = {
                        tooltip: {
                            trigger: 'axis'
                        },
                        legend: {
                            data: ['长租入场', '长租出场']
                        },
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '3%',
                            containLabel: true
                        },
                        xAxis: {
                            type: 'category',
                            boundaryGap: false,
                            data: xAxisData
                        },
                        yAxis: {
                            type: 'value'
                        },
                        series: [
                            {
                                name: '长租入场',
                                type: 'line',
                                data: seriesData2
                            },
                            {
                                name: '长租出场',
                                type: 'line',
                                data: seriesData1
                            }
                        ]
                    };
                    myChart.setOption(option);
                })
            },
            initChart3() {
                passFlow(this.date).then((res) => {
                    let xAxisData = [];
                    let seriesData1 = [];
                    let seriesData2 = [];
                    let seriesData3 = [];
                    let seriesData4 = [];
                    if (res.data.data) {
                        res.data.data.tempAppearance.forEach(e => {
                            xAxisData.push(e.time);
                            seriesData1.push(e.value);
                        });
                        res.data.data.tempEntrance.forEach(e => {
                            seriesData2.push(e.value);
                        });
                        res.data.data.longAppearance.forEach(e => {
                            seriesData3.push(e.value);
                        });
                        res.data.data.longEntrance.forEach(e => {
                            seriesData4.push(e.value);
                        });
                    }
                    let myChart = echarts.init(this.$refs.myEchart3);
                    let option = {
                        title: {
                            // text: '折线图堆叠'
                        },
                        tooltip: {
                            trigger: 'axis'
                        },
                        legend: {
                            data: ['临停入场', '临停出场', '长租入场', '长租出场']
                        },
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '3%',
                            containLabel: true
                        },
                        toolbox: {
                            feature: {
                                saveAsImage: {}
                            }
                        },
                        xAxis: {
                            type: 'category',
                            boundaryGap: false,
                            data: xAxisData
                        },
                        yAxis: {
                            type: 'value'
                        },
                        series: [
                            {
                                name: '临停入场',
                                type: 'line',
                                smooth: true,
                                //修改折线的颜色
                                itemStyle: {
                                    color: '#c23531'
                                },
                                data: seriesData2
                            },
                            {
                                name: '临停出场',
                                type: 'line',
                                smooth: true,
                                //修改折线的颜色
                                itemStyle: {
                                    color: '#2f4554'
                                },
                                data: seriesData1
                            },
                            {
                                name: '长租入场',
                                type: 'line',
                                smooth: true,
                                //修改折线的颜色
                                itemStyle: {
                                    color: '#61a0a8'
                                },
                                data: seriesData4
                            },
                            {
                                name: '长租出场',
                                type: 'line',
                                smooth: true,
                                //修改折线的颜色
                                itemStyle: {
                                    color: '#d48265'
                                },
                                data: seriesData3
                            }
                        ]
                    };
                    myChart.setOption(option);
                })
            },
            search() {
                this.initChart1();
                this.initChart2();
            },
            // 初始化日期
            getDay() {
                var date = new Date();
                var year = date.getFullYear();
                var month = date.getMonth() + 1;
                var day = date.getDate();
                if (month < 10) {
                    month = '0' + month;
                }
                if (day < 10) {
                    day = '0' + day;
                }
                this.weekData = year + '-' + month + '-' + day;
                if (this.weekData != null) {
                    this.$nextTick(() => {
                        this.weekDataNow = document.querySelector("#getWeekData").value;
                        // console.log('week当前的值', this.weekDataNow)
                        this.year = this.weekDataNow.substr(0,4);
                        let str2 = this.weekDataNow.substr(6,3);//截取从第6个开始往后数3位之间的字符
                        this.weekTime = str2.trim()
                        // console.log('weekTime当前的值',this.year,str2)
                    });
                }
            },

        },
    }
</script>

<style scoped>
    .user-search {
        margin-top: 10px;
    }
</style>
