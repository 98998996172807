<template>
    <div>
        <div style="margin-bottom: 10px">
            <span>缴费金额：{{totalPay}}元，</span>
            <span>有效车辆：{{effectiveNum}}</span>
        </div>
        <!--列表-->
        <el-table
                size="small"
                :data="tableData"
                highlight-current-row
                border
                v-loading="loading"
                element-loading-text="拼命加载中"
                style="width: 100%"
                :row-style="{height:'45px'}"
                :cell-style="{padding:'0px'}"
                :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                stripe
                height="500"
                max-height="500"
        >
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column prop="date" label="日期" align="center"></el-table-column>
            <el-table-column prop="payMoney" label="缴费金额(元)" align="center"></el-table-column>
            <el-table-column prop="effective" label="有效车辆" align="center"></el-table-column>
        </el-table>
    </div>
</template>

<script>
    import {longIncome} from "../../../api/statistics";
    import {formatDate, isNumber} from "../../../utils";

    export default {
        name: "longRent",
        data() {
            return {
                loading: false,
                tableData: [],
                effectiveNum: "",
                totalPay: "",
                months: "",
            }
        },
        props: {
            month: [Number, String],
        },
        watch: {
            month(val) {
                this.months = val
            }
        },
        created() {
            this.months = this.month
        },
        mounted() {
            this.getLongIncomeData();
        },
        methods: {
            getLongIncomeData() { //获取数据
                let that = this;
                that.loading = true;
                let param = {
                    year: formatDate(this.months, "yyyy"),
                    month: formatDate(this.months, "MM")
                }
                longIncome(param).then(function (res) {
                    that.loading = false;
                    that.tableData = res.data.data.incomeList;
                    that.effectiveNum = isNumber(res.data.data.effectiveNum);
                    that.totalPay = isNumber(res.data.data.totalPay);
                }).catch(function (error) {
                    that.loading = false;
                    console.log(error);
                });
            },
        }
    }
</script>

<style scoped>

</style>
