<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>访客管理</el-breadcrumb-item>
            <el-breadcrumb-item>访客列表</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 搜索筛选 -->
        <el-form :inline="true" :model="filters" class="user-search">
            <el-form-item style="width: 11%;">
                <el-input v-model="filters.visitorName" size="small" clearable placeholder="访客姓名"
                          @keyup.enter.native="handleSearch"/>
            </el-form-item>
            <el-form-item style="width: 12%;">
                <el-input v-model="filters.visitorPhone" size="small" clearable placeholder="访客手机号码"
                          @keyup.enter.native="handleSearch"/>
            </el-form-item>
            <el-form-item style="width: 13%;">
                <el-select size="small" v-model="filters.status" clearable filterable placeholder="访问申请状态">
                    <el-option label="待审核" value="1"></el-option>
                    <el-option label="已通过" value="2"></el-option>
                    <el-option label="已驳回" value="3"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item style="width: 11%;">
                <el-input v-model="filters.visiteeName" size="small" clearable placeholder="被访人姓名"
                          @keyup.enter.native="handleSearch"/>
            </el-form-item>
            <el-form-item style="width: 11%;">
                <el-input v-model="filters.visitorLicensePlate" size="small" clearable placeholder="车牌号"
                          @keyup.enter.native="handleSearch"/>
            </el-form-item>
            <el-form-item>
                <el-date-picker size="small" v-model="date" type="daterange" range-separator="至"
                                start-placeholder="开始日期" end-placeholder="结束日期"
                                value-format="yyyy-MM-dd">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-search" type="primary" size="small" @click="handleSearch">查询</el-button>
                <el-button size="mini" icon="el-icon-plus" type="primary" @click="handleAdd">添加</el-button>
                <el-button type="primary" size="small" @click="handleCode">访客二维码</el-button>
            </el-form-item>
        </el-form>
        <el-table
                v-loading="loading"
                element-loading-text="拼命加载中"
                size="small"
                :data="tableData"
                highlight-current-row
                border stripe
                style="width: 100%;"
                :row-style="{height:'45px'}"
                :cell-style="{padding:'0px'}"
                :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                height="500" max-height="500">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column prop="visitorName" label="姓名" align="center"></el-table-column>
            <el-table-column prop="status" label="状态" align="center">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.status != null"
                            :type="scope.row.status == 1 ? '' : scope.row.status == 2 ? 'success' : 'info'">
                        {{scope.row.status == 1 ? '待审核' : scope.row.status == 2 ? '已通过' : scope.row.status == 3 ? '已驳回'
                        : ''}}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="visitorAvatar" label="头像" align="center">
                <template slot-scope="scope">
                    <el-image
                            style="width: 50px; height: 50px"
                            :src="scope.row.visitorAvatar?scope.row.visitorAvatar:require('../../../assets/images/defaultImg.png')"
                            :preview-src-list="[scope.row.visitorAvatar?scope.row.visitorAvatar:require('../../../assets/images/defaultImg.png')]">
                        <div slot="error">
                            <el-image :src="require('../../../assets/images/defaultImg.png')">
                            </el-image>
                        </div>
                    </el-image>
                </template>
            </el-table-column>
            <el-table-column prop="visitorPhone" label="手机号码" align="center"></el-table-column>
            <el-table-column prop="visitorLicensePlate" label="车牌号码" align="center"></el-table-column>
            <el-table-column prop="startTime" label="来访起始时间" align="center"></el-table-column>
            <el-table-column prop="endTime" label="来访截止时间" align="center"></el-table-column>
            <el-table-column prop="visiteeName" label="被访人" align="center"></el-table-column>
            <el-table-column prop="visitReason" label="来访事由" align="center"></el-table-column>
            <el-table-column prop="rejectReason" label="拒绝理由" align="center"></el-table-column>
            <el-table-column width="250" align="center" label="操作">
                <template slot-scope="scope">
                    <el-button size="mini" @click="handleEdit(scope.$index, scope.row)" icon="el-icon-setting"
                               type="primary">编 辑
                    </el-button>
                    <el-button v-if="scope.row.status == 1" size="mini" @click="handleAudit(scope.$index, scope.row)"
                               type="warning">审 核
                    </el-button>
                    <el-button size="mini" type="danger" class="el-icon-delete"
                               @click="handleDelete(scope.$index, scope.row)">删 除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="filters.current"
                       :page-sizes="[10, 50, 200, 1000]"
                       :page-size="filters.size"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
        <!-- 生成二维码的弹窗 -->
        <div class="dialog_div">
            <el-dialog :visible.sync="QRcodeDialogVisible" top="0" width="40%" align="center">
                <div class="dialogqr">
                    <div class="qrimg">
                        <div class="qrcode" id="qrcode"></div>
                        <div class="qrtext">
                            <span style="font-size: 14px">【{{projectName}}】</span>
                        </div>
                    </div>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import {deleteVisitor, editProject, getVisitorList} from "../../../api/visitorMachine";
    import {getCurrentProject} from "../../../api/login";
    import config from "../../../config";
    import QRCode from "qrcodejs2";

    export default {
        name: "visitorManagement",
        data() {
            return {
                loading: false,
                total: 0,
                tableData: [],
                filters: {
                    visitorName: '',
                    visitorPhone: '',
                    status: '1',
                    visiteeName: '',
                    visitorLicensePlate: '',
                    startTime: '',
                    endTime: '',
                    current: 1,
                    size: 10,
                },
                date: [],
                timer: null,
                videoWidth: 400,
                videoHeight: 300,
                imgSrc: "",
                thisCancas: null,
                thisContext: null,
                thisVideo: null,
                img: "",
                QRcodeDialogVisible: false,
                projectName: localStorage.getItem('projectName')
            }
        },
        mounted() {
            this.fetchData();
            this.timer = setInterval(() => {
                this.fetchData();
            }, 60000)
            var image = process.env.NODE_ENV === "production" ? this.prodImg : this.testImg;
            this.getPosterPic(this.visitorImg, image);
        },
        beforeDestroy() {
            clearInterval(this.timer)
        },
        methods: {
            fetchData() {
                this.filters.startTime = this.date && this.date.length ? this.date[0] : "";
                this.filters.endTime = this.date && this.date.length ? this.date[1] : "";
                this.loading = true;
                getVisitorList(this.filters).then((res) => {
                    this.loading = false;
                    if (res.data.code == "SUCCESS") {
                        this.tableData = res.data.data.records;
                        this.total = res.data.data.total;
                        this.filters.current = res.data.data.current;
                        this.filters.size = res.data.data.size;
                    } else {
                        this.$message.info(res.data.message);
                    }
                })
            },
            handleSearch() {
                this.filters.current = 1;
                this.fetchData();
            },
            handleSizeChange(val) {
                this.filters.size = val;
                this.filters.current = 1;
                this.fetchData();
            },
            handleCurrentChange(val) {
                this.filters.current = val;
                this.fetchData();
            },
            //访客二维码
            handleCode() {
                this.QRcodeDialogVisible = true;
                this.$nextTick(() => {
                    let qrcodeEL = document.getElementById("qrcode");
                    let qrcode = new QRCode(qrcodeEL);
                    document.getElementById("qrcode").innerHTML = "";
                    this.GetQRcode(localStorage.getItem('projectId'), localStorage.getItem('projectName'), localStorage.getItem('projectType1'), localStorage.getItem('projectType2'))
                })
            },
            //  生成二维码
            GetQRcode(id, name, type1, type2) {
                var url = process.env.NODE_ENV === "production" ? "http://pc.rlinking.com/#/visitor?parkingId=" + id + "&projectName=" + name + "&type1=" + type1 + "&type2=" + type2
                    : "http://passage-user-test.rlinking.com/#/visitor?parkingId=" + id + "&projectName=" + name + "&type1=" + type1 + "&type2=" + type2;
                var qrcode = new QRCode('qrcode', {
                    text: url,       //  需要生成二维码的内容
                    width: 240,
                    height: 240,
                    colorDark: '#000000',
                    colorLight: '#ffffff',
                    correctLevel: QRCode.CorrectLevel.H
                });
            },
            //添加
            handleAdd() {
                let title = "添加";
                this.$router.push({path: 'addVisitors', query: {title: title}});
            },
            //编辑
            handleEdit(index, row) {
                let title = "编辑";
                this.$router.push({path: 'addVisitors', query: {title: title, data: encodeURI(JSON.stringify(row))}});
            },
            handleAudit(index, row) {
                let title = "审核";
                this.$router.push({path: 'auditVisitors', query: {title: title, data: encodeURI(JSON.stringify(row))}})
            },
            //删除
            handleDelete(index, row) {
                this.$confirm("确定要删除访客信息吗?", "温馨提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    deleteVisitor(row.id).then((res) => {
                        if (res.data.code == "SUCCESS") {
                            this.fetchData();
                            this.$message.success("删除成功！");
                        } else {
                            this.$message.info(res.data.message);
                        }
                    })
                }).catch(() => {
                    this.$message.info("已取消删除!");
                });
            },
            getPosterPic(picUrl, qrUrl) {
                let that = this
                var canvas = document.createElement('canvas')
                var myImage = new Image()
                myImage.src = picUrl //背景图片
                myImage.crossOrigin = 'Anonymous'
                myImage.onload = function () {
                    canvas.width = myImage.width
                    canvas.height = myImage.height
                    var context = canvas.getContext('2d')
                    context.rect(0, 0, canvas.width, canvas.height)
                    context.fillStyle = '#fff'
                    context.fill()
                    context.drawImage(myImage, 0, 0, myImage.width, myImage.height)
                    var myImage2 = new Image()
                    myImage2.src = qrUrl //二维码图片
                    myImage2.crossOrigin = 'Anonymous'
                    myImage2.onload = function () {
                        let x = 920 // myImage.width - myImage2.width - 34
                        let y = 1550 // myImage.height - myImage2.height - 10
                        context.drawImage(myImage2, x, y, 2850, 2850)
                        that.convertCanvasToImage(canvas)
                    }
                }
            },
            convertCanvasToImage(canvas) {
                this.imgSrc = canvas.toDataURL("image/jpeg");
            },
        }
    }
</script>

<style scoped lang="scss">
    .user-search {
        margin-top: 15px;
    }

    .dialog_div {
        ::v-deep .el-dialog__wrapper {
            .el-dialog {
                background-color: unset !important;
                box-shadow: unset !important;

                .el-dialog__header {
                    background-color: unset !important;
                }

                .el-dialog__headerbtn .el-dialog__close {
                    color: #fff;
                    font-size: 30px;
                }

                .el-dialog__headerbtn .el-dialog__close:hover {
                    color: gray;
                }
            }
        }
    }

    .dialogqr {
        width: 400px;
        height: 610px;
    }

    .dialogqr .qrimg {
        height: 620px;
        border-radius: 16px;
        background-size: 400px 620px;
        background-repeat: no-repeat;
        /* 把背景图片固定住 */
        /*background-attachment: fixed;*/
        background-image: url("../../../assets/images/20231023.jpg");
    }

    .dialogqr .qrimg .qrcode {
        padding-top: 138px;
    }

    .dialogqr .qrimg .qrtext {
        padding: 35px 0 0 0;
        display: flex;
        flex-direction: row;
        color: #fae5e3;
        justify-content: center;
        line-height: 20px;
    }

    .qrtext span {
        font-size: 12px;
    }
</style>
