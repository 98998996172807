<template>
    <div>
        <el-table
                size="small"
                :data="treeData"
                highlight-current-row
                border stripe
                style="width: 100%;margin-top: 10px;"
                :row-style="{height:'45px'}"
                :cell-style="{padding:'0px'}"
                :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                height="500" max-height="500">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column prop="name" label="通道名称" align="center"></el-table-column>
            <el-table-column prop="doorwayNo" label="通道编号" align="center"></el-table-column>
            <el-table-column prop="type" label="通道类型" align="center">
                <template slot-scope="scope">
                    <el-tag v-show="scope.row.doorwayDirection != null" :type="scope.row.doorwayDirection == 1 ? '' : 'success'">
                        {{scope.row.doorwayDirection == 1 ? '入口' : scope.row.doorwayDirection == 2 ? '出口' : ''}}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="deviceName" label="通道设备" align="center"></el-table-column>
            <el-table-column prop="deviceStatus" label="通道设备状态" align="center">
                <template slot-scope="scope">
                    <el-tag v-show="scope.row.deviceStatus != null" :type="scope.row.deviceStatus == 1 ? '' : 'info'">
                        {{scope.row.deviceStatus == 1 ? '在线' : scope.row.deviceStatus == 2 ? '离线' : ''}}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="deviceType" label="通道设备类型" align="center">
                <template slot-scope="scope">
                    <el-tag v-show="scope.row.deviceType != null" :type="scope.row.deviceType == 4 ? '' : 'warning'">
                        {{scope.row.deviceType == 4 ? '人脸机' : scope.row.deviceType == 5 ?'门禁控制器' : ''}}
                    </el-tag>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
    export default {
        name: "third",
        props: {
            treeData: Array,
        },
    };
</script>

<style scoped>
</style>
