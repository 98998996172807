import request from '@/utils/request'
import config from '@/config'

// 访客列表
export function getVisitorList(data) {
    return request({
        url: `${config.api}/visitor/list`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}

// 新增访客
export function addAppointment(data) {
    return request({
        url: `${config.api}/visitor/appointment`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 修改访客
export function updateVisitor(data) {
    return request({
        url: `${config.api}/visitor`,
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 删除访客
export function deleteVisitor(id) {
    return request({
        url: `${config.api}/visitor`,
        method: 'delete',
        params: {requestId: id},
    })
}

//查询人员信息
export function queryUserList(data) {
    return request({
        url: `${config.cpi}/user/search`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}

//审核访客
export function examineVisitor(data) {
    return request({
        url: `${config.api}/visitor/verify`,
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 访客机列表
export function getDeviceVisitorList(data) {
    return request({
        url: `${config.api}/device/visitor/list`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}

// 新增访客机
export function saveVisitor(data) {
    return request({
        url: `${config.api}/device/visitor`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 修改访客机
export function editVisitor(data) {
    return request({
        url: `${config.api}/device/visitor`,
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

//删除访客机
export function delVisitor(id) {
    return request({
        url: `${config.api}/device/visitor`,
        method: 'delete',
        params: {id: id},
    })
}

// 修改缺省通道设置
export function editDefault(data) {
    return request({
        url: `${config.api}/visitor/doorway/default`,
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 修改项目
export function editProject(data) {
    return request({
        url: `${config.api}/project`,
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 访客设置时间端
export function getAvailableList(data) {
    return request({
        url: `${config.api}/visitor/period/available/list`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}

export function saveAvailable(data) {
    return request({
        url: `${config.api}/visitor/period/available`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}
//删除
export function delAvailable(id) {
    return request({
        url: `${config.api}/visitor/period/available`,
        method: 'delete',
        params: {id: id},
    })
}
