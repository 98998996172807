import request from '@/utils/request'
import config from '@/config'

// 长租车区域列表
export function longCarAreaList() {
    return request({
        url: `${config.bpi}/parking/area/customer`,
        method: 'get',
        headers: {'showLoading': false}
    })
}

// 长租费率列表
export function longCarRateList(areaId) {
    return request({
        url: `${config.bpi}/longCar/rate`,
        method: 'get',
        params: {areaId: areaId},
        headers: {'showLoading': false}
    })
}

// 会员车费率列表
export function rateMemberArea(areaId) {
    return request({
        url: `${config.bpi}/rate/member/area`,
        method: 'get',
        params: {areaId: areaId},
        headers: {'showLoading': false}
    })
}

// 费率列表
export function rateMemberParking(parkingId) {
    return request({
        url: `${config.bpi}/rate/member/parking`,
        method: 'get',
        params: {parkingId: parkingId},
        headers: {'showLoading': false}
    })
}

//车位列表
export function parkingDataList(id) {
    return request({
        url: `${config.bpi}/longCar/place/area`,
        method: 'get',
        params: {areaId: id},
        headers: {'showLoading': false}
    })
}

// 长租车列表
export function longCarList(data) {
    return request({
        url: `${config.bpi}/longCar`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}

// 新增(修改)长租车
export function longCarSave(data) {
    return request({
        url: `${config.bpi}/longCar`,
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

//删除长租车
export function longCarDel(id) {
    return request({
        url: `${config.bpi}/longCar`,
        method: 'delete',
        params: {id: id},
    })
}

//删除长租车(id,只删除车辆信息)
export function longCarIdDel(id) {
    return request({
        url: `${config.bpi}/longCar/vehicle`,
        method: 'delete',
        params: {id: id},
    })
}

// 退费长租车
export function longCarRefund(data) {
    return request({
        url: `${config.bpi}/longCar/refund`,
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 续费长租车
export function longCarRenewal(data) {
    return request({
        url: `${config.bpi}/longCar/renewal`,
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 转让长租车
export function longCarTransfer(data) {
    return request({
        url: `${config.bpi}/longCar/transfer`,
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

//删除车位信息
export function longCarRateDel(id) {
    return request({
        url: `${config.bpi}/longCar/charge`,
        method: 'delete',
        params: {id: id},
    })
}

//下载导入模板
export function longCarDownTemplate() {
    return request({
        url: `${config.bpi}/longCar/import/model`,
        method: 'get',
        responseType: 'blob',
        headers: {'showLoading': false}
    })
}

//导入
export function longCarImportExcel(file) {
    return request({
        url: `${config.bpi}/longCar/import`,
        method: 'post',
        data: file,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
}

//黑名单列表
export function blackList(data) {
    return request({
        url: `${config.bpi}/blackList`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}

// 添加黑名单
export function blackListSave(data) {
    return request({
        url: `${config.bpi}/blackList`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

//删除黑名单
export function blackListDel(ids) {
    return request({
        url: `${config.bpi}/blackList`,
        method: 'delete',
        params: {ids: ids}
    })
}

//同步白名单
export function updateWhiteList(data) {
    return request({
        url: `${config.bpi}/longCar/whitelist/sync`,
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

//获取设备
export function equipmentList(data) {
    return request({
        url: `${config.bpi}/equipment/parking`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}
