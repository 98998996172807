import request from '@/utils/request'
import config from '@/config'

// 无人值守列表
export function peopleList() {
    return request({
        url: `${config.bpi}/unattended/people`,
        method: 'get',
        headers: {'showLoading': false}
    })
}
// 二维码
export function unattendedBindPeopleQrCode() {
    return request({
        url: `${config.bpi}/unattended/qrCode`,
        method: 'get',
        headers: {'showLoading': false}
    })
}
// 通过审核
export function passPeople(userId) {
    return request({
        url: `${config.bpi}/unattended/people/pass?userId=`+userId,
        method: 'put',
    })
}
// 解除绑定
export function repealPeople(userId) {
    return request({
        url: `${config.bpi}/unattended/people/repeal?userId=`+userId,
        method: 'put',
    })
}
