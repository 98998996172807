import request from '@/utils/request'
import config from '@/config'

// 运营情况
export function operateInfo(parkingId) {
    return request({
        url: `${config.bpi}/parkingData/operateInfo`,
        method: 'get',
        params: {parkingId: parkingId},
        headers: {'showLoading': false}
    })
}

// 流量走势
export function flowTrend(data) {
    return request({
        url: `${config.bpi}/parkingData/flowTrend`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}

// 收费走势
export function chargeTrend(data) {
    return request({
        url: `${config.bpi}/parkingData/chargeTrend`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}

//人行首页统计
export function personnelStatistics() {
    return request({
        url: `${config.cpi}/statistics/home`,
        method: 'get',
        headers: {'showLoading': false}
    })
}

//测温首页统计
export function temperatureStatistics() {
    return request({
        url: `${config.cpi}/statistics/measure/temperature`,
        method: 'get',
        headers: {'showLoading': false}
    })
}

//测温记录
export function temperatureRecord(data) {
    return request({
        url: `${config.cpi}/measure/temperature/record`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}

//监控大屏—道口设备
export function equipmentList(data) {
    return request({
        url: `${config.bpi}/parking/road/equipment/list`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}
