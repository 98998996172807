<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>记录查询</el-breadcrumb-item>
            <el-breadcrumb-item>在场车辆</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 搜索筛选 -->
        <el-form :inline="true" :model="filters" class="user-search">
            <el-form-item>
                <el-input size="small" v-model="filters.licensePlate" clearable placeholder="请输入车牌号码"
                          @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
            <el-form-item>
                <el-select size="small" v-model="filters.parkingType" clearable filterable placeholder="请选择停车类型">
                    <el-option label="临停" value="1"></el-option>
                    <el-option label="长租" value="2"></el-option>
                    <el-option label="会员" value="3"></el-option>
                    <el-option label="长租过期会员" value="4"></el-option>
                    <el-option label="长租超员" value="5"></el-option>
                    <el-option label="储值" value="6"></el-option>
                    <el-option label="预约" value="7"></el-option>
                    <el-option label="长租过期临停" value="8"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-search" type="primary" size="small" @click="handleSearch">查询</el-button>
                <el-button type="primary" size="small" @click="handleBatch">批量出场</el-button>
            </el-form-item>
        </el-form>
        <!--列表-->
        <el-table
                size="small"
                :data="tableData"
                highlight-current-row
                border
                v-loading="loading"
                element-loading-text="拼命加载中"
                style="width: 100%"
                :default-sort="{prop: 'entranceTime', order: 'descending'}"
                :row-style="{height:'45px'}"
                :cell-style="{padding:'0px'}"
                :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                stripe height="500" max-height="500">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column prop="licensePlate" label="车牌号码" align="center">
                <template slot-scope="scope">
                    <span>{{scope.row.licensePlate?scope.row.licensePlate:'无牌车'}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="rfidNo" label="卡号" align="center"></el-table-column>
            <el-table-column prop="entranceTime" label="入场时间" align="center"></el-table-column>
            <el-table-column prop="parkingTime" label="停车时长(分钟)" align="center" :formatter="dateFormat">
            </el-table-column>
            <el-table-column prop="parkingType" label="停车类型" align="center">
                <template slot-scope="scope">
                    <el-tag type="primary" v-if="scope.row.parkingType == 1">临停</el-tag>
                    <el-tag type="success" v-else-if="scope.row.parkingType == 2">长租</el-tag>
                    <el-tag type="warning" v-else-if="scope.row.parkingType == 3">会员</el-tag>
                    <el-tag type="info" v-else-if="scope.row.parkingType == 4 || scope.row.parkingType == 8">长租过期
                    </el-tag>
                    <el-tag type="danger" v-else-if="scope.row.parkingType == 5">长租超员</el-tag>
                    <el-tag type="danger" v-else-if="scope.row.parkingType == 6">储值</el-tag>
                    <el-tag type="danger" v-else-if="scope.row.parkingType == 7">预约</el-tag>
                </template>
            </el-table-column>
            <!--            <el-table-column prop="payMoney" label="应付金额(元)"></el-table-column>-->
            <el-table-column prop="entranceImageUrl" label="入场图片" align="center">
                <template slot-scope="scope">
                    <el-image
                            style="width: 50px; height: 50px"
                            :src="scope.row.entranceImageUrl?scope.row.entranceImageUrl:require('../../../assets/images/defaultImg.png')"
                            :preview-src-list="[scope.row.entranceImageUrl?scope.row.entranceImageUrl:require('../../../assets/images/defaultImg.png')]">
                        <div slot="error">
                            <el-image :src="require('../../../assets/images/defaultImg.png')">
                            </el-image>
                        </div>
                    </el-image>
                    <!--                    <span @click="handleEdit(scope.row)" style="color: #2a6496">查看</span>-->
                </template>
            </el-table-column>
            <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                    <el-button size="mini" @click="handleDelete(scope.$index, scope.row)"
                               type="danger">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="filters.current"
                       :page-sizes="[10, 50, 200, 1000]"
                       :page-size="filters.size"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
        <el-dialog v-draggable title="批量出场" :visible.sync="visibleDialog">

            <el-form size="small" :model="form" ref="form" :rules="rules" label-width="220px">
                <el-form-item label="入场起止时间" prop="value">
                    <el-date-picker
                            v-model="form.value"
                            type="datetimerange"
                            range-separator="至"
                            start-placeholder="起始时间"
                            end-placeholder="截止时间"
                            value-format="yyyy-MM-dd HH:mm:ss">
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <div style="margin-left: 120px;color: red;font-weight: bold;line-height: 25px;font-size: 16px;">
                注意：该操作没有进一步确认，确定后会将输入时间段内的进场车辆<br/>
                全部置为出场状态，影响相关车辆的缴费，请谨慎操作！
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="visibleDialog = false">取 消</el-button>
              <el-button type="primary" @click="submitForm('form')">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import {presentCar, presentCarDel} from "@/api/record/vehiclelist";
    import {formatDate} from '@/utils/index.js'
    import {leaveBatch} from "../../../api/record/vehiclelist";

    export default {
        name: "equipment",
        data() {
            return {
                loading: false,
                tableData: [],
                filters: {
                    current: 1,
                    size: 10,
                    licensePlate: '',
                    parkingType: '',
                    startTime: '',
                    endTime: '',
                },
                total: 0,
                visibleDialog: false,
                form: {
                    value: []
                },
                rules: {
                    value: [{required: true, message: "请选择", trigger: "change"}],
                },
            }
        },
        mounted() {
            this.fetchData();  //调用接口获取动态数据
        },
        methods: {
            fetchData() { //获取数据
                let that = this;
                let params = {
                    current: that.filters.current,
                    size: that.filters.size,
                    licensePlate: that.filters.licensePlate,
                    parkingType: that.filters.parkingType,
                };
                that.loading = true;
                presentCar(params).then((res) => {
                    that.loading = false;
                    that.tableData = res.data.data.records;
                    that.total = res.data.data.total;
                    that.filters.current = res.data.data.current;
                    that.filters.size = res.data.data.size;
                }).catch(error => {
                    that.loading = false;
                    console.log(error);
                });
            },
            handleSizeChange(val) {
                this.filters.size = val;
                this.filters.current = 1;
                this.fetchData();
            },
            handleCurrentChange(val) {
                this.filters.current = val;
                this.fetchData();
            },
            handleSearch() {
                this.filters.current = 1;
                this.fetchData();
            },
            handleDelete(index, row) {
                this.$confirm('是否确认删除在场车辆记录?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    presentCarDel(row.id).then((res) => {
                        this.fetchData();
                        if (res.data.code == "SUCCESS") {
                            this.$message.success(res.data.message);
                        } else {
                            this.$message.info(res.data.message);
                        }
                    })
                }).catch(() => {
                    this.$message.info('已取消!');
                });
            },
            handleBatch() {
                this.form = [];
                this.visibleDialog = true;
            },
            submitForm(form) {
                this.$refs[form].validate((valid) => {
                    if (valid) {
                        let param = {
                            entranceStartTime: this.form.value[0],
                            entranceEndTime: this.form.value[1]
                        }
                        leaveBatch(param).then((res) => {
                            this.visibleDialog = false;
                            if (res.data.code == "SUCCESS") {
                                this.fetchData();
                                this.$message.success(res.data.message);
                            } else {
                                this.$message.info(res.data.message);
                            }
                        }).catch((err) => {
                            this.visibleDialog = false;
                        });
                    }
                })
            },
            handleEdit(row) {
                this.$alert('<img width="100%" src="' + row.entranceImageUrl + '"/>', {
                    dangerouslyUseHTMLString: true,
                    showConfirmButton: false,
                    closeOnClickModal: true,
                    customClass: "msgbox",
                }).catch(() => {
                });
            },
            //停车时长
            dateFormat(row) {
                //定义两个变量time1,time2分别保存当前和入场时间
                var time1 = this.dayjs(row.entranceTime).format('YYYY-MM-DD HH:mm:ss');
                var time2 = this.dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss');
                //截取字符串，得到日期部分"2021-10-14",用split把字符串分隔成数组
                var begin1 = time1.substr(0, 10).split("-");
                var end1 = time2.substr(0, 10).split("-");
                //将拆分的数组重新组合，并实例成化新的日期对象
                var date1 = new Date(begin1[1] + -+begin1[2] + -+begin1[0]);
                var date2 = new Date(end1[1] + -+end1[2] + -+end1[0]);
                //得到两个日期之间的差值m，以分钟为单位
                // Math.abs(date2-date1)计算出以毫秒为单位的差值
                // Math.abs(date2-date1)/1000得到以秒为单位的差值
                // Math.abs(date2-date1)/1000/60得到以分钟为单位的差值
                var m = parseInt(Math.abs(date2 - date1) / 1000 / 60);
                //小时数和分钟数相加得到总的分钟数
                //time1.substr(11,2)截取字符串得到时间的小时数
                // parseInt(time1.substr(11,2))*60把小时数转化成为分钟
                var min1 = parseInt(time1.substr(11, 2)) * 60 + parseInt(time1.substr(14, 2));
                var min2 = parseInt(time2.substr(11, 2)) * 60 + parseInt(time2.substr(14, 2));
                //两个分钟数相减得到时间部分的差值，以分钟为单位
                var n = min2 - min1;
                //将日期和时间两个部分计算出来的差值相加，即得到两个时间相减后的分钟数
                var minutes = m + n;
                var nn = Number(minutes);
                //2.验证是否为有效数字
                if (!isNaN(nn)) {
                    //有效数字
                    if (nn > 0) {
                        return nn;
                    } else if (nn < 0) {
                        return 0;
                    } else {
                        return 0;
                    }
                }
            }
        }
    }
</script>

<style scoped>
    .el-table th {
        text-align: center;
    }

    .el-table tbody tr td:first-child {
        text-align: center;
    }

    .user-search {
        margin-top: 20px;
    }

    .el-dialog__wrapper {
        overflow: hidden;
    }
</style>
