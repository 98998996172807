<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>商家优惠</el-breadcrumb-item>
            <el-breadcrumb-item>优惠管理</el-breadcrumb-item>
        </el-breadcrumb>
        <!--    <div style="float: right">当前车场:xxx停车场</div>-->
        <!-- 搜索筛选 -->
        <el-form :inline="true" :model="filters" class="user-search">
            <el-form-item>
                <el-select size="small" v-model="filters.businessId" clearable filterable placeholder="请选择商家">
                    <el-option v-for="item in couponBusinessData" :key="item.id" :label="item.businessName"
                               :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-select size="small" v-model="filters.couponType" clearable filterable placeholder="请选择优惠类型">
                    <el-option label="现金券" value="1"></el-option>
                    <el-option label="折扣券" value="2"></el-option>
                    <el-option label="全免" value="3"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-select size="small" v-model="filters.status" clearable filterable placeholder="请选择状态">
                    <el-option label="待使用" value="0"></el-option>
                    <el-option label="使用中" value="1"></el-option>
                    <el-option label="已停用" value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-input size="small" v-model="filters.couponName" placeholder="请输入优惠名称"
                          @keyup.enter.native="fetchData"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input size="small" v-model="filters.modelName" placeholder="请输入模板名称"
                          @keyup.enter.native="fetchData"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-search" type="primary" size="small" @click="fetchData">查询</el-button>
                <el-button icon="el-icon-plus" type="primary" size="small" @click="handleAdd">添加</el-button>
            </el-form-item>
        </el-form>
        <!--列表-->
        <el-table
                size="small"
                :data="tableData"
                highlight-current-row
                v-loading="loading"
                element-loading-text="拼命加载中"
                border
                style="width: 100%"
                :row-style="{height:'45px'}"
                :cell-style="{padding:'0px'}"
                :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                stripe
                height="500"
                max-height="500"
                :default-sort="{prop: 'createTime', order: 'descending'}">
            <el-table-column min-width="30" type="index" label="序号"></el-table-column>
            <el-table-column prop="businessName" label="商家名称" align="center"></el-table-column>
            <el-table-column prop="modelName" label="模板名称" align="center"></el-table-column>
            <el-table-column prop="couponName" label="优惠名称" align="center"></el-table-column>
            <el-table-column prop="giveOutNum" label="总数量" align="center"></el-table-column>
            <el-table-column prop="" label="剩余数量" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.giveOutNum - scope.row.businessUseNum }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="businessUseNum" label="使用数量" align="center"></el-table-column>
            <el-table-column prop="price" label="单价(元)" align="center"></el-table-column>
            <el-table-column prop="startEffectiveTime" label="有效期起始" align="center"></el-table-column>
            <el-table-column prop="endEffectiveTime" label="有效期截止" align="center"></el-table-column>
            <el-table-column prop="status" label="状态" align="center">
                <template slot-scope="scope">
                    <el-tag type="success" v-if="scope.row.status == 0">待启用</el-tag>
                    <el-tag type="primary" v-if="scope.row.status == 1">使用中</el-tag>
                    <el-tag type="danger" v-else-if="scope.row.status == 2">已停用</el-tag>
                    <el-tag type="info" v-else-if="scope.row.status == 3">已过期</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="creator" label="创建人" align="center"></el-table-column>
            <el-table-column min-width="110" prop="createTime" label="创建时间" align="center"></el-table-column>
            <el-table-column width="120" align="center" label="操作">
                <template slot-scope="scope">
                    <!--          <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"-->
                    <!--                     type="primary">通过 </el-button >-->
                    <el-button v-if="scope.row.status == 1 || scope.row.status == 0"
                               size="mini"
                               type="danger"
                               @click="handleDeactivate(scope.$index, scope.row.id)">停用
                    </el-button>
                    <el-button v-if="scope.row.status == 2"
                               size="mini"
                               type="primary"
                               @click="handleEnable(scope.$index, scope.row.id)">启用
                    </el-button>
                    <el-button v-if="scope.row.status == 3"
                               size="mini"
                               type="info">启用
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="filters.current"
                       :page-sizes="[10, 50, 200, 1000]"
                       :page-size="filters.size"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
        <el-dialog v-draggable  :title="title" :visible.sync="addDialog">
            <el-form :model="editForm" ref="editForm" size="small" :rules="rules">
                <el-row :gutter="24">
                    <el-col :span="20">
                        <el-form-item label="优惠名称" prop="couponName" :label-width="formLabelWidth">
                            <el-input size="small" v-model="editForm.couponName	" placeholder="请输入内容"/>
                        </el-form-item>
                        <el-form-item label="优惠模板" prop="modelName" :label-width="formLabelWidth">
                            <el-select @change="changeModelName" style="width: 100%" size="small"
                                       v-model="editForm.modelName" clearable placeholder="请选择商家">
                                <el-option v-for="item in couponModelData" :key="item.id" :label="item.name"
                                           :value="item.name"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="发放张数" prop="giveOutNum" :label-width="formLabelWidth">
                            <el-input size="small" oninput="value=value.replace(/[^\d]/g,'')"
                                      v-model.number="editForm.giveOutNum" placeholder="请输入内容"/>
                        </el-form-item>
                        <el-form-item label="商家" prop="businessName" :label-width="formLabelWidth">
                            <el-select @change="changeBusinessName" style="width: 100%" size="small"
                                       v-model="editForm.businessName" clearable placeholder="请选择商家">
                                <el-option v-for="item in couponBusinessData" :key="item.id" :label="item.businessName"
                                           :value="item.businessName"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="单价(元)" prop="price" :label-width="formLabelWidth">
                            <el-input v-model="editForm.price" oninput="value=value.replace(/[^0-9.]/g,'')"
                                      placeholder="请输入单价"/>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="有效日期" required :label-width="formLabelWidth">
                    <el-col :span="10">
                        <el-form-item prop="startEffectiveTime">
                            <el-date-picker type="date" placeholder="选择开始日期"
                                            value-format="yyyy-MM-dd" v-model="editForm.startEffectiveTime"
                                            style="width: 100%;"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col style="text-align: center;" :span="1">-</el-col>
                    <el-col :span="10">
                        <el-form-item prop="endEffectiveTime">
                            <el-date-picker type="date" placeholder="选择结束日期"
                                            value-format="yyyy-MM-dd" v-model="editForm.endEffectiveTime"
                                            style="width: 100%;"></el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button @click="addDialog = false">取 消</el-button>
        <el-button type="primary" @click="confirm('editForm')">确 定</el-button>
      </span>
        </el-dialog>
    </div>
</template>
<!---->
<script>
    import {
        couponList,
        couponBusinessSave,
        couponBusinessList,
        modelList,
        couponStatus
    } from "@/api/merchantDiscount/coupon";
    import {formatDate} from "@/utils";

    export default {
        name: "preferential",
        data() {
            const validateMoney = (rule, value, callback) => {
                if (!value) {
                    callback(new Error('单价不能为空'))
                } else if (value.indexOf(".") != -1 && value.split('.').length > 2) {
                    callback(new Error('请输入正确格式的金额')) //防止输入多个小数点
                } else {
                    callback();
                }
                // else if (value.indexOf(".") != -1 && value.split('.')[1].length > 2) {
                //     callback(new Error('请输入正确的小数位数')) //小数点后两位
                // }
            };
            return {
                loading: false,
                total: 0,
                tableData: [],
                filters: {
                    businessId: '',
                    couponName: '',
                    couponType: '',
                    modelName: '',
                    status: '',
                    current: 1,
                    size: 10,
                },
                formLabelWidth: '120px',
                addDialog: false,
                title: "",
                editForm: {
                    businessId: "",
                    businessName: "",
                    couponId: "",
                    couponName: "",
                    couponType: "",
                    endEffectiveTime: "",
                    startEffectiveTime: "",
                    id: "",
                    modelName: "",
                    price: "",
                    giveOutNum: "",
                    preferential: "",
                },
                rules: {
                    couponName: [{required: true, message: "请输入", trigger: "blur"}],
                    businessName: [{required: true, message: "请输入", trigger: "blur"}],
                    price: [
                        {type: 'string', required: true, trigger: 'blur', validator: validateMoney},
                    ],
                    giveOutNum: [{required: true, message: "请输入", trigger: "blur"},
                        {type: 'number', message: '须为数字值'}],
                    modelName: [{required: true, message: "请选择", trigger: "change"}],
                    endEffectiveTime: [
                        {required: true, message: '请选择日期', trigger: 'change'}
                    ],
                    startEffectiveTime: [
                        {required: true, message: '请选择日期', trigger: 'change'}
                    ],
                },
                date: [],
                couponBusinessData: [],
                couponModelData: [],
            }
        },
        mounted() {
            this.fetchData();  //调用接口获取动态数据
            this.getCouponBusinessList();
            this.getCouponModelList();
        },
        methods: {
            fetchData() { //获取数据
                let that = this;
                let params = {
                    current: that.filters.current,
                    size: that.filters.size,
                    businessId: that.filters.businessId,
                    couponName: that.filters.couponName,
                    couponType: that.filters.couponType,
                    modelName: that.filters.modelName,
                    status: that.filters.status,
                };
                that.loading = true;
                couponList(params).then((res) => {
                    that.loading = false;
                    that.tableData = res.data.data.records;
                    that.total = res.data.data.total;
                    that.filters.current = res.data.data.current;
                    that.filters.size = res.data.data.size;
                }).catch(error => {
                    that.loading = false;
                    console.log(error);
                });
            },
            handleSizeChange(val) {
                this.filters.size = val;
                this.filters.current = 1;
                this.fetchData();
            },
            handleCurrentChange(val) {
                this.filters.current = val;
                this.fetchData();
            },
            handleAdd() {
                this.title = '优惠管理';
                this.addDialog = true;
                Object.keys(this.editForm).map(key => {
                    this.editForm[key] = '';
                })
                this.date = [];
            },
            changeBusinessName(e) {
                this.getCouponBusinessList(e);
            },
            getCouponBusinessList(name) {
                couponBusinessList().then((res) => {
                    this.couponBusinessData = res.data.data;
                    this.couponBusinessData.forEach((element) => {
                        if (element.businessName == name) {
                            this.editForm.businessId = element.id;
                            this.editForm.businessName = element.businessName;
                            return;
                        }
                    })
                }).catch(error => {
                    console.log(error);
                });
            },
            changeModelName(e) {
                this.getCouponModelList(e);
            },
            getCouponModelList(name) {
                modelList().then((res) => {
                    this.couponModelData = res.data.data;
                    this.couponModelData.forEach((element) => {
                        if (element.name == name) {
                            this.editForm.couponId = element.id;
                            this.editForm.couponType = element.type;
                            this.editForm.preferential = element.preferential;
                            return;
                        }
                    })
                }).catch(error => {
                    console.log(error);
                });
            },
            //添加
            confirm(editForm) {
                this.$refs[editForm].validate((valid) => {
                    if (valid) {
                        let param = {
                            id: this.editForm.id,
                            businessName: this.editForm.businessName,
                            businessId: this.editForm.businessId,
                            couponId: this.editForm.couponId,
                            couponName: this.editForm.couponName,
                            couponType: this.editForm.couponType,
                            endEffectiveTime: this.editForm.endEffectiveTime,
                            startEffectiveTime: this.editForm.startEffectiveTime,
                            modelName: this.editForm.modelName,
                            price: this.editForm.price,
                            giveOutNum: this.editForm.giveOutNum,
                            preferential: this.editForm.preferential
                        }
                        couponBusinessSave(param).then((res) => {
                            this.addDialog = false;
                            this.fetchData();
                            if (res.data.code == "SUCCESS") {
                                this.$message.success("保存成功！");
                            } else {
                                this.$message.info(res.data.message);
                            }
                        })
                    }
                })
            },
            handleDeactivate(index, id) {
                console.log(id)
                this.$confirm('是否确认停用?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let status = 2;
                    couponStatus(id, status).then((res) => {
                        this.fetchData();
                        if (res.data.code == "SUCCESS") {
                            this.$message.success("更改成功！");
                        } else {
                            this.$message.info(res.data.message);
                        }
                    })
                }).catch(() => {
                    this.$message.info('已取消');
                });
            },
            handleEnable(index, id) {
                this.$confirm('是否确认启用?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let status = 1;
                    couponStatus(id, status).then((res) => {
                        this.fetchData();
                        if (res.data.code == "SUCCESS") {
                            this.$message.success("更改成功！");
                        } else {
                            this.$message.info(res.data.message);
                        }
                    })
                }).catch(() => {
                    this.$message.info('已取消');
                });
            },
        }
    }
</script>

<style scoped>
    .el-table th {
        text-align: center;
    }

    .el-table tbody tr td:first-child {
        text-align: center;
    }

    .user-search {
        margin-top: 20px;
    }

    .el-dialog__wrapper {
        overflow: hidden;
    }
</style>
