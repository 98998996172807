<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>车辆管理</el-breadcrumb-item>
            <el-breadcrumb-item>储值车管理</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 搜索筛选 -->
        <el-form :inline="true" :model="filters" class="user-search">
            <el-row>
                <el-form-item>
                    <el-select size="mini" v-model="filters.carProperty" clearable filterable placeholder="请选择车辆属性">
                        <el-option label="小车" value="1"></el-option>
                        <el-option label="大车" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input size="small" v-model="filters.licensePlate" clearable placeholder="请输入车牌号码"
                              @keyup.enter.native="handleSearch"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input size="small" v-model="filters.carOwnerPhone" clearable placeholder="请输入车主手机号"
                              @keyup.enter.native="handleSearch"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input size="small" v-model="filters.carOwnerName" clearable placeholder="请输入车主姓名"
                              @keyup.enter.native="handleSearch"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input size="small" v-model="filters.rfidNo" clearable placeholder="请输入RFID卡号"
                              @keyup.enter.native="handleSearch"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item>
                    <el-input size="small" v-model="filters.etcNo" clearable placeholder="请输入ETC卡号"
                              @keyup.enter.native="handleSearch"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button icon="el-icon-search" type="primary" size="small" @click="handleSearch">查询</el-button>
                    <el-button icon="el-icon-plus" type="primary" size="small" @click="handleAdd">添加</el-button>
                    <el-button icon="el-icon-download" type="primary" size="mini" @click="handleTemplate">下载导入模板
                    </el-button>
                    <el-button type="primary" size="mini" @click="handleImport">导入</el-button>
                </el-form-item>
            </el-row>
        </el-form>
        <el-table v-loading="loading" element-loading-text="拼命加载中" size="small" :data="tableData" highlight-current-row
                  border stripe style="width: 100%" :row-style="{ height: '45px' }" :cell-style="{ padding: '0px' }"
                  :header-cell-style="{ background: '#eef1f6', color: '#606266' }" height="500" max-height="500">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column prop="ownerName" label="车主姓名" align="center"></el-table-column>
            <el-table-column prop="ownerPhone" label="车主手机号" align="center"></el-table-column>
            <el-table-column prop="ownerIdCardNo" label="车主身份证号" align="center"></el-table-column>
            <el-table-column prop="licensePlate" label="车牌号码" align="center"></el-table-column>
            <el-table-column prop="rfidNo" label="卡号" align="center"></el-table-column>
            <el-table-column prop="tag" label="车辆标签" align="center"></el-table-column>
            <el-table-column prop="carAttrib" label="车辆属性" align="center">
                <template slot-scope="scope">
                    <el-tag type="success" v-if="scope.row.carAttrib == 1">小车</el-tag>
                    <el-tag type="warning" v-else-if="scope.row.carAttrib == 2">大车</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="areaId" label="所属区域" align="center">
                <template slot-scope="scope">
                    <div v-for="item in parkingAreas" :key="item.id">
                        <span v-if="item.id === scope.row.areaId">{{item.name}}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="regularCarRateId" label="费率" align="center"
                             :formatter="getCarRate"></el-table-column>
            <el-table-column prop="remark" label="备注" align="center"></el-table-column>
            <el-table-column width="160" align="center" label="操作">
                <template slot-scope="scope">
                    <el-button size="mini" @click="handleEdit(scope.$index, scope.row)" type="primary">编辑
                    </el-button>
                    <el-button size="mini" @click="handleDelete(scope.$index, scope.row)" type="danger">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                       :current-page="filters.current" :page-sizes="[10, 50, 200, 1000]" :page-size="filters.size"
                       layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
        <el-dialog v-draggable  :title="title" :visible.sync="visibleDialog">
            <el-form :model="editForm" ref="editForm" size="small" :rules="rules" label-width="130px">
                <el-row>
                    <el-col :span="11">
                        <el-form-item label="车主姓名" prop="ownerName" :label-width="formLabelWidth">
                            <el-input size="small" v-model="editForm.ownerName" placeholder="请输入内容"/>
                        </el-form-item>
                        <el-form-item label="车主手机" prop="ownerPhone" :label-width="formLabelWidth">
                            <el-input size="small" oninput="value=value.replace(/[^\d]/g,'')"
                                      v-model="editForm.ownerPhone" placeholder="请输入内容"/>
                        </el-form-item>
                        <el-form-item label="车主身份证号" prop="ownerIdCardNo" :label-width="formLabelWidth">
                            <el-input size="small" v-model="editForm.ownerIdCardNo" placeholder="请输入内容"/>
                        </el-form-item>
                        <el-form-item label="ETC卡号" prop="etcNo" :label-width="formLabelWidth">
                            <el-input size="small" v-model="editForm.etcNo" placeholder="请输入内容"/>
                        </el-form-item>
                        <el-form-item label="RFID卡号" prop="rfidNo" :label-width="formLabelWidth">
                            <el-input size="small" v-model="editForm.rfidNo" placeholder="请输入内容"/>
                        </el-form-item>
                        <el-form-item label="备注" prop="remark" :label-width="formLabelWidth">
                            <el-input size="small" v-model="editForm.remark" placeholder="请输入内容"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11">
                        <el-form-item label="车牌号" prop="licensePlate" :label-width="formLabelWidth">
                            <el-input size="small" v-model="editForm.licensePlate" placeholder="请输入内容"/>
                        </el-form-item>
                        <el-form-item label="车辆属性" prop="carAttrib" :label-width="formLabelWidth">
                            <el-select :disabled="disabled" size="small" v-model="editForm.carAttrib"
                                       placeholder="请选择车辆属性">
                                <el-option label="小车" :value="1"></el-option>
                                <el-option label="大车" :value="2"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="车辆标签" prop="tag" :label-width="formLabelWidth">
                            <el-input size="small" v-model="editForm.tag" placeholder="请输入内容"/>
                        </el-form-item>
                        <el-form-item label="选择区域" prop="areaId" :label-width="formLabelWidth">
                            <el-select :disabled="disabled" size="small" @change="changeArea" v-model="editForm.areaId"
                                       placeholder="请选择区域">
                                <el-option v-for="item in parkingAreas" :label="item.name" :value="item.id"
                                           :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="费率" prop="regularCarRateId" :label-width="formLabelWidth">
                            <el-select :disabled="disabled" size="small" v-model="editForm.regularCarRateId"
                                       placeholder="请选择费率">
                                <el-option v-for="item in longCarRate" :label="item.rateName" :value="item.id"
                                           :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="可通行区域" prop="regularCarInArea" :label-width="formLabelWidth">
                            <el-checkbox-group :disabled="disabled" v-model="editForm.regularCarInArea">
                                <el-checkbox v-for="item in parkingAreas" :label="item.id" :key="item.id">{{ item.name}}
                                </el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button @click="visibleDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('editForm')">确 定</el-button>
      </span>
        </el-dialog>
        <el-dialog v-draggable  title="可通行区域选择" :visible.sync="importDialog" v-loading="importLoading"
                   element-loading-text="拼命加载中"
                   element-loading-spinner="el-icon-loading"
                   element-loading-background="rgba(0, 0, 0, 0.6)">
            <div style="text-align: center;margin-bottom: 10px">请选择可通行区域，本次导入车辆可通行区域将统一处理。</div>
            <el-form :model="importForm" ref="importForm" size="small">
                <el-form-item prop="parkingAreaId" label-width="140px">
                    <el-checkbox-group v-model="importForm.longCarInAreas">
                        <el-checkbox v-for="item in parkingAreas" :label="item.id"
                                     :key="item.id" :value="item.id">
                            {{item.name }}
                        </el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item>
                    <div style="display: flex;flex-direction: row;justify-content: space-around">
                        <el-upload class="upload-demo inline-block" action="" :http-request="httpRequest"
                                   :show-file-list="false"
                                   accept=".xls,.xlsx"
                                   :before-upload="beforeAvatarUpload">
                            <el-button size="small" style="margin:0 0 20px 20px" type="success" icon="el-icon-upload">
                                开始导入
                            </el-button>
                        </el-upload>
                        <div>
                            <el-button size="small" @click="importDialog = false">取 消</el-button>
                        </div>
                    </div>
                </el-form-item>
            </el-form>
        </el-dialog>
        <!--        文件导入错误数据-->
        <el-dialog v-draggable  title="导入结果" :visible.sync="dialogVisible" width="90%">
            <el-row>
                <el-col :span="6"><span>成功导入：{{successNum}}</span></el-col>
                <el-col :span="6"><span>失败导入：{{failNum}}</span></el-col>
                <el-col :span="6">导入总条数：{{successNum + failNum}}</el-col>
                <el-col :span="6">
                    <el-button type="primary" size="mini" @click="dialogVisible = false">关闭</el-button>
                </el-col>
            </el-row>
            <!--列表-->
            <el-table
                    v-if="importData.length > 0"
                    size="small"
                    :data="importData"
                    highlight-current-row
                    border
                    style="width: 100%;margin-top: 10px;"
                    :row-style="{height:'45px'}"
                    :cell-style="{padding:'0px'}"
                    :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                    stripe
            >
                <el-table-column min-width="30" type="index" label="序号"></el-table-column>
                <el-table-column prop="ownerName" label="车主姓名" align="center"></el-table-column>
                <el-table-column prop="ownerPhone" label="车主手机号" align="center"></el-table-column>
                <el-table-column prop="ownerIdCardNo" label="车主身份证号" align="center"></el-table-column>
                <el-table-column prop="licensePlate" label="车牌号码" align="center"></el-table-column>
                <el-table-column prop="rfidNo" label="卡号" align="center"></el-table-column>
                <el-table-column prop="tag" label="车辆标签" align="center"></el-table-column>
                <el-table-column prop="carAttrib" label="车辆属性" align="center">
                    <template slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.carAttrib == 1">小车</el-tag>
                        <el-tag type="warning" v-else-if="scope.row.carAttrib == 2">大车</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="areaId" label="所属区域" align="center"></el-table-column>
                <el-table-column prop="regularCarRateId" label="费率" align="center"></el-table-column>
                <el-table-column prop="remark" label="备注" align="center"></el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>

<script>
    import {longCarAreaList, longCarRateList, rateMemberArea, rateMemberParking} from "../../../api/vehicle/longcar";
    import {
        getRegularCar,
        regularCarDel,
        regularCarDownTemplate,
        regularCarEdit, regularCarImportExcel,
        regularCarSave
    } from "../../../api/regular";
    import {getCurrentParking} from "../../../api/login";

    export default {
        name: "valueStorage",
        data() {
            return {
                loading: false,
                total: 0,
                tableData: [],
                filters: {
                    licensePlate: '',
                    carOwnerName: '',
                    carOwnerPhone: '',
                    carProperty: '',
                    current: 1,
                    size: 10,
                    carType: 6
                },
                title: "",
                formLabelWidth: '120px',
                visibleDialog: false,
                editForm: {
                    id: "",
                    ownerName: "",//车主姓名
                    ownerPhone: "",//车主号码
                    ownerIdCardNo: "", //车主身份证
                    licensePlate: "",//车牌号码
                    carAttrib: "",//车辆属性
                    tag: "", //车辆标签
                    remark: "",//备注
                    areaId: "",//区域id
                    regularCarRateId: "", //长租费率id
                    rfidNo: "", //RFID卡号
                    etcNo: "", //ETC卡号
                    regularCarInArea: [],
                },
                parkingAreas: [],
                longCarRate: [],
                parkingData: [],
                rateMemberArea: [],
                rules: {
                    ownerName: [{required: true, message: "请输入", trigger: "blur"}],
                    ownerPhone: [{required: true, message: "请输入", trigger: "blur"}],
                    licensePlate: [{required: true, message: "请输入", trigger: "blur"}],
                    carProperty: [{required: true, message: "请选择", trigger: "change"}],
                    carAttrib: [{required: true, message: "请选择", trigger: "change"}],
                    regularCarRateId: [{required: true, message: "请选择", trigger: "change"}],
                    areaId: [{required: true, message: "请选择", trigger: "change"}],
                },
                disabled: false,
                areaId: "",
                parkingId: "",
                importDialog: false,
                importLoading: false,
                importForm: {
                    longCarInAreas: [],
                },
                dialogVisible: false,
                importData: [],
                successNum: "",
                failNum: "",
            };
        },
        created() {
            this.getCurrentParking();
        },
        mounted() {
            this.fetchData();
            this.getParkingAreaRoad();
        },
        methods: {
            fetchData() {
                this.loading = true;
                getRegularCar(this.filters).then((res) => {
                    this.loading = false;
                    if (res.data.code == "SUCCESS") {
                        this.tableData = res.data.data.records;
                        this.total = res.data.data.total;
                        this.filters.current = res.data.data.current;
                        this.filters.size = res.data.data.size;
                    } else {
                        this.$message.info(res.data.message);
                    }
                });
            },
            //获取区域
            getParkingAreaRoad() {
                longCarAreaList().then((res) => {
                    this.parkingAreas = res.data.data.parkingAreas;
                }).catch((err) => {
                    console.log(err)
                });
            },
            changeArea(areaId) {
                this.getLongCarRateList(areaId);
            },
            //获取长租费率
            getLongCarRateList(areaId) {
                rateMemberArea(areaId).then((res) => {
                    if (res.data.data.length > 0) {
                        this.longCarRate = res.data.data;
                    }
                }).catch((err) => {
                    console.log(err)
                });
            },
            getCurrentParking() {
                getCurrentParking().then((res) => {
                    this.parkingId = res.data.data.id;
                    this.getRateMemberParking(this.parkingId)
                })
            },
            getRateMemberParking(parkingId) {
                rateMemberParking(parkingId).then((res) => {
                    if (res.data.data.length > 0) {
                        this.rateMemberArea = res.data.data;
                    }
                }).catch((err) => {
                    console.log(err)
                });
            },
            getCarRate(row) {
                let name = "";
                this.rateMemberArea.forEach(item => {
                    if (item.id == row.regularCarRateId) {
                        name = item.rateName;
                    }
                })
                return name;
            },
            handleSearch() {
                this.filters.current = 1;
                this.fetchData();
            },
            handleSizeChange(val) {
                this.filters.size = val;
                this.filters.current = 1;
                this.fetchData();
            },
            handleCurrentChange(val) {
                this.filters.current = val;
                this.fetchData();
            },
            handleAdd() {
                this.title = "添加";
                this.visibleDialog = true;
                this.disabled = false;
                Object.keys(this.editForm).map((key) => {
                    this.editForm[key] = "";
                });
                this.editForm.regularCarInArea = [];
            },
            submitForm(editForm) {
                this.$refs[editForm].validate((valid) => {
                        if (valid) {
                            let regularCarInAreaArr = [...new Set(this.editForm.regularCarInArea)];
                            let regularCarInAreaList = [];
                            regularCarInAreaArr.forEach((e) => {
                                regularCarInAreaList.push({areaId: e});
                            });
                            let params = {
                                id: this.editForm.id,
                                ownerName: this.editForm.ownerName,//车主姓名
                                ownerPhone: this.editForm.ownerPhone,//车主号码
                                ownerIdCardNo: this.editForm.ownerIdCardNo, //车主身份证
                                licensePlate: this.editForm.licensePlate,//车牌号码
                                carAttrib: this.editForm.carAttrib,//车辆属性
                                tag: this.editForm.tag, //车辆标签
                                remark: this.editForm.remark,//备注
                                areaId: this.editForm.areaId,//区域id
                                regularCarRateId: this.editForm.regularCarRateId, //长租费率id
                                rfidNo: this.editForm.rfidNo, //RFID卡号
                                etcNo: this.editForm.etcNo, //ETC卡号
                                regularCarInArea: regularCarInAreaList,
                                carType: 6
                            };
                            if (this.editForm.id) {
                                regularCarEdit(params).then((res) => {
                                    this.visibleDialog = false;
                                    if (res.data.code == "SUCCESS") {
                                        this.fetchData();
                                        this.$message.success("编辑成功！");
                                    } else {
                                        this.$message.info(res.data.message);
                                    }
                                });
                            } else {
                                regularCarSave(params).then((res) => {
                                    this.visibleDialog = false;
                                    if (res.data.code == "SUCCESS") {
                                        this.fetchData();
                                        this.$message.success("保存成功！");
                                    } else {
                                        this.$message.info(res.data.message);
                                    }
                                });
                            }
                        }
                    }
                );
            },
            //编辑
            handleEdit(index, row) {
                this.title = "编辑";
                this.visibleDialog = true;
                this.disabled = true;
                Object.keys(this.editForm).map((key) => {
                    this.editForm[key] = row[key];
                });
                this.editForm.id = row.id;
                this.editForm.regularCarInArea = [];
                this.editForm.regularCarInArea.push(row.areaId);
                this.getLongCarRateList();
                this.getLongCarRateList(row.areaId);
            },
            //删除
            handleDelete(index, row) {
                this.$confirm("确定要删除数据吗?", "温馨提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    regularCarDel(row.id).then((res) => {
                        if (res.data.code == "SUCCESS") {
                            this.filters.current = 1;
                            this.fetchData();
                            this.$message.success("删除成功！");
                        } else {
                            this.$message.info(res.data.message);
                        }
                    });
                }).catch(() => {
                    this.$message.info("已取消删除!");
                });
            }, //下载导入模板
            handleTemplate() {
                regularCarDownTemplate(3).then((res) => {
                    this.$FileSaver(res.data, "储值车列表模板.xls");
                })
            },
            //验证文件类型
            beforeAvatarUpload(file) {
                const isXls = file.type === "application/vnd.ms-excel" ? true : file.type ===
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? true : false;
                if (!isXls) {
                    this.$message.error("上传的文件只能是xls以及xlsx格式!");
                }
                return isXls;
            },
            // ---------------导入
            handleImport() {
                this.importDialog = true;
                this.importLoading = false;
                this.importForm.longCarInAreas = [];
            },
            httpRequest(param) {
                let formData = new FormData();
                formData.append("file", param.file);
                formData.append("carType", 6);
                formData.append("areaId", this.importForm.longCarInAreas.toString());
                this.importLoading = true;
                this.$confirm('注意：导入会覆盖原有数据，请谨慎操作！', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    setTimeout(() => {
                        regularCarImportExcel(formData).then(res => {
                            this.dialogVisible = true;
                            this.importDialog = false;
                            if (res.data.code == "SUCCESS") {
                                this.importLoading = false;
                                this.importData = res.data.data.errorData;
                                this.failNum = res.data.data.failNum;
                                this.successNum = res.data.data.successNum;
                                this.$message.success(res.data.message);
                                this.fetchData();
                            } else {
                                this.importLoading = false;
                                this.$message.error(res.data.message);
                            }
                        }).catch(error => {
                            this.dialogVisible = false;
                            this.importDialog = false;
                            this.$message.error("导入失败，请稍后再试！");
                        });
                    }, 1000);
                }).catch(() => {
                    this.$message.info('已取消导入');
                });
            },
        },
    };
</script>
<style scoped>
    .user-search {
        margin-top: 10px;
    }
</style>
