import request from '@/utils/request'
import config from '@/config'

// 设备列表
export function getDevice(data) {
    return request({
        url: `${config.cpi}/device`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}

// 未绑定设备列表
export function getUnboundList() {
    return request({
        url: `${config.cpi}/device/unboundList`,
        method: 'get',
        headers: {'showLoading': false}
    })
}

// 添加设备
export function AddDevice(data) {
    return request({
        url: `${config.cpi}/device`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 编辑设备
export function EditDevice(data) {
    return request({
        url: `${config.cpi}/device`,
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 删除设备
export function delDevice(id) {
    return request({
        url: `${config.cpi}/device`,
        method: 'delete',
        params: {ids: id},
    })
}

// 设备开闸
export function openGate(id) {
    return request({
        url: `${config.cpi}/device/openGate/${id}`,
        method: 'post',
    })
}

// 添加设备
export function addTitle(data) {
    return request({
        url: `${config.cpi}/device/display/title/sync`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

export function addTime(data) {
    return request({
        url: `${config.cpi}/device/time/sync`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

export function restart(data) {
    return request({
        url: `${config.cpi}/device/restart`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 设置触摸屏密码
export function screenPwd(data) {
    return request({
        url: `${config.cpi}/device/settings/screen/pwd`,
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

export function delClear(data) {
    return request({
        url: `${config.cpi}/device/whitelist/clear`,
        method: 'delete',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 测温设备列表
export function getDeviceTemperature(data) {
    return request({
        url: `${config.cpi}/device/measure/temperature`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}

// 添加测温设备
export function addDeviceTemperature(data) {
    return request({
        url: `${config.cpi}/device/measure/temperature`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 编辑测温设备
export function editDeviceTemperature(data) {
    return request({
        url: `${config.cpi}/device/measure/temperature`,
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 删除测温设备
export function delDeviceTemperature(id) {
    return request({
        url: `${config.cpi}/device/measure/temperature`,
        method: 'delete',
        params: {ids: id},
    })
}

// 路由设备列表
export function routerList(data) {
    return request({
        url: `${config.api}/device/router`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}

// 添加路由设备
export function routerSave(data) {
    return request({
        url: `${config.api}/device/router`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 编辑路由设备
export function routerEdit(data) {
    return request({
        url: `${config.api}/device/router`,
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 删除路由设备
export function routerDel(id) {
    return request({
        url: `${config.api}/device/router`,
        method: 'delete',
        params: {id: id},
    })
}


