import request from '@/utils/request'
import config from '@/config'

// 通行授权已授权列表
export function getUserAuthList(data) {
    return request({
        url: `${config.cpi}/auth/userAuthList`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}

// 撤销授权
export function revoke(data) {
    return request({
        url: `${config.cpi}/auth/revoke`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 撤销授权
export function revokeDevice(data) {
    return request({
        url: `${config.cpi}/auth/revoke/device`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 授权
export function AddAuth(data) {
    return request({
        url: `${config.cpi}/auth`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}
