<template>
    <div class="indexMain">
        <el-alert v-if="show" style="z-index: 999;height: 40px;"
                  title="网络异常已中断"
                  type="warning"
                  center
                  :closable="false">
        </el-alert>
        <div id="index" ref="appRef">
            <div class="bg">
                <dv-loading v-if="loading">Loading...</dv-loading>
                <div v-else class="host-body">
                    <!--头部-->
                    <div class="header">
                        <div class="left">
                            <p style="cursor: pointer; text-align: left;" @click="handleReturn">返 回</p>
                            <p style="cursor: pointer;" @click="handleFullScreen">全屏切换</p>
                        </div>
                        <h2>雷达测速系统数据监控平台</h2>
                        <p class="right">{{ dateYear }} {{ dateWeek }} {{ dateDay }}</p>
                    </div>
                    <!-- 主体 -->
                    <div class="content">
                        <!-- 左 -->
                        <div class="large-left">
                            <div class="large-left-top">
                                <dv-border-box-11 title="设备情况——览表">
                                    <div class="box">
                                        <div class="operate">
                                            <div>
                                                <span>{{deviceTotal}}</span>
                                                <p>设备总数</p>
                                            </div>
                                            <div>
                                                <span>{{deviceOnline}}</span>
                                                <p>设备在线</p>
                                            </div>
                                            <div>
                                                <span>{{deviceOffline}}</span>
                                                <p>设备离线</p>
                                            </div>
                                        </div>
                                    </div>
                                </dv-border-box-11>
                            </div>
                            <div class="large-left-bottom">
                                <dv-border-box-12>
                                    <div class="large-left-bottom-title"><h1>速度情况柱状图</h1></div>
                                    <div style="width: 100%;height: 232px;">
                                        <div class="Rose_diagram" id="Rose_diagram" ref="Rose_diagram"></div>
                                    </div>
                                    <dv-scroll-board :config="board_info" class="carousel_list" oddRowBGC="#fff"/>
                                </dv-border-box-12>
                            </div>
                        </div>
                        <!-- 中间 -->
                        <div class="large-center">
                            <div class="large-center-top">
                                <dv-border-box-11 title="测速实时监控">
                                    <div class="large-center-div">
                                        <el-carousel :indicator-position="realTime.length > 15 ? 'none' : 'outside'"
                                                     :autoplay="false">
                                            <el-carousel-item v-for="item in realTime" :key="item.id">
                                                <el-card
                                                        :body-style="{ height: '600px', background: 'rgba(211,214,221,0.1)', }">
                                                    <!--                                                    <el-image class="box-img"-->
                                                    <!--                                                              :src="item.img?item.img:require('../assets/images/defaultImg.png')"-->
                                                    <!--                                                              :preview-src-list="[item.img?item.img:require('../assets/images/defaultImg.png')]">-->
                                                    <!--                                                    </el-image>-->
                                                    <img :src="item.realTimeVideoUrl?'http://' + item.realTimeVideoUrl + ':8088/cgi-bin/video_cgi' : require('../assets/images/defaultImg.png')"
                                                         class="avatar"/>
                                                    <div class="box-info">
                                                        <p>{{item.licensePlate ? item.licensePlate: ''}}</p>
                                                        <p>{{item.speedPositionName ? item.speedPositionName: ''}}</p>
                                                        <p>{{item.speedValue ? item.speedValue+' km/h' :''}}</p>
                                                    </div>
                                                </el-card>
                                            </el-carousel-item>
                                        </el-carousel>
                                        <!--                                        <el-carousel indicator-position="none" :autoplay="false">-->
                                        <!--                                            <el-carousel-item>-->
                                        <!--                                                <el-card-->
                                        <!--                                                        :body-style="{ height: '600px', background: 'rgba(211,214,221,0.1)', }">-->
                                        <!--                                                                                                        <div class="Dashboard" id="Dashboard" ref="Dashboard"></div>-->
                                        <!--                                                                                                        <p style="width: 100%; font-size: 32px;font-weight: bold; color: #fff; height: 50px; position: absolute;  left: 39%; top: 75%;">-->
                                        <!--                                                                                                            {{licensePlate}}</p>-->
                                        <!--                                                </el-card>-->
                                        <!--                                            </el-carousel-item>-->
                                        <!--                                        </el-carousel>-->
                                    </div>
                                </dv-border-box-11>
                            </div>
                        </div>
                        <div class="large-right">
                            <!-- 右侧 -->
                            <div class="large-right-top">
                                <dv-border-box-11 title="设备列表">
                                    <dv-scroll-board
                                            :config="device" class="carousel_list" oddRowBGC="#fff"/>
                                </dv-border-box-11>
                            </div>
                            <div class="large-right-bottom">
                                <dv-border-box-12>
                                    <div class="large-left-bottom-title"><h1>测速车辆总数</h1></div>
                                    <div class="large-left-bottom-up">
                                        <p>{{todaySpeedTotal}}</p>
                                    </div>
                                    <div class="large-left-bottom-down">
                                        <div>
                                            <span>{{todayNormal}}</span>
                                            <p>速度正常数</p>
                                        </div>
                                        <div>
                                            <span>{{todayLowSpeed}}</span>
                                            <p>慢速车辆数</p>
                                        </div>
                                        <div>
                                            <span>{{todayFastSpeed}}</span>
                                            <p>超速车辆数</p>
                                        </div>
                                    </div>
                                </dv-border-box-12>
                            </div>
                        </div>
                    </div>
                    <div class="bottom-list">
                        <dv-border-box-12>
                            <div class="bottom-list-div" v-for="(item,i)  in listData" :key="item.id"
                                 :style="{'background': i == 1 || i == 3 ? 'rgb(211, 214, 221, 0.2)':''}">
                                <div class="bottom-list-div-image">
                                    <el-image
                                            :src="item.img?item.img:require('../assets/images/defaultImg.png')"
                                            :preview-src-list="[item.img?item.img:require('../assets/images/defaultImg.png')]">
                                    </el-image>
                                </div>
                                <div class="bottom-list-div-font">
                                    <span>车牌号：{{item.licensePlate}}</span>
                                    <span>速度：{{item.speedValue}}</span>
                                    <span>异常比例：{{item.abnormalRatio}}%</span>
                                    <span>时间：{{dayjs(item.speedTime).format('HH:mm:ss') }}</span>
                                    <span>地点：{{item.speedPositionName}}</span>
                                </div>
                            </div>
                        </dv-border-box-12>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {formatTime} from '@/utils/index.js'
    import drawMixin from "@/utils/drawMixin";
    import * as echarts from "echarts";
    import {getSpeedDevice, getSpeedRecord, speedStatistics, speedStatisticsRealtime} from "../api/speed";

    export default {
        mixins: [drawMixin],
        name: "radarSmartScreen",
        data() {
            return {
                timing: null,
                dateDay: null,
                dateYear: null,
                dateWeek: null,
                weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
                loading: false,
                show: false,
                board_info: {},
                device: {},
                fullscreen: false,
                deviceOffline: 0,
                deviceOnline: 0,
                deviceTotal: 0,
                todayFastSpeed: 0,
                todayLowSpeed: 0,
                todayNormal: 0,
                todaySpeedTotal: 0,
                listData: [],
                tableData: [],
                keyer: null,
                realTime: [],
                licensePlate: ""
            }
        },
        mounted() {
            this.timeFn();
            //加载loading图
            this.cancelLoading();
            //数据展示接口
            this.keyer = setInterval(() => {
                this.getSpeedStatistics();
                this.fetchData();
                this.getSpeedDevice();
                this.getspeedStatisticsRealtime();

            }, 10000);
            window.addEventListener('online', this.updateOnlineStatus);
            window.addEventListener('offline', this.updateOnlineStatus);
        },
        //销毁前清除
        beforeDestroy() {
            clearInterval(this.keyer);
            clearInterval(this.timing);
            window.removeEventListener('online', this.updateOnlineStatus);
            window.removeEventListener('offline', this.updateOnlineStatus);
        },
        methods: {
            timeFn() {
                this.timing = setInterval(() => {
                    this.dateDay = formatTime(new Date(), 'HH: mm: ss');
                    this.dateYear = formatTime(new Date(), 'yyyy年MM月dd日');
                    this.dateWeek = this.weekday[new Date().getDay()];
                }, 1000)
            },
            //loading
            cancelLoading() {
                this.loading = true;
                setTimeout(() => {
                    this.loading = false;
                    this.getSpeedStatistics();
                    this.fetchData();
                    this.getspeedStatisticsRealtime();
                    this.getSpeedDevice();
                }, 500);
            },
            updateOnlineStatus(e) {
                const {type} = e;
                this.onLine = type === 'online';
                if (type === 'online') {
                    this.show = false;
                } else if (type === 'offline') {
                    this.show = true;
                }
            },
            // 全屏事件
            handleFullScreen() {
                let element = document.documentElement;
                // 判断是否已经是全屏
                // 如果是全屏，退出
                if (this.fullscreen) {
                    if (document.exitFullscreen) {
                        document.exitFullscreen();
                    } else if (document.webkitCancelFullScreen) {
                        document.webkitCancelFullScreen();
                    } else if (document.mozCancelFullScreen) {
                        document.mozCancelFullScreen();
                    } else if (document.msExitFullscreen) {
                        document.msExitFullscreen();
                    }
                    console.log("已还原！");
                } else {
                    // 否则，进入全屏
                    if (element.requestFullscreen) {
                        element.requestFullscreen();
                    } else if (element.webkitRequestFullScreen) {
                        element.webkitRequestFullScreen();
                    } else if (element.mozRequestFullScreen) {
                        element.mozRequestFullScreen();
                    } else if (element.msRequestFullscreen) {
                        // IE11
                        element.msRequestFullscreen();
                    }
                    console.log("已全屏！");
                }
                // 改变当前全屏状态
                this.fullscreen = !this.fullscreen;
            },
            handleReturn() {
                this.$router.push('/speedMain/home');
            },
            fetchData() {
                let params = {
                    current: 1,
                    size: 5
                };
                getSpeedRecord(params).then(res => {
                    if (res.data.code == "SUCCESS") {
                        this.listData = res.data.data.records;
                    }
                })
            },
            getSpeedDevice() {
                getSpeedDevice().then(res => {
                    if (res.data.code == "SUCCESS") {
                        this.tableData = res.data.data;
                        let arrlistresult = [];
                        this.tableData.map(item => {
                            if (item.deviceType === 6) {
                                item.deviceType = '测速相机';
                            } else {
                                item.deviceType = '';
                            }
                            if (item.status === 1) {
                                item.status = "在线";
                            } else {
                                item.status = "离线";
                            }
                            let arrlist = [item.deviceName, item.deviceNo, item.deviceSn, item.deviceType, item.status];
                            arrlistresult.push(arrlist);
                        });
                        this.device = {
                            header: ["设备名称", "设备编号", "设备序列号", "设备类型", "设备状态"],
                            data: arrlistresult,
                            rowNum: 5, //表格行数
                            headerHeight: 35,
                            headerBGC: "#0f1325", //表头
                            oddRowBGC: "#0f1325", //奇数行
                            evenRowBGC: "#171c33", //偶数行
                            align: ['center', 'center', 'center', 'center', 'center'],
                            carousel: 'page',

                        }
                    }
                })
            },
            getSpeedStatistics() {
                speedStatistics().then(res => {
                    this.deviceOffline = res.data.data.deviceOffline;
                    this.deviceOnline = res.data.data.deviceOnline;
                    this.deviceTotal = res.data.data.deviceTotal;
                    this.todayFastSpeed = res.data.data.todayFastSpeed;
                    this.todayLowSpeed = res.data.data.todayLowSpeed;
                    this.todayNormal = res.data.data.todayNormal;
                    this.todaySpeedTotal = res.data.data.todaySpeedTotal;
                    var num1 = this.todayNormal || this.todaySpeedTotal ? parseFloat((this.todayNormal / this.todaySpeedTotal) * 100).toFixed(2) : 0;
                    var num2 = this.todayLowSpeed || this.todaySpeedTotal ? parseFloat((this.todayLowSpeed / this.todaySpeedTotal) * 100).toFixed(2) : 0;
                    var num3 = this.todayFastSpeed || this.todaySpeedTotal ? parseFloat((this.todayFastSpeed / this.todaySpeedTotal) * 100).toFixed(2) : 0;
                    this.board_info = {
                        header: ["块号", "状态", "速度比",],
                        data: [[`<span style="color:#2BCB95;">绿色</span>`, "正常", num1 + "%"], [`<span style="color:#69aae4;">蓝色</span>`, "慢速", num2 + "%"], [`<span style="color:#ed7e32;">橙色</span>`, "超速", num3 + "%"]],
                        rowNum: 5, //表格行数
                        headerHeight: 35,
                        headerBGC: "#0f1325", //表头
                        oddRowBGC: "#0f1325", //奇数行
                        evenRowBGC: "#171c33", //偶数行
                        align: ['center', 'center', 'center'],
                        carousel: 'page',

                    };
                    this.$nextTick(() => {
                        this.Rose_diagram();
                    });
                })
            },
            Rose_diagram() {
                let myEchart = document.getElementById('Rose_diagram');
                let mapChart = echarts.init(myEchart); //图表初始化，china-map是绑定的元素
                window.addEventListener("resize", function () {
                    mapChart.resize();
                }); //如果容器变大小，自适应从新构图
                var num1 = this.todayNormal || this.todaySpeedTotal ? parseFloat((this.todayNormal / this.todaySpeedTotal) * 100).toFixed(2) : 0;
                var num2 = this.todayLowSpeed || this.todaySpeedTotal ? parseFloat((this.todayLowSpeed / this.todaySpeedTotal) * 100).toFixed(2) : 0;
                var num3 = this.todayFastSpeed || this.todaySpeedTotal ? parseFloat((this.todayFastSpeed / this.todaySpeedTotal) * 100).toFixed(2) : 0;
                let option = {
                    //数据的颜色设置
                    color: ["#2BCB95", "#69aae4", "#ed7e32"],
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    tooltip: {//提示框组件
                        axisPointer: {
                            // 坐标轴指示器，坐标轴触发有效
                            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                        },
                        formatter: '速度比 : {c}%'
                    },
                    xAxis: {     //设置x轴
                        type: 'category',
                        splitLine: {show: false},//去除网
                    },
                    yAxis: {
                        show: false,  // 是否显示
                        splitLine: {
                            show: false
                        },//去除网格线
                    },
                    series: [{
                        type: 'bar',
                        label: {
                            show: true,
                            fontSize: 16,
                            formatter: '{c}%'　　　　//这是关键，在需要的地方加上就行了
                        },
                        data: [num1],

                    }, {
                        type: 'bar',
                        label: {
                            show: true,
                            fontSize: 16,
                            formatter: '{c}%'　　　　//这是关键，在需要的地方加上就行了
                        },
                        data: [num2],

                    }, {
                        type: 'bar',
                        label: {
                            show: true,
                            fontSize: 16,
                            formatter: '{c}%'　　　　//这是关键，在需要的地方加上就行了
                        },
                        data: [num3],

                    }]
                };
                mapChart.setOption(option); //生成图表
            },
            getspeedStatisticsRealtime() {
                speedStatisticsRealtime().then(res => {
                    this.realTime = Object.values(res.data.data);
                    console.log(this.realTime)
                    // console.log(typeof this.realTime)
                    // this.$nextTick(() => {
                    //     this.Dashboard();
                    // });
                })
            },
            Dashboard() {
                let myEchart = document.getElementById('Dashboard');
                // console.log(this.realTime.length, '=====')
                this.seriesData = [];
                // for (var i = 0; i < this.realTime.length; i++) {
                console.log(this.realTime[1], '进来了')
                console.log(this.realTime[1].speedValue)
                this.seriesData = [
                    {value: this.realTime[1].speedValue},
                ];
                console.log(this.seriesData)
                let mapChart = echarts.init(myEchart); //图表初始化
                window.addEventListener("resize", function () {
                    mapChart.resize();
                }); //如果容器变大小，自适应从新构图
                let option = {
                    //环形图中间文字
                    // title: {
                    // text: this.realTime[1].speedPositionName,
                    // 主标题样式
                    // textStyle: {
                    //     fontSize: '32',
                    //     color: '#fff',
                    //     align: 'center',
                    //     left: 'center'
                    // },
                    // left:'40%',    // title组件离容器左侧距离，写法如'5'||'5%'
                    // right:'50%',
                    // textAlign:'auto',
                    // textVerticalAlign:'auto',
                    // bottom: '5%',
                    // subtext: this.realTime[1].licensePlate,
                    // 副标题样式
                    // subtextStyle: {
                    //     fontSize: '30',
                    //     fontWeight: '800',
                    //     color: '#fff',left: '10%', // 位置
                    //     top: '90%' // 位置
                    // }
                    // },
                    title: [
                        { // 标题
                            text: this.realTime[1].speedPositionName,
                            left: 'center',
                            top: '90%',// 位置
                            textStyle: { // 标题样式
                                fontSize: 30,
                                color: '#fff',
                            },
                        },
                        { // 副标题
                            text: this.realTime[1].licensePlate,     // '/n'代表换行
                            textStyle: { // 标题样式
                                fontSize: 30,
                                color: '#fff',
                            },
                            left: 'center',
                            top: '80%' // 位置
                        }
                    ],
                    series: [
                        {
                            type: 'gauge',
                            axisLine: {
                                lineStyle: {
                                    width: 30,
                                    color: [
                                        [0.3, '#67e0e3'],
                                        [0.7, '#37a2da'],
                                        [1, '#fd666d']
                                    ]
                                }
                            },
                            pointer: {
                                itemStyle: {
                                    color: 'auto'
                                }
                            },
                            axisTick: {
                                distance: -30,
                                length: 8,
                                lineStyle: {
                                    color: '#fff',
                                    width: 2
                                }
                            },
                            splitLine: {
                                distance: -30,
                                length: 30,
                                lineStyle: {
                                    color: '#fff',
                                    width: 4
                                }
                            },
                            axisLabel: {
                                color: 'auto',
                                distance: 40,
                                fontSize: 20
                            },
                            detail: {
                                valueAnimation: true,
                                formatter: '{value} km/h',
                                color: 'auto',
                                textStyle: {
                                    fontFamily: "transist"
                                },
                            },
                            data: this.seriesData
                        }
                    ]
                };
                mapChart.setOption(option); //生成图表
            }
            // }
        }
    }
</script>

<style lang="scss" scoped>
    @import "src/assets/scss/index";

    .indexMain {
        width: 100%;
        height: 100%;
        background-color: #020308;
        /*overflow: hidden;*/
    }

    /* 头部 header */
    .header {
        height: 80px;
        line-height: 80px;
        background: url(../assets/head_bg.png) no-repeat;
        background-size: 100% 100%;
        color: #fff;
        position: relative;
    }

    .header h2 {
        flex: 1;
        text-align: center;
        font-size: 30px;
    }

    .header .left {
        position: absolute;
        left: 30px;
        top: -10px;
        font-size: 20px;
        display: flex;
        justify-content: space-around;
        width: 300px;
    }

    .header .right {
        position: absolute;
        right: 30px;
        top: -10px;
        font-size: 20px;
    }

    /* 主体 content */
    .content {
        height: 100%;
        display: flex;
        justify-content: space-between;
    }

    .large-left {
        width: 30%;
        height: 700px;
    }

    .large-left-top .dv-border-box-11 {
        height: 230px;
    }

    .large-left-top .dv-border-box-11 .box {
        width: 100%;
        position: absolute;
        top: 55px;
    }

    .large-left-top .dv-border-box-11 .box .operate {
        line-height: 40px;
        padding: 20px 40px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .large-left-top .dv-border-box-11 .box .operate div {
        width: 30%;
        height: 130px;
        text-align: center;
        background: rgba(211, 214, 221, 0.1);
        line-height: 50px;
        padding-top: 10px;
        /*border-right: 1px solid #ebebeb;*/
    }

    .large-left-top .dv-border-box-11 .box .operate p {
        color: #568aea;
        font-size: 26px;
    }

    .large-left-top .dv-border-box-11 .box .operate span {
        color: #ffffff;
        font-size: 30px;
        font-weight: bold;
        font-family: 仿宋;
    }

    .large-left-bottom {
        height: 460px;
    }

    .large-left-bottom .dv-border-box-8 .dv-decoration-7 {
        width: 200px;
        height: 12px;
        position: absolute;
        top: 20px;
        left: 15px;
    }

    #Rose_diagram {
        height: 100%;
        width: 100%;
        margin: 0 auto;
        padding-top: 75px;
    }

    //轮播表格
    .carousel_list {
        width: 96%;
        height: 66%;
        margin: 20px auto;
    }

    .large-center {
        width: 40%;
        height: 700px;
        position: relative;
    }

    .large-center-top .dv-border-box-11 {
        height: 690px;
    }

    .large-center-div {
        position: absolute;
        top: 70px;
        width: 95%;
        margin: 0 20px;
    }

    .el-carousel /deep/ .el-carousel__container {
        height: 580px;
    }

    .el-card {
        border: none;
        box-shadow: none;
        background: none;
    }

    .large-center-div img {
        height: 480px;
        width: 100%;
    }

    .large-center-div .box-info {
        display: flex;
        justify-content: space-between;
        color: white;
        width: 100%;
        font-size: 25px;
        padding: 0 10px;
        position: absolute;
        bottom: 30px;
        left: 0;
    }

    #Dashboard {
        width: 100%;
        height: 100%;
    }

    .large-right {
        width: 30%;
        height: 700px;
    }

    .large-right-top .dv-border-box-11 {
        height: 280px;
        padding-top: 45px;
        padding-bottom: 12px;
        //轮播表格
        .carousel_list {
            width: 96%;
            height: 100%;
            margin: 20px auto;
        }

        /*.carousel_list /deep/ .header-item:nth-child(2) {*/
        /*    width: 35% !important;*/
        /*}*/

        /*.carousel_list /deep/ .header-item:nth-child(3) {*/
        /*    width: 35% !important;*/
        /*}*/
        /*.carousel_list /deep/ .ceil:nth-child(2) {*/
        /*    width: 35% !important;*/
        /*}*/

        /*.carousel_list /deep/ .ceil:nth-child(3) {*/
        /*    width: 35% !important;*/
        /*}*/
    }

    .large-right-bottom .dv-border-box-12 {
        height: 410px;
    }

    .large-right-bottom .dv-border-box-8 .dv-decoration-7 {
        width: 200px;
        height: 12px;
        position: absolute;
        top: 20px;
        left: 15px;
    }

    .large-left-bottom-title {
        width: 96.5%;
        height: 63px;
        margin: 0 10px;
        background: linear-gradient(to left, #82c276, #66bbff);
        position: absolute;
        top: 10px;
        text-align: center;
        line-height: 65px;
    }

    .large-left-bottom-up {
        background: rgba(211, 214, 221, 0.1);
        margin: 10px;
        position: absolute;
        top: 65px;
        width: 96.5%;
        height: 120px;
        text-align: center;
        font-size: 50px;
        font-family: 微软雅黑;
        line-height: 120px;
    }

    .large-left-bottom-down {
        width: 100%;
        height: 200px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: absolute;
        top: 200px;
    }

    .large-left-bottom-down div {
        background: rgba(211, 214, 221, 0.1);
        margin: 10px;
        padding: 10px;
        line-height: 80px;
        width: 33%;
        font-size: 30px;
        font-weight: bold;
        text-align: center;
        font-family: 微软雅黑;
    }

    .large-left-bottom-down div p {
        font-size: 26px;
        font-weight: normal;
    }

    .bottom-list .dv-border-box-12 {
        height: 270px;
    }

    .bottom-list .dv-border-box-12 /deep/ .border-box-content {
        display: flex;
        justify-content: start;
        padding: 20px;
    }

    .bottom-list-div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 10px;
    }

    .bottom-list-div .bottom-list-div-image /deep/ .el-image {
        width: 180px;
        height: 200px;
        margin: 0 10px;
    }

    .bottom-list-div .bottom-list-div-font {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        font-size: 16px;
        line-height: 30px;
    }
</style>
