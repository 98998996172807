<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>人员管理</el-breadcrumb-item>
            <el-breadcrumb-item>组织管理</el-breadcrumb-item>
        </el-breadcrumb>
        <el-container>
            <el-aside>
                <el-tree :data="treeData" :highlight-current=true
                         default-expand-all
                         :props="defaultProps"
                         :render-content="renderContent"
                         @node-click="handleNodeClick">
                </el-tree>
            </el-aside>
            <el-main>
                <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="组 织" name="first">
                        <div style="display:flex;flex-direction: row;">
                            <div>
                                <el-button size="small" icon="el-icon-plus" type="primary" @click="handleAdd"
                                           v-show="!is3rd">添加自定义组织
                                </el-button>
                                <el-button size="small" icon="el-icon-plus" type="primary" @click="handleAddIs3rd"
                                           v-show="is3rd || type == 0">添加第三方组织
                                </el-button>
                                <el-button size="small" type="primary" @click="handleOrgDownModel" v-show="type == 0">
                                    下载第三方组织模板
                                </el-button>
                            </div>
                            <el-upload v-show="type == 0" action="" :http-request="handleOrgImport"
                                       :show-file-list="false"
                                       accept=".xls,.xlsx"
                                       :before-upload="handleBeforeAvatar">
                                <el-button size="small" style="margin:0 0 20px 20px" type="success"
                                           icon="el-icon-upload">
                                    导入第三方组织
                                </el-button>
                            </el-upload>
                        </div>
                        <el-table
                                size="small"
                                :data="tableData"
                                highlight-current-row
                                border stripe
                                style="width: 100%;margin-top: 10px;"
                                :row-style="{height:'45px'}"
                                :cell-style="{padding:'0px'}"
                                :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                                height="500" max-height="500">
                            <el-table-column type="index" label="序号"></el-table-column>
                            <el-table-column prop="name" label="组织名称" align="center">
                                <template slot-scope="scope">
                                    <span :style="scope.row.is3rd ? 'color:#409EFF' : '' ">{{scope.row.name}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="nodeNo" label="组织编号" align="center"></el-table-column>
                            <el-table-column prop="type" label="组织性质" align="center">
                                <template slot-scope="scope">
                                    <el-tag :type="scope.row.type == 1 ? 'warning' : ''">
                                        {{scope.row.type == 1 ? '机构' : scope.row.type == 2 ? '部门' : ''}}
                                    </el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="操作">
                                <template slot-scope="scope">
                                    <el-button size="mini"
                                               @click="handleEdit(scope.$index, scope.row)"
                                               icon="el-icon-setting"
                                               type="primary">编辑
                                    </el-button>
                                    <el-button size="mini" type="danger"
                                               class="el-icon-delete"
                                               @click="handleDelete(scope.$index, scope.row)">删除
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane v-if="type != 0 && type != ''" label="人 员" name="second">
                        <el-form :inline="true" :model="filters" class="user-search">
                            <div style="display: flex;flex-direction: column;">
                                <div style="height: 50px;">
                                    <el-form-item style="width: 15%">
                                        <el-input v-model="filters.name" clearable size="small" placeholder="人员姓名"/>
                                    </el-form-item>
                                    <el-form-item style="width: 15%">
                                        <el-input v-model="filters.userNo" clearable size="small" placeholder="人员编号"/>
                                    </el-form-item>
                                    <el-form-item style="width: 15%">
                                        <el-input v-model="filters.phone" clearable size="small" placeholder="电话号码"/>
                                    </el-form-item>
                                    <el-form-item style="width: 15%">
                                        <el-input v-model="filters.id" clearable size="small" placeholder="人员系统编号"/>
                                    </el-form-item>
                                </div>
                                <div style="height: 50px;">
                                    <el-form-item>
                                        <!--                                <div style="display: flex;flex-direction: row;">-->
                                        <!--                                </div>-->

                                        <el-button icon="el-icon-search" type="primary" size="small"
                                                   @click="fetchData(false)">部门下查询
                                        </el-button>
                                        <el-button v-if="!is3rd" icon="el-icon-plus" type="primary" size="small"
                                                   @click="handlePersonnelAdd">
                                            添加
                                        </el-button>
                                        <el-button type="primary" size="small" @click="handleExportExcel">导出人员</el-button>
                                        <el-button icon="el-icon-download" type="primary" size="small"
                                                   @click="handleTemplateExport">模板导出
                                        </el-button>
                                        <!-- accept=".xls,.xlsx" -->
                                        <el-button size="small" type="success" icon="el-icon-upload"
                                                   @click="handleUpload">
                                            批量导入
                                        </el-button>
                                        <el-button icon="el-icon-bangzhu" type="primary" size="small"
                                                   @click="fetchData(true)">全局搜索
                                        </el-button>
                                    </el-form-item>
                                </div>
                            </div>
                        </el-form>
                        <el-table
                                id="out-table"
                                ref="multipleTable"
                                size="small"
                                :data="tableList"
                                highlight-current-row
                                border stripe
                                style="width: 100%;"
                                :row-style="{height:'45px'}"
                                :cell-style="{padding:'0px'}"
                                :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                                height="460" max-height="460">
                            <el-table-column type="index" label="序号"></el-table-column>
                            <el-table-column width="160" prop="userNo" label="人员编号" align="center"></el-table-column>
                            <el-table-column prop="name" label="姓名" align="center"></el-table-column>
                            <el-table-column prop="phone" label="手机号" align="center"></el-table-column>
                            <el-table-column prop="avatar" label="头像" align="center" v-if="oprationShow">
                                <template slot-scope="scope">
                                    <el-image
                                            style="width: 50px; height: 50px"
                                            :src="scope.row.avatar?scope.row.avatar:require('../../../assets/images/defaultImg.png')"
                                            :preview-src-list="[scope.row.avatar?scope.row.avatar:require('../../../assets/images/defaultImg.png')]">
                                    </el-image>
                                </template>
                            </el-table-column>
                            <el-table-column prop="avatar" label="头像" align="center" v-if="imgShow"></el-table-column>
                            <el-table-column prop="passNo" label="门禁卡号" align="center"></el-table-column>
                            <el-table-column width="160" prop="idCardNo" label="身份证" align="center"></el-table-column>
                            <el-table-column v-if="global" prop="orgName" label="部门名称" align="center"></el-table-column>
                            <el-table-column prop="sex" label="性别" align="center">
                                <template slot-scope="scope">
                                    <el-tag v-if="scope.row.sex"
                                            :type="scope.row.sex == 1 ? '' : scope.row.sex == 2 ? 'danger' : 'info'">
                                        {{scope.row.sex == 1 ? '男' : scope.row.sex == 2 ? '女' : scope.row.sex == 3 ?
                                        '未知' : ''}}
                                    </el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column prop="addr" label="详细住址" align="center"></el-table-column>
                            <el-table-column prop="id" label="人员系统编号" align="center"></el-table-column>
                            <el-table-column width="350" fixed="right" align="center" label="操作" v-if="oprationShow">
                                <template slot-scope="scope">
                                    <el-button size="mini" @click="handleAuthorization(scope.$index, scope.row)"
                                               type="success">授权
                                    </el-button>
                                    <el-button size="mini" @click="handleRevoke(scope.$index, scope.row)"
                                               type="warning">撤销授权
                                    </el-button>
                                    <el-button size="mini"
                                               @click="handlePersonnelEdit(scope.$index, scope.row)"
                                               icon="el-icon-setting" type="primary">编辑
                                    </el-button>
                                    <el-button size="mini" type="danger" class="el-icon-delete"
                                               @click="handlePersonnelDelete(scope.$index, scope.row)">删除
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-pagination background
                                       @size-change="handleSizeChange"
                                       @current-change="handleCurrentChange"
                                       :current-page="filters.current"
                                       :page-sizes="[10, 50, 200, 1000, 2000]"
                                       :page-size="filters.size"
                                       layout="total, sizes, prev, pager, next, jumper"
                                       :total="total">
                        </el-pagination>
                    </el-tab-pane>
                </el-tabs>
            </el-main>
            <!--    组织管理-->
            <el-dialog v-draggable :title="title" :visible.sync="visibleDialog" width="30%">
                <el-form :model="visibleForm" ref="visibleForm" size="small" :rules="rules"
                         label-width="130px">
                    <el-form-item label="组织名称" prop="orgName">
                        <el-input style="width: 70%" size="small" v-model="visibleForm.orgName"
                                  placeholder="请输入组织名称"/>
                    </el-form-item>
                    <el-form-item label="组织编号" prop="orgNo">
                        <el-input style="width: 70%" size="small" v-model="visibleForm.orgNo"
                                  placeholder="请输入组织编号"/>
                    </el-form-item>
                    <el-form-item v-show="title=='编辑组织'" label="上级组织" prop="nodeNo">
                        <el-cascader ref="nodeNo" size="small" v-model="visibleForm.value"
                                     :props="{ checkStrictly: true, children: 'subList',
                                             value: 'id',label: 'name',}"
                                     :options="treeData" @change="handleSelectNodeNo">
                        </el-cascader>
                    </el-form-item>
                    <el-form-item label="组织性质" prop="type">
                        <el-select size="small" v-model="visibleForm.type" placeholder="请选择组织性质">
                            <el-option v-if="type != 2" label="机构" :value="1"></el-option>
                            <el-option v-if="type != 0" label="部门" :value="2"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="visibleDialog = false">取 消</el-button>
                    <el-button type="primary" @click="onSubmit('visibleForm')">确 定</el-button>
                </span>
            </el-dialog>
            <!--    人员管理-->
            <el-dialog v-draggable :title="personnelTitle" :visible.sync="personnelDialog">
                <el-form :model="personnelForm" ref="personnelForm" size="small" :rules="rules"
                         label-width="100px">
                    <el-row>
                        <el-col :span="11">
                            <el-form-item label="姓名" prop="name">
                                <el-input size="small" v-model="personnelForm.name"
                                          placeholder="请输入内容"/>
                            </el-form-item>
                            <el-form-item label="手机号" prop="phone">
                                <el-input size="small" v-model="personnelForm.phone"
                                          placeholder="请输入内容"/>
                            </el-form-item>
                            <el-form-item label="身份证号" prop="idCardNo">
                                <el-input size="small" v-model="personnelForm.idCardNo"
                                          placeholder="请输入内容"/>
                            </el-form-item>
                            <el-form-item label="人员系统编号" prop="id">
                                <el-input disabled size="small" v-model="personnelForm.id"/>
                            </el-form-item>
                            <el-form-item label="人员编号" prop="userNo">
                                <el-input size="small" v-model="personnelForm.userNo"
                                          placeholder="人员编号"/>
                            </el-form-item>
                            <el-form-item label="详细住址" prop="addr">
                                <el-input size="small" v-model="personnelForm.addr"
                                          placeholder="请输入内容"/>
                            </el-form-item>
                            <el-form-item label="所属组织">
                                <el-cascader style="width: 100%;" ref="xxxxLabel"
                                             :props="{ checkStrictly: true, children: 'subList',
                                             value: 'id',label: 'name',}"
                                             size="small" v-model="personnelForm.value"
                                             :options="treeData" @change="handleSelect">
                                </el-cascader>
                            </el-form-item>
                            <el-form-item label="是否第三方" prop="is3rd">
                                <el-radio-group size="small" v-model="personnelForm.is3rd">
                                    <el-radio :label="true">是</el-radio>
                                    <el-radio :label="false">否</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span="11">
                            <el-form-item label="性别" prop="sex">
                                <el-select style="width: 100%" size="small" v-model="personnelForm.sex"
                                           placeholder="请选择性别">
                                    <el-option label="男" :value="1"></el-option>
                                    <el-option label="女" :value="2"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="门禁卡号" prop="passNo">
                                <el-input size="small" v-model="personnelForm.passNo"
                                          oninput="value=value.replace(/[^\d]/g,'')"
                                          placeholder="请输入内容"/>
                            </el-form-item>
                            <el-form-item label="自定义标签" prop="tag">
                                <el-input size="small" v-model="personnelForm.tag"
                                          placeholder="请输入内容"/>
                            </el-form-item>
                            <el-form-item label="通行密码" prop="password">
                                <el-input v-model="personnelForm.password" placeholder="密码"
                                          show-password auto-complete="new-password"></el-input>
                            </el-form-item>
                            <el-form-item label="头像" prop="avatar">
                                <el-tabs v-model="activeNameAvatar" type="card">
                                    <el-tab-pane label="上传" name="first">
                                        <div style="width:178px;height:178px;position:relative;">
                                            <el-upload
                                                    class="avatar-uploader"
                                                    action="#"
                                                    :multiple="false"
                                                    name="file"
                                                    :auto-upload="false"
                                                    :show-file-list="false"
                                                    :on-change="handleChange">
                                                <img v-if="personnelForm.avatar" :src="personnelForm.avatar"
                                                     class="avatar">
                                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                            </el-upload>
                                        </div>
                                    </el-tab-pane>
                                    <el-tab-pane label="示例" name="third">
                                        <el-image style="width: 80%; height: 80%"
                                                  :src="require('../../../assets/images/example.jpg')"
                                                  :preview-src-list="[require('../../../assets/images/example.jpg')]">
                                        </el-image>
                                    </el-tab-pane>
                                    <el-tab-pane label="拍照" name="second">
                                        <img v-if="personnelForm.avatar" :src="personnelForm.avatar"
                                             class="avatar" @click="handleSetImage()">
                                        <i v-else class="el-icon-plus avatar-uploader-icon"
                                           @click="handleSetImage()"></i>
                                    </el-tab-pane>
                                </el-tabs>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="personnelDialog = false">取 消</el-button>
                    <el-button type="primary" @click="submitForm('personnelForm')">确 定</el-button>
                </span>
            </el-dialog>
            <!--    添加授权-->
            <el-dialog v-draggable title="添加授权" :visible.sync="authorizationDialog">
                <el-container>
                    <el-aside>
                        <el-tree
                                :data="childrenTreeData"
                                default-expand-all
                                ref="childrenTree"
                                node-key="id"
                                show-checkbox
                                :props="defaultChildrenProps"
                                @check-change="handleAddCheckClick">
                        </el-tree>
                    </el-aside>
                    <el-main>
                        <div class="block">
                            <span class="demonstration">有效期：</span>
                            <el-date-picker
                                    size="small"
                                    v-model="date"
                                    type="datetimerange"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    value-format="yyyy-MM-dd HH:mm:ss">
                            </el-date-picker>
                        </div>
                        <el-table
                                size="small"
                                :data="authTableData"
                                highlight-current-row
                                border stripe
                                style="width: 100%;"
                                :row-style="{height:'45px'}"
                                :cell-style="{padding:'0px'}"
                                :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                                height="310" max-height="310">
                            <el-table-column type="index" label="序号"></el-table-column>
                            <el-table-column prop="doorwayName" label="通道名称" align="center"></el-table-column>
                            <el-table-column prop="direction" label="通道出入类型" align="center">
                                <template slot-scope="scope">
                                    <el-tag v-if="scope.row.direction != null"
                                            :type="scope.row.direction == 1 ? '' : 'info'">
                                        {{scope.row.direction == 1 ? '入口' : scope.row.direction == 2 ? '出口' : ''}}
                                    </el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column prop="areaName" label="区域名称" align="center"></el-table-column>
                        </el-table>
                        <el-pagination background
                                       @size-change="handleSizeChangeTwo"
                                       @current-change="handleCurrentChangeTwo"
                                       :current-page="filtersAuth.current"
                                       :page-sizes="[10, 50, 200, 1000]"
                                       :page-size="filtersAuth.size"
                                       layout="total, sizes, prev, pager, next, jumper"
                                       :total="totalAuth">
                        </el-pagination>
                    </el-main>
                </el-container>
                <span slot="footer" class="dialog-footer">
                <el-button @click="authorizationDialog = false">取 消</el-button>
                <el-button type="primary" @click="onSubmitAuthorization()">确 定</el-button>
            </span>
            </el-dialog>
            <!--    撤销授权-->
            <el-dialog v-draggable title="撤销授权" :visible.sync="revokeDialog">
                <el-table
                        ref="revokeData"
                        @selection-change="handleSelectionChange"
                        size="small"
                        :data="authTableData"
                        highlight-current-row
                        border stripe
                        style="width: 100%;"
                        :row-style="{height:'45px'}"
                        :cell-style="{padding:'0px'}"
                        :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                        height="310" max-height="310">
                    <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column type="index" label="序号"></el-table-column>
                    <el-table-column prop="doorwayName" label="通道名称" align="center"></el-table-column>
                    <el-table-column prop="direction" label="通道出入类型" align="center">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.direction != null"
                                    :type="scope.row.direction == 1 ? '' : 'info'">
                                {{scope.row.direction == 1 ? '入口' : scope.row.direction == 2 ? '出口' : ''}}
                            </el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="areaName" label="区域名称" align="center"></el-table-column>
                </el-table>
                <el-pagination background
                               @size-change="handleSizeChangeTwo"
                               @current-change="handleCurrentChangeTwo"
                               :current-page="filtersAuth.current"
                               :page-sizes="[10, 50, 200, 1000]"
                               :page-size="filtersAuth.size"
                               layout="total, sizes, prev, pager, next, jumper"
                               :total="totalAuth">
                </el-pagination>
                <span slot="footer" class="dialog-footer">
                <el-button @click="revokeDialog = false">取 消</el-button>
                <el-button type="primary" @click="onSubmitRevoke()">确 定</el-button>
            </span>
            </el-dialog>
            <!--    拍照上传-->
            <el-dialog v-draggable title="拍照上传" :visible.sync="dialogVisible" width="60%" @close="handleClose" center>
                <el-row>
                    <el-col :span="12" style="text-align: center">
                        <div style="text-align: center;font-size: 14px;font-weight: bold;margin-bottom: 10px;">摄像头</div>
                        <!-- 这里就是摄像头显示的画面 -->
                        <video id="videoCamera" :width="videoWidth" :height="videoHeight" autoplay></video>
                        <div class="iCenter">
                            <el-button type='primary' size='small' icon="el-icon-camera" @click="takePhone"
                                       style="margin-top: 10px;">拍照
                            </el-button>
                            <el-button @click="getCompetence" icon="el-icon-video-camera" size="small"> 打开摄像头
                            </el-button>
                            <el-button size="small" @click="stopNavigator" icon="el-icon-switch-button"
                                       style="margin-top: 10px;">关闭摄像头
                            </el-button>
                        </div>
                    </el-col>
                    <el-col :span="12" style="text-align: center">
                        <div style="text-align: center;font-size: 14px;font-weight: bold;margin-bottom: 10px;">拍摄效果
                        </div>
                        <!-- 这里是点击拍照显示的图片画面 -->
                        <canvas id="canvasCamera" :width="videoWidth" :height="videoHeight"></canvas>
                        <el-button icon="el-icon-check" type="primary" size="small" @click="setImage"
                                   style="margin-top: 10px;">保存
                        </el-button>
                        <el-button size="small" @click="handleClose" icon="el-icon-close" style="margin-top: 10px;">取消
                        </el-button>
                    </el-col>
                </el-row>
            </el-dialog>
            <!--    文件导入错误数据-->
            <el-dialog v-draggable title="导入结果" :visible.sync="dialogTableVisible" width="90%">
                <el-row>
                    <el-col :span="6"><span>成功导入：{{successNum}}</span></el-col>
                    <el-col :span="6"><span>失败导入：{{failNum}}</span></el-col>
                    <el-col :span="6">导入总条数：{{successNum + failNum}}</el-col>
                    <el-col :span="6">
                        <el-button type="primary" size="mini" @click="dialogTableVisible = false">关闭</el-button>
                    </el-col>
                </el-row>
                <!--列表-->
                <el-table
                        v-if="importData.length > 0"
                        size="small"
                        :data="importData"
                        highlight-current-row
                        border stripe
                        style="width: 100%;margin-top: 10px;"
                        :row-style="{height:'45px'}"
                        :cell-style="{padding:'0px'}"
                        :header-cell-style="{background:'#eef1f6',color:'#606266'}">
                    <el-table-column min-width="30" type="index" label="序号"></el-table-column>
                    <el-table-column prop="errorMsg" label="错误信息" align="center"></el-table-column>
                    <el-table-column width="260" prop="userNo" label="人员编号" align="center"></el-table-column>
                    <el-table-column prop="name" label="姓名" align="center"></el-table-column>
                    <el-table-column prop="sex" label="性别" align="center">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.sex != null"
                                    :type="scope.row.sex == 1 ? '' : scope.row.sex == 2 ? 'danger' : 'info'">
                                {{scope.row.sex == 1 ? '男' : scope.row.sex == 2 ? '女' : '未知'}}
                            </el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="phone" label="手机号" align="center"></el-table-column>
                    <el-table-column prop="passNo" label="通行卡号" align="center"></el-table-column>
                    <el-table-column prop="idCardNo" label="身份证" align="center"></el-table-column>
                    <!--                    <el-table-column width="160" prop="id" label="人员系统编号" align="center"></el-table-column>-->
                </el-table>
            </el-dialog>

            <el-dialog v-draggable title="导入结果" :visible.sync="importDialog" width="90%">
                <el-row>
                    <el-col :span="6"><span>成功导入：{{successOrgNum}}</span></el-col>
                    <el-col :span="6"><span>失败导入：{{failOrgNum}}</span></el-col>
                    <el-col :span="6">导入总条数：{{successOrgNum + failOrgNum}}</el-col>
                    <el-col :span="6">
                        <el-button type="primary" size="mini" @click="importDialog = false">关闭</el-button>
                    </el-col>
                </el-row>
                <!--列表-->
                <el-table
                        v-if="importOrgData.length > 0"
                        size="small"
                        :data="importOrgData"
                        highlight-current-row
                        border stripe
                        style="width: 100%;margin-top: 10px;"
                        :row-style="{height:'45px'}"
                        :cell-style="{padding:'0px'}"
                        :header-cell-style="{background:'#eef1f6',color:'#606266'}">
                    <el-table-column min-width="30" type="index" label="序号"></el-table-column>
                    <el-table-column prop="name" label="组织名称" align="center"></el-table-column>
                    <el-table-column prop="type" label="组织性质" align="center">
                        <template slot-scope="scope">
                            <el-tag :type="scope.row.type == 1 ? 'warning' : ''">
                                {{scope.row.type == 1 ? '机构' : scope.row.type == 2 ? '部门' : ''}}
                            </el-tag>
                        </template>
                    </el-table-column>
                </el-table>
            </el-dialog>
            <!--    批量导入    -->
            <el-dialog v-draggable title="导入提示" :visible.sync="batchImportVisible" top="5vh">
                <div style="display: flex;flex-direction: column;justify-content: center;align-items:center;margin: 25px;">
                    <h4>注意：导入会覆盖原来的人员和授权数据，请谨慎操作！</h4>
                    <div style="margin: 25px;">
                        <span style="margin-right: 20px">唯一标识</span>
                        <el-radio v-model="distItem" label="phone">手机号</el-radio>
                        <el-radio v-model="distItem" label="userNo">人员编号</el-radio>
                    </div>
                    <h4 style="color: red;">注意：头像照片尽量按照示例规范提供，以免出现授权失败的情况！</h4>
                    <el-image style="width: 80%; height: 80%"
                              :src="require('../../../assets/images/example.jpg')"
                              :preview-src-list="[require('../../../assets/images/example.jpg')]">
                    </el-image>
                </div>
                <span slot="footer" class="dialog-footer" style="display: flex;justify-content: end;">
                    <el-button @click="batchImportVisible = false">取 消</el-button>
                    <!--<el-upload class="upload-demo inline-block"
                               ref="upload"
                               :multiple="true" action=""
                               :show-file-list="false"
                               :auto-upload="false"
                               :on-change="handleOnChange">
                        <el-button type="primary">确 定</el-button>
                    </el-upload>-->
                    <el-upload class="upload-demo inline-block"
                               action="#" ref="upload" :multiple="true"
                               :before-upload="beforeUploadImage"
                               :http-request="uploadSuccess"
                               :on-change="handleOnChange"
                               :show-file-list="false">
                        <el-button type="primary">确 定</el-button>
                    </el-upload>
                </span>
            </el-dialog>
            <!--       超过图片     -->
            <el-dialog v-draggable title="提示" :visible.sync="dialogImageVisible" width="40%" top="5%">
                <p style="margin-bottom: 20px;">注意：上传文件大小不能超过 2MB，请重新上传</p>
                <el-table
                        size="small"
                        :data="imgList"
                        highlight-current-row
                        border stripe
                        style="width: 100%;"
                        :row-style="{height:'45px'}"
                        :cell-style="{padding:'0px'}"
                        :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                        height="460" max-height="460">
                    <el-table-column type="index" label="序号"></el-table-column>
                    <el-table-column prop="name" label="图片名称" align="center"></el-table-column>
                </el-table>
            </el-dialog>
            <!--        选择导出文件类型-->
            <el-dialog v-draggable title="文件类型" :visible.sync="selectDialog" width="250px">
                <el-form :model="exportForm" ref="exportForm" size="small" :rules="exportRules">
                    <el-form-item prop="radio" label-width="50px">
                        <el-radio v-model="exportForm.radio" label="xls">xls</el-radio>
                        <el-radio v-model="exportForm.radio" label="xlsx">xlsx</el-radio>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="selectDialog = false">取 消</el-button>
        <el-button size="small" type="primary" @click="onExportExcel('exportForm')">确 定</el-button>
        </span>
            </el-dialog>
        </el-container>
    </div>
</template>

<script>
    import {
        addOrg,
        addUser,
        delOrg,
        delUser, downTemplate,
        editOrg,
        editUser,
        getUser,
        handleTreeData, importTemplate, orgDownModel, orgImport, uploadFile
    } from "../../../api/organizationApi";
    import {getTreeData} from "../../../api/spaceApi";
    import {AddAuth, getUserAuthList, revoke} from "../../../api/authorizationApi";

    export default {
        name: "organizationManagement",
        data() {
            return {
                oprationShow: true,
                imgShow: false,
                activeName: 'first',
                type: "0",
                treeData: [],
                defaultProps: {
                    children: 'subList',
                    value: 'id',
                    label: 'name',
                },
                tableData: [],
                title: "",
                visibleDialog: false,
                visibleForm: {
                    orgName: "",
                    type: "",
                    id: "",
                    parentId: "",
                    is3rd: "",
                    orgNo: "",
                    nodeNo: "",
                    value: [],
                },
                rules: {
                    orgName: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ],
                    sex: [
                        {required: true, message: '请选择', trigger: 'change'},
                    ],
                    name: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ],
                    phone: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ],
                    idCardNo: [
                        {
                            pattern: /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/,
                            message: "请输入合法身份证号",
                            trigger: "blur"
                        }
                    ],
                },
                filters: {
                    name: '',
                    userNo: '',
                    phone: '',
                    id: '',
                    current: 1,
                    size: 10,
                },
                total: 0,
                tableList: [],
                personnelTitle: "",
                personnelDialog: false,
                personnelForm: {
                    avatar: "",
                    name: "",
                    idCardNo: "",
                    passNo: "",
                    phone: "",
                    sex: "",
                    userNo: "",
                    tag: "",
                    addr: "",
                    id: "",
                    orgId: "",
                    password: "",
                    value: [],
                    is3rd: ""
                },
                idNumber: "",
                orgId: "",
                authorizationDialog: false,
                childrenTreeData: [],
                defaultChildrenProps: {
                    children: 'subList',
                    label: 'name',
                },
                doorwayIds: [],
                userIds: [],
                totalAuth: 0,
                authTableData: [],
                filtersAuth: {
                    current: 1,
                    size: 10,
                    userId: '',
                },
                date: [],
                startTime: '',
                endTime: '',
                revokeDialog: false,
                multipleSelection: [],
                deviceStatus: [],

                activeNameAvatar: 'first',
                dialogVisible: false,
                videoWidth: 400,
                videoHeight: 300,
                imgSrc: "",
                thisCancas: null,
                thisContext: null,
                thisVideo: null,
                openVideo: false,

                dialogTableVisible: false,
                importData: [],
                successNum: "",
                failNum: "",

                formFile: [],
                arrList: [],
                fileList: [],
                fileExecl: [],
                file: "",
                num: 0,
                timer: "",
                imgList: [],
                base64: '',
                options: [],
                is3rd: "",
                loading: "",
                importDialog: false,
                importOrgData: [],
                failOrgNum: 0,
                successOrgNum: 0,
                batchImportVisible: false,
                distItem: "phone",
                dialogImageVisible: false,
                global: false,
                exportForm: {
                    radio: ""
                },
                exportRules: {
                    radio: [
                        {required: true, message: '请选择导出类型', trigger: 'change'}
                    ],
                },
                selectDialog: false,
                flag: true,
            };
        },
        watch: {
            fileList: function () {
                // console.log(this.formFile.length - 1, '111111111')
                // console.log(this.fileList.length, '00000000')
                if (this.formFile.length - 1 === this.fileList.length) {
                    this.$nextTick(() => {
                        this.fileExecl = [];
                        this.beforeUploadFile(this.file.raw, this.fileList).then(res => {
                            this.fileExecl = res;
                            // console.log(this.fileExecl)
                            this.fileList = [];
                            this.$refs['upload'].clearFiles();
                            this.fileExecl = Array.from(new Set(this.fileExecl.map(JSON.stringify))).map(JSON.parse);
                            // 计算需要切片的次数
                            // console.log(res.length)
                            var groupCount = Math.ceil(this.fileExecl.length / 100);
                            // console.log(groupCount)
                            // 创建空白的结果数组
                            var resultArr = new Array(groupCount).fill().map(() => []);
                            // console.log(resultArr)
                            // 对原始数组进行切片并放入结果数组中
                            for (let i = 0; i < this.fileExecl.length; i++) {
                                var index = Math.floor(i / 100);
                                resultArr[index].push(this.fileExecl[i]);
                            }
                            this.httpRequest(resultArr);
                            this.$refs['upload'].clearFiles();
                            // console.log(resultArr)
                        })
                    });
                    return
                }
            },
            "tableList": {
                handler() {
                    this.$nextTick(() => {
                        this.$refs.multipleTable.doLayout();
                    });
                },
                deep: true

            }
        },
        mounted() {
            this.handleGetTreeData();
        },
        methods: {
            handleClick(tab) {
                // if (tab.name == "second") {
                // }
            },
            handleGetTreeData() {
                let param = {
                    isUser: false
                };
                handleTreeData(param).then((res) => {
                    let data = {
                        type: '0',
                        id: 0,
                        is3rd: false,
                        'name': localStorage.getItem('projectName'),
                        'subList': res.data.data
                    };
                    this.treeData = [data];
                    this.tableData = data.subList;
                    this.options = this.getTreeData([data]);
                    // console.log(this.treeData)
                }).catch(error => {
                    console.log(error);
                });
            },
            getTreeData(data) {
                for (var i = 0; i < data.length; i++) {
                    if (data[i].subList.length < 1) {
                        // children若为空数组，则将children设为undefined
                        data[i].subList = undefined
                    } else {
                        // children若不为空数组，则继续 递归调用 本方法
                        this.getTreeData(data[i].subList)
                    }
                }
                return data
            },
            handleNodeClick(data) {
                // console.log(data)
                this.visibleForm.parentId = data.id;
                this.type = data.type;
                this.is3rd = data.is3rd ? data.is3rd : false;
                this.tableData = data.subList;
                if (data.type == 1) {
                    this.orgId = data.id;
                    this.activeName = 'first';
                    this.filters.current = 1;
                    this.fetchData()
                } else if (data.type == 2) {
                    this.activeName = 'second';
                    this.orgId = data.id;
                    this.filters.current = 1;
                    this.fetchData();
                } else {
                    this.activeName = 'first';
                }
            },
            renderContent(h, {node, data, store}) {
                if (data.is3rd == true) {
                    return <span style = "color:#409EFF"> {node.label} </span>;
                } else {
                    return <span> {node.label} </span>;
                }
            },
            handleOrgDownModel() {
                orgDownModel().then((res) => {
                    this.$FileSaver(res.data, "组织结构导入模板.xls");
                });
            },
            handleOrgImport(param) {
                let formData = new FormData();
                formData.append("file", param.file);
                formData.append("is3rd", true);
                this.$confirm('导入组织需要删除原来的组织数据以及组织下的人员数据后全部重新导入，请谨慎操作！确定需要导入吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    orgImport(formData).then((res) => {
                        if (res.data.code == "SUCCESS") {
                            this.importDialog = true;
                            this.importOrgData = res.data.data.errorData;
                            this.failOrgNum = res.data.data.failNum;
                            this.successOrgNum = res.data.data.successNum;
                            this.$message.success(res.data.message);
                            this.handleGetTreeData();
                        }
                    })
                }).catch(() => {
                    this.$message.info('已取消导入');
                });
            },
            //验证文件类型
            handleBeforeAvatar(file) {
                const isXls = file.type === "application/vnd.ms-excel" ? true : file.type ===
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? true : false;
                if (!isXls) {
                    this.$message.error("上传的文件只能是xls以及xlsx格式!");
                }
                return isXls;
            },
            //添加
            handleAdd() {
                this.title = "添加自定义组织";
                this.visibleDialog = true;
                this.visibleForm.id = "";
                this.visibleForm.orgName = "";
                if (this.visibleForm.parentId == 0 || this.visibleForm.parentId == undefined) {
                    this.visibleForm.parentId = "";
                    this.visibleForm.type = 1;
                } else {
                    this.visibleForm.type = 2;
                }
                this.visibleForm.is3rd = false;
            },
            handleAddIs3rd() {
                this.title = "添加第三方组织";
                this.visibleDialog = true;
                this.visibleForm.id = "";
                this.visibleForm.orgName = "";
                if (this.visibleForm.parentId == 0 || this.visibleForm.parentId == undefined) {
                    this.visibleForm.parentId = "";
                    this.visibleForm.type = 1;
                } else {
                    this.visibleForm.type = 2;
                }
                this.visibleForm.is3rd = true;
            },
            onSubmit(visibleForm) {
                this.$refs[visibleForm].validate((valid) => {
                        if (valid) {
                            let params = {
                                orgName: this.visibleForm.orgName,
                                type: this.visibleForm.type,
                                id: this.visibleForm.id,
                                parentId: this.visibleForm.parentId == 0 ? this.visibleForm.parentId = null : this.visibleForm.parentId,
                                nodeNo: this.visibleForm.nodeNo,
                                orgNo: this.visibleForm.orgNo,
                                is3rd: this.visibleForm.is3rd
                            };
                            if (this.visibleForm.id) {
                                editOrg(params).then((res) => {
                                    this.visibleDialog = false;
                                    if (res.data.code == "SUCCESS") {
                                        this.handleGetTreeData();
                                        this.$message.success("编辑成功！");
                                    } else {
                                        this.$message.info(res.data.message);
                                    }
                                })
                            } else {
                                addOrg(params).then((res) => {
                                    this.visibleDialog = false;
                                    if (res.data.code == "SUCCESS") {
                                        this.handleGetTreeData();
                                        this.$message.success("保存成功！");
                                    } else {
                                        this.$message.info(res.data.message);
                                    }
                                })
                            }
                        }
                    }
                )
            },
            //编辑
            handleEdit(index, row) {
                this.title = "编辑组织";
                this.visibleDialog = true;
                Object.keys(this.visibleForm).map(key => {
                    this.visibleForm[key] = row[key];
                });
                this.visibleForm.id = row.id;
                this.visibleForm.type = row.type;
                this.visibleForm.orgName = row.name;
                this.visibleForm.orgNo = row.nodeNo;
                this.visibleForm.value = this.changeDetSelect(row.parentId, this.options);
            },
            //删除
            handleDelete(index, row) {
                this.$confirm("确定要删除" + row.name + "吗?", "温馨提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    delOrg(row.id).then((res) => {
                        if (res.data.code == "SUCCESS") {
                            this.handleGetTreeData();
                            this.$message.success("删除成功！");
                        } else {
                            this.$message.info(res.data.message);
                        }
                    })
                }).catch(() => {
                    this.$message.info("已取消删除!");
                });
            },
            //人员列表
            fetchData(val) {
                this.global = val;
                let params = {
                    name: this.filters.name,
                    userNo: this.filters.userNo,
                    phone: this.filters.phone,
                    id: this.filters.id,
                    size: this.filters.size,
                    current: this.filters.current,
                    orgId: this.orgId,
                    global: val
                };
                getUser(params).then((res) => {
                    this.tableList = res.data.data.records;
                    this.total = res.data.data.total;
                    this.filters.current = res.data.data.current;
                    this.filters.size = res.data.data.size;
                })
            },
            handleSizeChange(val) {
                this.filters.size = val;
                this.filters.current = 1;
                this.fetchData(this.global);
            },
            handleCurrentChange(val) {
                this.filters.current = val;
                this.fetchData(this.global);
            },
            handlePersonnelAdd() {
                this.personnelTitle = "内部人员信息";
                Object.keys(this.personnelForm).map(key => {
                    this.personnelForm[key] = '';
                })
                this.activeNameAvatar = 'first';
                this.personnelForm.orgId = this.orgId;
                this.personnelForm.value = [];
                this.personnelForm.value = this.changeDetSelect(this.orgId, this.options);
                this.personnelDialog = true;
            },
            handlePersonnelEdit(index, row) {
                this.personnelTitle = "内部人员信息";
                this.personnelDialog = true;
                Object.keys(this.personnelForm).map(key => {
                    this.personnelForm[key] = row[key];
                });
                this.personnelForm.value = [];
                this.personnelForm.value = this.changeDetSelect(row.orgId, this.options);
                this.activeNameAvatar = 'first';
                this.idNumber = row.idCardNo;
                this.filtersAuth.userIds = row.id;
                this.filtersAuth.current = 1;
                this.orgId = row.orgId;
                this.authorizationData();
            },
            handleSelect() {
                const checkedNodes = this.$refs['xxxxLabel'].getCheckedNodes();
                this.personnelForm.orgId = checkedNodes[0].value;
            },
            handleSelectNodeNo() {
                const checkedNodes = this.$refs['nodeNo'].getCheckedNodes();
                this.visibleForm.parentId = checkedNodes[0].value;
            },
            changeDetSelect(key, treeData) {
                if (!key) {
                    key = 0
                }
                let arr = []; // 在递归时操作的数组
                let returnArr = []; // 存放结果的数组
                let depth = 0; // 定义全局层级
                // 定义递归函数
                function childrenEach(childrenData, depthN) {
                    for (var j = 0; j < childrenData.length; j++) {
                        depth = depthN; // 将执行的层级赋值 到 全局层级
                        arr[depthN] = childrenData[j].id;
                        if (childrenData[j].id == key) {
                            returnArr = arr.slice(0, depthN + 1); //将目前匹配的数组，截断并保存到结果数组，
                            break;
                        } else {
                            if (childrenData[j].subList) {
                                depth++;
                                childrenEach(childrenData[j].subList, depth);
                            }
                        }
                    }
                    return returnArr;
                }

                return childrenEach(treeData, depth);
            },
            submitForm(personnelForm) {
                this.$refs[personnelForm].validate((valid) => {
                    if (valid) {
                        /*for (var i = 0; i < this.options.length; i++) {
                            if (this.options[i].subList) {
                                for (var j = 0; j < this.options[i].subList.length; j++) {
                                    if (this.options[i].subList[j].id == this.personnelForm.orgId) {
                                        if (this.options[i].subList[j].type == 1) {
                                            this.$message.warning("人员不能直接分配在组织下，只能分配在部门下，请重新选择！！");
                                            return;
                                        }
                                    }
                                }
                            } else if (this.options[i].id == this.personnelForm.orgId) {
                                if (this.options[i].type == 1) {
                                    this.$message.warning("人员不能直接分配在组织下，只能分配在部门下，请重新选择！！");
                                }
                                return;
                            }
                        }*/
                        let param = {
                            id: this.personnelForm.id,
                            avatar: this.personnelForm.avatar,
                            idCardNo: this.personnelForm.idCardNo,
                            name: this.personnelForm.name,
                            passNo: this.personnelForm.passNo,
                            phone: this.personnelForm.phone,
                            sex: this.personnelForm.sex,
                            userNo: this.personnelForm.userNo,
                            tag: this.personnelForm.tag,
                            addr: this.personnelForm.addr,
                            orgId: this.personnelForm.orgId,
                            password: this.personnelForm.password,
                            is3rd: this.personnelForm.is3rd,
                        }
                        if (this.personnelForm.id) {
                            if (this.authTableData.length > 0) {
                                if (this.idNumber != this.personnelForm.idCardNo) {
                                    this.$message.warning("请撤销授权再更改身份证号！");
                                    return;
                                }
                            }
                            editUser(param).then((res) => {
                                this.personnelDialog = false;
                                this.orgId = this.personnelForm.orgId;
                                this.fetchData();
                                if (res.data.code == "SUCCESS") {
                                    this.$message.success("编辑成功！");
                                } else {
                                    this.$message.info(res.data.message);
                                }
                            })
                        } else {
                            addUser(param).then((res) => {
                                this.personnelDialog = false;
                                this.orgId = this.personnelForm.orgId;
                                this.fetchData();
                                if (res.data.code == "SUCCESS") {
                                    this.$message.success("保存成功！");
                                } else {
                                    this.$message.info(res.data.message);
                                }
                            })
                        }
                    }
                })
            },
            //授权
            handleAuthorization(index, row) {
                this.flag = true;
                this.filtersAuth.userIds = row.id;
                this.userIds = [row.id];
                this.date = [];
                this.filtersAuth.current = 1;
                this.handleTreeDataList();
                this.authorizationData();
            },
            handleAddCheckClick() {
                let doorwayInfo = this.$refs.childrenTree.getCheckedNodes(true);
                this.doorwayIds = [];
                this.deviceStatus = [];
                doorwayInfo.forEach(element => {
                    if (element.type === 2) {
                        this.doorwayIds.push(element.id);
                        this.deviceStatus.push(element.deviceStatus);
                    }
                });
            },
            handleTreeDataList() {
                getTreeData().then((res) => {
                    this.childrenTreeData = res.data.data;
                    if (this.findItem(this.childrenTreeData) == 2) {
                        this.$confirm('有设备不在线，确定需要授权吗？', '温馨提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            this.authorizationDialog = true;
                        }).catch(() => {
                            this.$message.info('已取消授权');
                        });
                        return;
                    } else {
                        this.authorizationDialog = true;
                    }
                })
            },
            findItem(data) {
                if (this.flag) {
                    for (const item of data) {
                        if (!item.subList) {
                            if (item.deviceStatus == 2) {
                                this.flag = false;
                                return item.deviceStatus;
                            }
                        } else {
                            const res = this.findItem(item.subList); //（一直不满足if，一直else，直到满足了if）
                            if (res) {  //res的值是 一直递归循环 直到到最后满足if找到的item，赋值给res。
                                return res;
                            }
                        }
                    }
                }
                return null;
            },
            authorizationData() {
                let params = {
                    size: this.filtersAuth.size,
                    current: this.filtersAuth.current,
                    userId: this.filtersAuth.userIds
                };
                this.authTableData = [];
                getUserAuthList(params).then((res) => {
                    res.data.data.records.forEach(item => {
                        if (item.status == 1) {
                            this.authTableData.push(item);
                        }
                    });
                    // this.authTableData = res.data.data.records;
                    this.totalAuth = res.data.data.total;
                    this.filtersAuth.current = res.data.data.current;
                    this.filtersAuth.size = res.data.data.size;
                    if (this.personnelDialog == false && this.revokeDialog == false) {
                        let arr = [];
                        this.authTableData.forEach(item => {
                            arr.push(item.doorwayId);
                        });
                        this.$nextTick(() => {
                            this.doorwayIds = arr;
                            this.$refs.childrenTree.setCheckedKeys(arr);
                        });
                    }
                })
            },
            onSubmitAuthorization() {
                this.startTime = this.date && this.date.length ? this.date[0] : "";
                this.endTime = this.date && this.date.length ? this.date[1] : "";
                let param = {
                    startTime: this.startTime,
                    endTime: this.endTime,
                    doorwayIds: this.doorwayIds,
                    userIds: this.userIds
                };
                AddAuth(param).then((res) => {
                    this.authorizationDialog = false;
                    if (res.data.code == "SUCCESS") {
                        this.$message.success("授权已执行！");
                    } else {
                        this.$message.info(res.data.message);
                    }
                })
            },
            //撤销授权
            handleRevoke(index, row) {
                this.$confirm('是否需要撤销授权？', '温馨提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.revokeDialog = true;
                    this.filtersAuth.userIds = row.id;
                    this.userIds = [row.id];
                    this.filtersAuth.current = 1;
                    this.authorizationData();
                }).catch(() => {
                    this.$message.info('已取消');
                });
            },
            handleSelectionChange(selection) {
                this.multipleSelection = [];
                selection.forEach(element => {
                    this.multipleSelection.push(element.doorwayId);
                });
            },
            onSubmitRevoke() {
                if (this.multipleSelection.length <= 0) {
                    this.$message.warning("请选择通道！");
                    return;
                }
                let param = {
                    doorwayIds: this.multipleSelection,
                    userIds: this.userIds
                }
                revoke(param).then((res) => {
                    if (res.data.code == "SUCCESS") {
                        this.revokeDialog = false;
                        this.$refs.revokeData.clearSelection();
                        this.$message.success("撤销授权已执行！");
                    } else {
                        this.$message.info(res.data.message);
                    }
                })
            },
            handleSizeChangeTwo(val) {
                this.filtersAuth.size = val;
                this.filtersAuth.current = 1;
                this.authorizationData();
            },
            handleCurrentChangeTwo(val) {
                this.filtersAuth.current = val;
                this.authorizationData();
            },
            //删除
            handlePersonnelDelete(index, row) {
                let data = ["确定要删除" + row.name + "吗?", "删除人员会同步撤销门禁授权，请谨慎操作！"];
                let str = '';
                data.forEach(item => {
                    str += `${item}<br/>`;
                });
                this.$confirm(str, "温馨提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    dangerouslyUseHTMLString: true,
                    type: "warning",
                }).then(() => {
                    delUser([row.id]).then((res) => {
                        if (res.data.code == "SUCCESS") {
                            this.fetchData();
                            this.$message.success("删除成功！");
                        } else {
                            this.$message.info(res.data.message);
                        }
                    })
                }).catch(() => {
                    this.$message.info("已取消删除!");
                });
            },
            handleChange(file) {
                const isLt10M = file.size / 1024 / 1024 < 10;
                var testmsg = /^image\/(jpeg|png|jpg)$/.test(file.raw.type);
                if (!testmsg) {
                    this.$message.error('上传图片只能是 JPEG|PNG|JPG 格式!');
                    return
                }
                if (!isLt10M) {
                    this.$message.error('上传图片大小不能超过 10MB!');
                    return;
                }
                let form = new FormData();
                form.append('file', file.raw);
                uploadFile(form).then((res) => {
                    if (res.data.code == "SUCCESS") {
                        this.$message.success("上传成功!");
                        this.personnelForm.avatar = res.data.data;
                    }
                });
            },
            handleSetImage() {
                this.dialogVisible = true;
                this.$nextTick(() => {
                    this.getCompetence();
                })
            },
            // 调用权限（打开摄像头功能）
            getCompetence() {
                var _this = this;
                _this.thisCancas = document.getElementById("canvasCamera");
                _this.thisContext = this.thisCancas.getContext("2d");
                _this.thisVideo = document.getElementById("videoCamera");
                // 获取媒体属性，旧版本浏览器可能不支持mediaDevices，我们首先设置一个空对象
                if (navigator.mediaDevices === undefined) {
                    navigator.mediaDevices = {};
                }
                // 一些浏览器实现了部分mediaDevices，我们不能只分配一个对象
                // 使用getUserMedia，因为它会覆盖现有的属性。
                // 这里，如果缺少getUserMedia属性，就添加它。
                if (navigator.mediaDevices.getUserMedia === undefined) {
                    navigator.mediaDevices.getUserMedia = function (constraints) {
                        // 首先获取现存的getUserMedia(如果存在)
                        var getUserMedia =
                            navigator.webkitGetUserMedia ||
                            navigator.mozGetUserMedia ||
                            navigator.getUserMedia;
                        // 有些浏览器不支持，会返回错误信息
                        // 保持接口一致
                        if (!getUserMedia) {
                            //不存在则报错
                            return Promise.reject(
                                new Error("getUserMedia is not implemented in this browser")
                            );
                        }
                        // 否则，使用Promise将调用包装到旧的navigator.getUserMedia
                        return new Promise(function (resolve, reject) {
                            getUserMedia.call(navigator, constraints, resolve, reject);
                        });
                    };
                }
                var constraints = {
                    audio: false,
                    video: {
                        width: this.videoWidth,
                        height: this.videoHeight,
                        transform: "scaleX(-1)",
                    },
                };
                navigator.mediaDevices
                    .getUserMedia(constraints)
                    .then(function (stream) {
                        // 旧的浏览器可能没有srcObject
                        if ("srcObject" in _this.thisVideo) {
                            _this.thisVideo.srcObject = stream;
                        } else {
                            // 避免在新的浏览器中使用它，因为它正在被弃用。
                            _this.thisVideo.src = window.URL.createObjectURL(stream);
                        }
                        _this.thisVideo.onloadedmetadata = function (e) {
                            _this.thisVideo.play();
                        };
                    })
                    .catch((err) => {
                        this.$message({
                            message: '没有开启摄像头权限或浏览器版本不兼容',
                            type: 'warning'
                        });
                        console.log(err);
                    });
            },
            //点击拍照截图画面
            takePhone() {
                // canvas画图
                this.thisContext.drawImage(this.thisVideo, 0, 0, this.videoWidth, this.videoHeight);
                this.imgSrc = this.thisCancas.toDataURL('image/png');
            },
            //  绘制图片（拍照功能）
            setImage() {
                // 获取图片base64链接
                var blob = this.convertBase64UrlToBlob(this.imgSrc); // 上一步中的函数
                let form = new FormData();
                form.append('file', blob, 'image.png');
                uploadFile(form).then((res) => {
                    if (res.data.code == "SUCCESS") {
                        this.$message.success("上传成功!");
                        this.personnelForm.avatar = res.data.data;
                        this.dialogVisible = false;
                        //清空画布
                        let c = document.getElementById("canvasCamera");
                        let cxt = c.getContext("2d");
                        cxt.clearRect(0, 0, c.width, c.height);
                        // 关闭摄像头
                        this.thisVideo.srcObject.getTracks()[0].stop();
                    } else {
                        this.$message.info(res.data.message);
                    }
                });
            },
            /*
            * * 将base64转换成可用formdata提交的文件
            * * @param {string} urlData base64的url
            * * @return {Blob}
            * */
            convertBase64UrlToBlob(urlData) {
                //去掉url的头，并转换为byte
                var split = urlData.split(',');
                var bytes = window.atob(split[1]);
                //处理异常,将ascii码小于0的转换为大于0
                var ab = new ArrayBuffer(bytes.length);
                var ia = new Uint8Array(ab);
                for (var i = 0; i < bytes.length; i++) {
                    ia[i] = bytes.charCodeAt(i);
                }
                return new Blob([ab], {type: split[0]});
            },
            handleClose() {
                this.dialogVisible = false;
                //清空画布
                let c = document.getElementById("canvasCamera");
                let cxt = c.getContext("2d");
                cxt.clearRect(0, 0, c.width, c.height);
                this.thisVideo.srcObject.getTracks()[0].stop();
            },
            // 关闭摄像头
            stopNavigator() {
                this.thisVideo.srcObject.getTracks()[0].stop();
            },
            //压缩base64图片
            getBase64(name, file) {
                let that = this;
                return new Promise(function (resolve, reject) {
                    let reader = new FileReader();
                    let imgResult = '';
                    reader.readAsDataURL(file);
                    reader.onload = function () {
                        // let str = reader.result.split(",")
                        imgResult = reader.result;
                        that.compressImage(name, imgResult, 800, (e => {
                            // console.log(e)
                            that.fileList.push(e);
                            resolve(that.fileList);
                        }))
                    }
                    reader.onerror = function (error) {
                        reject(error);
                    }
                    // reader.onloadend = function () {
                    //     resolve(that.fileList);
                    // }
                })
            },
            compressImage(name, base64, w, callback) {
                let param = {};
                // if (base64.length <= 32767) {
                //     param = {"name": name, "base64data": base64}
                //     callback(param);
                //     console.log("直接返回")
                //     return
                // }
                var newImage = new Image();
                var imgWidth, imgHeight;
                newImage.src = base64;
                // newImage.setAttribute("crossOrigin", 'Anonymous'); //url为外域时需要
                newImage.onload = function () {
                    var quality = 1; //压缩系数0-1之间
                    imgWidth = newImage.width;
                    imgHeight = newImage.height;
                    var canvas = document.createElement("canvas");
                    var ctx = canvas.getContext("2d");
                    if (Math.max(imgWidth, imgHeight) > w) {
                        if (imgWidth > imgHeight) {
                            canvas.width = w;
                            canvas.height = (w * imgHeight) / imgWidth;
                        } else {
                            canvas.height = w;
                            canvas.width = (w * imgWidth) / imgHeight;
                        }
                    } else {
                        canvas.width = imgWidth;
                        canvas.height = imgHeight;
                    }
                    // console.log(canvas.width, canvas.height, "画布宽高")
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                    ctx.drawImage(this, 0, 0, canvas.width, canvas.height);
                    //压缩语句
                    var base64 = canvas.toDataURL("image/jpeg", quality);
                    // 如想确保图片压缩到自己想要的尺寸,如要求在50-150kb之间，请加以下语句，quality初始值根据情况自定
                    // while (base64.length / 1024 > 150) {
                    // 	quality -= 0.01;
                    // 	base64 = canvas.toDataURL("image/jpeg", quality);
                    // }
                    // 防止最后一次压缩低于最低尺寸，只要quality递减合理，无需考虑
                    // while (base64.length / 1024 < 50) {
                    // 	quality += 0.001;
                    // 	base64 = canvas.toDataURL("image/jpeg", quality);
                    // }
                    while (base64.length > 32767) {
                        quality -= 0.01;
                        // console.log(base64.length + "循环压缩" + quality)
                        base64 = canvas.toDataURL("image/jpeg", quality);
                    }
                    param = {"name": name, "base64data": base64}
                    // console.log(base64.length + "循环1压缩")
                    callback(param); //必须通过回调函数返回，否则无法及时拿到该值
                }
            },
            handleUpload() {
                this.batchImportVisible = true;
                this.num = 0;
                this.arrList = [];
                this.formFile = [];
                this.imgList = [];
                this.$refs['upload'].clearFiles();
            },
            //上传前回调
            beforeUploadImage(file) {
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isLt2M) {
                    // console.log(file)
                    this.dialogImageVisible = true;
                    this.imgList.push({"name": file.name});
                    // console.log(this.imgList)
                    // this.$message.error('上传文件大小不能超过 2MB!');
                } else {
                    this.arrList.push(file);
                }
            },
            uploadSuccess(val) {
            },
            handleOnChange(file, imgList) {
                this.formFile = imgList;
                let isXls = file.raw.type === "application/vnd.ms-excel" ? true : file.raw.type ===
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? true : false;
                if (isXls) {
                    this.file = file;
                }
                this.loading = this.$loading({
                    lock: true,
                    text: '正在导入数据中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                setTimeout(() => {
                    // console.log(this.arrList.length)
                    // console.log(this.formFile.length)
                    if (this.arrList.length === this.formFile.length) {
                        this.dialogImageVisible = false;
                        let testmsg = /^image\/(jpeg|png|jpg)$/.test(file.raw.type);
                        if (testmsg) {
                            this.getBase64(file.name, file.raw).then(res => {
                            })
                        } else if (this.formFile.length == 1 && !testmsg) {
                            this.fileExecl = [];
                            this.beforeUploadFile(file.raw).then(res => {
                                this.fileExecl = res;
                                // console.log(this.fileExecl)
                                this.$refs['upload'].clearFiles();
                                this.fileExecl = Array.from(new Set(this.fileExecl.map(JSON.stringify))).map(JSON.parse);
                                // 计算需要切片的次数
                                // console.log(res.length)
                                var groupCount = Math.ceil(this.fileExecl.length / 100);
                                // console.log(groupCount)
                                // 创建空白的结果数组
                                var resultArr = new Array(groupCount).fill().map(() => []);
                                // console.log(resultArr)
                                // 对原始数组进行切片并放入结果数组中
                                for (let i = 0; i < this.fileExecl.length; i++) {
                                    var index = Math.floor(i / 100);
                                    resultArr[index].push(this.fileExecl[i]);
                                }
                                this.httpRequest(resultArr);
                                this.$refs['upload'].clearFiles();
                                // console.log(resultArr)
                            })
                        }
                    } else {
                        this.loading.close();
                    }
                }, 100);
                this.batchImportVisible = false;
            },
            beforeUploadFile(file, fileList) {
                var that = this;
                let tmpDown = [];
                return new Promise(function (resolve, reject) {
                    let fileReader = new FileReader();
                    fileReader.onload = (ev) => {
                        const data = ev.target.result;
                        const workbook = that.$XLSX.read(data, {
                            type: "binary",
                        });
                        let str = that.$XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);
                        // console.log(str)
                        let tmpdata = str[0];
                        str.unshift({});
                        let keyMap = []; //获取keys
                        for (let k in tmpdata) {
                            keyMap.push(k);
                            str[0][k] = k;
                        }
                        for (let i = 0; i < str.length; i++) {
                            if (str[i]['_3'] != '_3' && str[i]['_3'] != '手机号' && str[i]['_3'] != '' && str[i]['人员信息导入模板'] != "填表说明" && str[i]['人员信息导入模板'] != "头像"
                                && str[i]['人员信息导入模板'] != "性别" && str[i]['人员信息导入模板'] != "部门编号" && str[i]['人员信息导入模板'] != "手机号" && str[i]['人员信息导入模板'] != "人员编号") {
                                tmpDown.push(
                                    {
                                        "name": str[i]['人员信息导入模板'],
                                        "userNo": str[i][''],
                                        "tag": str[i]['_1'],
                                        "sex": str[i]['_2'],
                                        "phone": str[i]['_3'],
                                        "passNo": str[i]['_4'],
                                        "password": str[i]['_5'],
                                        "avatar": str[i]['_6'],
                                        "idCardNo": str[i]['_7'],
                                        "orgId": str[i]['_8'],
                                        'is3rd': that.is3rd,
                                        'distItem': that.distItem
                                    }
                                )
                            }
                        }
                        if (fileList) {
                            fileList.map((item, index) => {
                                let name = item.name.split(".");
                                tmpDown.map((item1, index1) => {
                                    // console.log(item1.name, '-------')
                                    // console.log(name[0], '======')
                                    if (item1.name == name[0]) {
                                        that.$set(item1, 'avatar', item.base64data);
                                        // console.log(tmpDown)
                                    }
                                })
                            });
                            that.fileList = [];
                        }
                        that.$refs['upload'].clearFiles();
                        resolve(tmpDown)
                    };
                    fileReader.readAsBinaryString(file);
                })
            },
            /*getList(newarr, file) {
                var that = this;
                const fileReader = new FileReader();
                fileReader.onload = (ev) => {
                    const data = ev.target.result;
                    const workbook = that.$XLSX.read(data, {
                        type: "binary",
                    });
                    let str = that.$XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]])
                    let tmpdata = str[0];
                    str.unshift({});
                    let keyMap = []; //获取keys
                    for (let k in tmpdata) {
                        keyMap.push(k);
                        str[0][k] = k;
                    }
                    for (let i = 0; i < str.length; i++) {
                        for (let j = 0; j < newarr.length; j++) {
                            let name = newarr[j].name.split(".")
                            if (str[i].人员信息导入模板 == name[0]) {
                                that.$set(str[i], '_5', newarr[j].base64data)
                                // console.log(str)
                            }
                        }
                    }
                    let tmpList = [];
                    str.map((v, i) => keyMap.map((k, j) => Object.assign({}, {
                        v: v[k],
                        position: (j > 25 ? that.getCharCol(j) : String.fromCharCode(65 + j)) + (i + 1)
                    }))).reduce((prev, next) => prev.concat(next)).forEach((v, i) =>
                        tmpList[v.position] = {
                            v: v.v
                        });
                    let outputPos = Object.keys(tmpList);
                    console.log(outputPos)
                    let tmpWB = {
                        SheetNames: ['mySheet'], //保存的表标题
                        Sheets: {
                            'mySheet': Object.assign({},
                                tmpList, //内容
                                {
                                    '!ref': outputPos[0] + ':' + outputPos[outputPos.length - 1] //设置填充区域
                                })
                        }
                    };
                    //创建二进制对象写入转换好的字节流
                    // console.log(tmpWB)
                    let type;
                    let tmpDown;
                    tmpDown = new Blob([that.s2ab(that.$XLSX.write(tmpWB,
                        {bookType: (type == undefined ? 'xlsx' : type), bookSST: false, type: 'binary'}//这里的数据是用来定义导出的格式类型
                    ))], {type: "application/vnd.ms-excel" || "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
                    that.formFile = tmpDown;
                    // console.log(this.formFile)
                    // that.httpRequest(tmpDown)
                };
                fileReader.readAsBinaryString(file);
            },*/
            httpRequest(file) {
                // console.log(file)
                // let formData = new FormData();
                // formData.append("file", tmpDown);
                // formData.append("is3rd", this.is3rd);
                console.log(this.num);
                if (file.length < this.num + 1) {
                    this.dialogTableVisible = true;
                    this.loading.close();
                    this.fetchData();
                    return
                }
                importTemplate(file[this.num]).then((res) => {
                    this.$refs['upload'].clearFiles();
                    if (res) {
                        if (res.data.code == "SUCCESS") {
                            if (file.length > this.num) {
                                this.num = this.num + 1;
                                this.httpRequest(file);
                            }
                            this.importData = res.data.data.errorData;
                            this.failNum = res.data.data.failNum;
                            this.successNum = res.data.data.successNum;
                        }
                    }
                }).catch(() => {
                    this.loading.close();
                });
            },
            s2ab(s) {
                let buf = new ArrayBuffer(s.length);
                let view = new Uint8Array(buf);
                for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
            },
            getCharCol(n) {
                let temCol = '',
                    s = '',
                    m = 0;
                while (n > 0) {
                    m = n % 26 + 1;
                    s = String.fromCharCode(m + 64) + s;
                    n = (n - m) / 26;
                }
                return s;
            },
            //模板导出
            handleTemplateExport() {
                downTemplate({is3rd: false}).then((res) => {
                    this.$FileSaver(res.data, "人员列表模板.xls");
                });
            },
            //选择导出类型
            handleExportExcel() {
                this.selectDialog = true;
                this.exportForm.radio = "";
            },
            //导出列表
            onExportExcel(exportForm) {
                this.$refs[exportForm].validate((valid) => {
                    if (valid) {
                        this.oprationShow = false;
                        this.imgShow = true;
                        this.filters.size = this.total;
                        this.$nextTick(function () {
                            let tables = document.querySelector("#out-table");
                            let table_book = this.$XLSX.utils.table_to_book(tables);
                            var table_write = this.$XLSX.write(table_book, {
                                bookType: this.exportForm.radio,
                                bookSST: true,
                                type: "array"
                            });
                            try {
                                this.$FileSaver.saveAs(
                                    new Blob([table_write], {type: "application/octet-stream"}),
                                    "人员导出记录." + this.exportForm.radio
                                );
                            } catch (e) {
                                if (typeof console !== "undefined") console.log(e, table_write);
                            }
                            this.oprationShow = true;
                            this.imgShow = false;
                            this.filters.size = 10;
                            this.selectDialog = false;
                            return table_write;
                        });
                    } else {
                        return false;
                    }
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    .el-tabs--border-card {
        box-shadow: none;
    }

    .el-aside {
        background-color: #ffffff !important;
        width: auto !important;
        margin-top: 20px;
    }

    .el-tree {
        max-height: 600px;
        overflow: auto;
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }

    .block {
        margin: 10px 0;
    }

    .camera_outer {
        position: relative;
        overflow: hidden;
        background-size: 100%;

        video,
        canvas,
        .tx_img {
            -moz-transform: scaleX(-1);
            -webkit-transform: scaleX(-1);
            -o-transform: scaleX(-1);
            transform: scaleX(-1);
        }

        .btn_camera {
            position: absolute;
            bottom: 4px;
            left: 0;
            right: 0;
            height: 50px;
            background-color: rgba(0, 0, 0, 0.3);
            line-height: 50px;
            text-align: center;
            color: #ffffff;
        }

        .bg_r_img {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            top: 0;
        }

        .img_bg_camera {
            position: absolute;
            right: 0;
            top: 0;

            img {
                width: 170px;
                height: 178px;
            }

            .img_btn_camera {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 50px;
                line-height: 50px;
                text-align: center;
                background-color: rgba(0, 0, 0, 0.3);
                color: #ffffff;

                .loding_img {
                    width: 50px;
                    height: 50px;
                }

            }
        }
    }

    .upload-demo {
        margin-left: 10px;
    }
</style>
