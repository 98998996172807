import request from '@/utils/request'
import config from '@/config'

// 语音套餐购买记录
export function voiceOrderList(data) {
    return request({
        url: `${config.bpi}/baseService/voiceOrder`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}

// 语音套餐剩余次数
export function voiceUserInfo() {
    return request({
        url: `${config.bpi}/baseService/voiceUserInfo`,
        method: 'get',
        headers: {'showLoading': false}
    })
}

// 语音套餐列表
export function productList() {
    return request({
        url: `${config.bpi}/baseService/product`,
        method: 'get',
        headers: {'showLoading': false}
    })
}

// 查询订单状态
export function voiceOrderStatus(data) {
    return request({
        url: `${config.bpi}/baseService/voiceOrder/status?orderNo=` + data,
        method: 'get',
        headers: {'showLoading': false}
    })
}

// 扫码支付统统一下单(获取二维码)
export function nativePay(data) {
    return request({
        url: `${config.bpi}/baseService/nativePay`,
        method: 'PUT',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 应用列表、续费管理
export function instanceList() {
    return request({
        url: `${config.api}/funcModule/instance`,
        method: 'get',
        headers: {'showLoading': false}
    })
}

//服务开关
export function instanceOpen(funcModuleCode, isOpen) {
    return request({
        url: `${config.api}/funcModule/instance?funcModuleCode=` + funcModuleCode + `&isOpen=` + isOpen,
        method: 'put',
    })
}

//续费二维码
export function instanceRenewal(data) {
    return request({
        url: `${config.api}/funcModule/instance/renewal`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

//定价列表
export function priceList(data) {
    return request({
        url: `${config.api}/incrementFunc/price`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}

// 续费记录
export function renewalRecordList(data) {
    return request({
        url: `${config.api}/funcModule/renewal/record`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}

// 功能列表
export function adjustableList() {
    return request({
        url: `${config.api}/billing/standard/list`,
        method: 'get',
        headers: {'showLoading': false}
    })
}
