<!--<template>-->
<!--    <div>-->
<!--        &lt;!&ndash; 面包屑导航 &ndash;&gt;-->
<!--        <el-breadcrumb separator-class="el-icon-arrow-right">-->
<!--            <el-breadcrumb-item>车场管理</el-breadcrumb-item>-->
<!--            <el-breadcrumb-item>无人值守</el-breadcrumb-item>-->
<!--        </el-breadcrumb>-->
<!--        <div class="clickBtn">-->
<!--            <el-button icon="el-icon-search" type="primary" size="small" @click="getPeopleList">刷新</el-button>-->
<!--            <el-button type="primary" size="small" @click="handleCode">授权二维码</el-button>-->
<!--        </div>-->
<!--        &lt;!&ndash;列表&ndash;&gt;-->
<!--        <el-table-->
<!--                size="small"-->
<!--                :data="tableData"-->
<!--                highlight-current-row-->
<!--                border-->
<!--                v-loading="loading"-->
<!--                element-loading-text="拼命加载中"-->
<!--                style="width: 100%"-->
<!--                :default-sort="{prop: 'passTime', order: 'descending'}"-->
<!--                :row-style="{height:'45px'}"-->
<!--                :cell-style="{padding:'0px'}"-->
<!--                :header-cell-style="{background:'#eef1f6',color:'#606266'}"-->
<!--                stripe-->
<!--                height="500"-->
<!--                max-height="500"-->
<!--        >-->
<!--            <el-table-column type="index" label="序号"></el-table-column>-->
<!--            <el-table-column prop="userVO.nickname" label="微信昵称" align="center"></el-table-column>-->
<!--            <el-table-column prop="avatar" label="微信头像" align="center">-->
<!--                <template slot-scope="scope">-->
<!--                    <el-image-->
<!--                            style="width: 50px; height: 50px"-->
<!--                            :src="scope.row.userVO.avatar?scope.row.userVO.avatar:require('../../../assets/images/not_found.png')"-->
<!--                            :preview-src-list="[scope.row.userVO.avatar?scope.row.userVO.avatar:require('../../../assets/images/not_found.png')]">-->
<!--                    </el-image>-->
<!--                </template>-->
<!--            </el-table-column>-->
<!--            <el-table-column prop="status" label="状态" align="center">-->
<!--                <template slot-scope="scope">-->
<!--                    <el-tag type="success" v-if="scope.row.status == 2">已通过</el-tag>-->
<!--                    <el-tag type="primary" v-else-if="scope.row.status == 1">待审核</el-tag>-->
<!--                </template>-->
<!--            </el-table-column>-->
<!--            <el-table-column align="center" label="操作">-->
<!--                <template slot-scope="scope" >-->
<!--                    <el-button v-if="scope.row.status == 1" size="mini" @click="handleAdopt(scope.row)"-->
<!--                               type="primary">通过审核-->
<!--                    </el-button>-->
<!--                    <el-button size="mini" @click="handleRelieve(scope.row)"-->
<!--                               type="danger">解除绑定-->
<!--                    </el-button>-->
<!--                </template>-->
<!--            </el-table-column>-->
<!--        </el-table>-->
<!--    </div>-->
<!--</template>-->

<template>
    <div class="warp-main">
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>车场管理</el-breadcrumb-item>
            <el-breadcrumb-item>无人值守</el-breadcrumb-item>
        </el-breadcrumb>
        <el-row :span="24" v-loading="loading" element-loading-text="拼命加载中">
            <el-col :span="24" class="toolbar" style="padding-bottom: 0;">
                <div class="clickBtn">
                    <el-button icon="el-icon-search" type="primary" size="small" @click="getPeopleList">刷新</el-button>
                    <el-button type="primary" size="small" @click="handleCode">授权二维码</el-button>
                </div>
            </el-col>
            <!--列表-->
            <el-table
                    size="small"
                    :data="tableData"
                    highlight-current-row
                    border
                    style="width: 100%"
                    :default-sort="{prop: 'passTime', order: 'descending'}"
                    :row-style="{height:'45px'}"
                    :cell-style="{padding:'0px'}"
                    :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                    stripe
                    height="500"
                    max-height="500"
            >
                <el-table-column type="index" label="序号"></el-table-column>
                <el-table-column prop="userVO.nickname" label="微信昵称" align="center"></el-table-column>
                <el-table-column prop="avatar" label="微信头像" align="center">
                    <template slot-scope="scope">
                        <el-image
                                style="width: 50px; height: 50px"
                                :src="scope.row.userVO.avatar?scope.row.userVO.avatar:require('../../../assets/images/defaultImg.png')"
                                :preview-src-list="[scope.row.userVO.avatar?scope.row.userVO.avatar:require('../../../assets/images/defaultImg.png')]">
                            <div slot="error">
                                <el-image :src="require('../../../assets/images/defaultImg.png')">
                                </el-image>
                            </div>
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="状态" align="center">
                    <template slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.status == 2">已通过</el-tag>
                        <el-tag type="primary" v-else-if="scope.row.status == 1">待审核</el-tag>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作">
                    <template slot-scope="scope">
                        <el-button v-if="scope.row.status == 1" size="mini" @click="handleAdopt(scope.row)"
                                   type="primary">通过审核
                        </el-button>
                        <el-button size="mini" @click="handleRelieve(scope.row)"
                                   type="danger">解除绑定
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-row>
    </div>
</template>

<script>
    import {peopleList, unattendedBindPeopleQrCode, passPeople, repealPeople} from "../../../api/unattended";

    export default {
        name: "unattended",
        data() {
            return {
                loading: false,
                tableData: [],
            }
        },
        mounted() {
            this.getPeopleList();
        },
        methods: {
            getPeopleList() {
                this.loading = true;
                peopleList().then((res) => {
                    this.loading = false;
                    this.tableData = res.data.data;
                })
            },
            //二维码
            handleCode() {
                unattendedBindPeopleQrCode().then((res) => {
                    this.$alert('<img width="100%" src="' + res.data.data + '"/>', {
                        dangerouslyUseHTMLString: true,
                        showConfirmButton: false,
                        closeOnClickModal: true,
                        center: true,
                        customClass: 'msgbox',
                        title: "注意：接收无人值守消息必须关注公众号“链路云停”！"
                    }).catch(() => {
                    });
                })
            },
            //通过
            handleAdopt(row) {
                this.$confirm('是否确认通过审核?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    passPeople(row.userId).then((res) => {
                        this.getPeopleList();
                        if (res.data.code == "SUCCESS") {
                            this.$message({
                                type: "success",
                                message: res.data.message,
                            });
                        } else {
                            this.$message({
                                type: "info",
                                message: res.data.message,
                            });
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
            },
            //解绑
            handleRelieve(row) {
                this.$confirm('是否确认解除绑定?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    repealPeople(row.userId).then((res) => {
                        this.getPeopleList();
                        if (res.data.code == "SUCCESS") {
                            this.$message({
                                type: "success",
                                message: res.data.message,
                            });
                        } else {
                            this.$message({
                                type: "info",
                                message: res.data.message,
                            });
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
            },
        }
    }
</script>

<style scoped>
    /*.msgbox{*/
    /*    width: auto !important;*/
    /*}*/
    .clickBtn {
        margin: 20px 0px 10px 0px;
    }
</style>
