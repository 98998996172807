<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>增值服务</el-breadcrumb-item>
            <el-breadcrumb-item>语音对讲</el-breadcrumb-item>
        </el-breadcrumb>
        <el-row style="margin: 20px 0;line-height: 30px;">
            <el-col :span="2">
                <el-button icon="el-icon-search" type="primary" size="small" @click="search">刷新</el-button>
            </el-col>
            <el-col :span="1">
                <el-button icon="el-icon-sold-out" type="primary" size="small" @click="handlePurchasePackage">购买套餐包
                </el-button>
            </el-col>
        </el-row>
        <el-row style="margin: 10px 0;line-height: 30px;">
            <h3>当前可用次数: {{num}}次</h3>
        </el-row>
        <!--列表-->
        <el-table
                size="small"
                :data="listData"
                highlight-current-row
                v-loading="loading"
                element-loading-text="拼命加载中"
                border
                style="width: 100%"
                :row-style="{height:'45px'}"
                :cell-style="{padding:'0px'}"
                :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                stripe
                height="500"
                max-height="500">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column prop="type" label="套餐包类型" align="center">
                <!--                <template slot-scope="scope">-->
                <!--                    <span v-if="scope.row.type == 1"></span>-->
                <!--                </template>-->
                音频通话
            </el-table-column>
            <el-table-column prop="type" label="套餐包次数(次)" align="center"></el-table-column>
            <el-table-column prop="number" label="数量" align="center"></el-table-column>
            <el-table-column prop="amount" label="金额(元)" align="center"></el-table-column>
            <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
            <el-table-column prop="endTime" label="截止时间" align="center"></el-table-column>
        </el-table>
        <el-pagination background
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="formInline.current"
                       :page-sizes="[10, 50, 200, 1000]"
                       :page-size="formInline.size"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
    </div>
</template>

<script>
    import {voiceOrderList, voiceUserInfo} from "../../../api/basicServices";

    export default {
        name: "voiceIntercom",
        data() {
            return {
                loading: false,
                total: 0,
                formInline: {
                    current: 1,
                    size: 10,
                },
                //用户数据
                listData: [],
                num: "",
            }
        },
        mounted() {
            this.getDataList();
            this.getVoiceUserInfo();
        },
        methods: {
            getDataList() {
                let that = this;
                that.loading = true;
                voiceOrderList(that.formInline).then((res) => {
                    that.loading = false;
                    that.listData = res.data.data.records;
                    that.total = res.data.data.total;
                    that.filters.current = res.data.data.current;
                    that.filters.size = res.data.data.size;
                }).catch(error => {
                    that.loading = false;
                    console.log(error);
                });
            },
            getVoiceUserInfo() {
                voiceUserInfo().then((res) => {
                    this.num = res.data.data;
                });
            },
            // 搜索事件
            search() {
                this.formInline.current = 1;
                this.getDataList();
                this.getVoiceUserInfo();
            },

            handleSizeChange(val) {
                this.formInline.size = val;
                this.formInline.current = 1;
                this.getDataList();
            },
            handleCurrentChange(val) {
                this.formInline.current = val;
                this.getDataList();
            },
            handlePurchasePackage() {
                this.$router.push("purchasePackage")
            },
        }
    }
</script>

<style scoped>

</style>
