<template>
    <div>
        <el-row :gutter="20" class="mgb20">
            <el-col :span="12">
                <el-card shadow="hover">
                    <template #header>
                        <div class="clearfix">
                            <span>人行概况</span>
                        </div>
                    </template>
                    <el-row :gutter="20" class="mgb20">
                        <el-col :span="12">
                            <el-card shadow="hover" :body-style="{ padding: '0px' }">
                                <div class="grid-content grid-con-1">
                                    <img class="grid-con-icon" src="../../assets/images/user.png"/>
                                    <div class="grid-cont-right">
                                        <div class="grid-num">{{dataInfo ? dataInfo.insideNumber : 0}}</div>
                                        <div>内部人数</div>
                                    </div>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="12">
                            <el-card shadow="hover" :body-style="{ padding: '0px' }">
                                <div class="grid-content grid-con-2">
                                    <img class="grid-con-icon" src="../../assets/images/customer.png"/>
                                    <div class="grid-cont-right">
                                        <div class="grid-num">{{dataInfo ? dataInfo.visitorNumber : 0}}</div>
                                        <div>访客人数</div>
                                    </div>
                                </div>
                            </el-card>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-card shadow="hover" :body-style="{ padding: '0px' }">
                                <div class="grid-content grid-con-3">
                                    <img class="grid-con-icon" src="../../assets/images/on-line.png"/>
                                    <div class="grid-cont-right">
                                        <div class="grid-num">{{dataInfo ? dataInfo.deviceNumber : 0}}</div>
                                        <div>设备数量</div>
                                    </div>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="12">
                            <el-card shadow="hover" :body-style="{ padding: '0px' }">
                                <div class="grid-content grid-con-4">
                                    <img class="grid-con-icon" src="../../assets/images/totalEquipment.png"/>
                                    <div class="grid-cont-right">
                                        <div class="grid-num">{{dataInfo ? dataInfo.activeDeviceNumber : 0}}</div>
                                        <div>在线设备数量</div>
                                    </div>
                                </div>
                            </el-card>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
            <el-col :span="12">
                <el-card shadow="hover">
                    <template #header>
                        <div class="clearfix">
                            <span>测温门概况</span>
                        </div>
                    </template>
                    <el-row :gutter="20" class="mgb20">
                        <el-col :span="12">
                            <el-card shadow="hover" :body-style="{ padding: '0px' }">
                                <div class="grid-content grid-con-1">
                                    <img class="grid-con-icon" src="../../assets/images/user.png"/>
                                    <div class="grid-cont-right">
                                        <div class="grid-num">
                                            {{ temperatureInfo ? temperatureInfo.pass : 0 }}
                                        </div>
                                        <div>测温人数</div>
                                    </div>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="12">
                            <el-card shadow="hover" :body-style="{ padding: '0px' }">
                                <div class="grid-content grid-con-2">
                                    <img class="grid-con-icon" src="../../assets/images/icon9.png"/>
                                    <div class="grid-cont-right">
                                        <div class="grid-num">
                                            {{ temperatureInfo ? temperatureInfo.abnormal : 0 }}
                                        </div>
                                        <div>异常人数</div>
                                    </div>
                                </div>
                            </el-card>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-card shadow="hover" :body-style="{ padding: '0px' }">
                                <div class="grid-content grid-con-3">
                                    <img class="grid-con-icon" src="../../assets/images/on-line.png"/>
                                    <div class="grid-cont-right">
                                        <div class="grid-num">
                                            {{ temperatureInfo ? temperatureInfo.device : 0 }}
                                        </div>
                                        <div>设备总数</div>
                                    </div>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="12">
                            <el-card shadow="hover" :body-style="{ padding: '0px' }">
                                <div class="grid-content grid-con-4">
                                    <img class="grid-con-icon" src="../../assets/images/totalEquipment.png"/>
                                    <div class="grid-cont-right">
                                        <div class="grid-num">
                                            {{ temperatureInfo ? temperatureInfo.deviceOnline : 0 }}
                                        </div>
                                        <div>设备在线数量</div>
                                    </div>
                                </div>
                            </el-card>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
        <el-row :gutter="20" class="mgb20">
            <el-col :span="12">
                <el-card shadow="hover">
                    <template #header>
                        <div class="clearfix">
                            <span>我的设备</span>
                        </div>
                    </template>
                    <el-table :data="dataList" style="width:100%;" stripe
                              :row-style="{height:'45px'}" :cell-style="{padding:'0px'}"
                              :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                              height="280" max-height="280">
                        <el-table-column type="index" label="序号"></el-table-column>
                        <el-table-column prop="doorwayName" label="通道名称" align="center"></el-table-column>
                        <el-table-column prop="deviceName" label="设备名称" align="center"></el-table-column>
                        <el-table-column prop="status" label="设备状态" align="center">
                            <template slot-scope="scope">
                                <el-tag v-if="scope.row.status != null" :type="scope.row.status == 1 ? '' : 'info'">
                                    {{scope.row.status == 1 ? '在线' : scope.row.status == 2 ? '离线' : ''}}
                                </el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="doorwayDirection" label="方向" align="center">
                            <template slot-scope="scope">
                                {{scope.row.doorwayDirection == 1 ? '入口' : scope.row.doorwayDirection == 2 ? '出口' : ''}}
                            </template>
                        </el-table-column>
                        <el-table-column align="center" label="操作">
                            <template slot-scope="scope">
                                <el-button size="mini" @click="handleOpen(scope.$index, scope.row)"
                                           type="primary">开闸
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination background
                                   @size-change="handleSizeChange1"
                                   @current-change="handleCurrentChange1"
                                   :current-page="filters.current"
                                   :page-sizes="[10, 50, 200, 1000]"
                                   :page-size="filters.size"
                                   layout="total, sizes, prev, pager, next, jumper"
                                   :total="total1">
                    </el-pagination>
                </el-card>
            </el-col>
            <el-col :span="12">
                <el-card shadow="hover">
                    <template #header>
                        <div class="clearfix">
                            <span>测温设备</span>
                        </div>
                    </template>
                    <el-table :data="deviceData" style="width:100%;" stripe
                              :row-style="{height:'45px'}" :cell-style="{padding:'0px'}"
                              :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                              height="280" max-height="280">
                        <el-table-column type="index" label="序号"></el-table-column>
                        <el-table-column prop="deviceName" label="设备名称" align="center"></el-table-column>
                        <el-table-column prop="sn" label="设备序列号" align="center"></el-table-column>
                        <el-table-column prop="deviceNo" label="设备编号" align="center"></el-table-column>
                        <el-table-column prop="status" label="设备在线状态" align="center">
                            <template slot-scope="scope">
                                <el-tag :type="scope.row.status == 1 ? '' : 'info'">
                                    {{ scope.row.status == 1 ? "在线" : scope.row.status == 2 ? "离线" : "" }}
                                </el-tag>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination background
                                   @size-change="handleSizeChange4"
                                   @current-change="handleCurrentChange4"
                                   :current-page="deviceForm.current"
                                   :page-sizes="[10, 50, 200, 1000]"
                                   :page-size="deviceForm.size"
                                   layout="total, sizes, prev, pager, next, jumper"
                                   :total="total4">
                    </el-pagination>
                </el-card>
            </el-col>
        </el-row>
        <el-row :gutter="20" class="mgb20">
            <el-card shadow="hover">
                <template #header>
                    <div class="clearfix">
                        <span>近七日客流量</span>
                    </div>
                </template>
                <div ref="myEchart1" style="height: 310px;"></div>
            </el-card>
        </el-row>
        <el-row :gutter="20" class="mgb20">
            <el-card shadow="hover">
                <template #header>
                    <div class="clearfix">
                        <span>抓拍记录</span>
                    </div>
                </template>
                <el-table :data="tableData" stripe style="width:100%;" height="400" max-height="400"
                          :row-style="{height:'45px'}" :cell-style="{padding:'0px'}" :row-class-name="tableRowClassName"
                          :header-cell-style="{background:'#eef1f6',color:'#606266'}">
                    <el-table-column type="index" label="序号"></el-table-column>
                    <el-table-column prop="personName" label="姓名" align="center"></el-table-column>
                    <el-table-column prop="temperature" label="温度" align="center">
                        <template slot-scope="scope">
                            {{scope.row.temperature == 0 ? '-' : scope.row.temperature}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="antiepidemic" label="防疫状态" align="center"></el-table-column>
                    <el-table-column prop="direction" label="方向" align="center">
                        <template slot-scope="scope">
                            {{scope.row.direction == 1 ? '入口' : scope.row.direction == 2 ? '出口' : ''}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="doorwayName" label="位置" align="center"></el-table-column>
                    <el-table-column prop="personType" label="人员类型" align="center">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.personType != null"
                                    :type="scope.row.personType == 1 ? '' : scope.row.personType == 2 ? 'success' : 'info'">
                                {{scope.row.personType == 1 ? '内部人员' : scope.row.personType == 2 ? '访客' :
                                scope.row.personType == 3 ? '未知' : ''}}
                            </el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="passageTime" label="时间" align="center"></el-table-column>
                    <el-table-column prop="passageImg" label="抓拍头像" align="center">
                        <template slot-scope="scope">
                            <el-image style="width: 50px;height: 50px;"
                                      :src="scope.row.passageImg?scope.row.passageImg:require('../../assets/images/defaultImg.png')"
                                      :preview-src-list="[scope.row.passageImg?scope.row.passageImg:require('../../assets/images/defaultImg.png')]">
                                <div slot="error">
                                    <el-image :src="require('../../assets/images/defaultImg.png')">
                                    </el-image>
                                </div>
                            </el-image>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background
                               @size-change="handleSizeChange2"
                               @current-change="handleCurrentChange2"
                               :current-page="queryForm.current"
                               :page-sizes="[10, 50, 200, 1000]"
                               :page-size="queryForm.size"
                               layout="total, sizes, prev, pager, next, jumper"
                               :total="total2">
                </el-pagination>
            </el-card>

        </el-row>
        <el-row :gutter="20">
            <el-card shadow="hover">
                <template #header>
                    <div class="clearfix">
                        <span>测温记录</span>
                    </div>
                </template>
                <el-table
                        :data="temperatureData"
                        stripe
                        style="width: 100%"
                        height="400"
                        max-height="400"
                        :row-style="{ height: '45px' }"
                        :cell-style="{ padding: '0px' }"
                        :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
                    <el-table-column type="index" label="序号"></el-table-column>
                    <el-table-column prop="temperature" label="体温(℃)" align="center">
                        <template slot-scope="scope">
                            {{scope.row.temperature == 0 ? '-' : scope.row.temperature}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="" label="体温状态" align="center">
                        <template slot-scope="scope">
                            <el-tag :type="scope.row.temperature > 37.2 ? 'danger' : 'success'">
                                {{scope.row.temperature > 37.2 ? '异常' : '正常'}}
                            </el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="deviceName" label="设备名称" align="center"></el-table-column>
                    <!--                    <el-table-column prop="hasMask" label="是否戴口罩" align="center">-->
                    <!--                        <template slot-scope="scope">-->
                    <!--                            <el-tag :type="scope.row.hasMask == 0 ? 'info' : ''">-->
                    <!--                                {{scope.row.hasMask == 0 ? '否' : '是'}}-->
                    <!--                            </el-tag>-->
                    <!--                        </template>-->
                    <!--                    </el-table-column>-->
                    <el-table-column prop="capTime" label="测量时间" align="center"></el-table-column>
                    <el-table-column prop="passageImg" label="抓拍头像" align="center">
                        <template slot-scope="scope">
                            <el-image style="width: 50px; height: 50px"
                                      :src="scope.row.capImg?scope.row.capImg:require('../../assets/images/defaultImg.png')"
                                      :preview-src-list="[scope.row.capImg?scope.row.capImg:require('../../assets/images/defaultImg.png')]">
                                <div slot="error">
                                    <el-image :src="require('../../assets/images/defaultImg.png')">
                                    </el-image>
                                </div>
                            </el-image>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                        background
                        @size-change="handleSizeChange3"
                        @current-change="handleCurrentChange3"
                        :current-page="temperatureForm.current"
                        :page-sizes="[10, 50, 200, 1000]"
                        :page-size="temperatureForm.size"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total3">
                </el-pagination>
            </el-card>
        </el-row>
    </div>
</template>

<script>
    import * as echarts from "echarts";
    import {getDevice, getDeviceTemperature, openGate} from "../../api/equipmentApi";
    import {getRecordPage} from "../../api/organizationApi";
    import {personnelStatistics, temperatureRecord, temperatureStatistics} from "../../api/home";

    export default {
        name: "indexPage",
        data() {
            return {
                dataInfo: "",
                dataList: [],
                total1: 0,
                total2: 0,
                total3: 0,
                total4: 0,
                filters: {
                    current: 1,
                    size: 10,
                },
                queryForm: {
                    current: 1,
                    size: 10,
                    startTime: '',
                },
                tableData: [],
                temperatureInfo: "",
                temperatureData: [],
                temperatureForm: {
                    current: 1,
                    size: 10,
                },
                deviceData: [],
                deviceForm: {
                    current: 1,
                    size: 10,
                },
            }
        },
        mounted() {
            this.myEchart1();
            this.getDeviceData();
            this.fetchData();
            this.getTemperatureStatistics();
            this.getTemperatureRecord();
            this.getDeviceTemperature();
        },
        methods: {
            //七日流量折线图
            myEchart1() {
                personnelStatistics().then((res) => {
                    this.dataInfo = res.data.data.cell;

                    let xAxisData = [];
                    let seriesData1 = [];
                    let seriesData2 = [];
                    if (res.data.data) {
                        res.data.data.passStatistics.forEach(e => {
                            xAxisData.push(e.time);
                            seriesData1.push(e.inPassCount);
                            seriesData2.push(e.outPassCount);
                        });
                    }
                    let mapChart = echarts.init(this.$refs.myEchart1); //图表初始化，china-map是绑定的元素
                    window.onresize = mapChart.resize; //如果容器变大小，自适应从新构图
                    let option = {
                        tooltip: {
                            trigger: 'axis'
                        },
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '3%',
                            containLabel: true
                        },
                        xAxis: {
                            type: 'category',
                            boundaryGap: true,
                            data: xAxisData,
                            position: "bottom",
                            axisLine: true,
                            axisLabel: {
                                color: "rgba(57,57,57,0.8)",
                                fontSize: 13,
                            },
                        },
                        yAxis: {
                            type: 'value',
                            name: "客流量",
                            nameLocation: "end",
                            nameGap: 24,
                            nameTextStyle: {
                                color: "#393939",
                                fontSize: 14,
                            },
                            splitNumber: 4,
                            axisLine: {
                                lineStyle: {
                                    opacity: 0,
                                    color: "#393939",
                                },
                            },
                            splitLine: {
                                show: true,
                                lineStyle: {
                                    color: "#393939",
                                    opacity: 0.1,
                                },
                            },
                            axisLabel: {
                                color: "rgba(57,57,57,0.8)",
                                fontSize: 12,
                                formatter: "{value} ",
                            },
                        },
                        legend: {
                            data: ['入场', '出场'],
                            textStyle: {
                                color: "#393939",
                            },
                        },
                        series: [
                            {
                                name: '入场',
                                type: 'line',
                                smooth: true,
                                //修改折线的颜色
                                data: seriesData1,
                                symbol: "emptyCircle",
                                symbolSize: 8,
                                itemStyle: {
                                    normal: {
                                        color: "#1c98e8",
                                    },
                                },
                                //线的颜色样式
                                lineStyle: {
                                    normal: {
                                        color: {
                                            type: "linear",
                                            x: 0,
                                            y: 0,
                                            x2: 1,
                                            y2: 0,
                                            colorStops: [
                                                {
                                                    offset: 0,
                                                    color: "#1c98e8",
                                                },
                                                {
                                                    offset: 1,
                                                    color: "#28f8de",
                                                },
                                            ],
                                        },
                                        width: 3,
                                    },
                                },
                                //填充颜色样式
                                areaStyle: {
                                    normal: {
                                        color: {
                                            type: "linear",
                                            x: 0,
                                            y: 0,
                                            x2: 0,
                                            y2: 1,
                                            colorStops: [
                                                {
                                                    offset: 0,
                                                    color: "rgba(35,184,210,.2)",
                                                },
                                                {
                                                    offset: 1,
                                                    color: "rgba(35,184,210,0)",
                                                },
                                            ],
                                        },
                                    },
                                },
                            },
                            {
                                name: '出场',
                                type: 'line',
                                data: seriesData2,
                                smooth: true,
                                symbol: "emptyCircle",
                                symbolSize: 8,
                                itemStyle: {
                                    normal: {
                                        color: "#F08519",
                                    },
                                },
                                //线的颜色样式
                                lineStyle: {
                                    normal: {
                                        color: {
                                            type: "linear",
                                            x: 0,
                                            y: 0,
                                            x2: 1,
                                            y2: 0,
                                            colorStops: [
                                                {
                                                    offset: 0,
                                                    color: "#F08519",
                                                },
                                                {
                                                    offset: 1,
                                                    color: "#ffbc76",
                                                },
                                            ],
                                        },
                                        width: 3,
                                    },
                                },
                                //填充颜色样式
                                areaStyle: {
                                    normal: {
                                        color: {
                                            type: "linear",
                                            x: 0,
                                            y: 0,
                                            x2: 0,
                                            y2: 1,
                                            colorStops: [
                                                {
                                                    offset: 0,
                                                    color: "rgba(35,184,210,.2)",
                                                },
                                                {
                                                    offset: 1,
                                                    color: "rgba(35,184,210,0)",
                                                },
                                            ],
                                        },
                                    },
                                },
                            }
                        ]
                    };
                    mapChart.setOption(option); //生成图表
                })
            },
            //我的设备列表
            getDeviceData() {
                getDevice(this.filters).then((res) => {
                    if (res.data.code == "SUCCESS") {
                        this.dataList = res.data.data.records;
                        this.total1 = res.data.data.total;
                        this.filters.current = res.data.data.current;
                        this.filters.size = res.data.data.size;
                    } else {
                        this.$message.info(res.data.message);
                    }
                })
            },
            handleSizeChange1(val) {
                this.filters.size = val;
                this.filters.current = 1;
                this.getDeviceData();
            },
            handleCurrentChange1(val) {
                this.filters.current = val;
                this.getDeviceData();
            },
            //抓拍记录
            fetchData() {
                this.queryForm.startTime = this.dayjs().subtract(6, 'day').format('YYYY-MM-DD HH:mm:ss');
                getRecordPage(this.queryForm).then((res) => {
                    if (res.data.code == "SUCCESS") {
                        this.tableData = res.data.data.records;
                        this.total2 = res.data.data.total;
                        this.queryForm.current = res.data.data.current;
                        this.queryForm.size = res.data.data.size;
                    } else {
                        this.$message.info(res.data.message);
                    }
                })
            },
            handleSizeChange2(val) {
                this.queryForm.size = val;
                this.queryForm.current = 1;
                this.fetchData();
            },
            handleCurrentChange2(val) {
                this.queryForm.current = val;
                this.fetchData();
            },
            handleOpen(index, row) {
                this.$confirm("确定要开闸吗?", "温馨提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    openGate(row.id).then((res) => {
                        if (res.data.code == "SUCCESS") {
                            this.getDeviceData();
                            this.$message.success(res.data.message);
                        } else {
                            this.$message.info(res.data.message);
                        }
                    })
                }).catch((err) => {
                    this.$message.info("已取消!");
                });
            },
            tableRowClassName({row, rowIndex}) {
                if (row.tempAbnormal === true) {
                    return 'warning-row';
                }
                return '';
            },
            getTemperatureStatistics() {
                temperatureStatistics().then(res => {
                    this.temperatureInfo = res.data.data;
                })
            },
            getTemperatureRecord() {
                temperatureRecord(this.temperatureForm).then(res => {
                    this.temperatureData = res.data.data.records;
                    this.total3 = res.data.data.total;
                    this.temperatureForm.current = res.data.data.current;
                    this.temperatureForm.size = res.data.data.size;
                })
            },
            handleSizeChange3(val) {
                this.temperatureForm.size = val;
                this.temperatureForm.current = 1;
                this.getTemperatureRecord();
            },
            handleCurrentChange3(val) {
                this.temperatureForm.current = val;
                this.getTemperatureRecord();
            },
            getDeviceTemperature() {
                getDeviceTemperature(this.deviceForm).then(res => {
                    this.deviceData = res.data.data.records;
                    this.total4 = res.data.data.total;
                    this.deviceForm.current = res.data.data.current;
                    this.deviceForm.size = res.data.data.size;
                })
            },
            handleSizeChange4(val) {
                this.deviceForm.size = val;
                this.deviceForm.current = 1;
                this.getDeviceTemperature();
            },
            handleCurrentChange4(val) {
                this.deviceForm.current = val;
                this.getDeviceTemperature();
            },
        }
    }
</script>

<style scoped>
    body {
        min-height: 75rem;
        -ms-overflow-style: none; /* IE 10+ */
        scrollbar-width: none; /* Firefox */
    }

    /* chrome 浏览器中滚动条消失 */
    body::-webkit-scrollbar {
        display: none !important;
    }

    .grid-content {
        display: flex;
        align-items: center;
        height: 100px;
    }

    .grid-cont-right {
        flex: 1;
        text-align: center;
        font-size: 14px;
        line-height: 30px;
        color: #999;
    }

    .grid-num {
        font-size: 30px;
        font-weight: bold;
    }

    .grid-con-icon {
        font-size: 50px;
        width: 100px;
        height: 100px;
        text-align: center;
        line-height: 100px;
        color: #fff;
    }

    .grid-con-1 .grid-con-icon {
        background: rgb(45, 140, 240);
    }

    .grid-con-1 .grid-num {
        color: rgb(45, 140, 240);
    }

    .grid-con-2 .grid-con-icon {
        background: rgb(240, 133, 25);
    }

    .grid-con-2 .grid-num {
        color: rgb(240, 133, 25);
    }

    .grid-con-3 .grid-con-icon {
        background: #67c23a;
    }

    .grid-con-3 .grid-num {
        color: #67c23a;
    }

    .grid-con-4 .grid-con-icon {
        background: rgb(176 75 135);
    }

    .grid-con-4 .grid-num {
        color: #b04b85;
    }

    .user-info-cont div:first-child {
        font-size: 30px;
        color: #222;
    }

    .user-info-list span {
        margin-left: 70px;
    }

    .clearfix {
        font-size: small;
        font-weight: 500;
    }

    .mgb20 {
        margin-bottom: 15px;
    }

    /deep/ .el-table .warning-row td {
        background: #F8ECDA !important;
        color: red
    }
</style>
