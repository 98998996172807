let config = {};
if (process.env.NODE_ENV === "development") {
    //生产环境
    config = {
        api: 'http://platform-user-test.rlinking.com',
        bpi: 'http://passage-car-test.rlinking.com',
        cpi: 'http://passage-person-test.rlinking.com',
        // api: 'http://192.168.0.156:10890',
        // bpi: 'http://192.168.0.156:10700',
        // cpi: 'http://192.168.0.156:10701',
    }
} else if (process.env.NODE_ENV === "production") {
    //正式环境
    config = {
        api: 'https://platform-user.rlinking.com',
        bpi: 'https://passage-car.rlinking.com',
        cpi: 'https://passage-person.rlinking.com',
    }
} else if (process.env.NODE_ENV === "test") {
    //测试环境
    config = {
        api: 'http://platform-user-test.rlinking.com',
        bpi: 'http://passage-car-test.rlinking.com',
        cpi: 'http://passage-person-test.rlinking.com',
    }
}
export default config
