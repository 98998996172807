<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>车场管理</el-breadcrumb-item>
            <el-breadcrumb-item>液晶广告素材</el-breadcrumb-item>
        </el-breadcrumb>
        <el-form :inline="true" :model="filters" class="user-search">
            <el-form-item>
                <el-input size="small" v-model="filters.materialName" placeholder="请输入素材名称"
                          @keyup.enter.native="fetchData"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-search" type="primary" size="small" @click="fetchData">查询</el-button>
                <el-button icon="el-icon-plus" type="primary" size="small" @click="handleAdd">添加</el-button>
            </el-form-item>
        </el-form>
        <!--列表-->
        <el-table
                size="small"
                :data="tableData"
                highlight-current-row
                v-loading="loading"
                element-loading-text="拼命加载中"
                border
                style="width: 100%"
                :row-style="{height:'45px'}"
                :cell-style="{padding:'0px'}"
                :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                stripe
                height="500"
                max-height="500">
            <el-table-column min-width="30" type="index" label="序号"></el-table-column>
            <el-table-column prop="materialName" label="素材名称" align="center"></el-table-column>
            <el-table-column prop="materialType" label="素材类型" align="center">
                <template slot-scope="scope">
                    <el-tag type="success" v-if="scope.row.materialType == 1">图片</el-tag>
                    <el-tag type="primary" v-if="scope.row.materialType == 2">视频</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="materialUrl" label="素材" align="center">
                <template slot-scope="scope">
                    <el-image v-if="scope.row.materialType == 1"
                              class="avatar-img" style="width: 178px; height: 100px" fit="contain"
                              :src="scope.row.materialUrl?scope.row.materialUrl:require('../../../assets/images/defaultImg.png')"
                              :preview-src-list="[scope.row.materialUrl?scope.row.materialUrl:require('../../../assets/images/defaultImg.png')]">
                        <div slot="error">
                            <el-image :src="require('../../../assets/images/defaultImg.png')">
                            </el-image>
                        </div>
                    </el-image>
                    <video v-if="scope.row.materialType == 2"
                           :src="scope.row.materialUrl"
                           class="video-avatar"
                           controls="controls">
                    </video>
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="添加时间" align="center" :formatter="getCreateTime"></el-table-column>
            <el-table-column prop="customerName" label="添加人员" align="center"></el-table-column>
            <el-table-column width="120" align="center" label="操作">
                <template slot-scope="scope">
                    <el-button size="mini" @click="handleDelete(scope.$index, scope.row)"
                               type="danger">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="filters.current"
                       :page-sizes="[10, 50, 200, 1000]"
                       :page-size="filters.size"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
        <el-dialog v-draggable  :title="title" :visible.sync="addDialog" width="40%">
            <el-form :model="editForm" ref="editForm" size="small" :rules="rules">
                <el-row>
                    <el-col :span="18">
                        <el-form-item label="素材名称" prop="materialName" :label-width="formLabelWidth">
                            <el-input size="small" v-model="editForm.materialName" placeholder="请输入内容"/>
                        </el-form-item>
                        <el-form-item label="素材类型" prop="materialType" :label-width="formLabelWidth">
                            <el-select style="width: 100%" size="small"
                                       v-model="editForm.materialType" clearable placeholder="请选择类型">
                                <el-option label="图片" :value="1"></el-option>
                                <el-option label="视频" :value="2"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="素材" :label-width="formLabelWidth">
                            <div style="display: flex;flex-direction: column;">
                                <div v-if="editForm.materialType == 1"
                                     style="width:178px;height:178px;position:relative;">
                                <span v-if="editForm.materialUrl" class="el-upload-list__item-delete"
                                      @click="handleImageRemove()"><i class="el-icon-delete"></i></span>
                                    <el-upload
                                            class="avatar-uploader"
                                            action="#"
                                            :multiple="false"
                                            name="file"
                                            :auto-upload="false"
                                            :show-file-list="false"
                                            :on-change="handleChange">
                                        <img v-if="editForm.materialUrl" :src="editForm.materialUrl" class="avatar">
                                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                    </el-upload>
                                </div>
                                <div v-else-if="editForm.materialType == 2"
                                     style="width:178px;height:178px;position:relative;">
                                    <el-upload class="avatar-uploader"
                                               action="#"
                                               :on-progress="uploadVideoProcess"
                                               :on-success="handleVideoSuccess"
                                               :before-upload="beforeUploadVideo"
                                               :http-request="uploadSuccess"
                                               :show-file-list="false">
                                        <video v-if="editForm.materialUrl !='' && !videoFlag"
                                               :src="editForm.materialUrl"
                                               class="avatar"
                                               controls="controls">
                                            您的浏览器不支持视频播放
                                        </video>
                                        <i v-else-if="editForm.materialUrl =='' && !videoFlag"
                                           class="el-icon-plus avatar-uploader-icon"></i>
                                        <el-progress v-if="videoFlag == true"
                                                     type="circle"
                                                     :percentage="videoUploadPercent"
                                                     style="margin-top:7px;"></el-progress>
                                    </el-upload>
                                </div>

                            </div>
                        </el-form-item>
                        <el-row style="text-align: left;">
                            <div style="margin-left: 135px;color: red;" v-if="editForm.materialType == 1">
                                支持格式JPG/PNG，大小不超过2MB，建议长宽比1.32:1
                            </div>
                            <div style="margin-left: 135px;color: red;" v-if="editForm.materialType == 2">
                                支持格式MP4/AVI/3GP，大小不超过20MB
                            </div>
                        </el-row>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addDialog = false">取 消</el-button>
                <el-button type="primary" @click="confirm('editForm')">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import {advertisingList, advertisingSave, advertisingDel} from "@/api/advertisement";
    import {uploadFile} from "@/api/merchantDiscount/merchant";

    export default {
        name: "lcdAdvertisingMaterial",
        data() {
            return {
                loading: false,
                total: 0,
                tableData: [],
                filters: {
                    materialName: '',
                    current: 1,
                    size: 10,
                },
                formLabelWidth: '200px',
                addDialog: false,
                title: "",
                editForm: {
                    materialName: "",
                    materialType: "",
                    materialUrl: "",
                },
                rules: {
                    materialName: [{required: true, message: "请输入", trigger: "blur"}],
                    materialType: [{required: true, message: "请选择", trigger: "change"}],
                    materialUrl: [{required: true, message: '请选择', trigger: 'change'}],
                },
                show: false,
                videoFlag: false,
                //是否显示进度条
                videoUploadPercent: "",
                //进度条的进度，
                isShowUploadVideo: false,
                //显示上传按钮
                videoForm: {
                    showVideoPath: ''
                }
            }
        },
        mounted() {
            this.fetchData();  //调用接口获取动态数据
        },
        methods: {
            fetchData() { //获取数据
                let that = this;
                let params = {
                    current: that.filters.current,
                    size: that.filters.size,
                    materialName: that.filters.materialName,
                };
                that.loading = true;
                advertisingList(params).then((res) => {
                    that.loading = false;
                    that.tableData = res.data.data.records;
                    that.total = res.data.data.total;
                    that.filters.current = res.data.data.current;
                    that.filters.size = res.data.data.size;
                }).catch(error => {
                    that.loading = false;
                    console.log(error);
                });
            },
            handleSizeChange(val) {
                this.filters.size = val;
                this.filters.current = 1;
                this.fetchData();
            },
            handleCurrentChange(val) {
                this.filters.current = val;
                this.fetchData();
            },
            handleAdd() {
                this.title = '液晶广告素材';
                this.addDialog = true;
                Object.keys(this.editForm).map(key => {
                    this.editForm[key] = '';
                })
                this.editForm.materialType = 1;
            },
            confirm(editForm) {
                this.$refs[editForm].validate((valid) => {
                        if (valid) {
                            let params = {
                                materialName: this.editForm.materialName,
                                materialType: this.editForm.materialType,
                                materialUrl: this.editForm.materialUrl,
                            }
                            advertisingSave(params).then((res) => {
                                this.addDialog = false;
                                this.fetchData();
                                if (res.data.code == "SUCCESS") {
                                    this.$message.success("保存成功！");
                                } else {
                                    this.$message.info(res.data.message);
                                }
                            })
                        }
                    }
                )
            },
            handleChange(file) {
                const isLt2M = file.size / 1024 / 1024 < 2;
                var testmsg = /^image\/(jpeg|png|jpg)$/.test(file.raw.type);
                if (!testmsg) {
                    this.$message.error('上传图片只能是 JPEG|PNG|JPG 格式!');
                    return
                }
                if (!isLt2M) {
                    this.$message.error('上传图片大小不能超过 2MB!');
                    return;
                }
                let form = new FormData();
                form.append('file', file.raw);
                uploadFile(form).then((res) => {
                        this.$message.success("上传成功!");
                        this.editForm.materialUrl = res.data.data;
                    }
                );
            },
            handleImageRemove() {
                let that = this;
                this.$confirm(
                    '是否确认删除?',
                    "警告",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    }
                ).then(function () {
                    that.editForm.value3 = '';
                });

            },
            //上传前回调
            beforeUploadVideo(file) {
                var fileSize = file.size / 1024 / 1024 < 20;
                if (['video/mp4', 'video/ogg', 'video/flv', 'video/avi', 'video/wmv', 'video/rmvb', 'video/mov', 'video/3gp'].indexOf(file.type) == -1) {
                    this.$message.error("请上传正确的视频格式");
                    return false;
                }
                if (!fileSize) {
                    this.$message.error("视频大小不能超过 20MB！");
                    return false;
                }
                this.isShowUploadVideo = false;
            },
            uploadSuccess(val) {
                let form = new FormData();
                form.append('file', val.file);
                uploadFile(form).then((res) => {
                        this.$message.success("上传成功!");
                        this.editForm.materialUrl = res.data.data;
                    }
                );
            },
            //进度条
            uploadVideoProcess(event, file, fileList) {
                this.videoFlag = true;
                this.videoUploadPercent = file.percentage.toFixed(0) * 1;
            },
            //上传成功回调
            handleVideoSuccess(res, file) {
                this.isShowUploadVideo = true;
                this.videoFlag = false;
                this.videoUploadPercent = 0;
                console.log(res, file)
            },
            handleDelete(index, row) {
                this.$confirm('是否确认删除液晶广告素材?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    advertisingDel(row.id).then((res) => {
                        this.fetchData();
                        if (res.data.code == "SUCCESS") {
                            this.$message.success(res.data.message);
                        } else {
                            this.$message.info(res.data.message);
                        }
                    })
                }).catch(() => {
                    this.$message.info('已取消');
                });
            },
            getCreateTime(row) {
                if (row.createTime != null) {
                    return row.createTime[0] + '-' + row.createTime[1] + '-' + row.createTime[2] + " " +
                        (row.createTime[3] ? row.createTime[3] : "00") + ':' + (row.createTime[4] ? row.createTime[4] : "00") + ':' + (row.createTime[5] ? row.createTime[5] : "00");
                }
            },
        }
    }
</script>

<style scoped>
    .user-search {
        margin-top: 20px;
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }

    .video-avatar {
        width: 178px;
        height: 100px;
        margin: 0 auto;
        display: block;
    }

    avatar-img img {
        width: 178px;
        height: 100px;
    }
</style>
