<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>访客管理</el-breadcrumb-item>
            <el-breadcrumb-item>访客机列表</el-breadcrumb-item>
        </el-breadcrumb>
        <el-form :inline="true" :model="filters" class="user-search">
            <el-form-item>
                <el-input v-model="filters.name" clearable size="small" @keyup.enter.native="handleSearch"
                          placeholder="请输入访客机名称"/>
            </el-form-item>
            <el-form-item>
                <el-input v-model="filters.deviceNo" clearable size="small" @keyup.enter.native="handleSearch"
                          placeholder="请输入访客机编号"/>
            </el-form-item>
            <el-form-item>
                <el-input v-model="filters.sn" clearable size="small" @keyup.enter.native="handleSearch"
                          placeholder="请输入访客机序列号"/>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-search" type="primary" size="small" @click="handleSearch">查询</el-button>
                <el-button icon="el-icon-plus" type="primary" size="small" @click="handleAdd">添加
                </el-button>
            </el-form-item>
        </el-form>
        <el-table
                v-loading="loading"
                element-loading-text="拼命加载中"
                size="small"
                :data="tableData"
                highlight-current-row
                border stripe
                style="width: 100%;"
                :row-style="{height:'45px'}"
                :cell-style="{padding:'0px'}"
                :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                height="500" max-height="500">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column prop="name" label="访客机名称" align="center"></el-table-column>
            <el-table-column prop="deviceNo" label="访客机编号" align="center"></el-table-column>
            <el-table-column prop="sn" label="访客机序列号" align="center"></el-table-column>
            <el-table-column prop="status" label="状态" align="center">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.status != null"
                            :type="scope.row.status == 1 ? '' : 'info'">
                        {{scope.row.status == 1 ? '在线' : scope.row.status == 2 ? '离线' : ''}}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                    <el-button size="mini" @click="handleEdit(scope.$index, scope.row)" icon="el-icon-setting"
                               type="primary">编辑
                    </el-button>
                    <el-button size="mini" type="danger" class="el-icon-delete"
                               @click="handleDelete(scope.$index, scope.row)">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="filters.current"
                       :page-sizes="[10, 50, 200, 1000]"
                       :page-size="filters.size"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
        <el-dialog v-draggable  :title="title" :visible.sync="visibleDialog" width="30%">
            <el-form :model="visibleForm" ref="visibleForm" size="small" :rules="rules" label-width="130px">
                <el-form-item label="访客机名称" prop="name">
                    <el-input style="width: 80%" size="small" v-model="visibleForm.name" placeholder="请输入内容"/>
                </el-form-item>
                <el-form-item label="访客机序列号" prop="sn">
                    <el-input :disabled="disabled" style="width: 80%" size="small" v-model="visibleForm.sn"
                              placeholder="请输入内容"/>
                </el-form-item>
                <el-form-item label="访客机编号" prop="deviceNo">
                    <el-input disabled style="width: 80%" size="small" v-model="visibleForm.deviceNo"
                              placeholder="访客机编号"/>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="visibleDialog = false">取 消</el-button>
                <el-button type="primary" @click="onSubmit('visibleForm')">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import {delVisitor, editVisitor, getDeviceVisitorList, saveVisitor} from "../../../api/visitorMachine";

    export default {
        name: "visitorMachineList",
        data() {
            return {
                loading: false,
                total: 0,
                tableData: [],
                filters: {
                    name: '',
                    deviceNo: '',
                    sn: '',
                    current: 1,
                    size: 10,
                },
                title: '',
                visibleDialog: false,
                visibleForm: {
                    name: '',
                    deviceNo: '',
                    sn: '',
                    id: '',
                },
                rules: {
                    name: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ],
                    sn: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ],
                },
                disabled: false,
                projectType1: "",
                projectType2: "",
            }
        },
        mounted() {
            this.fetchData();
        },
        methods: {
            //查询
            fetchData() {
                this.loading = true;
                getDeviceVisitorList(this.filters).then((res) => {
                    this.loading = false;
                    if (res.data.code == "SUCCESS") {
                        this.tableData = res.data.data.records;
                        this.total = res.data.data.total;
                        this.filters.current = res.data.data.current;
                        this.filters.size = res.data.data.size;
                    } else {
                        this.$message.info(res.data.message);
                    }
                })
            },
            handleSearch() {
                this.filters.current = 1;
                this.fetchData();
            },
            handleSizeChange(val) {
                this.filters.size = val;
                this.filters.current = 1;
                this.fetchData();
            },
            handleCurrentChange(val) {
                this.filters.current = val;
                this.fetchData();
            },
            //添加
            handleAdd() {
                this.title = "访客机管理";
                this.visibleDialog = true;
                this.disabled = false;
                Object.keys(this.visibleForm).map(key => {
                    this.visibleForm[key] = '';
                })
            },
            //编辑
            handleEdit(index, row) {
                this.title = "编辑访客机管理";
                this.visibleDialog = true;
                this.disabled = true;
                Object.keys(this.visibleForm).map(key => {
                    this.visibleForm[key] = row[key];
                });
                this.visibleForm.id = row.id;
            },
            onSubmit(visibleForm) {
                this.$refs[visibleForm].validate((valid) => {
                    if (valid) {
                        let params = {
                            name: this.visibleForm.name,
                            sn: this.visibleForm.sn,
                            id: this.visibleForm.id
                        }
                        if (this.visibleForm.id) {
                            editVisitor(params).then((res) => {
                                this.visibleDialog = false;
                                if (res.data.code == "SUCCESS") {
                                    this.fetchData();
                                    this.$message.success("编辑成功！");
                                } else {
                                    this.$message.info(res.data.message);
                                }
                            })
                        } else {
                            saveVisitor(params).then((res) => {
                                this.visibleDialog = false;
                                if (res.data.code == "SUCCESS") {
                                    this.fetchData();
                                    this.$message.success("保存成功！");
                                } else {
                                    this.$message.info(res.data.message);
                                }
                            })
                        }
                    }
                })
            },
            //删除
            handleDelete(index, row) {
                this.$confirm("确定要删除访客机吗?", "温馨提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    delVisitor(row.id).then((res) => {
                        if (res.data.code == "SUCCESS") {
                            this.fetchData();
                            this.$message.success("删除成功！");
                        } else {
                            this.$message.info(res.data.message);
                        }
                    })
                }).catch(() => {
                    this.$message.info("已取消删除!");
                });
            }
        }
    }
</script>

<style scoped>
    .user-search {
        margin-top: 15px;
    }
</style>
