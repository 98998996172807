<template>
    <el-container class="container">
        <el-header height="50px" style="padding: inherit">
            <router-view name="top"></router-view>
        </el-header>
        <el-container>
            <el-aside>
                <router-view name="aside"></router-view>
            </el-aside>
            <el-main>
                <router-view></router-view>
            </el-main>
        </el-container>
    </el-container>
</template>
<script>
    export default {
        name: 'home',
        data() {
            return {
                loading: false
            }
        }
    }
</script>
<style scoped>
    aside {
        width: auto !important;
        background-color: #334157 !important;
    }

    .el-main {
        background-color: #fff;
    }

    /*.el-container {*/
    /*    height: 100vh;*/
    /*}*/
</style>
