import request from '@/utils/request'
import config from '@/config.js'

//液晶广告素材列表
export function advertisingList(data) {
    return request({
        url: `${config.bpi}/advertising/material`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}

// 添加液晶广告素材
export function advertisingSave(data) {
    return request({
        url: `${config.bpi}/advertising/material`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 删除液晶广告素材
export function advertisingDel(id) {
    return request({
        url: `${config.bpi}/advertising/material`,
        method: 'delete',
        params: {id: id}
    })
}

//液晶广告节目列表
export function advertisingShowList(data) {
    return request({
        url: `${config.bpi}/advertising/show`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}

//添加液晶广告节目
export function advertisingShowSave(data) {
    return request({
        url: `${config.bpi}/advertising/show`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}
// 删除液晶广告节目
export function advertisingShowDel(id) {
    return request({
        url: `${config.bpi}/advertising/show`,
        method: 'delete',
        params: {id: id}
    })
}

//液晶广告设置列表
export function advertisingEquipmentList(data) {
    return request({
        url: `${config.bpi}/advertising/equipment`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}

//添加液晶广告设置
export function advertisingEquipmentSave(data) {
    return request({
        url: `${config.bpi}/advertising/equipment`,
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}
