<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>车场管理</el-breadcrumb-item>
            <el-breadcrumb-item>液晶广告节目</el-breadcrumb-item>
        </el-breadcrumb>
        <el-form :inline="true" :model="filters" class="user-search">
            <el-form-item>
                <el-input size="small" v-model="filters.showName" placeholder="请输入节目名称"
                          @keyup.enter.native="fetchData"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-search" type="primary" size="small" @click="fetchData">查询</el-button>
                <el-button icon="el-icon-plus" type="primary" size="small" @click="handleAdd">添加</el-button>
            </el-form-item>
        </el-form>
        <el-table
                size="small"
                :data="tableData"
                highlight-current-row
                v-loading="loading"
                element-loading-text="拼命加载中"
                border
                style="width: 100%"
                :row-style="{height:'45px'}"
                :cell-style="{padding:'0px'}"
                :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                stripe
                height="500"
                max-height="500">
            <el-table-column min-width="30" type="index" label="序号"></el-table-column>
            <el-table-column prop="showName" label="节目名称" align="center"></el-table-column>
            <el-table-column prop="createTime" label="添加时间" align="center" :formatter="getCreateTime"></el-table-column>
            <el-table-column prop="customerName" label="添加人员" align="center"></el-table-column>
            <el-table-column width="180" align="center" label="操作">
                <template slot-scope="scope">
                    <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
                               type="primary">编辑
                    </el-button>
                    <el-button size="mini" type="danger" class="el-icon-delete"
                               @click="handleDelete(scope.$index, scope.row)">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="filters.current"
                       :page-sizes="[10, 50, 200, 1000]"
                       :page-size="filters.size"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
        <el-dialog v-draggable  title="液晶广告节目" :visible.sync="addDialog" top="10vh">
            <el-form :model="editForm" ref="editForm" size="small" :rules="rules" label-width="80px">
                <el-row>
                    <el-col :span="16">
                        <el-form-item label="节目名称" prop="showName">
                            <el-input size="small" v-model="editForm.showName" placeholder="请输入内容"/>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-form-item label="素材名称" prop="materialType">
                        <el-select style="width: 25%;" @change="onChange" size="small" v-model="editForm.materialType"
                                   placeholder="请选择素材类型">
                            <el-option label="图片" :value="1"></el-option>
                            <el-option label="视频" :value="2"></el-option>
                        </el-select>
                        <el-select style="margin: 0 10px;" size="small" v-model="editForm.id"
                                   placeholder="请选择素材">
                            <el-option
                                    v-for="item in options"
                                    :key="item.id"
                                    :label="item.materialName"
                                    :value="item.id">
                            </el-option>
                            <!--                            <el-pagination-->
                            <!--                                    @current-change="handleCurrentChange2"-->
                            <!--                                    :page-size="editForm.size"-->
                            <!--                                    layout="prev, pager, next"-->
                            <!--                                    :total="totalnum">-->
                            <!--                            </el-pagination>-->
                        </el-select>
                        <el-button icon="el-icon-plus" type="primary" size="small" @click="addClick">添加
                        </el-button>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-table
                            size="small"
                            :data="advertisingMaterials"
                            highlight-current-row
                            border
                            style="width: 100%"
                            :row-style="{height:'45px'}"
                            :cell-style="{padding:'0px'}"
                            :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                            stripe height="300px">
                        <el-table-column min-width="30" type="index" label="序号"></el-table-column>
                        <el-table-column prop="materialName" label="素材名称" align="center"></el-table-column>
                        <el-table-column prop="materialType" label="素材类型" align="center">
                            <template slot-scope="scope">
                                <el-tag type="success" v-if="scope.row.materialType == 1">图片</el-tag>
                                <el-tag type="primary" v-if="scope.row.materialType == 2">视频</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="materialUrl" label="素材" align="center">
                            <template slot-scope="scope">
                                <el-image v-if="scope.row.materialType == 1"
                                          class="avatar-img" style="width: 178px; height: 100px" fit="contain"
                                          :src="scope.row.materialUrl?scope.row.materialUrl:require('../../../assets/images/defaultImg.png')"
                                          :preview-src-list="[scope.row.materialUrl?scope.row.materialUrl:require('../../../assets/images/defaultImg.png')]">
                                    <div slot="error">
                                        <el-image :src="require('../../../assets/images/defaultImg.png')">
                                        </el-image>
                                    </div>
                                </el-image>
                                <video v-if="scope.row.materialType == 2"
                                       :src="scope.row.materialUrl"
                                       class="video-avatar"
                                       controls="controls">
                                </video>
                            </template>
                        </el-table-column>
                        <el-table-column width="120" align="center" label="操作">
                            <template slot-scope="scope">
                                <el-button size="mini" @click="handleAddDelete(scope.$index, scope.row)"
                                           type="danger">删除
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addDialog = false">取 消</el-button>
                <el-button type="primary" @click="confirm('editForm')">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import {
        advertisingList,
        advertisingShowDel,
        advertisingShowList,
        advertisingShowSave
    } from "../../../api/advertisement";

    export default {
        name: "lcdAdvertisingProgram",
        data() {
            return {
                loading: false,
                total: 0,
                tableData: [],
                filters: {
                    showName: '',
                    current: 1,
                    size: 10,
                },
                addDialog: false,
                formLabelWidth: '120px',
                editForm: {
                    showName: "",
                    id: '',
                    showId: "",
                    materialName: '',
                    materialType: '',
                    current: 1,
                    size: 10,
                },
                rules: {
                    showName: [{required: true, message: "请输入", trigger: "blur"}],
                    // materialType: [{required: true, message: '请选择', trigger: 'change'}],
                },
                options: [],
                totalnum: 0,
                advertisingMaterials: [],
                advertisingShow: [],
            }
        },
        mounted() {
            this.fetchData();  //调用接口获取动态数据
        },
        methods: {
            fetchData() { //获取数据
                let that = this;
                let params = {
                    current: that.filters.current,
                    size: that.filters.size,
                    showName: that.filters.showName,
                };
                that.loading = true;
                advertisingShowList(params).then((res) => {
                    that.loading = false;
                    that.tableData = res.data.data.records;
                    that.total = res.data.data.total;
                    that.filters.current = res.data.data.current;
                    that.filters.size = res.data.data.size;
                }).catch(error => {
                    that.loading = false;
                    console.log(error);
                });
            },
            handleSizeChange(val) {
                this.filters.size = val;
                this.filters.current = 1;
                this.fetchData();
            },
            handleCurrentChange(val) {
                this.filters.current = val;
                this.fetchData();
            },
            handleAdd() {
                this.addDialog = true;
                Object.keys(this.editForm).map(key => {
                    this.editForm[key] = '';
                })
                this.advertisingMaterials = [];
                this.advertisingShow = [];
                this.editForm.current = 1;
                this.editForm.size = 1000;
            },
            handleEdit(index, row) {
                this.addDialog = true;
                this.editForm.current = 1;
                this.editForm.size = 1000;
                this.editForm.showName = row.showName;
                this.editForm.showId = row.id;
                this.editForm.id = "";
                this.editForm.materialType = "";
                this.advertisingMaterials = row.advertisingMaterials;
            },
            getAdvertisingList() {
                let params = {
                    current: this.editForm.current,
                    size: this.editForm.size,
                    // materialName: this.editForm.materialName,
                    materialType: this.editForm.materialType,
                };
                advertisingList(params).then((res) => {
                    this.options = res.data.data.records;
                    this.totalnum = res.data.data.total;
                }).catch(error => {
                    console.log(error);
                });
            },
            //分页方法
            handleCurrentChange2(val) {
                this.editForm.current = val;
                this.getAdvertisingList();
            },
            onChange() {
                this.editForm.current = 1;
                this.getAdvertisingList();
            },
            //添加选择素材
            addClick() {
                let material = this.options.find((x) => x.id == this.editForm.id)
                let index = this.advertisingMaterials.findIndex((x) => x.materialType == 2)
                let exist = this.advertisingMaterials.find((x) => x.id == material.id)
                if (material.materialType == 2) {
                    if (index == -1) index = this.advertisingMaterials.length
                    this.$set(this.advertisingMaterials, index, material)
                } else if (!exist) {
                    this.advertisingMaterials.push(material)
                }
            },
            //提交添加表单素材节目
            confirm(editForm) {
                this.$refs[editForm].validate((valid) => {
                    if (valid) {
                        let params = {
                            id: this.editForm.showId,
                            advertisingMaterials: this.advertisingMaterials,
                            showName: this.editForm.showName,
                        }
                        advertisingShowSave(params).then((res) => {
                            this.addDialog = false;
                            this.fetchData();
                            if (res.data.code == "SUCCESS") {
                                this.$message.success("保存成功！");
                            } else {
                                this.$message.info(res.data.message);
                            }
                        })
                    }
                })
            },
            //添加删除素材
            handleAddDelete(index, row) {
                this.$confirm('是否确认删除广告素材?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.advertisingMaterials.splice(index, 1);
                    this.$message.success('已删除');
                }).catch(() => {
                    this.$message.info('已取消');
                });
            },
            getCreateTime(row) {
                if (row.createTime != null) {
                    return row.createTime[0] + '-' + row.createTime[1] + '-' + row.createTime[2] + " " +
                        (row.createTime[3] ? row.createTime[3] : "00") + ':' + (row.createTime[4] ? row.createTime[4] : "00") + ':' + (row.createTime[5] ? row.createTime[5] : "00");
                }
            },
            //删除
            handleDelete(index, row) {
                this.$confirm("确定要删除设备吗?", "温馨提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    advertisingShowDel(row.id).then((res) => {
                        if (res.data.code == "SUCCESS") {
                            this.fetchData();
                            this.$message.success("删除成功！");
                        } else {
                            this.$message.info(res.data.message);
                        }
                    })
                }).catch(() => {
                    this.$message.info("已取消删除!");
                });
            },
        }
    }
</script>

<style scoped>
    .user-search {
        margin-top: 20px;
    }

    .video-avatar {
        width: 178px;
        height: 100px;
        margin: 0 auto;
        display: block;
    }

    avatar-img img {
        width: 178px;
        height: 100px;
    }
</style>
