<template>
    <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="通行规则" name="one">
            <el-row :gutter="20">
                <el-col :span="16">
                    <el-form label-position="left" ref="editForm" :model="editForm">
                        <el-form-item label="固定车识别模式" :label-width="formLabelWidth">
                            <el-radio-group v-model="editForm.carMode">
                                <el-radio label="1">车牌</el-radio>
                                <el-radio label="2">卡</el-radio>
                                <el-radio label="3">车牌或卡</el-radio>
                                <el-radio label="4">车牌+卡</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="长租车过期转为会员车/临时车" :label-width="formLabelWidth">
                            <el-radio-group v-model="editForm.valueF">
                                <el-radio label="8">临时车</el-radio>
                                <el-radio label="4">会员车</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="长租车过期是否收费" :label-width="formLabelWidth">
                            <el-radio-group v-model="editForm.value16">
                                <el-radio label="0">否</el-radio>
                                <el-radio label="1">是</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="长租车通行权限判断依据" :label-width="formLabelWidth">
                            <el-radio-group v-model="editForm.value18">
                                <el-radio label="1">区域属性</el-radio>
                                <el-radio label="2">车位可通行区域</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="中央支付可出场时间" :label-width="formLabelWidth">
                            <div style="display: flex">
                                <el-input style="width: 50%;" size="small" oninput="value=value.replace(/[^\d]/g,'')"
                                          v-model="editForm.value1" placeholder="请输入"/>
                                <span style="margin-left: 5px;width: 40px;">分钟</span>
                            </div>
                        </el-form-item>
                        <el-form-item label="是否为收费车场" :label-width="formLabelWidth">
                            <el-radio-group v-model="editForm.value14" @change="handleCheck">
                                <el-radio label="0">否</el-radio>
                                <el-radio label="1">是</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="是否开通预约车" :label-width="formLabelWidth">
                            <el-radio-group v-model="editForm.value15">
                                <el-radio label="0">否</el-radio>
                                <el-radio label="1">是</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="是否开启ETC收费" :label-width="formLabelWidth" v-show="editForm.value14 == 1">
                            <el-checkbox label="ETC收费" v-model="editForm.etcChargeMode"
                                         true-label="true"
                                         false-label="false"></el-checkbox>
                        </el-form-item>
                        <el-form-item label="无牌车判定模式" :label-width="formLabelWidth">
                            <el-radio-group v-model="editForm.value17">
                                <el-radio label="0">宽松</el-radio>
                                <el-radio label="1">严格</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="尾牌处理" :label-width="formLabelWidth">
                            <el-checkbox label="过滤尾牌" v-model="editForm.value12"
                                         true-label="true"
                                         false-label="false"></el-checkbox>
                        </el-form-item>
                        <el-form-item label="临时车未匹配入场记录时处理方式" :label-width="formLabelWidth">
                            <el-row>
                                <el-col :span="12">
                                    <el-radio-group v-model="editForm.value3">
                                        <el-radio label="1">直接放行</el-radio>
                                        <el-radio label="3">禁止通行</el-radio>
                                        <el-radio label="2">收费</el-radio>
                                    </el-radio-group>
                                </el-col>
                                <el-col :span="12">
                                    <div style="display: flex">
                                        <el-input style="width: 50%;" size="small"
                                                  oninput="value=value.replace(/[^0-9.]/g,'')" v-model="editForm.value4"
                                                  placeholder="请输入"/>
                                        <span style="margin-left: 5px;width: 40px;">元</span>
                                    </div>
                                </el-col>
                            </el-row>
                        </el-form-item>
                        <el-form-item label="车位满允许进入车辆" :label-width="formLabelWidth">
                            <el-checkbox-group v-model="editForm.value5">
                                <el-checkbox label="1">临时车</el-checkbox>
                                <el-checkbox label="2">长租车</el-checkbox>
                                <el-checkbox label="3">会员车</el-checkbox>
                                <el-checkbox label="6">储值车</el-checkbox>
                                <el-checkbox label="4">长租过期</el-checkbox>
                                <el-checkbox label="5">一位多车超员</el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                        <el-form-item label="自由通行车牌类型" :label-width="formLabelWidth">
                            <el-checkbox label="军警车" v-model="editForm.value10" true-label="true"
                                         false-label="false"></el-checkbox>
                        </el-form-item>
                        <el-form-item label="车牌对比方式" :label-width="formLabelWidth">
                            <el-row>
                                <el-col :span="11">
                                    <el-radio-group v-model="editForm.value6">
                                        <el-radio label="1">完全匹配</el-radio>
                                        <el-radio label="2">允许错几位</el-radio>
                                    </el-radio-group>
                                </el-col>
                                <el-col :span="11">
                                    <el-select size="small" v-model="editForm.value7" clearable placeholder="请选择">
                                        <el-option label="1" value="1"></el-option>
                                        <el-option label="2" value="2"></el-option>
                                        <el-option label="3" value="3"></el-option>
                                    </el-select>
                                </el-col>
                            </el-row>
                        </el-form-item>
                        <el-form-item label="是否启用一位多车" :label-width="formLabelWidth">
                            <el-radio-group v-model="editForm.value13">
                                <el-radio label="0">否</el-radio>
                                <el-radio label="1">是</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item v-show="editForm.value13 == '1'" label="一位多车入场车辆超出车位时"
                                      :label-width="formLabelWidth">
                            <el-radio-group v-model="editForm.valueSix">
                                <el-radio label="1">禁止入场</el-radio>
                                <el-radio label="2">按会员车费率收费</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item :label-width="formLabelWidth">
                            <el-button type="primary" @click="onSubmit1('editForm')">保 存</el-button>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
        </el-tab-pane>
        <el-tab-pane label="语音" name="two">
            <div style="width: 50%">
                <el-form ref="editForm" :model="editForm" label-width="140px">
                    <el-form-item label="日间音量设置">
                        <div style="display: flex">
                            <el-input style="width: 50%;" min="0" max="10" oninput="value=value.replace(/[^\d]/g,'')"
                                      size="small"
                                      v-model="editForm.value8" placeholder="请输入"/>
                            <span style="margin-left: 5px;width: 120px;">取值范围0-10</span>
                        </div>
                    </el-form-item>
                    <el-form-item label="晚间音量设置">
                        <div style="display: flex">
                            <el-input style="width: 50%;" min="0" max="10" oninput="value=value.replace(/[^\d]/g,'')"
                                      size="small"
                                      v-model="editForm.value11" placeholder="请输入"/>
                            <span style="margin-left: 5px;width: 120px;">取值范围0-10</span>
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit2('editForm')">保 存</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-tab-pane>
        <el-tab-pane label="数据上报" name="three">
            <el-row :gutter="20">
                <el-col :span="16">
                    <el-form ref="editForm" :model="editForm" label-position="left" >
                        <el-form-item>
                            <el-checkbox label="是否上报" v-model="editForm.value9" true-label="true"
                                         false-label="false"></el-checkbox>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="onSubmit3('editForm')">保 存</el-button>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
        </el-tab-pane>
        <el-tab-pane label="场中场设置" name="four">
            <el-row :gutter="20">
                <el-col :span="16">
                    <el-form label-position="left" ref="editForm" :model="editForm">
                        <el-form-item>
                            <el-checkbox label="开启场中场" v-model="editForm.valueA" true-label="true"
                                         false-label="false"></el-checkbox>
                        </el-form-item>
                        <el-form-item>
                            <el-checkbox v-if="editForm.valueA == 'true'" label="分开审计" v-model="editForm.valueB"
                                         true-label="true"
                                         false-label="false"></el-checkbox>
                        </el-form-item>
                        <el-form-item v-if="editForm.valueA == 'true'" label="跨区计费方式" label-width="100px">
                            <el-radio-group @change="handleChange" v-model="editForm.valueC">
                                <el-radio label="1">多费率累计</el-radio>
                                <el-radio label="2">单一费率</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item v-if="editForm.valueC == 1" label="计费方式" label-width="100px">
                            <el-radio-group v-model="editForm.valueD">
                                <el-radio label="1">全天单位时长</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item v-if="editForm.valueC == 2" label="计费方式" label-width="100px">
                            <el-radio-group v-model="editForm.valueD">
                                <el-radio label="2">全天阶梯固定</el-radio>
                                <el-radio label="3">全天按次</el-radio>
                                <el-radio label="4">分时段单位时长</el-radio>
                                <el-radio label="5">分时段按次</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item v-if="editForm.valueC == 2" label="选择区域" label-width="100px">
                            <el-select size="small" v-model="editForm.valueE">
                                <el-option v-for="item in areasData" :label="item.name"
                                           :value="item.id" :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item :label-width="formLabelWidth">
                            <el-button type="primary" @click="submitForm('editForm')">保 存</el-button>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
        </el-tab-pane>
    </el-tabs>
</template>

<script>
    import {configSave, parkingAreaRoadList, parkingConfigList} from "../../../../api/yardSetting";

    export default {
        name: "fullFieldParameters",
        data() {
            return {
                activeName: 'one',
                typeData: [
                    {
                        index: 1,
                        name: '车牌'
                    },
                    {
                        index: 2,
                        name: '缴费金额'
                    },
                    {
                        index: 3,
                        name: '停车时长'
                    },
                    {
                        index: 4,
                        name: '长租车/临停车'
                    },
                ],
                formLabelWidth: '225px',
                editForm: {
                    value1: "",//中央支付可出场时间
                    value2: "",//长租过期是否收费
                    value3: "",//临时车未匹配入场记录时处理方式
                    value4: "",
                    value5: [],//车位满允许进入车辆
                    value6: "",//车牌对比方式
                    value7: "",
                    value8: "",
                    value9: "",//是否上报
                    value10: "true",//自由通行车牌类型
                    value11: "",//音量设置
                    value12: "",//是否过滤尾牌
                    value13: "0",
                    value14: "0",
                    value15: "0",
                    value16: "1",
                    value17: "1",
                    value18: "1",
                    valueA: "",
                    valueB: "",
                    valueC: "",
                    valueD: "",
                    valueE: "",
                    valueF: "4",
                    valueSix: "",
                    carMode: "1",
                    etcChargeMode: "false"
                },
                display: true,
                show: false,
                areasData: [],
                id: "",
                parking_space_full_process: []
            };
        },
        mounted() {
            this.id = localStorage.getItem("subSysId");
            this.getParkingConfigList();
        },
        methods: {
            handleClick(e) {
                this.getParkingConfigList();
                if (e.name === 'four') {
                    this.getDataList();
                }
            },
            getParkingConfigList() {
                parkingConfigList(this.id).then((res) => {
                    this.editForm.value14 = res.data.data.charge_enable ? res.data.data.charge_enable[0].configValue == "true" ? '1' : '0' : '0';
                    this.editForm.value15 = res.data.data.appointment_enable ? res.data.data.appointment_enable[0].configValue == "true" ? '1' : '0' : '0';
                    this.editForm.value16 = res.data.data.long_car_expire_charge ? res.data.data.long_car_expire_charge[0].configValue == "true" ? '1' : '0' : '1';
                    this.editForm.value17 = res.data.data.unlicensed_judge_strict ? res.data.data.unlicensed_judge_strict[0].configValue == "true" ? '1' : '0' : '1';
                    this.editForm.value18 = res.data.data.long_car_pass_credential ? res.data.data.long_car_pass_credential[0].configValue : '1';
                    this.editForm.valueF = res.data.data.long_car_expire_type ? res.data.data.long_car_expire_type[0].configValue : '4';
                    this.editForm.carMode = res.data.data.recog_regular_car_mode ? res.data.data.recog_regular_car_mode[0].configValue : '1';
                    this.editForm.etcChargeMode = res.data.data.etc_charge_enable ? res.data.data.etc_charge_enable[0].configValue == "true" ? '1' : '0' : '0';
                    this.editForm.value1 = res.data.data.pay_after_play_time ? res.data.data.pay_after_play_time[0].configValue : '';
                    // this.editForm.value2 = res.data.data.long_car_over_charge ? res.data.data.long_car_over_charge[0].configValue : '';
                    this.editForm.value3 = res.data.data.not_match_process ? res.data.data.not_match_process[0].configValue : '';
                    this.editForm.value4 = res.data.data.not_match_process_charge ? res.data.data.not_match_process_charge[0].configValue : '';
                    this.editForm.value6 = res.data.data.license_plate_comparison ? res.data.data.license_plate_comparison[0].configValue : '';
                    this.editForm.value7 = res.data.data.license_place_comparison_place ? res.data.data.license_place_comparison_place[0].configValue : '';
                    this.editForm.value8 = res.data.data.voice_volume ? res.data.data.voice_volume[0].configValue : '';
                    this.editForm.value9 = res.data.data.data_report ? res.data.data.data_report[0].configValue : '';
                    this.editForm.value10 = res.data.data.official_business_car_passage ? res.data.data.official_business_car_passage[0].configValue : '';
                    this.editForm.value11 = res.data.data.voice_night_volume ? res.data.data.voice_night_volume[0].configValue : '';
                    this.editForm.value12 = res.data.data.ignore_rear_license_plate ? res.data.data.ignore_rear_license_plate[0].configValue : '';
                    this.editForm.value13 = res.data.data.one_place_multi_car ? res.data.data.one_place_multi_car[0].configValue == "true" ? '1' : '0' : '0';
                    this.editForm.valueA = res.data.data.enable_nested_parking ? res.data.data.enable_nested_parking[0].configValue : '';
                    this.editForm.valueB = res.data.data.audit_separate ? res.data.data.audit_separate[0].configValue : '';
                    this.editForm.valueC = res.data.data.trans_area_charge_mode ? res.data.data.trans_area_charge_mode[0].configValue : '';
                    this.editForm.valueD = res.data.data.charge_mode ? res.data.data.charge_mode[0].configValue : '';
                    this.editForm.valueE = res.data.data.single_charge_rule_area ? res.data.data.single_charge_rule_area[0].configValue ? parseInt(res.data.data.single_charge_rule_area[0].configValue) : '' : '';
                    this.editForm.valueSix = res.data.data.one_place_multi_car_exceed ? res.data.data.one_place_multi_car_exceed[0].configValue : '';
                    if (res.data.data.parking_space_full_process) {
                        const r = new Map();
                        let arr = res.data.data.parking_space_full_process.filter((a) => !r.has(a.configValue) && r.set(a.configValue, a.configValue));
                        arr.forEach((e) => {
                            this.editForm.value5.push(e.configValue + '');
                        })
                    }
                    let arr = new Set(this.editForm.value5);
                    this.editForm.value5 = Array.from(arr);
                })
            },
            handleCheck(e) {
                if (e == 1) {
                    const confirmText = ['是否现在去设置收费标准?', '需要配置临时车和会员车费率'];
                    const newDatas = [];
                    const h = this.$createElement;
                    for (const i in confirmText) {
                        newDatas.push(h('p', null, confirmText[i]));
                    }
                    this.$confirm(h('div', null, newDatas), '温馨提示', {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    }).then(() => {
                        this.editForm.value14 = true;
                        var charge_enable = [{
                            configCode: "charge_enable",
                            configValue: this.editForm.value14,
                            parkingId: this.id,
                        }];
                        let param = {
                            charge_enable: charge_enable
                        }
                        configSave(this.id, param).then((res) => {
                            if (res.data.code == "SUCCESS") {
                                this.$emit('change', '4');
                                this.getParkingConfigList();
                            } else {
                                this.$message.info(res.data.message);
                            }
                        })
                    }).catch(() => {
                        this.$message.info("已取消!");
                    });
                } else {
                    this.editForm.value14 = false;
                    var charge_enable = [{
                        configCode: "charge_enable",
                        configValue: this.editForm.value14,
                        parkingId: this.id,
                    }];
                    let param = {
                        charge_enable: charge_enable
                    }
                    configSave(this.id, param).then((res) => {
                        if (res.data.code == "SUCCESS") {
                            this.getParkingConfigList();
                        } else {
                            this.$message.info(res.data.message);
                        }
                    })
                }
            },
            onSubmit1(editForm) {
                this.$refs[editForm].validate((valid) => {
                    if (valid) {
                        var recog_regular_car_mode = [{
                            configCode: "recog_regular_car_mode",
                            configValue: this.editForm.carMode,
                            parkingId: this.id,
                        }]
                        var etc_charge_enable = [{
                            configCode: "etc_charge_enable",
                            configValue: this.editForm.etcChargeMode ? this.editForm.etcChargeMode == "1" ? true : false : false,
                            parkingId: this.id,
                        }]
                        var pay_after_play_time = [{
                            configCode: "pay_after_play_time",
                            configValue: this.editForm.value1,
                            parkingId: this.id,
                        }]
                        // var long_car_over_charge = [{
                        //     configCode: "long_car_over_charge",
                        //     configValue: this.editForm.value2,
                        //     parkingId: this.id,
                        // }]
                        var not_match_process = [{
                            configCode: "not_match_process",
                            configValue: this.editForm.value3,
                            parkingId: this.id,
                        }]
                        var not_match_process_charge = [{
                            configCode: "not_match_process_charge",
                            configValue: this.editForm.value4,
                            parkingId: this.id,
                        }]
                        this.parking_space_full_process = [];
                        for (var i = 0; i < this.editForm.value5.length; i++) {
                            this.parking_space_full_process.push({
                                configCode: "parking_space_full_process",
                                configValue: this.editForm.value5[i],
                                parkingId: this.id,
                            })
                        }
                        var license_plate_comparison = [{
                            configCode: "license_plate_comparison",
                            configValue: this.editForm.value6,
                            parkingId: this.id,
                        }]
                        var one_place_multi_car_exceed = [{
                            configCode: "one_place_multi_car_exceed",
                            configValue: this.editForm.valueSix,
                            parkingId: this.id,
                        }]
                        var license_place_comparison_place = [{
                            configCode: "license_place_comparison_place",
                            configValue: this.editForm.value7,
                            parkingId: this.id,
                        }]
                        var official_business_car_passage = [{
                            configCode: "official_business_car_passage",
                            configValue: this.editForm.value10,
                            parkingId: this.id,
                        }]
                        var ignore_rear_license_plate = [{
                            configCode: "ignore_rear_license_plate",
                            configValue: this.editForm.value12,
                            parkingId: this.id,
                        }]
                        var one_place_multi_car = [{
                            configCode: "one_place_multi_car",
                            configValue: this.editForm.value13 ? this.editForm.value13 == "1" ? true : false : false,
                            parkingId: this.id,
                        }]
                        var long_car_expire_type = [{
                            configCode: "long_car_expire_type",
                            configValue: this.editForm.valueF,
                            parkingId: this.id,
                        }]
                        var appointment_enable = [{
                            configCode: "appointment_enable",
                            configValue: this.editForm.value15 ? this.editForm.value15 == "1" ? true : false : false,
                            parkingId: this.id,
                        }]
                        var long_car_expire_charge = [{
                            configCode: "long_car_expire_charge",
                            configValue: this.editForm.value16 ? this.editForm.value16 == "1" ? true : false : true,
                            parkingId: this.id,
                        }]
                        var unlicensed_judge_strict = [{
                            configCode: "unlicensed_judge_strict",
                            configValue: this.editForm.value17 ? this.editForm.value17 == "1" ? true : false : true,
                            parkingId: this.id,
                        }]
                        var long_car_pass_credential = [{
                            configCode: "long_car_pass_credential",
                            configValue: this.editForm.value18,
                            parkingId: this.id,
                        }]
                        const res = new Map();
                        let param = {
                            long_car_pass_credential: long_car_pass_credential,
                            long_car_expire_charge: long_car_expire_charge,
                            appointment_enable: appointment_enable,
                            long_car_expire_type: long_car_expire_type,
                            pay_after_play_time: pay_after_play_time,
                            unlicensed_judge_strict: unlicensed_judge_strict,
                            not_match_process: not_match_process,
                            not_match_process_charge: not_match_process_charge,
                            parking_space_full_process: this.parking_space_full_process.filter((a) => !res.has(a.configValue) && res.set(a.configValue, a.configValue)),
                            license_plate_comparison: license_plate_comparison,
                            license_place_comparison_place: license_place_comparison_place,
                            official_business_car_passage: official_business_car_passage,
                            ignore_rear_license_plate: ignore_rear_license_plate,
                            one_place_multi_car: one_place_multi_car,
                            one_place_multi_car_exceed: one_place_multi_car_exceed,
                            recog_regular_car_mode: recog_regular_car_mode,
                            etc_charge_enable: etc_charge_enable
                        }
                        configSave(this.id, param).then((res) => {
                            if (res.data.code == "SUCCESS") {
                                this.$message.success("保存成功！");
                                this.getParkingConfigList();
                            } else {
                                this.$message.info(res.data.message);
                            }
                        })
                    }
                })
            },
            onSubmit2(editForm) {
                this.$refs[editForm].validate((valid) => {
                    if (valid) {
                        if (this.editForm.value8 > 10 || this.editForm.value8 < 0) {
                            this.$message({
                                message: '音量设置数值需小于10或大于0',
                                type: 'warning',
                                duration: 2000
                            })
                            return;
                        }
                        if (this.editForm.value11 > 10 || this.editForm.value11 < 0) {
                            this.$message({
                                message: '音量设置数值需小于10或大于0',
                                type: 'warning',
                                duration: 2000
                            })
                            return;
                        }
                        var voice_volume = [{
                            configCode: "voice_volume",
                            configValue: this.editForm.value8,
                            parkingId: this.id,
                        }]
                        var voice_night_volume = [{
                            configCode: "voice_night_volume",
                            configValue: this.editForm.value11,
                            parkingId: this.id,
                        }]
                        let param = {
                            voice_volume: voice_volume,
                            voice_night_volume: voice_night_volume
                        }
                        configSave(this.id, param).then((res) => {
                            if (res.data.code == "SUCCESS") {
                                this.$message.success("保存成功！");
                                this.getParkingConfigList();
                            } else {
                                this.$message.info(res.data.message);
                            }
                        })
                    }
                })
            },
            onSubmit3(editForm) {
                this.$refs[editForm].validate((valid) => {
                    if (valid) {
                        var data_report = [{
                            configCode: "data_report",
                            configValue: this.editForm.value9,
                            parkingId: this.id,
                        }]
                        let param = {
                            data_report: data_report,
                        }
                        configSave(this.id, param).then((res) => {
                            if (res.data.code == "SUCCESS") {
                                this.$message.success("保存成功！");
                                this.getParkingConfigList();
                            } else {
                                this.$message.info(res.data.message);
                            }
                        })
                    }
                })
            },
            getDataList() {
                parkingAreaRoadList(this.id).then((res) => {
                    this.areasData = res.data.data.parkingAreas;
                })
            },
            handleChange(e) {
                this.editForm.valueD = "";
                if (e == 1) {
                    this.display = true;
                    this.show = false;
                } else {
                    this.show = true;
                    this.display = false;
                }
            },
            submitForm(editForm) {
                this.$refs[editForm].validate((valid) => {
                    if (valid) {
                        var enable_nested_parking = [{
                            configCode: "enable_nested_parking",
                            configValue: this.editForm.valueA,
                            parkingId: this.id,
                        }]
                        var audit_separate = [{
                            configCode: "audit_separate",
                            configValue: this.editForm.valueB,
                            parkingId: this.id,
                        }]
                        var trans_area_charge_mode = [{
                            configCode: "trans_area_charge_mode",
                            configValue: this.editForm.valueC,
                            parkingId: this.id,
                        }]
                        var charge_mode = [{
                            configCode: "charge_mode",
                            configValue: this.editForm.valueD,
                            parkingId: this.id,
                        }]
                        var single_charge_rule_area = [{
                            configCode: "single_charge_rule_area",
                            configValue: this.editForm.valueE,
                            parkingId: this.id,
                        }]
                        let param = {
                            enable_nested_parking: enable_nested_parking,
                            audit_separate: audit_separate,
                            trans_area_charge_mode: trans_area_charge_mode,
                            charge_mode: charge_mode,
                            single_charge_rule_area: single_charge_rule_area,
                        }
                        configSave(this.id, param).then((res) => {
                            if (res.data.code == "SUCCESS") {
                                this.$message.success("保存成功！");
                                this.getParkingConfigList();
                            } else {
                                this.$message.info(res.data.message);
                            }
                        })
                    }
                })
            },
        },
    }
</script>

<style scoped>

</style>
