import request from '@/utils/request'
import config from '@/config'

// 列表
export function getRegularCar(data) {
    return request({
        url: `${config.bpi}/regularCar`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}

// 新增
export function regularCarSave(data) {
    return request({
        url: `${config.bpi}/regularCar`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 编辑
export function regularCarEdit(data) {
    return request({
        url: `${config.bpi}/regularCar`,
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

//删除
export function regularCarDel(id) {
    return request({
        url: `${config.bpi}/regularCar`,
        method: 'delete',
        params: {id: id}
    })
}

//下载导入模板
export function regularCarDownTemplate(carType) {
    return request({
        url: `${config.bpi}/regularCar/import/model?carType=` + carType,
        method: 'get',
        responseType: 'blob',
        headers: {'showLoading': false}
    })
}

//导入
export function regularCarImportExcel(data) {
    return request({
        url: `${config.bpi}/regularCar/import`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
}
