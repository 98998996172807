<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>车辆管理</el-breadcrumb-item>
            <el-breadcrumb-item>黑名单列表</el-breadcrumb-item>
        </el-breadcrumb>
        <el-form :inline="true" :model="filters" class="user-search">
            <el-form-item>
                <el-input size="small" v-model="filters.licensePlate" clearable placeholder="请输入车牌号码"
                          @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input size="small" v-model="filters.cardNo" clearable placeholder="请输入卡号"
                          @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-search" type="primary" size="small" @click="handleSearch">查询</el-button>
                <el-button type="primary" size="small" @click="handleAdd">添加</el-button>
                <el-button type="primary" size="small" @click="handleBatchDelete">批量删除</el-button>
            </el-form-item>
        </el-form>
        <!--列表-->
        <el-table
                size="small"
                :data="tableData"
                @selection-change="handleSelectionChange"
                ref="blackListTable"
                highlight-current-row
                border
                v-loading="loading"
                element-loading-text="拼命加载中"
                style="width: 100%"
                :default-sort="{prop: 'passTime', order: 'descending'}"
                :row-style="{height:'45px'}"
                :cell-style="{padding:'0px'}"
                :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                stripe
                height="500"
                max-height="500">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column prop="licensePlate" label="车牌号" align="center"></el-table-column>
            <el-table-column prop="cardNo" label="卡号" align="center"></el-table-column>
            <el-table-column prop="ownerName" label="车主姓名" align="center"></el-table-column>
            <el-table-column prop="ownerPhone" label="车主手机" align="center"></el-table-column>
            <el-table-column prop="ownerIdCard" label="车主身份证号" align="center"></el-table-column>
            <el-table-column prop="createTime" label="添加时间" align="center"></el-table-column>
            <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                    <el-button size="mini" type="danger" class="el-icon-delete" @click="handleDelete(scope.row)">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="filters.current"
                       :page-sizes="[10, 50, 200, 1000]"
                       :page-size="filters.size"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
        <el-dialog v-draggable  title="添加黑名单" :visible.sync="addDialog" width="500px">
            <el-form :model="editForm" ref="editForm" size="mini" :rules="rules">
                <el-row>
                    <el-col :span="20">
                        <el-form-item label="车牌号码" prop="licensePlate" :label-width="formLabelWidth">
                            <el-input size="small" v-model="editForm.licensePlate" placeholder="请输入内容"/>
                        </el-form-item>
                        <el-form-item label="卡号" prop="cardNo" :label-width="formLabelWidth">
                            <el-input size="small" v-model="editForm.cardNo" placeholder="请输入内容"/>
                        </el-form-item>
                        <el-form-item label="车主姓名" prop="ownerName" :label-width="formLabelWidth">
                            <el-input size="small" v-model="editForm.ownerName" placeholder="请输入内容"/>
                        </el-form-item>
                        <el-form-item label="车主手机" prop="ownerPhone" :label-width="formLabelWidth">
                            <el-input size="small" v-model="editForm.ownerPhone" placeholder="请输入内容"/>
                        </el-form-item>
                        <el-form-item label="车主身份证" prop="ownerIdCard" :label-width="formLabelWidth">
                            <el-input size="small" v-model="editForm.ownerIdCard" placeholder="请输入内容"/>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-form-item :label-width="formLabelWidth">
                        <el-button @click="addDialog = false">取 消</el-button>
                        <el-button type="primary" @click="confirm('editForm')">确 定</el-button>
                    </el-form-item>
                </el-row>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    import {blackList, blackListSave, blackListDel} from "../../../api/vehicle/longcar";

    export default {
        name: "blacklistList",
        data() {
            return {
                loading: false,
                tableData: [],
                multipleSelection: [],
                total: 0,
                filters: {
                    current: 1,
                    size: 10,
                    licensePlate: "",
                    cardNo: ""
                },
                addDialog: false,
                formLabelWidth: '140px',
                editForm: {
                    licensePlate: "",
                    ownerIdCard: "",
                    ownerName: "",
                    ownerPhone: "",
                    cardNo: ""
                },
                rules: {
                    licensePlate: [{required: true, message: "请输入", trigger: "blur"}],
                },
            }
        },
        mounted() {
            this.getBlackList();
        },
        methods: {
            handleSearch() {
                this.filters.current = 1;
                this.getBlackList();
            },
            getBlackList() {
                let that = this;
                that.loading = true;
                let params = {
                    current: that.filters.current,
                    size: that.filters.size,
                    licensePlate: that.filters.licensePlate,
                    cardNo: that.filters.cardNo
                };
                blackList(params).then((res) => {
                    if (res.data.code == "SUCCESS") {
                        that.loading = false;
                        that.tableData = res.data.data.records;
                        that.total = res.data.data.total;
                        that.filters.current = res.data.data.current;
                        that.filters.size = res.data.data.size;
                    } else {
                        this.$message.error(res.data.message)
                    }
                }).catch(error => {
                    that.loading = false;
                });
            },
            handleSizeChange(val) {
                this.filters.size = val;
                this.filters.current = 1;
                this.getBlackList();
            },
            handleCurrentChange(val) {
                this.filters.current = val;
                this.getBlackList();
            },
            handleAdd() {
                this.addDialog = true;
                Object.keys(this.editForm).map(key => {
                    this.editForm[key] = '';
                })
            },
            confirm(editForm) {
                this.$refs[editForm].validate((valid) => {
                    if (valid) {
                        let param = {
                            licensePlate: this.editForm.licensePlate,
                            ownerIdCard: this.editForm.ownerIdCard,
                            ownerName: this.editForm.ownerName,
                            ownerPhone: this.editForm.ownerPhone,
                            cardNo: this.editForm.cardNo
                        }
                        blackListSave(param).then((res) => {
                            this.addDialog = false;
                            this.getBlackList();
                            if (res.data.code == "SUCCESS") {
                                this.$message.success(res.data.message);
                            } else {
                                this.$message.error(res.data.message);
                            }
                        })
                    }
                })
            },
            handleSelectionChange(selection) {
                this.multipleSelection = [];
                selection.forEach(element => {
                    this.multipleSelection.push(element.id);
                });
                JSON.parse(JSON.stringify(this.multipleSelection));
            },
            handleBatchDelete() {
                this.$confirm("是否确认批量删除数据?", "温馨提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    blackListDel(this.multipleSelection.toString()).then((res) => {
                        this.$refs.blackListTable.clearSelection();
                        this.getBlackList();
                        if (res.data.code == "SUCCESS") {
                            this.$message({
                                type: "success",
                                message: "删除成功!",
                            });
                        } else {
                            this.$message({
                                type: "info",
                                message: res.data.message,
                            });
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
            },
            handleDelete(row) {
                this.$confirm("是否确认删除数据?", "温馨提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    blackListDel(row.id).then((res) => {
                        if (res.data.code == "SUCCESS") {
                            this.getBlackList();
                            this.$message({
                                type: "success",
                                message: "删除成功!",
                            });
                        } else {
                            this.$message({
                                type: "info",
                                message: res.data.message,
                            });
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
            },

        }
    }
</script>

<style scoped>
    .user-search {
        margin-top: 15px;
    }
</style>
