import request from '@/utils/request'
import Config from '@/config'

// 层级列表
export function parkingAreaRoadList(id) {
    return request({
        url: `${Config.bpi}/parking/area`,
        method: 'get',
        params: {parkingId: id},
        headers: {'showLoading': false}
    })
}

export function parkingEquipment(id) {
    return request({
        url: `${Config.bpi}/parkingAdmin/equipment/snap`,
        method: 'put',
        params: {roadId: id},
    })
}

// 添加区域
export function areaSave(data) {
    return request({
        url: `${Config.bpi}/parking/area`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 编辑区域
export function areaUpdate(data) {
    return request({
        url: `${Config.bpi}/parking/area`,
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 删除区域
export function areaDel(id) {
    return request({
        url: `${Config.bpi}/parking/area`,
        method: 'delete',
        params: {id: id},
    })
}

// 添加道口
export function roadSave(data) {
    return request({
        url: `${Config.bpi}/parking/road`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 编辑道口
export function roadUpdate(data) {
    return request({
        url: `${Config.bpi}/parking/road`,
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 删除道口
export function roadDel(id) {
    return request({
        url: `${Config.bpi}/parking/road`,
        method: 'delete',
        params: {id: id},
    })
}

//车位列表
export function parkingDataList(data) {
    return request({
        url: `${Config.bpi}/parking/place`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}

// 添加车位
export function parkingSave(data) {
    return request({
        url: `${Config.bpi}/parking/place`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 添加批量添加车位
export function parkingSaveBatch(data) {
    return request({
        url: `${Config.bpi}/parking/place/batch`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 删除车位
export function parkingDel(id) {
    return request({
        url: `${Config.bpi}/parking/place`,
        method: 'delete',
        params: {id: id},
    })
}

// 设备列表
export function equipmentList(id) {
    return request({
        url: `${Config.bpi}/equipment/road`,
        method: 'get',
        params: {roadId: id},
        headers: {'showLoading': false}
    })
}

// 设备列表
export function carList(id) {
    return request({
        url: `${Config.bpi}/equipment/notDistribution`,
        method: 'get',
        params: {parkingId: id},
        headers: {'showLoading': false}
    })
}

// 删除道口设备
export function equipmentDel(id) {
    return request({
        url: `${Config.bpi}/equipment/road`,
        method: 'delete',
        params: {equipmentId: id},
    })
}

// 添加道口设备
export function equipmentSave(data) {
    return request({
        url: `${Config.bpi}/equipment/road`,
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 编辑设备
export function equipmentUpdate(data) {
    return request({
        url: `${Config.bpi}/equipment/project`,
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 添加道口屏显
export function displaySave(data) {
    return request({
        url: `${Config.bpi}/display`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 添加道口屏显音量
export function volume(data) {
    return request({
        url: `${Config.bpi}/parkingAdmin/equipment/volume`,
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

//远端设置
export function settingRemote(sn, model) {
    return request({
        url: `${Config.bpi}/equipment/setting/remote?sn=` + sn + `&model=` + model,
        method: 'get',
        headers: {'showLoading': false}
    })
}

// 添加参数
export function configSave(id, data) {
    return request({
        url: `${Config.bpi}/parking/config?parkingId=` + id,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 参数列表
export function parkingConfigList(id) {
    return request({
        url: `${Config.bpi}/parking/config`,
        method: 'get',
        params: {parkingId: id},
        headers: {'showLoading': false}
    })
}

// 长租车列表
export function longCarList(data) {
    return request({
        url: `${Config.bpi}/rate/long/page`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}

// 编辑长租车
export function longCarEdit(data) {
    return request({
        url: `${Config.bpi}/rate/long`,
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 添加长租车
export function longCarSave(data) {
    return request({
        url: `${Config.bpi}/rate/long`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 删除长租车
export function longCarDel(id) {
    return request({
        url: `${Config.bpi}/rate/long`,
        method: 'delete',
        params: {id: id},
    })
}

// 临停车列表
export function tempCarList(data) {
    return request({
        url: `${Config.bpi}/rate/temp`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}

// 临停车列表
export function tempCarAllList(id) {
    return request({
        url: `${Config.bpi}/rate/temp/list`,
        method: 'get',
        params: {parkingId: id},
        headers: {'showLoading': false}
    })
}

// 添加临停车
export function tempCarSave(data) {
    return request({
        url: `${Config.bpi}/rate/temp`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 修改默认设置
export function tempCarEdit(id) {
    return request({
        url: `${Config.bpi}/rate/temp/default`,
        method: 'put',
        params: {id: id},
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 添加状态
export function disableEdit(data) {
    return request({
        url: `${Config.bpi}/rate/temp/disable`,
        method: 'put',
        params: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 删除临停车
export function tempCarDel(id) {
    return request({
        url: `${Config.bpi}/rate/temp`,
        method: 'delete',
        params: {id: id},
    })
}

//测试价格
export function tempCost(data) {
    return request({
        url: `${Config.bpi}/rate/temp/cost`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

//LCD列表
export function equipmentLCD(id) {
    return request({
        url: `${Config.bpi}/equipment/lcd`,
        method: 'get',
        params: {parkingId: id},
        headers: {'showLoading': false}
    })
}

//LED主板类型
export function ledCardType() {
    return request({
        url: `${Config.bpi}/display/ledCardType`,
        method: 'get',
        headers: {'showLoading': false}
    })
}

//下载二维码
export function parkingDown(parkingId) {
    return request({
        url: `${Config.bpi}/parking/road/down`,
        method: 'get',
        params: {parkingId: parkingId},
        headers: {'showLoading': false}
    })
}
