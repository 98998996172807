<template>
    <div class="eldiv">
        <iframe src="http://fcwl.rlinking.com" loading="lazy" id="mobsf" scrolling="no"
                frameborder="0" class="iframe"></iframe>
    </div>
</template>

<script>
    export default {
        name: "test",
        data() {
            return {
                fullscreenLoading: false
            }
        },
        mounted() {
            /**
             * iframe-宽高自适应显示
             //  */
            // this.fullscreenLoading = true;
            // setTimeout(() => {
            //     this.fullscreenLoading = false;
            // }, 2000);
            this.openFullScreen();

            function changeMobsfIframe() {
                const mobsf = document.getElementById('mobsf');
                const clientHeight = document.documentElement.clientHeight - 95;
                mobsf.style.height = `${clientHeight}px`
            }

            changeMobsfIframe()

            window.onresize = function () {
                changeMobsfIframe()
            }
        },
        methods: {
            openFullScreen() {
                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0,0,0,0.7)'
                });
                setTimeout(() => {
                    loading.close();
                }, 2000);
            }
        }
    }
</script>

<style scoped>
    .eldiv {
        margin: -20px;
    }

    .iframe {
        width: 100%;
        height: 100%;
        border: 0;
        overflow: hidden;
        box-sizing: border-box;
    }
</style>
