import request from '@/utils/request'
import config from '@/config'

// 商家列表
export function businessList(data) {
    return request({
        url: `${config.bpi}/business`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}
// 添加商家
export function businessSave(data) {
    return request({
        url: `${config.bpi}/business`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}
// 编辑商家
export function businessEdit(data) {
    return request({
        url: `${config.bpi}/business`,
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 删除商家
export function businessDel(id) {
    return request({
        url: `${config.bpi}/business`,
        method: 'delete',
        params: {id:id}
    })
}

// 重置密码
export function businessReset(id) {
    return request({
        url: `${config.bpi}/business/password`,
        method: 'put',
        params: {id:id}
    })
}

// 文件上传
export function uploadFile(data) {
    return request({
        url: `${config.bpi}/uploadFile`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
}
