<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>雷达测速</el-breadcrumb-item>
            <el-breadcrumb-item>测速点管理</el-breadcrumb-item>
            <el-breadcrumb-item>设置</el-breadcrumb-item>
        </el-breadcrumb>
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="基础设置" name="1">
                <el-row>
                    <el-col :span="14">
                        <el-form label-position="left" ref="editForm" :model="editForm" size="small">
                            <el-form-item label="低于">
                                <div style="display: flex">
                                    <el-input size="small" oninput="value=value.replace(/[^\d]/g,'')"
                                              v-model="editForm.value1" placeholder="请输入"/>
                                    <span style="margin-left: 5px;width: 80px;">km/h为低速</span>
                                </div>
                            </el-form-item>
                            <el-form-item label="超过">
                                <div style="display: flex">
                                    <el-input size="small" oninput="value=value.replace(/[^\d]/g,'')"
                                              v-model="editForm.value2" placeholder="请输入"/>
                                    <span style="margin-left: 5px;width: 80px;">km/h为超速</span>
                                </div>
                            </el-form-item>
                            <!--<el-form-item label="雷达默认速度">
                                <div style="display: flex">
                                    <el-input size="small" oninput="value=value.replace(/[^\d]/g,'')"
                                              v-model="editForm.value1" placeholder="请输入"/>
                                    <span style="margin-left: 5px;width: 40px;">km/h</span>
                                </div>
                            </el-form-item>
                            <el-form-item label="图片保存时间">
                                <el-input size="small" oninput="value=value.replace(/[^\d]/g,'')"
                                          v-model="editForm.value1" placeholder="请输入"/>
                            </el-form-item>
                            <el-form-item label="启动抓拍条件">
                                <el-checkbox-group v-model="editForm.value1">
                                    <el-checkbox label="超速" name="type"></el-checkbox>
                                    <el-checkbox label="低速" name="type"></el-checkbox>
                                    <el-checkbox label="正常" name="type"></el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>-->
                            <el-form-item label-width="120px">
                                <el-button type="primary" @click="onSubmit1()">保 存</el-button>
                                <el-button type="primary" @click="handleReturn">返 回</el-button>
                            </el-form-item>
                        </el-form>
                    </el-col>
                </el-row>
            </el-tab-pane>
            <el-tab-pane label="相机" name="2">
                <el-row>
                    <el-col :span="12">
                        <el-form ref="saveForm" :model="saveForm" size="small">
                            <el-form-item label="相机名称">
                                <el-select @change="hanldeChange" @visible-change="hanldeVisibleChange" size="small"
                                           v-model="saveForm.id"
                                           clearable placeholder="请选择">
                                    <el-option v-for="item in deviceList" :key="item.id" :label="item.deviceName"
                                               :value="item.id"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="相机编号">
                                <el-input size="small" v-model="saveForm.deviceNo" readonly/>
                            </el-form-item>
                            <el-form-item label="相机状态">
                                <el-input size="small" v-model="saveForm.status" readonly/>
                            </el-form-item>
                            <el-form-item label-width="120px">
                                <el-button type="primary" @click="onSubmit2()">保 存</el-button>
                                <el-button type="primary" @click="handleReturn">返 回</el-button>
                            </el-form-item>
                        </el-form>
                    </el-col>
                </el-row>
            </el-tab-pane>
            <el-tab-pane label="测速板" name="3">
                <el-form ref="editForm" :model="editForm" label-width="120px" size="small">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="显示刷屏时间">
                                <el-select size="small" v-model="editForm.value3" clearable placeholder="请选择时间">
                                    <el-option label="500ms" value="0"></el-option>
                                    <el-option label="1000ms" value="1"></el-option>
                                    <el-option label="1500ms" value="2"></el-option>
                                    <el-option label="2000ms" value="3"></el-option>
                                    <el-option label="2500ms" value="4"></el-option>
                                    <el-option label="3000ms" value="5"></el-option>
                                    <el-option label="3500ms" value="6"></el-option>
                                    <el-option label="4000ms" value="7"></el-option>
                                    <el-option label="4500ms" value="8"></el-option>
                                    <el-option label="5000ms" value="9"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="音量">
                                <el-input-number style="width: 50%" size="small" v-model="editForm.value4"
                                                 :max="30"></el-input-number>
                            </el-form-item>
                            <el-form-item label="波特率">
                                <el-select disabled size="small" v-model="editForm.value5" clearable
                                           placeholder="请选择波特率">
                                    <el-option label="9600" value="9600"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="点阵显示内容">
                                <el-select size="small" v-model="editForm.value6" clearable placeholder="请选择显示内容">
                                    <el-option label="下雨路滑请小心驾驶" value="0"></el-option>
                                    <el-option label="注意行人 请降低车速！" value="1"></el-option>
                                    <el-option label="下雪路滑请小心驾驶！" value="2"></el-option>
                                    <el-option label="阴雨天请开车灯！" value="3"></el-option>
                                    <el-option label="限速路段请按规定车速行驶！" value="4"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="协议">
                                <el-select size="small" v-model="editForm.value7" clearable placeholder="请选择协议">
                                    <el-option label="拓宝协议" value="0"></el-option>
                                    <el-option label="HR协议" value="1"></el-option>
                                    <el-option label="TD协议" value="2"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="14">
                            <el-form-item label="不显示车速">
                                <div style="display: flex">
                                    <el-input size="small" oninput="value=value.replace(/[^\d]/g,'')"
                                              v-model="editForm.value8" placeholder="请输入"/>
                                    <span style="margin-left: 5px;width: 40px;">km/h</span>
                                </div>
                            </el-form-item>
                            <el-form-item label="显绿色车速">
                                <div style="display: flex">
                                    <el-input size="small" oninput="value=value.replace(/[^\d]/g,'')"
                                              v-model="editForm.value9" placeholder="请输入"/>
                                    <span style="margin-left: 5px;width: 40px;">km/h</span>
                                </div>
                            </el-form-item>
                            <el-form-item label="显绿闪车速">
                                <div style="display: flex">
                                    <el-input size="small" oninput="value=value.replace(/[^\d]/g,'')"
                                              v-model="editForm.value10" placeholder="请输入"/>
                                    <span style="margin-left: 5px;width: 40px;">km/h</span>
                                </div>
                            </el-form-item>
                            <el-form-item label="显红色车速">
                                <div style="display: flex">
                                    <el-input size="small" oninput="value=value.replace(/[^\d]/g,'')"
                                              v-model="editForm.value11" placeholder="请输入"/>
                                    <span style="margin-left: 5px;width: 40px;">km/h</span>
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-form-item>
                        <el-button type="primary" @click="handleRealtimeConfig()">读取实时设置</el-button>
                        <el-button type="primary" @click="handleTimeSync">同步时间</el-button>
                        <el-button type="primary" @click="onSubmit1('editForm')">保 存</el-button>
                        <el-button @click="onSubmit1('editForm')">返 回</el-button>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
            <!--<el-tab-pane label="雷达" name="4">
                <el-form ref="editForm" :model="editForm" label-width="120px" size="small">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="输出目标">
                                <el-select size="small" v-model="editForm.value1" clearable placeholder="请选择输出目标">
                                    <el-option label="最近车辆" value="1"></el-option>
                                    <el-option label="最快车辆" value="2"></el-option>
                                    <el-option label="最慢车辆" value="3"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="延时清零时间">
                                <el-select size="small" v-model="editForm.value1" clearable placeholder="请选择时间">
                                    <el-option label="400ms" value="1"></el-option>
                                    <el-option label="800ms" value="2"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="灵敏度">
                                <el-select size="small" v-model="editForm.value1" clearable placeholder="请选择音量">
                                    <el-option label="1" value="1"></el-option>
                                    <el-option label="2" value="2"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="雷达上线">
                                <div style="display: flex">
                                    <el-input size="small" oninput="value=value.replace(/[^\d]/g,'')"
                                              v-model="editForm.value1" placeholder="请输入"/>
                                    <span style="margin-left: 5px;width: 40px;">km/h</span>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="波特率">
                                <el-select size="small" v-model="editForm.value1" clearable placeholder="请选择波特率">
                                    <el-option label="4800" value="1"></el-option>
                                    <el-option label="9600" value="2"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="刷新时间">
                                <el-select size="small" v-model="editForm.value1" clearable placeholder="请选择显示内容">
                                    <el-option label="400ms" value="1"></el-option>
                                    <el-option label="800ms" value="2"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="检测方向">
                                <el-select size="small" v-model="editForm.value1" clearable placeholder="请选择协议">
                                    <el-option label="靠近方向" value="1"></el-option>
                                    <el-option label="远离方向" value="2"></el-option>
                                    <el-option label="双向方向" value="3"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="雷达下限">
                                <div style="display: flex">
                                    <el-input size="small" oninput="value=value.replace(/[^\d]/g,'')"
                                              v-model="editForm.value1" placeholder="请输入"/>
                                    <span style="margin-left: 5px;width: 40px;">km/h</span>
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-form-item label-width="50px">
                        <el-button type="primary" @click="onSubmit1('editForm')">读取实时设置</el-button>
                        <el-button type="primary" @click="onSubmit1('editForm')">恢复出厂设置</el-button>
                        <el-button type="primary" @click="onSubmit1('editForm')">保 存</el-button>
                        <el-button type="primary" @click="handleReturn">返 回</el-button>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
            <el-tab-pane label="大屏" name="5">
                <div style="font-size: 32px; padding: 30px 15px;">精彩功能，敬请期待</div>
                <el-button size="small" type="primary" style="margin-left: 130px" @click="handleReturn">返 回</el-button>
            </el-tab-pane>-->
        </el-tabs>
    </div>
</template>

<script>
    import {
        addSpeedConfig,
        editSpeedPDevice,
        getSpeedConfig,
        getSpeedDevice,
        realtimeConfig,
        timeSync
    } from "../../api/speed";

    export default {
        name: "radarSettings",
        data() {
            return {
                activeName: '1',
                editForm: {
                    value1: "",
                    value2: "",
                    value3: "",
                    value4: "",
                    value5: "9600",
                    value6: "",
                    value7: "",
                    value8: "",
                    value9: "",
                    value10: "",
                    value11: "",
                },
                saveForm: {
                    id: "",
                    deviceNo: "",
                    status: "",
                },
                deviceList: [],
            }
        },
        mounted() {
            this.getSpeedConfigList();
        },
        methods: {
            handleClick(e) {
                if (e.name != "2") {
                    this.getSpeedConfigList();
                } else {
                    this.getSpeedDeviceList();
                }

            },
            getSpeedConfigList() {
                getSpeedConfig({speedPositionId: this.$route.query.id}).then(res => {
                    if (res.data.code == "SUCCESS") {
                        this.editForm.value1 = res.data.data.slow_speed_threhold ? res.data.data.slow_speed_threhold[0].configValue : '';
                        this.editForm.value2 = res.data.data.fast_speed_threhold ? res.data.data.fast_speed_threhold[0].configValue : '';
                        this.editForm.value3 = res.data.data.display_refresh_interval ? res.data.data.display_refresh_interval[0].configValue : '';
                        this.editForm.value4 = res.data.data.volume_level ? res.data.data.volume_level[0].configValue : '';
                        this.editForm.value5 = res.data.data.panel_band_rate ? res.data.data.panel_band_rate[0].configValue : '';
                        this.editForm.value6 = res.data.data.lattice_content ? res.data.data.lattice_content[0].configValue : '';
                        this.editForm.value7 = res.data.data.panel_protocol ? res.data.data.panel_protocol[0].configValue : '';
                        this.editForm.value8 = res.data.data.threhold_speed_no_display ? res.data.data.threhold_speed_no_display[0].configValue : '';
                        this.editForm.value9 = res.data.data.threhold_speed_green ? res.data.data.threhold_speed_green[0].configValue : '';
                        this.editForm.value10 = res.data.data.threhold_speed_green_bling ? res.data.data.threhold_speed_green_bling[0].configValue : '';
                        this.editForm.value11 = res.data.data.threhold_speed_red ? res.data.data.threhold_speed_red[0].configValue : '';
                    }
                })
            },
            onSubmit1() {
                let slow_speed_threhold = [{
                    configCode: "slow_speed_threhold",
                    configValue: this.editForm.value1,
                    speedPositionId: this.$route.query.id,
                }];
                let fast_speed_threhold = [{
                    configCode: "fast_speed_threhold",
                    configValue: this.editForm.value2,
                    speedPositionId: this.$route.query.id,
                }];
                let display_refresh_interval = [{
                    configCode: "display_refresh_interval",
                    configValue: this.editForm.value3,
                    speedPositionId: this.$route.query.id,
                }];
                let volume_level = [{
                    configCode: "volume_level",
                    configValue: this.editForm.value4,
                    speedPositionId: this.$route.query.id,
                }];
                let panel_band_rate = [{
                    configCode: "panel_band_rate",
                    configValue: this.editForm.value5,
                    speedPositionId: this.$route.query.id,
                }];
                let lattice_content = [{
                    configCode: "lattice_content",
                    configValue: this.editForm.value6,
                    speedPositionId: this.$route.query.id,
                }];
                let panel_protocol = [{
                    configCode: "panel_protocol",
                    configValue: this.editForm.value7,
                    speedPositionId: this.$route.query.id,
                }];
                let threhold_speed_no_display = [{
                    configCode: "threhold_speed_no_display",
                    configValue: this.editForm.value8,
                    speedPositionId: this.$route.query.id,
                }];
                let threhold_speed_green = [{
                    configCode: "threhold_speed_green",
                    configValue: this.editForm.value9,
                    speedPositionId: this.$route.query.id,
                }];
                let threhold_speed_green_bling = [{
                    configCode: "threhold_speed_green_bling",
                    configValue: this.editForm.value10,
                    speedPositionId: this.$route.query.id,
                }];
                let threhold_speed_red = [{
                    configCode: "threhold_speed_red",
                    configValue: this.editForm.value11,
                    speedPositionId: this.$route.query.id,
                }];
                let param = {
                    slow_speed_threhold: slow_speed_threhold,
                    fast_speed_threhold: fast_speed_threhold,
                    display_refresh_interval: display_refresh_interval,
                    volume_level: volume_level,
                    panel_band_rate: panel_band_rate,
                    lattice_content: lattice_content,
                    panel_protocol: panel_protocol,
                    threhold_speed_no_display: threhold_speed_no_display,
                    threhold_speed_green: threhold_speed_green,
                    threhold_speed_green_bling: threhold_speed_green_bling,
                    threhold_speed_red: threhold_speed_red,
                }
                addSpeedConfig(param, this.$route.query.id).then(res => {
                    if (res.data.code == "SUCCESS") {
                        this.$message.success("保存成功！");
                        this.getSpeedConfigList();
                    } else {
                        this.$message.info(res.data.message);
                    }
                })
            },
            // 读取实时设置
            handleRealtimeConfig() {
                realtimeConfig({speedPositionId: this.$route.query.id}).then(res => {
                    if (res.data.code == "SUCCESS") {
                        this.$message.success(res.data.message)
                    }
                })
            },
            // 同步时间
            handleTimeSync() {
                timeSync({speedPositionId: this.$route.query.id}).then(res => {
                    if (res.data.code == "SUCCESS") {
                        this.$message.success(res.data.message)
                    }
                })
            },
            getSpeedDeviceList() {
                getSpeedDevice({speedPosId: this.$route.query.id}).then(res => {
                    if (res.data.code == "SUCCESS") {
                        this.deviceList = res.data.data;
                        res.data.data.forEach(item => {
                            if (this.$route.query.id == item.speedPositionId) {
                                this.saveForm.id = item.id;
                                this.saveForm.deviceNo = item.deviceNo;
                                this.saveForm.status = item.status === 1 ? '在线' : item.status === 2 ? '离线' : '';
                            }
                        })
                    }
                })
            },
            hanldeVisibleChange() {
                getSpeedDevice().then(res => {
                    if (res.data.code == "SUCCESS") {
                        this.deviceList = res.data.data;
                    }
                })
            },
            hanldeChange(e) {
                this.deviceList.forEach(item => {
                    if (e === item.id) {
                        this.saveForm = item;
                        this.saveForm.status = item.status === 1 ? '在线' : item.status === 2 ? '离线' : '';
                    }
                })
            },
            onSubmit2() {
                let param = {
                    id: this.saveForm.id,
                    speedPositionId: this.$route.query.id
                }
                editSpeedPDevice(param).then(res => {
                    if (res.data.code == "SUCCESS") {
                        this.getSpeedDeviceList();
                        this.$message.success(res.data.message);
                    } else {
                        this.$message.info(res.data.message);
                    }
                })
            },
            handleReturn() {
                this.$router.go(-1);
            },
        }
    }
</script>

<style scoped>
    .el-tabs {
        margin: 15px 0;
    }

    .el-select {
        width: 50%;
    }

    .el-input {
        width: 50%;
    }
</style>
