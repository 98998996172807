<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>系统管理</el-breadcrumb-item>
            <el-breadcrumb-item>路由设备</el-breadcrumb-item>
        </el-breadcrumb>
        <el-row style="margin: 20px 0;line-height: 30px;">
            <el-col :span="2">
                <el-button icon="el-icon-search" type="primary" size="small" @click="handleSearch">刷新</el-button>
            </el-col>
            <el-col :span="1">
                <el-button icon="el-icon-plus" type="primary" size="small" @click="handleAdd">添加</el-button>
            </el-col>
        </el-row>
        <!--列表-->
        <el-table
                size="small"
                :data="tableData"
                highlight-current-row
                v-loading="loading"
                element-loading-text="拼命加载中"
                border style="width: 100%"
                :row-style="{height:'45px'}"
                :cell-style="{padding:'0px'}"
                :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                stripe height="500" max-height="500">
            <el-table-column min-width="30" type="index" label="序号"></el-table-column>
            <el-table-column prop="name" label="路由名称" align="center"></el-table-column>
            <el-table-column prop="sn" label="路由序列号" align="center"></el-table-column>
            <el-table-column prop="status" label="状态" align="center">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.status == 1">在线</el-tag>
                    <el-tag type="info" v-else-if="scope.row.status == 2">离线</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="cardNo" label="卡号" align="center"></el-table-column>
            <el-table-column width="260" align="center" label="操作">
                <template slot-scope="scope">
                    <el-button v-if="scope.row.cardNo" size="mini" type="success" class="el-icon-bank-card"
                               @click="handleRecharge(scope.$index, scope.row)"
                    >充值
                    </el-button>
                    <el-button type="primary" size="mini" icon="el-icon-edit-outline"
                               @click="handleEdit(scope.$index, scope.row)"
                    >编辑
                    </el-button>
                    <el-button size="mini" type="danger" class="el-icon-delete"
                               @click="handleDelete(scope.$index, scope.row)"
                    >删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="filters.current"
                       :page-sizes="[10, 50, 200, 1000]"
                       :page-size="filters.size"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
        <el-dialog v-draggable  title="路由设备" :visible.sync="addDialog" width="30%">
            <el-form :model="editForm" ref="editForm" size="small" :rules="rules">
                <el-row>
                    <el-col :span="20">
                        <el-form-item label="路由名称" prop="name" :label-width="formLabelWidth">
                            <el-input size="small" v-model="editForm.name" placeholder="请输入路由名称"/>
                        </el-form-item>
                        <el-form-item label="路由序列号" prop="sn" :label-width="formLabelWidth">
                            <el-input size="small" v-model="editForm.sn" placeholder="请输入路由序列号"/>
                        </el-form-item>
                        <el-form-item label="卡号" prop="cardNo" :label-width="formLabelWidth">
                            <el-input size="small" v-model="editForm.cardNo" placeholder="请输入卡号"/>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addDialog = false">取 消</el-button>
                <el-button type="primary" @click="onSubmit('editForm')">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 生成二维码的弹窗 -->
        <el-dialog v-draggable  title="充值二维码" :visible.sync="QRcodeDialogVisible" width="30%">
            <div style="display: flex;justify-content: center;">
                <div class="qrcode" id="qrcode"></div>
            </div>
        </el-dialog>
    </div>
</template>

<script>

    import {routerDel, routerEdit, routerList, routerSave} from "../../../api/equipmentApi";
    import QRCode from 'qrcodejs2'

    export default {
        name: "routingEquipment",
        data() {
            return {
                loading: false,
                total: 0,
                tableData: [{name: 'xxx'}],
                filters: {
                    current: 1,
                    size: 10,
                },
                editForm: {
                    id: "",
                    name: "",
                    sn: "",
                    cardNo: "",
                    projectId: "",
                    projectNo: "",
                },
                rules: {
                    name: [{required: true, message: "请输入", trigger: "blur"}],
                    sn: [{required: true, message: "请输入", trigger: "blur"}],
                },
                formLabelWidth: '130px',
                projectData: [],
                addDialog: false,
                QRcodeDialogVisible: false,
            }
        },
        mounted() {
            this.fetchData();
        },
        methods: {
            fetchData() {
                this.loading = true;
                routerList(this.filters).then((res) => {
                    this.loading = false;
                    if (res.data.code == "SUCCESS") {
                        this.tableData = res.data.data.records;
                        this.total = res.data.data.total;
                        this.filters.current = res.data.data.current;
                        this.filters.size = res.data.data.size;
                    } else {
                        this.$message.info(res.data.message);
                    }
                }).catch(() => {
                    this.loading = false;
                });
            },
            handleSearch() {
                this.filters.current = 1;
                this.fetchData();
            },
            handleSizeChange(val) {
                this.filters.size = val;
                this.filters.current = 1;
                this.fetchData();
            },
            handleCurrentChange(val) {
                this.filters.current = val;
                this.fetchData();
            },
            handleAdd() {
                this.addDialog = true;
                Object.keys(this.editForm).map(key => {
                    this.editForm[key] = '';
                })
            },
            handleEdit(index, row) {
                this.addDialog = true;
                Object.keys(this.editForm).map(key => {
                    this.editForm[key] = row[key];
                });
            },
            //提交表单
            onSubmit(editForm) {
                this.$refs[editForm].validate((valid) => {
                    if (valid) {
                        let user = window.localStorage.getItem('userdata');
                        if (user) {
                            user = JSON.parse(user);
                            console.log(user)
                            this.editForm.projectId = user.projectId;
                            this.editForm.projectNo = user.projectNo;
                        }
                        if (this.editForm.id) {
                            routerEdit(this.editForm).then(res => {
                                this.addDialog = false;
                                this.fetchData();
                                if (res.data.code == "SUCCESS") {
                                    this.$message.success("编辑成功！");
                                } else {
                                    this.$message.info(res.data.message);
                                }
                            })
                        } else {
                            routerSave(this.editForm).then(res => {
                                this.addDialog = false;
                                this.fetchData();
                                if (res.data.code == "SUCCESS") {
                                    this.$message.success("保存成功！");
                                } else {
                                    this.$message.info(res.data.message);
                                }
                            })
                        }

                    }
                })
            },
            //二维码弹出框
            handleRecharge(index, row) {
                this.QRcodeDialogVisible = true
                this.$nextTick(() => {
                    let qrcodeEL = document.getElementById("qrcode");
                    let qrcode = new QRCode(qrcodeEL);
                    document.getElementById("qrcode").innerHTML = "";
                    this.GetQRcode(row.cardNo)
                })
            },
            //  生成二维码
            GetQRcode(cardNo) {
                var qrcode = new QRCode('qrcode', {
                    text: "http://iot.juzi1688.cn/dist/#/info?iccid=" + cardNo,       //  需要生成二维码的内容
                    width: 256,
                    height: 256,
                    colorDark: '#000000',
                    colorLight: '#ffffff',
                    correctLevel: QRCode.CorrectLevel.H
                });
            },
            //删除
            handleDelete(index, row) {
                this.$confirm("确定要删除吗?", "温馨提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    routerDel(row.id).then((res) => {
                        if (res.data.code == "SUCCESS") {
                            this.filters.current = 1;
                            this.fetchData();
                            this.$message.success("删除成功!");
                        } else {
                            this.$message.info(res.data.message);
                        }
                    })
                }).catch(() => {
                    this.$message.info("已取消删除!");
                });
            }
        }
    }
</script>

<style scoped>
</style>
