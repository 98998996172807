<template>
    <div class="top-middle-cmp">
        <div class="chart-name">
            泊位使用率时间分布
            <dv-decoration-1 style="width:210px;height:30px;"/>
        </div>
        <!--    <dv-charts :option="option" style="width: 8rem;height: 5.1rem;margin: 0 auto"/>-->
        <div ref="myEchart1" style="width: 600px;height: 400px;margin: 0 auto"></div>
    </div>
</template>

<script>
    // import echartMixins from "@/utils/resizeMixins";
    import * as echarts from "echarts";
    import {parkingDistribution, parkingOperate} from "../../../api/bigscreen";

    export default {
        props: ['parkingDistribution'],
        data() {
            return {
                timing: null,
                chart: null
            };
        },
        // mixins: [echartMixins],
        mounted() {
            // this.timing = setInterval(() => {
            //     this.$nextTick(() => {
            //         this.draw();
            //     });
            // }, 1000 * 5)
        },
        // destroyed () {
        //     clearInterval(this.timing)
        // },
        watch:{
            parkingDistribution:{
                handler(newValue, oldValue) {
                    let dataList = newValue;
                    let xAxisData = [];
                    let seriesData = [];
                    let total = sessionStorage.getItem('total')
                    if (dataList) {
                        dataList.forEach(e => {
                            xAxisData.push(e.time);
                            let num = (e.value / total).toFixed(2);
                            seriesData.push((num * 100).toFixed(2));
                            // seriesData.push(e.value);
                        });
                    }
                    this.chart = echarts.init(this.$refs.myEchart1);
                    let option = {
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'cross',
                                crossStyle: {
                                    color: '#d3d3d3'
                                },
                            },
                            formatter: '{b0}<br/>{a0}: {c0}%<br />',
                        },
                        grid: {
                            left: '25',
                            right: '25',
                            bottom: '24',
                            top: '75',
                            containLabel: true
                        },
                        // legend: {
                        // data: ['泊位使用率'],
                        // orient: 'horizontal',
                        // icon: "rect",
                        // show: true,
                        // left: 20,
                        // top: 25,
                        // textStyle: {
                        //     color: '#d3d3d3'
                        // }
                        // },
                        xAxis: {
                            type: 'category',
                            data: xAxisData,
                            axisLabel: {
                                color: '#d3d3d3',
                                textStyle: {
                                    fontSize: 12
                                },
                            },
                            splitLine: {
                                show: false
                            },
                            axisTick: {
                                show: false
                            },
                            axisLine: {
                                show: false
                            },
                        },
                        yAxis: {
                            type: 'value',
                            max: 100,
                            min: 0,
                            axisLabel: {
                                color: '#d3d3d3',
                                textStyle: {
                                    fontSize: 12
                                },
                                formatter: '{value}%',
                            },
                            splitLine: {
                                show: true,
                                lineStyle: {
                                    color: '#F3F4F4'
                                }
                            },
                            axisTick: {
                                show: false
                            },
                            axisLine: {
                                show: false
                            },
                        },
                        series: [
                            {
                                name: '泊位使用率',
                                type: 'line',
                                smooth: true,
                                data: seriesData,
                                itemStyle: {
                                    color: '#61a0a8',
                                    // normal: {
                                    //     label: {
                                    //         show: true,
                                    //         formatter: '{c}%'
                                    //     }
                                    // }
                                },
                            }
                        ]
                    }
                    this.chart.setOption(option);
                },
                deep: true
            }
        },
        methods: {
            draw() {
                // 基于准备好的dom，初始化echarts实例
                parkingOperate().then((res) => {

                })
            }
        },
    };
</script>

<style lang="scss" scoped>
    .top-middle-cmp {
        position: relative;
        //padding: 0 25px;
        box-sizing: border-box;
        top: 25px;

        .chart-name {
            position: absolute;
            left: 40px;
            text-align: right;
            font-size: 24px;
            font-weight: bold;
            color: #5589e9;
        }
    }
</style>
