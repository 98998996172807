<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>系统管理</el-breadcrumb-item>
            <el-breadcrumb-item>工具文件下载</el-breadcrumb-item>
        </el-breadcrumb>
        <el-table
                size="small"
                :data="listData"
                highlight-current-row
                v-loading="loading"
                element-loading-text="拼命加载中"
                border style="width: 100%"
                :row-style="{height:'45px'}"
                :cell-style="{padding:'0px'}"
                :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                stripe height="600" max-height="600">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column prop="name" label="文件名" align="center"></el-table-column>
            <el-table-column prop="content" label="说明" align="center"></el-table-column>
            <el-table-column prop="size" label="大小" align="center"></el-table-column>
            <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                    <el-button size="mini" @click="handleDownload(scope.row)"
                               type="primary">下载
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
    export default {
        name: "toolDownload",
        data() {
            return {
                loading: false,
                //用户数据
                listData: [{
                    name: "H本地人脸机工具软件",
                    url: "https://pan.baidu.com/s/1asDZisjGiuoGP47dOF9oTQ?pwd=b1w7",
                    content: "本地调整TH款人脸机所有参数",
                    size: "196M"
                }, {
                    name: "Q款相机工具软件",
                    url: "https://pan.baidu.com/s/1Z-DsE5BNTwzrLmPfl49ySQ?pwd=tbca",
                    content: "车牌识别相机脱机、线圈、识别等参数调整",
                    size: "2M"
                }, {
                    name: "黑体测温调试工具",
                    url: "https://pan.baidu.com/s/1J3U5OEEONyIgOFk-aTaQjw?pwd=r3h0",
                    content: "TJ款黑体设备参数、IP等设置",
                    size: "4M"
                }],
            }
        },
        methods: {
            handleDownload(row) {
                window.open(row.url)
            },
        }
    }
</script>

<style scoped>
    .el-table {
        margin-top: 15px;
    }
</style>
