<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>人员管理</el-breadcrumb-item>
            <el-breadcrumb-item>内部人员申请审核</el-breadcrumb-item>
        </el-breadcrumb>
        <el-form :inline="true" :model="filters" class="user-search">
            <el-form-item style="width: 11%;">
                <el-input v-model="filters.name" size="small" clearable placeholder="姓名"
                          @keyup.enter.native="handleSearch"/>
            </el-form-item>
            <el-form-item style="width: 11%;">
                <el-input v-model="filters.userNo" size="small" clearable placeholder="人员编号"
                          @keyup.enter.native="handleSearch"/>
            </el-form-item>
            <el-form-item style="width: 12%;">
                <el-input v-model="filters.phone" size="small" clearable placeholder="手机号码"
                          @keyup.enter.native="handleSearch"/>
            </el-form-item>
            <el-form-item style="width: 12%;">
                <el-input v-model="filters.idCardNo" size="small" clearable placeholder="身份证号码"
                          @keyup.enter.native="handleSearch"/>
            </el-form-item>
            <el-form-item style="width: 12%;">
                <el-select size="small" v-model="filters.gender" clearable filterable placeholder="性别">
                    <el-option label="男" value="1"></el-option>
                    <el-option label="女" value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item style="width: 13%;">
                <el-select size="small" v-model="filters.status" clearable filterable placeholder="申请状态">
                    <el-option label="待审核" value="1"></el-option>
                    <el-option label="已通过" value="2"></el-option>
                    <el-option label="已驳回" value="3"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-search" type="primary" size="small" @click="handleSearch">查询</el-button>
                <el-button type="primary" size="small" @click="handleCode">申请二维码</el-button>
                <el-button type="primary" size="small" @click="handleBatch">批量审核</el-button>
                <el-button type="primary" size="small" @click="handlePhone">管理员手机号</el-button>
            </el-form-item>
        </el-form>
        <el-table
                ref="listTable"
                @selection-change="handleSelectionChange"
                v-loading="loading"
                element-loading-text="拼命加载中"
                size="small"
                :data="tableData"
                highlight-current-row
                border stripe
                style="width: 100%;"
                :row-style="{height:'45px'}"
                :cell-style="{padding:'0px'}"
                :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                height="500" max-height="500">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column prop="name" label="姓名" align="center">
                <template slot-scope="scope">
                    <span :style="scope.row.is3rd ? 'color:#409EFF' : '' ">{{scope.row.name}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="userNo" label="人员编号" align="center"></el-table-column>
            <el-table-column prop="phone" label="手机号码" align="center"></el-table-column>
            <el-table-column prop="idCardNo" label="身份证" align="center"></el-table-column>
            <el-table-column prop="sex" label="性别" align="center">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.sex != null"
                            :type="scope.row.sex == 1 ? '' : 'warning'">
                        {{scope.row.sex == 1 ? '男' : '女'}}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="visitorAvatar" label="头像" align="center">
                <template slot-scope="scope">
                    <el-image
                            style="width: 50px; height: 50px"
                            :src="scope.row.avatar?scope.row.avatar:require('../../../assets/images/defaultImg.png')"
                            :preview-src-list="[scope.row.avatar?scope.row.avatar:require('../../../assets/images/defaultImg.png')]">
                        <div slot="error">
                            <el-image :src="require('../../../assets/images/defaultImg.png')">
                            </el-image>
                        </div>
                    </el-image>
                </template>
            </el-table-column>
            <el-table-column prop="status" label="状态" align="center">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.status != null"
                            :type="scope.row.status == 1 ? '' : scope.row.status == 2 ? 'success' : 'info'">
                        {{scope.row.status == 1 ? '待审核' : scope.row.status == 2 ? '已通过' : scope.row.status == 3 ? '已驳回'
                        : ''}}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="申请时间" align="center"></el-table-column>
            <el-table-column prop="rejectReason" label="驳回理由" align="center"></el-table-column>
            <el-table-column prop="is3rd" label="是否三方组织员工" align="center">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.is3rd != null"
                            :type="scope.row.is3rd == true ? '' : 'info'">
                        {{scope.row.is3rd == true ? '是' : '否'}}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column width="200" align="center" label="操作">
                <template slot-scope="scope">
                    <el-button v-if="scope.row.status == 1" size="mini" @click="handleAudit(scope.row)"
                               type="warning">审 核
                    </el-button>
                    <el-button size="mini" @click="handleShow(scope.row)" type="primary">查 看</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="filters.current"
                       :page-sizes="[10, 50, 200, 1000]"
                       :page-size="filters.size"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
        <el-dialog v-draggable :title="title" :visible.sync="visibleDialog" top="5vh">
            <el-form :model="visibleForm" ref="visibleForm" size="small" :rules="rules" label-width="140px">
                <div v-if="title == '申请审核' && active == 0">
                    <el-row>
                        <el-col :span="11">
                            <el-form-item label="姓名" prop="name">
                                <el-input size="small" v-model="visibleForm.name"
                                          placeholder="请输入内容"/>
                            </el-form-item>
                            <el-form-item label="手机号" prop="phone">
                                <el-input size="small" v-model="visibleForm.phone"
                                          placeholder="请输入内容"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="11">
                            <el-form-item label="人员编号" prop="userNo">
                                <el-input size="small" v-model="visibleForm.userNo"
                                          placeholder="人员编号"/>
                            </el-form-item>
                            <el-form-item label="所属组织" prop="value">
                                <el-cascader ref="xxxxLabel" :props="{ checkStrictly: true, children: 'subList',
                                             value: 'id',label: 'name',}" size="small" v-model="visibleForm.value"
                                             :options="options" @change="handlech">
                                </el-cascader>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-table
                            size="small" :data="tableUserList"
                            highlight-current-row border style="width: 100%"
                            :row-style="{height:'45px'}"
                            :cell-style="{padding:'0px'}"
                            :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                            stripe height="300" max-height="300">
                        <el-table-column type="index" label="序号"></el-table-column>
                        <el-table-column prop="userNo" label="人员编号" align="center"></el-table-column>
                        <el-table-column prop="name" label="姓名" align="center"></el-table-column>
                        <el-table-column prop="phone" label="手机号" align="center"></el-table-column>
                        <el-table-column prop="avatar" label="头像" align="center">
                            <template slot-scope="scope">
                                <el-image
                                        style="width: 50px; height: 50px"
                                        :src="scope.row.avatar?scope.row.avatar:require('../../../assets/images/defaultImg.png')"
                                        :preview-src-list="[scope.row.avatar?scope.row.avatar:require('../../../assets/images/defaultImg.png')]">
                                </el-image>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <el-row v-if="active == 1 || title == '查看'">
                    <el-col :span="11">
                        <el-form-item label="姓名" prop="name">
                            <el-input size="small" v-model="visibleForm.name"
                                      placeholder="请输入内容"/>
                        </el-form-item>
                        <el-form-item label="人员编号" prop="userNo">
                            <el-input size="small" v-model="visibleForm.userNo"
                                      placeholder="人员编号"/>
                        </el-form-item>
                        <el-form-item label="手机号" prop="phone">
                            <el-input size="small" v-model="visibleForm.phone"
                                      placeholder="请输入内容"/>
                        </el-form-item>
                        <el-form-item label="身份证号" prop="idCardNo">
                            <el-input size="small" v-model="visibleForm.idCardNo"
                                      placeholder="请输入内容"/>
                        </el-form-item>
                        <el-form-item label="详细住址" prop="addr">
                            <el-input size="small" v-model="visibleForm.addr"
                                      placeholder="请输入内容"/>
                        </el-form-item>
                        <el-form-item label="所属组织" prop="value">
                            <el-cascader ref="xxxxLabel" :props="{ checkStrictly: true, children: 'subList',
                                             value: 'id',label: 'name',}" size="small" v-model="visibleForm.value"
                                         :options="options" @change="handlech">
                            </el-cascader>
                        </el-form-item>
                        <el-form-item label="拒绝理由" prop="rejectReason">
                            <el-input size="small" type="textarea" v-model="visibleForm.rejectReason"></el-input>
                        </el-form-item>
                        <el-form-item label="是否三方组织员工" prop="is3rd">
                            <!--                            <el-select style="width: 100%" v-model="visibleForm.is3rd"-->
                            <!--                                       placeholder="请选择">-->
                            <!--                                <el-option label="是" :value="true"></el-option>-->
                            <!--                                <el-option label="否" :value="false"></el-option>-->
                            <!--                            </el-select>-->
                            <el-radio-group size="small" v-model="visibleForm.is3rd">
                                <el-radio :label="true">是</el-radio>
                                <el-radio :label="false">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11">
                        <el-form-item label="性别" prop="sex">
                            <el-select style="width: 100%" size="small" v-model="visibleForm.sex"
                                       placeholder="请选择性别">
                                <el-option label="男" :value="1"></el-option>
                                <el-option label="女" :value="2"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="门禁卡号" prop="passNo">
                            <el-input size="small" v-model="visibleForm.passNo"
                                      oninput="value=value.replace(/[^\d]/g,'')"
                                      placeholder="请输入内容"/>
                        </el-form-item>
                        <el-form-item label="自定义标签" prop="tag">
                            <el-input size="small" v-model="visibleForm.tag"
                                      placeholder="请输入内容"/>
                        </el-form-item>
                        <el-form-item label="头像" prop="avatar">
                            <el-tabs v-model="activeNameAvatar" type="card">
                                <el-tab-pane label="上传" name="first">
                                    <div style="width:178px;height:178px;position:relative;">
                                        <el-upload
                                                class="avatar-uploader"
                                                action="#"
                                                :multiple="false"
                                                name="file"
                                                :auto-upload="false"
                                                :show-file-list="false"
                                                :on-change="handleChange">
                                            <img v-if="visibleForm.avatar" :src="visibleForm.avatar"
                                                 class="avatar">
                                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                        </el-upload>
                                    </div>
                                </el-tab-pane>
                                <el-tab-pane label="示例" name="third">
                                    <el-image style="width: 80%; height: 80%"
                                              :src="require('../../../assets/images/example.jpg')"
                                              :preview-src-list="[require('../../../assets/images/example.jpg')]">
                                    </el-image>
                                </el-tab-pane>
                                <el-tab-pane label="拍照" name="second">
                                    <img v-if="visibleForm.avatar" :src="visibleForm.avatar"
                                         class="avatar" @click="handleSetImage()">
                                    <i v-else class="el-icon-plus avatar-uploader-icon"
                                       @click="handleSetImage()"></i>
                                </el-tab-pane>
                            </el-tabs>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button v-if="title == '申请审核' && active == 1" type="primary"
                           @click="submitForm('visibleForm',true)">通 过</el-button>
                <el-button v-if="title == '申请审核' && active == 1" type="warning"
                           @click="submitForm('visibleForm',false)">拒 绝</el-button>
                <el-button v-if="title == '申请审核' && active == 0 && activeIndex == 0" type="primary"
                           @click="getAllUser(true)">查 重</el-button>
                <el-button v-if="title == '申请审核' && active == 0 && activeIndex == 1" @click="next"
                           type="primary">下一步 </el-button>
                <el-button @click="visibleDialog = false">取 消</el-button>
            </span>
        </el-dialog>
        <!--  拍照上传  -->
        <el-dialog v-draggable title="拍照上传" :visible.sync="dialogVisible" width="60%" @close="handleClose" center>
            <el-row>
                <el-col :span="12" style="text-align: center">
                    <div style="text-align: center;font-size: 14px;font-weight: bold;margin-bottom: 10px;">摄像头</div>
                    <!-- 这里就是摄像头显示的画面 -->
                    <video id="videoCamera" :width="videoWidth" :height="videoHeight" autoplay></video>
                    <div class="iCenter">
                        <el-button type='primary' size='small' icon="el-icon-camera" @click="takePhone"
                                   style="margin-top: 10px;">拍照
                        </el-button>
                        <el-button @click="getCompetence" icon="el-icon-video-camera" size="small"> 打开摄像头
                        </el-button>
                        <el-button size="small" @click="stopNavigator" icon="el-icon-switch-button"
                                   style="margin-top: 10px;">关闭摄像头
                        </el-button>
                    </div>
                </el-col>
                <el-col :span="12" style="text-align: center">
                    <div style="text-align: center;font-size: 14px;font-weight: bold;margin-bottom: 10px;">拍摄效果
                    </div>
                    <!-- 这里是点击拍照显示的图片画面 -->
                    <canvas id="canvasCamera" :width="videoWidth" :height="videoHeight"></canvas>
                    <el-button icon="el-icon-check" type="primary" size="small" @click="setImage"
                               style="margin-top: 10px;">保存
                    </el-button>
                    <el-button size="small" @click="handleClose" icon="el-icon-close" style="margin-top: 10px;">取消
                    </el-button>
                </el-col>
            </el-row>
        </el-dialog>
        <!-- 生成二维码的弹窗 -->
        <div class="dialog_div">
            <el-dialog :visible.sync="QRcodeDialogVisible" top="0" width="40%" align="center">
                <div class="dialogqr">
                    <div class="qrimg">
                        <div class="qrcode" id="qrcode"></div>
                        <div class="qrtext">
                            <span style="font-size: 14px">【{{villageName}}】 温馨提示</span>
                            <span>资料提交成功后需等待1个工作日 查看结果可再次扫码</span>
                            <span>如长时间未审核可拨打电话{{contactPhone}}进行咨询</span>
                        </div>
                    </div>
                </div>
            </el-dialog>
        </div>
        <!--     批量审核   -->
        <el-dialog v-draggable title="批量审核" :visible.sync="dialogFormVisible" width="25%">
            <el-form :model="form" ref="form" size="small" :rules="formRules" label-width="100px">
                <el-form-item label="所属组织" prop="list">
                    <el-cascader ref="xxxxLabel" size="small" v-model="form.list" :options="options"
                                 :props="defaultChildrenProps"
                                 @change="handlech">
                    </el-cascader>
                </el-form-item>
                <el-form-item label="拒绝理由" prop="rejectReason">
                    <el-input size="small" type="textarea" v-model="form.rejectReason"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="onSubmite('form',true)">通 过
                </el-button>
                <el-button type="warning" @click="onSubmite('form',false)">拒 绝
                </el-button>
            </div>
        </el-dialog>
        <el-dialog v-draggable title="批量审核结果" :visible.sync="dialogTableVisible" align="center">
            <el-row>
                <el-col :span="6"><span>成功审核：{{successNum}}</span></el-col>
                <el-col :span="6"><span>失败审核：{{failNum}}</span></el-col>
                <el-col :span="6">审核总条数：{{successNum + failNum}}</el-col>
                <el-col :span="6">
                    <el-button type="primary" size="mini" @click="dialogTableVisible = false">关闭</el-button>
                </el-col>
            </el-row>
            <el-table
                    v-if="gridData.length > 0"
                    size="small"
                    :data="gridData"
                    highlight-current-row
                    border
                    style="width: 100%;margin-top: 10px;"
                    :row-style="{height:'45px'}"
                    :cell-style="{padding:'0px'}"
                    :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                    stripe
            >
                <el-table-column min-width="30" type="index" label="序号"></el-table-column>
                <el-table-column prop="name" label="姓名" align="center"></el-table-column>
                <el-table-column prop="userNo" label="人员编号" align="center"></el-table-column>
                <el-table-column prop="phone" label="手机号码" align="center"></el-table-column>
                <el-table-column prop="idCardNo" label="身份证" align="center"></el-table-column>
                <el-table-column prop="sex" label="性别" align="center">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.sex != null"
                                :type="scope.row.sex == 1 ? '' : 'warning'">
                            {{scope.row.sex == 1 ? '男' : '女'}}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="visitorAvatar" label="头像" align="center">
                    <template slot-scope="scope">
                        <el-image
                                style="width: 50px; height: 50px"
                                :src="scope.row.avatar?scope.row.avatar:require('../../../assets/images/defaultImg.png')"
                                :preview-src-list="[scope.row.avatar?scope.row.avatar:require('../../../assets/images/defaultImg.png')]">
                            <div slot="error">
                                <el-image :src="require('../../../assets/images/defaultImg.png')">
                                </el-image>
                            </div>
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="rejectReason" label="驳回理由" align="center"></el-table-column>
                <el-table-column prop="errorMsg" label="错误信息" align="center"></el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>

<script>
    import {
        getUser,
        handleTreeData, managerContact,
        uploadFile,
        userRequestlist,
        userRequestVerify, userRequestVerifyBatch,
        villageInfo
    } from "../../../api/organizationApi";
    import QRCode from 'qrcodejs2'

    export default {
        name: "userRequestVerify",
        data() {
            return {
                filters: {
                    name: '',
                    phone: '',
                    current: 1,
                    size: 10,
                    status: ''
                },
                title: "查看",
                loading: false,
                total: 0,
                tableData: [],
                visibleDialog: false,
                visibleForm: {
                    avatar: "",
                    name: "",
                    idCardNo: "",
                    passNo: "",
                    phone: "",
                    sex: "",
                    userNo: "",
                    tag: "",
                    addr: "",
                    villageId: "",
                    villageName: "",
                    orgId: "",
                    rejectReason: "",
                    is3rd: "",
                    id: "",
                    value: [],
                },
                rules: {
                    value: [
                        {required: true, message: '请选择', trigger: 'change'},
                    ],
                    sex: [
                        {required: true, message: '请选择', trigger: 'change'},
                    ],
                    is3rd: [
                        {required: true, message: '请选择', trigger: 'change'},
                    ],
                    name: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ],
                    phone: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ]
                },
                activeNameAvatar: 'first',
                dialogVisible: false,
                videoWidth: 400,
                videoHeight: 300,
                imgSrc: "",
                thisCancas: null,
                thisContext: null,
                thisVideo: null,
                img: "",
                QRcodeDialogVisible: false,
                options: [],
                defaultChildrenProps: {
                    children: 'subList',
                    label: 'name',
                    value: 'id',
                },

                multipleSelection: [],
                form: {
                    list: [],
                    rejectReason: ""
                },
                formRules: {
                    list: [
                        {required: true, message: '请选择', trigger: 'change'},
                    ]
                },
                dialogTableVisible: false,
                dialogFormVisible: false,
                gridData: [],
                successNum: "",
                failNum: "",
                villageId: "",
                villageName: "",
                contactPhone: "",
                visitorImg: require('@/assets/images/156.png'),
                active: 0,
                activeIndex: 0,
                tableUserList: []
            };
        },
        created() {
            this.getVillageInfo();
        },
        mounted() {
            this.fetchData();
            this.handleGetTreeData();
        },
        methods: {
            getVillageInfo() {
                villageInfo().then(res => {
                    this.villageId = res.data.data.id;
                    this.villageName = res.data.data.villageName;
                    this.contactPhone = res.data.data.contactPhone;
                })
            },
            handlech(value) {
                const checkedNodes = this.$refs['xxxxLabel'].getCheckedNodes();
                this.visibleForm.orgId = checkedNodes[0].value;
            },
            async handleGetTreeData() {
                let param = {
                    isUser: false
                };
                await handleTreeData(param).then((res) => {
                    this.options = this.getTreeData(res.data.data);
                }).catch(error => {
                    console.log(error);
                });
            },
            getTreeData(data) {
                for (var i = 0; i < data.length; i++) {
                    if (data[i].subList.length < 1) {
                        // children若为空数组，则将children设为undefined
                        data[i].subList = undefined
                    } else {
                        // children若不为空数组，则继续 递归调用 本方法
                        this.getTreeData(data[i].subList)
                    }
                }
                return data
            },
            fetchData() {
                this.loading = true;
                userRequestlist(this.filters).then(res => {
                    this.loading = false;
                    if (res.data.code == "SUCCESS") {
                        this.tableData = res.data.data.records;
                        this.total = res.data.data.total;
                        this.filters.current = res.data.data.current;
                        this.filters.size = res.data.data.size;
                    } else {
                        this.$message.info(res.data.message);
                    }
                })
            },
            handleSearch() {
                this.filters.current = 1;
                this.fetchData();
            },
            handleSizeChange(val) {
                this.filters.size = val;
                this.filters.current = 1;
                this.fetchData();
            },
            handleCurrentChange(val) {
                this.filters.current = val;
                this.fetchData();
            },
            //查看
            handleShow(row) {
                this.title = '查看';
                this.visibleDialog = true;
                Object.keys(this.visibleForm).map(key => {
                    this.visibleForm[key] = row[key];
                });
                this.activeNameAvatar = 'first';
                this.visibleForm.value = [];
                this.visibleForm.value = this.changeDetSelect(row.orgId, this.options);
            },
            //申请
            handleAudit(row) {
                this.title = '申请审核';
                this.active = 0;
                this.activeIndex = 0;
                this.visibleDialog = true;
                Object.keys(this.visibleForm).map(key => {
                    this.visibleForm[key] = row[key];
                });
                this.activeNameAvatar = 'first';
                this.visibleForm.value = [];
                this.visibleForm.value = this.changeDetSelect(row.orgId, this.options);
            },
            changeDetSelect(key, treeData) {
                if (!key) {
                    key = 0
                }
                let arr = []; // 在递归时操作的数组
                let returnArr = []; // 存放结果的数组
                let depth = 0; // 定义全局层级
                // 定义递归函数
                function childrenEach(childrenData, depthN) {
                    for (var j = 0; j < childrenData.length; j++) {
                        depth = depthN; // 将执行的层级赋值 到 全局层级
                        arr[depthN] = childrenData[j].id;
                        if (childrenData[j].id == key) {
                            returnArr = arr.slice(0, depthN + 1); //将目前匹配的数组，截断并保存到结果数组，
                            break;
                        } else {
                            if (childrenData[j].subList) {
                                depth++;
                                childrenEach(childrenData[j].subList, depth);
                            }
                        }
                    }
                    return returnArr;
                }

                return childrenEach(treeData, depth);
            },
            getAllUser(val) {
                let params = {
                    userNo: this.visibleForm.userNo,
                    phone: this.visibleForm.phone,
                    size: 1000,
                    current: 1,
                    global: val
                };
                getUser(params).then((res) => {
                    this.tableUserList = res.data.data.records;
                    this.activeIndex = 1
                })
            },
            submitForm(visibleForm, value) {
                this.$refs[visibleForm].validate((valid) => {
                        if (valid) {
                            // if (value === true && !this.visibleForm.value) {
                            //     this.$message.warning("请选择所属组织");
                            //     return;
                            // }
                            if (!this.visibleForm.orgId) {
                                this.$message.warning("请选择所属组织");
                                return;
                            }
                            let param = {
                                id: this.visibleForm.id,
                                avatar: this.visibleForm.avatar || null,
                                idCardNo: this.visibleForm.idCardNo || null,
                                userNo: this.visibleForm.userNo || null,
                                name: this.visibleForm.name || null,
                                passNo: this.visibleForm.passNo || null,
                                phone: this.visibleForm.phone || null,
                                sex: this.visibleForm.sex || null,
                                tag: this.visibleForm.tag || null,
                                addr: this.visibleForm.addr || null,
                                orgId: this.visibleForm.orgId || null,
                                is3rd: this.visibleForm.is3rd || null,
                                rejectReason: this.visibleForm.rejectReason || null,
                                villageId: this.visibleForm.villageId || null,
                                villageName: this.visibleForm.villageName || null,
                                approve: value
                            }
                            // for (var i = 0; i < this.options.length; i++) {
                            //     if (this.options[i].subList) {
                            //         for (var j = 0; j < this.options[i].subList.length; j++) {
                            //             if (this.options[i].subList[j].id == this.visibleForm.orgId) {
                            //                 if (this.options[i].subList[j].type == 1) {
                            //                     this.$message.warning("人员不能直接分配在组织下，只能分配在部门下，请重新选择！！");
                            //                     return;
                            //                 }
                            //             }
                            //         }
                            //     } else if (this.options[i].id == this.visibleForm.orgId) {
                            //         if (this.options[i].type == 1) {
                            //             this.$message.warning("人员不能直接分配在组织下，只能分配在部门下，请重新选择！！");
                            //             return;
                            //         }
                            //     }
                            // }
                            userRequestVerify(param).then(res => {
                                this.visibleDialog = false;
                                if (res.data.code == "SUCCESS") {
                                    this.fetchData();
                                    if (value === false) {
                                        this.$message.success("申请已驳回！");
                                    } else {
                                        this.$message.success("申请成功！");
                                    }
                                } else {
                                    this.$message.info(res.data.message);
                                }
                            })
                        }
                    }
                )
            },
            next() {
                this.active = 1;
            },
            handleChange(file) {
                const isLt10M = file.size / 1024 / 1024 < 10;
                var testmsg = /^image\/(jpeg|png|jpg)$/.test(file.raw.type);
                if (!testmsg) {
                    this.$message.error('上传图片只能是 JPEG|PNG|JPG 格式!');
                    return
                }
                if (!isLt10M) {
                    this.$message.error('上传图片大小不能超过 10MB!');
                    return;
                }
                let form = new FormData();
                form.append('file', file.raw);
                uploadFile(form).then((res) => {
                    if (res.data.code == "SUCCESS") {
                        this.$message.success("上传成功!");
                        this.visibleForm.avatar = res.data.data;
                    }
                });
            },
            handleSetImage() {
                this.dialogVisible = true;
                this.$nextTick(() => {
                    this.getCompetence();
                })
            },
            // 调用权限（打开摄像头功能）
            getCompetence() {
                var _this = this;
                _this.thisCancas = document.getElementById("canvasCamera");
                _this.thisContext = this.thisCancas.getContext("2d");
                _this.thisVideo = document.getElementById("videoCamera");
                // 获取媒体属性，旧版本浏览器可能不支持mediaDevices，我们首先设置一个空对象
                if (navigator.mediaDevices === undefined) {
                    navigator.mediaDevices = {};
                }
                // 一些浏览器实现了部分mediaDevices，我们不能只分配一个对象
                // 使用getUserMedia，因为它会覆盖现有的属性。
                // 这里，如果缺少getUserMedia属性，就添加它。
                if (navigator.mediaDevices.getUserMedia === undefined) {
                    navigator.mediaDevices.getUserMedia = function (constraints) {
                        // 首先获取现存的getUserMedia(如果存在)
                        var getUserMedia =
                            navigator.webkitGetUserMedia ||
                            navigator.mozGetUserMedia ||
                            navigator.getUserMedia;
                        // 有些浏览器不支持，会返回错误信息
                        // 保持接口一致
                        if (!getUserMedia) {
                            //不存在则报错
                            return Promise.reject(
                                new Error("getUserMedia is not implemented in this browser")
                            );
                        }
                        // 否则，使用Promise将调用包装到旧的navigator.getUserMedia
                        return new Promise(function (resolve, reject) {
                            getUserMedia.call(navigator, constraints, resolve, reject);
                        });
                    };
                }
                var constraints = {
                    audio: false,
                    video: {
                        width: this.videoWidth,
                        height: this.videoHeight,
                        transform: "scaleX(-1)",
                    },
                };
                navigator.mediaDevices
                    .getUserMedia(constraints)
                    .then(function (stream) {
                        // 旧的浏览器可能没有srcObject
                        if ("srcObject" in _this.thisVideo) {
                            _this.thisVideo.srcObject = stream;
                        } else {
                            // 避免在新的浏览器中使用它，因为它正在被弃用。
                            _this.thisVideo.src = window.URL.createObjectURL(stream);
                        }
                        _this.thisVideo.onloadedmetadata = function (e) {
                            _this.thisVideo.play();
                        };
                    })
                    .catch((err) => {
                        this.$message({
                            message: '没有开启摄像头权限或浏览器版本不兼容',
                            type: 'warning'
                        });
                        console.log(err);
                    });
            },
            //点击拍照截图画面
            takePhone() {
                // canvas画图
                this.thisContext.drawImage(this.thisVideo, 0, 0, this.videoWidth, this.videoHeight);
                this.imgSrc = this.thisCancas.toDataURL('image/png');
            },
            //  绘制图片（拍照功能）
            setImage() {
                // 获取图片base64链接
                var blob = this.convertBase64UrlToBlob(this.imgSrc); // 上一步中的函数
                let form = new FormData();
                form.append('file', blob, 'image.png');
                uploadFile(form).then((res) => {
                    if (res.data.code == "SUCCESS") {
                        this.$message.success("上传成功!");
                        this.personnelForm.avatar = res.data.data;
                        this.dialogVisible = false;
                        //清空画布
                        let c = document.getElementById("canvasCamera");
                        let cxt = c.getContext("2d");
                        cxt.clearRect(0, 0, c.width, c.height);
                        // 关闭摄像头
                        this.thisVideo.srcObject.getTracks()[0].stop();
                    } else {
                        this.$message.info(res.data.message);
                    }
                });
            },
            /*
            * * 将base64转换成可用formdata提交的文件
            * * @param {string} urlData base64的url
            * * @return {Blob}
            * */
            convertBase64UrlToBlob(urlData) {
                //去掉url的头，并转换为byte
                var split = urlData.split(',');
                var bytes = window.atob(split[1]);
                //处理异常,将ascii码小于0的转换为大于0
                var ab = new ArrayBuffer(bytes.length);
                var ia = new Uint8Array(ab);
                for (var i = 0; i < bytes.length; i++) {
                    ia[i] = bytes.charCodeAt(i);
                }
                return new Blob([ab], {type: split[0]});
            },
            handleClose() {
                this.dialogVisible = false;
                //清空画布
                let c = document.getElementById("canvasCamera");
                let cxt = c.getContext("2d");
                cxt.clearRect(0, 0, c.width, c.height);
                this.thisVideo.srcObject.getTracks()[0].stop();
            },
            // 关闭摄像头
            stopNavigator() {
                this.thisVideo.srcObject.getTracks()[0].stop();
            },
            handleCode() {
                this.QRcodeDialogVisible = true;
                this.$nextTick(() => {
                    let qrcodeEL = document.getElementById("qrcode");
                    let qrcode = new QRCode(qrcodeEL);
                    document.getElementById("qrcode").innerHTML = "";
                    this.GetQRcode(this.villageId, this.villageName)
                })
            },
            //  生成二维码
            GetQRcode(id, name) {
                var url = process.env.NODE_ENV === "production" ? "http://pc.rlinking.com/#/accessControl?villageId=" + id + "&villageName=" + name : "http://passage-user-test.rlinking.com/#/accessControl?villageId=" + id + "&villageName=" + name;
                var qrcode = new QRCode('qrcode', {
                    text: url,       //  需要生成二维码的内容
                    width: 240,
                    height: 240,
                    colorDark: '#000000',
                    colorLight: '#ffffff',
                    correctLevel: QRCode.CorrectLevel.H
                });
            },
            handleSelectionChange(selection) {
                this.multipleSelection = [];
                selection.forEach(element => {
                    this.multipleSelection.push({
                        id: element.id,
                        avatar: element.avatar,
                        idCardNo: element.idCardNo,
                        userNo: element.userNo,
                        name: element.name,
                        passNo: element.passNo,
                        phone: element.phone,
                        sex: element.sex,
                        tag: element.tag,
                        addr: element.addr,
                        villageId: element.villageId,
                        villageName: element.villageName,
                        orgId: "",
                        approve: "",
                        rejectReason: "",
                    });
                });
            },
            handleBatch() {
                this.dialogFormVisible = true;
                this.form.rejectReason = "";
                this.form.list = [];
            },
            onSubmite(form, value) {
                this.$refs[form].validate((valid) => {
                    if (valid) {
                        if (value === true && this.form.list.length < 1) {
                            this.$message.warning("请选择所属组织");
                            return;
                        }
                        this.multipleSelection.forEach(item => {
                            item.orgId = this.form.list.at(-1);
                            item.rejectReason = this.form.rejectReason;
                            item.approve = value;
                        })
                        /*for (var i = 0; i < this.options.length; i++) {
                            if (this.options[i].subList) {
                                for (var j = 0; j < this.options[i].subList.length; j++) {
                                    if (this.options[i].subList[j].id == this.form.list.at(-1)) {
                                        if (this.options[i].subList[j].type == 1) {
                                            this.$message.warning("人员不能直接分配在组织下，只能分配在部门下，请重新选择！！");
                                            return;
                                        }
                                    }
                                }
                            } else if (this.options[i].id == this.form.list.at(-1)) {
                                if (this.options[i].type == 1) {
                                    this.$message.warning("人员不能直接分配在组织下，只能分配在部门下，请重新选择！！");
                                    return;
                                }
                            }
                        }*/
                        userRequestVerifyBatch(this.multipleSelection).then(res => {
                            if (res.data.code == "SUCCESS") {
                                this.dialogFormVisible = false;
                                this.dialogTableVisible = true;
                                this.gridData = res.data.data.errorData;
                                this.failNum = res.data.data.fail;
                                this.successNum = res.data.data.success;
                                if (value === false) {
                                    this.$message.success("申请已驳回！");
                                } else {
                                    this.$message.success("申请成功！");
                                }
                                this.fetchData()
                            } else {
                                this.$message.error(res.data.message);
                            }
                        }).catch(error => {
                            this.dialogFormVisible = false;
                            this.$message.error("审核失败，请稍后再试！");
                        });
                    }
                })
            },
            handlePhone() {
                this.$prompt('管理员手机号码', '', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputValue: this.contactPhone,
                    inputValidator: (value) => {       // 点击按钮时，对文本框里面的值进行验证
                        if (!value) {
                            return '请输入手机号码';
                        } else if (isNaN(value)) {
                            return '手机号输入不正确';
                        }
                    },
                }).then(({value}) => {
                    managerContact(value).then(res => {
                        if (res.data.code == "SUCCESS") {
                            this.$message.success(res.data.message);
                            this.getVillageInfo();
                        }
                    })
                }).catch(error => {
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    .user-search {
        margin-top: 15px;
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }

    .block {
        margin: 10px 0;
    }

    .camera_outer {
        position: relative;
        overflow: hidden;
        background-size: 100%;

        video,
        canvas,
        .tx_img {
            -moz-transform: scaleX(-1);
            -webkit-transform: scaleX(-1);
            -o-transform: scaleX(-1);
            transform: scaleX(-1);
        }

        .btn_camera {
            position: absolute;
            bottom: 4px;
            left: 0;
            right: 0;
            height: 50px;
            background-color: rgba(0, 0, 0, 0.3);
            line-height: 50px;
            text-align: center;
            color: #ffffff;
        }

        .bg_r_img {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            top: 0;
        }

        .img_bg_camera {
            position: absolute;
            right: 0;
            top: 0;

            img {
                width: 170px;
                height: 178px;
            }

            .img_btn_camera {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 50px;
                line-height: 50px;
                text-align: center;
                background-color: rgba(0, 0, 0, 0.3);
                color: #ffffff;

                .loding_img {
                    width: 50px;
                    height: 50px;
                }

            }
        }
    }

    .upload-demo {
        margin-left: 10px;
    }

    ::v-deep .el-dialog__body {
        padding: 30px 20px 0;
    }

    .dialog_div {
        ::v-deep .el-dialog__wrapper {
            .el-dialog {
                background-color: unset !important;
                box-shadow: unset !important;

                .el-dialog__header {
                    background-color: unset !important;
                }

                .el-dialog__headerbtn .el-dialog__close {
                    color: #fff;
                    font-size: 30px;
                }

                .el-dialog__headerbtn .el-dialog__close:hover {
                    color: gray;
                }
            }
        }
    }

    .dialogqr {
        width: 400px;
        height: 610px;
    }

    .dialogqr .qrimg {
        height: 620px;
        background-size: 400px 620px;
        background-repeat: no-repeat;
        /* 把背景图片固定住 */
        /*background-attachment: fixed;*/
        background-image: url("../../../assets/images/156.png");
    }

    .dialogqr .qrimg .qrcode {
        padding-top: 185px;
    }

    .dialogqr .qrimg .qrtext {
        padding: 30px 0 0 50px;
        display: flex;
        flex-direction: column;
        color: #fae5e3;
        align-items: start;
        line-height: 20px;
    }

    .qrtext span {
        font-size: 12px;
    }

</style>
