<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>车辆管理</el-breadcrumb-item>
            <el-breadcrumb-item>长租车辆</el-breadcrumb-item>
            <el-breadcrumb-item>添加车辆</el-breadcrumb-item>
        </el-breadcrumb>
        <el-form :model="editForm" ref="editForm" size="small" class="user-search" :rules="rules">
            <el-row>
                <el-row>
                    <h2 style="margin: 0px 50px 15px;">车主信息</h2>
                </el-row>
                <el-row>
                    <el-col :span="11">
                        <el-form-item label="车主姓名" prop="ownerName" :label-width="formLabelWidth">
                            <el-input style="width: 55%;" size="small" v-model="editForm.ownerName"
                                      placeholder="请输入内容"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11">
                        <el-form-item label="车主身份证号" prop="idCard" :label-width="formLabelWidth">
                            <el-input style="width: 55%;" size="small" v-model="editForm.idCard"
                                      placeholder="请输入内容"/>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="11">
                        <el-form-item label="车主手机" prop="ownerPhone" :label-width="formLabelWidth">
                            <el-input style="width: 55%;" size="small" oninput="value=value.replace(/[^\d]/g,'')"
                                      v-model="editForm.ownerPhone"
                                      placeholder="请输入内容"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11">
                        <el-form-item label="备注" prop="remark" :label-width="formLabelWidth">
                            <el-input style="width: 55%;" size="small" v-model="editForm.remark"
                                      placeholder="请输入内容"/>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <h2 style="float: left;margin: 0px 50px;line-height: 40px;">车辆信息</h2>
                    <div style="float: right;width: 25%;margin: 0px 0px 15px;">
                        <el-button icon="el-icon-plus" type="primary" size="small" @click="addLongCarInfo">添加
                        </el-button>
                    </div>
                </el-row>
                <el-row v-for="(item, index) in editForm.longCarInfo" :key="item.key">
                    <div style="float: right;width: 25%;margin: 0px 0px 15px;" v-if="index !== 0">
                        <el-button icon="el-icon-delete" type="danger" size="small"
                                   @click.prevent="removeLongCarInfo(item)">
                            删除
                        </el-button>
                    </div>
                    <el-col :span="11">
                        <el-form-item label="车牌号码" :prop="'longCarInfo.' + index + '.licensePlate'"
                                      :label-width="formLabelWidth"
                                      :rules="[{ required: true, message: '请输入', trigger: 'blur' }]">
                            <el-input style="width: 55%;" size="small" placeholder="请输入内容"
                                      v-model.trim="item.licensePlate">
                                <template slot="prepend">
                                    <LicenseKeyboard custom-class="app-license-keyboard"
                                                     customLicenseClass="license-container"
                                                     v-model="item.licensePlate" fontSize="0.3rem"/>
                                </template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="车辆标签" prop="carType" :label-width="formLabelWidth">
                            <el-input style="width: 55%;" size="small" v-model="item.carTag"
                                      placeholder="请输入内容"/>
                        </el-form-item>
                        <el-form-item label="RFID卡号" prop="rfidNo" :label-width="formLabelWidth">
                            <el-input style="width: 55%;" size="small" v-model="item.rfidNo"
                                      placeholder="请输入内容"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11">
                        <el-form-item label="车辆属性" :prop="'longCarInfo.' + index + '.carProperty'"
                                      :label-width="formLabelWidth"
                                      :rules="[{ required: true, message: '请选择', trigger: 'change' }]">
                            <el-select style="width: 55%;" size="small" v-model="item.carProperty"
                                       placeholder="请选择车辆属性">
                                <el-option label="小车" value="1"></el-option>
                                <el-option label="大车" value="2"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="ETC卡号" prop="etcNo" :label-width="formLabelWidth">
                            <el-input style="width: 55%;" size="small" v-model="item.etcNo"
                                      placeholder="请输入内容"/>
                        </el-form-item>
                        <!--                        <el-form-item label="车辆属性" :prop="'longCarInfo.' + index + '.carProperty'"-->
                        <!--                                      :label-width="formLabelWidth"-->
                        <!--                                      :rules="[{ required: true, message: '请选择', trigger: 'change' }]">-->
                        <!--                            <el-select style="width: 55%;" size="small" v-model="item.carProperty"-->
                        <!--                                       placeholder="请选择车辆属性">-->
                        <!--                                <el-option label="小车" value="1"></el-option>-->
                        <!--                                <el-option label="大车" value="2"></el-option>-->
                        <!--                            </el-select>-->
                        <!--                        </el-form-item>-->
                    </el-col>
                </el-row>
            </el-row>
            <el-row>
                <h2 style="float: left;margin: 0px 50px;line-height: 40px;">车位信息</h2>
                <div style="float: right;width: 25%;margin: 0px 0px 15px;">
                    <el-button icon="el-icon-plus" type="primary" size="small" @click="addDomain">添加</el-button>
                </div>
            </el-row>
            <el-row v-for="(domain, index) in editForm.domains" :key="domain.key">
                <div style="float: right;width: 25%;margin: 0px 0px 15px;" v-if="index !== 0">
                    <el-button icon="el-icon-delete" type="danger" size="small" @click.prevent="removeDomain(domain)">删除
                    </el-button>
                </div>
                <el-col :span="11">
                    <el-form-item label="选择区域" :prop="'domains.' + index + '.parkingAreaId'"
                                  :label-width="formLabelWidth"
                                  :rules="[{ required: true, message: '请选择', trigger: 'change' }]">
                        <el-select style="width: 55%;" @change="changeArea" size="small" v-model="domain.parkingAreaId"
                                   placeholder="请选择长租区域">
                            <el-option v-for="item in parkingAreas" :label="item.name"
                                       :value="item.id" :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="长租费率" :prop="'domains.' + index + '.longCarRateId'"
                                  :label-width="formLabelWidth"
                                  :rules="[{ required: true, message: '请选择', trigger: 'change' }]">
                        <el-select @change="changeRate(domain.longCarRateId,index)" style="width: 55%;" size="small"
                                   v-model="domain.longCarRateId"
                                   placeholder="请选择长租费率">
                            <el-option v-for="item in longCarRate" :label="item.rateName"
                                       :value="item.id" :key="item.id"></el-option>
                            <el-option label="自定义" value=" "></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="周期类型" :prop="'domains.' + index + '.cycleType'" :label-width="formLabelWidth">
                        <el-input readonly style="width: 55%;" size="small" v-if="domain.cycleType == 1" value="月"/>
                        <el-input readonly style="width: 55%;" size="small" v-else-if="domain.cycleType == 2"
                                  value="季"/>
                        <el-input readonly style="width: 55%;" size="small" v-else-if="domain.cycleType == 3"
                                  value="半年"/>
                        <el-input readonly style="width: 55%;" size="small" v-else-if="domain.cycleType == 4"
                                  value="年"/>
                        <el-input readonly style="width: 55%;" size="small" v-else-if="domain.cycleType == 5"
                                  value="长期"/>
                        <el-input readonly style="width: 55%;" size="small" v-else value=""/>
                    </el-form-item>
                    <el-form-item label="金额" :prop="'domains.' + index + '.price'" :label-width="formLabelWidth"
                                  :rules="[{ required: true, message: '请输入金额', trigger: 'blur' }]">
                        <el-input style="width: 55%;" size="small" v-model="domain.price"
                                  oninput="value=value.replace(/[^0-9.]/g,'')" placeholder="请输入内容"/>
                    </el-form-item>
                    <el-form-item label="可通行区域" :prop="'domains.' + index + '.longCarInAreas'"
                                  :label-width="formLabelWidth"
                                  :rules="[{ required: true, message: '请选择可通行区域', trigger: 'change' }]">
                        <el-checkbox-group v-model="domain.longCarInAreas">
                            <el-checkbox v-for="item in parkingAreas" :label="item.id"
                                         :key="item.id">{{ item.name }}
                            </el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                </el-col>
                <el-col :span="11">
                    <el-form-item label="车位编号" :prop="'domains.' + index + '.parkingPlaceNumber'"
                                  :label-width="formLabelWidth">
                        <el-select style="width: 55%;" filterable allow-create size="small"
                                   v-model="domain.parkingPlaceNumber"
                                   placeholder="请选择车位">
                            <el-option v-for="item in parkingData" :key="item.id"
                                       :label="item.parkingPlaceNumber"
                                       :value="item.parkingPlaceNumber"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item hidden label="车辆属性" :prop="'domains.' + index + '.carProperty'"
                                  :label-width="formLabelWidth">
                        <el-input style="width: 55%;" size="small" v-model="domain.carProperty" readonly></el-input>
                        <el-input readonly style="width: 55%;" size="small" v-if="domain.carProperty == 1" value="月"/>
                        <el-input readonly style="width: 55%;" size="small" value="季"/>
                    </el-form-item>
                    <el-form-item label="有效期" :prop="'domains.' + index + '.startTime'" :label-width="formLabelWidth"
                                  :rules="[{ required: true, message: '请选择日期', trigger: 'change' }]">
                        <el-date-picker @change="changeTime" v-model="domain.startTime" format="yyyy-MM-dd"
                                        value-format="yyyy-MM-dd"
                                        clearable style="width: 55%" type="date"
                                        placeholder="请输入开始时间"></el-date-picker>

                        <el-date-picker @change="changeDate" v-model="domain.endTime" format="yyyy-MM-dd"
                                        value-format="yyyy-MM-dd"
                                        clearable style="width: 55%" type="date"
                                        placeholder="请输入结束时间"></el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-form-item :label-width="formLabelWidth">
                <el-button size="small" type="primary" @click="onSubmit('editForm')">保存</el-button>
                <el-button size="small" @click="cancel">返回</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    // import Keyboard from '@/components/Keyboard'
    import {longCarAreaList, longCarRateList, longCarSave, parkingDataList} from "@/api/vehicle/longcar";
    import Vue from 'vue'
    import LicenseKeyboard from 'vue-license-keyboard';
    import 'vue-license-keyboard/lib/vue-license-keyboard.css';

    Vue.use(LicenseKeyboard);
    export default {
        name: "addVehicle",
        inject: ['reload'],
        // components: {
        //     Keyboard
        // },

        data() {
            return {
                formLabelWidth: '120px',
                editForm: {
                    ownerName: "",//车主姓名
                    ownerPhone: "",//车主号码
                    idCard: "", //车主身份证
                    remark: "",//备注
                    longCarInfo: [{
                        licensePlate: "",//车牌号码
                        carProperty: "",//车辆属性
                        carTag: "", //车辆标签
                        rfidNo: "", //RFID卡号
                        etcNo: "", //ETC卡号
                    }],
                    licensePlate: "",//车牌号码
                    carProperty: "",//车辆属性
                    carIdentity: "", //车辆身份
                    domains: [{
                        parkingAreaId: "",//区域id
                        cycleType: "",//周期类型
                        carProperty: "",//车辆属性
                        price: "",//价格
                        startTime: "",//开始时间
                        endTime: "",//结束时间
                        longCarRateId: "", //长租费率id
                        parkingPlaceNumber: "", //车位编号
                        days: "", //天数
                        longCarInAreas: [],
                    }],
                },
                longCarInAreas: [],
                parkingAreas: [],
                longCarRate: [],
                parkingData: [],
                rules: {
                    ownerName: [{required: true, message: "请输入", trigger: "blur"}],
                    ownerPhone: [{required: true, message: "请输入", trigger: "blur"}],
                    licensePlate: [{required: true, message: "请输入", trigger: "blur"}],
                    carProperty: [{required: true, message: "请选择", trigger: "change"}],
                },
                areaId: "",
                option: {
                    show: '',
                    sourceDom: ''
                },
            }
        },
        created() {
            this.getParkingAreaRoad();
        },
        methods: {
            getInputValue(val) {
                if (val === '删除') {
                    this.editForm.licensePlate = this.editForm.licensePlate.slice(0, this.editForm.licensePlate.length - 1)
                } else {
                    this.editForm.licensePlate += val
                }
            },

            onFocus() {
                this.$set(this.option, 'show', true);
                this.$set(this.option, 'sourceDom', this.$refs['keyboard']);
            },
            //获取区域
            getParkingAreaRoad() {
                longCarAreaList().then((res) => {
                    this.parkingAreas = res.data.data.parkingAreas;
                }).catch((err) => {
                    console.log(err)
                });
            },
            changeRate(e, i) {
                if (e != " ") {
                    this.getLongCarRateList(this.areaId, e);
                    this.editForm.domains[i].startTime = "";
                    this.editForm.domains[i].endTime = "";
                } else {
                    this.editForm.domains[i].cycleType = "";
                    this.editForm.domains[i].startTime = "";
                    this.editForm.domains[i].endTime = "";
                    this.editForm.domains[i].price = "";
                    this.editForm.domains[i].paidAmount = "";
                }
            },
            //获取长租费率
            getLongCarRateList(areaId, id) {
                longCarRateList(areaId).then((res) => {
                    if (res.data.data.length > 0) {
                        this.longCarRate = res.data.data;
                        this.longCarRate.forEach((element) => {
                            if (element.id === id) {
                                this.editForm.domains.forEach((e) => {
                                    if (e.longCarRateId === id) {
                                        e.carProperty = element.carProperty;
                                        e.cycleType = element.cycle;
                                        e.price = element.price;
                                        e.paidAmount = element.paidAmount;
                                    }
                                });
                                return;
                            }
                        })
                    }
                }).catch((err) => {
                    console.log(err)
                });
            },
            changeArea(areaId) {
                this.getParkingDataList(areaId);
                this.areaId = areaId;
                this.getLongCarRateList(this.areaId);
            },
            //获取车位
            getParkingDataList(id) {
                parkingDataList(id).then((res) => {
                    if (res.data.data.length > 0) {
                        this.parkingData = res.data.data;
                    } else {
                        this.editForm.domains.forEach((e) => {
                            if (e.parkingAreaId === id) {
                                this.parkingData = [];
                                e.parkingPlaceNumber = "";
                            }
                        })
                    }
                }).catch((err) => {
                    console.log(err)
                });
            },
            //时间计算
            changeTime() {
                this.editForm.domains.forEach((e) => {
                    if (e.cycleType == 1) {
                        e.endTime = this.dayjs(e.startTime).add(30, 'day').format("YYYY-MM-DD");
                        const date1 = this.dayjs(e.startTime, "yyyy-MM-dd");
                        const date2 = this.dayjs(e.endTime, "yyyy-MM-dd");
                        e.days = date2.diff(date1, 'day');
                    } else if (e.cycleType == 2) {
                        e.endTime = this.dayjs(e.startTime).add(90, 'day').format("YYYY-MM-DD");
                        const date1 = this.dayjs(e.startTime, "yyyy-MM-dd");
                        const date2 = this.dayjs(e.endTime, "yyyy-MM-dd");
                        e.days = date2.diff(date1, 'day');
                    } else if (e.cycleType == 3) {
                        e.endTime = this.dayjs(e.startTime).add(181, 'day').format("YYYY-MM-DD");
                        const date1 = this.dayjs(e.startTime, "yyyy-MM-dd");
                        const date2 = this.dayjs(e.endTime, "yyyy-MM-dd");
                        e.days = date2.diff(date1, 'day');
                    } else if (e.cycleType == 4) {
                        e.endTime = this.dayjs(e.startTime).add(365, 'day').format("YYYY-MM-DD");
                        const date1 = this.dayjs(e.startTime, "yyyy-MM-dd");
                        const date2 = this.dayjs(e.endTime, "yyyy-MM-dd");
                        e.days = date2.diff(date1, 'day');
                    } else if (e.cycleType == 5) {
                        let time = "2099-12-31";
                        e.endTime = this.dayjs(time).format("YYYY-MM-DD");
                        const date1 = this.dayjs(e.startTime, "yyyy-MM-dd");
                        const date2 = this.dayjs(e.endTime, "yyyy-MM-dd");
                        e.days = date2.diff(date1, 'day');
                    }
                })
            },
            changeDate() {
                this.editForm.domains.forEach((e) => {
                    if (e.cycleType == "") {
                        const date1 = this.dayjs(e.startTime, "yyyy-MM-dd");
                        const date2 = this.dayjs(e.endTime, "yyyy-MM-dd");
                        e.days = date2.diff(date1, 'day');
                    }
                })
            },
            //提交
            onSubmit(editForm) {
                this.$refs[editForm].validate((valid) => {
                    if (valid) {
                        this.editForm.domains.forEach((e) => {
                            e.paidAmount = e.price;
                            let longCarInAreasList = [];
                            e.longCarInAreas.forEach(e => {
                                longCarInAreasList.push({parkingAreaId: e});
                            })
                            e.longCarInAreas = longCarInAreasList;
                        });
                        let param = {
                            ownerName: this.editForm.ownerName,
                            ownerPhone: this.editForm.ownerPhone,
                            idCard: this.editForm.idCard,
                            remark: this.editForm.remark,
                            longCarInfo: this.editForm.longCarInfo,
                            longCarCharges: this.editForm.domains,
                        }
                        // const map = new Map();
                        // const qc = this.editForm.longCarInfo.filter(key => !map.has(key.licensePlate) && map.set(key.licensePlate, 1)) // 这里对name属性进行去重
                        const licensePlate = this.editForm.longCarInfo.map(value => value.licensePlate);
                        const licensePlateSet = new Set(licensePlate);
                        if (licensePlateSet.size != licensePlate.length) {
                            this.$message.warning('车牌号中有重复！');
                            return false;
                        }
                        longCarSave(param).then((res) => {
                            if (res.data.code == "SUCCESS") {
                                this.$message.success(res.data.message);
                                this.$router.push({path: '/parkingLot/longTermRentRate'});
                            } else {
                                this.reload();
                                this.$message.error(res.data.message);
                            }
                        }).catch(() => {
                            this.reload();
                            this.$message.error("保存失败，请稍后再试！");
                        });
                    }
                })
            },
            cancel() {
                this.$router.push({path: '/parkingLot/longTermRentRate'});
            },
            //动态表单项增加
            addLongCarInfo() {
                this.editForm.longCarInfo.push({
                    licensePlate: "",
                    carProperty: "",
                    carTag: "",
                    rfidNo: "", //RFID卡号
                    etcNo: "", //ETC卡号
                    key: Date.now(),
                });
            },
            addDomain() {
                this.editForm.domains.push({
                    parkingAreaId: "",//区域id
                    carProperty: "",//车辆属性
                    cycleType: "",//周期类型
                    price: "",//价格
                    paidAmount: "", //已缴金额
                    startTime: "",//开始时间
                    endTime: "",//结束时间
                    longCarRateId: "", //长租费率id
                    days: "", //天数
                    parkingPlaceNumber: "", //车位编号
                    longCarInAreas: [],
                    key: Date.now(),
                });
            },
            //动态表单项删除
            removeLongCarInfo(item) {
                var index = this.editForm.longCarInfo.indexOf(item);
                if (index !== -1) {
                    this.editForm.longCarInfo.splice(index, 1);
                }
            },
            removeDomain(item) {
                var index = this.editForm.domains.indexOf(item);
                if (index !== -1) {
                    this.editForm.domains.splice(index, 1);
                }
            },
        }
    }
</script>
<style lang="scss" scoped>
    .user-search {
        margin-top: 20px;
    }

    .app-license-keyboard {
        font-size: 0;
    }

    .app-license-keyboard /deep/ .icon {
        font-size: .3rem;
    }

    .app-license-keyboard /deep/ .license-container /deep/ .license-buttons {
        padding: 0 2rem;
    }

    .app-license-keyboard /deep/ .license-container /deep/ .license-buttons span {
        margin: .15rem .125rem;
        padding: .15rem 0;
        border: .0325rem solid #3d51aa
    }

    .app-license-keyboard /deep/ .license-container /deep/ .license-bar {
        padding: 0.112rem 2.1rem;
        font-weight: 700;
        font-size: .5rem;
    }
</style>
