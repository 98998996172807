<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>记录查询</el-breadcrumb-item>
            <el-breadcrumb-item>车场异常处理记录</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 搜索筛选 -->
        <el-form :inline="true" :model="filters" class="user-search">
            <el-form-item>
                <el-select size="small" v-model="filters.type" clearable placeholder="请选择异常类型">
                    <!--          <el-option label="修改车牌" value="1"></el-option>-->
                    <el-option label="开闸" value="2"></el-option>
                    <el-option label="关闸" value="5"></el-option>
                    <!--          <el-option label="匹配入场记录" value="3"></el-option>-->
                    <!--          <el-option label="重新抓拍" value="4"></el-option>-->
                </el-select>
            </el-form-item>
            <el-form-item label="处理时间">
                <el-date-picker
                        size="small"
                        v-model="date"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-input size="small" v-model="filters.licensePlate" clearable placeholder="请输入识别车牌"
                          @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-search" type="primary" size="small" @click="handleSearch">查询</el-button>
            </el-form-item>
        </el-form>
        <!--列表-->
        <el-table
                size="small"
                :data="tableData"
                highlight-current-row
                border
                v-loading="loading"
                element-loading-text="拼命加载中"
                style="width: 100%"
                :row-style="{height:'45px'}"
                :cell-style="{padding:'0px'}"
                :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                stripe
                height="500"
                max-height="500"
                :default-sort="{prop: 'createTime', order: 'descending'}">
            <el-table-column min-width="30" type="index" label="序号"></el-table-column>
            <el-table-column prop="discernLicensePlate" label="识别车牌" align="center"></el-table-column>
            <el-table-column prop="updateLicensePlate" label="修改车牌" align="center"></el-table-column>
            <el-table-column prop="handingMethod" label="处理方式" align="center">
                <template slot-scope="scope">
                    <!--          <el-tag type="success" v-if="scope.row.handingMethod == 1">修改车牌</el-tag>-->
                    <el-tag type="primary" v-if="scope.row.handingMethod == 2">开闸</el-tag>
                    <el-tag type="" v-else-if="scope.row.handingMethod == 5">关闸</el-tag>
                    <!--          <el-tag type="info" v-else-if="scope.row.handingMethod == 3">匹配入场记录</el-tag>-->
                    <!--          <el-tag type="danger" v-else-if="scope.row.handingMethod == 4">重新抓拍</el-tag>-->
                </template>
            </el-table-column>
            <el-table-column prop="direction" label="出入方向" align="center">
                <template slot-scope="scope">
                    <el-tag type="success" v-if="scope.row.direction == 1">入场</el-tag>
                    <el-tag type="primary" v-else-if="scope.row.direction == 2">出场</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="roadName" label="道口名称" align="center"></el-table-column>
            <el-table-column prop="operator" label="处理人" align="center"></el-table-column>
            <el-table-column prop="createTime" label="处理时间" align="center"></el-table-column>
        </el-table>
        <el-pagination background
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="filters.current"
                       :page-sizes="[10, 50, 200, 1000]"
                       :page-size="filters.size"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
    </div>
</template>

<script>
    import {formatDate} from "@/utils";
    import {exceptionHandlingRecord} from "@/api/record/vehiclelist";

    export default {
        name: "exceptionHandling",
        data() {
            return {
                loading: false,
                total: 0,
                tableData: [],
                filters: {
                    current: 1,
                    size: 10,
                    licensePlate: '',
                    type: '',
                    startTime: '',
                    endTime: '',
                },
                date: [formatDate(new Date(), "yyyy-MM-dd"), formatDate(new Date(), "yyyy-MM-dd")],
            }
        },
        created() {
            this.fetchData();  //调用接口获取动态数据
        },
        methods: {
            fetchData() { //获取数据
                let that = this;
                that.filters.startTime =
                    that.date && that.date.length
                        ? formatDate(that.date[0], "yyyy-MM-dd")
                        : "";
                that.filters.endTime =
                    that.date && that.date.length
                        ? formatDate(that.date[1], "yyyy-MM-dd")
                        : "";
                let params = {
                    current: that.filters.current,
                    size: that.filters.size,
                    licensePlate: that.filters.licensePlate,
                    type: that.filters.type,
                    startTime: that.filters.startTime,
                    endTime: that.filters.endTime,
                };
                that.loading = true;
                exceptionHandlingRecord(params).then((res) => {
                    that.loading = false;
                    that.tableData = res.data.data.records;
                    that.total = res.data.data.total;
                    that.filters.current = res.data.data.current;
                    that.filters.size = res.data.data.size;
                }).catch(error => {
                    that.loading = false;
                    console.log(error);
                });
            },
            handleSearch() {
                this.filters.current = 1;
                this.fetchData();
            },
            handleSizeChange(val) {
                this.filters.size = val;
                this.filters.current = 1;
                this.fetchData();
            },
            handleCurrentChange(val) {
                this.filters.current = val;
                this.fetchData();
            },
        }
    }
</script>

<style scoped>
    .el-table th {
        text-align: center;
    }

    .el-table tbody tr td:first-child {
        text-align: center;
    }

    .user-search {
        margin-top: 20px;
    }

</style>
