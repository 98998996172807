<template>
    <div class="indexMain">
        <el-alert v-if="show" style="z-index: 999;height: 40px;"
                  title="网络异常已中断"
                  type="warning"
                  center
                  :closable="false">
        </el-alert>
        <div ref="appRef" id="index">
            <div class="bg">
                <dv-loading v-if="loading">Loading...</dv-loading>
                <div v-else class="host-body">
                    <!-- 第一行 -->
                    <div class="d-flex jc-center">
                        <dv-decoration-10 class="dv-dec-10"/>
                        <div class="d-flex jc-center">
                            <dv-decoration-8 class="dv-dec-8" :color="['#568aea', '#000000']"/>
                            <div class="title">
                                <span class="title-text">智慧停车监控中心</span>
                                <dv-decoration-3
                                        class="dv-dec-6"
                                        :reverse="true"
                                        :color="['#50e3c2', '#67a1e5']"
                                />
                            </div>
                            <dv-decoration-8
                                    class="dv-dec-8"
                                    :reverse="true"
                                    :color="['#568aea', '#000000']"
                            />
                        </div>
                        <dv-decoration-10 class="dv-dec-10-s"/>
                    </div>

                    <!-- 第二行 -->
                    <div class="d-flex jc-between px-2">
                        <div class="d-flex aside-width">
                            <div class="react-left react-l-s">
                                <span class="react-before"></span>
                                <router-link :to="{path:'/parkingLot/homePage'}"><span class="text colorBlue">返回</span>
                                </router-link>
                            </div>
                            <div :class="arrList.length == 1 ? 'react-left ml-3-1 colorBlue' : 'react-left ml-3-1'"
                                 @click="handleClickNum(1,'')">
                                <span class="text">1画面</span>
                            </div>
                            <div :class="arrList.length == 4 && num == 2 ? 'react-left ml-3-1 colorBlue' : 'react-left ml-3-1'"
                                 @click="handleClickNum(4,2)">
                                <span class="text">2画面</span>
                            </div>
                            <div :class="arrList.length == 4 && num != 2 ? 'react-left ml-3-1 colorBlue' : 'react-left ml-3-1'"
                                 @click="handleClickNum(4,'')">
                                <span class="text">4画面</span>
                            </div>
                            <div :class="arrList.length == 9?'react-left ml-3-1 colorBlue' : 'react-left ml-3-1'"
                                 @click="handleClickNum(9,'')">
                                <span class="text">9画面</span>
                            </div>
                        </div>
                        <div class="d-flex aside-width">
                            <div class="react-right mr-33 react-l-ss">
                                <span class="text fw-b">{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span>
                            </div>
                            <div class="react-right mr-33 react-l-sss">
                                <span class="text fw-b colorBlue"
                                      style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{parkingName}}</span>
                            </div>
                            <div class="react-right  react-l-s">
                                <span class="react-after"></span>
                                <span class="text fw-b colorBlue" @click="handleFullScreen">全屏切换</span>
                            </div>
                        </div>
                    </div>
                    <!-- 第三行 -->
                    <div class="body-box">
                        <div class="content-box-left">
                            <div v-if="num != 2"
                                 :class="arrList.length == 9 ? 'left-box3':arrList.length == 4 ? 'left-box2' : arrList.length == 1 ? 'left-box4':''">
                                <div class="left-box" v-for="(item,index) in arrList" :key="index">
                                    <div v-if="columns[index]">
                                        <span class="left-box-tt">{{columns[index].roadName}}</span>
                                        <i class="el-icon-close close-icon" @click="handleDelete(index)"/>
                                        <el-image class="left-box-img"
                                                  :src="columns[index].img?columns[index].img:require('../assets/images/defaultImg.png')"
                                                  :preview-src-list="[columns[index].img?columns[index].img:require('../assets/images/defaultImg.png')]">
                                        </el-image>
                                        <img v-if="columns[index].equipmentModel == 2" :src="columns[index].innerIp"
                                             class="avatar"/>
                                        <iframe v-else-if="columns[index].equipmentModel == 1" ref="iframe"
                                                :src="columns[index].innerIp" class="avatar" scrolling="no"
                                                frameborder="0"></iframe>
                                        <div class="left-box-btm">
                                            <div class="left-box-txt">
                                                <p>{{columns[index].licensePlat}}</p>
                                                <p class="text">
                                                    <span style="color: red;">{{columns[index].carType == 1 ? '临时车' : columns[index].carType == 2 ? '长租车':columns[index].carType == 3 ? '会员车': columns[index].carType == 4 ? '长租过期车': columns[index].carType == 5 ? '长租超员车': columns[index].carType == 6 ? '储值车':columns[index].carType == 7 ? '预约车':''}}</span>
                                                    <span :style="arrList.length == 9 ? 'float: right;' : 'float: right;font-size: 25px;'"
                                                          v-show="columns[index].roadType == 2">{{columns[index].payMoney ? columns[index].payMoney + "元" : '0元'}}</span>
                                                </p>
                                            </div>
                                            <div class="left-box-btn" v-show="columns[index].roadType == 1">
                                                <el-button @click="handleClick1(columns[index].roadId)" type="primary">
                                                    确认放行
                                                </el-button>
                                                <el-button @click="handleClick2(columns[index].roadId)" type="primary">
                                                    手动开闸
                                                </el-button>
                                                <el-button v-show="arrList.length == 1"
                                                           @click="handleClick3(columns[index].roadId)" type="primary">
                                                    重新识别
                                                </el-button>
                                                <el-dropdown v-show="arrList.length != 1" split-button
                                                             @click="handleClick3(columns[index].roadId)"
                                                             type="primary">
                                                    重新识别
                                                    <el-dropdown-menu v-if="columns[index].roadType === 100"
                                                                      slot="dropdown">
                                                        <el-dropdown-item>
                                                            <div @click="handleClick4(columns[index].roadId)">修改车牌</div>
                                                        </el-dropdown-item>
                                                    </el-dropdown-menu>
                                                </el-dropdown>
                                            </div>
                                            <div class="left-box-btn" v-show="columns[index].roadType == 2">
                                                <el-button @click="handleClick1(columns[index].roadId)" type="primary">
                                                    免费放行
                                                </el-button>
                                                <el-button @click="handleClick2(columns[index].roadId)" type="primary">
                                                    手动开闸
                                                </el-button>
                                                <el-button v-show="arrList.length == 1"
                                                           @click="handleClick5(columns[index].roadId)" type="primary">
                                                    现金开闸
                                                </el-button>
                                                <el-button v-show="arrList.length == 1"
                                                           @click="handleClick3(columns[index].roadId)" type="primary">
                                                    重新识别
                                                </el-button>
                                                <el-button v-show="arrList.length == 1"
                                                           @click="handleClick6(columns[index].roadId)" type="primary">
                                                    手动匹配
                                                </el-button>
                                                <el-dropdown v-show="arrList.length != 1" split-button type="primary"
                                                             @click="handleClick5(columns[index].roadId)">
                                                    现金开闸
                                                    <el-dropdown-menu slot="dropdown">
                                                        <el-dropdown-item>
                                                            <div @click="handleClick3(columns[index].roadId)">重新识别</div>
                                                        </el-dropdown-item>
                                                        <el-dropdown-item>
                                                            <div @click="handleClick6(columns[index].roadId)">手动匹配</div>
                                                        </el-dropdown-item>
                                                    </el-dropdown-menu>
                                                </el-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <i v-else class="el-icon-plus left-box-icon"
                                       @click="handleClickdialog(index)"></i>
                                </div>
                            </div>
                            <div v-else-if="num == 2" class="left-box1">
                                <div class="left-box boxWH" v-for="(item,index) in twoGrid" :key="index + '-only'">
                                    <div v-if="item">
                                        <span class="left-box-tt">{{item.roadName}}</span>
                                        <i class="el-icon-close close-icon" @click="handleDelete(index)"/>
                                        <img v-if="item.equipmentModel == 2" :src="item.innerIp"
                                             class="avatar"/>
                                        <iframe v-else-if="item.equipmentModel == 1" ref="iframe"
                                                :src="item.innerIp" class="avatar" scrolling="no"
                                                frameborder="0"></iframe>
                                        <div class="left-box-btm">
                                            <div class="left-box-txt">
                                                <p>{{item.licensePlat}}</p>
                                                <p class="text">
                                                    <span style="color: red;">{{item.carType == 1 ? '临时车' : item.carType == 2 ? '长租车':item.carType == 3 ? '会员车': item.carType == 4 ? '长租过期车': item.carType == 5 ? '长租超员车': item.carType == 6 ? '储值车':item.carType == 7 ? '预约车':''}}</span>
                                                    <span style="float: right;font-size: 25px;"
                                                          v-show="item.roadType == 2">{{item.payMoney ? item.payMoney + "元" : '0元'}}</span>
                                                </p>
                                            </div>
                                            <div class="left-box-btn" v-show="item.roadType == 1">
                                                <el-button @click="handleClick1(item.roadId)" type="primary">
                                                    确认放行
                                                </el-button>
                                                <el-button @click="handleClick2(item.roadId)" type="primary">
                                                    手动开闸
                                                </el-button>
                                                <el-dropdown split-button @click="handleClick3(item.roadId)"
                                                             type="primary">
                                                    重新识别
                                                    <el-dropdown-menu v-if="item.roadType == 100" slot="dropdown">
                                                        <el-dropdown-item>
                                                            <div @click="handleClick4(item.roadId)">修改车牌</div>
                                                        </el-dropdown-item>
                                                    </el-dropdown-menu>
                                                </el-dropdown>
                                            </div>
                                            <div class="left-box-btn" v-show="item.roadType == 2">
                                                <el-button @click="handleClick1(item.roadId)" type="primary">
                                                    免费放行
                                                </el-button>
                                                <el-button @click="handleClick2(item.roadId)" type="primary">
                                                    手动开闸
                                                </el-button>
                                                <el-dropdown split-button type="primary"
                                                             @click="handleClick5(item.roadId)">
                                                    现金开闸
                                                    <el-dropdown-menu slot="dropdown">
                                                        <el-dropdown-item>
                                                            <div @click="handleClick3(item.roadId)">重新识别</div>
                                                        </el-dropdown-item>
                                                        <el-dropdown-item>
                                                            <div @click="handleClick6(item.roadId)">手动匹配</div>
                                                        </el-dropdown-item>
                                                    </el-dropdown-menu>
                                                </el-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <i v-else class="el-icon-plus left-box-icon" @click="handleClickdialog(index)"></i>
                                </div>
                                <div class="left-box" v-for="(item,index) in twoGrid" :key="index">
                                    <div v-if="item">
                                        <el-image class="avatar-img"
                                                  :src="item.img?item.img:require('../assets/images/defaultImg.png')"
                                                  :preview-src-list="[item.img?item.img:require('../assets/images/defaultImg.png')]">
                                        </el-image>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content-box-right">
                            <div class="right_box1">
                                <dv-border-box-12 style="padding-top: 10px">
                                    <p class="right-div-title"><i class="el-icon-tickets"/>过车收费信息 </p>
                                    <div class="right-div-txtt">
                                        <span>车牌号码：{{roadInfo ? roadInfo.licensePlat : ''}}</span>
                                        <span>通道名称：{{roadInfo ? roadInfo.roadName : ''}}</span>
                                        <span>车辆类型：{{roadInfo.carType == 1 ? "临停车" : roadInfo.carType == 2 ? "长租车" : roadInfo.carType == 3 ? "会员车"
                                                : roadInfo.carType == 4 || roadInfo.carType == 8 ? "长租过期车" : roadInfo.carType == 5 ? "长租超员车" : roadInfo.carType == 6 ? "储值车" : roadInfo.carType == 7 ? "预约车" : ""}}</span>
                                        <span>优惠折扣：{{roadInfo ? roadInfo.discount ? roadInfo.discount + '元' : '0元' : ''}}</span>
                                        <span>停车实缴：{{roadInfo ? roadInfo.payMoney ? roadInfo.payMoney + '元' : '0元' : ''}}</span>
                                        <span>支付状态：{{roadInfo ? roadInfo.paid == true && roadInfo.payMoney != null ? '已支付' : roadInfo.paid == false && roadInfo.payMoney != null ? '未支付' : '' : ''}}</span>
                                        <span>入场时间：{{roadInfo ? roadInfo.entranceTime : ''}}</span>
                                        <p>状态显示：{{roadInfo ? roadInfo.doorwayCarDesc : ''}}</p>
                                    </div>
                                </dv-border-box-12>
                            </div>
                            <div class="right_box2">
                                <dv-border-box-12 style="padding-top: 10px">
                                    <p class="right-div-title"><i class="el-icon-s-custom"/>当班信息</p>
                                    <div :style="{ display: 'flex',flexDirection: 'row',}">
                                        <div class="right-div-image">
                                            <el-image :src="require('../assets/images/24341.png')"/>
                                        </div>
                                        <div class="right-div-font">
                                            <span>用户名称：{{userName ? userName : ''}}</span>
                                            <span>当班人员：{{realName ? realName : ''}}</span>
                                            <span>上岗时间：{{lastLoginTime ? lastLoginTime : ''}}</span>
                                            <el-button style="width: 100px;" @click="handleShiftChange" size="small"
                                                       type="primary">换班
                                            </el-button>
                                        </div>
                                    </div>
                                </dv-border-box-12>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <el-dialog v-draggable title="选择设备" :visible.sync="dialogTableVisible" append-to-body top="4vh">
                <el-table :data="gridData">
                    <el-table-column property="roadName" align="center" label="道口名称"></el-table-column>
                    <el-table-column property="equipmentName" align="center" label="设备名称"></el-table-column>
                    <el-table-column property="type" align="center" label="状态" :formatter="getStatus"></el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <el-button @click="handleClickInfo(scope.row)" type="primary" size="small">选择
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-dialog>
            <el-dialog v-draggable title="换班" :visible.sync="dialogFormVisible" width="30%" append-to-body>
                <el-form ref="form" :model="form" size="small" :rules="rules">
                    <el-row>
                        <el-col :span="20">
                            <el-form-item prop="username" label="账号" :label-width="formLabelWidth">
                                <el-select style="width: 100%;" allow-create filterable v-model="form.username"
                                           clearable placeholder="请选择账号"
                                           :popper-append-to-body="false" popper-class="select-popper">
                                    <el-option v-for="item in usernameList" :key="item" :label="item"
                                               :value="item"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item prop="pwd" label="密码" :label-width="formLabelWidth">
                                <el-input v-model="form.pwd" autocomplete="no"
                                          placeholder="请输入密码" show-password></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                <div style="display: flex;flex-direction: column;line-height: 30px">
                    <p style="display: flex;justify-content: space-around;align-items: flex-end">
                        <span>收费总额：{{totalIncome}}</span>
                        <span>免费总额：{{discountIncome}}</span>
                    </p>
                    <p style="display: flex;justify-content: space-around;align-items: flex-end">
                        <span>线上总额：{{onlineIncome}}</span>
                        <span>现金总额：{{cashIncome}}</span>
                    </p>
                </div>
                <div slot="footer" class="dialog-footer">
                    <el-button size="small" @click="dialogFormVisible = false">取 消</el-button>
                    <el-button size="small" type="primary" @click="submitForm('form')">确 定</el-button>
                </div>
            </el-dialog>
            <el-dialog v-draggable title="手动匹配" :visible.sync="dialogVisible" width="60%" append-to-body>
                <el-form :inline="true" :model="filters" class="user-search">
                    <el-form-item>
                        <el-input size="small" v-model="filters.licensePlate" placeholder="请输入车牌号码"
                                  clearable></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-date-picker
                                size="small"
                                v-model="filters.date"
                                type="datetimerange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button icon="el-icon-search" type="primary" size="small" @click="handleSearch">查询
                        </el-button>
                    </el-form-item>
                </el-form>
                <el-table :data="dataList" size="small" highlight-current-row style="width: 100%"
                          :row-style="{height:'45px'}"
                          :cell-style="{padding:'0px'}"
                          :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                          stripe height="350" max-height="350">
                    <el-table-column property="licensePlate" label="车牌号" align="center"></el-table-column>
                    <el-table-column prop="parkingType" label="停车类型" align="center">
                        <template slot-scope="scope">
                            <el-tag type="primary" v-if="scope.row.parkingType == 1">临停</el-tag>
                            <el-tag type="success" v-else-if="scope.row.parkingType == 2">长租</el-tag>
                            <el-tag type="warning" v-else-if="scope.row.parkingType == 3">会员</el-tag>
                            <el-tag type="info" v-else-if="scope.row.parkingType == 4 || scope.row.parkingType == 8">
                                长租过期
                            </el-tag>
                            <el-tag type="danger" v-else-if="scope.row.parkingType == 5">长租超员</el-tag>
                            <el-tag type="danger" v-else-if="scope.row.parkingType == 6">储值</el-tag>
                            <el-tag type="danger" v-else-if="scope.row.parkingType == 7">预约</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="entranceRoadName" label="入口车道" align="center"></el-table-column>
                    <el-table-column prop="entranceImageUrl" label="入场图片" align="center">
                        <template slot-scope="scope">
                            <el-image
                                    style="width: 50px; height: 50px"
                                    :src="scope.row.entranceImageUrl?scope.row.entranceImageUrl:require('../assets/images/defaultImg.png')"
                                    :preview-src-list="[scope.row.entranceImageUrl?scope.row.entranceImageUrl:require('../assets/images/defaultImg.png')]">
                                <div slot="error">
                                    <el-image :src="require('../assets/images/defaultImg.png')">
                                    </el-image>
                                </div>
                            </el-image>
                        </template>
                    </el-table-column>
                    <el-table-column property="entranceTime" label="入场时间" align="center"></el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <el-button type="success" size="mini" @click="handleMatching(scope.row)">匹配</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background
                               @size-change="handleSizeChange"
                               @current-change="handleCurrentChange"
                               :current-page="filters.current"
                               :page-sizes="[10, 50, 200, 1000]"
                               :page-size="filters.size"
                               layout="total, sizes, prev, pager, next, jumper"
                               :total="total">
                </el-pagination>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import {formatTime} from '@/utils/index.js';
    import {equipmentList} from "../api/home";
    import {getCurrentParking, getCurrentProject} from "../api/login";
    import {
        chargeOpen,
        customerSwitch,
        open, parkingAdminPassInfoList, parkingAdminPresentCar,
        parkingAdminStatistics,
        parkingAreaRoadInfo,
        pass, photograph, presentCarMatch
    } from "../api/bigscreen";
    import drawMixin from "@/utils/drawMixin";
    import {formatDate, isNumber} from "../utils";

    export default {
        name: "monitorPreview",
        mixins: [drawMixin],
        data() {
            return {
                timing: null,
                dateDay: null,
                dateYear: null,
                dateWeek: null,
                weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
                loading: true,
                img: "",
                columns: [],
                boxIndex: "",
                num: "1",
                arrList: new Array(4),
                dialogTableVisible: false,
                gridData: [],
                parkingName: "",
                parkingId: "",
                roadInfo: {},
                infoList: [],
                mytimer: "",
                dialogFormVisible: false,
                form: {
                    username: '',
                    pwd: '',
                },
                rules: {
                    username: [{required: true, message: '请输入账号', trigger: 'blur'}],
                    pwd: [{required: true, message: '请输入密码', trigger: 'blur'}]
                },
                formLabelWidth: '120px',
                totalIncome: '',
                discountIncome: '',
                onlineIncome: '',
                cashIncome: '',
                twoGrid: new Array(2),
                dialogVisible: false,
                dataList: [],
                total: 0,
                filters: {
                    current: 1,
                    size: 5,
                    licensePlate: "",
                    timeStart: "",
                    timeEnd: "",
                    date: []
                },
                userName: "",
                realName: "",
                lastLoginTime: "",
                entranceRoadId: "",
                usernameList: [],

                show: false,
                onLine: navigator.onLine,
                fullscreen: false
            }
        },
        created() {
            this.twoGrid = JSON.parse(localStorage.getItem("twoGrid")) || new Array(2);
            this.columns = JSON.parse(localStorage.getItem("columns")) || [];
            this.arrList = JSON.parse(localStorage.getItem("arrList")) || new Array(4);
            this.num = localStorage.getItem("num") || this.num;
        },
        mounted() {
            this.cancelLoading();
            this.timeFn();
            this.getCurrentParking();
            this.testing();
            let user = window.localStorage.getItem('userdata');
            if (user) {
                user = JSON.parse(user);
                this.userName = user.username || '';
                this.realName = user.realName || '';
                this.lastLoginTime = user.lastLoginTime || '';
                this.usernameList = user.usernameList || [];
            }
            window.addEventListener('online', this.updateOnlineStatus);
            window.addEventListener('offline', this.updateOnlineStatus);
        },
        beforeDestroy() {
            clearInterval(this.timing);
            clearTimeout(this.mytimer);
            window.removeEventListener('online', this.updateOnlineStatus);
            window.removeEventListener('offline', this.updateOnlineStatus);
        },
        methods: {
            // 全屏事件
            handleFullScreen() {
                let element = document.documentElement;
                // 判断是否已经是全屏
                // 如果是全屏，退出
                if (this.fullscreen) {
                    if (document.exitFullscreen) {
                        document.exitFullscreen();
                    } else if (document.webkitCancelFullScreen) {
                        document.webkitCancelFullScreen();
                    } else if (document.mozCancelFullScreen) {
                        document.mozCancelFullScreen();
                    } else if (document.msExitFullscreen) {
                        document.msExitFullscreen();
                    }
                    console.log("已还原！");
                } else {
                    // 否则，进入全屏
                    if (element.requestFullscreen) {
                        element.requestFullscreen();
                    } else if (element.webkitRequestFullScreen) {
                        element.webkitRequestFullScreen();
                    } else if (element.mozRequestFullScreen) {
                        element.mozRequestFullScreen();
                    } else if (element.msRequestFullscreen) {
                        // IE11
                        element.msRequestFullscreen();
                    }
                    console.log("已全屏！");
                }
                // 改变当前全屏状态
                this.fullscreen = !this.fullscreen;
            },
            updateOnlineStatus(e) {
                const {type} = e;
                if (type === 'online') {
                    this.testing();
                    this.show = false;
                } else if (type === 'offline') {
                    this.show = true;
                }
                this.twoGrid = JSON.parse(localStorage.getItem("twoGrid")) || new Array(2);
                this.columns = JSON.parse(localStorage.getItem("columns")) || [];
                this.arrList = JSON.parse(localStorage.getItem("arrList")) || new Array(4);
                this.num = localStorage.getItem("num") || this.num;
            },
            testing() {
                var that = this;
                let temp = [];
                that.columns.map(item => {
                    if (item)
                        if (item.roadId) {
                            temp.push(item);
                        }
                });
                clearTimeout(that.mytimer);
                // 调用轮询
                if (temp.length) {
                    that.getParkingAreaRoadInfo(temp.map(item => item.roadId));
                }

                this.columns.forEach(item => {
                    if (item) {
                        const matched = this.infoList.find(({roadId}) => roadId === item.roadId);

                        if (matched) {
                            Object.assign(item, {licensePlat: matched.licensePlat},
                                {img: matched.img},
                                {payMoney: isNumber(matched.payMoney)},
                                {carType: matched.carType});
                        }
                    }
                });
                localStorage.setItem("columns", JSON.stringify(this.columns));
                if (this.num == 2) {
                    this.twoGrid.forEach(item => {
                        if (item) {
                            const matched = this.infoList.find(({roadId}) => roadId === item.roadId);

                            if (matched) {
                                Object.assign(item, {licensePlat: matched.licensePlat},
                                    {img: matched.img},
                                    {payMoney: isNumber(matched.payMoney)},
                                    {carType: matched.carType});
                            }
                        }
                    });
                    localStorage.setItem("twoGrid", JSON.stringify(this.twoGrid));
                }
            },
            timeFn() {
                this.timing = setInterval(() => {
                    this.dateDay = formatTime(new Date(), 'HH: mm: ss');
                    this.dateYear = formatTime(new Date(), 'yyyy年MM月dd日');
                    this.dateWeek = this.weekday[new Date().getDay()];
                }, 1000);
            },
            cancelLoading() {
                setTimeout(() => {
                    this.loading = false;
                }, 500);
            },
            getCurrentParking() {
                getCurrentParking().then((res) => {
                    this.parkingId = res.data.data.id;
                    this.parkingName = res.data.data.parkingName;
                })
            },
            getEquipmentList() {
                let param = {
                    parkingId: this.parkingId,
                    online: 1,
                    equipmentType: 'PASSAGE_CAMERA'
                }
                equipmentList(param).then(res => {
                    this.gridData = res.data.data;
                })
            },
            handleClickNum(value, num) {
                this.arrList = new Array(value);
                this.num = num;
                localStorage.setItem("arrList", JSON.stringify(new Array(value)));
                localStorage.setItem("num", num);
                this.twoGrid = JSON.parse(localStorage.getItem("twoGrid")) || new Array(2);
            },
            handleClickdialog(index) {
                this.dialogTableVisible = true;
                this.boxIndex = index;
                this.getEquipmentList();
            },
            getStatus(row) {
                let type = "";
                this.columns.forEach((item, index) => {
                    if (item)
                        if (item.id == row.id) {
                            type = '已添加';
                        }
                });
                return type;
            },
            handleClickInfo: function (row) {
                this.columns.forEach((item, index) => {
                    if (item)
                        if (item.id == row.id) {
                            // this.columns.splice(index, 1)
                            this.$set(this.columns, index, "");
                        }
                });
                this.twoGrid.forEach((item, index) => {
                    if (item)
                        if (item.id == row.id) {
                            this.$set(this.twoGrid, index, "");
                        }
                });
                // this.$set(row, 'equipmentModel', 1);
                if (row.equipmentModel == 2) {
                    this.$set(row, 'innerIp', 'http://' + row.innerIp + ':8088/cgi-bin/video_cgi');
                    // console.log('芊熠', row.innerIp)
                } else if (row.equipmentModel == 1) {
                    // console.log('臻识', row.innerIp)
                    this.$set(row, 'innerIp', "static/mgpeg_login.html?innerIp=" + row.innerIp + ":9080");
                }
                parkingAdminPassInfoList(row.roadId).then(res => {
                        if (res.data.data.last) {
                            this.roadInfo = res.data.data.last;
                            this.roadInfo.payMoney = isNumber(this.roadInfo.payMoney);
                            this.roadInfo.discount = isNumber(this.roadInfo.discount);
                        }
                        if (res.data.data.list) {
                            this.infoList = res.data.data.list;
                            const matched = this.infoList.find(({roadId}) => roadId === row.roadId);
                            // console.log(matched, 'matched');
                            if (matched) {
                                Object.assign(row, {licensePlat: matched.licensePlat},
                                    {img: matched.img},
                                    {payMoney: isNumber(matched.payMoney)},
                                    {carType: matched.carType});
                            }
                        }
                        this.$set(this.columns, this.boxIndex, row);
                        localStorage.setItem("columns", JSON.stringify(this.columns));
                        if (this.boxIndex < 2) {
                            this.$set(this.twoGrid, this.boxIndex, row);
                            localStorage.setItem("twoGrid", JSON.stringify(this.twoGrid));
                        }
                        this.testing();
                        this.dialogTableVisible = false;
                    }
                );
                // clearTimeout(this.mytimer);
                // var _this = this;
                // setTimeout(function () {
                // }, 1000);
            },
            getParkingAreaRoadInfo(roadIdList) {
                var _this = this;
                parkingAdminPassInfoList(roadIdList).then(res => {
                        if (res.data.data.last) {
                            _this.roadInfo = res.data.data.last;
                            _this.roadInfo.payMoney = isNumber(_this.roadInfo.payMoney);
                            _this.roadInfo.discount = isNumber(_this.roadInfo.discount);
                        }
                        if (res.data.data.list) {
                            _this.infoList = res.data.data.list;
                        }
                        _this.mytimer = setTimeout(() => {
                            clearTimeout(_this.mytimer);
                            _this.testing();
                            // this.getParkingAdminStatistics();
                        }, 3000);
                    }
                )
            },
            handleDelete(index) {
                if (index !== -1) {
                    this.$set(this.columns, index, "");
                    localStorage.setItem("columns", JSON.stringify(this.columns));
                    if (index === 0 || index === 1) {
                        this.$set(this.twoGrid, index, null);
                        localStorage.setItem("twoGrid", JSON.stringify(this.twoGrid));
                    }
                }
            },
            handleShiftChange() {
                this.dialogFormVisible = true;
                Object.keys(this.form).map(key => {
                    this.form[key] = "";
                });
                this.$refs['form'].resetFields();
                this.getParkingAdminStatistics();
            },
            getParkingAdminStatistics() {
                parkingAdminStatistics(this.lastLoginTime).then(res => {
                    this.totalIncome = res.data.data.totalIncome;
                    this.discountIncome = res.data.data.discountIncome;
                    this.onlineIncome = res.data.data.onlineIncome;
                    this.cashIncome = res.data.data.cashIncome;
                })
            },
            submitForm(formName) {
                this.$refs[formName].validate(valid => {
                    if (valid) {
                        customerSwitch(this.form).then(res => {
                            this.dialogFormVisible = false;
                            if (res.data.code == "SUCCESS") {
                                localStorage.setItem('token', res.data.data.token);
                                localStorage.setItem('userdata', JSON.stringify(res.data.data.customerDTO));
                                this.userName = res.data.data.customerDTO.username;
                                this.realName = res.data.data.customerDTO.realName;
                                this.lastLoginTime = res.data.data.customerDTO.lastLoginTime;
                                this.usernameList = res.data.data.customerDTO.usernameList;
                                getCurrentProject().then((res) => {
                                    // this.$message.success("登录成功！");
                                    window.localStorage.setItem('projectName', res.data.data.projectName);
                                    window.localStorage.setItem('projectId', res.data.data.id);
                                    let projectType = res.data.data.projectType;
                                    this.projectType = [];
                                    projectType.forEach((e) => {
                                        this.projectType.push(e.type);
                                        if (e.type == 1) {
                                            window.localStorage.setItem('subSysId', e.subSysId);
                                        }
                                    })
                                    window.localStorage.setItem('projectType1', this.projectType.filter(item => item === 1).toString());
                                    window.localStorage.setItem('projectType2', this.projectType.filter(item => item === 2).toString());
                                })
                            } else {
                                this.$message.success(res.data.message);
                            }
                        })
                    }
                })
            },
            //确认放行、免费放行
            handleClick1(roadId) {
                pass(roadId).then((res) => {
                    if (res.data.code === "SUCCESS") {
                        this.$message.success("开闸" + res.data.message);
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
            },
            //手动开闸、直接开闸
            handleClick2(roadId) {
                open(roadId).then((res) => {
                    if (res.data.code === "SUCCESS") {
                        this.$message.success("开闸" + res.data.message);
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
            },
            //重新识别
            handleClick3(roadId) {
                photograph(roadId).then((res) => {
                    if (res.data.code === "SUCCESS") {
                        this.$message.success("重新识别" + res.data.message);
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
            },
            //修改车牌
            handleClick4(roadId) {
                // this.$prompt('请输入车牌号', '相机IP', {
                //     confirmButtonText: '确定',
                //     cancelButtonText: '取消',
                //     inputPlaceholder: "请输入",
                //     inputValidator: (value) => {
                //         // 点击按钮时，对文本框里面的值进行验证
                //         if (!value) {
                //             return '车牌号不能为空';
                //         }
                //     },
                // }).then(({value}) => {
                // })
            },
            //现金开闸
            handleClick5(roadId) {
                chargeOpen(roadId).then((res) => {
                    if (res.data.code === "SUCCESS") {
                        this.$message.success("开闸" + res.data.message);
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
            },
            //手动匹配
            handleClick6(roadId) {
                this.dialogVisible = true;
                this.entranceRoadId = roadId;
                this.getParkingAdminPresentCar();
                this.filters.licensePlate = "";
                this.filters.date = [];
            },
            getParkingAdminPresentCar() {
                this.filters.timeStart =
                    this.date && this.date.length
                        ? formatDate(this.date[0], "yyyy-MM-dd HH:mm:ss")
                        : "";
                this.filters.timeEnd =
                    this.date && this.date.length
                        ? formatDate(this.date[1], "yyyy-MM-dd HH:mm:ss")
                        : "";
                parkingAdminPresentCar(this.filters).then((res) => {
                    if (res.data.code === "SUCCESS") {
                        this.dataList = res.data.data.records;
                        this.total = res.data.data.total;
                        this.filters.current = res.data.data.current;
                        this.filters.size = res.data.data.size;
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
            },
            handleSearch() {
                this.filters.current = 1;
                this.getParkingAdminPresentCar();
            },
            handleSizeChange(val) {
                this.filters.size = val;
                this.filters.current = 1;
                this.getParkingAdminPresentCar();
            },
            handleCurrentChange(val) {
                this.filters.current = val;
                this.getParkingAdminPresentCar();
            },
            handleMatching(row) {
                let param = {
                    presentCarId: row.id,
                    roadId: this.entranceRoadId
                };
                presentCarMatch(param).then((res) => {
                    this.dialogVisible = false;
                    if (res.data.code === "SUCCESS") {
                        this.$message.success(res.data.message);
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
            },
        }
    }
</script>
<style lang="scss" scoped>
    .indexMain {
        width: 100%;
        height: 100%;
        background-color: #020308;
        /*overflow: hidden;*/
    }

    #index {
        color: #d3d6dd;
        width: 1920px;
        height: 1080px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transform-origin: left top;
        overflow: hidden;

        .bg {
            width: 100%;
            height: 100%;
            padding: 16px 16px 0 16px;
            background-image: url("../assets/pageBg.png");
            background-size: cover;
            background-position: center center;
        }

        .host-body {
            .dv-dec-10,
            .dv-dec-10-s {
                width: 33.3%;
                height: 5px;
            }

            .dv-dec-10-s {
                transform: rotateY(180deg);
            }

            .dv-dec-8 {
                width: 200px;
                height: 50px;
            }

            .title {
                position: relative;
                width: 300px;
                text-align: center;
                background-size: cover;
                background-repeat: no-repeat;

                .title-text {
                    font-size: 24px;
                    position: absolute;
                    width: 100%;
                    bottom: 40%;
                    left: 50%;
                    transform: translate(-50%);
                }

                .dv-dec-6 {
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    width: 250px;
                    height: 10px;
                    transform: translate(-50%);
                }
            }

            // 第二行
            .aside-width {
                width: 40%;
            }

            .react-r-s,
            .react-l-s,
            .react-l-ss,
            .react-l-sss {
                background-color: #0f1325;
            }

            // 平行四边形
            .react-right {
                &.react-l-s {
                    text-align: right;
                    width: 180px;
                    cursor: pointer;
                }

                &.react-l-ss {
                    /*text-align: right;*/
                    width: 500px;
                }

                &.react-l-sss {
                    text-align: center;
                    width: 250px;
                }

                &.mr-33 {
                    margin-right: 25px;
                }

                font-size: 20px;
                width: 300px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                transform: skewX(-45deg);

                .react-after {
                    position: absolute;
                    right: -20px;
                    top: 0;
                    height: 40px;
                    width: 40px;
                    background-color: #0f1325;
                    transform: skewX(45deg);
                }

                .text {
                    display: inline-block;
                    transform: skewX(45deg);
                }
            }

            .react-left {
                &.react-l-s {
                    width: 200px;
                    text-align: left;
                }

                &.ml-3-1 {
                    margin-left: 25px;
                }

                font-size: 18px;
                width: 300px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                transform: skewX(45deg);
                background-color: #0f1325;
                cursor: pointer;

                .react-before {
                    position: absolute;
                    left: -25px;
                    top: 0;
                    height: 40px;
                    width: 40px;
                    background-color: #0f1325;
                    transform: skewX(-45deg);
                }

                .text {
                    display: inline-block;
                    transform: skewX(-45deg);
                }
            }

            .body-box {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
                height: 100%;
                margin-top: 10px;
                /*下方区域的布局*/
                .content-box-left {
                    width: 70%;

                    .left-box {
                        border: 2px dashed #d9d9d97a;
                        border-radius: 6px;
                        cursor: pointer;
                        position: relative;
                        overflow: hidden;
                    }

                    .left-box:hover {
                        border-color: #409EFF;
                    }

                    .left-box-icon {
                        font-size: 50px;
                        color: #8c939d;
                        width: 178px;
                        height: 178px;
                        line-height: 178px;
                        text-align: center;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        transform-origin: left top;
                    }

                    .close-icon {
                        font-size: 30px;
                        color: #ffffff;
                        position: absolute;
                        right: 1%;
                        top: 1%;
                        z-index: 999;
                    }

                    .left-box-tt {
                        position: absolute;
                        top: 5%;
                        left: 50%;
                        font-size: 22px;
                    }

                    .left-box2 .left-box-img {
                        position: absolute;
                        top: 10%;
                        left: 3%;
                        width: 150px;
                        height: 150px;
                    }

                    .left-box3 .left-box-img {
                        position: absolute;
                        top: 10%;
                        left: 3%;
                        width: 100px;
                        height: 100px;
                    }

                    .left-box4 .left-box-img {
                        position: absolute;
                        top: 10%;
                        left: 3%;
                        width: 200px;
                        height: 200px;
                    }

                    .left-box1 {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: space-between;
                    }

                    .left-box1 > div {
                        width: 49.6%;
                        height: 49.6%;
                        border-radius: 8px;
                        font-size: 20px;
                    }

                    .left-box1 .boxWH {
                        width: 49.6%;
                        height: 50%;
                        margin-bottom: 8px;
                    }

                    .left-box1 .avatar {
                        width: 100%;
                        height: 400px;
                    }

                    .left-box1 .avatar-img {
                        width: 100%;
                        height: 465px;
                        padding: 10px;
                    }

                    .left-box1 .left-box-btm {
                        display: flex;
                        justify-content: space-between;
                        margin: 10px 0 5px;
                        width: 100%;

                        .left-box-txt {
                            display: flex;
                            flex-direction: column;
                            width: 32%;
                            font-size: 25px;
                            /*line-height: 22px;*/
                            padding-left: 8px;
                        }

                        .left-box-txt > p {
                            word-wrap: break-word;
                            word-break: break-all;
                            padding-top: 8px;
                        }

                        .left-box-txt .text {
                            font-size: 18px;
                            margin-top: 9px;
                        }

                        .left-box-btn {
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                            width: 65%;
                        }

                        .left-box-btn .el-button {
                            font-size: 18px !important;
                            /*padding: 10px !important;*/
                            border-radius: unset !important;
                        }

                        .left-box-btn /deep/ .el-button-group {
                            margin-left: 10px;

                            .el-button {
                                font-size: 18px !important;
                                /*padding: 10px !important;*/
                                border-radius: unset !important;
                            }
                        }

                    }

                    .left-box2 {
                        display: grid;
                        width: 100%;
                        height: 100%;
                        grid-template-rows: repeat(2, 1fr);
                        grid-template-columns: repeat(2, 1fr);
                        grid-gap: .8%;
                        grid-auto-flow: row;
                    }

                    .left-box2 > div {
                        border-radius: 8px;
                        font-size: 20px;
                    }

                    .left-box2 .avatar {
                        width: 100%;
                        height: 390px;
                    }

                    .left-box2 .left-box-btm {
                        display: flex;
                        justify-content: space-between;
                        margin: 10px 0 5px;
                        width: 100%;

                        .left-box-txt {
                            display: flex;
                            flex-direction: column;
                            width: 32%;
                            font-size: 25px;
                            /*line-height: 22px;*/
                            padding-left: 8px;
                        }

                        .left-box-txt > p {
                            word-wrap: break-word;
                            word-break: break-all;
                            padding-top: 8px;
                        }

                        .left-box-txt .text {
                            font-size: 18px;
                            margin-top: 9px;
                        }

                        .left-box-btn {
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                            width: 65%;
                        }

                        .left-box-btn .el-button {
                            font-size: 18px !important;
                            /*padding: 10px !important;*/
                            border-radius: unset !important;
                        }

                        .left-box-btn /deep/ .el-button-group {
                            margin-left: 10px;

                            .el-button {
                                font-size: 18px !important;
                                /*padding: 10px !important;*/
                                border-radius: unset !important;
                            }
                        }

                    }

                    .left-box3 {
                        display: grid;
                        width: 100%;
                        height: 100%;
                        grid-template-rows: repeat(3, 1fr);
                        grid-template-columns: repeat(3, 1fr);
                        grid-gap: .8%;
                        grid-auto-flow: row;
                    }

                    .left-box3 > div {
                        border-radius: 8px;
                        font-size: 20px;
                    }

                    .left-box3 .avatar {
                        width: 100%;
                        height: 240px;
                    }

                    .left-box3 .left-box-btm {
                        display: flex;
                        justify-content: space-between;
                        margin: 10px 0;
                        width: 100%;

                        .left-box-txt {
                            display: flex;
                            flex-direction: column;
                            width: 32%;
                            font-size: 20px;
                            /*line-height: 20px;*/
                            padding-left: 8px;
                        }

                        .left-box-txt > p {
                            word-wrap: break-word;
                            word-break: break-all;
                            padding-top: 8px;
                        }

                        .left-box-txt .text {
                            font-size: 18px;
                            margin-top: 7px;
                        }

                        .left-box-btn {
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                            width: 70%;
                        }

                        .left-box-btn .el-button {
                            font-size: 14px !important;
                            padding: 10px !important;
                            border-radius: unset !important;
                        }

                        .left-box-btn /deep/ .el-button-group {
                            margin-left: 10px;

                            .el-button {
                                font-size: 14px !important;
                                padding: 10px !important;
                                border-radius: unset !important;
                            }
                        }
                    }

                    .left-box4 {
                        display: grid;
                        width: 100%;
                        height: 100%;
                        grid-template-rows: repeat(1, 1fr);
                        grid-template-columns: repeat(1, 1fr);
                        grid-auto-flow: row;
                    }

                    .left-box4 > div {
                        border-radius: 8px;
                        font-size: 20px;
                    }

                    .left-box4 .avatar {
                        width: 100%;
                        height: 860px;
                    }

                    .left-box4 .left-box-btm {
                        display: flex;
                        justify-content: space-between;
                        padding: 15px;
                        width: 100%;

                        .left-box-txt {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            width: 35%;
                            font-size: 32px;
                            padding-right: 50px;
                        }

                        .left-box-txt .text {
                            font-size: 26px;
                            display: flex;
                            flex-direction: column;

                            span:first-child {
                                margin-bottom: 25px;
                            }
                        }

                        .left-box-btn {
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                            width: 45%;
                        }

                        .left-box-btn .el-button {
                            font-size: 20px !important;
                            border-radius: unset !important;
                        }

                        .left-box-btn /deep/ .el-button-group {
                            margin-left: 10px;

                            .el-button {
                                font-size: 20px !important;
                                border-radius: unset !important;
                            }
                        }
                    }

                }


                .content-box-right {
                    width: 29.5%;

                    .right_box1 {
                        height: 650px;
                        width: 100%;
                        position: relative;
                    }

                    .right_box2 {
                        height: 300px;
                        width: 100%;
                        position: relative;
                    }

                    .right-div-title {
                        margin: 15px 15px 0 15px;
                        font-size: 22px;
                    }

                    .right-div-txt {
                        display: flex;
                        flex-direction: column;
                        color: #ffffff;
                        font-size: 20px;
                        margin: 0 30px;
                    }

                    .right-div-txtt {
                        display: flex;
                        flex-direction: column;
                        color: #ffffff;
                        font-size: 20px;
                        margin: 0 30px;
                    }

                    .right-div-txt > span {
                        padding: 35px 0;
                    }

                    .right-div-txtt > span {
                        padding: 29px 0;
                    }

                    .right-div-txt > p {
                        margin-top: 15px;
                        overflow-wrap: break-word;
                        word-break: break-all;
                        height: 65px;
                        overflow: auto;
                        line-height: 35px
                    }

                    .right-div-txtt > p {
                        margin-top: 15px;
                        overflow-wrap: break-word;
                        word-break: break-all;
                        height: 65px;
                        overflow: auto;
                        line-height: 35px
                    }

                    .right-div-image {
                        width: 160px;
                        height: 200px;
                        margin: 0 30px;
                        border: 1px solid #dfdfdf;
                        padding: 20px 0 0 8px;
                        border-radius: 15%;
                        position: absolute;
                        top: 40%;
                        transform: translateY(-36%);
                    }

                    .right-div-font {
                        position: absolute;
                        left: 35%;
                        top: 12%;
                        display: flex;
                        flex-direction: column;
                        color: #ffffff;
                        font-size: 18px;
                        margin: 0 30px;
                        line-height: 50px;
                    }

                    .right-div-font .el-button {
                        border-radius: unset;
                        font-size: 20px;
                        margin-top: 10px;
                    }
                }
            }
        }
    }

    .el-select /deep/ .select-popper {
        z-index: 8888 !important;
        top: auto !important;
        left: auto !important
    }
</style>
