<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>车辆管理</el-breadcrumb-item>
            <el-breadcrumb-item>长租车辆</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 搜索筛选 -->
        <el-form :inline="true" :model="filters" class="user-search">
            <el-form-item style="width: 10%">
                <el-select size="mini" v-model="filters.carProperty" clearable filterable placeholder="车辆属性">
                    <el-option label="小车" value="1"></el-option>
                    <el-option label="大车" value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item style="width: 10%">
                <el-input size="mini" v-model="filters.carNumber" clearable placeholder="车牌号码"
                          @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
            <el-form-item style="width: 10%">
                <el-input size="mini" v-model="filters.carOwnerPhone" clearable placeholder="车主手机"
                          @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
            <el-form-item style="width: 10%">
                <el-input size="mini" v-model="filters.carOwnerName" clearable placeholder="车主姓名"
                          @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
            <el-form-item style="width: 10%">
                <el-select size="mini" v-model="filters.expired" clearable placeholder="状态">
                    <el-option label="全部" value=" "></el-option>
                    <el-option label="已过期" value="true"></el-option>
                    <el-option label="未过期" value="false"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <div style="display: flex;flex-direction: row;margin-top: 6px;">
                    <el-button icon="el-icon-search" type="primary" size="mini" @click="handleSearch">查询</el-button>
                    <el-button icon="el-icon-plus" type="primary" size="mini" @click="handleAdd">添加</el-button>
                    <el-button icon="el-icon-download" type="primary" size="mini" @click="handleTemplate">下载导入模板
                    </el-button>
                    <el-button type="primary" size="mini" @click="handleImport">导入</el-button>
                    <el-button type="primary" size="mini" @click="handleExportExcel">导出</el-button>
                    <!--                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"-->
                    <el-button type="primary" size="mini" @click="handleSyncWhitelist">同步白名单</el-button>
                </div>
            </el-form-item>
        </el-form>
        <!--列表-->
        <el-table
                id="out-table"
                size="small"
                :data="tableData"
                highlight-current-row
                border
                v-loading="loading"
                element-loading-text="拼命加载中"
                :span-method="objectSpanMethod"
                style="width: 100%"
                :row-style="{height:'45px'}"
                :cell-style="{padding:'0px'}"
                :header-cell-style="{background:'#eef1f6',color:'#606266'}">
            <el-table-column min-width="30" type="index" label="序号"></el-table-column>
            <el-table-column prop="ownerName" label="车主姓名" align="center"></el-table-column>
            <el-table-column width="100" prop="ownerPhone" label="车主手机" align="center"></el-table-column>
            <el-table-column width="100" prop="idCard" label="车主身份证号" align="center"></el-table-column>
            <el-table-column prop="remark" label="备注" align="center"></el-table-column>
            <el-table-column width="160" prop="licensePlate" label="车牌号码|卡号" align="center">
                <template slot-scope="scope">
                    <div v-for="(item, i) in scope.row.licensePlate" :key="i">
                        <div class="column-line" v-if="item">{{item}}</div>
                        <div class="column-line" v-else-if="!item">{{item}}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="carTag" label="车辆标签" align="center">
                <template slot-scope="scope">
                    <!--                    <div style="line-height: 44px;" v-html="scope.row.carTag"></div>-->
                    <div v-for="(item, i) in scope.row.carTag" :key="i">
                        <div class="column-line" v-if="item">{{item}}</div>
                        <div class="column-line" v-else-if="!item">{{item}}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="carProperty" label="车辆属性" align="center">
                <template slot-scope="scope">
                    <div v-for="(item, i) in scope.row.carProperty" :key="i">
                        <el-tag style="margin: 8px 0;" type="success" v-if="item == 1">小车</el-tag>
                        <el-tag style="margin: 8px 0;" type="warning" v-else-if="item == 2">大车</el-tag>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="cycleType" label="周期类型" align="center">
                <template slot-scope="scope">
                    <el-tag type="info" v-if="scope.row.cycleType == 1">月</el-tag>
                    <el-tag v-else-if="scope.row.cycleType == 2">季</el-tag>
                    <el-tag type="success" v-else-if="scope.row.cycleType == 3">半年</el-tag>
                    <el-tag type="warning" v-else-if="scope.row.cycleType == 4">年</el-tag>
                    <el-tag type="danger" v-else-if="scope.row.cycleType == 5">长期</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="parkingAreaId" label="所属区域" :formatter="getParkingArea"
                             align="center"></el-table-column>
            <el-table-column prop="parkingPlaceNumber" label="车位编号" align="center"></el-table-column>
            <el-table-column prop="" label="是否有效" :formatter="getParkingTime" align="center"></el-table-column>
            <el-table-column width="120" prop="startTime" label="有效期起始时间" align="center"></el-table-column>
            <el-table-column width="120" prop="endTime" label="有效期截止时间" align="center"></el-table-column>
            <el-table-column fixed="right" width="160" align="center" label="操作" v-if="oprationShow">
                <template slot-scope="scope">
                    <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
                               type="primary">编辑
                    </el-button>
                    <el-button size="mini" @click="handleDelete(scope.$index, scope.row)"
                               type="danger">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="filters.current"
                       :page-sizes="[10, 100, 200, 500, 1000]"
                       :page-size="filters.size"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
        <!--        文件导入错误数据-->
        <el-dialog v-draggable title="导入结果" :visible.sync="visibleDialog" width="90%">
            <el-row>
                <el-col :span="6"><span>成功导入：{{successNum}}</span></el-col>
                <el-col :span="6"><span>失败导入：{{failNum}}</span></el-col>
                <el-col :span="6">导入总条数：{{successNum + failNum}}</el-col>
                <el-col :span="6">
                    <el-button type="primary" size="mini" @click="visibleDialog = false">关闭</el-button>
                </el-col>
            </el-row>
            <!--列表-->
            <el-table
                    v-if="importData.length > 0"
                    size="small"
                    :data="importData"
                    highlight-current-row
                    border
                    style="width: 100%;margin-top: 10px;"
                    :row-style="{height:'45px'}"
                    :cell-style="{padding:'0px'}"
                    :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                    stripe
            >
                <el-table-column min-width="30" type="index" label="序号"></el-table-column>
                <el-table-column prop="licensePlate" label="车牌号码" align="center"></el-table-column>
                <el-table-column prop="ownerName" label="车主姓名" align="center"></el-table-column>
                <el-table-column width="100" prop="ownerPhone" label="车主手机" align="center"></el-table-column>
                <el-table-column width="100" prop="idCard" label="车主身份证号" align="center"></el-table-column>
                <el-table-column prop="carIdentity" label="车辆类型" align="center"></el-table-column>
                <el-table-column prop="carProperty" label="车辆属性" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.carProperty == 1">小车</span>
                        <span v-else-if="scope.row.carProperty == 2">大车</span>
                    </template>
                </el-table-column>
                <el-table-column prop="remark" label="备注" align="center"></el-table-column>
                <el-table-column prop="parkingAreaId" label="所属区域" :formatter="getParkingArea"
                                 align="center"></el-table-column>
                <el-table-column prop="parkingPlaceNumber" label="车位编号" align="center"></el-table-column>
                <el-table-column prop="" label="是否有效" :formatter="getParkingTime" align="center"></el-table-column>
                <el-table-column width="120" prop="startTime" label="有效期起始时间" align="center"
                                 :formatter="dateForma"></el-table-column>
                <el-table-column width="120" prop="endTime" label="有效期截止时间" align="center"
                                 :formatter="dateForma"></el-table-column>
            </el-table>
        </el-dialog>
        <!--        选择导出文件类型-->
        <el-dialog v-draggable title="文件类型" :visible.sync="selectDialog" width="250px">
            <el-form :model="editForm" ref="editForm" size="small" :rules="rules">
                <el-form-item prop="radio" label-width="50px">
                    <el-radio v-model="editForm.radio" label="xls">xls</el-radio>
                    <el-radio v-model="editForm.radio" label="xlsx">xlsx</el-radio>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="selectDialog = false">取 消</el-button>
        <el-button size="small" type="primary" @click="onExportExcel('editForm')">确 定</el-button>
        </span>
        </el-dialog>
        <el-dialog v-draggable title="可通行区域选择" :visible.sync="importDialog" v-loading="importLoading"
                   element-loading-text="拼命加载中"
                   element-loading-spinner="el-icon-loading"
                   element-loading-background="rgba(0, 0, 0, 0.6)">
            <div style="text-align: center;margin-bottom: 10px">请选择可通行区域，本次导入车辆可通行区域将统一处理。</div>
            <el-form :model="importForm" ref="importForm" size="small" :rules="Frules">
                <el-form-item prop="longCarInAreas" label-width="140px">
                    <el-checkbox-group v-model="importForm.longCarInAreas">
                        <el-checkbox v-for="item in parkingAreas" :label="item.id"
                                     :key="item.id" :value="item.id">
                            {{item.name }}
                        </el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item>
                    <div style="display: flex;flex-direction: row;justify-content: space-around">
                        <el-button v-if="importForm.longCarInAreas.length == 0" type="success" icon="el-icon-upload"
                                   @click="onUpload('importForm')">开始导入
                        </el-button>
                        <el-upload v-else class="upload-demo inline-block" action="" :http-request="httpRequest"
                                   :show-file-list="false"
                                   accept=".xls,.xlsx"
                                   :before-upload="beforeAvatarUpload">
                            <el-button size="small" style="margin:0 0 20px 20px" type="success" icon="el-icon-upload">
                                开始导入
                            </el-button>
                        </el-upload>
                        <div>
                            <el-button size="small" @click="importDialog = false">取 消</el-button>
                        </div>
                    </div>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog v-draggable title="同步白名单" :visible.sync="syncWhitelistDialog" v-loading="syncWhitelistLoading"
                   element-loading-text="拼命加载中"
                   element-loading-spinner="el-icon-loading"
                   element-loading-background="rgba(0, 0, 0, 0.6)">
            <el-table
                    size="small"
                    :data="equipmentData"
                    @selection-change="handleSelectionChange"
                    ref="equipmentTable"
                    highlight-current-row
                    border
                    style="width: 100%"
                    :row-style="{height:'45px'}"
                    :cell-style="{padding:'0px'}"
                    :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                    stripe
                    height="350"
                    max-height="350">
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column prop="equipmentName" label="设备名称" align="center"></el-table-column>
                <el-table-column prop="status" label="设备状态" align="center">
                    <template slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.status == 1">在线</el-tag>
                        <el-tag type="warning" v-else-if="scope.row.status == 2">离线</el-tag>
                    </template>
                </el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="syncWhitelistDialog = false">取 消</el-button>
                <el-button size="small" type="primary" @click="handleEquipment">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>

    import {
        longCarList,
        longCarDel,
        longCarAreaList,
        longCarDownTemplate,
        longCarImportExcel,
        updateWhiteList,
        equipmentList
    } from "@/api/vehicle/longcar";

    export default {
        name: "longTermRentRate",
        data() {
            return {
                loading: false,
                oprationShow: true,
                total: 0,
                tableData: [],
                tableList: [],
                filters: {
                    current: 1,
                    size: 10,
                    carNumber: '',
                    carOwnerName: '',
                    carOwnerPhone: '',
                    carProperty: '',
                    expired: '',
                },
                formLabelWidth: '120px',

                rowList: [],
                spanArr: [],
                position: 0,
                parkingAreas: [],
                visibleDialog: false,
                importData: [],
                successNum: "",
                failNum: "",
                selectDialog: false,
                editForm: {
                    radio: "",
                },
                rules: {
                    radio: [
                        {required: true, message: '请选择导出类型', trigger: 'change'}
                    ],
                },
                importDialog: false,
                importLoading: false,
                importForm: {
                    longCarInAreas: [],
                },
                Frules: {
                    longCarInAreas: [
                        {required: true, message: '请选择可通行区域', trigger: 'change'}
                    ],
                },
                syncWhitelistDialog: false,
                equipmentData: [],
                multipleSelection: [],
                syncWhitelistLoading: false,
            }
        },
        mounted() {
            this.fetchData();
            this.getParkingAreaList();
            this.filters.expired = this.$route.query.expired;
        },
        methods: {
            //获取数据
            fetchData() {
                let that = this;
                that.loading = true;
                let params = {
                    current: that.filters.current,
                    size: that.filters.size,
                    licensePlate: that.filters.carNumber,
                    carOwnerName: that.filters.carOwnerName,
                    carOwnerPhone: that.filters.carOwnerPhone,
                    carProperty: that.filters.carProperty,
                    expired: that.filters.expired || that.$route.query.expired,
                };
                longCarList(params).then((res) => {
                    that.loading = false;
                    let list = res.data.data.records;
                    let tableData = [];
                    list.forEach((item) => {
                        if (!item.longCarCharges || item.longCarCharges.length <= 0) {
                            item.rowspan = 1;
                            let licensePlate = [];
                            let carProperty = [];
                            let carTag = [];
                            for (var e = 0; e < item.longCarInfo.length; e++) {
                                licensePlate.push(item.longCarInfo[e].rfidNo ? item.longCarInfo[e].licensePlate + ' | ' + item.longCarInfo[e].rfidNo : item.longCarInfo[e].licensePlate);
                                carProperty.push(item.longCarInfo[e].carProperty);
                                carTag.push(item.longCarInfo[e].carTag);
                            }
                            item.licensePlate = licensePlate;
                            item.carProperty = carProperty;
                            item.carTag = carTag;
                            tableData.push(item);
                        } else {
                            let licensePlate = [];
                            let carProperty = [];
                            let carTag = [];
                            for (var i = 0; i < item.longCarInfo.length; i++) {
                                licensePlate.push(item.longCarInfo[i].rfidNo ? item.longCarInfo[i].licensePlate + ' | ' + item.longCarInfo[i].rfidNo : item.longCarInfo[i].licensePlate);
                                carProperty.push(item.longCarInfo[i].carProperty);
                                carTag.push(item.longCarInfo[i].carTag);
                            }
                            item.longCarInfo.forEach((long, index) => {
                                item.longCarInfoId = long.longCarInfoId;
                            })
                            item.longCarCharges.forEach((long, index) => {
                                if (index == 0) {
                                    if (item.longCarInfoId == item.id) {
                                        long.licensePlate = licensePlate;
                                        long.carProperty = carProperty;
                                        long.carTag = carTag;
                                    }
                                    long.rowspan = item.longCarCharges.length;
                                    long.ownerName = item.ownerName;
                                    long.ownerPhone = item.ownerPhone;
                                    long.idCard = item.idCard;
                                    long.id = item.id;
                                    long.remark = item.remark;
                                }
                                tableData.push(long);
                            })
                        }
                    })
                    that.tableData = tableData;
                    that.total = res.data.data.total;
                    that.filters.current = res.data.data.current;
                    that.filters.size = res.data.data.size;
                }).catch(error => {
                    that.loading = false;
                });
            },
            objectSpanMethod({row, column, rowIndex, columnIndex}) {
                if (columnIndex < 8 || columnIndex === 14) {
                    if (row && row.rowspan) {
                        return {
                            rowspan: row.rowspan,
                            colspan: 1
                        }
                    } else {
                        return {
                            rowspan: 0,
                            colspan: 0
                        }
                    }
                }
            },
            handleSearch() {
                this.filters.current = 1;
                this.fetchData();
            },
            handleSizeChange(val) {
                this.filters.size = val;
                this.filters.current = 1;
                this.fetchData();
            },
            handleCurrentChange(val) {
                this.filters.current = val;
                this.fetchData();
            },
            getParkingAreaList() {
                longCarAreaList().then((res) => {
                    this.parkingAreas = res.data.data.parkingAreas;
                })
            },
            getParkingArea(row, column) {
                let name = ""
                this.parkingAreas.map((e) => {
                    if (e.id == row.parkingAreaId) {
                        name = e.name;
                    }
                })
                return name;
            },
            //添加跳转
            handleAdd() {
                // this.$router.push({path:'/home',query: {id:'1'}})
                this.$router.push({path: '/parkingLot/addVehicle'})
            },
            //编辑跳转
            handleEdit(index, row) {
                this.$router.push({path: '/parkingLot/editVehicle', query: {id: row.id}})
            },
            //删除
            handleDelete(index, row) {
                this.$confirm('是否确认删除长租车?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    longCarDel(row.id).then((res) => {
                        this.fetchData();
                        if (res.data.code == "SUCCESS") {
                            this.$message.success(res.data.message);
                        } else {
                            this.$message.info(res.data.message);
                        }
                    })
                }).catch(() => {
                    this.$message.info('已取消');
                });
            },
            //导出列表
            handleExportExcel() {
                this.selectDialog = true;
                this.editForm.radio = "";
            },
            onExportExcel(editForm) {
                this.$refs[editForm].validate((valid) => {
                    if (valid) {
                        this.oprationShow = false
                        this.$nextTick(function () {
                            let tables = document.getElementById("out-table");
                            let table_book = this.$XLSX.utils.table_to_book(tables);
                            var table_write = this.$XLSX.write(table_book, {
                                bookType: this.editForm.radio,
                                bookSST: true,
                                type: "array"
                            });
                            try {
                                this.$FileSaver.saveAs(
                                    new Blob([table_write], {type: "application/octet-stream"}),
                                    "长租车辆列表." + this.editForm.radio
                                );
                            } catch (e) {
                                if (typeof console !== "undefined") console.log(e, table_write);
                            }
                            this.oprationShow = true;
                            this.selectDialog = false;
                            return table_write;
                        })
                    } else {
                        return false;
                    }
                });
            },
            //下载导入模板
            handleTemplate() {
                longCarDownTemplate().then((res) => {
                    this.$FileSaver(res.data, "长租车辆列表模板.xls");
                })
            },
            //验证文件类型
            beforeAvatarUpload(file) {
                const isXls = file.type === "application/vnd.ms-excel" ? true : file.type ===
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? true : false;
                if (!isXls) {
                    this.$message.error("上传的文件只能是xls以及xlsx格式!");
                }
                return isXls;

            },
            // ---------------导入
            handleImport() {
                this.importDialog = true;
            },
            onUpload(form) {
                this.$refs[form].validate((valid) => {
                    if (valid) {
                        console.log('进来了')
                    }
                })
            },
            httpRequest(param) {
                let formData = new FormData();
                formData.append("file", param.file);
                formData.append("areaId", this.importForm.longCarInAreas.toString());
                this.importLoading = true;
                // console.log(this.importForm.longCarInAreasa.join(","))
                this.$confirm('注意：导入会覆盖原有数据，请谨慎操作！', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    setTimeout(() => {
                        longCarImportExcel(formData).then(res => {
                            this.visibleDialog = true;
                            this.importDialog = false;
                            if (res.data.code == "SUCCESS") {
                                this.importLoading = false;
                                this.importData = res.data.data.errorData;
                                this.failNum = res.data.data.failNum;
                                this.successNum = res.data.data.successNum;
                                this.$message.success(res.data.message);
                                this.fetchData()
                            } else {
                                this.importLoading = false;
                                this.$message.error(res.data.message);
                            }
                        }).catch(error => {
                            this.visibleDialog = false;
                            this.importLoading = false;
                            this.$message.error("导入失败，请稍后再试！");
                        });
                    }, 1000);
                }).catch(() => {
                    this.importLoading = false;
                    this.$message.info('已取消导入');
                });
            },
            //同步白名单
            handleSelectionChange(selection) {
                this.multipleSelection = [];
                selection.forEach(element => {
                    this.multipleSelection.push(element);
                });
                // console.log(this.multipleSelection)
            },
            handleEquipment() {
                this.syncWhitelistLoading = true;
                updateWhiteList(JSON.stringify(this.multipleSelection)).then(res => {
                    this.syncWhitelistDialog = false;
                    this.syncWhitelistLoading = false;
                    if (res.data.code == "SUCCESS") {
                        this.$message.success('操作成功');
                        this.$refs.equipmentTable.clearSelection();
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
            },
            handleSyncWhitelist() {
                this.syncWhitelistDialog = true;
                let param = {
                    equipmentType: 1,
                    distribute: true,
                    status: 1
                }
                equipmentList(param).then(res => {
                    this.equipmentData = res.data.data;
                });
            },
            dateForma(row, column) {
                var date = row[column.property];
                if (date == undefined) {
                    return ''
                }
                return this.dayjs(date).format("YYYY-MM-DD");
            },
            getParkingTime(row) {
                let name = ""
                if (row.endTime === undefined) {
                    return name;
                } else if (row.endTime != "" || row.endTime != undefined) {
                    let startTime = this.dayjs().unix();
                    let endTime = this.dayjs(row.endTime).unix()
                    if (startTime > endTime) {
                        name = '否'
                    } else {
                        name = '是'
                    }
                    return name;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .user-search {
        margin-top: 10px;
    }

    /deep/ .el-loading-spinner {
        font-size: 40px;
    }

    /deep/ .el-table .cells {
        white-space: pre-line !important;
    }

    .column-line {
        height: 46px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    /*/deep/ .el-loading-spinner /deep/ i {*/
    /*    color: #fff;*/
    /*}*/
    /*/deep/ .el-loading-spinner /deep/ .el-loading-text{*/
    /*    color: #fff;*/
    /*}*/
</style>
