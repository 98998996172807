import request from '@/utils/request'
import config from '@/config'

// 区域树列表
export function getTreeData() {
    return request({
        url: `${config.cpi}/space/treeData`,
        method: 'get',
        headers: {'showLoading': false}
    })
}

// 添加区域
export function AddArea(data) {
    return request({
        url: `${config.cpi}/space/area`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 修改区域
export function EditArea(data) {
    return request({
        url: `${config.cpi}/space/area`,
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 删除区域
export function delArea(id) {
    return request({
        url: `${config.cpi}/space/area`,
        method: 'delete',
        params: {ids: id},
    })
}

// 添加通道
export function AddDoorway(data) {
    return request({
        url: `${config.cpi}/space/doorway`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 修改通道
export function EditDoorway(data) {
    return request({
        url: `${config.cpi}/space/doorway`,
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 删除通道
export function delDoorway(id, data) {
    return request({
        url: `${config.cpi}/space`,
        method: 'delete',
        params: {ids: id, forceDelAuth: data},
    })
}
