<template>
    <div id="app">
        <router-view v-if="isRouterAlive"></router-view>
    </div>
</template>
<script>
    export default {
        name: 'App',
        provide() {
            return {
                reload: this.reload
            }
        },
        data() {
            return {
                isRouterAlive: true
            }
        },
        methods: {
            reload() {
                this.isRouterAlive = false
                this.$nextTick(() => this.isRouterAlive = true)
            }
        }
    }
</script>

<style lang="scss" scoped>
    /*#app {*/
    /*width: 100vw;*/
    /*height: 100vh;*/
    /*background-color: #020308;*/
    /*overflow: hidden;*/
    /*}*/
    /*滚动条的宽度*/
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
        width: 8px !important;
        height: 8px !important; /*纵向滚动条 必写*/
    }

    /*滚动条的滑块*/
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
        background-color: #a1a3a9;
        border-radius: 3px;
    }

    /deep/ .el-dialog__header {
        background-color: #e0e0e0
    }
</style>
