import request from '@/utils/request'
import config from '@/config'

// 组织树列表
export function handleTreeData(data) {
    return request({
        url: `${config.cpi}/org/treeData`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}

// 添加组织
export function addOrg(data) {
    return request({
        url: `${config.cpi}/org`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 修改组织
export function editOrg(data) {
    return request({
        url: `${config.cpi}/org`,
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 删除组织
export function delOrg(id) {
    return request({
        url: `${config.cpi}/org`,
        method: 'delete',
        params: {ids: id},
    })
}

// 人员列表
export function getUser(data) {
    return request({
        url: `${config.cpi}/user`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}

// 添加人员
export function addUser(data) {
    return request({
        url: `${config.cpi}/user`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 编辑人员
export function editUser(data) {
    return request({
        url: `${config.cpi}/user`,
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 删除人员
export function delUser(ids) {
    return request({
        url: `${config.cpi}/user`,
        method: 'delete',
        data: ids,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 文件上传
export function uploadFile(data) {
    return request({
        url: `${config.api}/common/uploadFile`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
}

// 人员通行记录
export function getRecordPage(data) {
    return request({
        url: `${config.cpi}/pass/getRecordPage`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}

// 黑名单列表
export function getBlacklist(data) {
    return request({
        url: `${config.cpi}/blacklist`,
        method: 'get',
        params: data
    })
}

// 添加黑名单
export function addBlacklist(data) {
    return request({
        url: `${config.cpi}/blacklist`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 删除黑名单
export function delBlacklist(ids) {
    return request({
        url: `${config.cpi}/blacklist`,
        method: 'delete',
        data: ids,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

//下载导入模板
export function downTemplate(data) {
    return request({
        url: `${config.cpi}/user/downModel`,
        method: 'post',
        params: data,
        responseType: 'blob',
    })
}

//导入
export function importTemplate(file) {
    return request({
        url: `${config.cpi}/user/import/batch`,
        method: 'post',
        data: file,
        headers: {
            'Content-Type': 'application/json',
        },
        // headers: {
        //     'Content-Type': 'multipart/form-data',
        // },
    })
}

//人员申请列表
export function userRequestlist(data) {
    return request({
        url: `${config.cpi}/user/request/list`,
        method: 'get',
        params: data
    })
}

//审核申请信息
export function userRequestVerify(data) {
    return request({
        url: `${config.cpi}/user/request/verify`,
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

//小区信息
export function villageInfo() {
    return request({
        url: `${config.cpi}/user/request/villageInfo`,
        method: 'get',
    })
}

//批量审核
export function userRequestVerifyBatch(data) {
    return request({
        url: `${config.cpi}/user/request/verify/batch`,
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

//管理员手机号
export function managerContact(data) {
    return request({
        url: `${config.cpi}/user/request/manager/contact`,
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

//导入组织
export function orgImport(data) {
    return request({
        url: `${config.cpi}/org/import`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
}

//下载组织结构导入模板
export function orgDownModel() {
    return request({
        url: `${config.cpi}/org/downModel`,
        method: 'post',
        responseType: 'blob',
        headers: {'showLoading': false}
    })
}
