<template>
    <el-row class="container">
        <!--头部-->
        <el-col :span="24" class="topbar-wrap">
            <div class="topbar-logo topbar-btn" v-show="!outside">
                <img class="logoimg" src="../../assets/555.png" alt=""/>
            </div>
            <!--       人车出入口AI智能管理平台~  v-if="!collapsed"-->
            <div class="topbar-logos" v-show="!outside">
                <span style="color: #fff;">运营管理中心</span>
            </div>
            <div style="float: left" v-show="!outside">
                <i :class="collapsed ? 'el-icon-s-unfold' : 'el-icon-s-fold'"
                   style="color: #fff;margin: 10px;font-size: 30px"
                   @click="toggleCollapse"></i>
            </div>
            <div class="topbar-title">
                <!-- 注意：这里就是topNavState作用之处，根据当前路由所在根路由的type值判断显示不同顶部导航菜单 -->
                <el-row v-show="$store.state.topNavState==='home'">
                    <el-col :span="24">
                        <el-menu :default-active="defaultActiveIndex" class="el-menu-demo"
                                 mode="horizontal" @select="handleSelect" :router="false">
                            <el-menu-item index="/enterpriseManager">通用业务</el-menu-item>
                            <el-menu-item v-if="!outside" v-show="projectType1 == 1 && num1"
                                          index="/parkingLotManager">停车场
                            </el-menu-item>
                            <el-menu-item v-if="!outside" v-show="projectType1 == ''"
                                          @click="handleClick('parkingLot')">停车场
                            </el-menu-item>
                            <el-menu-item v-show="projectType2 == 2 && num2" index="/personnelManager">人行
                            </el-menu-item>
                            <el-menu-item v-show="projectType2 == ''" @click="handleClick('personnel')">人行
                            </el-menu-item>
                            <el-menu-item v-show="projectType3 == 3 && num3" index="/speedMainManager">雷达测速
                            </el-menu-item>
                            <el-menu-item v-show="projectType3 == ''" @click="handleClick('speedMain')">雷达测速
                            </el-menu-item>
                        </el-menu>
                    </el-col>
                </el-row
                >
            </div>
            <div class="topbar-account topbar-btn" v-show="!outside">
                <el-dropdown @command="handleCommand">
                    <span class="el-dropdown-link userinfo-inner">
                        帮助向导
                        <i class="el-icon-caret-bottom"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="parkingLot" v-show="projectType1 == 1 && num1">车场系统</el-dropdown-item>
                        <el-dropdown-item command="personnel" v-show="projectType2 == 2 && num2">人行系统</el-dropdown-item>
                        <el-dropdown-item command="speedMain" v-show="projectType3 == 3 && num3">雷达测速</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <div style="margin: 0 10px"><span style="font-size: 14px;font-weight: bold;">项目名称：{{projectName}}</span>
                </div>
                <el-dropdown trigger="click">
                    <span class="el-dropdown-link userinfo-inner">
                        <i class="iconfont icon-user"></i> {{username}}
                        <i class="el-icon-caret-bottom"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>
                            <div @click="jumpTo('/enterprise/personalCenter')"><span
                                    style="color: #555;font-size: 14px;">个人信息</span></div>
                        </el-dropdown-item>
                        <el-dropdown-item divided @click.native="logout">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </el-col>
        <el-drawer
                :visible.sync="drawer"
                direction="rtl"
                :with-header="false"
                size="25%">
            <div>
                <span>请按下列操作指引完成配置，其中蓝色字体部分为准备工作或者补充说明，其余为页面上的配置。</span>
                <el-divider></el-divider>
            </div>
            <div v-show="command == 'parkingLot'">
                <div class="drawer-div" v-for="item in content1" :key="item.t1">
                    <p><span>{{item.t1}}</span> {{item.t2}}</p>
                    <ul class="drawer-info">
                        <li v-show="item.t3">
                            <div class="status-point"/>
                            <span>{{item.t3}}</span></li>
                        <li v-show="item.t4">
                            <div class="status-point"/>
                            <span :style="item.t1 == '03' || item.t1 == '04' ? 'color: #66b1ff' : ''">{{item.t4}}</span>
                        </li>
                        <div @click="jumpTo(activated == item.num  ? item.path : '')"
                             :style="activated == item.num  ? '' : 'color: #8a8a8a'">{{item.t5}}
                            <i class="el-icon-right"></i>
                        </div>
                    </ul>
                </div>
            </div>
            <div v-show="command == 'personnel'">
                <div class="drawer-div" v-for="item in content2" :key="item.t1">
                    <p><span>{{item.t1}}</span> {{item.t2}}</p>
                    <ul class="drawer-info">
                        <li v-show="item.t3">
                            <div class="status-point"/>
                            <span :style="item.t1 == '01' ? 'color: #66b1ff' : ''">{{item.t3}}</span>
                        </li>
                        <li v-show="item.t4">
                            <div class="status-point"/>
                            <span :style="item.t1 == '01' ? 'color: #66b1ff' : ''">{{item.t4}}</span>
                        </li>
                        <li v-show="item.t5">
                            <div class="status-point"/>
                            <span>{{item.t5}}</span></li>
                        <div @click="jumpTo(activated1 == item.num  ? item.path : '')"
                             :style="activated1 == item.num  ? '' : 'color: #8a8a8a'">{{item.t6}}
                            <i class="el-icon-right"></i>
                        </div>
                    </ul>
                </div>
            </div>
            <div v-show="command == 'speedMain'">
                <div class="drawer-div" v-for="item in content3" :key="item.t1">
                    <p><span>{{item.t1}}</span> {{item.t2}}</p>
                    <ul class="drawer-info">
                        <li v-show="item.t3">
                            <div class="status-point"/>
                            <span :style="item.t1 == '01' ? 'color: #66b1ff' : ''">{{item.t3}}</span>
                        </li>
                        <li v-show="item.t4">
                            <div class="status-point"/>
                            <span :style="item.t1 == '01' ? 'color: #66b1ff' : ''">{{item.t4}}</span>
                        </li>
                        <li v-show="item.t5">
                            <div class="status-point"/>
                            <span>{{item.t5}}</span></li>
                        <div @click="jumpTo(activated2 == item.num  ? item.path : '')"
                             :style="activated2 == item.num  ? '' : 'color: #8a8a8a'">{{item.t6}}
                            <i class="el-icon-right"></i>
                        </div>
                    </ul>
                </div>
            </div>

            <div class="drawer-btn">
                <el-button @click="drawer = false" type="primary"> 关闭</el-button>
            </div>
        </el-drawer>
    </el-row>
</template>
<script>
    import 'element-ui/lib/theme-chalk/display.css';
    import {road} from '../../utils/road.js'
    import bus from '../../utils/bus.js'
    import {getCurrentProject, getCustomerInfo, getMenuList} from "../../api/login";

    export default {
        data() {
            return {
                loading: false,
                companyName: '',
                username: '',
                projectName: '',
                defaultActiveIndex: '',
                homeMenu: false,
                collapsed: false,
                menuList: [],
                parkingLotList: [],
                projectType: [],
                projectType1: "",
                projectType2: "",
                projectType3: "",
                outside: "",
                drawer: false,
                activated: 0,
                activated1: 0,
                activated2: 0,
                command: "parkingLot",
                content1: [
                    {
                        t1: "01",
                        t2: "设置车场参数",
                        t3: "跟据现场实际情况勾选",
                        t4: "",
                        t5: "去配置",
                        path: "/content/parkingSettings?activeName=2",
                        num: 0
                    },
                    {
                        t1: "02",
                        t2: "添加长租费率",
                        t3: "可设置大小车型在不同区域不同时间周期的收费标准",
                        t4: "",
                        t5: "去配置",
                        path: "/content/parkingSettings?activeName=3",
                        num: 1
                    },
                    {
                        t1: "03",
                        t2: "添加临停费率",
                        t3: "可设置大小车型在不同区域里面的临停费率",
                        t4: "不设置费率时，收费车场的临时车辆无法通行",
                        t5: "去配置",
                        path: "/content/parkingSettings?activeName=4",
                        num: 2
                    },
                    {
                        t1: "04",
                        t2: "添加会员费率",
                        t3: "可设置大小车型在不同区域里面的会员费率",
                        t4: "长租车过期后，以会员车费率计算费用",
                        t5: "去配置",
                        path: "/content/parkingSettings?activeName=5",
                        num: 3
                    },
                    {
                        t1: "05",
                        t2: "增加操作员",
                        t3: "点击【角色管理】，添加角色",
                        t4: "点击【授权】，勾选权限",
                        t5: "去配置",
                        path: "/content/roleManagement",
                        num: 4
                    },
                    {
                        t1: "06",
                        t2: "增加用户",
                        t3: "【用户管理】 添加用户关联角色",
                        t4: "",
                        t5: "去配置",
                        path: "/content/userManagement",
                        num: 5
                    }
                ],
                content2: [
                    {
                        t1: "01",
                        t2: "添加设备",
                        t3: "向平台申请项目授权设备数",
                        t4: "获取设备SN码",
                        t5: "门禁通道管理添加设备",
                        t6: "去配置",
                        path: "/content/deviceManagement",
                        num: 0
                    },
                    {
                        t1: "02",
                        t2: "空间管理",
                        t3: "选中项目名---增加区域",
                        t4: "选中区域名---添加通道，将通道与设备进行绑定",
                        t5: "",
                        t6: "去配置",
                        path: "/content/spaceManagement",
                        num: 1
                    },
                    {
                        t1: "03",
                        t2: "组织及人员管理",
                        t3: "选中项目名---增加【机构】",
                        t4: "选中机构名---增加【部门】",
                        t5: "选中部门名---添加用户信息",
                        t6: "去配置",
                        path: "/content/organizationManagement",
                        num: 2
                    },
                    {
                        t1: "04",
                        t2: "通行授权",
                        t3: "勾选需要授权的人员名称，点【授权】",
                        t4: "勾选需要授权的区域通道",
                        t5: "选择有效期",
                        t6: "去配置",
                        path: "/content/accessAuthorization",
                        num: 3
                    }
                ],
                content3: [
                    {
                        t1: "01",
                        t2: "添加设备",
                        t3: "向平台申请项目授权设备数",
                        t4: "获取设备SN码",
                        t5: "测速设备管理添加设备",
                        t6: "去配置",
                        path: "/content/device",
                        num: 0
                    },
                    {
                        t1: "02",
                        t2: "测速点管理",
                        t3: "添加测速点名称后点【设置】",
                        t4: "绑定测速点与设备",
                        t5: "设置其它参数",
                        t6: "去配置",
                        path: "/content/position",
                        num: 1
                    }
                ],
                dataList: [],
                num1: null,
                num2: null,
                num3: null,
            }
        },
        created() {
            if (this.$route.query.token) {
                localStorage.setItem('token', this.$route.query.token);
                getCustomerInfo().then((res) => {
                    if (res.data.code == "SUCCESS") {
                        let user = res.data.data.customerDTO;
                        if (user) {
                            this.username = user.username || '';
                        }
                        localStorage.setItem('token', res.data.data.token);
                        localStorage.setItem('userdata', JSON.stringify(res.data.data.customerDTO));
                    }
                });
                getCurrentProject().then((res) => {
                    this.projectName = res.data.data.projectName;
                    window.localStorage.setItem('projectName', res.data.data.projectName);
                    window.localStorage.setItem('projectId', res.data.data.id);
                    let projectType = res.data.data.projectType;
                    this.projectType = [];
                    projectType.forEach((e) => {
                        this.projectType.push(e.type);
                        if (e.type == 1) {
                            window.localStorage.setItem('subSysId', e.subSysId);
                        }
                    })
                    this.projectType1 = this.projectType.filter(item => item === 1).toString();
                    this.projectType2 = this.projectType.filter(item => item === 2).toString();
                    this.projectType3 = this.projectType.filter(item => item === 3).toString();
                    window.localStorage.setItem('projectType1', this.projectType.filter(item => item === 1).toString());
                    window.localStorage.setItem('projectType2', this.projectType.filter(item => item === 2).toString());
                    window.localStorage.setItem('projectType3', this.projectType.filter(item => item === 3).toString());
                })
            } else {
                let user = window.localStorage.getItem('userdata');
                if (user) {
                    user = JSON.parse(user);
                    this.username = user.username || '';
                }
                this.projectName = window.localStorage.getItem('projectName');
                this.dataList = JSON.parse(window.localStorage.getItem('menuList'));
                this.num1 = this.dataList.parkingLot.length;
                this.num2 = this.dataList.personnel.length;
                this.num3 = this.dataList.speed.length;
                this.projectType1 = window.localStorage.getItem('projectType1');
                this.projectType2 = window.localStorage.getItem('projectType2');
                this.projectType3 = window.localStorage.getItem('projectType3');
            }
        },
        mounted() {
            // 组件创建完后获取数据
            this.outside = sessionStorage.getItem('outside');
            let path = this.$route.path.split("/");
            this.fetchNavData('/' + path[1] + 'Manager');
        },
        watch: {
            'defaultActiveIndex': function (msg) { // 路由改变时执行
                this.fetchNavData(msg);
            },
            '$route': function (to, from) { // 路由改变时执行
                this.activated = sessionStorage.getItem('active') || 0;
                this.activated1 = sessionStorage.getItem('active1') || 0;
                this.activated2 = sessionStorage.getItem('active2') || 0;
            }
        },
        methods: {
            handleCommand(command) {
                if (this.projectType1 == 1 && command == 'parkingLot') {
                    this.command = command;
                    this.drawer = true;
                } else if (this.projectType2 == 2 && command == 'personnel') {
                    this.command = command;
                    this.drawer = true;
                } else if (this.projectType3 == 3 && command == 'speedMain') {
                    this.command = command;
                    this.drawer = true;
                } else {
                    let title = "";
                    if (command == 'parkingLot') {
                        title = "暂未开通停车场管理，请联系渠道商开通。"
                    } else if (command == 'personnel') {
                        title = "暂未开通人行管理，请联系渠道商开通。"
                    } else if (command == 'speedMain') {
                        title = "暂未开通雷达测速，请联系渠道商开通。"
                    }
                    this.$confirm(title, "温馨提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    }).then(() => {

                    }).catch(() => {
                    });
                }
            },
            // 切换显示
            toggleCollapse() {
                this.collapsed = !this.collapsed;
                bus.$emit('collapsed', this.collapsed)
            },
            jumpTo(url) {
                if (url) {
                    this.drawer = false;
                    if (url === '/enterprise/personalCenter') {
                        let value = '/enterpriseManager';
                        this.fetchNavData(value, '/enterprise/personalCenter');
                        this.$router.push(url); //用go刷新
                    } else {
                        this.$router.push(url);
                    }
                }
            },
            handleSelect(index) {
                if (index) {
                    this.defaultActiveIndex = index;
                }
                if (index == '/enterpriseManager') {
                    if (this.dataList.openCommon.length > 0 && sessionStorage.getItem('outside')) {
                        for (var a = 0; a < this.dataList.openCommon.length; a++) {
                            if (this.dataList.openCommon[a].path == '/enterprise' && this.dataList.openCommon[0].children.length > 0) {
                                this.$router.push({path: this.dataList.openCommon[0].children[0].path});
                            }
                        }
                        return;
                    } else if (this.dataList.enterprise.length > 0) {
                        for (var c = 0; c < this.dataList.enterprise.length; c++) {
                            if (this.dataList.enterprise[c].path == '/enterprise' && this.dataList.enterprise[0].children.length > 0) {
                                this.$router.push({path: this.dataList.enterprise[0].children[0].path});
                            }
                        }
                        return;
                    }
                } else if (this.dataList.parkingLot.length > 0 && index == '/parkingLotManager') {
                    for (var j = 0; j < this.dataList.parkingLot.length; j++) {
                        if (this.dataList.parkingLot[j].path !== '/parkingLot') {
                            if (this.dataList.parkingLot[1].path == '/parkingLot/homePage') {
                                this.$router.push({path: '/parkingLot/homePage'});
                            } else {
                                this.$router.push({path: this.dataList.parkingLot[j].path});
                            }
                        } else {
                            if (this.dataList.parkingLot[j].path == '/parkingLot' && this.dataList.parkingLot[0].children.length > 0) {
                                this.$router.push({path: this.dataList.parkingLot[0].children[0].path});
                            }
                        }
                    }
                    return;
                } else if (this.dataList.personnel.length > 0 && index == '/personnelManager') {
                    for (var i = 0; i < this.dataList.personnel.length; i++) {
                        if (this.dataList.personnel[i].path !== '/personnel') {
                            this.$router.push({path: this.dataList.personnel[0].path});
                        } else {
                            if (this.dataList.personnel[i].path == '/personnel' && this.dataList.personnel[0].children.length > 0) {
                                this.$router.push({path: this.dataList.personnel[0].children[0].path});
                            }
                        }
                    }
                    return;
                } else if (this.dataList.speed.length > 0 && index == '/speedMainManager') {
                    for (var b = 0; b < this.dataList.speed.length; b++) {
                        if (this.dataList.speed[b].path !== '/speedMain') {
                            this.$router.push({path: this.dataList.speed[0].path});
                        }
                    }
                    return;
                }
            },
            handleClick(val) {
                let title = "";
                if (val == 'parkingLot') {
                    title = "暂未开通停车场管理，请联系渠道商开通。"
                } else if (val == 'personnel') {
                    title = "暂未开通人行管理，请联系渠道商开通。"
                } else if (val == 'speedMain') {
                    title = "暂未开通雷达测速，请联系渠道商开通。"
                }
                this.$confirm(title, "温馨提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {

                }).catch(() => {
                });
            },
            fetchNavData(value, path) { // 初始化菜单激活项
                let cur_path;
                if (path === '/enterprise/personalCenter') {
                    cur_path = path;
                } else {
                    cur_path = this.$route.path; //获取当前路由
                }
                // console.log('获取当前路由', this.$route.path)
                let routers = this.$router.options.routes; // 获取路由对象
                let nav_type = "home", nav_name = "";
                this.$store.state.topNavState = nav_type;
                this.menuList = [];
                getMenuList().then((res) => {
                        window.localStorage.setItem('menuList', JSON.stringify(res.data.data));
                        if (value == '/enterpriseManager') {
                            if (sessionStorage.getItem('outside')) {
                                this.menuList = res.data.data.openCommon;
                            } else {
                                this.menuList = res.data.data.enterprise;
                            }
                        } else if (value == '/parkingLotManager') {
                            this.menuList = res.data.data.parkingLot;
                        } else if (value == '/personnelManager') {
                            this.menuList = res.data.data.personnel;
                        } else if (value == '/speedMainManager') {
                            this.menuList = res.data.data.speed;
                        }
                        for (var j = 0; j < this.menuList.length; j++) {
                            // this.$set(this.menuList[j], 'icon', 'iconfont icon-home');
                            if (this.menuList[j].path.split('/')[1] === cur_path.split('/')[1]) {
                                nav_name = this.menuList[j].component;
                                break;
                            } else if (cur_path === '/parkingLot/editVehicle' || cur_path === '/parkingLot/addVehicle') {
                                nav_name = 'parkingLot';
                                break;
                            } else if (cur_path === '/enterprise/purchasePackage' || cur_path === '/enterprise/addVisitors'
                                || cur_path === '/enterprise/auditVisitors' || cur_path === '/enterprise/addRenewal'
                                || cur_path === '/enterprise/nesting') {
                                nav_name = 'enterprise';
                                break;
                            } else if (cur_path === '/speedMain/radarSettings') {
                                nav_name = 'speedMain';
                                break;
                            }
                            if (this.menuList[j].children) {
                                let grandChildren = this.menuList[j].children;
                                for (let z = 0; z < grandChildren.length; z++) {
                                    if (grandChildren[z].path === cur_path) {
                                        nav_name = this.menuList[j].component;
                                        break;
                                    }
                                }
                            }
                        }
                        this.$store.state.leftNavState = nav_name;
                        bus.$emit('leftNavState', nav_name);
                        bus.$emit('menuList', this.menuList);
                        if (nav_name == "enterprise") {
                            return this.defaultActiveIndex = '/' + nav_name + 'Manager';
                        } else if (nav_name == "parkingLot") {
                            return this.defaultActiveIndex = '/' + nav_name + 'Manager';
                        } else if (nav_name == "personnel") {
                            return this.defaultActiveIndex = '/' + nav_name + 'Manager';
                        } else if (nav_name == "speedMain") {
                            return this.defaultActiveIndex = '/' + nav_name + 'Manager';
                        }
                    }
                )
            },
            logout() {
                //logout
                this.$confirm('确认退出吗?', '提示', {
                    confirmButtonClass: 'el-button--warning'
                }).then(() => {
                    //确认
                    localStorage.removeItem('token');
                    localStorage.clear();
                    sessionStorage.clear();
                    this.$router.push('/login')
                }).catch(() => {
                });
            }
        },
    }
</script>
<style scoped>
    .container .topbar-wrap .topbar-logo {
        float: left;
        width: auto !important;
        line-height: 26px;
    }

    .container .topbar-wrap .topbar-logos {
        float: left;
        width: 115px;
        font-size: 18px;
        font-weight: bold;
        word-break: break-all;
        margin: 15px 10px 15px 10px;
    }

    .topbar-account {
        display: flex;
    }

    /*/deep/ .el-drawer__container {*/
    /*    top: 50px*/
    /*}*/

    /deep/ .el-drawer__body {
        padding: 15px 25px 25px 20px;
    }

    /deep/ .el-divider--horizontal {
        margin: 10px 0;
    }

    .drawer-div {
        display: flex;
        flex-direction: column;
        line-height: 30px;
    }

    .drawer-div p {
        font-size: 18px;
        font-weight: bold;
    }

    .drawer-div span {
        font-family: "Eras Bold ITC";
        font-style: italic;
        margin-right: 5px;
    }

    .drawer-info {
        display: flex;
        flex-direction: column;
        align-items: start;
        margin-left: 20px;
        list-style: disc;
    }

    .drawer-info div {
        color: #e28103;
        cursor: pointer;
        margin-left: 15px;
    }

    .drawer-info li {
        font-size: 20px;
    }

    .drawer-info span {
        font-size: 13px;
        font-style: normal;
    }

    .drawer-info li .status-point {
        display: inline-block;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: black;
        margin: 2px 10px 2px 0;
    }

    .drawer-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
    }

</style>
