<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>人行管理</el-breadcrumb-item>
            <el-breadcrumb-item>门禁通道管理</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 搜索筛选 -->
        <el-form :inline="true" :model="filters" class="user-search">
            <el-form-item>
                <el-input v-model="filters.name" clearable size="small" placeholder="请输入设备名称"
                          @keyup.enter.native="handleSearch"/>
            </el-form-item>
            <el-form-item>
                <el-select size="small" v-model="filters.type" clearable placeholder="请选择设备类型">
                    <el-option label="人脸机" value="4"></el-option>
                    <!--                    <el-option label="门禁控制器" value="5"></el-option>-->
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-select size="small" v-model="filters.status" clearable placeholder="请选择设备在线状态">
                    <el-option label="在线" value="1"></el-option>
                    <el-option label="离线" value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-search" type="primary" size="small" @click="handleSearch">查询</el-button>
                <el-button icon="el-icon-plus" type="primary" size="small" @click="handleAdd">添加</el-button>
                <el-button type="primary" size="small" @click="handleSyncDisplayTitle">同步显示屏标题</el-button>
                <el-button type="primary" size="small" @click="handleSyncTime">同步时间</el-button>
                <el-button type="primary" size="small" @click="handleClearTheBlankList">清空白名单</el-button>
                <el-button type="primary" size="small" @click="handleRestart">重启</el-button>
            </el-form-item>
        </el-form>
        <el-table
                @selection-change="handleSelectionChange"
                ref="tableData"
                v-loading="loading"
                element-loading-text="拼命加载中"
                size="small"
                :data="tableData"
                highlight-current-row
                border stripe
                style="width: 100%;"
                :row-style="{height:'45px'}"
                :cell-style="{padding:'0px'}"
                :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                height="500" max-height="500">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="deviceName" label="设备名称" align="center"></el-table-column>
            <el-table-column prop="sn" label="设备序列号" align="center"></el-table-column>
            <el-table-column prop="deviceNo" label="设备编号" align="center"></el-table-column>
            <el-table-column prop="deviceModel" label="设备型号" align="center">
                <template slot-scope="scope">
                    {{scope.row.deviceModel == 1 ? 'FACE_H' : scope.row.deviceModel == 2 ? 'FACE_J' :
                    scope.row.deviceModel == 3 ? 'FACE_Y' : scope.row.deviceModel == 4 ? 'FACE_TIF' : ''}}
                </template>
            </el-table-column>
            <el-table-column prop="status" label="设备在线状态" align="center">
                <template slot-scope="scope">
                    <el-tag v-show="scope.row.status != null" :type="scope.row.status == 1 ? '' : 'info'">
                        {{scope.row.status == 1 ? '在线' : scope.row.status == 2 ? '离线' : ''}}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="type" label="设备类型" align="center">
                <template slot-scope="scope">
                    <el-tag v-show="scope.row.type != null" :type="scope.row.type == 4 ? 'warning' : 'danger'">
                        {{scope.row.type == 4 ? '人脸机' : scope.row.type == 5 ?'门禁控制器' : ''}}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="title" label="显示屏标题" align="center"></el-table-column>
            <el-table-column width="300" align="center" label="操作">
                <template slot-scope="scope">
                    <el-button size="mini" @click="handleEdit(scope.$index, scope.row)" icon="el-icon-edit-outline"
                               type="primary">编辑
                    </el-button>
                    <el-button size="mini" type="danger" class="el-icon-delete"
                               @click="handleDelete(scope.$index, scope.row)">删除
                    </el-button>
                    <el-button v-show="scope.row.deviceModel == 3" size="mini"
                               @click="handleSetting(scope.row)" icon="el-icon-setting"
                               type="warning">设置密码
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="filters.current"
                       :page-sizes="[10, 50, 200, 1000]"
                       :page-size="filters.size"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
        <el-dialog v-draggable :title="title" :visible.sync="visibleDialog" width="30%">
            <el-form :model="visibleForm" ref="visibleForm" size="small" :rules="rules" label-width="130px">
                <el-form-item label="设备名称" prop="deviceName">
                    <el-input style="width: 80%" size="small" v-model="visibleForm.deviceName" placeholder="请输入内容"/>
                </el-form-item>
                <el-form-item label="设备序列号" prop="sn">
                    <el-input :disabled="disabled" style="width: 80%" size="small" v-model="visibleForm.sn"
                              placeholder="请输入内容"/>
                </el-form-item>
                <el-form-item label="设备编号" prop="deviceNo">
                    <el-input disabled style="width: 80%" size="small" v-model="visibleForm.deviceNo"
                              placeholder="设备编号"/>
                </el-form-item>
                <el-form-item label="设备类型" prop="type">
                    <el-select size="small" v-model="visibleForm.type" placeholder="请选择设备类型">
                        <el-option label="人脸机" :value="4"></el-option>
                        <!--                        <el-option label="门禁控制器" :value="5"></el-option>-->
                    </el-select>
                </el-form-item>
                <el-form-item label="设备型号" prop="deviceModel">
                    <el-select size="small" v-model="visibleForm.deviceModel" placeholder="请选择设备型号">
                        <el-option label="FACE_H" :value="1"></el-option>
                        <el-option label="FACE_J" :value="2"></el-option>
                        <el-option label="FACE_Y" :value="3"></el-option>
                        <el-option label="FACE_TIF" :value="4"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="显示屏标题" prop="title">
                    <el-input style="width: 80%" size="small" v-model="visibleForm.title" placeholder="请输入内容"/>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="visibleDialog = false">取 消</el-button>
                <el-button type="primary" @click="submitForm('visibleForm')">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import {
        AddDevice,
        addTime,
        addTitle,
        delClear,
        delDevice,
        EditDevice,
        getDevice,
        restart, screenPwd
    } from "../../../api/equipmentApi";
    import {login} from "../../../api/login";

    export default {
        name: "deviceManagement",

        data() {
            return {
                loading: false,
                total: 0,
                tableData: [],
                filters: {
                    name: '',
                    status: '',
                    type: '',
                    current: 1,
                    size: 10,
                },
                title: '',
                visibleDialog: false,
                visibleForm: {
                    title: '',
                    deviceName: '',
                    deviceNo: '',
                    deviceModel: '',
                    type: '',
                    sn: '',
                    id: '',
                },
                rules: {
                    deviceName: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ],
                    sn: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ],
                    type: [
                        {required: true, message: '请选择', trigger: 'change'},
                    ],
                    deviceModel: [
                        {required: true, message: '请选择', trigger: 'change'},
                    ],
                },
                disabled: false,
                multipleSelection: [],
            }
        },
        mounted() {
            this.fetchData();
        },
        methods: {
            fetchData() {
                this.loading = true;
                getDevice(this.filters).then((res) => {
                    this.loading = false;
                    if (res.data.code == "SUCCESS") {
                        this.tableData = res.data.data.records;
                        this.total = res.data.data.total;
                        this.filters.current = res.data.data.current;
                        this.filters.size = res.data.data.size;
                    } else {
                        this.$message.info(res.data.message);
                    }
                })
            },
            handleSearch() {
                this.filters.current = 1;
                this.fetchData();
            },
            handleSizeChange(val) {
                this.filters.size = val;
                this.filters.current = 1;
                this.fetchData();
            },
            handleCurrentChange(val) {
                this.filters.current = val;
                this.fetchData();
            },
            handleAdd() {
                this.title = "设备管理";
                this.visibleDialog = true;
                this.disabled = false;
                Object.keys(this.visibleForm).map(key => {
                    this.visibleForm[key] = '';
                })
                this.visibleForm.type = 4;
            },
            submitForm(visibleForm) {
                this.$refs[visibleForm].validate((valid) => {
                        if (valid) {
                            let params = {
                                title: this.visibleForm.title,
                                deviceName: this.visibleForm.deviceName,
                                deviceModel: this.visibleForm.deviceModel,
                                type: this.visibleForm.type,
                                sn: this.visibleForm.sn,
                                id: this.visibleForm.id
                            }
                            if (this.visibleForm.id) {
                                EditDevice(params).then((res) => {
                                    this.visibleDialog = false;
                                    if (res.data.code == "SUCCESS") {
                                        this.fetchData();
                                        this.$message.success("编辑成功！");
                                    } else {
                                        this.$message.info(res.data.message);
                                    }
                                })
                            } else {
                                AddDevice(params).then((res) => {
                                    this.visibleDialog = false;
                                    if (res.data.code == "SUCCESS") {
                                        this.fetchData();
                                        this.$message.success("保存成功！");
                                    } else {
                                        this.$message.info(res.data.message);
                                    }
                                })
                            }
                        }
                    }
                )
            },
            //编辑
            handleEdit(index, row) {
                this.title = "编辑设备管理";
                this.visibleDialog = true;
                this.disabled = true;
                Object.keys(this.visibleForm).map(key => {
                    this.visibleForm[key] = row[key];
                });
                this.visibleForm.id = row.id;
            },
            //删除
            handleDelete(index, row) {
                this.$confirm("确定要删除设备吗?", "温馨提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    delDevice(row.id).then((res) => {
                        if (res.data.code == "SUCCESS") {
                            this.fetchData();
                            this.$message.success("删除成功！");
                        } else {
                            this.$message.info(res.data.message);
                        }
                    })
                }).catch(() => {
                    this.$message.info("已取消删除!");
                });
            },
            handleSetting(row) {
                this.$prompt('', '触摸屏设置', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    closeOnClickModal: false,
                    customClass: "reset-message-box",
                    type: "warning",
                    inputPattern: /.{6,20}$/,  // 正则表达式，验证输入是否为1-9开头的最多20位数字,
                    inputPlaceholder: "请输入密码",
                    inputErrorMessage: '请输入6到20位的密码'
                }).then(({value}) => {
                    this.$set(row, 'screenPwd', value);
                    var arr = [];
                    arr.push(row);
                    screenPwd(arr).then((res) => {
                        if (res.data.code == "SUCCESS") {
                            this.fetchData();
                            this.$message.success(res.data.message);
                        } else {
                            this.$refs.tableData.clearSelection();
                            this.$message.info(res.data.message);
                        }
                    })
                }).catch(() => {
                    // this.$message.info("已取消操作");
                });
            },
            handleSelectionChange(selection) {
                this.multipleSelection = [];
                selection.forEach(element => {
                    this.multipleSelection.push(element);
                });
                JSON.parse(JSON.stringify(this.multipleSelection));
            },
            //
            handleSyncDisplayTitle() {
                this.$prompt('', '显示屏标题', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    closeOnClickModal: false,
                    customClass: "reset-message-box",
                    type: "warning",
                    inputPlaceholder: "请输入标题",
                }).then(({value}) => {
                    if (this.multipleSelection.length <= 0) {
                        this.$message.warning("请选择设备！");
                        return;
                    }
                    let param = {
                        title: value,
                        deviceParams: this.multipleSelection
                    }
                    addTitle(param).then((res) => {
                        if (res.data.code == "SUCCESS") {
                            this.fetchData();
                            this.$message.success(res.data.message);
                        } else {
                            this.$refs.tableData.clearSelection();
                            this.$message.info(res.data.message);
                        }
                    })
                }).catch(() => {
                    // this.$message.info("已取消操作");
                });
            },
            handleSyncTime() {
                this.$confirm("确定要同步时间吗?", "温馨提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    closeOnClickModal: false,
                    type: "warning",
                }).then(() => {
                    if (this.multipleSelection.length <= 0) {
                        this.$message.warning("请选择设备！");
                        return;
                    }
                    addTime(this.multipleSelection).then((res) => {
                        if (res.data.code == "SUCCESS") {
                            this.fetchData();
                            this.$message.success(res.data.message);
                        } else {
                            this.$refs.tableData.clearSelection();
                            this.$message.info(res.data.message);
                        }
                    })
                }).catch(() => {
                });
            },
            handleClearTheBlankList() {
                this.$prompt('确定要清空白名单吗?请输入登录密码以确认清空。', '温馨提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    closeOnClickModal: false,
                    customClass: "reset-message-box",
                    type: "warning",
                    inputType: "password",
                    inputPlaceholder: "请输入密码",
                    inputErrorMessage: "请输入正确密码",
                    inputValidator: (value) => {       // 点击按钮时，对文本框里面的值进行验证
                        if (!value) {
                            return '请输入正确密码';
                        }
                    },
                }).then(({value}) => {
                    if (this.multipleSelection.length <= 0) {
                        this.$message.warning("请选择设备！");
                        return;
                    }
                    let user = window.localStorage.getItem('userdata');
                    user = JSON.parse(user);
                    let param = {
                        username: user.username,
                        password: value,
                    }
                    login(param).then((res) => {
                        if (res.data.code === 'SUCCESS') {
                            // 缓存token
                            localStorage.setItem('token', res.data.data.token);
                            // 缓存用户个人信息
                            localStorage.setItem('userdata', JSON.stringify(res.data.data.customerDTO));
                            delClear(this.multipleSelection).then((res) => {
                                if (res.data.code == "SUCCESS") {
                                    this.fetchData();
                                    this.$message.success(res.data.message);
                                } else {
                                    this.$refs.tableData.clearSelection();
                                    this.$message.info(res.data.message);
                                    return;
                                }
                            }).catch((err) => {
                                this.$refs.tableData.clearSelection();
                                this.$message.error("操作失败，请稍后再试！");
                            });
                        } else {
                            this.$message.error(res.data.message);
                            return false;
                        }
                    })
                }).catch(() => {
                });
            },
            handleRestart() {
                this.$confirm("确定需要重启设备吗？", "温馨提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    closeOnClickModal: false,
                    type: "warning",
                }).then(() => {
                    if (this.multipleSelection.length <= 0) {
                        this.$message.warning("请选择设备！");
                        return;
                    }
                    restart(this.multipleSelection).then((res) => {
                        if (res.data.code == "SUCCESS") {
                            this.fetchData();
                            this.$message.success(res.data.message);
                        } else {
                            this.$refs.tableData.clearSelection();
                            this.$message.info(res.data.message);
                        }
                    })
                }).catch(() => {
                });
            },
        }
    }
</script>

<style scoped>
    .user-search {
        margin-top: 15px;
    }
</style>
