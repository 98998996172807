<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>雷达测速</el-breadcrumb-item>
            <el-breadcrumb-item>测速记录</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 搜索筛选 -->
        <el-form :inline="true" :model="filters" class="user-search">
            <el-form-item>
                <el-input v-model="filters.licensePlate" clearable size="small" placeholder="请输入车牌号"
                          @keyup.enter.native="handleSearch"/>
            </el-form-item>
            <el-form-item>
                <el-input v-model="filters.speedPositionName" clearable size="small" placeholder="请输入地点"
                          @keyup.enter.native="handleSearch"/>
            </el-form-item>
            <el-form-item>
                <el-select size="small" v-model="filters.status" clearable placeholder="请选择结果">
                    <el-option label="正常" value="1"></el-option>
                    <el-option label="超速" value="2"></el-option>
                    <el-option label="慢速" value="3"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-date-picker size="small" v-model="date" type="daterange"
                                range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-search" type="primary" size="small" @click="handleSearch">查询</el-button>
            </el-form-item>
        </el-form>
        <el-table
                v-loading="loading"
                element-loading-text="拼命加载中"
                size="small"
                :data="tableData"
                highlight-current-row
                border
                stripe
                style="width: 100%"
                :row-style="{ height: '45px' }"
                :cell-style="{ padding: '0px' }"
                :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
                height="500" max-height="500">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column prop="deviceName" label="车牌号" align="center">
                <template slot-scope="scope">
                    <span style="color: #4b67af;cursor: pointer;"
                          @click="handleEdit(scope.$index,scope.row)">{{scope.row.licensePlate}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="speedPositionName" label="地点" align="center"></el-table-column>
            <el-table-column prop="speedTime" label="时间" align="center"></el-table-column>
            <el-table-column prop="plateColor" label="车牌颜色" align="center">
                <template slot-scope="scope">
                    <span v-if="scope.row.plateColor == 0">未知</span>
                    <span v-if="scope.row.plateColor == 1">蓝色</span>
                    <span v-else-if="scope.row.plateColor == 2">黄色</span>
                    <span v-else-if="scope.row.plateColor == 3">白色</span>
                    <span v-else-if="scope.row.plateColor == 4">黑色</span>
                    <span v-else-if="scope.row.plateColor == 5">绿色</span>
                    <span v-else-if="scope.row.plateColor == 6">黄绿色</span>
                    <span v-else-if="scope.row.plateColor == 7">其他</span>
                </template>
            </el-table-column>
            <el-table-column prop="plateType" label="车牌类型" align="center">
                <template slot-scope="scope">
                    <span v-if="scope.row.plateType == 0">未知</span>
                    <span v-if="scope.row.plateType == 1">普通蓝牌</span>
                    <span v-else-if="scope.row.plateType == 2">普通黑牌</span>
                    <span v-else-if="scope.row.plateType == 3">普通黄牌</span>
                    <span v-else-if="scope.row.plateType == 4">双层黄牌</span>
                    <span v-else-if="scope.row.plateType == 5">警察车牌</span>
                    <span v-else-if="scope.row.plateType == 6">双层武警</span>
                    <span v-else-if="scope.row.plateType == 7">单层军牌</span>
                    <span v-else-if="scope.row.plateType == 8">双层军牌</span>
                    <span v-else-if="scope.row.plateType == 9">个性车牌</span>
                    <span v-else-if="scope.row.plateType == 10">新能源小车牌</span>
                    <span v-else-if="scope.row.plateType == 11">新能源大车牌</span>
                    <span v-else-if="scope.row.plateType == 12">大使馆车牌</span>
                    <span v-else-if="scope.row.plateType == 13">领事馆车牌</span>
                    <span v-else-if="scope.row.plateType == 14">民航车牌 </span>
                    <span v-else-if="scope.row.plateType == 15">其他</span>
                </template>
            </el-table-column>
            <!--            <el-table-column prop="carType" label="车型" align="center">-->
            <!--                <template slot-scope="scope">-->
            <!--                    <span v-if="scope.row.carType == 0">无效</span>-->
            <!--                    <span v-if="scope.row.carType == 1">大巴</span>-->
            <!--                    <span v-else-if="scope.row.carType == 2">小汽车</span>-->
            <!--                    <span v-else-if="scope.row.carType == 3">中巴</span>-->
            <!--                    <span v-else-if="scope.row.carType == 4">小巴</span>-->
            <!--                    <span v-else-if="scope.row.carType == 5">卡车</span>-->
            <!--                </template>-->
            <!--            </el-table-column>-->
            <el-table-column prop="speedValue" label="速度(KM/H)" align="center"></el-table-column>
            <el-table-column prop="status" label="结果" align="center">
                <template slot-scope="scope">
                    <span v-if="scope.row.status == 1">正常</span>
                    <span v-else-if="scope.row.status == 2">超速</span>
                    <span v-else-if="scope.row.status == 3">慢速</span>
                </template>
            </el-table-column>
            <!--            <el-table-column prop="img" label="抓拍图片" align="center">-->
            <!--                <template slot-scope="scope">-->
            <!--                    <el-image-->
            <!--                            style="width: 50px; height: 50px"-->
            <!--                            :src="scope.row.img?scope.row.img:require('../../assets/images/defaultImg.png')"-->
            <!--                            :preview-src-list="[scope.row.img?scope.row.img:require('../../assets/images/defaultImg.png')]">-->
            <!--                    </el-image>-->
            <!--                </template>-->
            <!--            </el-table-column>-->
        </el-table>
        <el-pagination background
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="filters.current"
                       :page-sizes="[10, 50, 200, 1000]"
                       :page-size="filters.size"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
        <el-dialog v-draggable  top="4vh" title="记录详情" :visible.sync="visibleDialog" width="50%">
            <el-form :model="visibleForm" ref="visibleForm" size="small" label-width="100px">
                <el-row>
                    <el-col :span="11">
                        <el-form-item label="时间">
                            <el-input v-model="visibleForm.speedTime" readonly/>
                        </el-form-item>
                        <el-form-item label="地点">
                            <el-input v-model="visibleForm.speedPositionName" readonly/>
                        </el-form-item>
                        <el-form-item label="车牌" prop="licensePlate">
                            <el-input v-model="visibleForm.licensePlate" placeholder="请输入车牌号"/>
                        </el-form-item>
                        <el-form-item label="车牌颜色">
                            <el-input v-model="visibleForm.plateColor" readonly/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11">
                        <!--                        <el-form-item label="车型">-->
                        <!--                            <el-input v-model="visibleForm.carType" readonly/>-->
                        <!--                        </el-form-item>-->
                        <el-form-item label="车速">
                            <el-input v-model="visibleForm.speedValue" readonly/>
                        </el-form-item>
                        <el-form-item label="结果">
                            <el-input v-model="visibleForm.status" readonly/>
                        </el-form-item>
                        <el-form-item label="车牌类型">
                            <el-input v-model="visibleForm.plateType" readonly/>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-image
                            :src="visibleForm.img?visibleForm.img:require('../../assets/images/defaultImg.png')"
                            :preview-src-list="[visibleForm.img?visibleForm.img:require('../../assets/images/defaultImg.png')]">
                    </el-image>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="previous()">上一条</el-button>
                <el-button type="primary" @click="next()">下一条</el-button>
                <el-button type="primary" @click="submitForm()">修 改</el-button>
                <el-button @click="visibleDialog = false">退 出</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import {getSpeedRecord, speedRecordEdit} from "../../api/speed";
    import {formatDate} from "../../utils";

    export default {
        name: "speedMeasurementRecord",
        data() {
            return {
                loading: false,
                total: 0,
                tableData: [],
                filters: {
                    current: 1,
                    size: 10,
                    value: "",
                    startTime: "",
                    endTime: "",
                    licensePlate: "",
                    status: ""
                },
                visibleDialog: false,
                visibleForm: {
                    id: "",
                    licensePlate: "",
                    plateColor: "",
                    img: "",
                    plateType: "",
                    carType: "",
                    speedValue: "",
                    status: "",
                    speedTime: "",
                    speedPositionName: "",
                },
                date: [],
                listData: [],
                allList: [],
                index: ""
            }
        },
        mounted() {
            this.fetchData();
        },
        methods: {
            fetchData() {
                this.filters.startTime = this.date && this.date.length ? formatDate(this.date[0], "yyyy-MM-dd") : "";
                this.filters.endTime = this.date && this.date.length ? formatDate(this.date[1], "yyyy-MM-dd") : "";
                let params = {
                    current: this.filters.current,
                    size: this.filters.size,
                    licensePlate: this.filters.licensePlate,
                    status: this.filters.status,
                    startTime: this.filters.startTime,
                    endTime: this.filters.endTime,
                    speedPositionName: this.filters.speedPositionName
                };
                this.loading = true;
                getSpeedRecord(params).then(res => {
                    this.loading = false;
                    if (res.data.code == "SUCCESS") {
                        this.tableData = res.data.data.records;
                        this.allList = res.data.data.records;
                        this.total = res.data.data.total;
                        this.current = res.data.data.current;
                        this.size = res.data.data.size;
                    }
                }).catch(() => {
                    this.loading = false;
                })
            },
            handleSearch() {
                this.filters.current = 1;
                this.fetchData();
            },
            handleSizeChange(val) {
                this.filters.size = val;
                this.filters.current = 1;
                this.fetchData();
            },
            handleCurrentChange(val) {
                this.filters.current = val;
                this.fetchData();
            },
            handleEdit(index, row) {
                this.visibleDialog = true;
                Object.keys(this.visibleForm).map(key => {
                    this.visibleForm[key] = row[key];
                });
                this.visibleForm.plateColor = this.visibleForm.plateColor ?
                    this.visibleForm.plateColor == 0 ? '未知' :
                        this.visibleForm.plateColor == 1 ? '蓝色' :
                            this.visibleForm.plateColor == 2 ? '黄色' :
                                this.visibleForm.plateColor == 3 ? '白色' :
                                    this.visibleForm.plateColor == 4 ? '黑色' :
                                        this.visibleForm.plateColor == 5 ? '绿色' :
                                            this.visibleForm.plateColor == 6 ? '黄绿色' :
                                                this.visibleForm.plateColor == 7 ? '其他' :
                                                    '' : '';
                this.visibleForm.carType = this.visibleForm.carType ?
                    this.visibleForm.carType == 0 ? '无效' :
                        this.visibleForm.carType == 1 ? '大巴' :
                            this.visibleForm.carType == 2 ? '小汽车' :
                                this.visibleForm.carType == 3 ? '中巴' :
                                    this.visibleForm.carType == 4 ? '小巴' :
                                        this.visibleForm.carType == 5 ? '卡车' :
                                            '' : '';
                this.visibleForm.status = this.visibleForm.status ?
                    this.visibleForm.status == 1 ? '正常' :
                        this.visibleForm.status == 2 ? '超速' :
                            this.visibleForm.status == 3 ? '慢速' :
                                '' : '';
                this.visibleForm.plateType = this.visibleForm.plateType ?
                    this.visibleForm.plateType == 0 ? '未知' :
                        this.visibleForm.plateType == 1 ? '普通蓝牌' :
                            this.visibleForm.plateType == 2 ? '普通黑牌' :
                                this.visibleForm.plateType == 3 ? '普通黄牌' :
                                    this.visibleForm.plateType == 4 ? '双层黄牌' :
                                        this.visibleForm.plateType == 5 ? '警察车牌' :
                                            this.visibleForm.plateType == 6 ? '双层武警' :
                                                this.visibleForm.plateType == 7 ? '单层军牌' :
                                                    this.visibleForm.plateType == 8 ? '双层军牌' :
                                                        this.visibleForm.plateType == 9 ? '个性车牌' :
                                                            this.visibleForm.plateType == 10 ? '新能源小车牌' :
                                                                this.visibleForm.plateType == 11 ? '新能源大车牌' :
                                                                    this.visibleForm.plateType == 12 ? '大使馆车牌' :
                                                                        this.visibleForm.plateType == 13 ? '领事馆车牌' :
                                                                            this.visibleForm.plateType == 14 ? '民航车牌' :
                                                                                this.visibleForm.plateType == 15 ? '其他' :
                                                                                    '' : '';
                this.index = index;
            },
            submitForm() {
                let params = {
                    id: this.visibleForm.id,
                    licensePlate: this.visibleForm.licensePlate,
                };
                speedRecordEdit(params).then(res => {
                    this.visibleDialog = false;
                    this.fetchData();
                    if (res.data.code == "SUCCESS") {
                        this.$message.success("编辑成功！");
                    } else {
                        this.$message.info(res.data.message);
                    }
                })
            },
            previous() {
                if (this.index > 0) {
                    this.index -= 1;
                    this.visibleForm = this.allList[this.index];
                    this.visibleForm.plateColor = this.visibleForm.plateColor ?
                        this.visibleForm.plateColor == 0 ? '未知' :
                            this.visibleForm.plateColor == 1 ? '蓝色' :
                                this.visibleForm.plateColor == 2 ? '黄色' :
                                    this.visibleForm.plateColor == 3 ? '白色' :
                                        this.visibleForm.plateColor == 4 ? '黑色' :
                                            this.visibleForm.plateColor == 5 ? '绿色' :
                                                this.visibleForm.plateColor == 6 ? '黄绿色' :
                                                    this.visibleForm.plateColor == 7 ? '其他' :
                                                        '' : '';
                    this.visibleForm.carType = this.visibleForm.carType ?
                        this.visibleForm.carType == 0 ? '无效' :
                            this.visibleForm.carType == 1 ? '大巴' :
                                this.visibleForm.carType == 2 ? '小汽车' :
                                    this.visibleForm.carType == 3 ? '中巴' :
                                        this.visibleForm.carType == 4 ? '小巴' :
                                            this.visibleForm.carType == 5 ? '卡车' :
                                                '' : '';
                    this.visibleForm.status = this.visibleForm.status ?
                        this.visibleForm.status == 1 ? '正常' :
                            this.visibleForm.status == 2 ? '超速' :
                                this.visibleForm.status == 3 ? '慢速' :
                                    '' : '';
                    this.visibleForm.plateType = this.visibleForm.plateType ?
                        this.visibleForm.plateType == 0 ? '未知' :
                            this.visibleForm.plateType == 1 ? '普通蓝牌' :
                                this.visibleForm.plateType == 2 ? '普通黑牌' :
                                    this.visibleForm.plateType == 3 ? '普通黄牌' :
                                        this.visibleForm.plateType == 4 ? '双层黄牌' :
                                            this.visibleForm.plateType == 5 ? '警察车牌' :
                                                this.visibleForm.plateType == 6 ? '双层武警' :
                                                    this.visibleForm.plateType == 7 ? '单层军牌' :
                                                        this.visibleForm.plateType == 8 ? '双层军牌' :
                                                            this.visibleForm.plateType == 9 ? '个性车牌' :
                                                                this.visibleForm.plateType == 10 ? '新能源小车牌' :
                                                                    this.visibleForm.plateType == 11 ? '新能源大车牌' :
                                                                        this.visibleForm.plateType == 12 ? '大使馆车牌' :
                                                                            this.visibleForm.plateType == 13 ? '领事馆车牌' :
                                                                                this.visibleForm.plateType == 14 ? '民航车牌' :
                                                                                    this.visibleForm.plateType == 15 ? '其他' :
                                                                                        '' : '';
                } else {
                    this.$message.error('已经是第一条');
                }
                console.log(this.index, '上', this.allList)
            },
            //
            next() {
                if (this.index < this.allList.length - 1) {
                    this.index += 1;
                    this.visibleForm = this.allList[this.index];
                    this.visibleForm.plateColor = this.visibleForm.plateColor ?
                        this.visibleForm.plateColor == 0 ? '未知' :
                            this.visibleForm.plateColor == 1 ? '蓝色' :
                                this.visibleForm.plateColor == 2 ? '黄色' :
                                    this.visibleForm.plateColor == 3 ? '白色' :
                                        this.visibleForm.plateColor == 4 ? '黑色' :
                                            this.visibleForm.plateColor == 5 ? '绿色' :
                                                this.visibleForm.plateColor == 6 ? '黄绿色' :
                                                    this.visibleForm.plateColor == 7 ? '其他' :
                                                        '' : '';
                    this.visibleForm.carType = this.visibleForm.carType ?
                        this.visibleForm.carType == 0 ? '无效' :
                            this.visibleForm.carType == 1 ? '大巴' :
                                this.visibleForm.carType == 2 ? '小汽车' :
                                    this.visibleForm.carType == 3 ? '中巴' :
                                        this.visibleForm.carType == 4 ? '小巴' :
                                            this.visibleForm.carType == 5 ? '卡车' :
                                                '' : '';
                    this.visibleForm.status = this.visibleForm.status ?
                        this.visibleForm.status == 1 ? '正常' :
                            this.visibleForm.status == 2 ? '超速' :
                                this.visibleForm.status == 3 ? '慢速' :
                                    '' : '';
                    this.visibleForm.plateType = this.visibleForm.plateType ?
                        this.visibleForm.plateType == 0 ? '未知' :
                            this.visibleForm.plateType == 1 ? '普通蓝牌' :
                                this.visibleForm.plateType == 2 ? '普通黑牌' :
                                    this.visibleForm.plateType == 3 ? '普通黄牌' :
                                        this.visibleForm.plateType == 4 ? '双层黄牌' :
                                            this.visibleForm.plateType == 5 ? '警察车牌' :
                                                this.visibleForm.plateType == 6 ? '双层武警' :
                                                    this.visibleForm.plateType == 7 ? '单层军牌' :
                                                        this.visibleForm.plateType == 8 ? '双层军牌' :
                                                            this.visibleForm.plateType == 9 ? '个性车牌' :
                                                                this.visibleForm.plateType == 10 ? '新能源小车牌' :
                                                                    this.visibleForm.plateType == 11 ? '新能源大车牌' :
                                                                        this.visibleForm.plateType == 12 ? '大使馆车牌' :
                                                                            this.visibleForm.plateType == 13 ? '领事馆车牌' :
                                                                                this.visibleForm.plateType == 14 ? '民航车牌' :
                                                                                    this.visibleForm.plateType == 15 ? '其他' :
                                                                                        '' : '';
                } else {
                    this.$message.error('已经是最后一条');
                }
                console.log(this.index, "下", this.allList)
            }
        }
    }
</script>

<style scoped>
    .user-search {
        margin-top: 15px;
    }

    /deep/ .el-dialog__body {
        padding: 0 20px;
    }
</style>
