<template>
    <!--左侧导航-->
    <div>
        <!--导航菜单-->
        <el-menu :default-active="$route.path" router :collapse="collapsed" ref="leftNavigation"
                 unique-opened
                 :collapse-transition="false"
                 background-color="#334157"
                 text-color="#fff"
                 active-text-color="#ffd04b">
            <template v-for="(item,index) in menuList">
                <!-- 注意：这里就是leftNavState状态作用之处，当该值与router的根路由的name相等时加载相应菜单组 -->
                <template v-if="item.component == leftNavState">
                    <template>
                        <el-submenu
                                v-if="item.path != '/monitorPreview' && item.path != '/personLargeDataScreen' && item.path != '/parkingSmartScreen' && item.path != '/parkingLot/homePage' && item.path != '/personnel/indexPage' && item.component != 'speedMain'"
                                :index="index+''" :key="index">
                            <template slot="title"><i :class="item.icon"></i><span slot="title">{{item.menuName}}</span>
                            </template>
                            <template v-for="term in item.children">
                                <el-menu-item :key="term.path" :index="term.path"
                                              :class="$route.path===term.path?'is-active':''">
                                    <i :class="term.icon"></i><span slot="title">{{term.menuName}}</span>
                                </el-menu-item>
                            </template>
                        </el-submenu>
                        <el-menu-item
                                v-else-if="item.path == '/monitorPreview' || item.path == '/personLargeDataScreen' || item.path == '/parkingSmartScreen' || item.path == '/parkingLot/homePage' || item.path == '/personnel/indexPage' || item.component == 'speedMain'"
                                :key="index" :index="item.path" :class="$route.path===item.path?'is-active':''">
                            <i :class="item.icon"></i><span slot="title">{{item.menuName}}</span>
                        </el-menu-item>
                    </template>
                </template>
            </template>
        </el-menu>
    </div>
</template>
<script>
    import bus from '../../utils/bus.js'

    export default {
        name: 'leftNav',
        data() {
            return {
                loading: false,
                collapsed: false,
                menuList: [],
                leftNavState: ""
            }
        },
        mounted() {
            bus.$on('collapsed', msg => {
                this.collapsed = msg;
            });
            bus.$on('leftNavState', msg => {
                this.leftNavState = msg;
            });
            bus.$on('menuList', msg => {
                this.menuList = msg;
            });
        },
    }
</script>
<style>
    .el-menu-vertical-demo:not(.el-menu--collapse) {
        width: 240px;
        min-height: 400px;
    }

    .el-menu-vertical-demo:not(.el-menu--collapse) {
        border: none;
        text-align: left;
    }

    .el-menu-item-group__title {
        padding: 0px;
    }

    .el-menu-bg {
        background-color: #1f2d3d !important;
    }

    .el-menu {
        border: none;
    }
</style>
