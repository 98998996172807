<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>商家优惠</el-breadcrumb-item>
            <el-breadcrumb-item>商家管理</el-breadcrumb-item>
        </el-breadcrumb>
        <!--    <div style="float: right">当前车场:xxx停车场</div>-->
        <!-- 搜索筛选 -->
        <el-form :inline="true" :model="filters" class="user-search">
            <el-form-item>
                <el-input size="small" v-model="filters.businessName" clearable placeholder="请输入商家名称"/>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-search" type="primary" size="small" @click="fetchData">查询</el-button>
                <el-button icon="el-icon-plus" type="primary" size="small" @click="handleAdd">添加</el-button>
            </el-form-item>
        </el-form>
        <!--列表-->
        <el-table
                size="small"
                :data="tableData"
                highlight-current-row
                v-loading="loading"
                element-loading-text="拼命加载中"
                border
                style="width: 100%"
                :row-style="{height:'45px'}"
                :cell-style="{padding:'0px'}"
                :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                stripe
                height="500"
                max-height="500"
                :default-sort="{prop: 'createTime', order: 'descending'}">
            <el-table-column min-width="30" type="index" label="序号"></el-table-column>
            <el-table-column prop="businessName" label="商家名称" align="center"></el-table-column>
            <el-table-column prop="businessNo" label="商家编号" align="center"></el-table-column>
            <el-table-column prop="logo" label="商家LOGO" align="center">
                <template slot-scope="scope">
                    <el-image
                            style="width: 50px; height: 50px"
                            :src="scope.row.logo?scope.row.logo:require('../../../assets/images/defaultImg.png')"
                            :preview-src-list="[scope.row.logo?scope.row.logo:require('../../../assets/images/defaultImg.png')]">
                        <div slot="error">
                            <el-image :src="require('../../../assets/images/defaultImg.png')">
                            </el-image>
                        </div>
                    </el-image>
                </template>
            </el-table-column>
            <el-table-column prop="remarks" label="商家描述" align="center"></el-table-column>
            <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
            <el-table-column width="300" align="center" label="操作">
                <template slot-scope="scope">
                    <el-button size="mini" @click="handleEdit(scope.$index, scope.row)" icon="el-icon-setting"
                               type="primary">编辑
                    </el-button>
                    <el-button size="mini" type="danger" class="el-icon-delete"
                               @click="handleDelete(scope.$index, scope.row)"
                    >删除
                    </el-button>
                    <el-button size="mini" type="warning" class="el-icon-edit"
                               @click="resetPassword(scope.$index, scope.row)"
                    >重置密码
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="filters.current"
                       :page-sizes="[10, 50, 200, 1000]"
                       :page-size="filters.size"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
        <el-dialog v-draggable :title="title" :visible.sync="addDialog">
            <el-form :model="editForm" ref="editForm" size="small" :rules="rules">
                <el-row>
                    <el-col :span="11">
                        <el-form-item label="商家名称" prop="businessName" :label-width="formLabelWidth">
                            <el-input size="small" v-model="editForm.businessName" placeholder="请输入内容"/>
                        </el-form-item>
                        <el-form-item label="商家编号" prop="businessNo" :label-width="formLabelWidth">
                            <el-input disabled size="small" v-model="editForm.businessNo"/>
                        </el-form-item>
                        <el-form-item label="商家LOGO" :label-width="formLabelWidth">
                            <div style="width:178px;height:178px;position:relative;">
              <span v-if="editForm.logo" class="el-upload-list__item-delete" @click="handleImageRemove()">
                <i class="el-icon-delete"></i>
              </span>
                                <el-upload
                                        class="avatar-uploader"
                                        action="#"
                                        :multiple="false"
                                        name="file"
                                        :auto-upload="false"
                                        :show-file-list="false"
                                        :on-change="handleChange">
                                    <img v-if="editForm.logo" :src="editForm.logo" class="avatar">
                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                </el-upload>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11">
                        <el-form-item label="商家描述" prop="remarks" :label-width="formLabelWidth">
                            <el-input size="small" v-model="editForm.remarks" placeholder="请输入内容"/>
                        </el-form-item>
                        <el-form-item label="登录账号" prop="accountName" :label-width="formLabelWidth">
                            <el-input :readonly="readonly" size="small" v-model="editForm.accountName"
                                      placeholder="请输入内容"/>
                        </el-form-item>
                        <el-form-item v-if="showPass" label="登录密码" prop="password" :label-width="formLabelWidth">
                            <el-input type="password" size="small" v-model="editForm.password" placeholder="请输入内容"/>
                        </el-form-item>
                        <el-form-item label="联系人姓名" prop="userName" :label-width="formLabelWidth">
                            <el-input size="small" v-model="editForm.userName" placeholder="请输入内容"/>
                        </el-form-item>
                        <el-form-item label="联系人电话" prop="phone" :label-width="formLabelWidth">
                            <el-input size="small" oninput="value=value.replace(/[^\d]/g,'')"
                                      v-model.number="editForm.phone" placeholder="请输入内容"/>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button @click="addDialog = false">取 消</el-button>
        <el-button type="primary" @click="confirm('editForm')">确 定</el-button>
      </span>
        </el-dialog>
    </div>
</template>

<script>
    import {
        businessDel,
        businessEdit,
        businessList,
        businessSave,
        businessReset,
        uploadFile
    } from "@/api/merchantDiscount/merchant";

    export default {
        name: "merchant",
        data() {
            return {
                loading: false,
                readonly: false,
                total: 0,
                tableData: [],
                filters: {
                    businessName: '',
                    current: 1,
                    size: 10,
                },
                title: "",
                addDialog: false,
                formLabelWidth: '140px',
                editForm: {
                    accountName: "",
                    businessName: "",
                    businessNo: "",
                    id: "",
                    logo: "",
                    password: "",
                    phone: "",
                    propertyId: "",
                    remarks: "",
                    userName: "",
                },
                rules: {
                    password: [{required: true, message: "请输入", trigger: "blur"}],
                    businessName: [{required: true, message: "请输入", trigger: "blur"}],
                    accountName: [{required: true, message: "请输入", trigger: "blur"}],
                },
                showPass: false,
            }
        },
        mounted() {
            this.fetchData();
        },
        methods: {
            fetchData() { //获取数据
                let that = this;
                let params = {
                    businessName: that.filters.businessName,
                    size: that.filters.size,
                    current: that.filters.current
                };
                that.loading = true;
                businessList(params).then((res) => {
                    that.loading = false;
                    that.tableData = res.data.data.records;
                    that.total = res.data.data.total;
                    that.filters.current = res.data.data.current;
                    that.filters.size = res.data.data.size;
                }).catch(function (error) {
                    that.loading = false;
                    console.log(error);
                });
            },
            handleSizeChange(val) {
                this.filters.size = val;
                this.filters.current = 1;
                this.fetchData();
            },
            handleCurrentChange(val) {
                this.filters.current = val;
                this.fetchData();
            },
            handleAdd() {
                this.title = '添加商家';
                this.addDialog = true;
                this.readonly = false;
                Object.keys(this.editForm).map(key => {
                    this.editForm[key] = '';
                })
                this.showPass = true;
            },
            handleEdit(index, row) {
                this.title = '编辑商家';
                this.addDialog = true;
                this.readonly = true;
                Object.keys(this.editForm).map(key => {
                    this.editForm[key] = row[key];
                });
                this.showPass = false;
            },
            confirm(editForm) {
                this.$refs[editForm].validate((valid) => {
                    if (valid) {
                        if (this.editForm.id) {
                            let param = {
                                id: this.editForm.id,
                                accountName: this.editForm.accountName,
                                businessName: this.editForm.businessName,
                                businessNo: this.editForm.businessNo,
                                phone: this.editForm.phone,
                                propertyId: this.editForm.propertyId,
                                remarks: this.editForm.remarks,
                                userName: this.editForm.userName,
                                logo: this.editForm.logo,
                            }
                            businessEdit(param).then((res) => {
                                this.addDialog = false;
                                this.fetchData();
                                if (res.data.code == "SUCCESS") {
                                    this.$message.success("编辑成功！");
                                } else {
                                    this.$message.info(res.data.message);
                                }
                            })
                        } else {
                            let param = {
                                accountName: this.editForm.accountName,
                                businessName: this.editForm.businessName,
                                businessNo: this.editForm.businessNo,
                                password: this.editForm.password,
                                phone: this.editForm.phone,
                                propertyId: this.editForm.propertyId,
                                remarks: this.editForm.remarks,
                                userName: this.editForm.userName,
                                logo: this.editForm.logo,
                            }
                            businessSave(param).then((res) => {
                                this.addDialog = false;
                                this.fetchData();
                                if (res.data.code == "SUCCESS") {
                                    this.$message.success("保存成功！");
                                } else {
                                    this.$message.info(res.data.message);
                                }
                            })
                        }
                    }
                })
            },
            handleDelete(index, row) {
                this.$confirm("确定要删除吗?", "温馨提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    businessDel(row.id).then((res) => {
                        if (res.data.code == "SUCCESS") {
                            this.fetchData();
                            this.$message.success("删除成功!");
                        } else {
                            this.$message.info(res.data.message);
                        }
                    })
                }).catch(() => {
                    this.$message.info("已取消删除");
                });
            },
            resetPassword(index, row) {
                let that = this;
                that.$confirm(
                    '是否确认重置密码?',
                    "警告",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    }
                ).then(function () {
                    businessReset(row.id).then((res) => {
                        if (res.data.code == "SUCCESS") {
                            that.$alert(
                                "该用户密码已重置为：" + res.data.data + "，请用新密码登录。",
                                "温馨提示", {
                                    confirmButtonText: '确定',
                                    callback: action => {
                                        console.log('成功')
                                    }
                                });
                            that.fetchData();
                        } else {
                            that.$message.info(res.data.message);
                        }
                    })
                }).catch(() => {
                    that.$message.info("已取消修改");
                });
            },
            handleChange(file) {
                const isLt10M = file.size / 1024 / 1024 < 10;
                var testmsg = /^image\/(jpeg|png|jpg)$/.test(file.raw.type);
                if (!testmsg) {
                    this.$message.error('上传图片只能是 JPEG|PNG|JPG 格式!');
                    return
                }
                if (!isLt10M) {
                    this.$message.error('上传图片大小不能超过 10MB!');
                    return;
                }
                let form = new FormData();
                form.append('file', file.raw);
                uploadFile(form).then((res) => {
                        this.$message.success("上传成功!");
                        this.editForm.logo = res.data.data;
                    }
                );
            },
            handleImageRemove() {
                let that = this;
                this.$confirm(
                    '是否确认删除?',
                    "警告",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    }
                ).then(function () {
                    that.editForm.value3 = '';
                });

            }
        }
    }
</script>

<style scoped>
    .user-search {
        margin-top: 10px;
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>
