<template>
    <div class="app-container">
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>系统管理</el-breadcrumb-item>
            <el-breadcrumb-item>个人中心</el-breadcrumb-item>
        </el-breadcrumb>
        <el-form :model="dataUserForm" ref="dataUserForm" :rules="rules" label-width="100px" style="margin-top: 20px">
            <el-row :gutter="24">
                <el-col :span="18">
                    <el-form-item label="用户名" prop="userName">
                        <el-input readonly v-model="dataUserForm.userName"/>
                    </el-form-item>
                    <el-form-item label="姓名" prop="userName">
                        <el-input v-model="dataUserForm.realName"/>
                    </el-form-item>
                    <el-form-item label="手机号码" prop="phoneNumber">
                        <el-input v-model="dataUserForm.phoneNumber"/>
                    </el-form-item>
                    <el-form-item label="邮箱">
                        <el-input v-model="dataUserForm.email"/>
                    </el-form-item>
                    <el-form-item label="密码" prop="password">
                        <div style="display: flex;">
                            <el-input type="password" readonly v-model="dataUserForm.password"/>
                            <el-button type="primary" size="small" @click="handleEditUser">修改密码</el-button>
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" size="small" @click="submitForm('dataUserForm')">保存</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <el-dialog v-draggable
                :close-on-click-modal="false"
                :visible.sync="dataUserDialog"
                width="500px"
                title="修改密码"
        >
            <el-form
                    size="small"
                    :model="dialogForm"
                    ref="dialogForm"
                    :rules="rules"
                    label-width="120px"
            >
                <el-row>
                    <el-form-item label="原密码" prop="oldPassword">
                        <el-input v-model="dialogForm.oldPassword" type="password"></el-input>
                    </el-form-item>
                </el-row>

                <el-row>
                    <el-form-item label="新密码" prop="newPassword">
                        <el-input v-model="dialogForm.newPassword" type="password"></el-input>
                    </el-form-item>
                </el-row>

                <el-row>
                    <el-form-item label="确认密码" prop="confirmPassword">
                        <el-input v-model="dialogForm.confirmPassword" type="password"></el-input>
                    </el-form-item>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dataUserDialog = false" size="mini">取 消</el-button>
              <el-button type="primary" @click="updatePassword" size="mini">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import {update} from "../../../api/login";

    export default {
        name: "personal",
        inject: ['reload'],
        data() {
            var validatePass2 = (rule, value, callback) => {
                if (value === "") {
                    callback(new Error("请再次输入密码"));
                } else if (value !== this.dialogForm.newPassword) {
                    callback(new Error("两次输入密码不一致!"));
                } else {
                    callback();
                }
            };
            return {
                dataUserForm: {
                    id: "",
                    userName: "",
                    password: "",
                    email: "",
                    phoneNumber: "",
                    realName: "",
                },
                rules: {
                    userName: [
                        {required: true, message: "用户名不能为空", trigger: "blur"},
                    ],
                    phoneNumber: [
                        {required: true, message: "联系电话不能为空", trigger: "blur"},
                        {
                            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
                            message: "请输入正确的联系电话",
                            trigger: "blur",
                        },
                    ],
                    salesAfterNumber: [
                        {
                            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
                            message: "请输入正确的联系电话",
                            trigger: "blur",
                        },
                    ],
                    oldPassword: [
                        {required: true, message: "用户密码不能为空", trigger: "blur"},
                        {min: 6, message: "用户密码不能少于六位数", trigger: "blur"},
                    ],

                    newPassword: [
                        {required: true, message: "用户密码不能为空", trigger: "blur"},
                        {min: 6, message: "用户密码不能少于六位数", trigger: "blur"},
                    ],

                    confirmPassword: [
                        {required: true, message: "请确认密码", trigger: "blur"},
                        {min: 6, message: "长度不能少于6个字符", trigger: "blur"},
                        {validator: validatePass2, trigger: "blur", required: true},
                    ],
                },
                dialogForm: {
                    oldPassword: "",
                    newPassword: "",
                    confirmPassword: "",
                },

                dataUserDialog: false,
            }
        },
        mounted() {
            let user = window.localStorage.getItem('userdata');
            user = JSON.parse(user);
            this.dataUserForm.id = user.id;
            this.dataUserForm.userName = user.username;
            this.dataUserForm.realName = user.realName;
            this.dataUserForm.password = user.password;
            this.dataUserForm.phoneNumber = user.phone;
            this.dataUserForm.email = user.email;
        },
        methods: {
            handleEditUser() {
                this.dataUserDialog = true;
            },
            submitForm(dataUserForm) {
                this.$refs[dataUserForm].validate((valid) => {
                    if (valid) {
                        let param = {
                            id: this.dataUserForm.id,
                            username: this.dataUserForm.userName,
                            realName: this.dataUserForm.realName,
                            // password: this.dataUserForm.password,
                            phone: this.dataUserForm.phoneNumber,
                            email: this.dataUserForm.email,
                        }
                        update(param).then((res) => {
                            this.dataUserDialog = false;
                            if (res.data.code == "SUCCESS") {
                                localStorage.setItem('userdata', JSON.stringify(res.data.data))
                                this.$message({
                                    type: "success",
                                    message: "保存成功！",
                                });
                                this.reload()
                            } else {
                                this.dataUserDialog = false;
                                this.$message({
                                    type: "info",
                                    message: res.data.message,
                                });
                            }
                        })
                    }
                })
            },
            updatePassword() {
                let param = {
                    id: this.dataUserForm.id,
                    username: this.dataUserForm.userName,
                    realName: this.dataUserForm.realName,
                    password: this.dialogForm.confirmPassword,
                    phone: this.dataUserForm.phoneNumber,
                }
                update(param).then((res) => {
                    this.dataUserDialog = false;
                    if (res.data.code == "SUCCESS") {
                        localStorage.setItem('userdata', JSON.stringify(res.data.data))
                        this.$message({
                            type: "success",
                            message: "保存成功！",
                        });
                        this.reload()
                    } else {
                        this.$message({
                            type: "info",
                            message: res.data.message,
                        });
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>
