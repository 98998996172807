<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>商家优惠</el-breadcrumb-item>
            <el-breadcrumb-item>优惠模板</el-breadcrumb-item>
        </el-breadcrumb>
        <!--    <div style="float: right">当前车场:xxx停车场</div>-->
        <!-- 搜索筛选 -->
        <el-form :inline="true" :model="filters" class="user-search">
            <el-form-item>
                <el-select size="small" v-model="filters.type" clearable filterable placeholder="请选择优惠类型">
                    <el-option label="现金券" value="1"></el-option>
                    <el-option label="折扣券" value="2"></el-option>
                    <el-option label="全免" value="3"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-search" type="primary" size="small" @click="fetchData">查询</el-button>
                <el-button icon="el-icon-plus" type="primary" size="small" @click="handleAdd">添加</el-button>
            </el-form-item>
        </el-form>
        <!--列表-->
        <el-table
                size="small"
                :data="tableData"
                highlight-current-row
                v-loading="loading"
                element-loading-text="拼命加载中"
                border
                style="width: 100%"
                :row-style="{height:'45px'}"
                :cell-style="{padding:'0px'}"
                :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                stripe
                height="500"
                max-height="500"
                :default-sort="{prop: 'createTime', order: 'descending'}"
        >
            <el-table-column min-width="30" type="index" label="序号"></el-table-column>
            <el-table-column prop="name" label="模板名称" align="center"></el-table-column>
            <el-table-column prop="type" label="优惠类型" align="center">
                <template slot-scope="scope">
                    <el-tag type="success" v-if="scope.row.type == 1">现金券</el-tag>
                    <el-tag type="primary" v-else-if="scope.row.type == 2">折扣券</el-tag>
                    <el-tag type="danger" v-else-if="scope.row.type == 3">全 免</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="preferential" label="优惠值" align="center">
                <template slot-scope="scope">
                    <span v-if="scope.row.type == 2">{{ scope.row.preferential*10 }}</span>
                    <span v-else>{{ scope.row.preferential }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="remarks" label="备注" align="center"></el-table-column>
            <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
            <el-table-column align="center" label="操作">
                <!--        min-width="160"-->
                <template slot-scope="scope">
                    <!--          <el-button size="mini" @click="handleEdit(scope.$index, scope.row)" icon="el-icon-setting"-->
                    <!--                     type="primary">编辑-->
                    <!--          </el-button>-->
                    <el-button size="mini" type="danger" class="el-icon-delete"
                               @click="handleDelete(scope.$index, scope.row)"
                    >删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="filters.current"
                       :page-sizes="[10, 50, 200, 1000]"
                       :page-size="filters.size"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
        <el-dialog v-draggable  :title="title" :visible.sync="addDialog">
            <el-form :model="editForm" ref="editForm" size="mini" :rules="rules">
                <el-row :gutter="24">
                    <el-col :span="20">
                        <el-form-item label="模板名称" prop="name" :label-width="formLabelWidth">
                            <el-input size="small" v-model="editForm.name" placeholder="请输入内容"/>
                        </el-form-item>
                        <el-form-item label="备注" prop="remarks" :label-width="formLabelWidth">
                            <el-input size="small" v-model="editForm.remarks" placeholder="请输入内容"/>
                        </el-form-item>
                        <el-form-item label="优惠规则" prop="type" :label-width="formLabelWidth">
                            <div style="display: flex;">
                                <el-radio v-model="editForm.type" :label="1">现金优惠</el-radio>
                                <el-input size="small" v-model="editForm.value1"
                                          oninput="value=value.replace(/[^0-9.]/g,'')" placeholder="请输入"/>
                                <span style="margin-left: 5px;width: 40px;">元</span>
                            </div>
                            <div style="display: flex;margin-top: 10px">
                                <el-radio v-model="editForm.type" :label="2">折扣优惠</el-radio>
                                <el-input size="small" v-model="editForm.value2"
                                          oninput="value=value.replace(/[^0-9.]/g,'')" placeholder="请输入"/>
                                <span style="margin-left: 5px;width: 40px;">折</span>
                            </div>
                            <div style="display: flex;margin-top: 10px">
                                <el-radio v-model="editForm.type" :label="3">全免优惠</el-radio>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button @click="addDialog = false">取 消</el-button>
        <el-button type="primary" @click="confirm('editForm')">确 定</el-button>
      </span>
        </el-dialog>
    </div>
</template>

<script>
    import {couponModelList, couponModelSave, couponModelDel} from "@/api/merchantDiscount/coupon";

    export default {
        name: "longTermRentRate",
        data() {
            return {
                loading: false,
                total: 0,
                tableData: [],
                filters: {
                    type: '',
                    current: 1,
                    size: 10,
                },
                title: "",
                addDialog: false,
                formLabelWidth: '140px',
                editForm: {
                    id: "",
                    name: "",
                    preferential: "",
                    propertyId: "",
                    remarks: "",
                    type: "",
                    value1: "",
                    value2: "",
                },
                rules: {
                    name: [{required: true, message: "请输入", trigger: "blur"}],
                    type: [{required: true, message: "请选择", trigger: "change"}],
                },
            }
        },
        mounted() {
            this.fetchData();
        },
        methods: {
            fetchData() { //获取数据
                let that = this;
                let params = {
                    current: that.filters.current,
                    size: that.filters.size,
                    type: that.filters.type
                };
                that.loading = true;
                couponModelList(params).then((res) => {
                    that.loading = false;
                    that.tableData = res.data.data.records;
                    that.total = res.data.data.total;
                    that.filters.current = res.data.data.current;
                    that.filters.size = res.data.data.size;
                }).catch((error) => {
                    that.loading = false;
                    console.log(error);
                });
            },
            handleSizeChange(val) {
                this.filters.size = val;
                this.filters.current = 1;
                this.fetchData();
            },
            handleCurrentChange(val) {
                this.filters.current = val;
                this.fetchData();
            },
            handleAdd() {
                this.title = '优惠模板';
                this.addDialog = true;
                Object.keys(this.editForm).map(key => {
                    this.editForm[key] = '';
                })
            },
            confirm(editForm) {
                this.$refs[editForm].validate((valid) => {
                    if (valid) {
                        if (this.editForm.type === 2) {
                            this.editForm.preferential = this.editForm.value2 / 10;
                        } else {
                            this.editForm.preferential = this.editForm.value1;
                        }
                        let param = {
                            id: this.editForm.id,
                            name: this.editForm.name,
                            preferential: this.editForm.preferential,
                            propertyId: this.editForm.propertyId,
                            remarks: this.editForm.remarks,
                            type: this.editForm.type,
                        }
                        couponModelSave(param).then((res) => {
                            this.addDialog = false;
                            this.fetchData();
                            if (res.data.code == "SUCCESS") {
                                this.$message({
                                    type: "success",
                                    message: "保存成功！",
                                });
                            } else {
                                this.$message({
                                    type: "info",
                                    message: res.data.message,
                                });
                            }
                        })
                    }
                })
            },
            handleEdit() {
                this.title = '编辑';
                this.addDialog = true;
            },
            handleDelete(index, row) {
                this.$confirm("是否确认删除数据?", "温馨提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    couponModelDel(row.id).then((res) => {
                        if (res.data.code == "SUCCESS") {
                            this.fetchData();
                            this.$message({
                                type: "success",
                                message: "删除成功!",
                            });
                        } else {
                            this.$message({
                                type: "info",
                                message: res.data.message,
                            });
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
            },
        }
    }
</script>

<style scoped>
    .user-search {
        margin-top: 10px;
    }
</style>
