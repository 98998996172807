<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>人员管理</el-breadcrumb-item>
            <el-breadcrumb-item>黑名单列表</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="clickBtn">
            <el-button icon="el-icon-search" type="primary" size="small" @click="getBlackList">刷新</el-button>
            <el-button type="primary" size="small" @click="handleAdd">添加</el-button>
            <el-button type="primary" size="small" @click="handleBatchDelete">批量删除</el-button>
        </div>
        <!--列表-->
        <el-table
                size="small"
                :data="tableData"
                @selection-change="handleSelectionChange"
                ref="blackListTable"
                highlight-current-row
                border
                v-loading="loading"
                element-loading-text="拼命加载中"
                style="width: 100%"
                :default-sort="{prop: 'passTime', order: 'descending'}"
                :row-style="{height:'45px'}"
                :cell-style="{padding:'0px'}"
                :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                stripe height="500" max-height="500">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column prop="name" label="姓名" align="center"></el-table-column>
            <el-table-column prop="phone" label="手机号" align="center"></el-table-column>
            <el-table-column prop="idCardNo" label="身份证号" align="center"></el-table-column>
            <el-table-column prop="passNo" label="卡号" align="center"></el-table-column>
            <el-table-column prop="createTime" label="添加时间" align="center"></el-table-column>
            <el-table-column prop="avatar" label="头像" align="center">
                <template slot-scope="scope">
                    <el-image style="width: 50px; height: 50px"
                              :src="scope.row.avatar?scope.row.avatar:require('../../../assets/images/defaultImg.png')"
                              :preview-src-list="[scope.row.avatar?scope.row.avatar:require('../../../assets/images/defaultImg.png')]">
                        <div slot="error">
                            <el-image :src="require('../../../assets/images/defaultImg.png')">
                            </el-image>
                        </div>
                    </el-image>
                </template>
            </el-table-column>
            <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                    <el-button size="mini" type="danger" class="el-icon-delete" @click="handleDelete(scope.row)">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="filters.current"
                       :page-sizes="[10, 50, 200, 1000]"
                       :page-size="filters.size"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
        <el-dialog v-draggable  title="添加黑名单" :visible.sync="addDialog" width="500px">
            <el-form :model="editForm" ref="editForm" size="mini" :rules="rules">
                <el-row>
                    <el-col :span="20">
                        <el-form-item label="姓名" prop="name" :label-width="formLabelWidth">
                            <el-input size="small" v-model="editForm.name" placeholder="请输入内容"/>
                        </el-form-item>
                        <el-form-item label="手机号" prop="phone" :label-width="formLabelWidth">
                            <el-input size="small" v-model="editForm.phone" oninput="value=value.replace(/[^\d]/g,'')"
                                      placeholder="请输入内容"/>
                        </el-form-item>
                        <el-form-item label="身份证号" prop="idCardNo" :label-width="formLabelWidth">
                            <el-input size="small" v-model="editForm.idCardNo" placeholder="请输入内容"/>
                        </el-form-item>
                        <el-form-item label="卡号" prop="passNo" :label-width="formLabelWidth">
                            <el-input size="small" v-model="editForm.passNo" oninput="value=value.replace(/[^\d]/g,'')"
                                      placeholder="请输入内容"/>
                        </el-form-item>
                        <el-form-item label="头像" prop="avatar" :label-width="formLabelWidth">
                            <el-upload
                                    class="avatar-uploader"
                                    action="#"
                                    :multiple="false"
                                    name="file"
                                    :auto-upload="false"
                                    :show-file-list="false"
                                    :on-change="handleChange">
                                <img v-if="editForm.avatar" :src="editForm.avatar"
                                     class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-form-item :label-width="formLabelWidth">
                        <el-button @click="addDialog = false">取 消</el-button>
                        <el-button type="primary" @click="confirm('editForm')">确 定</el-button>
                    </el-form-item>
                </el-row>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    import {addBlacklist, delBlacklist, getBlacklist, uploadFile} from "../../../api/organizationApi";

    export default {
        name: "personnelBlacklist",
        data() {
            return {
                loading: false,
                tableData: [],
                multipleSelection: [],
                total: 0,
                filters: {
                    current: 1,
                    size: 10,
                },
                addDialog: false,
                formLabelWidth: '140px',
                editForm: {
                    name: "",
                    phone: "",
                    idCardNo: "",
                    passNo: "",
                    avatar: "",
                },
                rules: {
                    name: [{required: true, message: "请输入", trigger: "blur"}],
                    phone: [{required: true, message: "请输入", trigger: "blur"}],
                },
            }
        },
        mounted() {
            this.getBlackList();
        },
        methods: {
            getBlackList() {
                this.loading = true;
                getBlacklist(this.filters).then((res) => {
                    this.loading = false;
                    if (res.data.code == "SUCCESS") {
                        this.tableData = res.data.data.records;
                        this.total = res.data.data.total;
                        this.filters.current = res.data.data.current;
                        this.filters.size = res.data.data.size;
                    }
                }).catch(() => {
                    this.loading = false;
                })
            },
            handleSizeChange(val) {
                this.filters.size = val;
                this.filters.current = 1;
                this.getBlackList();
            },
            handleCurrentChange(val) {
                this.filters.current = val;
                this.getBlackList();
            },
            handleAdd() {
                this.addDialog = true;
                Object.keys(this.editForm).map(key => {
                    this.editForm[key] = '';
                })
            },
            confirm(editForm) {
                this.$refs[editForm].validate((valid) => {
                    if (valid) {
                        addBlacklist(this.editForm).then(res => {
                            this.addDialog = false;
                            this.getBlackList();
                            if (res.data.code == "SUCCESS") {
                                this.$message.success(res.data.message);
                            }
                        })
                    }
                })
            },
            handleSelectionChange(selection) {
                this.multipleSelection = [];
                selection.forEach(element => {
                    this.multipleSelection.push(element.id);
                });
            },
            handleBatchDelete() {
                this.$confirm("是否确认批量删除数据?", "温馨提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    delBlacklist(this.multipleSelection).then(res => {
                        this.$refs.blackListTable.clearSelection();
                        this.getBlackList();
                        if (res.data.code == "SUCCESS") {
                            this.$message.success(res.data.message);
                        }
                    })
                })
            },
            handleDelete(row) {
                this.$confirm("是否确认删除数据?", "温馨提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    this.multipleSelection = [];
                    this.multipleSelection.push(row.id);
                    delBlacklist([row.id]).then(res => {
                        this.getBlackList();
                        if (res.data.code == "SUCCESS") {
                            this.$message.success(res.data.message);
                        }
                    })
                })
            },
            handleChange(file) {
                const isLt10M = file.size / 1024 / 1024 < 10;
                var testmsg = /^image\/(jpeg|png|jpg)$/.test(file.raw.type);
                if (!testmsg) {
                    this.$message.error('上传图片只能是 JPEG|PNG|JPG 格式!');
                    return
                }
                if (!isLt10M) {
                    this.$message.error('上传图片大小不能超过 10MB!');
                    return;
                }
                let form = new FormData();
                form.append('file', file.raw);
                uploadFile(form).then((res) => {
                    this.$message.success("上传成功!");
                    this.editForm.avatar = res.data.data;
                });
            },
        }
    }
</script>

<style scoped>
    .clickBtn {
        margin: 20px 0px 10px 0px
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>
