<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>增值服务</el-breadcrumb-item>
            <el-breadcrumb-item>应用列表</el-breadcrumb-item>
        </el-breadcrumb>
        <div style="background: #f9f9f9;">
            <el-divider></el-divider>
            <div style="display: flex;flex-direction: row;justify-content: space-between;align-items: center;margin: 0 10px;">
                <div style="display: flex;flex-direction: row;">
                    <img src="../../../assets/images/info.png" style="width: 100px;height: 100px;">
                    <div style="margin: 0 10px;display: flex;flex-direction: column;justify-content: space-around">
                        <div style="display: flex;flex-direction: row;line-height: 36px;">
                            <h1>模板消息</h1>
                            <el-tag @click="jumpTo('/enterprise/addRenewal?billingObjectCode=1')"
                                    style="margin: 0 10px;cursor: pointer;" type="danger" v-if="listData.length == 0">
                                未开通
                            </el-tag>
                            <el-tag @click="jumpTo('/enterprise/addRenewal?billingObjectCode=1&endTime='+ endTime +'&billingUnit=' + billingUnit)"
                                    style="margin: 0 10px;cursor: pointer;"
                                    :type="expired == true ? 'warning' : expired == false ? 'success' : ''" v-else>
                                {{expired == true ? '已过期' : expired == false ? '续费' : ''}}
                            </el-tag>
                        </div>
                        <div>主要是提供一种公众号提醒。包括车场内商业推广服务、出入场车主信息推送以及其他访客预约信息。</div>
                        <div>接收和月卡快到期提醒。</div>
                    </div>
                </div>
                <div>
                    <el-switch @change="handleChange" :disabled="disabled" v-model="value1" active-text="开"
                               inactive-text="关"/>
                </div>
            </div>
            <el-divider></el-divider>
        </div>
<!--        <div style="background: #fff;">-->
<!--            &lt;!&ndash;            <el-divider></el-divider>&ndash;&gt;-->
<!--            <div-->
<!--                    style="display: flex;flex-direction: row;justify-content: space-between;align-items: center;margin: 0 10px;">-->
<!--                <div style="display: flex;flex-direction: row;">-->
<!--                    <img src="../../../assets/images/icon10.png" style="width: 100px;height: 100px;">-->
<!--                    <div style="margin: 0 10px;display: flex;flex-direction: column;justify-content: space-around">-->
<!--                        <div style="display: flex;flex-direction: row;line-height: 36px;">-->
<!--                            <h1>物联网卡综合服务</h1>-->
<!--                            <el-tag @click="jumpTo('/enterprise/nesting')" style="margin: 0 10px;cursor: pointer;"-->
<!--                                    type="danger">点击进入-->
<!--                            </el-tag>-->
<!--                        </div>-->
<!--                        <div>物联网卡综合服务，包括流量查询、费用统计、分润查询及统计等</div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <el-divider></el-divider>-->
<!--        </div>-->
    </div>
</template>

<script>
    import {instanceList, instanceOpen} from "../../../api/basicServices";

    export default {
        name: "applicationList",
        data() {
            return {
                value1: "true",
                //数据
                listData: [],
                disabled: false,
                funcModuleCode: "",
                expired: "",
                status: "",
                endTime: "",
                billingUnit: "",
            }
        },
        mounted() {
            this.getInstanceList();
        },
        methods: {
            jumpTo(url) {
                this.$router.push(url); //用go刷新
            },
            getInstanceList() {
                instanceList().then(res => {
                    if (res.data.data.length > 0) {
                        this.disabled = false;
                        this.listData = res.data.data;
                        this.listData.forEach(item => {
                            if (item.funcModuleCode == 1) {
                                this.expired = item.expired;
                                this.status = item.status;
                                this.endTime = item.endTime;
                                this.funcModuleCode = item.funcModuleCode;
                                this.billingUnit = item.billingUnit;
                                //    1-开启，2-关闭
                                this.value1 = this.status == 1 ? true : this.status == 2 ? false : '';
                            }
                        })
                    } else {
                        this.disabled = true;
                    }
                })
            },
            handleChange(e) {
                instanceOpen(this.funcModuleCode, e).then(res => {
                    if (res.data.code == 'SUCCESS') {
                        this.getInstanceList();
                        this.$message.success(res.data.message);
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>
