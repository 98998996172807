<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>统计分析</el-breadcrumb-item>
            <el-breadcrumb-item>收入统计</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 搜索筛选 -->
        <el-form :inline="true" :model="queryForm" class="user-search">
            <el-form-item>
                <el-date-picker
                        v-model="queryForm.month"
                        type="month"
                        format="yyyy 年 MM 月"
                        value-format="yyyy-MM"
                        placeholder="请选择月份"
                        :clearable="false">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-search" type="primary" size="small" @click="fetchData">查询</el-button>
            </el-form-item>
        </el-form>
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="临停" name="first">
                <temporary-stop v-if="activeName=='first'" ref="temporary" :month="queryForm.month"></temporary-stop>
            </el-tab-pane>
            <el-tab-pane label="长租" name="second">
                <long-rent v-if="activeName=='second'" ref="long" :month="queryForm.month"></long-rent>
            </el-tab-pane>
            <el-tab-pane label="优惠券" name="third">
                <coupon v-if="activeName=='third'" ref="coupon" :month="queryForm.month"></coupon>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    import temporaryStop from './temporaryStop'
    import longRent from './longRent'
    import coupon from './coupon'
    import {formatDate} from "../../../utils";

    export default {
        name: "project",
        data() {
            return {
                queryForm: {
                    month: formatDate(new Date(), "yyyy-MM"),
                },
                activeName: 'first'
            }
        },
        components: {
            temporaryStop,
            longRent,
            coupon,
        },
        created() {
            // this.fetchData()
        },
        methods: {
            fetchData() {
                if (this.$refs.temporary) {
                    this.$refs.temporary.getTmpIncomeData(formatDate(this.queryForm.month, "yyyy"), formatDate(this.queryForm.month, "MM"));
                } else if (this.$refs.long) {
                    this.$refs.long.getLongIncomeData(formatDate(this.queryForm.month, "yyyy"), formatDate(this.queryForm.month, "MM"));
                } else if (this.$refs.coupon) {
                    this.$refs.coupon.getCouponData(formatDate(this.queryForm.month, "yyyy"), formatDate(this.queryForm.month, "MM"));
                }
            },
            handleClick(tab, event) {
            },
        }
    }
</script>

<style scoped>
    .el-table th {
        text-align: center;
    }

    .el-table tbody tr td:first-child {
        text-align: center;
    }

    .user-search {
        margin-top: 20px;
    }

    .el-dialog__wrapper {
        overflow: hidden;
    }
</style>
