<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>记录查询</el-breadcrumb-item>
            <el-breadcrumb-item>临时车缴费记录</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 搜索筛选 -->
        <el-form :inline="true" :model="filters" class="user-search">
            <el-form-item>
                <el-input size="small" v-model="filters.licensePlate" placeholder="请输入车牌号码"
                          @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
            <el-form-item>
                <el-date-picker
                        size="small"
                        v-model="date"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-select size="small" v-model="filters.payType" clearable filterable placeholder="请选择缴费方式">
                    <el-option label="线上支付" value="1"></el-option>
                    <el-option label="现金" value="2"></el-option>
                    <el-option label="手动放行" value="3"></el-option>
                    <el-option label="ETC缴费" value="4"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-search" type="primary" size="small" @click="handleSearch">查询</el-button>
                <!--        <el-button type="primary" size="small" @click="fetchData">导入</el-button>-->
                <el-button type="primary" size="small" @click="handleExportExcel">导出</el-button>
            </el-form-item>
        </el-form><!--列表-->
        <el-table
                id="out-table"
                size="small"
                :data="tableData"
                highlight-current-row
                border
                v-loading="loading"
                element-loading-text="拼命加载中"
                style="width: 100%"
                :row-style="{height:'45px'}"
                :cell-style="{padding:'0px'}"
                :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                stripe
                height="500"
                max-height="500"
                :default-sort="{prop: 'payTime', order: 'descending'}"
        >
            <el-table-column min-width="30" type="index" label="序号"></el-table-column>
            <el-table-column prop="licensePlate" label="车牌号码" align="center">
                <template slot-scope="scope">
                    {{scope.row.licensePlate?scope.row.licensePlate:'无牌车'}}
                </template>
            </el-table-column>
            <el-table-column prop="rfidNo" label="卡号" align="center"></el-table-column>
            <el-table-column prop="payTime" label="缴费时间" align="center"></el-table-column>
            <el-table-column prop="realAmount" label="实付金额" align="center">
                <template slot-scope="scope">
                    {{scope.row.realAmount ? scope.row.realAmount > 0 ? scope.row.realAmount : 0 : 0}}
                </template>
            </el-table-column>
            <el-table-column prop="amount" label="应付金额(元)" align="center">
                <template slot-scope="scope">
                    {{scope.row.amount ? scope.row.amount > 0 ? scope.row.amount : 0 : 0}}
                </template>
            </el-table-column>
            <el-table-column prop="discountAmount" label="优惠抵扣" align="center"></el-table-column>
            <el-table-column prop="payType" label="缴费方式" align="center">
                <template slot-scope="scope">
                    <el-tag type="primary" v-if="scope.row.payType == 1">线上支付</el-tag>
                    <el-tag type="success" v-else-if="scope.row.payType == 2">现金支付</el-tag>
                    <el-tag type="warning" v-else-if="scope.row.payType == 3">手动放行</el-tag>
                    <el-tag type="danger" v-else-if="scope.row.payType == 4">ETC缴费</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="collector" label="收费人员" align="center"></el-table-column>
        </el-table>
        <el-pagination background
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="filters.current"
                       :page-sizes="[10, 100, 200, 500, 1000]"
                       :page-size="filters.size"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
        <!--        选择导出文件类型-->
        <el-dialog v-draggable  title="文件类型" :visible.sync="selectDialog" width="250px">
            <el-form :model="editForm" ref="editForm" size="small" :rules="rules">
                <el-form-item prop="radio" label-width="50px">
                    <el-radio v-model="editForm.radio" label="xls">xls</el-radio>
                    <el-radio v-model="editForm.radio" label="xlsx">xlsx</el-radio>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="selectDialog = false">取 消</el-button>
        <el-button size="small" type="primary" @click="onExportExcel('editForm')">确 定</el-button>
        </span>
        </el-dialog>
    </div>
</template>

<script>
    import {formatDate} from "../../../utils";
    import {longPayRecord, temporaryPayRecord} from "../../../api/record/vehiclelist";

    export default {
        name: "paymentRecord",
        data() {
            return {
                loading: false,
                total: 0,
                tableData: [],
                filters: {
                    current: 1,
                    direction: '',
                    endTime: '',
                    licensePlate: '',
                    parkingType: '',
                    size: 10,
                    startTime: '',
                    payType: '',
                },
                selectDialog: false,
                editForm: {
                    radio: "",
                },
                rules: {
                    radio: [
                        {required: true, message: '请选择导出类型', trigger: 'change'}
                    ],
                },
                date: [],
            }
        },
        mounted() {
            this.fetchData();  //调用接口获取动态数据
        },
        methods: {
            fetchData() { //获取数据
                let that = this;
                that.filters.startTime =
                    that.date && that.date.length
                        ? formatDate(that.date[0], "yyyy-MM-dd")
                        : "";
                that.filters.endTime =
                    that.date && that.date.length
                        ? formatDate(that.date[1], "yyyy-MM-dd")
                        : "";
                that.loading = true;
                temporaryPayRecord(that.filters).then((res) => {
                    that.loading = false;
                    that.tableData = res.data.data.records;
                    that.total = res.data.data.total;
                    that.filters.current = res.data.data.current;
                    that.filters.size = res.data.data.size;
                }).catch(error => {
                    that.loading = false;
                    console.log(error);
                });
            },
            handleSearch() {
                this.filters.current = 1;
                this.fetchData();
            },
            handleSizeChange(val) {
                this.filters.size = val;
                this.filters.current = 1;
                this.fetchData();
            },
            handleCurrentChange(val) {
                this.filters.current = val;
                this.fetchData();
            },
            handleExportExcel() {
                this.selectDialog = true;
                this.editForm.radio = "";
            },
            //导出列表
            onExportExcel(editForm) {
                this.$refs[editForm].validate((valid) => {
                    if (valid) {
                        this.$nextTick(function () {
                            let tables = document.getElementById("out-table");
                            let table_book = this.$XLSX.utils.table_to_book(tables);
                            var table_write = this.$XLSX.write(table_book, {
                                bookType: this.editForm.radio,
                                bookSST: true,
                                type: "array"
                            });
                            try {
                                this.$FileSaver.saveAs(
                                    new Blob([table_write], {type: "application/octet-stream"}),
                                    "临时车缴费记录." + this.editForm.radio
                                );
                            } catch (e) {
                                if (typeof console !== "undefined") console.log(e, table_write);
                            }
                            return table_write;
                        });
                    } else {
                        return false;
                    }
                });
            },
        }
    }
</script>

<style scoped>
    .el-table th {
        text-align: center;
    }

    .el-table tbody tr td:first-child {
        text-align: center;
    }

    .user-search {
        margin-top: 20px;
    }
</style>
