<template>
    <div class="indexMain">
        <el-alert v-if="show" style="z-index: 999;height: 40px;"
                  title="网络异常已中断"
                  type="warning"
                  center
                  :closable="false">
        </el-alert>
        <div id="index" ref="appRef">
            <div class="bg">
                <dv-loading v-show="loading">Loading...</dv-loading>
                <div class="host-body">
                    <div>
                        <!-- 顶部title部分 -->
                        <el-row>
                            <el-col :span="6">
                                <div class="title_return">
                                    <router-link :to="{path:'/personnel/indexPage'}" style="color: #ffffff;width: 130px;">返 回
                                    </router-link>
                                    <div style="cursor: pointer;" @click="handleFullScreen">全屏切换</div>
                                </div>
                                <dv-decoration-8 class="title_right" :color="['#008CFF', '#00ADDD']"/>
                            </el-col>
                            <el-col :span="12">
                                <div class="title_text">人车管理平台物业大数据中心</div>
                                <dv-decoration-5 class="title_center" :color="['#008CFF', '#00ADDD']"/>
                            </el-col>
                            <el-col :span="6">
                                <div class="title_time">{{ dateYear + dateWeek + dateDay }}</div>
                                <dv-decoration-8
                                        :reverse="true"
                                        class="title_left"
                                        :color="['#008CFF', '#00ADDD']"/>
                            </el-col>
                        </el-row>
                        <!-- 主体部分 -->
                        <el-row>
                            <!-- 第一列 -->
                            <el-col :span="8">
                                <div class="left_box1">
                                    <dv-border-box-12>
                                        <div class="react-left" v-if="latelyPersonPassList.length > 0">
                                            <div class="react-left-div" v-for="(item,i) in latelyPersonPassList"
                                                 :key="item.id"
                                                 :style="{'background': i == 1 || i == 3 ? 'rgb(211, 214, 221, 0.1)':''}">
                                                <div class="left-div-img">
                                                    <el-image
                                                            :src="item.passageImg ? item.passageImg : require('../assets/images/24341.png')"
                                                            :preview-src-list="[item.passageImg ? item.passageImg : require('../assets/images/24341.png')]"/>
                                                </div>
                                                <div class="left-div-img">
                                                    <el-image
                                                            :src="item.avatar ? item.avatar : require('../assets/images/24341.png')"
                                                            :preview-src-list="[item.avatar ? item.avatar : require('../assets/images/24341.png')]"/>
                                                </div>
                                                <div class="left-div-font">
                                                    <span>姓名：{{item.personName ? item.personName : ''}}</span>
                                                    <span>体温：{{item.temperature > 0 ? item.temperature : item.temperature == 0 ? "-" :  '-'}}</span>
                                                    <span>类型：{{item.personType == 1 ? '内部人员' : item.personType == 2 ? '访客' : ''}}</span>
                                                    <span>通道：{{item.doorwayName ? item.doorwayName : ''}}</span>
                                                    <!--                                                    formatDate()-->
                                                    <span>时间：{{item.passageTime ? item.passageTime.substring(10, 20) : ''}}</span>
                                                    <span>方向：{{item.direction == 1 ? '入口' : item.direction == 2 ? '出口' : ''}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="react-left" v-else>
                                            <div class="react-left-div"
                                                 :style="{'background': i == 1 || i == 3 ? 'rgb(211, 214, 221, 0.1)':''}"
                                                 v-for="(item,i) in 5" :key="item">
                                                <div class="left-div-image">
                                                    <el-image
                                                            :src="passImg?passImg:require('../assets/images/24341.png')"/>
                                                </div>
                                                <div class="left-div-image">
                                                    <el-image
                                                            :src="passImg?passImg:require('../assets/images/24341.png')"/>
                                                </div>
                                                <div class="left-div-font">
                                                    <span>姓名：</span>
                                                    <span>体温：</span>
                                                    <span>类型：</span>
                                                    <span>通道：</span>
                                                    <span>时间：</span>
                                                    <span>方向：</span>
                                                </div>
                                            </div>
                                        </div>
                                    </dv-border-box-12>
                                </div>
                            </el-col>
                            <!-- 第二列 -->
                            <el-col :span="8">
                                <div class="centontList">
                                    <div class="operate">
                                        <div>
                                            <p>入场人员</p>
                                            <span>{{cell.inNumber ? cell.inNumber :0}}</span>
                                        </div>
                                        <div>
                                            <p>在场人员</p>
                                            <span>{{cell.presentNumber ? cell.presentNumber :0}}</span>
                                        </div>
                                        <div>
                                            <p>在场访客数</p>
                                            <span>{{cell.visitorPresentNumber ? cell.visitorPresentNumber :0}}</span>
                                        </div>
                                    </div>
                                </div>
                                <p class="center-title">在场人员</p>
                                <el-carousel :indicator-position="areaStatistics.length > 15 ? 'none' : 'outside'"
                                             :autoplay="false">
                                    <el-carousel-item v-for="item in areaStatistics" :key="item.value">
                                        <el-card :body-style="{ height: '300px', background: 'rgb(211, 214, 221, 0.1)', display: 'flex',
                                                flexDirection: 'row', justifyContent: 'space-around',}">
                                            <div class="Rose_diagram" id="Rose_diagram" ref="Rose_diagram"></div>
                                        </el-card>
                                    </el-carousel-item>
                                </el-carousel>
                                <p class="center-title">体温异常人员</p>
                                <el-carousel indicator-position="outside" :autoplay="false"
                                             v-if="temperatureAbnormalList.length > 0">
                                    <el-carousel-item v-for="item in temperatureAbnormalList" :key="item.value">
                                        <el-card
                                                :body-style="{ height: '300px', background: 'rgb(211, 214, 221, 0.1)', display: 'flex',flexDirection: 'row', justifyContent: 'space-around'}">
                                            <div>
                                                <div class="conter-div-img">
                                                    <el-image
                                                            :src="item.passageImg ? item.passageImg : require('../assets/images/24341.png')"
                                                            :preview-src-list="[item.passageImg ? item.passageImg : require('../assets/images/24341.png')]"/>
                                                </div>
                                                <div class="dv-box-info">
                                                    <span>姓名：{{item.personName ? item.personName : ''}}</span>
                                                    <span>体温：{{item.temperature > 0 ? item.temperature : item.temperature == 0 ? "-" :  '-'}}</span>
                                                    <span>通道：{{item.doorwayName ? item.doorwayName : ''}}</span>
                                                </div>
                                            </div>
                                            <div>
                                                <div class="conter-div-img">
                                                    <el-image
                                                            :src="item.avatar ? item.avatar : require('../assets/images/24341.png')"
                                                            :preview-src-list="[item.avatar ? item.avatar : require('../assets/images/24341.png')]"/>
                                                </div>
                                                <div class="dv-box-info">
                                                    <span>类型：{{item.personType == 1 ? '内部人员' : item.personType == 2 ? '访客' : ''}}</span>
                                                    <span>时间：{{item.passageTime ? item.passageTime : ''}}</span>
                                                    <span>方向：{{item.direction == 1 ? '入口' : item.direction == 2 ? '出口' : ''}}</span>
                                                </div>
                                            </div>
                                        </el-card>
                                    </el-carousel-item>
                                </el-carousel>
                                <el-carousel indicator-position="outside" :autoplay="false" v-else>
                                    <el-carousel-item v-for="item in 1" :key="item.value">
                                        <el-card
                                                :body-style="{ height: '300px', background: 'rgb(211, 214, 221, 0.1)', display: 'flex',flexDirection: 'row', justifyContent: 'space-around'}">
                                            <div>
                                                <div class="div-img">
                                                    <el-image
                                                            :src="passImg?passImg:require('../assets/images/24341.png')"/>
                                                </div>
                                                <div class="dv-box-info">
                                                    <span>姓名：</span>
                                                    <span>体温：</span>
                                                    <span>通道：</span>
                                                </div>
                                            </div>
                                            <div>
                                                <div class="div-img">
                                                    <el-image
                                                            :src="passImg?passImg:require('../assets/images/24341.png')"/>
                                                </div>
                                                <div class="dv-box-info">
                                                    <span>类型：</span>
                                                    <span>时间：</span>
                                                    <span>方向：</span>
                                                </div>
                                            </div>
                                        </el-card>
                                    </el-carousel-item>
                                </el-carousel>
                            </el-col>
                            <!-- 第三列 -->
                            <el-col :span="8">
                                <!-- 折线图部分 -->
                                <div class="right_box1">
                                    <dv-border-box-12 style="padding-top: 10px">
                                        <p style="margin: 15px;font-size: 18px;">今日流量</p>
                                        <div ref="line_center_diagram" id="line_center_diagram"></div>
                                    </dv-border-box-12>
                                </div>
                                <!-- 场内访客人员部分 -->
                                <div class="right_box2">
                                    <dv-border-box-12 style="padding-top: 10px">
                                        <p style="margin: 15px 15px 0 15px;font-size: 18px;">场内访客人员</p>
                                        <el-carousel indicator-position="none" :autoplay="false"
                                                     v-if="visitorPresentList.length > 0">
                                            <el-carousel-item v-for="item in visitorPresentList" :key="item.value">
                                                <el-card :body-style="{ display: 'flex',flexDirection: 'row',}">
                                                    <div class="right-div-img">
                                                        <el-image
                                                                :src="item.inPassageImg ? item.inPassageImg : require('../assets/images/24341.png')"
                                                                :preview-src-list="[item.inPassageImg ? item.inPassageImg : require('../assets/images/24341.png')]"/>
                                                    </div>
                                                    <div class="right-div-font">
                                                        <span>姓名：{{item.personName ? item.personName : '-'}}
                                                            <el-button style="margin: 0 10px;" round
                                                                       size="samll" @click="handleClick(item.id)"
                                                                       type="primary">手动离场</el-button>
                                                        </span>
                                                        <span>手机号码：{{item.phone ? item.phone : ''}}</span>
                                                        <span>入场时间：{{item.inPassageTime ? item.inPassageTime : ''}}</span>
                                                        <span>预约截止：{{item.visitEndTime ? item.visitEndTime : ''}}</span>
                                                        <span>入场通道：{{item.inDoorwayName ? item.inDoorwayName : ''}}</span>
                                                    </div>
                                                </el-card>
                                            </el-carousel-item>
                                        </el-carousel>
                                        <el-carousel indicator-position="none" :autoplay="false" v-else>
                                            <el-carousel-item v-for="item in 1" :key="item.value">
                                                <el-card :body-style="{ display: 'flex',flexDirection: 'row',}">
                                                    <div class="right-div-image">
                                                        <el-image
                                                                :src="passImg?passImg:require('../assets/images/24341.png')"/>
                                                    </div>
                                                    <div class="right-div-font">
                                                        <span>姓名：  <el-button style="margin: 0 10px;" round
                                                                              size="samll"
                                                                              type="primary">手动离场</el-button>
                                                        </span>
                                                        <span>手机号码：</span>
                                                        <span>入场时间：</span>
                                                        <span>预约截止：</span>
                                                        <span>入场通道：</span>
                                                    </div>
                                                </el-card>
                                            </el-carousel-item>
                                        </el-carousel>
                                    </dv-border-box-12>
                                </div>
                                <!-- 设备状态统计部分 -->
                                <div class="right_box3">
                                    <dv-border-box-12 style="padding-top: 10px">
                                        <p style="font-size: 18px;margin: 10px">设备状态统计</p>
                                        <dv-scroll-board
                                                @click="scrollBoard"
                                                :config="board_info"
                                                class="carousel_list"
                                                oddRowBGC="#fff"
                                        />
                                    </dv-border-box-12>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import drawMixin from "@/utils/drawMixin"; //自适应缩放
    import {formatTime} from '@/utils/index.js';//日期格式转换
    import * as echarts from "echarts";
    import {personnelManualExit, personnelStatisticsBig} from "../api/bigscreen";
    import {openGate} from "../api/equipmentApi";
    import {formatDate} from "../utils";

    export default {
        name: "personLargeDataScreen",
        mixins: [drawMixin],
        data() {
            return {
                //定时器
                timing: null,
                keyer: null,
                //loading图
                loading: true,
                //时分秒
                dateDay: null,
                //年月日
                dateYear: null,
                //周几
                dateWeek: null,
                //周几
                weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
                passImg: "",
                show: false,
                onLine: navigator.onLine,

                visitorPresentList: [],//访客人员列表
                deviceList: [],//设备列表
                latelyPersonPassList: [],
                cell: {},
                temperatureAbnormalList: [],//体温异常人员数
                areaStatistics: [],//各区在场统计
                todayHourFlow: [], //今日流量
                //左侧轮播表格配置项
                board_info: {},
                seriesData: [],
                fullscreen: false
            };
        },
        mounted() {
            //获取实时时间
            this.timeFn();
            //加载loading图
            this.cancelLoading();
            //数据展示接口
            this.keyer = setInterval(() => {
                this.personnelStatistics();
            }, 10000);
            window.addEventListener('online', this.updateOnlineStatus);
            window.addEventListener('offline', this.updateOnlineStatus);
        },
        //销毁前清除
        beforeDestroy() {
            clearInterval(this.keyer);
            clearInterval(this.timing);
            window.removeEventListener('online', this.updateOnlineStatus);
            window.removeEventListener('offline', this.updateOnlineStatus);
        },
        methods: {
            // 全屏事件
            handleFullScreen() {
                let element = document.documentElement;
                // 判断是否已经是全屏
                // 如果是全屏，退出
                if (this.fullscreen) {
                    if (document.exitFullscreen) {
                        document.exitFullscreen();
                    } else if (document.webkitCancelFullScreen) {
                        document.webkitCancelFullScreen();
                    } else if (document.mozCancelFullScreen) {
                        document.mozCancelFullScreen();
                    } else if (document.msExitFullscreen) {
                        document.msExitFullscreen();
                    }
                    console.log("已还原！");
                } else {
                    // 否则，进入全屏
                    if (element.requestFullscreen) {
                        element.requestFullscreen();
                    } else if (element.webkitRequestFullScreen) {
                        element.webkitRequestFullScreen();
                    } else if (element.mozRequestFullScreen) {
                        element.mozRequestFullScreen();
                    } else if (element.msRequestFullscreen) {
                        // IE11
                        element.msRequestFullscreen();
                    }
                    console.log("已全屏！");
                }
                // 改变当前全屏状态
                this.fullscreen = !this.fullscreen;
            },
            updateOnlineStatus(e) {
                const {type} = e;
                this.onLine = type === 'online';
                if (type === 'online') {
                    this.show = false;
                } else if (type === 'offline') {
                    this.show = true;
                }
            },
            formatDate(time) {
                return formatDate(time, 'HH:mm:ss');
            },
            //右上角当前日期时间显示：每一秒更新一次最新时间
            timeFn() {
                this.timing = setInterval(() => {
                    //获取当前时分秒
                    this.dateDay = formatTime(new Date(), 'HH: mm: ss');
                    //获取当前年月日
                    this.dateYear = formatTime(new Date(), 'yyyy-MM-dd');
                    //获取当前周几
                    this.dateWeek = this.weekday[new Date().getDay()];
                }, 1000);
            },
            //loading
            cancelLoading() {
                this.loading = true;
                setTimeout(() => {
                    this.loading = false;
                    this.personnelStatistics();
                }, 500);
            },
            personnelStatistics() {
                personnelStatisticsBig().then(res => {
                    this.visitorPresentList = res.data.data.visitorPresentList; //访客人员列表
                    this.board_info.data = res.data.data.deviceList; //设备列表
                    this.latelyPersonPassList = res.data.data.latelyPersonPassList;
                    this.cell = res.data.data.cell;
                    this.temperatureAbnormalList = res.data.data.temperatureAbnormalList; //体温异常人员数
                    this.areaStatistics = res.data.data.areaStatistics; //各区在场统计
                    this.todayHourFlow = res.data.data.todayHourFlow;//今天流量
                    let arrlistresult = [];
                    res.data.data.deviceList.map(item => {
                        let arrlist = [item.deviceName, item.status == 1 ? '在线' : item.status == 2 ? '离线' : '', item.type == 4 ? '人脸机' : '', "开闸", item.id];
                        arrlistresult.push(arrlist);
                    });
                    this.board_info = {
                        header: ["设备名称", "在线状态", "类型", "操作"],
                        data: arrlistresult,
                        index: true,
                        columnWidth: [50],
                        evenRowBGC: "#020308",
                        oddRowBGC: "#382B47",
                        headerBGC: "#020308",
                        align: ['center', 'center', 'center', 'center'],
                    };
                    this.$nextTick(() => {
                        //左侧玫瑰饼图
                        this.Rose_diagram();
                        this.line_center_diagram();
                    });
                })
            },
            handleClick(id) {
                this.$confirm("确定要手动离场吗?", "温馨提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    personnelManualExit(id).then((res) => {
                        if (res.data.code == "SUCCESS") {
                            this.$message.success(res.data.message);
                        } else {
                            this.$message.info(res.data.message);
                        }
                    })
                }).catch(() => {
                    this.$message.info("已取消操作!");
                });
            },
            //设备开闸
            scrollBoard(val) {
                if (val.ceil === "开闸") {
                    openGate(val.row[5]).then((res) => {
                        if (res.data.code == "SUCCESS") {
                            this.$message.success("开闸" + res.data.message);
                        } else {
                            this.$message.error(res.data.message);
                        }
                    }).catch(() => {
                        this.$message.error("开闸失败，请稍后再试！");
                    });
                }
            },
            //折线图
            line_center_diagram() {
                let mapChart = this.$echarts.init(this.$refs.line_center_diagram);//图表初始化，china-map是绑定的元素
                window.onresize = mapChart.resize; //如果容器变大小，自适应从新构图
                let xAxisData = [];
                let seriesData1 = [];
                let seriesData2 = [];
                if (this.todayHourFlow) {
                    this.todayHourFlow.forEach(e => {
                        xAxisData.push(e.time);
                        seriesData1.push(e.inPassCount);
                        seriesData2.push(e.outPassCount);
                    });
                }
                let option = {
                    tooltip: {
                        trigger: 'axis'
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: xAxisData,
                        position: "bottom",
                        axisLine: true,
                        axisLabel: {
                            color: "rgba(222,240,240,0.8)",
                            fontSize: 13,
                        },
                    },
                    yAxis: {
                        type: 'value',
                        name: "客流量",
                        nameLocation: "end",
                        nameGap: 24,
                        nameTextStyle: {
                            color: "#DEF0F0",
                            fontSize: 14,
                        },
                        splitNumber: 4,
                        axisLine: {
                            lineStyle: {
                                opacity: 0,
                                color: "#B4B4B4",
                            },
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: "#fff",
                                opacity: 0.1,
                            },
                        },
                        axisLabel: {
                            color: "rgba(255,255,255,.8)",
                            fontSize: 12,
                            formatter: "{value} ",
                        },
                    },
                    legend: {
                        data: ['入场', '出场'],
                        textStyle: {
                            color: "#def0f0",
                        },
                    },
                    series: [
                        {
                            name: '入场',
                            type: 'line',
                            smooth: true,
                            //修改折线的颜色
                            data: seriesData1,
                            symbol: "emptyCircle",
                            symbolSize: 8,
                            itemStyle: {
                                normal: {
                                    color: "#fff",
                                },
                            },
                            //线的颜色样式
                            lineStyle: {
                                normal: {
                                    color: {
                                        type: "linear",
                                        x: 0,
                                        y: 0,
                                        x2: 1,
                                        y2: 0,
                                        colorStops: [
                                            {
                                                offset: 0,
                                                color: "#1c98e8",
                                            },
                                            {
                                                offset: 1,
                                                color: "#28f8de",
                                            },
                                        ],
                                    },
                                    width: 3,
                                },
                            },
                            //填充颜色样式
                            areaStyle: {
                                normal: {
                                    color: {
                                        type: "linear",
                                        x: 0,
                                        y: 0,
                                        x2: 0,
                                        y2: 1,
                                        colorStops: [
                                            {
                                                offset: 0,
                                                color: "rgba(35,184,210,.2)",
                                            },
                                            {
                                                offset: 1,
                                                color: "rgba(35,184,210,0)",
                                            },
                                        ],
                                    },
                                },
                            },
                        },
                        {
                            name: '出场',
                            type: 'line',
                            data: seriesData2,
                            smooth: true,
                            symbol: "emptyCircle",
                            symbolSize: 8,
                            itemStyle: {
                                normal: {
                                    color: "#fff",
                                },
                            },
                            //线的颜色样式
                            lineStyle: {
                                normal: {
                                    color: {
                                        type: "linear",
                                        x: 0,
                                        y: 0,
                                        x2: 1,
                                        y2: 0,
                                        colorStops: [
                                            {
                                                offset: 0,
                                                color: "#d14a61",
                                            },
                                            {
                                                offset: 1,
                                                color: "#c2590a",
                                            },
                                        ],
                                    },
                                    width: 3,
                                },
                            },
                            //填充颜色样式
                            areaStyle: {
                                normal: {
                                    color: {
                                        type: "linear",
                                        x: 0,
                                        y: 0,
                                        x2: 0,
                                        y2: 1,
                                        colorStops: [
                                            {
                                                offset: 0,
                                                color: "rgba(35,184,210,.2)",
                                            },
                                            {
                                                offset: 1,
                                                color: "rgba(35,184,210,0)",
                                            },
                                        ],
                                    },
                                },
                            },
                        }
                    ]
                };
                mapChart.setOption(option); //生成图表
            },
            //饼图
            Rose_diagram() {
                // let pie = document.getElementById('Rose_diagram');
                let myEchart = document.getElementsByClassName('Rose_diagram');
                this.seriesData = []
                for (var i = 0; i < myEchart.length; i++) {
                    this.seriesData = [
                        {name: '内部人员', value: this.areaStatistics[i].presentNumber},
                        {name: '访客人数', value: this.areaStatistics[i].visitorPresentNumber},
                    ]
                    let mapChart = echarts.init(myEchart[i]); //图表初始化，china-map是绑定的元素
                    window.addEventListener("resize", function () {
                        mapChart.resize();
                    }); //如果容器变大小，自适应从新构图
                    let option = {
                        //环形图中间文字
                        title: {
                            text: this.areaStatistics[i].areaName,
                            // 副标题
                            // subtext: "123",
                            // 主副标题间距
                            itemGap: 25,
                            // x: 'center',
                            // y: 'center',
                            top: '100',
                            right: '90',
                            // 主标题样式
                            textStyle: {
                                fontSize: '24',
                                color: '#fff'
                            },
                            // 副标题样式
                            subtextStyle: {
                                fontSize: '20',
                                fontWeight: '800',
                                color: '#fff'
                            }
                        },
                        //数据的颜色设置
                        color: ["#2BCB95", "#69aae4"],
                        //鼠标移入显示的文字
                        tooltip: {
                            trigger: "item",
                            formatter: "{b} : {c}<br/> {d}%",
                            textStyle: {
                                fontFamily: "微软雅黑",
                                fontSize: 18,
                                color: '#fff',
                            }
                        },
                        //图例设置
                        legend: {
                            orient: "vertical",  //竖直展示，如果横着的话，这个可以直接去掉
                            right: "10%",
                            bottom: "10%",
                            align: "left",
                            itemGap: 20,  //数据上下的距离
                            icon: "none", //圆形小点展示，默认为方形，去掉就是方形展示
                            // itemWidth: 15, // 设置宽度
                            data: ['内部人员', '访客人数'],
                            textStyle: {
                                fontFamily: "微软雅黑",
                                fontSize: 18,
                                color: '#fff',
                            },
                        },
                        formatter: function (name) {
                            var data = option.series[0].data;//获取series中的data
                            var total = 0;
                            var tarValue;
                            for (var i = 0, l = data.length; i < l; i++) {
                                total += data[i].value;
                                if (data[i].name == name) {
                                    tarValue = data[i].value;
                                }
                            }
                            return name + " " + " " + tarValue;
                        },
                        series: [
                            {
                                radius: ['50%', '80%'],
                                center: ['36%', '56%'],
                                avoidLabelOverlap: false,
                                type: "pie",
                                label: {
                                    normal: {
                                        formatter: "{d}" + "%", //直接百分比，没有去掉小数位，上边是去掉小数位的
                                        //formatter: "{b}：{c}人"  //正常兼职：456
                                        position: "inner", //文字显示在内部，如果在外边把这个去掉就好
                                        color: "#fff",     //文字的颜色
                                        fontFamily: "微软雅黑",
                                        fontSize: 14,
                                    }
                                },
                                data: this.seriesData
                            }
                        ]
                    };
                    mapChart.setOption(option); //生成图表
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    //页面样式部分！！！！
    .indexMain {
        width: 100%;
        height: 100%;
        background-color: #020308;
        /*overflow: hidden;*/
    }

    #index {
        color: #d3d6dd;
        width: 1920px;
        height: 1080px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transform-origin: left top;
        overflow: hidden;

        .bg {
            //整体页面背景
            width: 100%;
            height: 100%;
            padding: 16px 16px 0 16px;
            background-image: url("../assets/pageBg.png"); //背景图
            background-size: cover; //背景尺寸
            background-position: center center; //背景位置
        }

        //顶部右边装饰效果
        .title_left {
            width: 100%;
            height: 50px;
            margin-top: -8px;
        }

        //顶部左边装饰效果
        .title_right {
            width: 100%;
            height: 50px;
            margin-top: -8px;
        }

        //顶部中间装饰效果
        .title_center {
            width: 100%;
            height: 50px;
        }

        //顶部中间文字数据可视化系统
        .title_text {
            text-align: center;
            font-size: 28px;
            font-weight: bold;
            margin-top: 14px;
            color: #008cff;
        }

        //左边 返回
        .title_return {
            font-size: 26px;
            text-align: center;
            font-family: 'Avenir', Helvetica, Arial, sans-serif;
            font-weight: bold;
            margin-top: 8px;
            display: flex;
            justify-content: space-around;
        }

        //时间日期
        .title_time {
            font-size: 26px;
            text-align: center;
            font-family: 'Avenir', Helvetica, Arial, sans-serif;
            margin-top: 8px;
        }

        //中间出入场数据
        .centontList {
            height: 110px;
            margin-top: 10px;
        }

        .operate {
            display: flex;
            flex-wrap: wrap;
            line-height: 50px;;
        }

        .operate div {
            width: 30%;
            text-align: center;
            border-right: 1px solid #ebebeb;
        }

        .operate p {
            color: #fff;
            font-size: 20px;
        }

        .operate span {
            color: #69aae4;
            font-size: 28px;
            font-weight: bold;
        }

        //最近通行人员
        .center-title {
            font-size: 24px;
            font-weight: bold;
            margin: 40px 20px;
        }

        .line_center {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            height: 310px;
        }

        .el-card {
            border: none;
            box-shadow: none;
            background: none;
        }

        .div-img {
            margin: auto;
            width: 170px;
            height: 170px;
            text-align: center;
        }

        .conter-div-img .el-image {
            margin: auto;
            width: 160px;
            height: 165px;
            text-align: center;
        }

        .dv-box-info {
            height: 150px;
            display: flex;
            flex-direction: column;
            font-size: 18px;
            line-height: 35px;
            color: #fff;
        }

        //体温异常人员
        .el-carousel {
            height: 340px;
            margin: 10px;
        }

        .el-carousel-item {
            width: 260px;
        }

        //左1模块
        .left_box1 {
            height: 970px;
            width: 100%;
            margin-bottom: 10px;
            position: relative;
        }

        .react-left {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            padding: 10px 0;
        }

        .react-left-div {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            height: 190px;
            margin: 0 5px;
        }

        .left-div-img .el-image {
            width: 170px;
            height: 190px;
            padding: 10px 0;
        }

        .left-div-image {
            width: 170px;
            height: 180px;
            padding: 10px 0;
        }

        .left-div-font {
            width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 18px;
            display: flex;
            flex-direction: column;
            line-height: 30px;
        }

        //右1模块
        .right_box1 {
            height: 320px;
            width: 100%;
            margin-bottom: 10px;
            position: relative;
        }

        //右2模块
        .right_box2 {
            height: 320px;
            width: 100%;
            margin-bottom: 10px;
        }

        .right-div-img .el-image {
            width: 175px;
            height: 215px;
            margin-left: 40px;
            border: none;
            border-radius: 10%;
        }

        .right-div-image {
            width: 160px;
            height: 200px;
            margin: 10px 10px 0 40px;
            border: 1px solid #dfdfdf;
            padding: 20px 0 0 8px;
            border-radius: 15%;
        }

        .right-div-font {
            display: flex;
            flex-direction: column;
            color: #ffffff;
            font-size: 18px;
            margin: 0 40px;
            line-height: 45px;
        }

        .right_box2 .el-carousel__container {
            height: 245px !important;
        }

        //右3模块
        .right_box3 {
            height: 310px;
            width: 100%;
        }

        //左2模块 折线图
        #columnar {
            height: 90%;
            width: 100%;
            margin-top: -3%;
            padding-bottom: 5px;
        }

        //折线图
        #line_center_diagram {
            height: 90%;
            width: 100%;
            margin-top: -3%;
            padding-bottom: 5px;
        }

        #Rose_diagram {
            height: 100%;
            width: 100%;
            margin-top: -5%;
        }

        //轮播表格
        .carousel_list {
            width: 96%;
            height: 85%;
            margin: 0 5px 0 10px;
        }
    }

    /deep/ .header-item:nth-child(2) {
        width: 180px !important;
    }

    /deep/ .header-item:nth-child(3) {
        width: 110px !important;
    }

    /deep/ .header-item:nth-child(4) {
        width: 130px !important;
    }

    /deep/ .ceil:nth-child(2) {
        width: 180px !important;
    }

    /deep/ .ceil:nth-child(3) {
        width: 110px !important;
    }

    /deep/ .ceil:nth-child(4) {
        width: 130px !important;
    }

    /deep/ .ceil:nth-child(5) {
        width: 110px !important;
        cursor: pointer;
    }

    /deep/ .ceil:nth-child(6) {
        display: none;
    }

    /deep/ .el-image__inner--center {
        top: auto;
        left: auto;
        transform: none;
    }
</style>

