<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>人行管理</el-breadcrumb-item>
            <el-breadcrumb-item>测温门管理</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 搜索筛选 -->
        <el-form :inline="true" :model="filters" class="user-search">
            <el-form-item>
                <el-input v-model="filters.name" clearable size="small" placeholder="请输入设备名称"
                          @keyup.enter.native="handleSearch"/>
            </el-form-item>
            <el-form-item>
                <el-select size="small" v-model="filters.status" clearable placeholder="请选择设备在线状态">
                    <el-option label="在线" value="1"></el-option>
                    <el-option label="离线" value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-search" type="primary" size="small" @click="handleSearch">查询</el-button>
                <el-button icon="el-icon-plus" type="primary" size="small" @click="handleAdd">添加</el-button>
            </el-form-item>
        </el-form>
        <el-table
                v-loading="loading"
                element-loading-text="拼命加载中"
                size="small"
                :data="tableData"
                highlight-current-row
                border
                stripe
                style="width: 100%"
                :row-style="{ height: '45px' }"
                :cell-style="{ padding: '0px' }"
                :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
                height="500" max-height="500">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column prop="deviceName" label="设备名称" align="center"></el-table-column>
            <el-table-column prop="sn" label="设备序列号" align="center"></el-table-column>
            <el-table-column prop="deviceNo" label="设备编号" align="center"></el-table-column>
            <el-table-column prop="status" label="设备在线状态" align="center">
                <template slot-scope="scope">
                    <el-tag v-show="scope.row.status != null" :type="scope.row.status == 1 ? '' : 'info'">
                        {{ scope.row.status == 1 ? "在线" : scope.row.status == 2 ? "离线" : "" }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column width="300" align="center" label="操作">
                <template slot-scope="scope">
                    <el-button size="mini" type="primary" icon="el-icon-setting"
                               @click="handleEdit(scope.$index, scope.row)">编辑
                    </el-button>
                    <el-button size="mini" type="danger" class="el-icon-delete"
                               @click="handleDelete(scope.$index, scope.row)">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="filters.current"
                :page-sizes="[10, 50, 200, 1000]"
                :page-size="filters.size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
        >
        </el-pagination>
        <el-dialog v-draggable  :title="title" :visible.sync="visibleDialog" width="30%">
            <el-form :model="visibleForm" ref="visibleForm" size="small" :rules="rules" label-width="130px">
                <el-form-item label="设备名称" prop="deviceName">
                    <el-input style="width: 80%" size="small" v-model="visibleForm.deviceName" placeholder="请输入内容"/>
                </el-form-item>
                <el-form-item label="设备序列号" prop="sn">
                    <el-input :disabled="disabled" style="width: 80%" size="small" v-model="visibleForm.sn"
                              placeholder="请输入内容"/>
                </el-form-item>
                <el-form-item label="设备编号" prop="deviceNo" v-show="title == '编辑设备管理'">
                    <el-input disabled style="width: 80%" size="small" v-model="visibleForm.deviceNo"
                              placeholder="设备编号"/>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="visibleDialog = false">取 消</el-button>
                <el-button type="primary" @click="submitForm('visibleForm')">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import {
        addDeviceTemperature,
        delDeviceTemperature,
        editDeviceTemperature,
        getDeviceTemperature
    } from "../../../api/equipmentApi";

    export default {
        name: "deviceManagement",
        data() {
            return {
                loading: false,
                total: 0,
                tableData: [],
                filters: {
                    name: "",
                    status: "",
                    current: 1,
                    size: 10,
                },
                title: "",
                visibleDialog: false,
                visibleForm: {
                    deviceName: "",
                    deviceNo: "",
                    sn: "",
                    id: "",
                },
                rules: {
                    deviceName: [{required: true, message: "请输入", trigger: "blur"}],
                    sn: [{required: true, message: "请输入", trigger: "blur"}],
                },
                disabled: false,
            };
        },
        mounted() {
            this.fetchData();
        },
        methods: {
            fetchData() {
                this.loading = true;
                getDeviceTemperature(this.filters).then(res => {
                    this.loading = false;
                    this.tableData = res.data.data.records;
                    this.total = res.data.data.total;
                    this.filters.current = res.data.data.current;
                    this.filters.size = res.data.data.size;
                })
            },
            handleSearch() {
                this.filters.current = 1;
                this.fetchData();
            },
            handleSizeChange(val) {
                this.filters.size = val;
                this.filters.current = 1;
                this.fetchData();
            },
            handleCurrentChange(val) {
                this.filters.current = val;
                this.fetchData();
            },
            handleAdd() {
                this.title = "设备管理";
                this.visibleDialog = true;
                this.disabled = false;
                Object.keys(this.visibleForm).map(key => {
                    this.visibleForm[key] = '';
                })
            },
            handleEdit(index, row) {
                this.title = "编辑设备管理";
                this.visibleDialog = true;
                this.disabled = true;
                Object.keys(this.visibleForm).map(key => {
                    this.visibleForm[key] = row[key];
                });
            },
            submitForm(visibleForm) {
                this.$refs[visibleForm].validate((valid) => {
                    if (valid) {
                        if (this.visibleForm.id) {
                            editDeviceTemperature(this.visibleForm).then(res => {
                                this.visibleDialog = false;
                                if (res.data.code == "SUCCESS") {
                                    this.fetchData();
                                    this.$message.success("编辑成功！");
                                }
                            })
                        } else {
                            let params = {
                                deviceName: this.visibleForm.deviceName,
                                sn: this.visibleForm.sn,
                            }
                            addDeviceTemperature(params).then(res => {
                                this.visibleDialog = false;
                                if (res.data.code == "SUCCESS") {
                                    this.fetchData();
                                    this.$message.success("保存成功！");
                                }
                            })
                        }
                    }
                })
            },
            handleDelete(index, row) {
                this.$confirm("确定要删除设备吗?", "温馨提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    delDeviceTemperature(row.id).then(res => {
                        this.visibleDialog = false;
                        if (res.data.code == "SUCCESS") {
                            this.fetchData();
                            this.$message.success("删除成功！");
                        }
                    })
                }).catch(() => {
                    this.$message.info("已取消删除!");
                });
            },
        }
    }
</script>

<style scoped>
    .user-search {
        margin-top: 15px;
    }
</style>
