<template>
    <div>
        <!-- 搜索筛选 -->
        <el-form :inline="true" :model="filters" class="user-search">
            <el-form-item>
                <el-select size="small" v-model="filters.cycle" clearable filterable
                           @keyup.enter.native="fetchData" placeholder="请选择周期类型">
                    <el-option label="月" value="1"></el-option>
                    <el-option label="季" value="2"></el-option>
                    <el-option label="半年" value="3"></el-option>
                    <el-option label="年" value="4"></el-option>
                    <el-option label="长期" value="5"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-select size="small" v-model="filters.carProperty" clearable filterable
                           @keyup.enter.native="fetchData" placeholder="请选择车辆属性">
                    <el-option label="小车" value="1"></el-option>
                    <el-option label="大车" value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-search" type="primary" size="small" @click="fetchData">查询</el-button>
                <el-button icon="el-icon-plus" type="primary" size="small" @click="handleAdd">添加费率</el-button>
            </el-form-item>
        </el-form>
        <!--列表-->
        <el-table
                size="small"
                :data="tableData"
                v-loading="loading"
                element-loading-text="拼命加载中"
                highlight-current-row
                border
                style="width: 100%"
                :row-style="{height:'45px'}"
                :cell-style="{padding:'0px'}"
                :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                stripe
                height="484"
                max-height="484"
                :default-sort="{prop: 'updateTime', order: 'descending'}"
        >
            <el-table-column min-width="30" type="index" label="序号"></el-table-column>
            <el-table-column prop="rateName" label="费率名称" align="center"></el-table-column>
            <el-table-column prop="parkingArea.name" label="区域" align="center"></el-table-column>
            <el-table-column prop="cycle" label="周期类型" align="center">
                <template slot-scope="scope">
                    <span v-if="scope.row.cycle == 1">月</span>
                    <span v-else-if="scope.row.cycle == 2">季</span>
                    <span v-else-if="scope.row.cycle == 3">半年</span>
                    <span v-else-if="scope.row.cycle == 4">年</span>
                    <span v-else-if="scope.row.cycle == 5">长期</span>
                </template>
            </el-table-column>
            <el-table-column prop="carProperty" label="车辆属性" align="center">
                <template slot-scope="scope">
                    <el-tag type="success" v-if="scope.row.carProperty === 1">小车</el-tag>
                    <el-tag v-if="scope.row.carProperty === 2">大车</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="price" label="价格" align="center"></el-table-column>
            <!--            <el-table-column prop="tempCarRateVO.rateName" label="到期启用费率" align="center"></el-table-column>-->
            <el-table-column prop="status" label="状态" align="center">
                <template slot-scope="scope">
                    <div v-if="scope.row.status != null">
                        <el-tag v-if="scope.row.status === 1">启用</el-tag>
                        <el-tag type="info" v-if="scope.row.status === 2">停用</el-tag>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="updateTime" label="更新时间" align="center" :formatter="getUpdateTime">
            </el-table-column>
            <el-table-column width="250" align="center" label="操作">
                <template slot-scope="scope">
                    <el-button size="mini" @click="handleEdit(scope.$index, scope.row)" icon="el-icon-setting"
                               type="primary">编辑
                    </el-button>
                    <el-button v-if="scope.row.status === 2" size="mini" type="warning"
                               @click="handleStatus(scope.$index, scope.row)"
                    >启用
                    </el-button>
                    <el-button v-if="scope.row.status === 1" size="mini" type="danger"
                               @click="handleStatus(scope.$index, scope.row)"
                    >停用
                    </el-button>
                    <!--                    <el-button size="mini" type="danger" class="el-icon-delete"-->
                    <!--                               @click="handleDelete(scope.$index, scope.row)"-->
                    <!--                    >删除-->
                    <!--                    </el-button>-->
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="filters.current"
                       :page-sizes="[10, 50, 200, 1000]"
                       :page-size="filters.size"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
        <!--    添加编辑-->
        <el-dialog v-draggable  :title="title" :visible.sync="addDialog" >
            <el-form :model="editForm" ref="editForm" size="small">
                <el-row>
                    <el-col :span="11">
                        <el-form-item label="费率名称" prop="rateName" :label-width="formLabelWidth" >
                            <el-input size="small" v-model="editForm.rateName" placeholder="请输入内容" />
                        </el-form-item>
                        <el-form-item label="区域" prop="parkingAreaId" :label-width="formLabelWidth">
                            <el-select :disabled="disabled" size="small" v-model="editForm.parkingAreaId"
                                       placeholder="请选择区域" style="width:100%">
                                <el-option v-for="item in areasData" :label="item.name"
                                           :value="item.id" :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="周期类型" prop="cycle" :label-width="formLabelWidth">
                            <el-select :disabled="disabled" size="small" v-model="editForm.cycle" placeholder="请选择周期类型"  style="width:100%">
                                <el-option label="月" :value="1"></el-option>
                                <el-option label="季" :value="2"></el-option>
                                <el-option label="半年" :value="3"></el-option>
                                <el-option label="年" :value="4"></el-option>
                                <el-option label="长期" :value="5"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11">
                        <el-form-item label="价格" prop="price" :label-width="formLabelWidth">
                            <div style="display: flex;">
                                <el-input size="small" oninput="value=value.replace(/[^0-9.]/g,'')"
                                          v-model="editForm.price" placeholder="请输入价格"/>
                                <span style="margin-left: 5px;">元</span>
                            </div>
                        </el-form-item>
                        <el-form-item label="状态" prop="status" :label-width="formLabelWidth">
                            <el-select size="small" v-model="editForm.status" placeholder="请选择状态"  style="width:100%">
                                <el-option label="启用" :value="1"></el-option>
                                <el-option label="停用" :value="2"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="车辆属性" prop="carProperty" :label-width="formLabelWidth">
                            <el-select :disabled="disabled" size="small" v-model="editForm.carProperty" style="width:100%"
                                       placeholder="请选择车辆属性">
                                <el-option label="小车" :value="1"></el-option>
                                <el-option label="大车" :value="2"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button @click="addDialog = false">取 消</el-button>
        <el-button type="primary" @click="confirm('editForm')">确 定</el-button>
      </span>
        </el-dialog>
    </div>
</template>

<script>
    import {longCarDel, longCarEdit, longCarList, longCarSave, parkingAreaRoadList} from "../../../../api/yardSetting";

    export default {
        name: "longTermRentRate",
        data() {
            return {
                loading: false,
                disabled: false,
                total: 0,
                tableData: [],
                filters: {
                    carProperty: '',
                    cycle: '',
                    current: 1,
                    size: 10,
                },
                title: "",
                addDialog: false,
                formLabelWidth: '120px',
                editForm: {
                    id: "",
                    parkingAreaId: "",
                    temporaryCarRateId: "",
                    rateName: "",
                    cycle: "",
                    carProperty: "",
                    price: "",
                },
                changeForm: {
                    status: "",
                },
                areasData: [],
                tempCarData: [],
                id: "",
            }
        },
        mounted() {
            this.id = localStorage.getItem("subSysId");
            this.fetchData();
            this.getDataList();
        },
        methods: {
            fetchData() { //获取数据
                let that = this;
                that.loading = true;
                let param = {
                    carProperty: this.filters.carProperty,
                    cycle: this.filters.cycle,
                    current: this.filters.current,
                    size: this.filters.size,
                    parkingId: this.id,
                }
                longCarList(param).then((result) => {
                    that.loading = false;
                    that.tableData = result.data.data.records;
                    that.total = result.data.data.total;
                    that.filters.current = result.data.data.current;
                    that.filters.size = result.data.data.size;
                }).catch((error) => {
                    that.loading = false;
                });
            },
            getDataList() {
                parkingAreaRoadList(this.id).then((res) => {
                    this.areasData = res.data.data.parkingAreas;
                })
            },
            handleSizeChange(val) {
                this.filters.size = val;
                this.filters.current = 1;
                this.fetchData();
            },
            handleCurrentChange(val) {
                this.filters.current = val;
                this.fetchData();
            },
            getUpdateTime(row) {
                if (row.updateTime != null) {
                    return row.updateTime[0] + '-' + row.updateTime[1] + '-' + row.updateTime[2]
                    // +" "+row.updateTime[3]+':'+row.updateTime[4]+':'+row.updateTime[5]
                }
            },
            handleAdd() {
                this.title = '添加';
                this.addDialog = true;
                this.disabled = false;
                Object.keys(this.editForm).map(key => {
                    this.editForm[key] = '';
                })
            },
            confirm(editForm) {
                this.$refs[editForm].validate((valid) => {
                    if (valid) {
                        let param = {
                            id: this.editForm.id,
                            parkingId: this.id,
                            rateName: this.editForm.rateName,
                            carProperty: this.editForm.carProperty,
                            cycle: this.editForm.cycle,
                            status: this.editForm.status,
                            price: this.editForm.price,
                            tempCarRateId: this.editForm.temporaryCarRateId,
                            parkingAreaId: this.editForm.parkingAreaId
                        }
                        if (this.editForm.id) {
                            longCarEdit(param).then((res) => {
                                this.addDialog = false;
                                this.fetchData();
                                if (res.data.code == "SUCCESS") {
                                    this.$message({
                                        type: "success",
                                        message: "编辑成功！",
                                    });
                                } else {
                                    this.$message({
                                        type: "info",
                                        message: res.data.message,
                                    });
                                }
                            }).catch((err) => {
                                this.addDialog = false;
                                this.$message.error("编辑保存失败，请稍后再试！");
                            });
                        } else {
                            longCarSave(param).then((res) => {
                                this.addDialog = false;
                                this.fetchData();
                                if (res.data.code == "SUCCESS") {
                                    this.$message({
                                        type: "success",
                                        message: "保存成功！",
                                    });
                                } else {
                                    this.$message({
                                        type: "info",
                                        message: res.data.message,
                                    });
                                }
                            }).catch((err) => {
                                this.addDialog = false;
                                this.$message.error("保存失败，请稍后再试！");
                            });
                        }
                    }
                })
            },
            handleEdit(index, row) {
                this.title = '编辑';
                this.addDialog = true;
                this.disabled = true;
                Object.keys(this.editForm).map(key => {
                    this.editForm[key] = row[key];
                });
                this.editForm.id = row.id;
                this.editForm.temporaryCarRateId = row.tempCarRateId;
                this.editForm.status = row.status;
                this.editForm.carProperty = row.carProperty;
            },
            handleDelete(index, row) {
                this.$confirm("确定要删除吗?", "温馨提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    longCarDel(row.id).then((res) => {
                        if (res.data.code == "SUCCESS") {
                            this.fetchData();
                            this.$message({
                                type: "success",
                                message: "删除成功!",
                            });
                        } else {
                            this.$message({
                                type: "info",
                                message: res.data.message,
                            });
                        }
                    })
                        .catch((err) => {
                            this.$message.error("删除失败，请稍后再试！");
                        });
                })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除",
                        });
                    });
            },
            //停用状态
            handleStatus(index, row) {
                if (row.status == 1) {
                    this.changeForm.status = 2;
                } else {
                    this.changeForm.status = 1;
                }
                let data = {
                    id: row.id,
                    status: this.changeForm.status,
                }
                this.$confirm("确定要更改吗?", "温馨提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    longCarEdit(data).then((res) => {
                        if (res.data.code == "SUCCESS") {
                            this.fetchData();
                            this.$message({
                                type: "success",
                                message: "更改成功!",
                            });
                        } else {
                            this.$message({
                                type: "info",
                                message: res.data.message,
                            });
                        }
                    })
                        .catch((err) => {
                            this.$message.error("更改失败，请稍后再试！");
                        });
                })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消更改",
                        });
                    });
            },
        }
    }
</script>

<style scoped>

</style>
