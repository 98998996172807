import request from '@/utils/request'
import config from '@/config'

//登录
export function loginrefresh() {
    return request({
        url: `${config.api}/user/customer/refresh`,
        method: 'get',
        headers: {'showLoading': false}
    })
}

// 出场车辆
export function appearanceRecord() {
    return request({
        url: `${config.bpi}/smartScreen/appearanceRecord`,
        method: 'get',
        headers: {'showLoading': false}
    })
}

// 收费排名
export function chargeRanking() {
    return request({
        url: `${config.bpi}/smartScreen/chargeRanking`,
        method: 'get',
        headers: {'showLoading': false}
    })
}

// 入场车辆
export function entranceRecord() {
    return request({
        url: `${config.bpi}/smartScreen/entranceRecord`,
        method: 'get',
        headers: {'showLoading': false}
    })
}

// 设备信息
export function equipment() {
    return request({
        url: `${config.bpi}/smartScreen/equipment`,
        method: 'get',
        headers: {'showLoading': false}
    })
}

// 异常处理
export function exceptionRecord() {
    return request({
        url: `${config.bpi}/smartScreen/exceptionRecord`,
        method: 'get',
        headers: {'showLoading': false}
    })
}

// 车场收入
export function income() {
    return request({
        url: `${config.bpi}/smartScreen/income`,
        method: 'get',
        headers: {'showLoading': false}
    })
}

// 泊位使用率分布
export function parkingDistribution() {
    return request({
        url: `${config.bpi}/smartScreen/parkingDistribution`,
        method: 'get',
        headers: {'showLoading': false}
    })
}

// 车位使用情况
export function parkingPlaceInfo() {
    return request({
        url: `${config.bpi}/smartScreen/parkingPlaceInfo`,
        method: 'get',
        headers: {'showLoading': false}
    })
}

// 进出车辆统计
export function passRecord() {
    return request({
        url: `${config.bpi}/smartScreen/passRecord`,
        method: 'get',
        headers: {'showLoading': false}
    })
}

//设备直接开闸
export function open(data) {
    return request({
        url: `${config.bpi}/parkingAdmin/equipment/open?roadId=` + data,
        method: 'put',
    })
}

//设备确认放行
export function pass(data) {
    return request({
        url: `${config.bpi}/parkingAdmin/equipment/pass?roadId=` + data,
        method: 'put',
    })
}

//设备现金放行
export function chargeOpen(data) {
    return request({
        url: `${config.bpi}/parkingAdmin/equipment/chargeOpen?roadId=` + data,
        method: 'put',
    })
}

//设备关闸
export function shutoff(data) {
    return request({
        url: `${config.bpi}/parkingAdmin/equipment/shutoff?roadId=` + data,
        method: 'put',
    })
}

//重新抓拍、重新识别
export function photograph(data) {
    return request({
        url: `${config.bpi}/parkingAdmin/photograph?roadId=` + data,
        method: 'put',
    })
}

//场内缴费信息
export function tempPayCenter(data) {
    return request({
        url: `${config.bpi}/parkingAdmin/innerCharge/order`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}

// 中央缴费
export function chargeCenter(data) {
    return request({
        url: `${config.bpi}/parkingAdmin/charge/center`,
        method: 'put',
        params: data
    })
}

//车场运营情况(passRecord-进出车场统计，entranceRecord-入场车辆，appearanceRecord-出场车辆，income-车场收入，
// parkingPlaceInfo-车位使用情况，parkingDistribution-泊位使用率分布，chargeRanking-收费排名)
export function parkingOperate() {
    return request({
        url: `${config.bpi}/smartScreen/parkingOperate`,
        method: 'get',
        headers: {'showLoading': false}
    })
}

//设备运营
export function equipmentOperate() {
    return request({
        url: `${config.bpi}/smartScreen/equipmentOperate`,
        method: 'get',
        headers: {'showLoading': false}
    })
}

//获取现场图片
export function parkingAreaRoadInfo(data) {
    return request({
        url: `${config.bpi}/parkingAdmin/parkingAreaRoadInfo?roadId=` + data,
        method: 'get',
        headers: {'showLoading': false}

    })
}

//人行大屏统计
export function personnelStatisticsBig() {
    return request({
        url: `${config.cpi}/statistics/big`,
        method: 'get',
        headers: {'showLoading': false}
    })
}

//人行大屏手动离场
export function personnelManualExit(id) {
    return request({
        url: `${config.cpi}/statistics/manualExit?id=` + id,
        method: 'post',
    })
}

//修改车牌并确认放行
export function modifyPlatePass(data) {
    return request({
        url: `${config.bpi}/parkingAdmin/modifyPlate/pass`,
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

//道口通行情况列表
export function parkingAdminPassInfoList(roadIdList) {
    return request({
        url: `${config.bpi}/parkingAdmin/pass/info/list`,
        method: 'get',
        params: {roadIdList: roadIdList + ''},
        headers: {'showLoading': false}
    })
}

//运营方当班统计信息
export function parkingAdminStatistics(data) {
    return request({
        url: `${config.bpi}/parkingAdmin/onduty/statistics`,
        method: 'get',
        params: {loginTime: data},
        headers: {'showLoading': false}
    })
}

// 运营方换班
export function customerSwitch(data) {
    return request({
        url: `${config.api}/user/customer/switch`,
        method: 'put',
        params: data,
    })
}

//在场车辆列表
export function parkingAdminPresentCar(data) {
    return request({
        url: `${config.bpi}/parkingAdmin/presentCar`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}

//匹配在场车辆
export function presentCarMatch(data) {
    return request({
        url: `${config.bpi}/parkingAdmin/presentCar/match`,
        method: 'post',
        params: data,
    })
}
