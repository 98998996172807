import request from '@/utils/request'
import config from '@/config'
// 优惠券模板列表
export function couponModelList(data) {
    return request({
        url: `${config.bpi}/coupon/model`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}
// 优惠券模板列表
export function modelList() {
    return request({
        url: `${config.bpi}/coupon/modelList`,
        method: 'get',
        headers: {'showLoading': false}
    })
}
// 添加优惠券模板
export function couponModelSave(data) {
    return request({
        url: `${config.bpi}/coupon/model`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}
// 编辑优惠券模板
export function couponModelEdit(data) {
    return request({
        url: `${config.bpi}/coupon/model`,
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 删除优惠券模板
export function couponModelDel(id) {
    return request({
        url: `${config.bpi}/coupon/model`,
        method: 'delete',
        params: {id:id}
    })
}

// 优惠券列表
export function couponList(data) {
    return request({
        url: `${config.bpi}/coupon`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}
// 发放优惠劵给商家
export function couponBusinessSave(data) {
    return request({
        url: `${config.bpi}/coupon/business`,
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 优惠统计
export function statisticsList(data) {
    return request({
        url: `${config.bpi}/coupon/statistics`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}

// 商家列表
export function couponBusinessList() {
    return request({
        url: `${config.bpi}/coupon/business`,
        method: 'get',
        headers: {'showLoading': false}
    })
}

// 编辑优惠管理启用(停用)
export function couponStatus(id, status) {
    return request({
        url: `${config.bpi}/coupon/status?id=`+id+`&type=`+status,
        method: 'put',
    })
}
