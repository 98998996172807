<template>
    <div class="app-container">
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>雷达测速</el-breadcrumb-item>
            <el-breadcrumb-item>测速点管理</el-breadcrumb-item>
        </el-breadcrumb>
        <el-button style="margin: 15px 0" icon="el-icon-plus" type="primary" size="small" @click="handleAdd">添加
        </el-button>
        <el-button style="margin: 15px 10px" type="primary" size="small" @click="handleRefresh">刷 新</el-button>
        <!--列表-->
        <el-table
                size="small"
                :data="listData"
                highlight-current-row
                v-loading="loading"
                element-loading-text="拼命加载中"
                border stripe
                style="width: 100%;"
                :row-style="{height:'45px'}"
                :cell-style="{padding:'0px'}"
                :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                height="500" max-height="500">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column prop="speedPositionName" label="测速点名称" align="center"></el-table-column>
            <el-table-column prop="speedPositionAddr" label="测速点位置" align="center"></el-table-column>
            <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                    <el-button size="mini" @click="handleSettings(scope.row)"
                               type="primary" icon="el-icon-setting">设置
                    </el-button>
                    <el-button size="mini" type="primary" icon="el-icon-edit-outline"
                               @click="handleEdit(scope.row)">编辑
                    </el-button>
                    <el-button size="mini" type="danger" class="el-icon-delete"
                               @click="handleDelete(scope.row)">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="current"
                       :page-sizes="[10, 50, 200, 1000]"
                       :page-size="size"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
        <el-dialog v-draggable  title="测速点管理" :visible.sync="visibleDialog" width="40%">
            <el-form :model="visibleForm" ref="visibleForm" size="small" :rules="rules" label-width="100px">
                <el-form-item label="测试点名称" prop="speedPositionName">
                    <el-input v-model="visibleForm.speedPositionName" placeholder="请输入内容"/>
                </el-form-item>
                <el-form-item label="测速点地址" prop="speedPositionAddr">
                    <el-input style="width: 100%" placeholder="请输入地址" v-model="visibleForm.speedPositionAddr"/>
                </el-form-item>
                <el-form-item label="测速点经纬度" prop="address">
                    <el-input style="width: 100%" placeholder="请选择地点" v-model="visibleForm.position" readonly>
                        <!--                                <template slot="prepend">-->
                        <!--                                    {{addressInfo.province}}{{addressInfo.city}}{{addressInfo.district}}-->
                        <!--                                </template>-->
                        <el-button slot="append" icon="el-icon-map" @click="showMap" type="primary">选择地点
                        </el-button>
                    </el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="visibleDialog = false">取 消</el-button>
                <el-button type="primary" @click="submitForm('visibleForm')">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 百度地图位置选择 -->
        <BMapAddressSelect ref="bmapAddressSelect" @confirmMapAddress="confirmMapAddress"></BMapAddressSelect>
    </div>
</template>

<script>
    import BMapAddressSelect from "../../components/BaiduMapSelect/index";
    import {addSpeedPosition, delSpeedPosition, editSpeedPosition, getSpeedPosition} from "../../api/speed";

    export default {
        name: "positionManagement",
        // eslint-disable-next-line vue/no-unused-components
        components: {BMapAddressSelect},
        data() {
            return {
                current: 1,
                size: 10,
                total: 0,
                loading: false,
                //数据
                listData: [],
                visibleDialog: false,
                visibleForm: {
                    id: "",
                    speedPositionName: "",
                    speedPositionAddr: "",
                    speedPositionLng: "",
                    speedPositionLat: "",
                    address: "",
                    position: ""
                },
                rules: {
                    speedPositionName: [{required: true, message: "请输入", trigger: "blur"}],
                },
            }
        },
        mounted() {
            this.fetchData();
        },
        methods: {
            fetchData() {
                let param = {
                    current: this.current,
                    size: this.size,
                }
                this.loading = true;
                getSpeedPosition(param).then(res => {
                    this.loading = false;
                    if (res.data.code == "SUCCESS") {
                        this.listData = res.data.data.records;
                        this.total = res.data.data.total;
                        this.current = res.data.data.current;
                        this.size = res.data.data.size;
                    }
                }).catch(() => {
                    this.loading = false;
                })
            },
            handleRefresh() {
                this.current = 1;
                this.fetchData();
            },
            handleSizeChange(val) {
                this.size = val;
                this.current = 1;
                this.fetchData();
            },
            handleCurrentChange(val) {
                this.current = val;
                this.fetchData();
            },
            /** 显示地图 */
            showMap() {
                this.$refs.bmapAddressSelect.show({
                    lng: this.visibleForm.speedPositionLng,
                    lat: this.visibleForm.speedPositionLat
                });
            },

            /** 确认地图地址 */
            confirmMapAddress(addressInfo) {
                this.visibleForm.speedPositionLng = addressInfo.longitude;
                this.visibleForm.speedPositionLat = addressInfo.latitude;
                this.visibleForm.position = addressInfo.longitude + ',' + addressInfo.latitude;
            },
            //添加
            handleAdd() {
                this.visibleDialog = true;
                Object.keys(this.visibleForm).map(key => {
                    this.visibleForm[key] = '';
                })
            },
            //提交表单
            submitForm(visibleForm) {
                this.$refs[visibleForm].validate((valid) => {
                    if (valid) {
                        let param = {
                            id: this.visibleForm.id,
                            speedPositionName: this.visibleForm.speedPositionName,
                            speedPositionAddr: this.visibleForm.speedPositionAddr,
                            speedPositionLng: this.visibleForm.speedPositionLng,
                            speedPositionLat: this.visibleForm.speedPositionLat
                        };
                        if (this.visibleForm.id) {
                            editSpeedPosition(param).then(res => {
                                this.visibleDialog = false;
                                this.fetchData();
                                if (res.data.code == "SUCCESS") {
                                    this.$message.success("编辑成功！");
                                } else {
                                    this.$message.info(res.data.message);
                                }
                            })
                        } else {
                            addSpeedPosition(param).then(res => {
                                this.visibleDialog = false;
                                this.fetchData();
                                if (res.data.code == "SUCCESS") {
                                    this.$message.success("保存成功！");
                                } else {
                                    this.$message.info(res.data.message);
                                }
                            })
                        }
                    }
                })
            },
            //设置
            handleSettings(row) {
                this.$router.push({path: '/speedMain/radarSettings', query:{id: row.id}});
            },
            //编辑
            handleEdit(row) {
                this.visibleDialog = true;
                Object.keys(this.visibleForm).map(key => {
                    this.visibleForm[key] = row[key];
                });
                this.visibleForm.position = row.speedPositionLng + ',' + row.speedPositionLat;
            },
            //删除
            handleDelete(row) {
                this.$confirm("是否确认删除数据?", "温馨提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    delSpeedPosition(row.id).then(res => {
                        this.fetchData();
                        if (res.data.code == "SUCCESS") {
                            this.$message.success("删除成功！");
                        } else {
                            this.$message.info(res.data.message);
                        }
                    })
                })
            }
        }
    }
</script>

<style scoped>
</style>
