import request from '@/utils/request'
import config from '@/config.js'

// 登录方法
export function login(data) {
    return request({
        url: `${config.api}/user/customer/login`,
        method: 'post',
        params: data,
    })
}

//用户管理列表
export function userList(data) {
    return request({
        url: `${config.api}/customer/project`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}

//修改
export function update(data) {
    return request({
        url: `${config.api}/customer`,
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

//重置密码
export function reset(data) {
    return request({
        url: `${config.api}/customer/pwd/reset`,
        method: 'put',
        params: data,
    })
}


// 添加物业
export function propertySave(data) {
    return request({
        url: `${config.api}/customer`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 删除物业
export function propertyDel(id) {
    return request({
        url: `${config.api}/customer`,
        method: 'delete',
        params: {id: id},
    })
}

//停车场相关
export function getCurrentParking() {
    return request({
        url: `${config.bpi}/parking/current`,
        method: 'get',
        headers: {'showLoading': false}
    })
}

//项目相关
export function getCurrentProject() {
    return request({
        url: `${config.api}/project/current`,
        method: 'get',
        headers: {'showLoading': false}
    })
}

//用户信息
export function getCustomerInfo() {
    return request({
        url: `${config.api}/customer/info`,
        method: 'get',
        headers: {'showLoading': false}
    })
}

//获取菜单列表
export function getMenuList() {
    return request({
        url: `${config.api}/user/customer/menu`,
        method: 'get',
        headers: {'showLoading': false}
    })
}

//角色管理列表
export function roleList(data) {
    return request({
        url: `${config.api}/customer/role`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}

// 添加角色
export function roleSave(data) {
    return request({
        url: `${config.api}/customer/role`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 修改角色
export function roleUpdate(data) {
    return request({
        url: `${config.api}/customer/role`,
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 删除角色
export function roleDel(id) {
    return request({
        url: `${config.api}/customer/role`,
        method: 'delete',
        params: {id: id},
    })
}

//授权(获取角色权限树)
export function rolePowerList(id) {
    return request({
        url: `${config.api}/customer/role/power`,
        method: 'get',
        params: {id: id},
        headers: {'showLoading': false}
    })
}

// 授权(保存)
export function rolePowerSave(data) {
    return request({
        url: `${config.api}/customer/role/rolePower`,
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

//获取角色
export function selectRoleList() {
    return request({
        url: `${config.api}/customer/roleList`,
        method: 'get',
        headers: {'showLoading': false}
    })
}

//开放平台接入方
export function userLogin(data) {
    return request({
        url: `${config.api}/open/user/login`,
        method: 'post',
        data: data,
        transformRequest: [function (data) {
            // 数据默认会以json格式传递，需要转成key-value
            let ret = '';
            for (let i in data) {
                ret += encodeURIComponent(i) + '=' + encodeURIComponent(data[i]) + '&'
            }
            return ret.slice(0, -1);
        }],
    })
}

//无人值守设置
export function parkingNotice(data) {
    return request({
        url: `${config.bpi}/parking`,
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

