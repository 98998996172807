<template>
    <div class="dashboard-editor-container">
        <el-row :gutter="20" class="mgb20">
            <el-col :span="6">
                <el-card shadow="hover" :body-style="{ padding: '0px' }">
                    <div class="grid-content grid-con-1">
                        <img class="grid-con-icon" src="../../assets/images/icon2.png"/>
                        <!--                        <i class="el-icon-user-solid "></i>-->
                        <div class="grid-cont-right">
                            <div class="grid-num">{{dataInfo.tempNum ? dataInfo.tempNum : 0}}</div>
                            <div>临停在场车辆</div>
                        </div>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="6">
                <el-card shadow="hover" :body-style="{ padding: '0px' }">
                    <div class="grid-content grid-con-1">
                        <img class="grid-con-icon" src="../../assets/images/icon1.png"/>
                        <div class="grid-cont-right">
                            <div class="grid-num">{{dataInfo.longNum ? dataInfo.longNum : 0}}</div>
                            <div>长租在场车辆</div>
                        </div>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="6">
                <el-card shadow="hover" :body-style="{ padding: '0px' }">
                    <div class="grid-content grid-con-2">
                        <img class="grid-con-icon" src="../../assets/images/icon3.png"/>
                        <div class="grid-cont-right">
                            <div class="grid-num">{{dataInfo.tempAppearNum ? dataInfo.tempAppearNum : 0}}</div>
                            <div>今日临停出场</div>
                        </div>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="6">
                <el-card shadow="hover" :body-style="{ padding: '0px' }">
                    <div class="grid-content grid-con-2">
                        <img class="grid-con-icon" src="../../assets/images/icon4.png"/>
                        <div class="grid-cont-right">
                            <div class="grid-num">{{dataInfo.longAppearNum ? dataInfo.longAppearNum : 0}}</div>
                            <div>今日长租出场</div>
                        </div>
                    </div>
                </el-card>
            </el-col>
        </el-row>
        <el-row :gutter="20" class="mgb20">
            <el-col :span="6">
                <el-card shadow="hover" :body-style="{ padding: '0px' }">
                    <div class="grid-content grid-con-3">
                        <img class="grid-con-icon" src="../../assets/images/icon5.png"/>
                        <!--                        <i class="el-icon-user-solid "></i>-->
                        <div class="grid-cont-right">
                            <div class="grid-num">{{dataInfo.todayTempPay ? dataInfo.todayTempPay : 0}}</div>
                            <div>今日临停收费(元)</div>
                        </div>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="6">
                <el-card shadow="hover" :body-style="{ padding: '0px' }">
                    <div class="grid-content grid-con-3">
                        <img class="grid-con-icon" src="../../assets/images/icon6.png"/>
                        <div class="grid-cont-right">
                            <div class="grid-num">{{dataInfo.monthLongPay ? dataInfo.monthLongPay : 0}}</div>
                            <div>本月长租收费(元)</div>
                        </div>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="6">
                <el-card shadow="hover" :body-style="{ padding: '0px' }">
                    <div class="grid-content grid-con-4">
                        <img class="grid-con-icon" src="../../assets/images/icon7.png"/>
                        <div class="grid-cont-right">
                            <div class="grid-num">{{dataInfo.longCreate ? dataInfo.longCreate : 0}}</div>
                            <div>本月长租新开</div>
                        </div>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="6">
                <el-card shadow="hover" :body-style="{ padding: '0px', cursor: 'pointer' }">
                    <div class="grid-content grid-con-4">
                        <img class="grid-con-icon" src="../../assets/images/icon8.png"/>
                        <div class="grid-cont-right" @click="jumpTo('/parkingLot/longTermRentRate?expired=true')">
                            <div class="grid-num">{{dataInfo.monthExpire ? dataInfo.monthExpire : 0}}</div>
                            <div>本月长租过期未续费</div>
                        </div>
                    </div>
                </el-card>
            </el-col>
        </el-row>
        <div class="tit"><span class="el-icon-s-data"></span><span>昨日营收</span></div>
        <el-row :gutter="20">
            <el-col :span="11">
                <el-card shadow="hover" class="mgb20" :body-style="{ padding: '10px 0px' }">
                    <template #header>
                        <div class="clearfix">
                            <p>近期车场流量走势</p>
                            <el-form ref="filters" :model="filters" :inline="true" label-position="right"
                                     style="height: 35px">
                                <el-form-item>
                                    <el-button type="primary" size="mini" @click="search(7)">近7天</el-button>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" size="mini" @click="search(15)">近15天</el-button>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" size="mini" @click="search(30)">近30天</el-button>
                                </el-form-item>
                            </el-form>
                        </div>
                    </template>
                    <div ref="myEchart1" id="myEchart1"></div>
                </el-card>
                <el-card shadow="hover" class="mgb20" :body-style="{ padding: '10px 0px' }">
                    <template #header>
                        <div class="clearfix">
                            <p>近期收费走势</p>
                            <el-form ref="filters" :model="filters" :inline="true" label-position="right"
                                     style="height: 35px">
                                <el-form-item>
                                    <el-button type="primary" size="mini" @click="searchDay(7)">近7天</el-button>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" size="mini" @click="searchDay(15)">近15天</el-button>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" size="mini" @click="searchDay(30)">近30天</el-button>
                                </el-form-item>
                            </el-form>
                        </div>
                    </template>
                    <div ref="myEchart2" id="myEchart2"></div>
                </el-card>
            </el-col>
            <el-col :span="13">
                <el-card shadow="hover" class="mgb20" :body-style="{ padding: '10px' }">
                    <template #header>
                        <div class="clearfix" style="margin: 5px 0">
                            <span>抓拍记录</span>
                        </div>
                    </template>
                    <el-table :data="tableData" stripe style="width:100%;" height="345" max-height="345"
                              :row-style="{height:'45px'}" :cell-style="{padding:'0px'}"
                              :header-cell-style="{background:'#eef1f6',color:'#606266'}">
                        :default-sort = "{prop: 'passTime', order: 'descending'}">
                        <el-table-column min-width="30" type="index" label="序号"></el-table-column>
                        <el-table-column prop="licensePlate" label="车牌号码" align="center">
                            <template slot-scope="scope">
                                <span>{{scope.row.licensePlate?scope.row.licensePlate:'无牌车'}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="direction" label="出入方向" align="center">
                            <template slot-scope="scope">
                                <el-tag type="info" v-if="scope.row.direction == 2">出场</el-tag>
                                <el-tag type="danger" v-else-if="scope.row.direction == 1">入场</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="roadName" label="道口名称" align="center"></el-table-column>
                        <el-table-column prop="passTime" label="通行时间" align="center"></el-table-column>
                        <el-table-column prop="parkingType" label="停车类型" align="center">
                            <template slot-scope="scope">
                                <el-tag type="primary" v-if="scope.row.parkingType == 1">临停</el-tag>
                                <el-tag type="success" v-else-if="scope.row.parkingType == 2">长租</el-tag>
                                <el-tag type="warning" v-else-if="scope.row.parkingType == 3">会员</el-tag>
                                <el-tag type="info"
                                        v-else-if="scope.row.parkingType == 4 || scope.row.parkingType == 8">
                                    长租过期
                                </el-tag>
                                <el-tag type="danger" v-else-if="scope.row.parkingType == 5">长租超员</el-tag>
                                <el-tag type="danger" v-else-if="scope.row.parkingType == 6">储值</el-tag>
                                <el-tag type="danger" v-else-if="scope.row.parkingType == 7">预约</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="passImg" label="通行图片" align="center">
                            <template slot-scope="scope">
                                <el-image
                                        style="width: 50px; height: 50px"
                                        :src="scope.row.passImg?scope.row.passImg:require('../../assets/images/defaultImg.png')"
                                        :preview-src-list="[scope.row.passImg?scope.row.passImg:require('../../assets/images/defaultImg.png')]">
                                    <div slot="error">
                                        <el-image :src="require('../../assets/images/defaultImg.png')">
                                        </el-image>
                                    </div>
                                </el-image>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination background
                                   @size-change="handleSizeChange"
                                   @current-change="handleCurrentChange"
                                   :current-page="passRecordFilters.current"
                                   :page-sizes="[10, 50, 200, 1000]"
                                   :page-size="passRecordFilters.size"
                                   layout="total, sizes, prev, pager, next, jumper"
                                   :total="total">
                    </el-pagination>
                </el-card>
                <el-card shadow="hover" class="mgb20">
                    <template #header>
                        <div class="clearfix" style="margin: 5px 0">
                            <span>我的设备</span>
                        </div>
                    </template>
                    <el-table :data="dataList" stripe style="width:100%;" height="315" max-height="315"
                              :row-style="{height:'45px'}" :cell-style="{padding:'0px'}"
                              :header-cell-style="{background:'#eef1f6',color:'#606266'}">
                        <el-table-column type="index" label="序号"></el-table-column>
                        <el-table-column prop="equipmentName" label="设备名称" align="center"></el-table-column>
                        <el-table-column prop="equipmentType" label="设备类型" align="center">
                            <template slot-scope="scope">
                                <el-tag v-if="scope.row.equipmentType != null"
                                        :type="scope.row.equipmentType == 1 ? '' : scope.row.equipmentType == 2 ? 'success' : scope.row.equipmentType == 3 ? 'warning' : scope.row.equipmentType == 5 ? 'info' : ''">
                                    {{scope.row.equipmentType == 1 ? '车牌相机' : scope.row.equipmentType == 2 ? '液晶显示控制器' :
                                    scope.row.equipmentType == 3 ? '车道监控相机' : scope.row.equipmentType == 5 ? '门禁控制器' :
                                    ''}}
                                </el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="roadName" label="道口名称" align="center"></el-table-column>
                        <el-table-column prop="status" label="状态" align="center">
                            <template slot-scope="scope">
                                <el-tag v-if="scope.row.status != null"
                                        :type="scope.row.status == 1 ? '' : 'info'">
                                    {{scope.row.status == 1 ? '在线' : scope.row.status == 2 ? '离线' : ''}}
                                </el-tag>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import * as echarts from "echarts";
    import {operateInfo, flowTrend, chargeTrend} from "../../api/home";
    import {passRecordList} from "../../api/record/vehiclelist";
    import {equipmentOperate} from "../../api/bigscreen";
    import {formatDate, isNumber} from "../../utils";

    export default {
        name: "homePage",
        data() {
            return {
                filters: {
                    name: ''
                },
                dataInfo: {
                    tempNum: "",
                    longNum: "",
                    tempAppearNum: "",
                    longAppearNum: "",
                    todayTempPay: "",
                    monthLongPay: "",
                    monthExpire: "",
                    longCreate: "",
                },
                days: "7",
                day: "7",
                loading: false,
                total: 0,
                tableData: [],
                passRecordFilters: {
                    current: 1,
                    size: 50,
                },
                dataList: [],
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.initChart1();
                this.initChart2();
            });
            this.getOperateInfo();
            this.passRecordFetchData();
            this.getEquipmentOperate();
        },
        methods: {
            jumpTo(url) {
                this.$router.push(url);
            },
            //运营情况
            getOperateInfo() {
                operateInfo().then((res) => {
                    this.dataInfo.tempNum = isNumber(res.data.data.tempNum);
                    this.dataInfo.longNum = isNumber(res.data.data.longNum);
                    this.dataInfo.tempAppearNum = isNumber(res.data.data.tempAppearNum);
                    this.dataInfo.longAppearNum = isNumber(res.data.data.longAppearNum);
                    this.dataInfo.todayTempPay = isNumber(res.data.data.todayTempPay);
                    this.dataInfo.monthLongPay = isNumber(res.data.data.monthLongPay);
                    this.dataInfo.monthExpire = isNumber(res.data.data.monthExpire);
                    this.dataInfo.longCreate = isNumber(res.data.data.longCreate);
                })
            },
            search(e) {
                this.days = e;
                this.initChart1()
            },
            //流量走势
            initChart1() {
                let param = {
                    days: this.days
                }
                flowTrend(param).then((res) => {
                        let xAxisData = [];
                        let seriesData1 = [];
                        let seriesData2 = [];
                        let seriesData3 = [];
                        let seriesData4 = [];
                        if (res.data.data) {
                            res.data.data.tempAppearance.forEach(e => {
                                xAxisData.push(e.time);
                                seriesData1.push(isNumber(e.value));
                            });
                            res.data.data.tmpEntrance.forEach(e => {
                                seriesData2.push(isNumber(e.value));
                            });
                            res.data.data.longAppearance.forEach(e => {
                                seriesData3.push(isNumber(e.value));
                            });
                            res.data.data.longEntrance.forEach(e => {
                                seriesData4.push(isNumber(e.value));
                            });
                        }
                        let myChart = echarts.init(this.$refs.myEchart1);
                        window.onresize = myChart.resize; //如果容器变大小，自适应从新构图
                        let option = {
                            tooltip: {
                                trigger: 'axis'
                            },
                            grid: {
                                left: '3%',
                                right: '4%',
                                bottom: '3%',
                                containLabel: true
                            },
                            toolbox: {
                                feature: {
                                    saveAsImage: {}
                                }
                            },
                            xAxis: {
                                type: 'category',
                                boundaryGap: true,
                                data: xAxisData,
                            },
                            yAxis: {
                                type: 'value'
                            },
                            legend: {
                                data: ['临停入场', '临停出场', '长租入场', '长租出场']
                            },
                            series: [
                                {
                                    name: '临停入场',
                                    type: 'line',
                                    smooth: true,
                                    //修改折线的颜色
                                    itemStyle: {
                                        color: '#c23531'
                                    },
                                    data: seriesData2
                                },
                                {
                                    name: '临停出场',
                                    type: 'line',
                                    smooth: true,
                                    //修改折线的颜色
                                    itemStyle: {
                                        color: '#2f4554'
                                    },
                                    data: seriesData1
                                },
                                {
                                    name: '长租入场',
                                    type: 'line',
                                    smooth: true,
                                    //修改折线的颜色
                                    itemStyle: {
                                        color: '#61a0a8'
                                    },
                                    data: seriesData4
                                },
                                {
                                    name: '长租出场',
                                    type: 'line',
                                    smooth: true,
                                    //修改折线的颜色
                                    itemStyle: {
                                        color: '#d48265'
                                    },
                                    data: seriesData3
                                }
                            ]
                        };
                        myChart.setOption(option);
                    }
                )
            },
            searchDay(e) {
                this.day = e;
                this.initChart2();
            },
            initChart2() {
                let param = {
                    days: this.day
                }
                chargeTrend(param).then((res) => {
                    let myEchart = echarts.init(this.$refs.myEchart2);
                    window.addEventListener("resize", function () {
                        myEchart.resize();
                    });//如果容器变大小，自适应从新构图
                    let xAxisData = [];
                    let seriesData1 = [];
                    let seriesData2 = [];
                    if (res.data.data) {
                        res.data.data.longCharge.forEach(e => {
                            xAxisData.push(e.time);
                            seriesData1.push(isNumber(e.doubleVal));
                        });
                        res.data.data.tempCharge.forEach(e => {
                            seriesData2.push(isNumber(e.doubleVal));
                        });
                    }
                    let option = {
                        tooltip: {
                            trigger: 'axis'
                        },
                        legend: {
                            data: ['临停', '长租']
                        },
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '3%',
                            containLabel: true
                        },
                        toolbox: {
                            feature: {
                                saveAsImage: {}
                            }
                        },
                        xAxis: {
                            type: 'category',
                            boundaryGap: true,
                            data: xAxisData
                        },

                        yAxis: {
                            type: 'value'
                        },
                        series: [
                            {
                                name: "临停",
                                type: "line",
                                smooth: true,
                                //修改折线的颜色
                                itemStyle: {
                                    color: '#2fed68'
                                },
                                data: seriesData2
                            },

                            {
                                name: "长租",
                                type: "line",
                                smooth: true,
                                itemStyle: {
                                    color: '#0055ba'
                                },
                                data: seriesData1
                            }
                        ]
                    };
                    myEchart.setOption(option);
                })
            },
            passRecordFetchData() { //获取数据
                let that = this;
                let params = {
                    current: that.passRecordFilters.current,
                    size: that.passRecordFilters.size,
                    startTime: formatDate(new Date(), "yyyy-MM-dd"),
                    endTime: formatDate(new Date(), "yyyy-MM-dd"),
                };
                that.loading = true;
                passRecordList(params).then((res) => {
                    that.loading = false;
                    that.tableData = res.data.data.records;
                    that.total = res.data.data.total;
                    that.passRecordFilters.current = res.data.data.current;
                    that.passRecordFilters.size = res.data.data.size;
                }).catch(error => {
                    that.loading = false;
                    console.log(error);
                });
            },
            handleSizeChange(val) {
                this.passRecordFilters.size = val;
                this.passRecordFilters.current = 1;
                this.passRecordFetchData();
            },
            handleCurrentChange(val) {
                this.passRecordFilters.current = val;
                this.passRecordFetchData();
            },

            //设备运营
            getEquipmentOperate() {
                equipmentOperate().then((res) => {
                    this.dataList = res.data.data.equipment
                })
            },
        },
    }
</script>

<style scoped>
    body {
        min-height: 75rem;
        -ms-overflow-style: none; /* IE 10+ */
        scrollbar-width: none; /* Firefox */
    }

    /* chrome 浏览器中滚动条消失 */
    body::-webkit-scrollbar {
        display: none !important;
    }

    .grid-content {
        display: flex;
        align-items: center;
        height: 100px;
    }

    .grid-cont-right {
        flex: 1;
        text-align: center;
        font-size: 14px;
        line-height: 30px;
        color: #999;
    }

    .grid-num {
        font-size: 30px;
        font-weight: bold;
    }

    .grid-con-icon {
        font-size: 50px;
        width: 100px;
        height: 100px;
        text-align: center;
        line-height: 100px;
        color: #fff;
    }

    .grid-con-1 .grid-con-icon {
        /*background: rgb(45, 140, 240);*/
    }


    .grid-con-2 .grid-con-icon {
        /*background: rgb(176 75 135);*/
    }


    .grid-con-3 .grid-con-icon {
        /*background: rgb(240, 133, 25);*/
    }

    .grid-con-1 .grid-num {
        color: rgb(45, 140, 240);
    }

    .grid-con-2 .grid-num {
        color: rgb(103, 194, 58);
    }

    .grid-con-3 .grid-num {
        color: rgb(176, 75, 135);
    }

    .grid-con-4 .grid-num {;
        color: rgb(240, 133, 25);
    }

    .user-info-cont div:first-child {
        font-size: 30px;
        color: #222;
    }

    .user-info-list span {
        margin-left: 70px;
    }

    .tit {
        font-family: "微软雅黑";
        font-size: 20px;
        margin: 20px 0;
    }

    /deep/ .el-card__header {
        padding: 10px;
    }

    .clearfix p {
        float: left;
        font-size: 15px;
        margin-top: 15px;
    }

    .clearfix span {
        font-size: 15px;
        margin: 20px 0;
    }


    .clearfix form {
        float: right;
    }

    .mgb20 {
        margin-bottom: 10px;
    }

    #myEchart1 {
        height: 345px;
        width: 100%;
    }

    #myEchart2 {
        height: 345px;
        width: 100%;
    }
</style>
