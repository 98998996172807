<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>系统管理</el-breadcrumb-item>
            <el-breadcrumb-item>用户管理</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 搜索筛选 -->
        <el-form :inline="true" :model="filters" class="user-search">
            <el-form-item>
                <el-input size="mini" v-model="filters.name" clearable placeholder="请输入姓名"
                          @keyup.enter.native="fetchData"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input size="mini" v-model="filters.username" clearable placeholder="请输入用户名"
                          @keyup.enter.native="fetchData"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-search" type="primary" size="mini" @click="fetchData">查询</el-button>
                <el-button icon="el-icon-plus" type="primary" size="mini" @click="handleAdd">添加</el-button>
                <el-button type="primary" size="mini" @click="handleClick">无人值守设置</el-button>
            </el-form-item>
        </el-form>
        <!--列表-->
        <el-table
                size="small"
                :data="tableData"
                highlight-current-row
                border
                v-loading="loading"
                element-loading-text="拼命加载中"
                style="width: 100%"
                :row-style="{height:'45px'}"
                :cell-style="{padding:'0px'}"
                :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                height="500"
                max-height="500">
            <el-table-column min-width="30" type="index" label="序号"></el-table-column>
            <el-table-column prop="username" label="用户名" align="center"></el-table-column>
            <el-table-column prop="realName" label="姓名" align="center"></el-table-column>
            <el-table-column prop="roleNameStr" label="角色" align="center"></el-table-column>
            <!--            <el-table-column prop="realName" label="性别" align="center"></el-table-column>-->
            <el-table-column prop="phone" label="手机号" align="center"></el-table-column>
            <el-table-column prop="email" label="邮箱" align="center"></el-table-column>
            <el-table-column prop="createTime" label="创建时间" align="center" :formatter="getCreateTime"></el-table-column>
            <el-table-column width="350" align="center" label="操作">
                <template slot-scope="scope">
                    <div>
                        <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
                                   type="primary">编辑
                        </el-button>
                        <el-button size="mini" @click="handleDelete(scope.row)"
                                   type="danger">删除
                        </el-button>
                        <el-button size="mini" type="warning" @click="handleChangePassword(scope.row)">修改密码
                        </el-button>
                        <el-button size="mini" type="warning" @click="handleReset(scope.row)">重置密码
                        </el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="filters.current"
                       :page-sizes="[10, 50, 200, 1000]"
                       :page-size="filters.size"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
        <el-dialog v-draggable :title="title" :visible.sync="addDialog">
            <el-form :model="editForm" ref="editForm" size="small" :rules="rules">
                <el-row>
                    <el-col :span="11">
                        <el-form-item label="用户名" prop="username" :label-width="formLabelWidth">
                            <el-input :readonly="readonly" size="small" v-model="editForm.username"
                                      placeholder="请输入内容"/>
                        </el-form-item>
                        <el-form-item v-if="showPass" label="密码" prop="password" :label-width="formLabelWidth">
                            <el-input type="password" size="small" v-model="editForm.password" placeholder="请输入内容"/>
                        </el-form-item>
                        <el-form-item label="手机号" prop="phone" :label-width="formLabelWidth">
                            <el-input oninput="value=value.replace(/[^\d]/g,'')" size="small" v-model="editForm.phone"
                                      placeholder="请输入内容"/>
                        </el-form-item>
                        <el-form-item label="账号状态" prop="status" :label-width="formLabelWidth">
                            <el-select style="width: 100%;" size="small" v-model="editForm.status" placeholder="请选择状态">
                                <el-option label="启用" :value="true"></el-option>
                                <el-option label="停用" :value="false"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11">
                        <el-form-item label="姓名" prop="realName" :label-width="formLabelWidth">
                            <el-input size="small" v-model="editForm.realName" placeholder="请输入内容"/>
                        </el-form-item>
                        <el-form-item v-if="showPass" label="确认密码" prop="checkPass" :label-width="formLabelWidth">
                            <el-input type="password" size="small" v-model="editForm.checkPass" placeholder="请输入内容"/>
                        </el-form-item>
                        <el-form-item label="角色" prop="roleIdList" :label-width="formLabelWidth">
                            <el-select style="width: 100%" size="small" v-model="editForm.roleIdList" multiple
                                       filterable allow-create default-first-option placeholder="请选择角色">
                                <el-option
                                        v-for="item in roleList"
                                        :key="item.id"
                                        :label="item.roleName"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="邮箱" prop="email" :label-width="formLabelWidth">
                            <el-input v-model="editForm.email" placeholder="请输入邮箱"/>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addDialog = false">取 消</el-button>
                <el-button type="primary" @click="submitForm('editForm')">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog v-draggable :visible.sync="dataUserDialog" :title="title" width="35%">
            <el-form size="small" :model="dialogForm" ref="dialogForm" :rules="dialogRules" label-width="120px">
                <el-row>
                    <el-col :span="20">
                        <el-form-item label="新密码" prop="newPassword">
                            <el-input v-model="dialogForm.newPassword" type="password"></el-input>
                        </el-form-item>
                        <el-form-item label="确认密码" prop="confirmPassword">
                            <el-input v-model="dialogForm.confirmPassword" type="password"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dataUserDialog = false" size="mini">取 消</el-button>
              <el-button type="primary" @click="updatePassword('dialogForm')" size="mini">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import {
        userList,
        propertySave,
        propertyDel,
        update,
        selectRoleList,
        getCurrentParking,
        parkingNotice
    } from "@/api/login";
    import {reset} from "../../../api/login";

    export default {
        name: "userManagement",
        data() {
            var validatePass = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请输入密码'));
                } else {
                    if (this.editForm.checkPass !== '') {
                        this.$refs.editForm.validateField('checkPass');
                    }
                    callback();
                }
            };
            var validatePass2 = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请再次输入密码'));
                } else if (value !== this.editForm.password) {
                    callback(new Error('两次输入密码不一致!'));
                } else {
                    callback();
                }
            };
            var validatePwd = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请输入密码'));
                } else {
                    if (this.dialogForm.confirmPassword !== '') {
                        this.$refs.dialogForm.validateField('confirmPassword');
                    }
                    callback();
                }
            };
            var validatePwd2 = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请再次输入密码'));
                } else if (value !== this.dialogForm.newPassword) {
                    callback(new Error('两次输入密码不一致!'));
                } else {
                    callback();
                }
            };
            return {
                loading: false,
                total: 0,
                tableData: [],
                filters: {
                    current: 1,
                    size: 10,
                    name: '',
                    username: '',
                },
                addDialog: false,
                readonly: false,
                showPass: false,
                title: '',
                editForm: {
                    projectId: "",
                    projectNo: "",
                    realName: "",
                    status: "",
                    username: "",
                    email: "",
                    password: "",
                    phone: "",
                    checkPass: "",
                    roleNameStr: "",
                    admin: false,
                    roleIdList: [],
                },
                roleList: [],
                rules: {
                    username: [{required: true, message: "请输入", trigger: "blur"}],
                    realName: [{required: true, message: "请输入", trigger: "blur"}],
                    password: [
                        {required: true, message: "密码不能为空", trigger: "blur"},
                        {min: 6, message: "用户密码不能少于六位数", trigger: "blur"},
                        {validator: validatePass, trigger: 'blur'}
                    ],
                    checkPass: [{required: true, message: "请输入", trigger: "blur"},
                        {validator: validatePass2, trigger: 'blur'}
                    ],
                    roleIdList: [{required: true, message: "请选择", trigger: "change"}],
                },
                formLabelWidth: '120px',
                dataUserDialog: false,
                dialogForm: {
                    id: "",
                    newPassword: "",
                    confirmPassword: "",
                },
                dialogRules: {
                    newPassword: [
                        {required: true, message: "用户密码不能为空", trigger: "blur"},
                        {min: 6, message: "用户密码不能少于六位数", trigger: "blur"},
                        {validator: validatePwd, trigger: 'blur'}
                    ],

                    confirmPassword: [
                        {required: true, message: "请确认密码", trigger: "blur"},
                        {min: 6, message: "长度不能少于6个字符", trigger: "blur"},
                        {validator: validatePwd2, trigger: "blur", required: true},
                    ],
                },
                dataInfo: {}
            }
        },
        mounted() {
            this.fetchData();
            this.getCurrentParking();
        },
        methods: {
            getCurrentParking() {
                getCurrentParking().then((res) => {
                    this.dataInfo = res.data.data;
                })
            },
            fetchData() {
                this.loading = true;
                userList(this.filters).then((res) => {
                    this.loading = false;
                    this.tableData = res.data.data.records;
                    this.total = res.data.data.total;
                    this.filters.current = res.data.data.current;
                    this.filters.size = res.data.data.size;
                }).catch(error => {
                    this.loading = false;
                });
            },
            handleSizeChange(val) {
                this.filters.size = val;
                this.filters.current = 1;
                this.fetchData();
            },
            handleCurrentChange(val) {
                this.filters.current = val;
                this.fetchData();
            },
            getCreateTime(row) {
                if (row.createTime != null) {
                    return row.createTime[0] + '-' + row.createTime[1] + '-' + row.createTime[2] + ' '
                        + row.createTime[3] + ':' + row.createTime[4] + ':' + row.createTime[5]
                }
            },
            selectRoleList() {
                selectRoleList().then((res) => {
                    this.roleList = res.data.data;
                })
            },
            //添加
            handleAdd() {
                this.title = '添加';
                this.addDialog = true;
                Object.keys(this.editForm).map(key => {
                    this.editForm[key] = '';
                })
                this.editForm.roleIdList = [];
                this.editForm.phone = '';
                this.editForm.status = true;
                this.readonly = false;
                this.showPass = true;
                this.selectRoleList();
            },
            // 编辑
            handleEdit(index, row) {
                this.title = '编辑';
                this.addDialog = true;
                this.readonly = true;
                this.showPass = false;
                Object.keys(this.editForm).map(key => {
                    this.editForm[key] = row[key];
                });
                this.editForm.id = row.id;
                this.editForm.checkPass = row.password;
                this.selectRoleList();
            },
            // 编辑、增加页面保存方法
            submitForm(editForm) {
                this.$refs[editForm].validate((valid) => {
                    if (valid) {
                        if (this.editForm.id) {
                            let param = {
                                id: this.editForm.id,
                                phone: this.editForm.phone,
                                realName: this.editForm.realName,
                                username: this.editForm.username,
                                email: this.editForm.email,
                                status: this.editForm.status,
                                admin: this.editForm.admin,
                                roleIdList: this.editForm.roleIdList
                            }
                            update(param).then((res) => {
                                this.addDialog = false;
                                if (res.data.code == "SUCCESS") {
                                    this.fetchData();
                                    this.$message.success("编辑成功！");
                                } else {
                                    this.$message.info(res.data.message);
                                }
                            }).catch((err) => {
                                this.addDialog = false;
                                this.$message.error("编辑保存失败，请稍后再试！");
                            });
                        } else {
                            let user = window.localStorage.getItem('userdata');
                            user = JSON.parse(user);
                            this.editForm.projectNo = user.projectNo;
                            this.editForm.projectId = user.projectId;
                            let admin = false;
                            let param = {
                                projectNo: this.editForm.projectNo,
                                projectId: this.editForm.projectId,
                                phone: this.editForm.phone,
                                realName: this.editForm.realName,
                                username: this.editForm.username,
                                email: this.editForm.email,
                                password: this.editForm.password,
                                status: this.editForm.status,
                                admin: admin,
                                roleIdList: this.editForm.roleIdList
                            }
                            propertySave(param).then((res) => {
                                this.addDialog = false;
                                if (res.data.code == "SUCCESS") {
                                    this.fetchData();
                                    this.$message.success("保存成功！");
                                } else {
                                    this.$message.info(res.data.message);
                                }
                            }).catch((err) => {
                                this.addDialog = false;
                                this.$message.error("编辑保存失败，请稍后再试！");
                            });
                        }
                    } else {
                        return false;
                    }
                });
            },
            //重置密码
            handleReset(row) {
                let that = this;
                that.$confirm(
                    '是否确认重置密码?',
                    "警告",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    }
                ).then(function () {
                    reset({customerId: row.id}).then((res) => {
                        if (res.data.code == "SUCCESS") {
                            that.$alert(
                                "该用户密码已重置为：" + res.data.data + "，请用新密码登录。",
                                "温馨提示", {
                                    confirmButtonText: '确定',
                                    callback: action => {
                                        console.log('成功')
                                    }
                                }
                            );
                            that.fetchData();
                        } else {
                            that.$message.error(res.data.message);
                        }
                    })
                }).catch(() => {
                    that.$message.error("已取消重置");
                });
            },
            handleDelete(row) {
                let that = this;
                that.$confirm(
                    '是否确认删除数据?',
                    "警告",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    }
                ).then(function () {
                    propertyDel(row.id).then((res) => {
                        if (res.data.code == "SUCCESS") {
                            that.fetchData();
                            that.$message.success("删除成功!");
                        } else {
                            that.$message.error(res.data.message);
                        }
                    })
                }).catch(() => {
                    that.$message.error("已取消删除");
                });
            },
            handleChangePassword(row) {
                this.title = '修改密码';
                this.dataUserDialog = true;
                Object.keys(this.dialogForm).map(key => {
                    this.dialogForm[key] = '';
                })
                this.dialogForm.id = row.id;
            },
            updatePassword(dialogForm) {
                this.$refs[dialogForm].validate((valid) => {
                    if (valid) {
                        let param = {
                            id: this.dialogForm.id,
                            password: this.dialogForm.confirmPassword,
                            admin: false,
                        }
                        update(param).then((res) => {
                            this.dataUserDialog = false;
                            if (res.data.code == "SUCCESS") {
                                this.fetchData();
                                this.$message.success("编辑成功！");
                            } else {
                                this.$message.error(res.data.message);
                            }
                        }).catch((err) => {
                            this.dataUserDialog = false;
                            this.$message.error("编辑保存失败，请稍后再试！");
                        });
                    } else {
                        return false;
                    }
                });
            },
            handleClick() {
                this.$prompt('此电话号码为用户语音通话次数用完后需要提供给车主的客服联系方式，如果接到此类电话，请尽快充值语音通话。', '', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputValue: this.dataInfo.adminPhone,
                    inputValidator: (value) => {       // 点击按钮时，对文本框里面的值进行验证
                        if (!value) {
                            return '请输入手机号码';
                        } else if (isNaN(value)) {
                            return '手机号输入不正确';
                        }
                    },
                }).then(({value}) => {
                    this.dataInfo.adminPhone = value;
                    parkingNotice(this.dataInfo).then(res => {
                        if (res.data.code == "SUCCESS") {
                            this.getCurrentParking();
                            this.$message.success(res.data.message);
                        } else {
                            this.$message.info(res.data.message);
                        }
                    })
                });
            }
        }
    }
</script>

<style scoped>
    .user-search {
        margin-top: 10px;
    }
</style>
