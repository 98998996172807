import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/assets/iconfont.css'
import '@/assets/css/style.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import {message} from './utils/message'
import Icon from 'vue-awesome/components/Icon';

import 'vue-awesome/icons/chart-bar.js';
import 'vue-awesome/icons/chart-area.js';
import 'vue-awesome/icons/chart-pie.js';
import 'vue-awesome/icons/chart-line.js';
import 'vue-awesome/icons/align-left.js';

Vue.use(ElementUI);
Vue.prototype.$message = message;
ElementUI.Dialog.props.closeOnClickModal.default = false;
//引入echarts插件
import echarts from 'echarts'

Vue.prototype.$echarts = echarts;
// 将自动注册所有组件为全局组件
import dataV from '@jiaminghi/data-view'

Vue.use(dataV)
// 全局注册图标
Vue.component('icon', Icon);

// 适配flex
import '@/common/flexible.js';

// 引入全局css
import './assets/scss/style.scss';

Vue.config.productionTip = false
import directive from './utils/directive'

Vue.use(directive)

import dayjs from 'dayjs';

Vue.prototype.dayjs = dayjs;

// vue中导出excel表格模板
import FileSaver from 'file-saver'
import XLSX from 'xlsx'

Vue.prototype.$FileSaver = FileSaver; //设置全局
Vue.prototype.$XLSX = XLSX; //设置全局

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
