import axios from 'axios'
import router from "@/router";
import {Message, MessageBox, Loading} from 'element-ui'
import _ from 'lodash';

axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';
//后台请求服务地址
var baseURL = "";
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: baseURL,
    // 超时
    timeout: 7200000
});

//loading对象
let loading;

//当前正在请求的数量
let needLoadingRequestCount = 0;

//显示loading
function showLoading() {
    // 后面这个判断很重要，因为关闭时加了抖动，此时loading对象可能还存在，
    // 但needLoadingRequestCount已经变成0.避免这种情况下会重新创建个loading
    if (needLoadingRequestCount === 0 && !loading) {
        loading = Loading.service({
            lock: true,
            text: "加载中...",
            background: 'rgba(255, 255, 255, 0.5)',
            target: '.el-dialog' || "body"
        });
    }
    needLoadingRequestCount++;
}

//隐藏loading
function hideLoading1() {
    needLoadingRequestCount--;
    needLoadingRequestCount = Math.max(needLoadingRequestCount, 0); //做个保护
    if (needLoadingRequestCount === 0) {
        //关闭loading
        toHideLoading();
    }
}

//隐藏loading
function hideLoading2() {
    needLoadingRequestCount--;
    needLoadingRequestCount = Math.max(needLoadingRequestCount, 0); //做个保护
    if (needLoadingRequestCount === 0) {
        //关闭loading
        endLoading();
    }
}

function endLoading() {
    loading.close();
    loading = null;
}

//防抖：将 300ms 间隔内的关闭 loading 便合并为一次。防止连续请求时， loading闪烁的问题。
var toHideLoading = _.debounce(() => {
    loading.close();
    loading = null;
}, 3000);

// request拦截器
service.interceptors.request.use(config => {
    if (localStorage.getItem('token')) {
        config.headers['token'] = localStorage.getItem('token')// 让每个请求携带自定义token 请根据实际情况自行修改
    }
    if (config.headers.showLoading !== false) {
        showLoading();
    }
    return config
}, error => {//判断当前请求是否设置了不显示Loading
    if (error.headers.showLoading !== false) {
        hideLoading1();
    }
    return Promise.reject(error)
});

let loginModal = false; //重复弹出要屏蔽

service.interceptors.response.use(response => {
    if (response.config.headers.showLoading !== false) {
        hideLoading2();
    }
    if (response.config.responseType == "blob") {
        return response;
    } else if (response.data.code === 'NO_LOGIN') {
        if (loginModal) {
            return;
        }
        loginModal = true;
        setTimeout(() => {
            loginModal = false;
        }, 5000);
        MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
                confirmButtonText: '重新登录',
                cancelButtonText: '取消',
                type: 'warning'
            }
        ).then(() => {
            localStorage.clear();
            router.replace({
                path: '/login',
            });
            return;
        })
    } else if (response.data.code != 'SUCCESS' && response.data.code != 'NEED_PAY') {
        if (response.data.message != null) {
            Message({
                message: response.data.message,
                type: 'error',
                duration: "2000"
            });
        }
        Promise.reject(`ERROR:${response.data.code}`)
    } else {
        return response;
    }
}, error => {
    if (error.config.headers.showLoading !== false) {
        hideLoading1();
    }
    return Promise.reject(error)
});
export default service
