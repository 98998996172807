<template>
    <div>
        <!-- 搜索筛选 -->
        <el-form :inline="true" :model="filters" class="user-search">
            <el-form-item>
                <el-select size="small" v-model="filters.carProperty" clearable placeholder="请选择车辆属性">
                    <el-option label="小车" value="1"></el-option>
                    <el-option label="大车" value="2"></el-option>
                    <el-option label="新能源小车" value="5"></el-option>
                    <el-option label="新能源大车" value="6"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-search" type="primary" size="small" @click="fetchData">查询</el-button>
                <el-button icon="el-icon-plus" type="primary" size="small" @click="handleAdd">添加费率</el-button>
            </el-form-item>
        </el-form>
        <!--列表-->
        <el-table
                size="small"
                :data="tableData"
                v-loading="loading"
                element-loading-text="拼命加载中"
                highlight-current-row
                border
                style="width: 100%"
                :row-style="{height:'45px'}"
                :cell-style="{padding:'0px'}"
                :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                stripe
                height="484"
                max-height="484">
            <el-table-column type="index" min-width="30" label="序号"></el-table-column>
            <el-table-column prop="rateName" label="规则名称" align="center"></el-table-column>
            <el-table-column prop="parkingArea.name" label="区域" align="center"></el-table-column>
            <el-table-column prop="carProperty" label="车辆属性" align="center">
                <template slot-scope="scope">
                    <el-tag type="success" v-if="scope.row.carProperty == 1">小车</el-tag>
                    <el-tag v-else-if="scope.row.carProperty == 2">大车</el-tag>
                    <el-tag type="warning" v-else-if="scope.row.carProperty == 5">新能源小车</el-tag>
                    <el-tag type="danger" v-else-if="scope.row.carProperty == 6">新能源大车</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="status" label="状态" align="center">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.status == 1">启用</el-tag>
                    <el-tag type="info" v-else-if="scope.row.status  == 2">停用</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="updateTime" label="更新时间" align="center" :formatter="getUpdateTime"></el-table-column>
            <el-table-column width="250" align="center" label="操作">
                <template slot-scope="scope">
                    <el-button size="small" @click="handleEdit(scope.$index, scope.row)"
                               type="primary">查看
                    </el-button>
                    <el-button v-if="scope.row.status === 1" size="small" @click="handleStatus(scope.$index, scope.row)"
                               type="danger">停用
                    </el-button>
                    <el-button v-if="scope.row.status === 2" size="small" @click="handleStatus(scope.$index, scope.row)"
                               type="warning">启用
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="filters.current"
                       :page-sizes="[10, 50, 200, 1000]"
                       :page-size="filters.size"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
        <!--    添加-->
        <el-dialog v-draggable :title="title" :visible.sync="addDialog" width="60%">
            <el-form :model="editForm" ref="editForm" size="small" :rules="rules">
                <el-row>
                    <el-col :span="11">
                        <el-form-item label="规则名称" prop="rateName" :label-width="formLabelWidth">
                            <el-input size="small" v-model="editForm.rateName" placeholder="请输入规则名称"/>
                        </el-form-item>
                        <el-form-item label="车辆属性" prop="carProperty" :label-width="formLabelWidth">
                            <el-select size="small" v-model="editForm.carProperty" placeholder="请选择车辆属性">
                                <el-option label="小车" :value="1"></el-option>
                                <el-option label="大车" :value="2"></el-option>
                                <el-option label="新能源小车" :value="5"></el-option>
                                <el-option label="新能源大车" :value="6"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="计费方式" prop="" :label-width="formLabelWidth">
                            <el-select size="small" v-model="editForm.value" placeholder="请选择计费方式"
                                       @change="selectBilling">
                                <el-option label="全天单位时长" :value="1"></el-option>
                                <el-option label="全天阶梯固定" :value="2"></el-option>
                                <el-option label="全天按次" :value="3"></el-option>
                                <el-option label="分时段单位时长" :value="4"></el-option>
                                <el-option label="分时段按次" :value="5"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="免费时长" prop="freeTime" :label-width="formLabelWidth">
                            <div style="display: flex;">
                                <el-input size="small" oninput="value=value.replace(/[^\d]/g,'')"
                                          v-model="editForm.freeTime" placeholder="请输入"/>
                                <span style="margin-left: 5px;width: 40px;">分钟</span>
                            </div>
                        </el-form-item>
                        <el-form-item v-if="editForm.value == 1 || editForm.value == 2" label="首付时长" prop="freeTime"
                                      :label-width="formLabelWidth">
                            <div style="display: flex;">
                                <el-input size="small" oninput="value=value.replace(/[^\d]/g,'')"
                                          v-model="editForm.firstTime" placeholder="请输入"/>
                                <span style="margin-left: 5px;width: 40px;">分钟</span>
                            </div>
                        </el-form-item>
                        <el-form-item v-if="editForm.value == 1" label="单位时长" prop="freeTime"
                                      :label-width="formLabelWidth">
                            <div style="display: flex;">
                                <el-input size="small" :min="1" oninput="value=value.replace(/^0|[^0-9]/g, '')"
                                          v-model="editForm.unitTime" placeholder="请输入"/>
                                <span style="margin-left: 5px;width: 40px;">分钟</span>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11">
                        <el-form-item label="状态" prop="status" :label-width="formLabelWidth">
                            <el-select size="small" v-model="editForm.status" placeholder="请选择状态">
                                <el-option label="启用" :value="1"></el-option>
                                <el-option label="停用" :value="2"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="区域" prop="parkingAreaId" :label-width="formLabelWidth">
                            <el-select size="small" v-model="editForm.parkingAreaId" placeholder="请选择区域">
                                <el-option v-for="item in areasData" :label="item.name"
                                           :value="item.id" :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item v-if="editForm.value != 2" label="24小时限额" prop="dayLimit"
                                      :label-width="formLabelWidth">
                            <div style="display: flex;">
                                <el-input size="small" oninput="value=value.replace(/[^0-9.]/g,'')"
                                          v-model="editForm.dayLimit" placeholder="请输入"/>
                                <span style="margin-left: 5px;">元</span>
                            </div>
                        </el-form-item>
                        <el-form-item v-if="editForm.value == 1 || editForm.value == 2" label="首付金额" prop="freeTime"
                                      :label-width="formLabelWidth">
                            <div style="display: flex;">
                                <el-input size="small" oninput="value=value.replace(/[^0-9.]/g,'')"
                                          v-model="editForm.firstAmount" placeholder="请输入"/>
                                <span style="margin-left: 5px;">元</span>
                            </div>
                        </el-form-item>
                        <el-form-item v-if="editForm.value == 1 || editForm.value == 3"
                                      :label="editForm.value == 3 ? '单次金额':'单位金额'" prop="freeTime"
                                      :label-width="formLabelWidth">
                            <div style="display: flex;">
                                <el-input size="small" oninput="value=value.replace(/[^0-9.]/g,'')"
                                          v-model="editForm.unitAmount" placeholder="请输入"/>
                                <span style="margin-left: 5px;">元</span>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <!--                单位时长-->
                <div :class="editForm.value == 4 || editForm.value == 5 ? 'domains' : ''"
                     v-if="editForm.value == 4 || editForm.value == 5">
                    <el-row style="width: 100%" v-for="(domain, index) in editForm.domains" :key="domain.key">
                        <div style="display: flex;flex-direction: row;justify-content: space-evenly">
                            <el-form-item label-width="30px">
                                <el-time-picker @change="pickerStartHours(index,domain.startHours)" format='HH:mm'
                                                value-format='HH:mm:ss' v-model="domain.startHours" placeholder="开始时间">
                                </el-time-picker>
                            </el-form-item>
                            <span style="margin: 10px;">至</span>
                            <el-form-item>
                                <div style="display: flex;flex-direction: row;">
                                    <el-time-picker @change="pickerEndHours(index,domain.endHours)" format='HH:mm'
                                                    value-format='HH:mm:ss' v-model="domain.endHours"
                                                    placeholder="结束时间">
                                    </el-time-picker>
                                    <div>（不计入计费时间）</div>
                                </div>
                            </el-form-item>
                            <div>
                                <el-button v-if="index === 0"
                                           style="margin-left: 10px;background: #fff;border: none;color: #fff;"
                                           size="mini">
                                    删除
                                </el-button>
                                <el-button v-if="index !== 0" style="margin-left: 10px" type="danger" size="mini"
                                           @click.prevent="removeDomain1(domain)">删除
                                </el-button>
                            </div>
                        </div>
                        <el-col :span="11" v-if="editForm.value != 5">
                            <el-form-item label="首付时长" :label-width="formLabelWidth"
                                          :prop="'domains.' + index + '.firstTime'"
                                          :rules="[{required: true, message: '请输入', trigger: 'blur'}]">
                                <div style="display: flex;">
                                    <el-input size="small" oninput="value=value.replace(/[^\d]/g,'')"
                                              v-model="domain.firstTime" placeholder="请输入"/>
                                    <span style="margin-left: 5px;width: 40px;">分钟</span>
                                </div>
                            </el-form-item>
                            <el-form-item label="单位时长" :label-width="formLabelWidth"
                                          :prop="'domains.' + index + '.unitTime'"
                                          :rules="[{required: true, message: '请输入', trigger: 'blur'}]">
                                <div style="display: flex;">
                                    <el-input size="small" :min="1" oninput="value=value.replace(/^0|[^0-9]/g, '')"
                                              v-model="domain.unitTime" placeholder="请输入"/>
                                    <span style="margin-left: 5px;width: 40px;">分钟</span>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="11">
                            <el-form-item v-if="editForm.value != 5" label="首付金额" :label-width="formLabelWidth"
                                          :prop="'domains.' + index + '.firstAmount'"
                                          :rules="[{required: true, message: '请输入', trigger: 'blur'}]">
                                <div style="display: flex;">
                                    <el-input size="small" oninput="value=value.replace(/[^0-9.]/g,'')"
                                              v-model="domain.firstAmount" placeholder="请输入"/>
                                    <span style="margin-left: 5px;">元</span>
                                </div>
                            </el-form-item>
                            <el-form-item :label="editForm.value == 5 ? '单次金额' : '单位金额'" :label-width="formLabelWidth"
                                          :prop="'domains.' + index + '.unitAmount'"
                                          :rules="[{required: true, message: '请输入', trigger: 'blur'}]">
                                <div style="display: flex;">
                                    <el-input size="small" oninput="value=value.replace(/[^0-9.]/g,'')"
                                              v-model="domain.unitAmount" placeholder="请输入"/>
                                    <span style="margin-left: 5px;">元</span>
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <!--                阶梯固定-->
                <div :class="editForm.value == 2 ? 'dynamic' : ''" v-if="editForm.value == 2">
                    <el-row v-for="(item, index) in editForm.array" :key="item.key">
                        <el-col :span="11">
                            <el-form-item label-width="70px"
                                          :prop="'array.' + index + '.timingStart'"
                                          :rules="[{required: true, message: '请输入', trigger: 'blur'}]">
                                <div style="display: flex;">
                                    <el-input size="small" oninput="value=value.replace(/[^\d]/g,'')"
                                              v-model="item.timingStart" placeholder="请输入"/>
                                    <span style="margin: 0 5px;width: 40px;">至</span>
                                    <el-input size="small" oninput="value=value.replace(/[^\d]/g,'')"
                                              v-model="item.timingEnd" placeholder="请输入"/>
                                    <span style="margin-left: 5px;width: 84px;">分钟</span>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="11">
                            <el-form-item label="收费金额" :label-width="formLabelWidth"
                                          :prop="'array.' + index + '.unitAmount'"
                                          :rules="[{required: true, message: '请输入', trigger: 'blur'}]">
                                <div style="display: flex;">
                                    <el-input size="small" oninput="value=value.replace(/[^0-9.]/g,'')"
                                              v-model="item.unitAmount" placeholder="请输入"/>
                                    <span style="margin-left: 5px;">元</span>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-button v-if="index !== 0" style="margin-left: 10px" type="danger" size="mini"
                                   @click.prevent="removeDomain2(item)">删除
                        </el-button>
                    </el-row>
                </div>
                <el-row>
                    <el-form-item label-width="70px">
                        <el-button size="small" type="primary" @click="confirm('editForm')">保 存</el-button>
                        <el-button size="small" type="info" @click="addDialog = false">取 消</el-button>
                        <el-button size="small" type="warning" v-if="editForm.value == 2" @click="addDomain2">新 增
                        </el-button>
                        <el-button size="small" type="warning" v-if="editForm.value == 4" @click="addDomain1">新 增
                        </el-button>
                        <el-button size="small" type="warning" v-if="editForm.value == 5" @click="addDomain3">新 增
                        </el-button>
                    </el-form-item>
                </el-row>
                <el-row style="border-top: #d3d3d3 1px solid;">
                    <div style="margin-top: 15px;display: flex;flex-direction: row;justify-content: space-around;">
                        <el-button type="success" size="small" @click="confirmCost1">计费测试</el-button>
                        <el-date-picker
                                style="width: 190px;"
                                size="small"
                                v-model="editForm.entranceTime"
                                type="datetime"
                                placeholder="开始日期">
                        </el-date-picker>
                        <el-date-picker
                                style="width: 190px;"
                                size="small"
                                v-model="editForm.exitTime"
                                type="datetime"
                                placeholder="结束日期">
                        </el-date-picker>
                        <div style="margin-top: 5px;display: flex;flex-direction: row;justify-content: center">
                            <span>时长：</span>
                            <span>{{dataTime}}</span>
                        </div>
                        <div style="margin-top: 5px;display: flex;flex-direction: row;justify-content: center">
                            <span>费用：</span>
                            <span>{{cost}} 元</span>
                        </div>
                    </div>
                </el-row>
            </el-form>
        </el-dialog>
        <!--    查看-->
        <el-dialog v-draggable :title="title" :visible.sync="showDialog" width="60%">
            <el-form :model="showForm" ref="showForm" size="small" :rules="rules">
                <el-row>
                    <el-col :span="11">
                        <el-form-item label="规则名称" prop="rateName" :label-width="formLabelWidth">
                            <el-input readonly size="small" v-model="showForm.rateName" placeholder="请输入内容"/>
                        </el-form-item>
                        <el-form-item label="车辆属性" prop="carProperty" :label-width="formLabelWidth">
                            <el-select disabled size="small" v-model="showForm.carProperty" placeholder="请选择车辆属性">
                                <el-option label="小车" :value="1"></el-option>
                                <el-option label="大车" :value="2"></el-option>
                                <el-option label="新能源小车" :value="5"></el-option>
                                <el-option label="新能源大车" :value="6"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="计费方式" prop="" :label-width="formLabelWidth">
                            <el-select disabled size="small" v-model="showForm.value" placeholder="请选择计费方式">
                                <el-option label="全天单位时长" :value="1"></el-option>
                                <el-option label="全天阶梯固定" :value="2"></el-option>
                                <el-option label="全天按次" :value="3"></el-option>
                                <el-option label="分时段单位时长" :value="4"></el-option>
                                <el-option label="分时段按次" :value="5"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="免费时长" prop="freeTime" :label-width="formLabelWidth">
                            <div style="display: flex;">
                                <el-input readonly size="small" oninput="value=value.replace(/[^\d]/g,'')"
                                          v-model="showForm.freeTime" placeholder="请输入"/>
                                <span style="margin-left: 5px;width: 40px;">分钟</span>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11">
                        <el-form-item label="状态" prop="status" :label-width="formLabelWidth">
                            <el-select disabled size="small" v-model="showForm.status" placeholder="请选择状态">
                                <el-option label="启用" :value="1"></el-option>
                                <el-option label="停用" :value="2"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="区域" prop="parkingAreaId" :label-width="formLabelWidth">
                            <el-select disabled size="small" v-model="showForm.parkingAreaId" placeholder="请选择区域">
                                <el-option v-for="item in areasData" :label="item.name"
                                           :value="item.id" :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item v-if="showForm.value != 2" label="24小时限额" prop="dayLimit"
                                      :label-width="formLabelWidth">
                            <div style="display: flex;">
                                <el-input readonly size="small" oninput="value=value.replace(/[^0-9.]/g,'')"
                                          v-model="showForm.dayLimit" placeholder="请输入"/>
                                <span style="margin-left: 5px;">元</span>
                            </div>
                        </el-form-item>
                        <el-form-item v-if="showForm.value == 2" label="首付时长" :label-width="formLabelWidth">
                            <div style="display: flex;">
                                <el-input readonly size="small" oninput="value=value.replace(/[^\d]/g,'')"
                                          v-model="showForm.domains[showForm.domains.length-1].firstTime"
                                          placeholder="请输入"/>
                                <span style="margin-left: 5px;width: 40px;">分钟</span>
                            </div>
                        </el-form-item>
                        <el-form-item v-if="showForm.value == 2" label="首付金额" :label-width="formLabelWidth">
                            <div style="display: flex;">
                                <el-input readonly size="small" oninput="value=value.replace(/[^0-9.]/g,'')"
                                          v-model="showForm.domains[showForm.domains.length-1].firstAmount"
                                          placeholder="请输入"/>
                                <span style="margin-left: 5px;">元</span>
                            </div>
                        </el-form-item>
                        <el-form-item v-if="showForm.value == 3" label="单次金额" :label-width="formLabelWidth">
                            <div style="display: flex;" v-for="(domain, index) in showForm.domains" :key="index">
                                <el-input readonly size="small" oninput="value=value.replace(/[^0-9.]/g,'')"
                                          v-model="domain.unitAmount" placeholder="请输入"/>
                                <span style="margin-left: 5px;">元</span>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div :class="showForm.value == 4 || showForm.value == 5 ? 'domains' : ''"
                     v-if="showForm.value == 1 || showForm.value == 4 || showForm.value == 5">
                    <el-row style="width: 100%" v-for="(domain, index) in showForm.domains" :key="domain.key">
                        <div style="display: flex;flex-direction: row;justify-content: space-evenly"
                             v-if="showForm.value != 1">
                            <el-form-item label-width="30px">
                                <el-time-picker readonly format='HH:mm' value-format='HH:mm:ss'
                                                v-model="domain.startHours"
                                                placeholder="开始时间">
                                </el-time-picker>
                            </el-form-item>
                            <span style="margin: 10px;">至</span>
                            <el-form-item>
                                <div style="display: flex;flex-direction: row;">
                                    <el-time-picker readonly format='HH:mm' value-format='HH:mm:ss'
                                                    v-model="domain.endHours"
                                                    placeholder="结束时间">
                                    </el-time-picker>
                                    <div>（不计入计费时间）</div>
                                </div>
                            </el-form-item>
                            <div>
                                <el-button v-if="index === 0"
                                           style="margin-left: 10px;background: #fff;border: none;color: #fff;"
                                           size="mini">
                                    删除
                                </el-button>
                                <el-button v-if="index !== 0" style="margin-left: 10px" type="danger" size="mini">删除
                                </el-button>
                            </div>
                        </div>
                        <el-col :span="11" v-if="showForm.value != 5">
                            <el-form-item label="首付时长" :label-width="formLabelWidth">
                                <div style="display: flex;">
                                    <el-input readonly size="small" oninput="value=value.replace(/[^\d]/g,'')"
                                              v-model="domain.firstTime" placeholder="请输入"/>
                                    <span style="margin-left: 5px;width: 40px;">分钟</span>
                                </div>
                            </el-form-item>
                            <el-form-item label="单位时长" :label-width="formLabelWidth">
                                <div style="display: flex;">
                                    <el-input readonly size="small" oninput="value=value.replace(/[^\d]/g,'')"
                                              v-model="domain.unitTime" placeholder="请输入"/>
                                    <span style="margin-left: 5px;width: 40px;">分钟</span>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="11">
                            <el-form-item v-if="showForm.value != 5" label="首付金额" :label-width="formLabelWidth">
                                <div style="display: flex;">
                                    <el-input readonly size="small" oninput="value=value.replace(/[^0-9.]/g,'')"
                                              v-model="domain.firstAmount" placeholder="请输入"/>
                                    <span style="margin-left: 5px;">元</span>
                                </div>
                            </el-form-item>
                            <el-form-item :label="editForm.value == 5 ? '单次金额' : '单位金额'" :label-width="formLabelWidth">
                                <div style="display: flex;">
                                    <el-input readonly size="small" oninput="value=value.replace(/[^0-9.]/g,'')"
                                              v-model="domain.unitAmount" placeholder="请输入"/>
                                    <span style="margin-left: 5px;">元</span>
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <!--                阶梯固定-->
                <div :class="showForm.value == 2 ? 'dynamic' : ''" v-if="showForm.value == 2">
                    <el-row v-for="(item, index) in showForm.domains" :key="item.key">
                        <el-col :span="11">
                            <el-form-item label-width="70px">
                                <div style="display: flex;">
                                    <el-input readonly size="small" oninput="value=value.replace(/[^\d]/g,'')"
                                              v-model="item.timingStart" placeholder="请输入"/>
                                    <span style="margin: 0 5px;width: 40px;">至</span>
                                    <el-input readonly size="small" oninput="value=value.replace(/[^\d]/g,'')"
                                              v-model="item.timingEnd" placeholder="请输入"/>
                                    <span style="margin-left: 5px;width: 84px;">分钟</span>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="11">
                            <el-form-item label="收费金额" :label-width="formLabelWidth">
                                <div style="display: flex;">
                                    <el-input readonly size="small" oninput="value=value.replace(/[^0-9.]/g,'')"
                                              v-model="item.unitAmount" placeholder="请输入"/>
                                    <span style="margin-left: 5px;">元</span>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-button v-if="index !== 0" style="margin-left: 10px" type="danger" size="mini">删除</el-button>
                    </el-row>
                </div>
                <el-row>
                    <el-form-item label-width="70px">
                        <el-button type="primary" @click="showDialog = false">关 闭</el-button>
                    </el-form-item>
                </el-row>
                <el-row style="border-top: #d3d3d3 1px solid;">
                    <div style="margin-top: 15px;display: flex;flex-direction: row;justify-content: space-around;">
                        <el-button size="small" type="primary" @click="confirmCost2">计费测试</el-button>
                        <el-date-picker
                                style="width: 190px;"
                                size="small"
                                v-model="showForm.entranceTime"
                                type="datetime"
                                placeholder="开始日期">
                        </el-date-picker>
                        <el-date-picker
                                style="width: 190px;"
                                size="small"
                                v-model="showForm.exitTime"
                                type="datetime"
                                placeholder="结束日期">
                        </el-date-picker>
                        <div style="margin-top: 5px;display: flex;flex-direction: row;justify-content: center">
                            <span>时长：</span>
                            <span>{{dataTime}}</span>
                        </div>
                        <div style="margin-top: 5px;display: flex;flex-direction: row;justify-content: center">
                            <span>费用：</span>
                            <span>{{cost}}元</span>
                        </div>
                    </div>
                </el-row>
            </el-form>
        </el-dialog>
        <!--   时段提示     -->
        <el-dialog v-draggable title="温馨提示" :visible.sync="promptDialog" width="40%">
            <div style="display: flex;flex-direction: column;align-items: center;line-height: 20px;max-height: 300px;overflow: auto;">
                <p>以下时间段设置有问题：</p>
                <p v-for="(item,index) in periodOfTime" :key="index">
                    {{item.startHours}}-{{item.endHours}}不能被单位时长{{item.unitTime}}整除；</p>
                <p>确定要添加吗？</p>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="promptDialog = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import {
        disableEdit,
        parkingAreaRoadList, tempCarDel,
        tempCarEdit,
        tempCarList,
        tempCarSave,
        tempCost
    } from "../../../../api/yardSetting";

    export default {
        name: "membershipCarRate",
        data() {
            return {
                loading: false,
                total: 0,
                tableData: [],
                filters: {
                    carProperty: '',
                    current: 1,
                    size: 10,
                },
                title: "",
                addDialog: false,
                selectDialog: false,
                statusDialog: false,
                showDialog: false,
                formLabelWidth: '140px',
                editForm: {
                    id: "",
                    parkingId: "",// 停车场id
                    parkingAreaId: "",//区域id
                    rateName: "",//费率名称
                    carProperty: "",//车辆属性
                    timeScope: "",//时间段范围 1,全天，2，多时段
                    timeChargeModel: "",//多时段计费方式 1，本时段计费，2，多时段相加
                    freeTime: "",// 免费时长 分钟
                    dayLimit: "",//24小时限额
                    status: "",
                    entranceTime: "",
                    exitTime: "",
                    domains: [],
                    array: [],
                    value: "",
                    carType: "",
                },
                showForm: {
                    id: "",
                    parkingId: "",// 停车场id
                    parkingAreaId: "",//区域id
                    rateName: "",//费率名称
                    carProperty: "",//车辆属性
                    timeScope: "",//时间段范围 1,全天，2，多时段
                    timeChargeModel: "",//多时段计费方式 1，本时段计费，2，多时段相加
                    freeTime: "",// 免费时长 分钟
                    dayLimit: "",//24小时限额
                    status: "",
                    entranceTime: "",
                    exitTime: "",
                    domains: [],
                    value: ""
                },
                dynamicValidateForm: {},
                rules: {
                    rateName: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ],
                    carProperty: [
                        {required: true, message: '请选择', trigger: 'change'}
                    ],
                    timeScope: [
                        {required: true, message: '请选择', trigger: 'change'},
                    ],
                    freeTime: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ],
                    dayLimit: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ],
                    status: [
                        {required: true, message: '请选择', trigger: 'change'},
                    ],
                    parkingAreaId: [
                        {required: true, message: '请选择', trigger: 'change'},
                    ],
                },
                changeForm: {
                    status: "",
                },
                changeRules: {
                    status: [
                        {required: true, message: '请选择', trigger: 'change'},
                    ],
                },
                cost: "",//费用
                dataTime: "",
                showtime: false,
                show: true,
                hideTime: true,
                hidingTimes: false,
                areasData: [],
                id: "",
                promptDialog: false,
                periodOfTime: [],
                filterGroup: [],
            }
        },
        mounted() {
            this.id = localStorage.getItem("subSysId");
            this.fetchData();
            this.getDataList();
        },
        methods: {
            fetchData() { //获取数据
                let that = this;
                that.loading = true;
                let carType = 3;
                let param = {
                    carProperty: this.filters.carProperty,
                    current: this.filters.current,
                    size: this.filters.size,
                    parkingId: this.id,
                    carType: carType
                }
                tempCarList(param).then((res) => {
                    that.loading = false;
                    that.tableData = res.data.data.records;
                    that.total = res.data.data.total;
                    that.filters.current = res.data.data.current;
                    that.filters.size = res.data.data.size;
                }).catch((error) => {
                    that.loading = false;
                });
            },
            getDataList() { //获取数据
                parkingAreaRoadList(this.id).then((res) => {
                    this.areasData = res.data.data.parkingAreas;
                })
            },
            getUpdateTime(row) {
                if (row.updateTime != null) {
                    return row.updateTime[0] + '-' + row.updateTime[1] + '-' + row.updateTime[2]
                }
            },
            handleSizeChange(val) {
                this.filters.size = val;
                this.filters.current = 1;
                this.fetchData();
            },
            handleCurrentChange(val) {
                this.filters.current = val;
                this.fetchData();
            },
            handleAdd() {
                this.title = '添加';
                this.addDialog = true;
                Object.keys(this.editForm).map(key => {
                    this.editForm[key] = '';
                });
                this.dataTime = "";
                this.cost = "";
                this.editForm.domains = [];
                this.editForm.value = 1;
                this.editForm.carType = 3;
                this.editForm.status = 1;
                this.showtime = false;
                this.show = true;
            },
            confirm(editForm) {
                this.$refs[editForm].validate((valid) => {
                    if (valid) {
                        let domains;
                        if (this.editForm.value == 1) {
                            this.editForm.domains = [];
                            this.editForm.domains.push({
                                startHours: "00:00:00",
                                endHours: "23:59:59",
                                firstTime: this.editForm.firstTime,
                                firstAmount: this.editForm.firstAmount,
                                unitTime: this.editForm.unitTime,
                                unitAmount: this.editForm.unitAmount,
                            });
                        } else if (this.editForm.value == 3) {
                            this.editForm.domains = [];
                            this.editForm.domains.push({
                                startHours: "00:00:00",
                                endHours: "23:59:59",
                                firstTime: "",
                                firstAmount: "",
                                unitTime: 24 * 60,
                                unitAmount: this.editForm.unitAmount,
                            });
                        }
                        this.filterGroup = [];
                        if (this.editForm.value == 2) {
                            this.editForm.array.forEach(item => {
                                item.firstTime = this.editForm.firstTime;
                                item.firstAmount = this.editForm.firstAmount;
                            })
                            this.filterGroup = this.editForm.array;
                        } else {
                            this.filterGroup = this.editForm.domains;
                        }
                        const totalMillis = [];
                        this.periodOfTime = [];
                        for (let i = 0; i < this.filterGroup.length; i++) {
                            const startMillis = this.timeToSec(this.filterGroup[i].startHours);
                            const endMillis = this.timeToSec(this.filterGroup[i].endHours);
                            const totel = endMillis + startMillis;
                            totalMillis.push(totel % this.filterGroup[i].unitTime);
                            if (totel % this.filterGroup[i].unitTime != 0) {
                                this.periodOfTime.push(this.filterGroup[i])
                            }
                        }
                        var aa = totalMillis.filter(element => element !== 0);
                        if (aa.length > 0) {
                            this.promptDialog = true;
                        } else {
                            this.submit();
                        }
                    }
                })
            },
            timeToSec(time) {
                var hour = time.split(':')[0];
                var min = time.split(':')[1];
                var sec = time.split(':')[2];
                var m = Number(hour * 60) + Number(min) + Math.floor(Math.round(sec / 60));
                return m
            },
            submit() {
                let param = {
                    parkingId: this.id,
                    parkingAreaId: this.editForm.parkingAreaId,
                    carProperty: this.editForm.carProperty,
                    dayLimit: this.editForm.dayLimit,
                    freeTime: this.editForm.freeTime,
                    rateName: this.editForm.rateName,
                    timeChargeModel: this.editForm.timeChargeModel,
                    timeScope: this.editForm.timeScope,
                    status: this.editForm.status,
                    tempCarRateScopes: this.filterGroup,
                    chargeMode: this.editForm.value,
                    carType: this.editForm.carType
                }
                let data = {
                    id: this.editForm.id,
                    status: this.editForm.status,
                }
                tempCarSave(param).then((res) => {
                    this.addDialog = false;
                    this.promptDialog = false;
                    this.fetchData();
                    if (res.data.code == "SUCCESS") {
                        this.$message.success(res.data.message);
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
                disableEdit(data).then((res) => {
                    console.log(res.data.message)
                })
            },
            confirmCost1() {
                this.dateFormat(this.editForm.entranceTime, this.editForm.exitTime);
                let carType = 3;
                let domains;
                if (this.editForm.value == 1) {
                    this.editForm.domains = [];
                    this.editForm.domains.push({
                        startHours: "00:00:00",
                        endHours: "23:59:59",
                        firstTime: this.editForm.firstTime,
                        firstAmount: this.editForm.firstAmount,
                        unitTime: this.editForm.unitTime,
                        unitAmount: this.editForm.unitAmount,
                    });
                } else if (this.editForm.value == 3) {
                    this.editForm.domains = [];
                    this.editForm.domains.push({
                        startHours: "00:00:00",
                        endHours: "23:59:59",
                        firstTime: "",
                        firstAmount: "",
                        unitTime: 24 * 60,
                        unitAmount: this.editForm.unitAmount,
                    });
                }
                if (this.editForm.value == 2) {
                    this.editForm.array.forEach(item => {
                        item.firstTime = this.editForm.firstTime;
                        item.firstAmount = this.editForm.firstAmount;
                    })
                    domains = this.editForm.array
                } else {
                    domains = this.editForm.domains
                }
                let param = {
                    parkingId: this.id,
                    parkingAreaId: this.editForm.parkingAreaId,
                    carProperty: this.editForm.carProperty,
                    dayLimit: this.editForm.dayLimit,
                    freeTime: this.editForm.freeTime,
                    rateName: this.editForm.rateName,
                    timeChargeModel: this.editForm.timeChargeModel,
                    timeScope: this.editForm.timeScope,
                    firstTime: this.editForm.firstTime,//首付时长
                    firstAmount: this.editForm.firstAmount,//首付金额
                    unitTime: this.editForm.unitTime,//单位时长
                    unitAmount: this.editForm.unitAmount,//单位金额
                    chargeMode: this.editForm.value,
                    status: this.editForm.status,
                    tempCarRateScopes: this.editForm.domains,
                    carType: carType,
                    entranceTime: this.dayjs(this.editForm.entranceTime).format('YYYY-MM-DD HH:mm:ss'),
                    exitTime: this.dayjs(this.editForm.exitTime).format('YYYY-MM-DD HH:mm:ss')
                }
                tempCost(param).then((res) => {
                    if (res.data.code == "SUCCESS") {
                        this.cost = res.data.data
                    } else {
                        this.$message.info(res.data.message);
                    }
                })
            },
            confirmCost2() {
                this.dateFormat(this.showForm.entranceTime, this.showForm.exitTime);
                let carType = 3;
                let param = {
                    id: this.showForm.id,
                    parkingId: this.id,
                    parkingAreaId: this.showForm.parkingAreaId,
                    carProperty: this.showForm.carProperty,
                    dayLimit: this.showForm.dayLimit,
                    freeTime: this.showForm.freeTime,
                    rateName: this.showForm.rateName,
                    timeChargeModel: this.showForm.timeChargeModel,
                    timeScope: this.showForm.timeScope,
                    firstTime: this.showForm.firstTime,//首付时长
                    firstAmount: this.showForm.firstAmount,//首付金额
                    unitTime: this.showForm.unitTime,//单位时长
                    unitAmount: this.showForm.unitAmount,//单位金额
                    status: this.showForm.status,
                    chargeMode: this.showForm.value,
                    tempCarRateScopes: this.showForm.domains,
                    carType: carType,
                    entranceTime: this.dayjs(this.showForm.entranceTime).format('YYYY-MM-DD HH:mm:ss'),
                    exitTime: this.dayjs(this.showForm.exitTime).format('YYYY-MM-DD HH:mm:ss')
                }
                tempCost(param).then((res) => {
                    if (res.data.code == "SUCCESS") {
                        this.cost = res.data.data
                    } else {
                        this.$message.info(res.data.message);
                    }
                })
            },
            dateFormat(entranceTime, exitTime) {
                var dateDiff = exitTime.getTime() - entranceTime.getTime();//时间差的毫秒数
                var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000));//计算出相差天数
                var leave1 = dateDiff % (24 * 3600 * 1000)    //计算天数后剩余的毫秒数
                var hours = Math.floor(leave1 / (3600 * 1000))//计算出小时数
                //计算相差分钟数
                var leave2 = leave1 % (3600 * 1000)    //计算小时数后剩余的毫秒数
                var minutes = Math.floor(leave2 / (60 * 1000))//计算相差分钟数
                //计算相差秒数
                var leave3 = leave2 % (60 * 1000)      //计算分钟数后剩余的毫秒数
                var seconds = Math.round(leave3 / 1000)
                this.dataTime = dayDiff + "天" + hours + "小时" + minutes + "分钟" + seconds + "秒";
                return "相差 " + dayDiff + "天 " + hours + "小时 " + minutes + " 分钟";
            },
            //停用状态
            handleStatus(index, row) {
                // this.statusDialog = true;
                if (row.status == 1) {
                    this.changeForm.status = 2;
                } else {
                    this.changeForm.status = 1;
                }
                let data = {
                    id: row.id,
                    status: this.changeForm.status,
                }
                this.$confirm("确定要更改吗?", "温馨提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    disableEdit(data).then((res) => {
                        if (res.data.code == "SUCCESS") {
                            this.fetchData();
                            this.$message.success("更改成功!");
                        } else {
                            this.$message.info(res.data.message);
                        }
                    })
                }).catch(() => {
                    this.$message.info("已取消更改！");
                });
            },
            SubmitForm(editForm) {
                this.$refs[editForm].validate((valid) => {
                    if (valid) {
                        if (this.changeForm.status == "启用") {
                            this.changeForm.status = 1;
                        } else {
                            this.changeForm.status = 2;
                        }
                        let data = {
                            id: this.editForm.id,
                            status: this.changeForm.status,
                        }
                        disableEdit(data).then((res) => {
                            this.statusDialog = false;
                            this.fetchData();
                            if (res.data.code == "SUCCESS") {
                                this.$message.success("更改成功!");
                            } else {
                                this.$message.info(res.data.message);
                            }
                        })
                    }
                })
            },
            handleEdit(index, row) {
                this.title = '查看';
                this.showDialog = true;
                Object.keys(this.showForm).map(key => {
                    this.showForm[key] = row[key];
                });
                this.date = [];
                this.dataTime = "";
                this.cost = "";
                this.showForm.id = row.id;
                this.showForm.domains = row.tempCarRateScopes;
                this.showForm.value = row.chargeMode;
            },
            onSubmit(editForm) {
                this.$refs[editForm].validate((valid) => {
                    if (valid) {
                        tempCarEdit(this.editForm.id).then((res) => {
                            this.selectDialog = false;
                            this.fetchData();
                            if (res.data.code == "SUCCESS") {
                                this.$message.success("编辑成功!");
                            } else {
                                this.$message.info(res.data.message);
                            }
                        })
                    }
                })
            },
            handleDelete(index, row) {
                this.$confirm("确定要删除吗?", "温馨提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    tempCarDel(row.id).then((res) => {
                        if (res.data.code == "SUCCESS") {
                            this.fetchData();
                            this.$message.success("删除成功!");
                        } else {
                            this.$message.info(res.data.message);
                        }
                    })
                }).catch(() => {
                    this.$message.success("已取消删除!");
                });
            },
            selectTime(e) {
                if (e != 1) {
                    this.showtime = true;
                    this.show = false;
                    this.editForm.timeChargeModel = '2'
                } else {
                    this.showtime = false;
                    this.show = true;
                }
            },
            selectBilling(e) {
                if (e == 1 || e == 3) {
                    this.editForm.domains = [];
                    this.editForm.array = [];
                } else if (e == 2) {
                    this.editForm.dayLimit = "";
                    this.editForm.domains = [];
                    this.editForm.array = [];
                    this.editForm.array.push({
                        startHours: "00:00:00",
                        endHours: "23:59:59",
                        firstTime: "",
                        firstAmount: "",
                        unitTime: "",
                        unitAmount: "",
                        timingStart: "",
                        timingEnd: "",
                    });
                } else if (e == 4) {
                    this.editForm.domains = [];
                    this.editForm.domains.push({
                        startHours: "00:00:00",
                        endHours: "23:59:59",
                        firstTime: "",
                        firstAmount: "",
                        unitTime: "",
                        unitAmount: "",
                    });
                } else if (e == 5) {
                    this.editForm.domains = [];
                    this.editForm.domains.push({
                        startHours: "00:00:00",
                        endHours: "23:59:59",
                        firstTime: "",
                        firstAmount: "",
                        unitTime: 24 * 60,
                        unitAmount: "",
                    });
                }
            },
            addDomain1() {
                this.editForm.domains.push({
                    startHours: this.editForm.domains[this.editForm.domains.length - 1].endHours,
                    endHours: this.editForm.domains[this.editForm.domains.length - 1].startHours,
                    firstTime: "",
                    firstAmount: "",
                    unitTime: "",
                    unitAmount: "",
                    key: Date.now()
                });
            },
            removeDomain1(item) {
                var index = this.editForm.domains.indexOf(item)
                if (index !== -1) {
                    this.editForm.domains.splice(index, 1)
                }
            },
            pickerStartHours(i, value) {
                if (i == 0) {
                    if (this.editForm.domains[i + 1]) {
                        this.editForm.domains[i + 1].endHours = value;
                    }
                } else if (this.editForm.domains.length > i) {
                    this.editForm.domains[i - 1].endHours = value;
                }
            },
            pickerEndHours(i, value) {
                if (i == 0) {
                    if (this.editForm.domains[i + 1]) {
                        this.editForm.domains[i + 1].startHours = value;
                    }
                } else if (this.editForm.domains.length - 1 === i) {
                    this.editForm.domains[0].startHours = value;
                } else if (this.editForm.domains.length > i) {
                    this.editForm.domains[i - 1].startHours = value;
                }
            },
            addDomain2() {
                this.editForm.array.push({
                    startHours: "00:00:00",
                    endHours: "23:59:59",
                    firstTime: "",
                    firstAmount: "",
                    unitTime: "",
                    unitAmount: "",
                    timingStart: "",
                    timingEnd: "",
                    key: Date.now()
                });
            },
            removeDomain2(item) {
                var index = this.editForm.array.indexOf(item)
                if (index !== -1) {
                    this.editForm.array.splice(index, 1)
                }
            },
            addDomain3() {
                this.editForm.domains.push({
                    startHours: this.editForm.domains[this.editForm.domains.length - 1].endHours,
                    endHours: this.editForm.domains[this.editForm.domains.length - 1].startHours,
                    firstTime: "",
                    firstAmount: "",
                    unitTime: 24 * 60,
                    unitAmount: "",
                    key: Date.now()
                });
            },
        }
    }
</script>

<style scoped>
    /deep/ .el-select {
        width: 100%;
    }

    .domains {
        /*display: flex;*/
        flex-wrap: wrap;
        height: 150px;
        overflow: auto;
    }

    .dynamic {
        /*display: flex;*/
        flex-wrap: wrap;
        height: 140px;
        overflow: auto;
    }

    .abow_dialog {
        display: flex;
        justify-content: center;
        align-items: Center;
        overflow: hidden;
    }

    .abow_dialog .el-dialog {
        margin: 0 auto !important;
        height: 90%;
        overflow: hidden;
    }

    .abow_dialog .el-dialog .el-dialog__body {
        position: absolute;
        left: 0;
        top: 54px;
        bottom: 0;
        right: 0;
        padding: 0;
        z-index: 1;
        overflow: hidden;
        overflow-y: auto;
    }

</style>
