<template>
    <div>
        <el-row :gutter="20">
            <el-col :span="8">
                <el-card shadow="hover" :body-style="{ padding: '0px' }">
                    <div class="grid-content grid-con-1">
                        <img class="grid-con-icon" src="../../assets/images/测速总速11.png"/>
                        <div class="grid-cont-right">
                            <div class="grid-num">{{todaySpeedTotal}}</div>
                            <div>测速总数</div>
                        </div>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="8">
                <el-card shadow="hover" :body-style="{ padding: '0px' }">
                    <div class="grid-content grid-con-4">
                        <img class="grid-con-icon" src="../../assets/images/超速.png"/>
                        <div class="grid-cont-right">
                            <div class="grid-num">{{todayFastSpeed}}</div>
                            <div>车辆超速</div>
                        </div>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="8">
                <el-card shadow="hover" :body-style="{ padding: '0px' }">
                    <div class="grid-content grid-con-2">
                        <img class="grid-con-icon" src="../../assets/images/on-line.png"/>
                        <div class="grid-cont-right">
                            <div class="grid-num">{{deviceTotal}}</div>
                            <div>设备总数</div>
                        </div>
                    </div>
                </el-card>
            </el-col>
        </el-row>
        <el-row :gutter="20" class="mgb20">
            <el-col :span="8">
                <el-card shadow="hover" :body-style="{ padding: '0px' }">
                    <div class="grid-content grid-con-1">
                        <img class="grid-con-icon" src="../../assets/images/正常22.png"/>
                        <div class="grid-cont-right">
                            <div class="grid-num">{{todayNormal}}</div>
                            <div>车速正常</div>
                        </div>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="8">
                <el-card shadow="hover" :body-style="{ padding: '0px' }">
                    <div class="grid-content grid-con-2">
                        <img class="grid-con-icon" src="../../assets/images/慢速1.png"/>
                        <div class="grid-cont-right">
                            <div class="grid-num">{{todayLowSpeed}}</div>
                            <div>车辆慢速</div>
                        </div>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="8">
                <el-card shadow="hover" :body-style="{ padding: '0px' }">
                    <div class="grid-content grid-con-3">
                        <img class="grid-con-icon" src="../../assets/images/totalEquipment.png"/>
                        <div class="grid-cont-right">
                            <div class="grid-num">{{deviceOnline}}</div>
                            <div>设备在线数量</div>
                        </div>
                    </div>
                </el-card>
            </el-col>
        </el-row>
        <el-card shadow="hover" :body-style="{ padding: '10px' }">
            <template #header>
                <div class="clearfix">
                    <span>测速记录</span>
                </div>
            </template>
            <el-table
                    :data="listData"
                    stripe tyle="width: 100%"
                    height="400" max-height="400"
                    :row-style="{ height: '45px' }"
                    :cell-style="{ padding: '0px' }"
                    :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
                <el-table-column type="index" label="序号"></el-table-column>
                <el-table-column prop="deviceName" label="车牌号" align="center">
                    <template slot-scope="scope">
                        {{scope.row.licensePlate}}
                    </template>
                </el-table-column>
                <el-table-column prop="speedPositionName" label="地点" align="center"></el-table-column>
                <el-table-column prop="speedTime" label="时间" align="center"></el-table-column>
                <el-table-column prop="plateColor" label="车牌颜色" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.plateColor == 0">未知</span>
                        <span v-if="scope.row.plateColor == 1">蓝色</span>
                        <span v-else-if="scope.row.plateColor == 2">黄色</span>
                        <span v-else-if="scope.row.plateColor == 3">白色</span>
                        <span v-else-if="scope.row.plateColor == 4">黑色</span>
                        <span v-else-if="scope.row.plateColor == 5">绿色</span>
                        <span v-else-if="scope.row.plateColor == 6">黄绿色</span>
                        <span v-else-if="scope.row.plateColor == 7">其他</span>
                    </template>
                </el-table-column>
                <el-table-column prop="plateType" label="车牌类型" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.plateType == 0">未知</span>
                        <span v-if="scope.row.plateType == 1">普通蓝牌</span>
                        <span v-else-if="scope.row.plateType == 2">普通黑牌</span>
                        <span v-else-if="scope.row.plateType == 3">普通黄牌</span>
                        <span v-else-if="scope.row.plateType == 4">双层黄牌</span>
                        <span v-else-if="scope.row.plateType == 5">警察车牌</span>
                        <span v-else-if="scope.row.plateType == 6">双层武警</span>
                        <span v-else-if="scope.row.plateType == 7">单层军牌</span>
                        <span v-else-if="scope.row.plateType == 8">双层军牌</span>
                        <span v-else-if="scope.row.plateType == 9">个性车牌</span>
                        <span v-else-if="scope.row.plateType == 10">新能源小车牌</span>
                        <span v-else-if="scope.row.plateType == 11">新能源大车牌</span>
                        <span v-else-if="scope.row.plateType == 12">大使馆车牌</span>
                        <span v-else-if="scope.row.plateType == 13">领事馆车牌</span>
                        <span v-else-if="scope.row.plateType == 14">民航车牌 </span>
                        <span v-else-if="scope.row.plateType == 15">其他</span>
                    </template>
                </el-table-column>
                <!--                <el-table-column prop="carType" label="车型" align="center">-->
                <!--                    <template slot-scope="scope">-->
                <!--                        <span v-if="scope.row.carType == 0">无效</span>-->
                <!--                        <span v-if="scope.row.carType == 1">大巴</span>-->
                <!--                        <span v-else-if="scope.row.carType == 2">小汽车</span>-->
                <!--                        <span v-else-if="scope.row.carType == 3">中巴</span>-->
                <!--                        <span v-else-if="scope.row.carType == 4">小巴</span>-->
                <!--                        <span v-else-if="scope.row.carType == 5">卡车</span>-->
                <!--                    </template>-->
                <!--                </el-table-column>-->
                <el-table-column prop="speedValue" label="速度(KM/H)" align="center"></el-table-column>
                <el-table-column prop="status" label="结果" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.status == 1">正常</span>
                        <span v-else-if="scope.row.status == 2">超速</span>
                        <span v-else-if="scope.row.status == 3">慢速</span>
                    </template>
                </el-table-column>
                <el-table-column prop="img" label="抓拍图片" align="center">
                    <template slot-scope="scope">
                        <el-image
                                style="width: 50px; height: 50px"
                                :src="scope.row.img?scope.row.img:require('../../assets/images/defaultImg.png')"
                                :preview-src-list="[scope.row.img?scope.row.img:require('../../assets/images/defaultImg.png')]">
                            <div slot="error">
                                <el-image :src="require('../../assets/images/defaultImg.png')">
                                </el-image>
                            </div>
                        </el-image>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="current"
                    :page-sizes="[10, 50, 200, 1000]"
                    :page-size="size"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
            </el-pagination>
        </el-card>
    </div>
</template>

<script>
    import {formatDate} from "../../utils";
    import {getSpeedRecord, speedStatistics} from "../../api/speed";

    export default {
        name: "speedMainHome",

        data() {
            return {
                listData: [],
                current: 1,
                size: 10,
                total: 0,
                deviceOffline: 0,
                deviceOnline: 0,
                deviceTotal: 0,
                todayFastSpeed: 0,
                todayLowSpeed: 0,
                todayNormal: 0,
                todaySpeedTotal: 0,
            }
        },
        mounted() {
            this.fetchData();
            this.getSpeedStatistics();
        },
        methods: {
            getSpeedStatistics() {
                speedStatistics().then(res => {
                    this.deviceOffline = res.data.data.deviceOffline;
                    this.deviceOnline = res.data.data.deviceOnline;
                    this.deviceTotal = res.data.data.deviceTotal;
                    this.todayFastSpeed = res.data.data.todayFastSpeed;
                    this.todayLowSpeed = res.data.data.todayLowSpeed;
                    this.todayNormal = res.data.data.todayNormal;
                    this.todaySpeedTotal = res.data.data.todaySpeedTotal;
                })
            },
            fetchData() {
                let params = {
                    current: this.current,
                    size: this.size
                };
                getSpeedRecord(params).then(res => {
                    if (res.data.code == "SUCCESS") {
                        this.listData = res.data.data.records;
                        this.total = res.data.data.total;
                        this.current = res.data.data.current;
                        this.size = res.data.data.size;
                    }
                })
            },
            handleSizeChange(val) {
                this.size = val;
                this.current = 1;
                this.fetchData();
            },
            handleCurrentChange(val) {
                this.current = val;
                this.fetchData();
            },
        }
    }
</script>

<style scoped>
    .grid-content {
        display: flex;
        align-items: center;
        height: 100px;
    }

    .grid-cont-right {
        flex: 1;
        text-align: center;
        font-size: 14px;
        line-height: 30px;
        color: #999;
    }

    .grid-num {
        font-size: 30px;
        font-weight: bold;
    }

    .grid-con-icon {
        font-size: 50px;
        width: 100px;
        height: 100px;
        text-align: center;
        line-height: 100px;
        color: #fff;
    }

    .grid-con-1 .grid-con-icon {
        background: rgb(45, 140, 240);
    }


    .grid-con-2 .grid-con-icon {
        background: rgb(103, 194, 58)
    }


    .grid-con-3 .grid-con-icon {
        background: #b04b85;
    }

    .grid-con-1 .grid-num {
        color: rgb(45, 140, 240);
    }

    .grid-con-2 .grid-num {
        color: rgb(103, 194, 58);
    }

    .grid-con-3 .grid-num {
        color: #b04b85;
    }

    .grid-con-4 .grid-num {
        color: rgb(240, 133, 25);
    }

    .mgb20 {
        margin: 10px 0;
    }
</style>
