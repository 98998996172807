<template>
    <div>
        <el-button size="mini" icon="el-icon-plus" type="primary" @click="addCrossing">添加道口</el-button>
        <el-table
                size="small"
                :data="treeData"
                highlight-current-row
                border style="width: 100%"
                :row-style="{height:'45px'}"
                :cell-style="{padding:'0px'}"
                :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                stripe height="500" max-height="500">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column prop="name" label="道口名称" align="center"></el-table-column>
            <el-table-column prop="type" label="道口类型" align="center">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.type == 1">入口</el-tag>
                    <el-tag type="info" v-else-if="scope.row.type == 2">出口</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="level" label="主次级别" align="center">
                <template slot-scope="scope">
                    <el-tag type="success" v-if="scope.row.level == 1">次道口</el-tag>
                    <el-tag v-else-if="scope.row.level == 2">主道口</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="type" label="临时车放行" align="center">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.tempPass == 1">自动</el-tag>
                    <el-tag type="info" v-else-if="scope.row.tempPass == 2">手动</el-tag>
                </template>
            </el-table-column>
            <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                    <el-button size="mini" @click="handleEdit(scope.$index, scope.row)" icon="el-icon-edit"
                               type="primary">编辑
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <!--   道口管理-->
        <el-dialog v-draggable :title="title" :visible.sync="addDialog" width="35%">
            <el-form :model="editForm" ref="editForm" size="small" label-width="180px" :rules="rules">
                <el-form-item label="道口名称" prop="name">
                    <el-input style="width: 203px;" size="small" v-model="editForm.name" placeholder="请输入内容"/>
                </el-form-item>
                <el-form-item label="主次级别" prop="roadLevelEnum">
                    <el-select :disabled="disabled" size="small" v-model="editForm.roadLevelEnum" placeholder="请选择级别">
                        <el-option label="次道口" :value="1"></el-option>
                        <el-option label="主道口" :value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="道口类型" prop="roadTypeEnum">
                    <el-select :disabled="disabled" size="small" v-model="editForm.roadTypeEnum" placeholder="请选择类型">
                        <el-option label="入口" :value="1"></el-option>
                        <el-option v-show="editForm.roadLevelEnum == 2" label="出口" :value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-if="editForm.roadLevelEnum == 1" label="紧邻区域" prop="adjacentAreaId">
                    <el-select :disabled="disabled" size="small" v-model="editForm.adjacentAreaId" placeholder="请选择区域">
                        <el-option v-for="item in adjacentAreas" :label="item.name"
                                   :value="item.id" :key="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="临时车放行" prop="tempPass">
                    <el-select size="small" v-model="editForm.tempPass" placeholder="请选择">
                        <el-option label="自动" :value="1"></el-option>
                        <el-option label="手动" :value="2"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button @click="addDialog = false">取 消</el-button>
        <el-button type="primary" @click="confirm('editForm')">确 定</el-button>
      </span>
        </el-dialog>
    </div>
</template>

<script>
    import {roadSave, roadUpdate} from "../../../../../api/yardSetting";

    export default {
        name: "second",
        props: {
            treeData: Array,
            parkingAreas: [Array, Object],
            areaId: [Number, String],
        },
        data() {
            return {
                tableData: [],
                title: "",
                addDialog: false,
                disabled: false,
                formLabelWidth: '140px',
                editForm: {
                    id: "",
                    name: "",
                    roadLevelEnum: "",
                    roadTypeEnum: "",
                    adjacentAreaId: "",
                    tempPass: ""
                },
                areasData: [],
                rules: {
                    name: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ],
                    roadLevelEnum: [
                        {required: true, message: '请选择', trigger: 'change'}
                    ],
                    roadTypeEnum: [
                        {required: true, message: '请选择', trigger: 'change'}
                    ],
                    adjacentAreaId: [
                        {required: true, message: '请选择', trigger: 'change'}
                    ]
                },
                areasId: '',
                id: localStorage.getItem('subSysId'),
                adjacentAreas: []
            }
        },
        methods: {
            addCrossing() {
                this.title = '添加道口';
                this.addDialog = true;
                this.disabled = false;
                Object.keys(this.editForm).map(key => {
                    this.editForm[key] = "";
                });
                this.editForm.tempPass = 1;
                this.adjacentAreas = [];
                this.parkingAreas.forEach(item => {
                    if (item.id !== this.areaId) {
                        this.adjacentAreas.push(item)
                    }
                })
            },
            handleEdit(index, row) {
                this.title = '编辑道口';
                this.addDialog = true;
                this.disabled = true;
                Object.keys(this.editForm).map(key => {
                    this.editForm[key] = row[key];
                });
                this.editForm.roadLevelEnum = row.level;
                this.editForm.roadTypeEnum = row.type;
                this.adjacentAreas = [];
                this.parkingAreas.forEach(item => {
                    if (item.id !== this.areaId) {
                        this.adjacentAreas.push(item)
                    }
                })
            },
            confirm(editForm) {
                this.$refs[editForm].validate((valid) => {
                    if (valid) {
                        if (this.editForm.roadLevelEnum == 2) {
                            this.editForm.adjacentAreaId = "";
                        }
                        let param = {
                            id: this.editForm.id,
                            areaId: this.areaId + "",
                            parkingId: this.id,
                            name: this.editForm.name,
                            level: this.editForm.roadLevelEnum + "",
                            type: this.editForm.roadTypeEnum + "",
                            adjacentAreaId: this.editForm.adjacentAreaId,
                            tempPass: this.editForm.tempPass
                        }
                        if (this.editForm.id) {
                            roadUpdate(param).then((res) => {
                                this.addDialog = false;
                                this.$emit('handleTreeData', 'second', this.areaId);
                                if (res.data.code == "SUCCESS") {
                                    this.$message.success("修改成功！");
                                } else {
                                    this.$message.info(res.data.message);
                                }
                            })
                        } else {
                            roadSave(param).then((res) => {
                                this.addDialog = false;
                                this.$emit('handleTreeData', 'second', this.areaId);
                                if (res.data.code == "SUCCESS") {
                                    this.$message.success("保存成功！");
                                } else {
                                    this.$message.info(res.data.message);
                                }
                            })
                        }
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .el-table {
        margin-top: 10px;
    }
</style>
