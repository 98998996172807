<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>人行管理</el-breadcrumb-item>
            <el-breadcrumb-item>测温记录</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 搜索筛选 -->
        <el-form :inline="true" :model="filters" class="user-search">
            <el-form-item>
                <el-date-picker size="small" v-model="date" type="datetimerange" range-separator="至"
                                start-placeholder="开始日期" end-placeholder="结束日期"
                                value-format="yyyy-MM-dd HH:mm:ss">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-search" type="primary" size="small" @click="handleSearch">查询</el-button>
            </el-form-item>
        </el-form>
        <el-table
                v-loading="loading"
                element-loading-text="拼命加载中"
                size="small"
                :data="tableData"
                highlight-current-row
                border stripe
                style="width: 100%;"
                :row-style="{height:'45px'}"
                :cell-style="{padding:'0px'}"
                :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                height="500" max-height="500">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column prop="temperature" label="体温(℃)" align="center">
                <template slot-scope="scope">
                    {{scope.row.temperature == 0 ? '-' : scope.row.temperature}}
                </template>
            </el-table-column>
            <el-table-column prop="" label="体温状态" align="center">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.temperature > 37.2 ? 'danger' : 'success'">
                        {{scope.row.temperature > 37.2 ? '异常' : '正常'}}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="deviceName" label="设备名称" align="center"></el-table-column>
            <!--            <el-table-column prop="hasMask" label="是否戴口罩" align="center">-->
            <!--                <template slot-scope="scope">-->
            <!--                    <el-tag v-show="scope.row.hasMask != null" :type="scope.row.hasMask == 0 ? 'info' : ''">-->
            <!--                        {{scope.row.hasMask == 0 ? '否' : '是'}}-->
            <!--                    </el-tag>-->
            <!--                </template>-->
            <!--            </el-table-column>-->
            <el-table-column prop="capTime" label="测量时间" align="center"></el-table-column>
            <el-table-column prop="passageImg" label="抓拍头像" align="center">
                <template slot-scope="scope">
                    <el-image style="width: 50px; height: 50px"
                              :src="scope.row.capImg?scope.row.capImg:require('../../../assets/images/defaultImg.png')"
                              :preview-src-list="[scope.row.capImg?scope.row.capImg:require('../../../assets/images/defaultImg.png')]">
                        <div slot="error">
                            <el-image :src="require('../../../assets/images/defaultImg.png')">
                            </el-image>
                        </div>
                    </el-image>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="filters.current"
                       :page-sizes="[10, 50, 200, 1000]"
                       :page-size="filters.size"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
    </div>
</template>

<script>
    import {temperatureRecord} from "../../../api/home";

    export default {
        name: "accessRecord",
        data() {
            return {
                loading: false,
                total: 0,
                tableData: [],
                filters: {
                    startTime: '',
                    endTime: '',
                    current: 1,
                    size: 10,
                },
                date: [],
            }
        },
        mounted() {
            this.fetchData();
        },
        methods: {
            fetchData() {
                this.filters.startTime = this.date && this.date.length ? this.date[0] : "";
                this.filters.endTime = this.date && this.date.length ? this.date[1] : "";
                this.loading = true;
                temperatureRecord(this.filters).then(res => {
                    this.loading = false;
                    this.tableData = res.data.data.records;
                    this.total = res.data.data.total;
                    this.filters.current = res.data.data.current;
                    this.filters.size = res.data.data.size;
                })
            },
            handleSearch() {
                this.filters.current = 1;
                this.fetchData();
            },
            handleSizeChange(val) {
                this.filters.size = val;
                this.filters.current = 1;
                this.fetchData();
            },
            handleCurrentChange(val) {
                this.filters.current = val;
                this.fetchData();
            },
        }
    }
</script>

<style scoped>
    .user-search {
        margin-top: 15px;
    }
</style>
