<template>
    <div>
        <el-button size="mini" icon="el-icon-plus" type="primary" @click="addArea">添加区域</el-button>
        <el-table
                size="small"
                :data="treeData"
                highlight-current-row
                border stripe
                style="width: 100%;margin-top: 10px;"
                :row-style="{height:'45px'}"
                :cell-style="{padding:'0px'}"
                :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                height="500" max-height="470">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column prop="name" label="区域名称" align="center"></el-table-column>
            <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                    <el-button size="mini" @click="handleEdit(scope.$index, scope.row)" icon="el-icon-setting"
                               type="primary">编辑
                    </el-button>
                    <el-button size="mini" type="danger" class="el-icon-delete"
                               @click="handleDelete(scope.$index, scope.row)">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <!--    区域管理-->
        <el-dialog v-draggable  :title="title" :visible.sync="regionalDialog" width="30%">
            <el-form :model="visibleForm" ref="visibleForm" size="small" :rules="rules" label-width="130px">
                <el-form-item label="区域名称" prop="areaName">
                    <el-input style="width: 80%" size="small" v-model="visibleForm.areaName" placeholder="请输入内容"/>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="regionalDialog = false">取 消</el-button>
                <el-button type="primary" @click="onSubmit('visibleForm')">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import {AddArea, delArea, EditArea} from "@/api/spaceApi";

    export default {
        name: "first",
        props: ["treeData"],
        data() {
            return {
                title: "",
                regionalDialog: false,
                visibleForm: {
                    areaName: ""
                },
                rules: {
                    areaName: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ],
                },
            }
        },
        methods: {
            //添加区域
            addArea() {
                this.title = "区域管理";
                this.regionalDialog = true;
                Object.keys(this.visibleForm).map(key => {
                    this.visibleForm[key] = '';
                })
            },
            //提交表单
            onSubmit(visibleForm) {
                this.$refs[visibleForm].validate((valid) => {
                        if (valid) {
                            let params = {
                                areaName: this.visibleForm.areaName,
                                id: this.visibleForm.id
                            }
                            if (this.visibleForm.id) {
                                EditArea(params).then((res) => {
                                    this.regionalDialog = false;
                                    if (res.data.code == "SUCCESS") {
                                        this.$emit('handleTreeData','first');
                                        this.$message.success("编辑成功！");
                                    } else {
                                        this.$message.info(res.data.message);
                                    }
                                })
                            } else {
                                AddArea(params).then((res) => {
                                    this.regionalDialog = false;
                                    if (res.data.code == "SUCCESS") {
                                        this.$emit('handleTreeData','first');
                                        this.$message.success("保存成功！");
                                    } else {
                                        this.$message.info(res.data.message);
                                    }
                                })
                            }
                        }
                    }
                )
            },
            //编辑
            handleEdit(index, row) {
                this.title = "编辑区域管理";
                this.regionalDialog = true;
                this.visibleForm.areaName = row.name;
                this.visibleForm.id = row.id;
            },
            //删除
            handleDelete(index, row) {
                this.$confirm("确定要删除区域吗?", "温馨提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    delArea(row.id).then((res) => {
                        if (res.data.code == "SUCCESS") {
                            this.$emit('handleTreeData','first');
                            this.$message.success("删除成功！");
                        } else {
                            this.$message.info(res.data.message);
                        }
                    })
                }).catch(() => {
                    this.$message.info("已取消删除!");
                });
            },
        }
    }
</script>

<style scoped>

</style>
