<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>记录查询</el-breadcrumb-item>
            <el-breadcrumb-item>车辆停车记录</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 搜索筛选 -->
        <el-form :inline="true" :model="filters" class="user-search">
            <el-form-item>
                <el-input size="small" v-model="filters.licensePlate" clearable placeholder="请输入车牌号码"
                          @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
            <el-form-item>
                <el-select size="small" v-model="filters.parkingType" clearable filterable placeholder="请选择停车类型">
                    <el-option label="临停" value="1"></el-option>
                    <el-option label="长租" value="2"></el-option>
                    <el-option label="会员" value="3"></el-option>
                    <el-option label="长租过期会员" value="4"></el-option>
                    <el-option label="长租超员" value="5"></el-option>
                    <el-option label="储值" value="6"></el-option>
                    <el-option label="预约" value="7"></el-option>
                    <el-option label="长租过期临停" value="8"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-date-picker
                        size="small"
                        v-model="date1"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="入场开始日期"
                        end-placeholder="入场结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-date-picker
                        size="small"
                        v-model="date2"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="出场开始日期"
                        end-placeholder="出场结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-search" type="primary" size="small" @click="handleSearch">查询</el-button>
                <el-button type="primary" size="small" @click="handleExportExcel">导出</el-button>
            </el-form-item>
        </el-form>
        <!--列表-->
        <el-table
                id="out-table"
                size="small"
                :data="tableData"
                highlight-current-row
                border
                v-loading="loading"
                element-loading-text="拼命加载中"
                style="width: 100%"
                :row-style="{height:'45px'}"
                :cell-style="{padding:'0px'}"
                :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                stripe height="491" max-height="500"
                :default-sort="{prop: 'entranceTime', order: 'descending'}">
            <el-table-column min-width="30" type="index" label="序号"></el-table-column>
            <el-table-column prop="licensePlate" label="车牌号码" align="center">
                <template slot-scope="scope">
                    <span>{{scope.row.licensePlate?scope.row.licensePlate:'无牌车'}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="rfidNo" label="卡号" align="center"></el-table-column>
            <el-table-column prop="parkingType" label="停车类型" align="center">
                <template slot-scope="scope">
                    <el-tag type="primary" v-if="scope.row.parkingType == 1">临停</el-tag>
                    <el-tag type="success" v-else-if="scope.row.parkingType == 2">长租</el-tag>
                    <el-tag type="warning" v-else-if="scope.row.parkingType == 3">会员</el-tag>
                    <el-tag type="info" v-else-if="scope.row.parkingType == 4 || scope.row.parkingType == 8">长租过期
                    </el-tag>
                    <el-tag type="danger" v-else-if="scope.row.parkingType == 5">长租超员</el-tag>
                    <el-tag type="danger" v-else-if="scope.row.parkingType == 6">储值</el-tag>
                    <el-tag type="danger" v-else-if="scope.row.parkingType == 7">预约</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="entranceRoadName" label="入口车道" align="center"></el-table-column>
            <el-table-column prop="exitRoadName" label="出口车道" align="center"></el-table-column>
            <el-table-column min-width="100" prop="entranceTime" label="入场时间" align="center">
                <template slot-scope="scope">
                    <span v-if="scope.row.entranceTime != null">
                        {{scope.row.entranceTime}}
                    </span>
                    <span v-else-if="scope.row.entranceTime == null && scope.row.exitTime != null">
                        {{scope.row.exitTime}}
                    </span>
                </template>
            </el-table-column>
            <el-table-column min-width="100" prop="exitTime" label="出场时间" align="center"></el-table-column>
            <el-table-column prop="" label="停车时长(分)" align="center" :formatter="dateFormat"></el-table-column>
            <el-table-column prop="payMoney" label="缴费金额" align="center">
                <template slot-scope="scope">
                    <span>{{scope.row.payMoney ? scope.row.payMoney : 0}}</span>
                    <!--                    <span v-if="scope.row.payMoney != null">-->
                    <!--                        {{scope.row.payMoney}}-->
                    <!--                    </span>-->
                    <!--                    <span v-else-if="scope.row.payMoney == null">-->
                    <!--                        0元-->
                    <!--                    </span>-->
                </template>
            </el-table-column>
            <el-table-column prop="couponName" label="优惠名称" align="center"></el-table-column>
            <el-table-column prop="couponDiscount" label="优惠抵扣" align="center"></el-table-column>
        </el-table>
        <el-pagination background
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="filters.current"
                       :page-sizes="[10, 100, 200, 500, 1000]"
                       :page-size="filters.size"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
        <!--        选择导出文件类型-->
        <el-dialog v-draggable title="文件类型" :visible.sync="selectDialog" width="250px">
            <el-form :model="editForm" ref="editForm" size="small" :rules="rules">
                <el-form-item prop="radio" label-width="50px">
                    <el-radio v-model="editForm.radio" label="xls">xls</el-radio>
                    <el-radio v-model="editForm.radio" label="xlsx">xlsx</el-radio>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="selectDialog = false">取 消</el-button>
        <el-button size="small" type="primary" @click="onExportExcel('editForm')">确 定</el-button>
        </span>
        </el-dialog>
    </div>
</template>


<script>
    import {formatDate} from "@/utils";
    import {parkingRecord} from "@/api/record/vehiclelist";

    export default {
        name: "vehicleParking",
        data() {
            return {
                loading: false,
                total: 0,
                tableData: [],
                filters: {
                    current: 1,
                    size: 10,
                    entranceEndTime: '',
                    entranceStartTime: '',
                    exitEndTime: '',
                    exitStartTime: '',
                    licensePlate: '',
                    parkingId: '',
                    parkingType: '',
                    startTime: '',
                    endTime: '',
                },
                date1: [],//入场
                date2: [],//出场
                selectDialog: false,
                editForm: {
                    radio: "",
                },
                rules: {
                    radio: [
                        {required: true, message: '请选择导出类型', trigger: 'change'}
                    ],
                },
            }
        },
        mounted() {
            this.fetchData();  //调用接口获取动态数据
        },
        methods: {
            fetchData() { //获取数据
                let that = this;
                that.filters.entranceStartTime =
                    that.date1 && that.date1.length
                        ? formatDate(that.date1[0], "yyyy-MM-dd")
                        : "";
                that.filters.entranceEndTime =
                    that.date1 && that.date1.length
                        ? formatDate(that.date1[1], "yyyy-MM-dd")
                        : "";
                that.filters.exitStartTime =
                    that.date2 && that.date2.length
                        ? formatDate(that.date2[0], "yyyy-MM-dd")
                        : "";
                that.filters.exitEndTime =
                    that.date2 && that.date2.length
                        ? formatDate(that.date2[1], "yyyy-MM-dd")
                        : "";
                let params = {
                    current: that.filters.current,
                    size: that.filters.size,
                    parkingId: that.filters.parkingId,
                    licensePlate: that.filters.licensePlate,
                    parkingType: that.filters.parkingType,
                    entranceEndTime: that.filters.entranceEndTime,
                    entranceStartTime: that.filters.entranceStartTime,
                    exitEndTime: that.filters.exitEndTime,
                    exitStartTime: that.filters.exitStartTime,
                    startTime: that.filters.startTime,
                    endTime: that.filters.endTime,
                };
                that.loading = true;
                parkingRecord(params).then((res) => {
                    that.loading = false;
                    that.tableData = res.data.data.records;
                    that.total = res.data.data.total;
                    that.filters.current = res.data.data.current;
                    that.filters.size = res.data.data.size;
                }).catch(error => {
                    that.loading = false;
                    console.log(error);
                });
            },
            handleSizeChange(val) {
                this.filters.size = val;
                this.filters.current = 1;
                this.fetchData();
            },
            handleCurrentChange(val) {
                this.filters.current = val;
                this.fetchData();
            },
            handleSearch() {
                this.filters.current = 1;
                this.fetchData();
            },
            //停车时长
            dateFormat(row) {
                let time1 = "";
                let time2 = "";
                let begin1 = "";
                let end1 = "";
                if (row.exitTime != null) {
                    time1 = this.dayjs(row.entranceTime).format('YYYY-MM-DD HH:mm:ss');
                    time2 = this.dayjs(row.exitTime).format('YYYY-MM-DD HH:mm:ss');
                } else if (row.entranceTime != null) {
                    time1 = this.dayjs(row.entranceTime).format('YYYY-MM-DD HH:mm:ss');
                    time2 = this.dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss');
                }
                //定义两个变量time1,time2分别保存当前和入场时间
                //截取字符串，得到日期部分"2021-10-14",用split把字符串分隔成数组
                if (time1 != "" || time2 != "") {
                    begin1 = time1.substr(0, 10).split("-");
                    end1 = time2.substr(0, 10).split("-");
                }
                //将拆分的数组重新组合，并实例成化新的日期对象
                var date1 = new Date(begin1[1] + -+begin1[2] + -+begin1[0]);
                var date2 = new Date(end1[1] + -+end1[2] + -+end1[0]);
                //得到两个日期之间的差值m，以分钟为单位
                // Math.abs(date2-date1)计算出以毫秒为单位的差值
                // Math.abs(date2-date1)/1000得到以秒为单位的差值
                // Math.abs(date2-date1)/1000/60得到以分钟为单位的差值
                var m = parseInt(Math.abs(date2 - date1) / 1000 / 60);
                //小时数和分钟数相加得到总的分钟数
                //time1.substr(11,2)截取字符串得到时间的小时数
                // parseInt(time1.substr(11,2))*60把小时数转化成为分钟
                var min1 = parseInt(time1.substr(11, 2)) * 60 + parseInt(time1.substr(14, 2));
                var min2 = parseInt(time2.substr(11, 2)) * 60 + parseInt(time2.substr(14, 2));
                //两个分钟数相减得到时间部分的差值，以分钟为单位
                var n = min2 - min1;
                //将日期和时间两个部分计算出来的差值相加，即得到两个时间相减后的分钟数
                var minutes = m + n;
                var nn = Number(minutes);
                //2.验证是否为有效数字
                if (!isNaN(nn)) {
                    //有效数字
                    if (nn > 0) {
                        return nn;
                    } else if (nn < 0) {
                        return 0;
                    } else {
                        return 0;
                    }
                }
            },
            handleExportExcel() {
                this.selectDialog = true;
                this.editForm.radio = "";
            },
            //导出列表
            onExportExcel(editForm) {
                this.$refs[editForm].validate((valid) => {
                    if (valid) {
                        this.$nextTick(function () {
                            let tables = document.getElementById("out-table");
                            let table_book = this.$XLSX.utils.table_to_book(tables);
                            var table_write = this.$XLSX.write(table_book, {
                                bookType: this.editForm.radio,
                                bookSST: true,
                                type: "array"
                            });
                            try {
                                this.$FileSaver.saveAs(
                                    new Blob([table_write], {type: "application/octet-stream"}),
                                    "车辆停车记录." + this.editForm.radio
                                );
                            } catch (e) {
                                if (typeof console !== "undefined") console.log(e, table_write);
                            }
                            return table_write;
                        });
                    } else {
                        return false;
                    }
                });
            },
        }
    }
</script>

<style scoped>
    .el-table th {
        text-align: center;
    }

    .el-table tbody tr td:first-child {
        text-align: center;
    }

    .user-search {
        margin-top: 20px;
    }
</style>
