<template>
    <div>
        <el-container>
            <el-aside>
                <el-tree
                        :data="tableData"
                        :highlight-current=true
                        default-expand-all
                        :props="defaultProps"
                        @node-click="handleNodeClick"
                >
                </el-tree>
            </el-aside>
            <el-main>
                <first v-if="type == 'first'" @handleTreeData="handleTreeData" :treeData="treeData"></first>
                <second v-if="type == 'second'" @handleTreeData="handleTreeData" :areaId="areaId"
                        :treeData="treeData" :parkingAreas="parkingAreas"></second>
                <third v-if="type == 'third'" :roadId="areaId" :id="id"></third>
            </el-main>
        </el-container>
    </div>
</template>

<script>
    import First from "./region/first";
    import Second from "./region/second";
    import Third from "./region/third";
    import {parkingAreaRoadList} from "../../../../api/yardSetting";

    export default {
        name: "basicSettings",
        components: {Third, Second, First},
        data() {
            return {
                defaultProps: {
                    children: 'parkingRoads',
                    label: 'name'
                },
                type: "first",
                tableData: [],
                treeData: [],
                areaId: "",
                id: "",
                parkingAreas: []
            };
        },
        mounted() {
            this.id = localStorage.getItem("subSysId");
            this.handleTreeData("first");
        },
        methods: {
            handleNodeClick(data) {
                if (data.rank == 1) {
                    this.type = "first";
                    this.treeData = data.parkingRoads;
                } else if (data.rank == 2) {
                    this.type = "second";
                    this.areaId = data.id;
                    this.treeData = data.parkingRoads;
                } else {
                    this.type = "third";
                    this.areaId = data.id;
                    this.id = data.parkingId;
                }
            },
            handleTreeData(type, areaId) {
                //获取数据
                parkingAreaRoadList(this.id).then((res) => {
                    this.parkingAreas = res.data.data.parkingAreas;
                    function recursion(data, rank) {
                        ++rank;
                        // eslint-disable-next-line no-prototype-builtins
                        if (data.hasOwnProperty('parkingRoads')) {
                            data.rank = rank;
                            data.parkingRoads.forEach((item) => {
                                recursion(item, rank);
                            })
                        } else {
                            data.rank = rank;
                        }
                        return data;
                    }

                    let data = {
                        rank: '0',
                        'name': localStorage.getItem('projectName'),
                        'parkingRoads': res.data.data.parkingAreas
                    };
                    data = recursion(data, 0);
                    this.tableData = [data];
                    this.type = type;
                    if (areaId != undefined) {
                        data.parkingRoads.forEach(item => {
                            if (areaId == item.id) {
                                this.treeData = item.parkingRoads;
                            }
                        })
                    } else {
                        this.treeData = data.parkingRoads;
                    }
                })
            },
        }
    }
</script>

<style scoped>
    .el-aside {
        background-color: #ffffff !important;
        width: auto !important;
    }
</style>
