<template>
    <div>
        <div style="margin-bottom: 10px">
            <span>销售总额：{{monthSell}}元，</span>
            <span>商家数：{{monthBusinessNum}}，</span>
            <span>优惠券张数：{{monthCouponNum}}张</span>
        </div>
        <!--列表-->
        <el-table
                size="small"
                :data="tableData"
                highlight-current-row
                border
                v-loading="loading"
                element-loading-text="拼命加载中"
                style="width: 100%"
                :row-style="{height:'45px'}"
                :cell-style="{padding:'0px'}"
                :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                stripe
                height="500"
                max-height="500"
        >
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column prop="date" label="日期" align="center"></el-table-column>
            <el-table-column prop="payMoney" label="销售总额(元)" align="center"></el-table-column>
            <el-table-column prop="businessNum" label="商家数" align="center"></el-table-column>
            <el-table-column prop="couponNum" label="优惠券张数" align="center"></el-table-column>
        </el-table>
    </div>
</template>

<script>
    import {coupon} from "../../../api/statistics";
    import {formatDate} from "../../../utils";

    export default {
        name: "coupon",
        data() {
            return {
                loading: false,
                tableData: [],
                monthBusinessNum: "",
                monthCouponNum: "",
                monthSell: "",
                months: "",
            }
        },
        props: {
            month: [Number, String],
        },
        watch: {
            month(val) {
                this.months = val
            }
        },
        created() {
            this.months = this.month
        },
        mounted() {
            this.getCouponData();
        },
        methods: {
            getCouponData() { //获取数据
                let that = this;
                that.loading = true;
                let param = {
                    year: formatDate(this.months, "yyyy"),
                    month: formatDate(this.months, "MM")
                }
                coupon(param).then((res) => {
                    that.loading = false;
                    that.tableData = res.data.data.incomeList;
                    that.monthBusinessNum = res.data.data.monthBusinessNum;
                    that.monthCouponNum = res.data.data.monthCouponNum;
                    that.monthSell = res.data.data.monthSell;
                }).catch(error => {
                    that.loading = false;
                    console.log(error);
                });
            },
        }
    }
</script>

<style scoped>

</style>
