<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>访客管理</el-breadcrumb-item>
            <el-breadcrumb-item>访客列表</el-breadcrumb-item>
            <el-breadcrumb-item>{{$route.query.title}}</el-breadcrumb-item>
        </el-breadcrumb>
        <el-form :model="visibleForm" ref="visibleForm" size="small" :rules="rules" label-width="130px"
                 class="user-search">
            <el-row>
                <el-col :span="11">
                    <el-form-item label="姓名" prop="visitorName">
                        <el-input size="small" v-model="visibleForm.visitorName"
                                  placeholder="请输入内容"/>
                    </el-form-item>
                    <el-form-item label="手机号" prop="visitorPhone">
                        <el-input size="small" v-model="visibleForm.visitorPhone"
                                  oninput="value=value.replace(/[^\d]/g,'')"
                                  placeholder="请输入内容"/>
                    </el-form-item>
                    <el-form-item label="证件号码" prop="visitorIdCard">
                        <el-input size="small" v-model="visibleForm.visitorIdCard"
                                  placeholder="请输入内容"/>
                    </el-form-item>
                    <el-form-item v-if="proType1 == 1" label="车牌号码" prop="visitorLicensePlate">
                        <el-input size="small" v-model="visibleForm.visitorLicensePlate"
                                  placeholder="请输入内容"/>
                    </el-form-item>
                    <el-form-item v-if="proType2 == 2" label="门禁卡号" prop="passCardNum">
                        <el-input size="small" v-model="visibleForm.passCardNum"
                                  oninput="value=value.replace(/[^\d]/g,'')"
                                  placeholder="请输入内容"/>
                    </el-form-item>
                    <el-form-item v-if="proType2 == 2" label="头像" prop="visitorAvatar">
                        <el-tabs v-model="activeName" type="card">
                            <el-tab-pane label="上传" name="first">
                                <el-upload
                                        class="avatar-uploader"
                                        action="#"
                                        :multiple="false"
                                        name="file"
                                        :auto-upload="false"
                                        :show-file-list="false"
                                        :on-change="handleChange">
                                    <img v-if="visibleForm.visitorAvatar" :src="visibleForm.visitorAvatar"
                                         class="avatar">
                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                </el-upload>
                            </el-tab-pane>
                            <el-tab-pane label="拍照" name="second">
                                <img v-if="visibleForm.visitorAvatar " :src="visibleForm.visitorAvatar "
                                     class="avatar" @click="handleSetImage()">
                                <i v-else class="el-icon-plus avatar-uploader-icon"
                                   @click="handleSetImage()"></i>
                            </el-tab-pane>
                            <el-tab-pane label="头像示例" name="third">
                                <el-image style="width: 50%; height: 50%"
                                          :src="require('../../../assets/images/example.jpg')"
                                          :preview-src-list="[require('../../../assets/images/example.jpg')]">
                                </el-image>
                            </el-tab-pane>
                        </el-tabs>
                    </el-form-item>
                </el-col>
                <el-col :span="11">
                    <el-form-item label="被访人" prop="visiteeName">
                        <el-select @change="handleSelectChange" @focus="handleFocus" clearable style="width: 100%"
                                   filterable v-model="visitee" placeholder="请选择" :filter-method="handleUserList">
                            <el-option
                                    v-for="(item, index) in userInfoList"
                                    :key="index"
                                    :label="item.name+' | '+ item.department +' | '+ item.phone"
                                    :value="{value:item.name,label:item.userNo}">
                                <div style="display: flex;justify-content: space-around">
                                    <span>{{ item.name }}</span>
                                    <span>{{ item.department }}</span>
                                    <span>{{ item.phone }}</span>
                                </div>
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="来访事由" prop="visitReason">
                        <el-select clearable style="width: 100%"
                                   v-model="visibleForm.visitReason"
                                   filterable
                                   allow-create
                                   default-first-option
                                   placeholder="请输入内容">
                            <el-option
                                    v-for="item in options"
                                    :key="item"
                                    :label="item"
                                    :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="随访人数" prop="followerCnt">
                        <el-input size="small" v-model="visibleForm.followerCnt"
                                  oninput="value=value.replace(/[^\d]/g,'')"
                                  placeholder="请输入内容"/>
                    </el-form-item>
                    <el-form-item label="携带物品" prop="carryItem">
                        <el-input size="small" v-model="visibleForm.carryItem"
                                  placeholder="请输入内容"/>
                    </el-form-item>
                    <el-form-item label="来访时间" prop="date">
                        <el-date-picker
                                style="width: 100%"
                                size="small"
                                v-model="visibleForm.date"
                                type="datetimerange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                value-format="yyyy-MM-dd HH:mm:ss">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item v-if="proType2 == 2" label="门禁授权" prop="name">
                        <el-input
                                placeholder="输入关键字进行过滤"
                                v-model="filterText">
                        </el-input>
                        <div style="display:flex;justify-content: space-between;margin-top: 10px;">
                            <el-tree :data="treeData" :highlight-current=true ref="tree" node-key="id"
                                     default-expand-all :props="defaultProps" show-checkbox
                                     @check-change="handleCheckClick" :filter-node-method="filterNode">
                            </el-tree>
                            <div>
                                <el-button @click="getCurrentProjectList" size="small" type="success">使用缺省通道设置
                                </el-button>
                            </div>
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-form-item>
                    <el-button @click="cancel">取 消</el-button>
                    <el-button type="primary" @click="onSubmit('visibleForm')">确 定</el-button>
                </el-form-item>
            </el-row>
        </el-form>
        <!--    拍照上传-->
        <el-dialog v-draggable  title="拍照上传" :visible.sync="dialogVisible" width="60%" @close="handleClose" center>
            <el-row>
                <el-col :span="12" style="text-align: center">
                    <div style="text-align: center;font-size: 14px;font-weight: bold;margin-bottom: 10px;">摄像头</div>
                    <!-- 这里就是摄像头显示的画面 -->
                    <video id="videoCamera" :width="videoWidth" :height="videoHeight" autoplay></video>
                    <div class="iCenter">
                        <el-button type='primary' size='small' icon="el-icon-camera" @click="takePhone"
                                   style="margin-top: 15px;">拍照
                        </el-button>
                        <el-button @click="getCompetence" icon="el-icon-video-camera" size="small"> 打开摄像头
                        </el-button>
                        <el-button size="small" @click="stopNavigator" icon="el-icon-switch-button"
                                   style="margin-top: 15px;">关闭摄像头
                        </el-button>
                    </div>
                </el-col>
                <el-col :span="12" style="text-align: center">
                    <div style="text-align: center;font-size: 14px;font-weight: bold;margin-bottom: 10px;">拍摄效果
                    </div>
                    <!-- 这里是点击拍照显示的图片画面 -->
                    <canvas id="canvasCamera" :width="videoWidth" :height="videoHeight"></canvas>
                    <el-button icon="el-icon-check" type="primary" size="small" @click="setImage"
                               style="margin-top: 15px;">保存
                    </el-button>
                    <el-button size="small" @click="handleClose" icon="el-icon-close" style="margin-top: 15px;">取消
                    </el-button>
                </el-col>
            </el-row>
        </el-dialog>
    </div>
</template>

<script>
    import {getTreeData} from "../../../api/spaceApi";
    import {uploadFile} from "../../../api/organizationApi";
    import {addAppointment, queryUserList, updateVisitor} from "../../../api/visitorMachine";
    import {getCurrentProject} from "../../../api/login";

    export default {
        name: "addVisitors",
        data() {
            return {
                visibleForm: {
                    visitorName: "",
                    visitorPhone: "",
                    visitorLicensePlate: "",
                    visitorAvatar: "",
                    visitReason: "",
                    passCardNum: "",
                    doorwayIds: "",
                    startTime: "",
                    endTime: "",
                    projectId: "",
                    visiteeName: "",
                    visiteeNo: "",
                    visitorIdCard: "",
                    followerCnt: "",
                    carryItem: "",
                    id: "",
                    date: [],
                },
                visitee: "",
                options: ['拜访', '送货', '维护'],
                rules: {
                    visitorName: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ],
                    visitorPhone: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ],
                    visiteeName: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ],
                    date: [
                        {required: true, message: '请输入', trigger: 'change'},
                    ],
                },
                defaultProps: {
                    children: 'subList',
                    label: 'name'
                },
                treeData: [],
                visitorDoorwayIds: [],
                projectType: [],
                proType1: "",
                proType2: "",
                userInfoList: [],

                activeName: 'first',
                dialogVisible: false,
                videoWidth: 400,
                videoHeight: 300,
                imgSrc: "",
                thisCancas: null,
                thisContext: null,
                thisVideo: null,
                openVideo: false,
                filterText: '',
            }
        },
        watch: {
            filterText(val) {
                this.$refs.tree.filter(val);
            }
        },
        mounted() {
            this.proType1 = localStorage.getItem('projectType1');
            this.proType2 = localStorage.getItem('projectType2');
            this.handleTreeData();
            let dataInfo = this.$route.query.data ? JSON.parse(decodeURI(this.$route.query.data)) : '';
            if (dataInfo) {
                Object.keys(this.visibleForm).forEach((n) => {
                    this.$set(this.visibleForm, n, dataInfo[n]);
                });
                this.visibleForm.date = [this.visibleForm.startTime, this.visibleForm.endTime];
                let doorwayIds = this.visibleForm.doorwayIds;
                if (doorwayIds) {
                    let arr = doorwayIds.split(',');
                    this.$nextTick(() => {
                        this.$refs.tree.setCheckedKeys(arr.map(Number));
                    });
                }
                this.handleUserList(this.visibleForm.visiteeName);
            } else {
                Object.keys(this.visibleForm).forEach((n) => {
                    let defaultStr = "";
                    this.$set(this.visibleForm, n, defaultStr);
                    this.visibleForm.date = [];
                });
                this.handleUserList();
            }
        },
        methods: {
            getCurrentProjectList() {
                getCurrentProject().then((res) => {
                        let visitorDoorwayIds = res.data.data.visitorDoorwayIds;
                        if (visitorDoorwayIds) {
                            let arr = visitorDoorwayIds.split(',');
                            this.$nextTick(() => {
                                this.$refs.tree.setCheckedKeys(arr.map(Number));
                            });
                        }
                    }
                )
            },
            handleTreeData() {
                getTreeData().then((res) => {
                    this.treeData = res.data.data;
                }).catch(error => {
                    console.log(error);
                });
            },
            filterNode(value, data) {
                if (!value) return true;
                return data.name.indexOf(value) !== -1;
            },
            handleCheckClick(data) {
                let visitorDoorwayInfo = this.$refs.tree.getCheckedNodes(true);
                this.visitorDoorwayIds = [];
                visitorDoorwayInfo.forEach(element => {
                    if (element.type === 2) {
                        this.visitorDoorwayIds.push(element.id);
                    }
                });
                this.visibleForm.doorwayIds = this.visitorDoorwayIds.toString();
            },
            handleFocus() {
                //     this.handleUserList();
            },
            handleUserList(value) {
                let param = {
                    projectId: localStorage.getItem("projectId"),
                    name: value,
                    fuzzyMatch: true
                }
                queryUserList(param).then((res) => {
                    this.userInfoList = res.data.data;
                    if (this.userInfoList.length > 0) {
                        this.userInfoList.forEach(item => {
                            if (item.name == this.visibleForm.visiteeName) {
                                this.visitee = item.name + ' | ' + item.department + ' | ' + item.phone;
                            }
                        })
                    } else {
                        this.visitee = this.visibleForm.visiteeName;
                    }
                }).catch(error => {
                    console.log(error);
                });
            },
            handleSelectChange(item) {
                const {value, label} = item;
                this.visibleForm.visiteeName = value;
                this.visibleForm.visiteeNo = label;
            },
            onSubmit(visibleForm) {
                this.$refs[visibleForm].validate((valid) => {
                    if (valid) {
                        let param = {
                            visitorName: this.visibleForm.visitorName,
                            visitorPhone: this.visibleForm.visitorPhone,
                            visitorLicensePlate: this.visibleForm.visitorLicensePlate,
                            visitorAvatar: this.visibleForm.visitorAvatar,
                            visiteeName: this.visibleForm.visiteeName,
                            visiteeNo: this.visibleForm.visiteeNo,
                            visitReason: this.visibleForm.visitReason,
                            passCardNum: this.visibleForm.passCardNum,
                            doorwayIds: this.visibleForm.doorwayIds,
                            startTime: this.visibleForm.date && this.visibleForm.date.length ? this.visibleForm.date[0] : "",
                            endTime: this.visibleForm.date && this.visibleForm.date.length ? this.visibleForm.date[1] : "",
                            projectId: localStorage.getItem("projectId"),
                            id: this.visibleForm.id,
                            visitorIdCard: this.visibleForm.visitorIdCard,
                            followerCnt: this.visibleForm.followerCnt,
                            carryItem: this.visibleForm.carryItem,
                        }
                        if (this.visibleForm.id) {
                            updateVisitor(param).then((res) => {
                                if (res.data.code == "SUCCESS") {
                                    this.$router.push('visitorManagement');
                                    this.$message.success(res.data.message);
                                } else {
                                    this.$message.info(res.data.message);
                                }
                            })
                        } else {
                            addAppointment(param).then((res) => {
                                if (res.data.code == "SUCCESS") {
                                    this.$router.push('visitorManagement');
                                    this.$message.success(res.data.message);
                                } else {
                                    this.$message.info(res.data.message);
                                }
                            })
                        }
                    }
                })
            },
            handleChange(file) {
                const isLt10M = file.size / 1024 / 1024 < 10;
                var testmsg = /^image\/(jpeg|png|jpg)$/.test(file.raw.type);
                if (!testmsg) {
                    this.$message.error('上传图片只能是 JPEG|PNG|JPG 格式!');
                    return
                }
                if (!isLt10M) {
                    this.$message.error('上传图片大小不能超过 10MB!');
                    return;
                }
                let form = new FormData();
                form.append('file', file.raw);
                uploadFile(form).then((res) => {
                    this.$message.success("上传成功!");
                    this.visibleForm.visitorAvatar = res.data.data;
                });
            },
            cancel() {
                this.$router.push('visitorManagement');
            },
            handleSetImage() {
                this.dialogVisible = true;
                this.$nextTick(() => {
                    this.getCompetence();
                })
            },
            // 调用权限（打开摄像头功能）
            getCompetence() {
                var _this = this;
                _this.thisCancas = document.getElementById("canvasCamera");
                _this.thisContext = this.thisCancas.getContext("2d");
                _this.thisVideo = document.getElementById("videoCamera");
                // 获取媒体属性，旧版本浏览器可能不支持mediaDevices，我们首先设置一个空对象
                if (navigator.mediaDevices === undefined) {
                    navigator.mediaDevices = {};
                }
                // 一些浏览器实现了部分mediaDevices，我们不能只分配一个对象
                // 使用getUserMedia，因为它会覆盖现有的属性。
                // 这里，如果缺少getUserMedia属性，就添加它。
                if (navigator.mediaDevices.getUserMedia === undefined) {
                    navigator.mediaDevices.getUserMedia = function (constraints) {
                        // 首先获取现存的getUserMedia(如果存在)
                        var getUserMedia =
                            navigator.webkitGetUserMedia ||
                            navigator.mozGetUserMedia ||
                            navigator.getUserMedia;
                        // 有些浏览器不支持，会返回错误信息
                        // 保持接口一致
                        if (!getUserMedia) {
                            //不存在则报错
                            return Promise.reject(
                                new Error("getUserMedia is not implemented in this browser")
                            );
                        }
                        // 否则，使用Promise将调用包装到旧的navigator.getUserMedia
                        return new Promise(function (resolve, reject) {
                            getUserMedia.call(navigator, constraints, resolve, reject);
                        });
                    };
                }
                var constraints = {
                    audio: false,
                    video: {
                        width: this.videoWidth,
                        height: this.videoHeight,
                        transform: "scaleX(-1)",
                    },
                };
                navigator.mediaDevices
                    .getUserMedia(constraints)
                    .then(function (stream) {
                        // 旧的浏览器可能没有srcObject
                        if ("srcObject" in _this.thisVideo) {
                            _this.thisVideo.srcObject = stream;
                        } else {
                            // 避免在新的浏览器中使用它，因为它正在被弃用。
                            _this.thisVideo.src = window.URL.createObjectURL(stream);
                        }
                        _this.thisVideo.onloadedmetadata = function (e) {
                            _this.thisVideo.play();
                        };
                    })
                    .catch((err) => {
                        this.$message({
                            message: '没有开启摄像头权限或浏览器版本不兼容',
                            type: 'warning'
                        });
                        console.log(err);
                    });
            },
            //点击拍照截图画面
            takePhone() {
                // canvas画图
                this.thisContext.drawImage(this.thisVideo, 0, 0, this.videoWidth, this.videoHeight);
                this.imgSrc = this.thisCancas.toDataURL('image/png');
            },
            //  绘制图片（拍照功能）
            setImage() {
                // 获取图片base64链接
                var blob = this.convertBase64UrlToBlob(this.imgSrc); // 上一步中的函数
                let form = new FormData();
                form.append('file', blob, 'image.png');
                uploadFile(form).then((res) => {
                    if (res.data.code == "SUCCESS") {
                        this.$message.success("上传成功!");
                        this.visibleForm.visitorAvatar = res.data.data;
                        this.dialogVisible = false;
                        //清空画布
                        let c = document.getElementById("canvasCamera");
                        let cxt = c.getContext("2d");
                        cxt.clearRect(0, 0, c.width, c.height);
                        // 关闭摄像头
                        this.thisVideo.srcObject.getTracks()[0].stop();
                    } else {
                        this.$message.info(res.data.message);
                    }
                });
            },
            /*
            * * 将base64转换成可用formdata提交的文件
            * * @param {string} urlData base64的url
            * * @return {Blob}
            * */
            convertBase64UrlToBlob(urlData) {
                //去掉url的头，并转换为byte
                var split = urlData.split(',');
                var bytes = window.atob(split[1]);
                //处理异常,将ascii码小于0的转换为大于0
                var ab = new ArrayBuffer(bytes.length);
                var ia = new Uint8Array(ab);
                for (var i = 0; i < bytes.length; i++) {
                    ia[i] = bytes.charCodeAt(i);
                }
                return new Blob([ab], {type: split[0]});
            },
            handleClose() {
                this.dialogVisible = false;
                //清空画布
                let c = document.getElementById("canvasCamera");
                let cxt = c.getContext("2d");
                cxt.clearRect(0, 0, c.width, c.height);
                this.thisVideo.srcObject.getTracks()[0].stop();
            },
            // 关闭摄像头
            stopNavigator() {
                this.thisVideo.srcObject.getTracks()[0].stop();
            },
        }
    }
</script>

<style lang="scss" scoped>
    .user-search {
        margin-top: 20px;
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }

    .camera_outer {
        position: relative;
        overflow: hidden;
        background-size: 100%;

        video,
        canvas,
        .tx_img {
            -moz-transform: scaleX(-1);
            -webkit-transform: scaleX(-1);
            -o-transform: scaleX(-1);
            transform: scaleX(-1);
        }

        .btn_camera {
            position: absolute;
            bottom: 4px;
            left: 0;
            right: 0;
            height: 50px;
            background-color: rgba(0, 0, 0, 0.3);
            line-height: 50px;
            text-align: center;
            color: #ffffff;
        }

        .bg_r_img {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            top: 0;
        }

        .img_bg_camera {
            position: absolute;
            right: 0;
            top: 0;

            img {
                width: 178px;
                height: 178px;
            }

            .img_btn_camera {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 50px;
                line-height: 50px;
                text-align: center;
                background-color: rgba(0, 0, 0, 0.3);
                color: #ffffff;

                .loding_img {
                    width: 50px;
                    height: 50px;
                }

            }
        }
    }
</style>
