<template>
    <el-container class="container">
        <el-header height="50px" style="padding: inherit">
            <router-view name="top"></router-view>
        </el-header>
        <el-main>
            <router-view></router-view>
        </el-main>
        <el-footer class="footer">
            <div style="display: flex;align-items: center">
                <el-button @click="hanldeBack">上一步</el-button>
                <el-button @click="hanldeNextStep">下一步</el-button>
                <el-button type="primary" @click="hanldeComplete">完成</el-button>

                <div class="drawer-div">
                    <p><span>{{content.t1}}</span> {{content.t2}}</p>
                    <ul class="drawer-info">
                        <li><span>{{content.t3}}</span></li>
                        <li v-show="content.t4"><span>{{content.t4}}</span></li>
                        <li v-show="content.t5"><span>{{content.t5}}</span></li>
                        <li v-show="content.t6"><span>{{content.t6}}</span></li>
                    </ul>
                </div>
            </div>
        </el-footer>
    </el-container>
</template>

<script>
    export default {
        name: "Content",
        data() {
            return {
                tableData: [],
                loading: false,
                total: 0,
                filters: {
                    size: 10,
                    current: 1,
                },
                dialogTableVisible: false,
                params: "",
                active: 0,
                content: {
                    t1: "01",
                    t2: "创建项目",
                    t3: "填写项目基本信息",
                    t4: "",
                }
            }
        },
        watch: {
            '$route': function (to, from) { // 路由改变时执行
                console.info("to.path:" + to.path);
                this.showInfo(to.fullPath);
            }
        },
        created: function () {
            this.showInfo(this.$route.fullPath);
        },
        methods: {
            showInfo(path) {
                if (path === '/content/parkingSettings?activeName=2') {
                    let info = {
                        t1: "01",
                        t2: "设置车场参数",
                        t3: "跟据现场实际情况勾选",
                    }
                    this.content = info;
                } else if (path === '/content/parkingSettings?activeName=3') {
                    let info = {
                        t1: "02",
                        t2: "添加长租费率",
                        t3: "可设置大小车型在不同区域的年、月等周期的收费标准",
                    }
                    this.content = info;
                } else if (path === '/content/parkingSettings?activeName=4') {
                    let info = {
                        t1: "03",
                        t2: "添加临停费率",
                        t3: "可设置大小车型在不同区域里面的临停费率",
                        t4: "*不设置费率时，收费车场的临时车辆无法通行",
                    }
                    this.content = info;
                } else if (path === '/content/parkingSettings?activeName=5') {
                    let info = {
                        t1: "04",
                        t2: "添加会员费率",
                        t3: "可设置大小车型在不同区域里面的会员费率",
                        t4: "*长租车过期后，以会员车费率计算费用",
                    }
                    this.content = info;
                } else if (path === '/content/roleManagement') {
                    let info = {
                        t1: "05",
                        t2: "增加操作员",
                        t3: "点击【角色管理】，添加角色",
                        t4: "点击【授权】，勾选权限",
                    }
                    this.content = info;
                } else if (path === '/content/userManagement') {
                    let info = {
                        t1: "06",
                        t2: "增加用户",
                        t3: "【用户管理】 添加用户关联角色",
                    }
                    this.content = info;
                } else if (path === '/content/deviceManagement') {
                    let info = {
                        t1: "01",
                        t2: "添加设备",
                        t3: "向平台申请项目授权设备数",
                        t4: "获取设备SN码",
                        t5: "门禁通道管理添加设备",
                    }
                    this.content = info;
                } else if (path === '/content/spaceManagement') {
                    let info = {
                        t1: "02",
                        t2: "空间管理",
                        t3: "选中项目名---增加区域",
                        t4: "选中区域名---添加通道，将通道与设备进行绑定",
                    }
                    this.content = info;
                } else if (path === '/content/organizationManagement') {
                    let info = {
                        t1: "03",
                        t2: "组织及人员管理",
                        t3: "选中项目名---增加【机构】",
                        t4: "选中机构名---增加【部门】",
                        t5: "选中部门名---添加用户信息",
                    }
                    this.content = info;
                } else if (path === '/content/accessAuthorization') {
                    let info = {
                        t1: "04",
                        t2: "通行授权",
                        t3: "勾选需要授权的人员名称，点【授权】",
                        t4: "勾选需要授权的区域通道",
                        t5: "选择有效期",
                    }
                    this.content = info;
                } else if (path === '/content/device') {
                    let info = {
                        t1: "01",
                        t2: "添加设备",
                        t3: "向平台申请项目授权设备数",
                        t4: "获取设备SN码",
                        t5: "测速设备管理添加设备",
                    }
                    this.content = info;
                } else if (path === '/content/position') {
                    let info = {
                        t1: "02",
                        t2: "测速点管理",
                        t3: "添加测速点名称后点【设置】",
                        t4: "绑定测速点与设备",
                        t5: "设置其它参数",
                    }
                    this.content = info;
                }


            },
            hanldeBack() {
                if (this.$route.path === '/content/userManagement') {
                    this.active = 4;
                    sessionStorage.setItem('active', this.active);
                    this.$router.push('/content/roleManagement');
                } else if (this.$route.path === '/content/roleManagement') {
                    this.active = 3;
                    sessionStorage.setItem('active', this.active);
                    this.$router.push('/content/parkingSettings?activeName=5');
                } else if (this.$route.fullPath === '/content/parkingSettings?activeName=5') {
                    this.active = 2;
                    sessionStorage.setItem('active', this.active);
                    this.$router.push('/content/parkingSettings?activeName=4');
                } else if (this.$route.fullPath === '/content/parkingSettings?activeName=4') {
                    this.active = 1;
                    sessionStorage.setItem('active', this.active);
                    this.$router.push('/content/parkingSettings?activeName=3');
                } else if (this.$route.fullPath === '/content/parkingSettings?activeName=3') {
                    this.active = 0;
                    sessionStorage.setItem('active', this.active);
                    this.$router.push('/content/parkingSettings?activeName=2');
                }
                if (this.$route.path === '/content/accessAuthorization') {
                    this.active = 2;
                    sessionStorage.setItem('active1', this.active);
                    this.$router.push('/content/organizationManagement');
                } else if (this.$route.path === '/content/organizationManagement') {
                    this.active = 1;
                    sessionStorage.setItem('active1', this.active);
                    this.$router.push('/content/spaceManagement');
                } else if (this.$route.fullPath === '/content/spaceManagement') {
                    this.active = 0;
                    sessionStorage.setItem('active1', this.active);
                    this.$router.push('/content/deviceManagement');
                }
                if (this.$route.path === '/content/position') {
                    this.active = 0;
                    sessionStorage.setItem('active2', this.active);
                    this.$router.push('/content/device');
                }
            },
            hanldeNextStep() {
                if (this.$route.fullPath === '/content/parkingSettings?activeName=2') {
                    this.active = 1;
                    sessionStorage.setItem('active', this.active);
                    this.$router.push('/content/parkingSettings?activeName=3');
                } else if (this.$route.fullPath === '/content/parkingSettings?activeName=3') {
                    this.active = 2;
                    sessionStorage.setItem('active', this.active);
                    this.$router.push('/content/parkingSettings?activeName=4');
                } else if (this.$route.fullPath === '/content/parkingSettings?activeName=4') {
                    this.active = 3;
                    sessionStorage.setItem('active', this.active);
                    this.$router.push('/content/parkingSettings?activeName=5');
                } else if (this.$route.fullPath === '/content/parkingSettings?activeName=5') {
                    this.active = 4;
                    sessionStorage.setItem('active', this.active);
                    this.$router.push('/content/roleManagement');
                } else if (this.$route.fullPath === '/content/roleManagement') {
                    this.active = 5;
                    sessionStorage.setItem('active', this.active);
                    this.$router.push('/content/userManagement');
                }
                if (this.$route.fullPath === '/content/deviceManagement') {
                    this.active = 1;
                    sessionStorage.setItem('active1', this.active);
                    this.$router.push('/content/spaceManagement');
                } else if (this.$route.fullPath === '/content/spaceManagement') {
                    this.active = 2;
                    sessionStorage.setItem('active1', this.active);
                    this.$router.push('/content/organizationManagement');
                } else if (this.$route.fullPath === '/content/organizationManagement') {
                    this.active = 3;
                    sessionStorage.setItem('active1', this.active);
                    this.$router.push('/content/accessAuthorization');
                }
                if (this.$route.fullPath === '/content/device') {
                    this.active = 1;
                    sessionStorage.setItem('active2', this.active);
                    this.$router.push('/content/position');
                }
            },
            hanldeComplete() {
                if (this.$route.name == 'parkingLot') {
                    this.$router.push('/parkingLot/homePage');
                    sessionStorage.removeItem('active')
                } else if (this.$route.name == 'personnel') {
                    this.$router.push('/personnel/indexPage');
                    sessionStorage.removeItem('active1')
                } else if (this.$route.name == 'speedMain') {
                    this.$router.push('/speedMain/home');
                    sessionStorage.removeItem('active2')
                }
            },
        }
    }
</script>

<style scoped>
    .el-main {
        padding: 15px 20px 0;
        width: 100%;
        position: absolute;
        top: 50px;
        bottom: 60px;
    }

    .el-footer {
        padding: 10px 20px;
        background-color: white;
    }

    .drawer-div {
        display: flex;
        flex-direction: row;
        margin-left: 40px;
    }

    .drawer-div p {
        font-size: 18px;
        font-weight: bold;
    }

    .drawer-div span {
        font-family: "Eras Bold ITC";
        font-style: italic;
        margin-right: 5px;
    }

    .drawer-info {
        display: flex;
        flex-direction: row;
        align-items: start;
        margin-left: 40px;
    }

    * {
        list-style-type: disc !important;
    }

    .drawer-info li {
        font-size: 20px;
        margin-left: 40px;
    }

    .drawer-info span {
        font-size: 13px;
        font-style: normal;
    }
</style>
