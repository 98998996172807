import request from '@/utils/request'
import config from '@/config'

// 通行记录列表
export function passRecordList(data) {
    return request({
        url: `${config.bpi}/record/passRecord`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}

// 异常处理记录
export function exceptionHandlingRecord(data) {
    return request({
        url: `${config.bpi}/record/exceptionHandlingRecord`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}

//长租车缴费记录
export function longPayRecord(data) {
    return request({
        url: `${config.bpi}/record/longPayRecord`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}

//临停车缴费记录
export function temporaryPayRecord(data) {
    return request({
        url: `${config.bpi}/record/temporaryPayRecord`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}

//在场车辆
export function parkingRecord(data) {
    return request({
        url: `${config.bpi}/record/parkingRecord`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}

//备选车辆
export function needCharge(data) {
    return request({
        url: `${config.bpi}/record/presentCar/needCharge`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}

//在场车辆
export function presentCar(data) {
    return request({
        url: `${config.bpi}/record/presentCar`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}

//在场车辆
export function presentCarDel(id) {
    return request({
        url: `${config.bpi}/record/presentCar`,
        method: 'delete',
        params: {id: id}
    })
}

//在场车辆批量出场
export function leaveBatch(data) {
    return request({
        url: `${config.bpi}/parkingAdmin/presentCar/leave/batch`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}
