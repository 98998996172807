<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>基础服务</el-breadcrumb-item>
            <el-breadcrumb-item>语音对讲</el-breadcrumb-item>
            <el-breadcrumb-item>购买套餐包</el-breadcrumb-item>
        </el-breadcrumb>
        <el-row style="margin: 20px 0;line-height: 25px;">
            <el-col :span="2">
                <span style="font-size: 18px;font-weight: bold;">套餐包类型</span>
            </el-col>
            <el-col :span="22">
                <div class="coupon">
                    <div class="coupon-left">
                        <div class="coupon-item">
                            <span class="item-num">{{num1.type}}次</span>
                            <span style="color: #8a8a8a">现在购买套餐包有效截止至{{year}}年{{month}}月{{day}}日</span>
                        </div>
                        <div class="coupon-num">
                            <span class="price">{{num1.price}}元</span>
                        </div>
                    </div>
                    <div class="split-line"></div>
                    <div class="coupon-con">
                        <el-input-number v-model="value1" :min="0"></el-input-number>
                    </div>
                </div>
                <div class="coupon">
                    <div class="coupon-left">
                        <div class="coupon-item">
                            <span class="item-num">{{num2.type}}次</span>
                            <span style="color: #8a8a8a">现在购买套餐包有效截止至{{year}}年{{month}}月{{day}}日</span>
                        </div>
                        <div class="coupon-num">
                            <span class="price">{{num2.price}}元</span>
                        </div>
                    </div>
                    <div class="split-line"></div>
                    <div class="coupon-con">
                        <el-input-number v-model="value2" :min="0"></el-input-number>
                    </div>
                </div>
                <div class="coupon">
                    <div class="coupon-left">
                        <div class="coupon-item">
                            <span class="item-num">{{num3.type}}次</span>
                            <span style="color: #8a8a8a">现在购买套餐包有效截止至{{year}}年{{month}}月{{day}}日</span>
                        </div>
                        <div class="coupon-num">
                            <span class="price">{{num3.price}}元</span>
                        </div>
                    </div>
                    <div class="split-line"></div>
                    <div class="coupon-con">
                        <el-input-number v-model="value3" :min="0"></el-input-number>
                    </div>
                </div>
                <div class="coupon">
                    <div class="coupon-left">
                        <div class="coupon-item">
                            <span class="item-num">{{num4.type}}次</span>
                            <span style="color: #8a8a8a">现在购买套餐包有效截止至{{year}}年{{month}}月{{day}}日</span>
                        </div>
                        <div class="coupon-num">
                            <span class="price">{{num4.price}}元</span>
                        </div>
                    </div>
                    <div class="split-line"></div>
                    <div class="coupon-con">
                        <el-input-number v-model="value4" :min="0"></el-input-number>
                    </div>
                </div>
            </el-col>
        </el-row>
        <el-divider></el-divider>

        <el-row :gutter="20">
            <el-col :span="2">
                <span style="font-size: 18px;font-weight: bold;">总计费用</span>
            </el-col>
            <el-col :span="22">
                <div style="margin-bottom: 20px;">
                    <span style="font-size: 20px;font-weight: bold;color: #EC7955;">{{total}}元</span>
                </div>
                <el-button style="width: 200px;" type="primary" @click="onClick">立即购买</el-button>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import {nativePay, productList} from "../../../api/basicServices";
    import {passPeople} from "../../../api/unattended";

    export default {
        name: "purchasePackage",
        data() {
            return {
                num1: "",
                num2: "",
                num3: "",
                num4: "",
                value1: "",
                value2: "",
                value3: "",
                value4: "",
                year: "",
                month: "",
                day: "",
                voiceOrderParam: [],
                // total: "",
            }
        },
        computed: {
            total: function () {
                return (this.value1 * this.num1.price) + (this.value2 * this.num2.price) +
                    (this.value3 * this.num3.price) + (this.value4 * this.num4.price)
            }
        },
        mounted() {
            this.getProductList();
            var date = new Date();
            this.year = date.getFullYear() + 1;// 减一是算去年时间，+ 1 是明年时间
            this.month = date.getMonth();
            this.day = date.getDate();
        },
        methods: {
            getProductList() {
                productList().then((res) => {
                    if (res.data.code == 'SUCCESS') {
                        this.num1 = res.data.data[0];
                        this.num2 = res.data.data[1];
                        this.num3 = res.data.data[2];
                        this.num4 = res.data.data[3];
                    } else {
                        this.num1 = 0;
                        this.num2 = 0;
                        this.num3 = 0;
                        this.num4 = 0;
                    }
                });
            },
            onClick() {
                this.voiceOrderParam = [];
                if (this.value1 != "") {
                    this.voiceOrderParam.push({
                        price:  this.value1 * this.num1.price,
                        type: this.num1.type,
                        number: this.value1
                    })
                }
                if (this.value2 != "") {
                    this.voiceOrderParam.push({
                        price: this.num2.price * this.value2,
                        type: this.num2.type,
                        number: this.value2
                    })
                }
                if (this.value3 != "") {
                    this.voiceOrderParam.push({
                        price: this.num3.price * this.value3,
                        type: this.num3.type,
                        number: this.value3
                    })
                }
                if (this.value4 != "") {
                    this.voiceOrderParam.push({
                        price: this.num4.price * this.value4,
                        type: this.num4.type,
                        number: this.value4
                    })
                }
                console.log(this.voiceOrderParam)
                nativePay(this.voiceOrderParam).then((res) => {
                    if (res.data.code == 'SUCCESS') {
                        this.$alert('<img width="100%" src="' + res.data.data + '"/>', {
                            dangerouslyUseHTMLString: true,
                            showConfirmButton: false,
                            closeOnClickModal: false,
                            center: true,
                            customClass: 'msgbox',
                            title: "语音对讲套餐包支付二维码",
                            callback: action => {
                                // this.$message({
                                //     type: 'info',
                                //     message: `action: ${action}`
                                // });
                                this.onClose();
                            }
                        }).catch(() => {
                        });
                    }
                })
            },
            onClose() {
                this.$confirm('是否支付完成?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$router.push("voiceIntercom")
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
            }
        }
    }
</script>

<style scoped>
    .coupon {
        display: inline-block;
        overflow: hidden;
        width: 100%;
    }

    .coupon-left {
        float: left;
        height: 100px;
        width: 45%;
        padding: 0 15px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border: 1px solid #d3d3d3;
        border-right: none;
        border-radius: 0 10px 10px 0px;
    }

    .coupon-item {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .coupon-num {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .item-num {
        font-size: 20px;
        font-weight: bold;
    }

    .price {
        color: #EC7955;
        font-size: 16px;
        font-weight: bold;
    }

    .coupon-con {
        float: left;
        width: 20%;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #d3d3d3;
        border-left: none;
        border-radius: 10px 0 0 10px;
    }

    .split-line {
        float: left;
        height: 90px;
        margin-top: 5px;
        content: "";
        border-right: 2px dashed #d3d3d3;
    }

</style>
