<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>增值服务</el-breadcrumb-item>
            <el-breadcrumb-item>续费管理</el-breadcrumb-item>
            <el-breadcrumb-item>续费</el-breadcrumb-item>
        </el-breadcrumb>
        <div style="margin: 20px 0;line-height: 70px;font-size: 16px;">
            <h1>模板消息</h1>
            <div>
                <span>当前到期时间：</span>
                <span style="color: #ff5722">{{startTime}}</span>
            </div>
            <div>
                <span>计价单位：</span>
                <el-select @change="handleChange" size="small" v-model="billingUnit" placeholder="请选择计价单位">
                    <el-option v-for="item in billingUnitList" :key="item.billingUnit"
                               :label="item.billingUnit"
                               :value="item.billingUnit"></el-option>
                </el-select>
            </div>
            <div>
                <span>购买数量：</span>
                <el-input @input="handleInput" style="width: 16%;" v-model="buyCount" clearable size="small"
                          placeholder="请输入购买数量" oninput="value=value.replace(/[^\d]/g,'')"/>
            </div>
            <div>
                <span>到期时间：</span>
                <span style="color: #ff5722">{{endTime}}</span>
            </div>
            <el-divider></el-divider>
            <el-row :gutter="20">
                <el-col :span="4">
                    <span style="font-size: 18px;font-weight: bold;">总计费用</span>
                    <el-button style="width: 200px;" type="primary" @click="handleClick">立即购买</el-button>
                </el-col>
                <el-col :span="20">
                    <div style="margin-bottom: 20px;">
                        <span style="font-size: 20px;font-weight: bold;color: #EC7955;">{{total}}元</span>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
    import {adjustableList, instanceRenewal, priceList} from "../../../api/basicServices";
    import {formatTime} from '@/utils/index.js'

    export default {
        name: "addRenewal",
        data() {
            return {
                billingUnit: "",
                buyCount: "",
                funList: [],
                billingUnitList: [],
                endTime: "",
                startTime: "",
                price: "",
            }
        },
        mounted() {
            this.getAdjustableList();
            this.billingUnit = this.$route.query.billingUnit;
            this.startTime = this.$route.query.endTime ? this.$route.query.endTime : formatTime(new Date(), 'yyyy-MM-dd') + ' ' + '23:59:59';
        },
        computed: {
            total: function () {
                return this.buyCount * this.price;
            }
        },
        methods: {
            getAdjustableList() {
                adjustableList().then(res => {
                    this.funList = res.data.data;
                    let billingList = this.funList.filter(item => item.billingObjectCode == this.$route.query.billingObjectCode)
                    billingList.forEach(item => {
                        this.billingUnitList = item.billingUnitList;
                    })
                })
            },
            handleChange(e) {
                let param = {
                    funcResCode: this.$route.query.billingObjectCode,
                    billingUnit: e
                }
                priceList(param).then(res => {
                    this.price = res.data.data.price;
                })
            },
            handleInput(e) {
                if (this.billingUnit == '月') {
                    this.endTime = this.dayjs(this.startTime).add(30 * e, 'day').format("YYYY-MM-DD HH:mm:ss");
                }
            },
            handleClick() {
                let param = {
                    funcModuleCode: this.$route.query.billingObjectCode,
                    billingUnit: this.billingUnit,
                    buyCount: this.buyCount,
                    endTime: this.endTime
                }
                instanceRenewal(param).then(res => {
                    if (res.data.code == 'SUCCESS') {
                        this.$alert('<img width="100%" src="' + res.data.data + '"/>', {
                            dangerouslyUseHTMLString: true,
                            showConfirmButton: false,
                            closeOnClickModal: false,
                            center: true,
                            customClass: 'msgbox',
                            title: "语音对讲套餐包支付二维码",
                            callback: action => {
                                // this.$message({
                                //     type: 'info',
                                //     message: `action: ${action}`
                                // });
                                this.onClose();
                            }
                        }).catch(() => {
                        });
                    }
                })
            },
            onClose() {
                this.$confirm('是否支付完成?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$router.push("renewalManagement")
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
            }
        }
    }
</script>

<style scoped>

</style>
