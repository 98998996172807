<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>访客管理</el-breadcrumb-item>
            <el-breadcrumb-item>访客列表</el-breadcrumb-item>
            <el-breadcrumb-item>{{$route.query.title}}</el-breadcrumb-item>
        </el-breadcrumb>
        <el-form :model="visibleForm" ref="visibleForm" size="small" :rules="rules" label-width="130px"
                 class="user-search">
            <el-row>
                <el-col :span="11">
                    <el-form-item label="姓名" prop="visitorName">
                        <el-input disabled size="small" v-model="visibleForm.visitorName"
                                  placeholder="请输入内容"/>
                    </el-form-item>
                    <el-form-item label="手机号" prop="visitorPhone">
                        <el-input disabled size="small" v-model="visibleForm.visitorPhone"
                                  placeholder="请输入内容"/>
                    </el-form-item>
                    <el-form-item label="证件号码" prop="visitorIdCard">
                        <el-input disabled size="small" v-model="visibleForm.visitorIdCard"
                                  placeholder="请输入内容"/>
                    </el-form-item>
                    <el-form-item v-if="proType1 == 1" label="车牌号码" prop="visitorLicensePlate">
                        <el-input disabled size="small" v-model="visibleForm.visitorLicensePlate"
                                  placeholder="请输入内容"/>
                    </el-form-item>
                    <el-form-item v-if="proType2 == 2" label="门禁卡号" prop="passCardNum">
                        <el-input size="small" v-model="visibleForm.passCardNum"
                                  oninput="value=value.replace(/[^\d]/g,'')"
                                  placeholder="请输入内容"/>
                    </el-form-item>
                    <el-form-item label="携带物品" prop="carryItem">
                        <el-input size="small" v-model="visibleForm.carryItem"
                                  placeholder="请输入内容"/>
                    </el-form-item>
                    <el-form-item v-if="proType2 == 2" label="头像" prop="visitorAvatar">
                        <img v-if="visibleForm.visitorAvatar" :src="visibleForm.visitorAvatar" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-form-item>
                </el-col>
                <el-col :span="11">
                    <el-form-item label="被访人" prop="visitee">
                        <el-select @change="handleSelectChange" @focus="handleFocus" style="width: 100%" filterable
                                   v-model="visitee" :filter-method="handleUserList" placeholder="请选择">
                            <el-option
                                    v-for="(item, index) in userInfoList"
                                    :key="index"
                                    :label="item.name+' | '+ item.department +' | '+ item.phone"
                                    :value="{value:item.name,label:item.userNo}">
                                <div style="display: flex;justify-content: space-around">
                                    <span>{{ item.name }}</span>
                                    <span>{{ item.department }}</span>
                                    <span>{{ item.phone }}</span>
                                </div>
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="来访事由" prop="visitReason">
                        <el-select disabled clearable style="width: 100%"
                                   v-model="visibleForm.visitReason"
                                   filterable
                                   allow-create
                                   default-first-option
                                   placeholder="请输入内容">
                            <el-option
                                    v-for="item in options"
                                    :key="item"
                                    :label="item"
                                    :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="随访人数" prop="followerCnt">
                        <el-input disabled size="small" v-model="visibleForm.followerCnt"
                                  placeholder="请输入内容"/>
                    </el-form-item>
                    <el-form-item label="来访时间" prop="date">
                        <el-date-picker
                                        style="width: 100%"
                                        size="small"
                                        v-model="date"
                                        type="datetimerange"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        value-format="yyyy-MM-dd HH:mm:ss">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="拒绝理由" prop="rejectReason">
                        <el-input size="small" type="textarea" v-model="visibleForm.rejectReason"></el-input>
                    </el-form-item>
                    <el-form-item v-if="proType2 == 2" label="门禁授权" prop="name">
                        <el-input
                                placeholder="输入关键字进行过滤"
                                v-model="filterText">
                        </el-input>
                        <div style="display:flex;justify-content: space-between;">
                            <el-tree :data="treeData" :highlight-current=true ref="tree" node-key="id"
                                     default-expand-all :props="defaultProps" show-checkbox
                                     @check-change="handleCheckClick" :filter-node-method="filterNode">
                            </el-tree>
                            <div>
                                <el-button @click="getCurrentProjectList" size="small" type="success">使用缺省通道设置
                                </el-button>
                            </div>
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit('visibleForm',true)">通 过</el-button>
                    <el-button type="warning" @click="onSubmit('visibleForm',false)">拒 绝</el-button>
                    <el-button @click="cancel">取 消</el-button>
                </el-form-item>
            </el-row>
        </el-form>
    </div>
</template>

<script>
    import {getCurrentProject} from "../../../api/login";
    import {getTreeData} from "../../../api/spaceApi";
    import {examineVisitor, queryUserList} from "../../../api/visitorMachine";
    import {uploadFile} from "../../../api/organizationApi";

    export default {
        name: "auditVisitors",
        data() {
            return {
                visibleForm: {
                    visitorName: "",
                    visitorPhone: "",
                    visitorLicensePlate: "",
                    visitorAvatar: "",
                    visitReason: "",
                    passCardNum: "",
                    doorwayIds: "",
                    startTime: "",
                    endTime: "",
                    projectId: "",
                    visiteeName: "",
                    visiteeNo: "",
                    id: "",
                    rejectReason: "",
                    visitorIdCard: "",
                    followerCnt: "",
                    carryItem: "",
                },
                visitee: "",
                date: [],
                options: ['拜访', '送货', '维护'],
                rules: {},
                defaultProps: {
                    children: 'subList',
                    label: 'name'
                },
                treeData: [],
                projectType: [],
                proType1: "",
                proType2: "",
                visitorDoorwayIds: [],
                userInfoList: [],
                filterText: '',
            }
        },
        watch: {
            filterText(val) {
                this.$refs.tree.filter(val);
            }
        },
        mounted() {
            this.proType1 = localStorage.getItem('projectType1');
            this.proType2 = localStorage.getItem('projectType2');
            this.handleTreeData();
            let dataInfo = JSON.parse(decodeURI(this.$route.query.data));
            if (dataInfo) {
                Object.keys(this.visibleForm).forEach((n) => {
                    this.$set(this.visibleForm, n, dataInfo[n]);
                });
                this.visitee = this.visibleForm.visiteeName;
                this.date = [this.visibleForm.startTime, this.visibleForm.endTime];
                let doorwayIds = this.visibleForm.doorwayIds;
                if (doorwayIds) {
                    let arr = doorwayIds.split(',');
                    this.$nextTick(() => {
                        this.$refs.tree.setCheckedKeys(arr.map(Number));
                    });
                }
            } else {
                Object.keys(this.visibleForm).forEach((n) => {
                    let defaultStr = "";
                    this.$set(this.visibleForm, n, defaultStr);
                });
            }
        },
        methods: {
            getCurrentProjectList() {
                getCurrentProject().then((res) => {
                        let visitorDoorwayIds = res.data.data.visitorDoorwayIds;
                        if (visitorDoorwayIds) {
                            let arr = visitorDoorwayIds.split(',');
                            this.$nextTick(() => {
                                this.$refs.tree.setCheckedKeys(arr.map(Number));
                            });
                        }
                    }
                )
            },
            handleTreeData() {
                getTreeData().then((res) => {
                    this.treeData = res.data.data;
                }).catch(error => {
                    console.log(error);
                });
            },
            filterNode(value, data) {
                if (!value) return true;
                return data.name.indexOf(value) !== -1;
            },
            handleCheckClick(data) {
                let visitorDoorwayInfo = this.$refs.tree.getCheckedNodes(true);
                this.visitorDoorwayIds = [];
                visitorDoorwayInfo.forEach(element => {
                    if (element.type === 2) {
                        this.visitorDoorwayIds.push(element.id);
                    }
                });
                this.visibleForm.doorwayIds = this.visitorDoorwayIds.toString();
            },
            remoteMethod(query) {
                if (query !== '') {
                    let param = {
                        projectId: localStorage.getItem("projectId"),
                        name: query,
                        fuzzyMatch: true
                    }
                    queryUserList(param).then((res) => {
                        this.userInfoList = res.data.data;
                    }).catch(error => {
                        console.log(error);
                    });
                } else {
                    this.userInfoList = [];
                }
            },
            handleFocus() {
                this.handleUserList(this.visitee);
            },
            handleUserList(value) {
                let param = {
                    projectId: localStorage.getItem("projectId"),
                    name: value,
                    fuzzyMatch: true
                }
                queryUserList(param).then((res) => {
                    this.userInfoList = res.data.data;
                }).catch(error => {
                    console.log(error);
                });
            },
            handleSelectChange(item) {
                const {value, label} = item;
                this.visibleForm.visiteeName = value;
                this.visibleForm.visiteeNo = label;
            },
            onSubmit(visibleForm, value) {
                this.$refs[visibleForm].validate((valid) => {
                    if (valid) {
                        let param = {
                            visitorName: this.visibleForm.visitorName,
                            visitorPhone: this.visibleForm.visitorPhone,
                            visitorLicensePlate: this.visibleForm.visitorLicensePlate,
                            visitorAvatar: this.visibleForm.visitorAvatar,
                            visiteeName: this.visibleForm.visiteeName,
                            visiteeNo: this.visibleForm.visiteeNo,
                            visitReason: this.visibleForm.visitReason,
                            passCardNum: this.visibleForm.passCardNum,
                            doorwayIds: this.visibleForm.doorwayIds,
                            startTime: this.date && this.date.length ? this.date[0] : "",
                            endTime: this.date && this.date.length ? this.date[1] : "",
                            projectId: localStorage.getItem("projectId"),
                            id: this.visibleForm.id,
                            rejectReason: this.visibleForm.rejectReason,
                            visitorIdCard: this.visibleForm.visitorIdCard,
                            followerCnt: this.visibleForm.followerCnt,
                            carryItem: this.visibleForm.carryItem,
                            approve: value
                        }
                        examineVisitor(param).then((res) => {
                            if (res.data.code == "SUCCESS") {
                                this.$router.push('visitorManagement');
                                this.$message.success(res.data.message);
                            } else {
                                this.$message.info(res.data.message);
                            }
                        })
                    }
                })
            },
            cancel() {
                this.$router.push('visitorManagement');
            },
        }
    }
</script>

<style scoped>
    .user-search {
        margin-top: 20px;
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>
