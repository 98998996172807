import Vue from 'vue'
import VueRouter from 'vue-router'
import {Message, MessageBox} from 'element-ui'
import TopNav from '@/components/nav/topNav.vue'
import LeftNav from '@/components/nav/leftNav.vue'
import Home from '@/views/Home.vue'
import Content from '@/views/Content.vue'
// import Login from '../views/login.vue'
import NotFound from '@/components/404.vue'
import dashboard from '@/components/dashboard.vue'

import EnterpriseIndex from '@/views/enterprise/index.vue'
import parkingLotIndex from '@/views/parkingLot/index.vue'
import personnelIndex from '@/views/personnel/index.vue'
import homePage from '@/views/parkingLot/homePage.vue'
import parkingSmartScreen from '@/views/parkingSmartScreen.vue'
import personLargeDataScreen from '@/views/personLargeDataScreen.vue'
import radarSmartScreen from '@/views/radarSmartScreen.vue'
import monitorPreview from '@/views/monitorPreview.vue'

import personalCenter from '@/views/enterprise/system/personalCenter.vue'
import userManagement from '@/views/enterprise/system/userManagement.vue'
import roleManagement from '@/views/enterprise/system/roleManagement.vue'
import toolDownload from '@/views/enterprise/system/toolDownload.vue'
import test from '@/views/enterprise/system/test.vue'
import routingEquipment from '@/views/enterprise/system/routingEquipment.vue'

import voiceIntercom from '@/views/enterprise/basicServices/voiceIntercom.vue'
import purchasePackage from '@/views/enterprise/basicServices/purchasePackage.vue'
import applicationList from '@/views/enterprise/basicServices/applicationList.vue'
import renewalManagement from '@/views/enterprise/basicServices/renewalManagement.vue'
import renewalRecord from '@/views/enterprise/basicServices/renewalRecord.vue'
import addRenewal from '@/views/enterprise/basicServices/addRenewal.vue'
import visitorManagement from '@/views/enterprise/visitor/visitorManagement.vue'
import addVisitors from '@/views/enterprise/visitor/addVisitors.vue'
import auditVisitors from '@/views/enterprise/visitor/auditVisitors.vue'
import visitorMachineList from '@/views/enterprise/visitor/visitorMachineList.vue'
import visitorSettings from '@/views/enterprise/visitor/visitorSettings.vue'

import vehiclePassingRecord from '@/views/parkingLot/recordQuery/vehiclePassingRecord.vue'
import onSiteVehicles from '@/views/parkingLot/recordQuery/onSiteVehicles.vue'
import temporaryPaymentRecord from '@/views/parkingLot/recordQuery/temporaryPaymentRecord.vue'
import longRentPaymentRecord from '@/views/parkingLot/recordQuery/longRentPaymentRecord.vue'
import exceptionHandling from '@/views/parkingLot/recordQuery/exceptionHandling.vue'
import vehicleParkingRecord from '@/views/parkingLot/recordQuery/vehicleParkingRecord.vue'

import merchantManagement from '@/views/parkingLot/merchantDiscount/merchantManagement.vue'
import discountTemplate from '@/views/parkingLot/merchantDiscount/discountTemplate.vue'
import preferentialManagement from '@/views/parkingLot/merchantDiscount/preferentialManagement.vue'
import preferentialStatistics from '@/views/parkingLot/merchantDiscount/preferentialStatistics.vue'

import incomeStatistics from '@/views/parkingLot/statisticalAnalysis/incomeStatistics.vue'
import flowAnalysis from '@/views/parkingLot/statisticalAnalysis/flowAnalysis.vue'
import temporaryStop from '@/views/parkingLot/statisticalAnalysis/temporaryStop.vue'
import longRent from '@/views/parkingLot/statisticalAnalysis/longRent.vue'
import coupon from '@/views/parkingLot/statisticalAnalysis/coupon.vue'

import longTermRentRate from '@/views/parkingLot/vehicleManagement/longTermRentRate.vue'
import editVehicle from '@/views/parkingLot/vehicleManagement/editVehicle.vue'
import addVehicle from '@/views/parkingLot/vehicleManagement/addVehicle.vue'
import blacklistList from '@/views/parkingLot/vehicleManagement/blacklistList.vue'
import memberCar from '@/views/parkingLot/vehicleManagement/memberCar.vue'
import valueStorage from '@/views/parkingLot/vehicleManagement/valueStorage.vue'

import unattended from '@/views/parkingLot/yardManagement/unattended.vue'
import parkingSettings from '@/views/parkingLot/yardManagement/parkingSettings.vue'
import lcdAdvertisingMaterial from '@/views/parkingLot/yardManagement/lcdAdvertisingMaterial.vue'
import lcdAdvertisingSettings from '@/views/parkingLot/yardManagement/lcdAdvertisingSettings.vue'
import lcdAdvertisingProgram from '@/views/parkingLot/yardManagement/lcdAdvertisingProgram.vue'
import centralCharge from '@/views/parkingLot/yardManagement/centralCharge.vue'


import indexPage from '@/views/personnel/indexPage.vue'

import spaceManagement from '@/views/personnel/humanDecency/spaceManagement.vue'
import deviceManagement from '@/views/personnel/humanDecency/deviceManagement.vue'
import accessAuthorization from '@/views/personnel/humanDecency/accessAuthorization.vue'
import personnelPassageRecord from '@/views/personnel/humanDecency/personnelPassageRecord.vue'

import organizationManagement from '@/views/personnel/management/organizationManagement.vue'
import userRequestVerify from '@/views/personnel/management/userRequestVerify.vue'
import personnelBlacklist from '@/views/personnel/management/personnelBlacklist.vue'

import deviceManagements from '@/views/personnel/temperature/deviceManagement.vue'
import measureTRecord from '@/views/personnel/temperature/measureTRecord.vue'

import speedMainHome from '@/views/radar/speedMainHome.vue'
import positionManagement from '@/views/radar/positionManagement.vue'
import speedMeasurementRecord from '@/views/radar/speedMeasurementRecord.vue'
import radarSettings from '@/views/radar/radarSettings.vue'
import device from '@/views/radar/deviceManagement.vue'
// 懒加载方式，当路由被访问的时候才加载对应组件
const Login = resolve => require(['@/views/login'], resolve);

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: 'login'
    },
    {
        path: '/login',
        name: '登录',
        component: Login,
    },
    {
        path: '/open/auth',
        name: '跳转',
        component: dashboard,
    },
    {
        path: '/parkingSmartScreen',
        name: 'parkingSmartScreen',
        component: parkingSmartScreen,
    },
    {
        path: '/personLargeDataScreen',
        name: 'personLargeDataScreen',
        component: personLargeDataScreen,
    },
    {
        path: '/speedMain/smartScreen',
        name: 'radarSmartScreen',
        component: radarSmartScreen,
    },
    {
        path: '/monitorPreview',
        name: 'monitorPreview',
        component: monitorPreview,
    },
    {
        path: '*',
        component: NotFound
    },
    {
        path: '/enterpriseManager',
        type: 'home',
        name: 'enterprise',
        component: Home,
        // redirect: '/enterprise/visitorManagement',
        redirect: to => {
            if (sessionStorage.getItem('outside')) {
                //业务系统
                return '/enterprise/visitorManagement'
            } else {
                return '/enterprise/personalCenter'
            }
        },
        menuShow: true,
        children: [
            {
                path: '/enterprise',
                components: {
                    default: EnterpriseIndex,
                    top: TopNav,
                    aside: LeftNav
                },
                name: '系统管理',
                iconCls: 'el-icon-setting',
                menuShow: true,
                children: [
                    {path: '/enterprise/personalCenter', component: personalCenter, name: '个人中心', menuShow: true},
                    {path: '/enterprise/userManagement', component: userManagement, name: '用户管理', menuShow: true},
                    {path: '/enterprise/roleManagement', component: roleManagement, name: '角色管理', menuShow: true},
                    {path: '/enterprise/toolDownload', component: toolDownload, name: '工具文件下载', menuShow: true},
                    {path: '/enterprise/routingEquipment', component: routingEquipment, name: '路由设备', menuShow: true}
                ]
            },
            {
                path: '/enterprise',
                components: {
                    default: EnterpriseIndex,
                    top: TopNav,
                    aside: LeftNav
                },
                name: '基础服务',
                iconCls: 'el-icon-service',
                menuShow: true,
                children: [
                    {path: '/enterprise/voiceIntercom', component: voiceIntercom, name: '语音对讲', menuShow: true},
                    {path: '/enterprise/purchasePackage', component: purchasePackage, name: '购买套餐包', menuShow: false},
                    {path: '/enterprise/applicationList', component: applicationList, name: '应用列表', menuShow: true},
                    {path: '/enterprise/nesting', component: test, name: '', menuShow: false},
                    {path: '/enterprise/renewalManagement', component: renewalManagement, name: '续费管理', menuShow: true},
                    {path: '/enterprise/addRenewal', component: addRenewal, name: '续费', menuShow: false},
                    {path: '/enterprise/renewalRecord', component: renewalRecord, name: '续费记录', menuShow: true},
                ]
            },
            {
                path: '/enterprise',
                components: {
                    default: EnterpriseIndex,
                    top: TopNav,
                    aside: LeftNav
                },
                name: '访客管理',
                iconCls: 'el-icon-service',
                menuShow: true,
                children: [
                    {path: '/enterprise/visitorManagement', component: visitorManagement, name: '访客列表', menuShow: true},
                    {
                        path: '/enterprise/visitorMachineList',
                        component: visitorMachineList,
                        name: '访客机列表',
                        menuShow: true
                    },
                    {
                        path: '/enterprise/visitorSettings',
                        component: visitorSettings,
                        name: '访客设置',
                        menuShow: true
                    },
                    {path: '/enterprise/addVisitors', component: addVisitors, name: '添加', menuShow: false},
                    {path: '/enterprise/auditVisitors', component: auditVisitors, name: '审核', menuShow: false},
                ]
            },
        ]
    },
    {
        path: '/parkingLotManager',
        type: 'home',
        name: 'parkingLot',
        component: Home,
        redirect: '/parkingLot/homePage',
        menuShow: true,
        children: [
            {
                path: '/parkingLot/homePage',
                name: '首页',
                components: {
                    default: homePage,
                    top: TopNav,
                    aside: LeftNav
                },
                leaf: true, // 只有一个节点
                iconCls: 'iconfont icon-home', // 图标样式class
                menuShow: true,
            },
            {
                path: '/parkingLot',
                components: {
                    default: parkingLotIndex,
                    top: TopNav,
                    aside: LeftNav
                },
                name: '车辆管理',
                iconCls: 'el-icon-truck',
                menuShow: true,
                children: [
                    {path: '/parkingLot/longTermRentRate', component: longTermRentRate, name: '长租车辆', menuShow: true},
                    {path: '/parkingLot/blacklistList', component: blacklistList, name: '黑名单', menuShow: true},
                    {path: '/parkingLot/memberCar', component: memberCar, name: '会员车管理', menuShow: true},
                    {path: '/parkingLot/valueStorage', component: valueStorage, name: '储值车管理', menuShow: true},
                    {path: '/parkingLot/addVehicle', component: addVehicle, name: '添加车辆', menuShow: false},
                    {path: '/parkingLot/editVehicle', component: editVehicle, name: '编辑车辆', menuShow: false},
                ]
            },
            {
                path: '/parkingLot',
                components: {
                    default: parkingLotIndex,
                    top: TopNav,
                    aside: LeftNav
                },
                name: '车场管理',
                iconCls: 'el-icon-office-building',
                menuShow: true,
                children: [
                    {path: '/parkingLot/unattended', component: unattended, name: '无人值守', menuShow: false},
                    {
                        path: '/parkingLot/parkingSettings', component: parkingSettings, name: '车场设置',
                        menuShow: true
                    },
                    {
                        path: '/parkingLot/lcdAdvertisingMaterial', component: lcdAdvertisingMaterial, name: '液晶广告素材',
                        menuShow: true
                    },
                    {
                        path: '/parkingLot/lcdAdvertisingProgram', component: lcdAdvertisingProgram, name: '液晶广告节目',
                        menuShow: true
                    },
                    {
                        path: '/parkingLot/lcdAdvertisingSettings', component: lcdAdvertisingSettings, name: '液晶广告设置',
                        menuShow: true
                    },
                    {path: '/parkingLot/centralCharge', component: centralCharge, name: '中央缴费', menuShow: true}
                ]
            },
            {
                path: '/parkingLot',
                components: {
                    default: parkingLotIndex,
                    top: TopNav,
                    aside: LeftNav
                },
                name: '商家优惠',
                iconCls: 'el-icon-s-custom',
                menuShow: true,
                children: [
                    {
                        path: '/parkingLot/merchantManagement', component: merchantManagement, name: '商家管理',
                        menuShow: true
                    },
                    {path: '/parkingLot/discountTemplate', component: discountTemplate, name: '优惠模板', menuShow: true},
                    {
                        path: '/parkingLot/preferentialManagement', component: preferentialManagement, name: '优惠管理',
                        menuShow: true
                    },
                    {
                        path: '/parkingLot/preferentialStatistics', component: preferentialStatistics, name: '优惠统计',
                        menuShow: true
                    }
                ]
            },
            {
                path: '/parkingLot',
                components: {
                    default: parkingLotIndex,
                    top: TopNav,
                    aside: LeftNav
                },
                name: '记录查询',
                iconCls: 'el-icon-tickets',
                menuShow: true,
                children: [
                    {
                        path: '/parkingLot/vehicleParkingRecord', component: vehicleParkingRecord, name: '车辆停车记录',
                        menuShow: true
                    },
                    {
                        path: '/parkingLot/vehiclePassingRecord', component: vehiclePassingRecord, name: '车辆通行记录',
                        menuShow: true
                    },
                    {path: '/parkingLot/onSiteVehicles', component: onSiteVehicles, name: '在场车辆', menuShow: true},
                    {
                        path: '/parkingLot/temporaryPaymentRecord', component: temporaryPaymentRecord, name: '临时车缴费记录',
                        menuShow: true
                    },
                    {
                        path: '/parkingLot/longRentPaymentRecord', component: longRentPaymentRecord, name: '长租车缴费记录',
                        menuShow: true
                    },
                    {
                        path: '/parkingLot/exceptionHandling', component: exceptionHandling, name: '车场异常处理记录',
                        menuShow: true
                    },
                ]
            },
            {
                path: '/parkingLot',
                name: '统计分析',
                components: {
                    default: parkingLotIndex,
                    top: TopNav,
                    aside: LeftNav
                },
                iconCls: 'el-icon-s-data',
                menuShow: true,
                children: [
                    {path: '/parkingLot/incomeStatistics', component: incomeStatistics, name: '收入统计', menuShow: true},
                    {path: '/parkingLot/flowAnalysis', component: flowAnalysis, name: '进出流量分析', menuShow: true},
                    {path: '/parkingLot/temporaryStop', component: temporaryStop, name: '临停', menuShow: false},
                    {path: '/parkingLot/longRent', component: longRent, name: '长租', menuShow: false},
                    {path: '/parkingLot/coupon', component: coupon, name: '优惠券', menuShow: false},
                ]
            },
        ]
    },
    {
        path: '/personnelManager',
        type: 'home',
        name: 'personnel',
        component: Home,
        redirect: '/personnel/indexPage',
        menuShow: true,
        children: [
            {
                path: '/personnel/indexPage',
                name: 'indexPage',
                components: {
                    default: indexPage,
                    top: TopNav,
                    aside: LeftNav
                },
                leaf: true, // 只有一个节点
                iconCls: 'iconfont icon-home', // 图标样式class
                menuShow: true,
            },
            {
                path: '/personnel',
                components: {
                    default: personnelIndex,
                    top: TopNav,
                    aside: LeftNav
                },
                name: '人行管理',
                iconCls: 'el-icon-setting',
                menuShow: true,
                children: [
                    {path: '/personnel/spaceManagement', component: spaceManagement, name: '空间管理', menuShow: true},
                    {path: '/personnel/deviceManagement', component: deviceManagement, name: '设备管理', menuShow: true},
                    {
                        path: '/personnel/accessAuthorization',
                        component: accessAuthorization,
                        name: '通行授权',
                        menuShow: true
                    },
                    {
                        path: '/personnel/personnelPassageRecord',
                        component: personnelPassageRecord,
                        name: '人员通行记录',
                        menuShow: true
                    },
                    {
                        path: '/personnel/deviceMeasureT', component: deviceManagements, name: '测温门管理', menuShow: true
                    },
                    {
                        path: '/personnel/measureTRecord', name: '测温记录', component: measureTRecord, menuShow: true,
                    },
                ]
            },
            {
                path: '/personnel',
                components: {
                    default: personnelIndex,
                    top: TopNav,
                    aside: LeftNav
                },
                name: '人员管理',
                iconCls: 'el-icon-setting',
                menuShow: true,
                children: [
                    {
                        path: '/personnel/organizationManagement',
                        component: organizationManagement,
                        name: '组织管理',
                        menuShow: true
                    },
                    {
                        path: '/personnel/userRequestVerify',
                        component: userRequestVerify,
                        name: '固定人员信息',
                        menuShow: true
                    },
                    {
                        path: '/personnel/personnelBlacklist',
                        component: personnelBlacklist,
                        name: '人员黑名单',
                        menuShow: true
                    },
                ]
            }
        ]
    },
    {
        path: '/speedMainManager',
        type: 'home',
        name: 'speedMain',
        component: Home,
        redirect: '/speedMain/home',
        menuShow: true,
        children: [
            {
                path: '/speedMain/home',
                name: 'home',
                components: {
                    default: speedMainHome,
                    top: TopNav,
                    aside: LeftNav
                },
                leaf: true, // 只有一个节点
                iconCls: 'iconfont icon-home', // 图标样式class
                menuShow: true,
            },
            {
                path: '/speedMain/position',
                name: 'position',
                components: {
                    default: positionManagement,
                    top: TopNav,
                    aside: LeftNav
                },
                leaf: true, // 只有一个节点
                iconCls: 'iconfont icon-home', // 图标样式class
                menuShow: true,
            },
            {
                path: '/speedMain/record',
                name: 'record',
                components: {
                    default: speedMeasurementRecord,
                    top: TopNav,
                    aside: LeftNav
                },
                leaf: true, // 只有一个节点
                iconCls: 'iconfont icon-home', // 图标样式class
                menuShow: true,
            },
            {
                path: '/speedMain/radarSettings',
                name: 'radarSettings',
                components: {
                    default: radarSettings,
                    top: TopNav,
                    aside: LeftNav
                },
                leaf: true, // 只有一个节点
                iconCls: 'iconfont icon-home', // 图标样式class
                menuShow: false,
            },
            {
                path: '/speedMain/device',
                name: 'device',
                components: {
                    default: device,
                    top: TopNav,
                    aside: LeftNav
                },
                leaf: true, // 只有一个节点
                iconCls: 'iconfont icon-home', // 图标样式class
                menuShow: false,
            }
        ]
    },
    {
        path: '/contentManager',
        type: 'content',
        name: 'content',
        component: Content,
        redirect: '/content/parkingSettings',
        menuShow: true,
        children: [
            {
                path: '/content/parkingSettings',
                components: {
                    default: parkingSettings,
                    top: TopNav,
                },
                name: '设置',
                iconCls: 'el-icon-s-operation',
                menuShow: true,
                leaf: true, // 只有一个节点
                children: []
            },
            {
                path: '/content/roleManagement',
                components: {
                    default: roleManagement,
                    top: TopNav,
                },
                name: '角色',
                iconCls: 'el-icon-s-operation',
                menuShow: true,
                leaf: true, // 只有一个节点
                children: []
            },
            {
                path: '/content/userManagement',
                components: {
                    default: userManagement,
                    top: TopNav,
                },
                name: '用户',
                iconCls: 'el-icon-s-operation',
                menuShow: true,
                leaf: true, // 只有一个节点
                children: []
            },
            {
                path: '/content/deviceManagement',
                components: {
                    default: deviceManagement,
                    top: TopNav,
                },
                name: '设备',
                iconCls: 'el-icon-s-operation',
                menuShow: true,
                leaf: true, // 只有一个节点
                children: []
            },
            {
                path: '/content/spaceManagement',
                components: {
                    default: spaceManagement,
                    top: TopNav,
                },
                name: '空间',
                iconCls: 'el-icon-s-operation',
                menuShow: true,
                leaf: true, // 只有一个节点
                children: []
            },
            {
                path: '/content/organizationManagement',
                components: {
                    default: organizationManagement,
                    top: TopNav,
                },
                name: '人员',
                iconCls: 'el-icon-s-operation',
                menuShow: true,
                leaf: true, // 只有一个节点
                children: []
            },
            {
                path: '/content/accessAuthorization',
                components: {
                    default: accessAuthorization,
                    top: TopNav,
                },
                name: '授权',
                iconCls: 'el-icon-s-operation',
                menuShow: true,
                leaf: true, // 只有一个节点
                children: []
            },
            {
                path: '/content/device',
                components: {
                    default: device,
                    top: TopNav,
                },
                name: '测速设备管理',
                iconCls: 'el-icon-s-operation',
                menuShow: true,
                leaf: true, // 只有一个节点
                children: []
            },
            {
                path: '/content/position',
                components: {
                    default: positionManagement,
                    top: TopNav,
                },
                name: '测速点管理',
                iconCls: 'el-icon-s-operation',
                menuShow: true,
                leaf: true, // 只有一个节点
                children: []
            },
        ]
    },
]

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
});
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {

    return originalPush.call(this, location).catch(err => err)

};
router.beforeEach((to, from, next) => {
    // console.log('to:' + to.path)
    if (to.path === '/login') {
        next();
    } else if (to.path === '/enterprise/personalCenter') {
        let token = localStorage.getItem('token');
        if (token === null || token === '') {
            next({path: '/login'})
        } else {
            next();
        }
    } else {
        next();
    }
    if (to.path === '/enterprise/visitorSettings' || to.path === '/enterprise/visitorMachineList') {
        let projectType = localStorage.getItem('projectType2');
        if (projectType != 2) {
            MessageBox.confirm('暂未开通人行管理，请联系渠道商开通。', '系统提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }
            ).then(() => {
                window.history.back();
            }).catch(() => {
                window.history.back();
            });
        } else {
            next();
        }
    }

});

export default router
