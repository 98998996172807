<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>车场管理</el-breadcrumb-item>
            <el-breadcrumb-item>液晶广告设置</el-breadcrumb-item>
        </el-breadcrumb>
        <el-container>
            <el-aside>
                <div style="display: flex;">
                    <el-checkbox-group @change="handleCheck" v-model="equipmentIds">
                        <el-checkbox v-for="item in options" :label="item.id" :key="item.id">
                            <br/>
                        </el-checkbox>
                    </el-checkbox-group>
                    <el-checkbox-group class="box" v-model="checkList">
                        <el-checkbox @change="changeChecked(item)" v-for="item in options" :label="item.id"
                                     :key="item.id">
                            {{item.equipmentName}}
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
            </el-aside>
            <el-container>
                <el-main>
                    <el-row :span="24" v-loading="loading" element-loading-text="拼命加载中">
                        <el-col :span="24" class="toolbar" style="padding-bottom: 0;">
                            <div class="clickBtn">
                                <el-select @change="onChange" size="small" v-model="advertisingShowId"
                                           placeholder="请选择节目">
                                    <el-option
                                            v-for="item in showList"
                                            :key="item.id"
                                            :label="item.showName"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                                <el-button style="margin: 0 10px;" icon="el-icon-plus" type="primary" size="small"
                                           @click="handleAdd">保存并下发设备
                                </el-button>
                            </div>
                        </el-col>
                        <!--列表-->
                        <el-table
                                size="small"
                                :data="tableData"
                                highlight-current-row
                                border
                                style="width: 100%"
                                :default-sort="{prop: 'passTime', order: 'descending'}"
                                :row-style="{height:'45px'}"
                                :cell-style="{padding:'0px'}"
                                :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                                stripe
                                height="500"
                                max-height="500"
                        >
                            <el-table-column type="index" label="序号"></el-table-column>
                            <el-table-column prop="materialName" label="素材名称" align="center"></el-table-column>
                            <el-table-column prop="materialType" label="素材类型" align="center">
                                <template slot-scope="scope">
                                    <el-tag type="success" v-if="scope.row.materialType == 1">图片</el-tag>
                                    <el-tag type="primary" v-if="scope.row.materialType == 2">视频</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column prop="materialUrl" label="素材" align="center">
                                <template slot-scope="scope">
                                    <el-image v-if="scope.row.materialType == 1"
                                              class="avatar-img" style="width: 178px; height: 100px" fit="contain"
                                              :src="scope.row.materialUrl?scope.row.materialUrl:require('../../../assets/images/defaultImg.png')"
                                              :preview-src-list="[scope.row.materialUrl?scope.row.materialUrl:require('../../../assets/images/defaultImg.png')]">
                                        <div slot="error">
                                            <el-image :src="require('../../../assets/images/defaultImg.png')">
                                            </el-image>
                                        </div>
                                    </el-image>
                                    <video v-if="scope.row.materialType == 2"
                                           :src="scope.row.materialUrl"
                                           class="video-avatar"
                                           controls="controls">
                                    </video>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-row>
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>

<script>
    import {peopleList} from "../../../api/unattended";
    import {advertisingEquipmentList, advertisingEquipmentSave, advertisingShowList} from "../../../api/advertisement";

    export default {
        name: "lcdAdvertisingSettings",
        data() {
            return {
                equipmentIds: [],
                checkList: [],
                loading: false,
                tableData: [],
                options: [],
                advertisingShowId: "",
                showList: [],
                current: 1,
                size: 500,
            }
        },

        mounted() {
            this.getAdvertisingEquipmentList();
            this.getAdvertisingShowList();
        },
        methods: {
            getAdvertisingEquipmentList() {
                advertisingEquipmentList().then((res) => {
                    this.options = res.data.data;
                    let dataList = res.data.data[0];
                    // this.equipmentIds.push(dataList.id);
                    this.checkList.push(dataList.id);
                    if (dataList.advertisingShowVO) {
                        this.tableData = dataList.advertisingShowVO.advertisingMaterials;
                    }
                    this.advertisingShowId = dataList.advertisingShowVO.id;
                })
            },
            handleCheck() {
                this.checkList = this.equipmentIds;
            },
            changeChecked(item) {
                this.checkList = [];
                if (item.advertisingShowVO) {
                    this.tableData = item.advertisingShowVO.advertisingMaterials;
                    this.advertisingShowId = item.advertisingShowVO.id;
                    this.checkList.push(item.id);
                } else {
                    this.tableData = [];
                }
                console.log(this.checkList)
            },
            getAdvertisingShowList() {
                let params = {
                    current: this.current,
                    size: this.size,
                };
                advertisingShowList(params).then((res) => {
                    this.showList = res.data.data.records;
                    this.total = res.data.data.total;
                })
            },
            onChange(e) {
                this.tableData = [];
                this.showList.forEach(item => {
                    if (e === item.id) {
                        this.tableData = item.advertisingMaterials
                    }
                })
            },
            handleAdd() {
                if (this.equipmentIds == undefined || this.equipmentIds.length <= 0) {
                    this.$message.info('请选择下发设备');
                    return;
                }
                let params = {
                    equipmentIds: this.equipmentIds,
                    advertisingShowId: this.advertisingShowId,
                }
                advertisingEquipmentSave(params).then((res) => {
                    if (res.data.code == "SUCCESS") {
                        this.$message.success("保存成功！");
                    } else {
                        this.$message.info(res.data.message);
                    }
                })
            }
        }

    }
</script>

<style lang="scss" scoped>
    .el-main {
        padding: unset;
    }

    .el-aside {
        background-color: #ffffff;
        color: #333;
        width: auto !important;
        /*text-align: center;*/
        line-height: 50px;
    }

    .el-checkbox:last-of-type {
        margin-right: 30px;
    }

    .clickBtn {
        margin: 10px 0px;
    }

    .el-checkbox-group {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .box {
        margin-left: -35px;
    }

    .box /deep/ .el-checkbox__inner {
        display: none;
    }

    /*.box /deep/.el-checkbox__input.is-checked+.el-checkbox__label{*/
    /*    color: #606266;*/
    /*}*/

    .video-avatar {
        width: 178px;
        height: 100px;
        margin: 0 auto;
        display: block;
    }

    avatar-img img {
        width: 178px;
        height: 100px;
    }
</style>
