import { render, staticRenderFns } from "./personalCenter.vue?vue&type=template&id=76f2f6e0&scoped=true&"
import script from "./personalCenter.vue?vue&type=script&lang=js&"
export * from "./personalCenter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76f2f6e0",
  null
  
)

export default component.exports