import request from '@/utils/request'
import config from '@/config'

// 测速点管理列表
export function getSpeedPosition(data) {
    return request({
        url: `${config.bpi}/speed/position`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}

// 添加测速点
export function addSpeedPosition(data) {
    return request({
        url: `${config.bpi}/speed/position`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 修改测速点
export function editSpeedPosition(data) {
    return request({
        url: `${config.bpi}/speed/position`,
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 删除测速点
export function delSpeedPosition(id) {
    return request({
        url: `${config.bpi}/speed/position`,
        method: 'delete',
        params: {id: id},
    })
}

//测速设备列表
export function getSpeedDevice(data) {
    return request({
        url: `${config.bpi}/speed/device/list`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}

// 添加测速设备
export function addSpeedDevice(data) {
    return request({
        url: `${config.bpi}/speed/device`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 修改测速设备
export function editSpeedPDevice(data) {
    return request({
        url: `${config.bpi}/speed/device`,
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

// 删除测速设备
export function delSpeedDevice(id) {
    return request({
        url: `${config.bpi}/speed/device`,
        method: 'delete',
        params: {id: id},
    })
}

//测速配置
export function getSpeedConfig(data) {
    return request({
        url: `${config.bpi}/speed/config`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}

//添加测速配置
export function addSpeedConfig(data, id) {
    return request({
        url: `${config.bpi}/speed/config?speedPositionId=` + id,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

//读取实时设置
export function realtimeConfig(data) {
    return request({
        url: `${config.bpi}/board/realtime/config`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}

//同步时间
export function timeSync(data) {
    return request({
        url: `${config.bpi}/board/time/sync`,
        method: 'post',
        params: data
    })
}

//测速记录
export function getSpeedRecord(data) {
    return request({
        url: `${config.bpi}/speed/record`,
        method: 'get',
        params: data,
        headers: {'showLoading': false}
    })
}

//修改详细记录
export function speedRecordEdit(data) {
    return request({
        url: `${config.bpi}/speed/record`,
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

//测试点列表数据
export function getSpeedPositionList() {
    return request({
        url: `${config.bpi}/speed/position/list`,
        method: 'get',
        headers: {'showLoading': false}
    })
}

//测速相关信息概览
export function speedStatistics() {
    return request({
        url: `${config.bpi}/speed/statistics/summary`,
        method: 'get',
        headers: {'showLoading': false}
    })
}

//实时测速信息
export function speedStatisticsRealtime() {
    return request({
        url: `${config.bpi}/speed/statistics/realtime`,
        method: 'get',
        headers: {'showLoading': false}
    })
}
