<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>车辆管理</el-breadcrumb-item>
            <el-breadcrumb-item>长租车辆</el-breadcrumb-item>
            <el-breadcrumb-item>编辑车辆</el-breadcrumb-item>
        </el-breadcrumb>
        <el-form :model="editForm" ref="editForm" size="mini" class="user-search" :rules="rules">
            <el-row>
                <el-row>
                    <h2 style="margin: 0px 50px 15px;">车主信息</h2>
                </el-row>
                <el-row>
                    <el-col :span="11">
                        <el-form-item label="车主姓名" prop="ownerName" :label-width="formLabelWidth">
                            <el-input style="width: 55%;" size="small" v-model="editForm.ownerName"
                                      placeholder="请输入内容"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11">
                        <el-form-item label="车主身份证号" prop="idCard" :label-width="formLabelWidth">
                            <el-input style="width: 55%;" size="small" v-model="editForm.idCard"
                                      placeholder="请输入内容"/>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="11">
                        <el-form-item label="车主手机" prop="ownerPhone" :label-width="formLabelWidth">
                            <el-input style="width: 55%;" size="small" oninput="value=value.replace(/[^\d]/g,'')"
                                      v-model="editForm.ownerPhone"
                                      placeholder="请输入内容"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11">
                        <el-form-item label="备注" prop="remark" :label-width="formLabelWidth">
                            <el-input style="width: 55%;" size="small" v-model="editForm.remark"
                                      placeholder="请输入内容"/>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <h2 style="float: left;margin: 0px 50px;line-height: 40px;">车辆信息</h2>
                    <div style="float: right;width: 25%;margin: 0px 0px 15px;">
                        <el-button icon="el-icon-plus" type="primary" size="small"
                                   @click="addLongCarInfo">添加
                        </el-button>
                    </div>
                </el-row>
                <el-row v-for="(item, index) in editForm.longCarInfo" :key="item.key">
                    <div style="float: right;width: 25%;margin: 0px 0px 15px;" v-if="item.id != ''">
                        <el-button icon="el-icon-delete" type="danger" size="small"
                                   @click.prevent="removeLongCarInfo(item)">
                            删除
                        </el-button>
                    </div>
                    <el-col :span="11">
                        <el-form-item label="车牌号码" prop="licensePlate" :label-width="formLabelWidth">
                            <el-input disabled style="width: 55%;" size="small"
                                      v-model.trim="item.licensePlate"
                                      placeholder="请输入内容"/>
                        </el-form-item>
                        <el-form-item label="车辆标签" prop="carType" :label-width="formLabelWidth">
                            <el-input style="width: 55%;" size="small" v-model="item.carTag"
                                      placeholder="请输入内容"/>
                        </el-form-item>
                        <el-form-item label="RFID卡号" prop="rfidNo" :label-width="formLabelWidth">
                            <el-input style="width: 55%;" size="small" v-model="item.rfidNo"
                                      placeholder="请输入内容"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11">
                        <el-form-item label="车辆属性" :prop="'longCarInfo.' + index + '.carProperty'"
                                      :label-width="formLabelWidth"
                                      :rules="[{ required: true, message: '请选择', trigger: 'change' }]">
                            <el-select disabled style="width: 55%;" size="small" v-model="item.carProperty"
                                       placeholder="请选择车辆属性">
                                <el-option label="小车" :value="1"></el-option>
                                <el-option label="大车" :value="2"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="ETC卡号" prop="etcNo" :label-width="formLabelWidth">
                            <el-input style="width: 55%;" size="small" v-model="item.etcNo"
                                      placeholder="请输入内容"/>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-row>
        </el-form>
        <el-form :model="rulesForm" ref="rulesForm" size="mini" class="user-search" :rules="rules">
            <el-row v-for="(item, index) in rulesForm.longCarInfo" :key="item.key">
                <div style="float: right;width: 25%;margin: 0px 0px 15px;">
                    <el-button icon="el-icon-delete" type="danger" size="small"
                               @click.prevent="removeLongCarInfoDel(item)">
                        删除
                    </el-button>
                </div>
                <el-col :span="11">
                    <el-form-item label="车牌号码" :prop="'longCarInfo.' + index + '.licensePlate'"
                                  :rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
                                  :label-width="formLabelWidth">
                        <el-input style="width: 55%;" size="small" placeholder="请输入内容"
                                  v-model.trim="item.licensePlate">
                            <template slot="prepend">
                                <LicenseKeyboard custom-class="app-license-keyboard"
                                                 customLicenseClass="license-container"
                                                 v-model="item.licensePlate" fontSize="0.3rem"/>
                            </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="车辆标签" prop="carType" :label-width="formLabelWidth">
                        <el-input style="width: 55%;" size="small" v-model="item.carTag"
                                  placeholder="请输入内容"/>
                    </el-form-item>
                    <el-form-item label="RFID卡号" prop="rfidNo" :label-width="formLabelWidth">
                        <el-input style="width: 55%;" size="small" v-model="item.rfidNo"
                                  placeholder="请输入内容"/>
                    </el-form-item>
                </el-col>
                <el-col :span="11">
                    <el-form-item label="车辆属性" :prop="'longCarInfo.' + index + '.carProperty'"
                                  :label-width="formLabelWidth"
                                  :rules="[{ required: true, message: '请选择', trigger: 'change' }]">
                        <el-select style="width: 55%;" size="small" v-model="item.carProperty"
                                   placeholder="请选择车辆属性">
                            <el-option label="小车" :value="1"></el-option>
                            <el-option label="大车" :value="2"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="ETC卡号" prop="etcNo" :label-width="formLabelWidth">
                        <el-input style="width: 55%;" size="small" v-model="item.etcNo"
                                  placeholder="请输入内容"/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <h2 style="float: left;margin: 0px 50px;line-height: 40px;">车位信息</h2>
                <div style="float: right;width: 25%;">
                    <el-button icon="el-icon-plus" type="primary" size="small" @click="addDomain">添加</el-button>
                </div>
            </el-row>
            <el-row v-for="(domain) in editForm.domains" :key="domain.key">
                <el-row>
                    <el-col :span="11">
                        <div style="text-align: right;width: 55%;margin-bottom: 15px" v-if="domain.parkingAreaId != ''">
                            <el-button type="success" size="small" @click="handleRenewal(domain)">续期</el-button>
                            <el-button type="success" size="small" @click="handleTransfer(domain)">转让</el-button>
                            <el-button type="success" size="small" @click="handleRefund(domain)">退费</el-button>
                            <el-button type="success" size="small" @click="handleDelete(domain)">删除</el-button>
                        </div>
                    </el-col>
                </el-row>
                <el-col :span="11">
                    <el-form-item label="选择区域" :label-width="formLabelWidth">
                        <el-select disabled style="width: 55%;"
                                   size="small"
                                   v-model="domain.parkingAreaId"
                                   placeholder="请选择长租区域">
                            <el-option v-for="item in parkingAreas" :label="item.name"
                                       :value="item.id" :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="长租费率" :label-width="formLabelWidth">
                        <el-select disabled style="width: 55%;"
                                   size="small"
                                   v-model="domain.longCarRateId"
                                   placeholder="请选择长租费率">
                            <el-option v-for="item in longCarRate" :label="item.rateName"
                                       :value="item.id" :key="item.id"></el-option>
                            <el-option label="自定义" :value="null"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="金额" :label-width="formLabelWidth">
                        <el-input readonly style="width: 55%;" size="small" v-model.number="domain.paidAmount"
                                  placeholder="请输入内容"/>
                    </el-form-item>
                    <el-form-item label="可通行区域" :label-width="formLabelWidth">
                        <el-checkbox-group :disabled="domain.longCarInArea != []" v-model="domain.longCarInAreas">
                            <el-checkbox v-for="item in parkingAreas" :label="item.id"
                                         :key="item.id" :value="item.id">
                                {{item.name }}
                            </el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                </el-col>
                <el-col :span="11">
                    <el-form-item label="车位编号" :label-width="formLabelWidth">
                        <el-select disabled style="width: 55%;" size="small"
                                   v-model="domain.parkingPlaceNumber"
                                   placeholder="请选择车位">
                            <el-option v-for="item in parkingData" :key="item.id"
                                       :label="item.parkingPlaceNumber"
                                       :value="item.parkingPlaceNumber"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="周期类型" :label-width="formLabelWidth">
                        <el-input readonly style="width: 55%;" size="small" v-if="domain.cycleType == 1" value="月"/>
                        <el-input readonly style="width: 55%;" size="small" v-else-if="domain.cycleType == 2"
                                  value="季"/>
                        <el-input readonly style="width: 55%;" size="small" v-else-if="domain.cycleType == 3"
                                  value="半年"/>
                        <el-input readonly style="width: 55%;" size="small" v-else-if="domain.cycleType == 4"
                                  value="年"/>
                        <el-input readonly style="width: 55%;" size="small" v-else-if="domain.cycleType == 5"
                                  value="长期"/>
                        <el-input readonly style="width: 55%;" size="small" v-else value=""/>
                    </el-form-item>
                    <el-form-item hidden label="车辆属性" :label-width="formLabelWidth">
                        <el-input readonly style="width: 55%;" size="small" v-model="domain.carProperty"/>
                    </el-form-item>
                    <el-form-item label="有效期" :label-width="formLabelWidth">
                        <el-date-picker readonly size="small"
                                        v-model="domain.startTime" format="yyyy-MM-dd"
                                        value-format="yyyy-MM-dd" style="width: 55%" type="date"
                                        placeholder="请输入开始时间"></el-date-picker>
                        <el-date-picker readonly v-model="domain.endTime" format="yyyy-MM-dd"
                                        value-format="yyyy-MM-dd" size="small"
                                        style="width: 55%" type="date"
                                        placeholder="请输入结束时间"></el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row v-for="(item, index) in rulesForm.longCarCharges" :key="item.key">
                <el-row>
                    <el-col :span="11">
                        <div style="text-align: right;width: 55%;margin-bottom: 15px">
                        </div>
                    </el-col>
                    <el-col :span="11">
                        <div style="float: right;width: 36.5%;">
                            <el-button icon="el-icon-delete" type="danger" size="small"
                                       @click.prevent="removeDomain(item)">删除
                            </el-button>
                        </div>
                    </el-col>
                </el-row>
                <el-col :span="11">
                    <el-form-item label="选择区域" :prop="'longCarCharges.' + index + '.parkingAreaId'"
                                  :label-width="formLabelWidth"
                                  :rules="[{ required: true, message: '请选择', trigger: 'change' }]">
                        <el-select style="width: 55%;" @change="changeArea"
                                   size="small"
                                   v-model="item.parkingAreaId"
                                   placeholder="请选择长租区域">
                            <el-option v-for="item in parkingAreas" :label="item.name"
                                       :value="item.id" :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="长租费率" :prop="'longCarCharges.' + index + '.longCarRateId'"
                                  :label-width="formLabelWidth"
                                  :rules="[{ required: true, message: '请选择', trigger: 'change' }]">
                        <el-select @change="changelongCarRate(item.longCarRateId,index)" style="width: 55%;"
                                   size="small" v-model="item.longCarRateId"
                                   placeholder="请选择长租费率">
                            <el-option v-for="item in longCarRate" :label="item.rateName"
                                       :value="item.id" :key="item.id"></el-option>
                            <el-option label="自定义" value=" "></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="金额" :prop="'longCarCharges.' + index + '.price'"
                                  :label-width="formLabelWidth"
                                  :rules="[{ required: true, message: '请输入金额', trigger: 'blur' }]">
                        <el-input style="width: 55%;" size="small" oninput="value=value.replace(/[^0-9.]/g,'')"
                                  v-model="item.price" placeholder="请输入金额"/>
                    </el-form-item>
                    <el-form-item label="可通行区域" :prop="'longCarCharges.' + index + '.longCarInAreas'"
                                  :label-width="formLabelWidth"
                                  :rules="[{ required: true, message: '请选择可通行区域', trigger: 'change' }]">
                        <el-checkbox-group v-model="item.longCarInAreas">
                            <el-checkbox v-for="item in parkingAreas" :label="item.id"
                                         :key="item.id" :value="item.id">
                                {{item.name }}
                            </el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                </el-col>
                <el-col :span="11">
                    <el-form-item label="车位编号" :prop="'longCarCharges.' + index + '.parkingPlaceNumber'"
                                  :label-width="formLabelWidth">
                        <el-select style="width: 55%;" size="small" filterable allow-create
                                   v-model="item.parkingPlaceNumber"
                                   placeholder="请选择车位">
                            <el-option v-for="item in parkingData" :key="item.id"
                                       :label="item.parkingPlaceNumber"
                                       :value="item.parkingPlaceNumber"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="周期类型" :prop="'longCarCharges.' + index + '.cycleType'"
                                  :label-width="formLabelWidth">
                        <el-input readonly style="width: 55%;" size="small" v-if="item.cycleType == 1" value="月"/>
                        <el-input readonly style="width: 55%;" size="small" v-else-if="item.cycleType == 2" value="季"/>
                        <el-input readonly style="width: 55%;" size="small" v-else-if="item.cycleType == 3" value="半年"/>
                        <el-input readonly style="width: 55%;" size="small" v-else-if="item.cycleType == 4" value="年"/>
                        <el-input readonly style="width: 55%;" size="small" v-else-if="item.cycleType == 5" value="长期"/>
                        <el-input readonly style="width: 55%;" size="small" v-else value=""/>
                    </el-form-item>
                    <el-form-item hidden label="车辆属性" :prop="'longCarCharges.' + index + '.carProperty'"
                                  :label-width="formLabelWidth">
                        <el-input readonly style="width: 55%;" size="small" v-model="item.carProperty"></el-input>
                    </el-form-item>
                    <el-form-item label="有效期" :prop="'longCarCharges.' + index + '.startTime'"
                                  :label-width="formLabelWidth"
                                  :rules="[{ required: true, message: '请选择日期', trigger: 'change' }]">
                        <el-date-picker @change="changeDate1" size="small"
                                        v-model="item.startTime" format="yyyy-MM-dd"
                                        value-format="yyyy-MM-dd" style="width: 55%" type="date"
                                        placeholder="请输入结束时间"></el-date-picker>
                        <el-date-picker @change="changeDate2" v-model="item.endTime" format="yyyy-MM-dd"
                                        value-format="yyyy-MM-dd" size="small"
                                        style="width: 55%" type="date"
                                        placeholder="请输入结束时间"></el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-form-item :label-width="formLabelWidth">
                <el-button size="small" type="primary" @click="submitForm('rulesForm')">保存</el-button>
                <el-button size="small" @click="resetForm('rulesForm')">取消</el-button>
            </el-form-item>
        </el-form>
        <!--    长租车续期-->
        <el-dialog v-draggable title="长租车续期" :visible.sync="renewalDialog" width="50%">
            <el-form :model="renewalForm" ref="renewalForm" size="mini">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="车牌号码" prop="licensePlate" :label-width="formLabelWidth">
                            <el-input size="small" readonly v-model="renewalForm.licensePlate"/>
                        </el-form-item>
                        <el-form-item label="当前长租费率" :label-width="formLabelWidth">
                            <el-select disabled size="small" v-model="renewalForm.longCarRateId"
                                       placeholder="请选择长租费率">
                                <el-option v-for="item in longCarRate" :label="item.rateName"
                                           :value="item.id" :key="item.id"></el-option>
                                <el-option label="自定义" :value="null"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="区域" :label-width="formLabelWidth">
                            <el-select disabled @change="changeArea" size="small" v-model="renewalForm.parkingAreaId"
                                       placeholder="请选择长租区域">
                                <el-option v-for="item in parkingAreas" :label="item.name"
                                           :value="item.id" :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="车位编号" :label-width="formLabelWidth">
                            <el-input readonly size="small" v-model="renewalForm.parkingPlaceNumber"/>
                        </el-form-item>
                    </el-col>
                    <el-row>
                        <el-col :span="17">
                            <el-form-item label="续期类型" prop="handleStatus" :label-width="formLabelWidth">
                                <el-select style="width: 100%;" size="small" v-model="renewalForm.rateId"
                                           placeholder="请选择方式"
                                           @change="handleShow">
                                    <el-option v-for="item in longCarRate" :label="item.rateName"
                                               :value="item.id" :key="item.id"></el-option>
                                    <el-option label="自定义" :value="null"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="当前到期时间" prop="handleStatus" :label-width="formLabelWidth">
                                <el-input size="small" readonly v-model="renewalForm.endTime"/>
                            </el-form-item>
                            <div v-if="show">
                                <el-form-item label="续期数量" prop="handleStatus" :label-width="formLabelWidth">
                                    <el-input @input="inputValue" size="small" v-model="renewalForm.renewalValue"
                                              placeholder="请输入内容"/>
                                </el-form-item>
                                <el-form-item label="金额" prop="handleStatus" :label-width="formLabelWidth">
                                    <el-input size="small" v-model="renewalForm.price" placeholder="请输入内容"/>
                                </el-form-item>
                            </div>
                            <!--                            <el-form-item label="续费起止日期" :label-width="formLabelWidth">-->
                            <!--                                <el-date-picker-->
                            <!--                                        @change="changeTime"-->
                            <!--                                        size="small"-->
                            <!--                                        v-model="renewalForm.date"-->
                            <!--                                        type="daterange"-->
                            <!--                                        range-separator="至"-->
                            <!--                                        start-placeholder="开始日期"-->
                            <!--                                        end-placeholder="结束日期">-->
                            <!--                                </el-date-picker>-->
                            <!--                            </el-form-item>-->

                            <el-form-item label="续费起止日期" :label-width="formLabelWidth">
                                <div style="display: flex;">
                                    <el-date-picker @change="changeTime" v-model="renewalForm.startDate"
                                                    format="yyyy-MM-dd"
                                                    value-format="yyyy-MM-dd"
                                                    clearable style="width: 55%" type="date"
                                                    placeholder="请输入开始时间"></el-date-picker>
                                    <div style="margin: 0 5px;">至</div>
                                    <el-date-picker @change="changeDate" v-model="renewalForm.endDate"
                                                    format="yyyy-MM-dd"
                                                    value-format="yyyy-MM-dd"
                                                    clearable style="width: 55%" type="date"
                                                    placeholder="请输入结束时间"></el-date-picker>
                                </div>
                            </el-form-item>
                            <div v-if="hidden">
                                <el-form-item label="收费(元)" prop="handleStatus" :label-width="formLabelWidth">
                                    <el-input size="small" v-model="renewalForm.price" placeholder="请输入内容"/>
                                </el-form-item>
                            </div>
                        </el-col>
                    </el-row>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button @click="renewalDialog = false">取 消</el-button>
        <el-button type="primary" @click="confirmRenewal('renewalForm')">确 定</el-button>
      </span>
        </el-dialog>
        <!--    长租车位转让-->
        <el-dialog v-draggable title="长租车位转让" :visible.sync="transferDialog" width="50%">
            <el-form :model="transferForm" ref="transferForm" size="mini" :rules="transferRules" label-width="100px">
                <el-row>
                    <el-row>
                        <el-col :span="11">
                            <el-form-item label="区域">
                                <el-select disabled @change="changeArea" size="small"
                                           v-model="transferForm.parkingAreaId"
                                           placeholder="请选择长租区域">
                                    <el-option v-for="item in parkingAreas" :label="item.name"
                                               :value="item.id" :key="item.id"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="车位编号">
                                <el-input size="small" readonly v-model="transferForm.parkingPlaceNumber"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="11">
                            <el-form-item label="长租费率">
                                <el-select disabled @change="changeRate" size="small"
                                           v-model="transferForm.longCarRateId"
                                           placeholder="请选择长租费率">
                                    <el-option v-for="item in longCarRate" :label="item.rateName"
                                               :value="item.id" :key="item.id"></el-option>
                                    <el-option label="自定义" :value="null"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="到期时间">
                                <el-input size="small" readonly v-model="transferForm.endTime"/>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="原车牌">
                                <el-input type="textarea" size="small" readonly
                                          v-model.trim="transferForm.licensePlate"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="原车主">
                                <el-input size="small" readonly v-model="transferForm.ownerName"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="原车主电话">
                                <el-input size="small" readonly v-model="transferForm.ownerPhone"/>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-col :span="16">
                        <el-form-item label="新车牌号码" prop="newLicensePlate">
                            <el-input size="small" v-model.trim="transferForm.newLicensePlate" placeholder="请输入内容"/>
                        </el-form-item>
                        <el-form-item label="车主姓名" prop="newOwnerName">
                            <el-input size="small" v-model="transferForm.newOwnerName" placeholder="请输入内容"/>
                        </el-form-item>
                        <el-form-item label="车主手机" prop="newOwnerPhone">
                            <el-input size="small" v-model="transferForm.newOwnerPhone" placeholder="请输入内容"/>
                        </el-form-item>
                        <el-form-item label="车主身份证号" prop="idCard">
                            <el-input size="small" v-model="transferForm.idCard" placeholder="请输入内容"/>
                        </el-form-item>
                        <el-form-item label="车辆标签" prop="carTag">
                            <el-input size="small" v-model="transferForm.carTag" placeholder="请输入内容"/>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button @click="transferDialog = false">取 消</el-button>
        <el-button type="primary" @click="confirmTransfer('transferForm')">转 让</el-button>
      </span>
        </el-dialog>
        <!--    长租车退费-->
        <el-dialog v-draggable title="长租车退费" :visible.sync="refundDialog" width="50%">
            <el-form :model="refundForm" ref="refundForm" size="mini">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="车牌号码" :label-width="formLabelWidth">
                            <el-input size="small" readonly v-model.trim="refundForm.licensePlate"/>
                        </el-form-item>
                        <el-form-item label="长租费率" :label-width="formLabelWidth">
                            <el-select disabled @change="changeRate" size="small"
                                       v-model="refundForm.longCarRateId">
                                <el-option v-for="item in longCarRate" :label="item.rateName"
                                           :value="item.id" :key="item.id"></el-option>
                                <el-option label="自定义" :value="null"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="区域" :label-width="formLabelWidth">
                            <el-select disabled @change="changeArea" size="small"
                                       v-model="refundForm.parkingAreaId">
                                <el-option v-for="item in parkingAreas" :label="item.name"
                                           :value="item.id" :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="车位编号" :label-width="formLabelWidth">
                            <el-input size="small" readonly v-model="refundForm.parkingPlaceNumber"/>
                        </el-form-item>
                        <el-form-item label="长租有效期" :label-width="formLabelWidth">
                            <el-input size="small" readonly v-model="refundForm.date"/>
                        </el-form-item>
                        <el-form-item label="已缴金额" :label-width="formLabelWidth">
                            <el-input size="small" readonly v-model="refundForm.price"/>
                        </el-form-item>
                    </el-col>
                    <el-row>
                        <el-col :span="17">
                            <el-form-item label="退费天数" prop="handleStatus" :label-width="formLabelWidth">
                                <div style="display: flex;">
                                    <el-input @input="inputRefundDay" size="small" v-model="refundForm.refundDay"
                                              placeholder="请输入内容"/>
                                    <span style="margin-left: 5px;width: 40px;">天</span>
                                </div>
                            </el-form-item>
                            <el-form-item label="新长租有效期" prop="handleStatus" :label-width="formLabelWidth">
                                <div style="display: flex;">
                                    <el-input readonly size="small" v-model="refundForm.dateTime1"/>
                                    <el-input readonly size="small" v-model="refundForm.dateTime2"/>
                                </div>
                            </el-form-item>
                            <el-form-item label="退费金额" prop="handleStatus" :label-width="formLabelWidth">
                                <div style="display: flex;">
                                    <el-input size="small" v-model="refundForm.refundAmount" placeholder="请输入内容"/>
                                    <span style="margin-left: 5px;width: 40px;">元</span>
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button @click="refundDialog = false">取 消</el-button>
        <el-button type="primary" @click="confirmFefund('refundForm')">确 定</el-button>
      </span>
        </el-dialog>
    </div>
</template>

<script>
    import {
        longCarList,
        longCarAreaList,
        longCarRateList,
        longCarSave,
        parkingDataList,
        longCarRenewal,
        longCarTransfer,
        longCarRefund,
        longCarRateDel
    } from "@/api/vehicle/longcar";
    import {longCarIdDel} from "../../../api/vehicle/longcar";
    import Vue from 'vue'
    import LicenseKeyboard from 'vue-license-keyboard';
    import 'vue-license-keyboard/lib/vue-license-keyboard.css';

    Vue.use(LicenseKeyboard);

    export default {
        name: "addVehicle",
        inject: ['reload'],
        data() {
            return {
                formLabelWidth: '120px',
                disabled: true,
                readonly: false,
                editForm: {
                    id: "",
                    ownerName: "",//车主姓名
                    ownerPhone: "",//车主号码
                    idCard: "", //车主身份证
                    remark: "",//备注
                    longCarInfo: [{
                        licensePlate: "",//车牌号码
                        carProperty: "",//车辆属性
                        carTag: "", //车辆标签
                        ownerName: "",//车主姓名
                        ownerPhone: "",//车主号码
                        idCard: "", //车主身份证
                        remark: "",//备注
                        rfidNo: "", //RFID卡号
                        etcNo: "", //ETC卡号
                    }],
                    licensePlate: "",//车牌号码
                    carProperty: "",//车辆属性
                    carIdentity: "", //车辆身份
                    domains: [{
                        id: "",
                        parkingAreaId: "",//区域id
                        cycleType: "",//周期类型
                        carProperty: "",//车辆属性
                        price: "",//价格
                        startTime: "",//开始时间
                        endTime: "",//结束时间
                        longCarRateId: "", //长租费率id
                        parkingPlaceNumber: "", //车位编号
                        days: "", //天数
                        longCarInAreas: [],
                    }],
                    renewalDialog: false,
                    transferDialog: false,
                },
                longCarCharges: [],
                rulesForm: {
                    longCarInfo: [],
                    longCarCharges: [],
                },
                show: false,
                hidden: false,
                renewalDialog: false,
                transferDialog: false,
                refundDialog: false,
                parkingAreas: [],
                longCarRate: [],
                parkingData: [],
                startDatePicker: "",
                endDatePicker: "",
                page: {
                    current: 1,
                    size: 10
                },
                rules: {
                    ownerName: [{required: true, message: "请输入", trigger: "blur"}],
                    ownerPhone: [{required: true, message: "请输入", trigger: "blur"}],
                    licensePlate: [{required: true, message: "请输入", trigger: "blur"}],
                    carProperty: [{required: true, message: "请选择", trigger: "change"}],
                },
                //长租车续期
                renewalForm: {
                    id: "",
                    ownerName: "",//车主姓名
                    parkingPlaceNumber: "",//车位编号
                    licensePlate: "",//车牌号码
                    longCarRateId: "", //长租费率id
                    parkingAreaId: "",//区域id
                    endTime: "",//结束时间
                    renewalValue: "",//续期数量
                    price: "",//收费
                    longCarInfoId: "",
                    days: "",//天数
                    rateId: "",
                    endDate: "",
                    startDate: "",
                    longCarRate: [],
                    paidAmount: "",
                },
                parkingAreaId: "",
                //长租车位转让
                transferForm: {
                    id: "",
                    ownerName: "",//车主姓名
                    parkingPlaceNumber: "",//车位编号
                    licensePlate: "",//车牌号码
                    ownerPhone: "",//原车主电话
                    idCard: "",//车主身份证
                    newLicensePlate: "",//新车牌号码
                    newOwnerName: "",//车主姓名
                    newOwnerPhone: "",//车主手机
                    longCarRateId: "", //长租费率id
                    parkingAreaId: "",//区域id
                    endTime: "",//结束时间
                    longCarInfo: [],
                    carProperty: "",
                    carTag: "",
                    longCarVehicle: [],
                    longCarCharge: [],
                },
                transferRules: {
                    newLicensePlate: [{required: true, message: "请输入", trigger: "blur"}],
                    newOwnerName: [{required: true, message: "请输入", trigger: "blur"}],
                    newOwnerPhone: [{required: true, message: "请输入", trigger: "blur"}],
                },
                //长租车退费
                refundForm: {
                    id: "",
                    parkingPlaceNumber: "",//车位编号
                    licensePlate: "",//车牌号码
                    longCarRateId: "", //长租费率id
                    parkingAreaId: "",//区域id
                    endTime: "",//结束时间,
                    startTime: "",
                    price: "",//收费
                    refundDay: "",//退费天数
                    refundAfterDate: "",//新长租有效期
                    refundAmount: "",//退费金额
                    dateTime1: "",//结束时间,
                    dateTime2: "",
                },
                refundAfterDate: [],//新长租有效期
                form: {},
            }
        },
        created() {
            let id = this.$route.query.id;
            if (!id) return;
            longCarList({current: 1, size: 10, id: id}).then((res) => {
                let data = res.data.data.records[0];
                this.setData(data);
            })
            this.getParkingAreaRoad();
        },
        methods: {
            //长租车表单编辑回显
            setData(val) {
                if (val) {
                    Object.keys(this.editForm).forEach((n) => {
                        this.$set(this.editForm, n, val[n]);
                    });
                } else {
                    Object.keys(this.editForm).forEach((n) => {
                        let defaultStr = '';
                        this.$set(this.editForm, n, defaultStr);
                    });
                }
                val.longCarCharges.forEach((item) => {
                    item.longCarInAreas = item.longCarInAreas.map((x) => x.parkingAreaId);
                })
                this.$set(this.editForm, 'longCarInfo', val.longCarInfo);
                this.$set(this.editForm, 'domains', val.longCarCharges);
                this.editForm.domains.forEach((e) => {
                    this.getLongCarRateList(e.parkingAreaId);
                });
            },
            //获取区域
            getParkingAreaRoad() {
                longCarAreaList().then((res) => {
                    this.parkingAreas = res.data.data.parkingAreas;
                }).catch((err) => {
                    console.log(err)
                });
            },
            changeArea(areaId) {
                this.getParkingDataList(areaId);
                this.areaId = areaId;
                this.getLongCarRateList(this.areaId);
            },
            //获取长租费率
            getLongCarRateList(areaId) {
                longCarRateList(areaId).then((res) => {
                    this.longCarRate = res.data.data;
                }).catch((err) => {
                    console.log(err)
                })
            },
            changelongCarRate(e, i) {
                if (e != " ") {
                    this.longCarRate.forEach((element) => {
                        if (element.id === e) {
                            this.rulesForm.longCarCharges.forEach((e) => {
                                if (e.longCarRateId === element.id) {
                                    e.cycleType = element.cycle;
                                    e.price = element.price;
                                }
                            });
                            return;
                        }
                    })
                    this.rulesForm.longCarCharges[i].startTime = "";
                    this.rulesForm.longCarCharges[i].endTime = "";
                } else {
                    this.rulesForm.longCarCharges[i].cycleType = "";
                    this.rulesForm.longCarCharges[i].startTime = "";
                    this.rulesForm.longCarCharges[i].endTime = "";
                    this.rulesForm.longCarCharges[i].price = "";
                    this.rulesForm.longCarCharges[i].paidAmount = "";
                }
            },
            changeRate(val) {
                this.longCarRate.forEach((element) => {
                    if (element.id === val) {
                        this.rulesForm.longCarCharges.forEach((e) => {
                            if (e.longCarRateId === element.id) {
                                e.cycleType = element.cycle;
                                e.price = element.price;
                                e.paidAmount = element.paidAmount;
                            }
                        });
                        return;
                    }
                })
            },
            //获取车位
            getParkingDataList(id) {
                parkingDataList(id).then((res) => {
                    if (res.data.data.length > 0) {
                        this.parkingData = res.data.data;
                    } else {
                        this.editForm.domains.forEach((e) => {
                            if (e.parkingAreaId === id) {
                                this.parkingData = [];
                                e.parkingPlaceNumber = "";
                            }
                        })
                    }
                }).catch((err) => {
                    console.log(err)
                });
            },
            //计算日期时间
            changeDate1() {
                this.rulesForm.longCarCharges.forEach((e) => {
                    if (e.cycleType == 1) {
                        e.endTime = this.dayjs(e.startTime).add(30, 'day').format("YYYY-MM-DD");
                        const date1 = this.dayjs(e.startTime, "yyyy-MM-dd");
                        const date2 = this.dayjs(e.endTime, "yyyy-MM-dd");
                        e.days = date2.diff(date1, 'day');
                    } else if (e.cycleType == 2) {
                        e.endTime = this.dayjs(e.startTime).add(90, 'day').format("YYYY-MM-DD");
                        const date1 = this.dayjs(e.startTime, "yyyy-MM-dd");
                        const date2 = this.dayjs(e.endTime, "yyyy-MM-dd");
                        e.days = date2.diff(date1, 'day');
                    } else if (e.cycleType == 3) {
                        e.endTime = this.dayjs(e.startTime).add(181, 'day').format("YYYY-MM-DD");
                        const date1 = this.dayjs(e.startTime, "yyyy-MM-dd");
                        const date2 = this.dayjs(e.endTime, "yyyy-MM-dd");
                        e.days = date2.diff(date1, 'day');
                    } else if (e.cycleType == 4) {
                        e.endTime = this.dayjs(e.startTime).add(365, 'day').format("YYYY-MM-DD");
                        const date1 = this.dayjs(e.startTime, "yyyy-MM-dd");
                        const date2 = this.dayjs(e.endTime, "yyyy-MM-dd");
                        e.days = date2.diff(date1, 'day');
                    } else if (e.cycleType == 5) {
                        let time = "2099-12-31";
                        e.endTime = this.dayjs(time).format("YYYY-MM-DD");
                        const date1 = this.dayjs(e.startTime, "yyyy-MM-dd");
                        const date2 = this.dayjs(e.endTime, "yyyy-MM-dd");
                        e.days = date2.diff(date1, 'day');
                    }
                })
            },
            changeDate2() {
                this.rulesForm.longCarCharges.forEach((e) => {
                    if (e.cycleType == "") {
                        const date1 = this.dayjs(e.startTime, "yyyy-MM-dd");
                        const date2 = this.dayjs(e.endTime, "yyyy-MM-dd");
                        e.days = date2.diff(date1, 'day');
                    }
                })
            },
            //提交表单
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.editForm.domains.forEach((e) => {
                            let longCarInAreasArr = [];
                            for (var i = 0; i < e.longCarInAreas.length; i++) {
                                //遍历传入的数组，查找传入数组的值是否存在新数组中
                                if (longCarInAreasArr.indexOf(e.longCarInAreas[i]) === -1) {
                                    //不存在就把值push到新数组
                                    longCarInAreasArr.push(e.longCarInAreas[i]);
                                }
                            }
                            let longCarInAreasList = [];
                            longCarInAreasArr.forEach(i => {
                                longCarInAreasList.push({parkingAreaId: i});
                            });
                            e.longCarInAreas = longCarInAreasList;
                        });
                        this.rulesForm.longCarCharges.forEach((e) => {
                            e.paidAmount = e.price;
                            let longCarInAreasList = [];
                            e.longCarInAreas.forEach(e => {
                                longCarInAreasList.push({parkingAreaId: e});
                            })
                            e.longCarInAreas = longCarInAreasList;
                        });
                        var longCarCharges = this.editForm.domains.concat(this.rulesForm.longCarCharges);
                        var longCarInfo = this.editForm.longCarInfo.concat(this.rulesForm.longCarInfo);
                        let param = {
                            id: this.editForm.id,
                            longCarCharges: longCarCharges,
                            ownerName: this.editForm.ownerName,
                            ownerPhone: this.editForm.ownerPhone,
                            idCard: this.editForm.idCard,
                            remark: this.editForm.remark,
                            longCarInfo: longCarInfo,
                        }
                        longCarSave(param).then((res) => {
                            if (res.data.code == "SUCCESS") {
                                this.$message.success(res.data.message);
                                this.$router.push({path: '/parkingLot/longTermRentRate'});
                            } else {
                                this.reload();
                                this.$message.error(res.data.message);
                            }
                        }).catch((err) => {
                            this.reload();
                            this.$message.error("编辑保存失败，请稍后再试！");
                        });
                    } else {
                        return false;
                    }
                });
            },
            //返回列表
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.$router.push({path: '/parkingLot/longTermRentRate'})
            },
            //动态表单项增加
            addLongCarInfo() {
                this.rulesForm.longCarInfo.push({
                    licensePlate: "",
                    carProperty: "",
                    carTag: "",
                    rfidNo: "", //RFID卡号
                    etcNo: "", //ETC卡号
                    key: Date.now(),
                });
            },
            //增加表单项
            addDomain() {
                this.rulesForm.longCarCharges.push({
                    parkingAreaId: "",//区域id
                    carProperty: "",//车辆属性
                    cycleType: "",//周期类型
                    price: "",//价格
                    paidAmount: "", //已缴金额
                    startTime: "",//开始时间
                    endTime: "",//结束时间
                    longCarRateId: "", //长租费率id
                    days: "", //天数
                    parkingPlaceNumber: "", //车位编号
                    longCarInAreas: [],
                    key: Date.now(),
                });
            },
            //删除表单项
            removeLongCarInfo(item) {
                this.$confirm('是否确认删除车辆信息?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    longCarIdDel(item.id).then((res) => {
                        this.reload();
                        if (res.data.code == "SUCCESS") {
                            this.$message.success(res.data.message);
                        } else {
                            this.reload();
                            this.$message.info(res.data.message);
                        }
                    })
                }).catch(() => {
                    this.$message.info('已取消');
                });
            },
            removeLongCarInfoDel(item) {
                var index = this.rulesForm.longCarInfo.indexOf(item);
                if (index !== -1) {
                    this.rulesForm.longCarInfo.splice(index, 1);
                }
            },
            removeDomain(item) {
                var index = this.rulesForm.longCarCharges.indexOf(item);
                if (index !== -1) {
                    this.rulesForm.longCarCharges.splice(index, 1);
                }
            },
            // 长租车续期
            handleRenewal(data) {
                this.renewalDialog = true;
                let licensePlate = [];
                for (var i = 0; i < this.editForm.longCarInfo.length; i++) {
                    licensePlate.push(this.editForm.longCarInfo[i].licensePlate);
                }
                this.renewalForm.licensePlate = licensePlate.toString();
                this.renewalForm.parkingAreaId = data.parkingAreaId;
                this.renewalForm.longCarRateId = data.longCarRateId;
                this.renewalForm.rateId = data.longCarRateId;
                this.renewalForm.parkingPlaceNumber = data.parkingPlaceNumber;
                this.renewalForm.endTime = data.endTime;
                this.renewalForm.longCarInfoId = data.longCarInfoId;
                this.renewalForm.id = data.id;
                this.renewalForm.longCarRate = data.longCarRate;
                this.renewalForm.renewalValue = 1;
                this.renewalForm.price = data.paidAmount.toFixed(2);
                this.renewalForm.paidAmount = data.paidAmount.toFixed(2);
                this.getLongCarRateList(data.parkingAreaId)
                this.longCarRate.forEach((el) => {
                    if (el.id === this.renewalForm.rateId) {
                        if (el.cycle == 1) {
                            this.renewalForm.startDate = this.dayjs(data.endTime).add(1, 'day').format("YYYY-MM-DD");
                            this.renewalForm.endDate = this.dayjs(data.endTime).add(30, 'day').format("YYYY-MM-DD");
                        } else if (el.cycle == 2) {
                            this.renewalForm.startDate = this.dayjs(data.endTime).add(1, 'day').format("YYYY-MM-DD");
                            this.renewalForm.endDate = this.dayjs(data.endTime).add(90, 'day').format("YYYY-MM-DD");
                        } else if (el.cycle == 3) {
                            this.renewalForm.startDate = this.dayjs(data.endTime).add(1, 'day').format("YYYY-MM-DD");
                            this.renewalForm.endDate = this.dayjs(data.endTime).add(181, 'day').format("YYYY-MM-DD");
                        } else if (el.cycle == 4) {
                            this.renewalForm.startDate = this.dayjs(data.endTime).add(1, 'day').format("YYYY-MM-DD");
                            this.renewalForm.endDate = this.dayjs(data.endTime).add(365, 'day').format("YYYY-MM-DD");
                        } else if (el.cycle == 5) {
                            this.renewalForm.startDate = this.dayjs(data.startTime).format("YYYY-MM-DD");
                            let time = "2099-12-31";
                            this.renewalForm.endDate = this.dayjs(time).format("YYYY-MM-DD");
                        }
                    }
                })
                if (data.longCarRateId == null) {
                    this.renewalForm.startDate = this.dayjs(data.endTime).add(1, 'day').format("YYYY-MM-DD");
                    this.renewalForm.endDate = "";
                }
                if (this.renewalForm.rateId == null) {
                    this.show = false;
                    this.hidden = true;
                } else {
                    this.show = true;
                    this.hidden = false;
                }
            },
            //根据选项显示自定义
            handleShow(e) {
                if (e == null) {
                    this.show = false;
                    this.hidden = true;
                    this.renewalForm.renewalValue = "";
                    this.renewalForm.price = this.renewalForm.paidAmount.toFixed(2);
                    this.renewalForm.startDate = this.dayjs(this.renewalForm.endTime).add(1, 'day').format("YYYY-MM-DD");
                    this.renewalForm.endDate = "";
                } else {
                    this.show = true;
                    this.hidden = false;
                    this.renewalForm.renewalValue = 1;
                    this.longCarRate.forEach((el) => {
                        if (el.id === e) {
                            this.renewalForm.price = (el.price * this.renewalForm.renewalValue).toFixed(2);
                            if (el.cycle == 1) {
                                this.renewalForm.startDate = this.dayjs(this.renewalForm.endTime).add(1, 'day').format("YYYY-MM-DD");
                                this.renewalForm.endDate = this.dayjs(this.renewalForm.endTime).add(30, 'day').format("YYYY-MM-DD");
                            } else if (el.cycle == 2) {
                                this.renewalForm.startDate = this.dayjs(this.renewalForm.endTime).add(1, 'day').format("YYYY-MM-DD");
                                this.renewalForm.endDate = this.dayjs(this.renewalForm.endTime).add(90, 'day').format("YYYY-MM-DD");
                            } else if (el.cycle == 3) {
                                this.renewalForm.startDate = this.dayjs(this.renewalForm.endTime).add(1, 'day').format("YYYY-MM-DD");
                                this.renewalForm.endDate = this.dayjs(this.renewalForm.endTime).add(181, 'day').format("YYYY-MM-DD");
                            } else if (el.cycle == 4) {
                                this.renewalForm.startDate = this.dayjs(this.renewalForm.endTime).add(1, 'day').format("YYYY-MM-DD");
                                this.renewalForm.endDate = this.dayjs(this.renewalForm.endTime).add(365, 'day').format("YYYY-MM-DD");
                            } else if (el.cycle == 5) {
                                this.renewalForm.startDate = this.dayjs(this.renewalForm.startTime).format("YYYY-MM-DD");
                                let time = "2099-12-31";
                                this.renewalForm.endDate = this.dayjs(time).format("YYYY-MM-DD");
                            }
                        }
                    })
                }
            },
            //续期计算日期
            changeTime() {
                this.longCarRate.forEach((el) => {
                    if (el.id === this.renewalForm.rateId) {
                        if (el.cycle == 1) {
                            this.renewalForm.endDate = this.dayjs(this.renewalForm.startDate).add(30 * this.renewalForm.renewalValue, 'day').format("YYYY-MM-DD");
                        } else if (el.cycle == 2) {
                            this.renewalForm.endDate = this.dayjs(this.renewalForm.startDate).add(90 * this.renewalForm.renewalValue, 'day').format("YYYY-MM-DD");
                        } else if (el.cycle == 3) {
                            this.renewalForm.endDate = this.dayjs(this.renewalForm.startDate).add(181 * this.renewalForm.renewalValue, 'day').format("YYYY-MM-DD");
                        } else if (el.cycle == 4) {
                            this.renewalForm.endDate = this.dayjs(this.renewalForm.startDate).add(365 * this.renewalForm.renewalValue, 'day').format("YYYY-MM-DD");
                        } else if (el.cycle == 5) {
                            let time = "2099-12-31";
                            this.renewalForm.endDate = this.dayjs(time).format("YYYY-MM-DD");
                        }
                    }
                })
            },
            changeDate() {
                this.renewalForm.renewalValue = 1;
                let day = this.dayjs(this.renewalForm.endDate).diff(this.dayjs(this.renewalForm.startDate), 'days')
                this.renewalForm.endDate = this.dayjs(this.renewalForm.startDate).add(day * this.renewalForm.renewalValue, 'day').format("YYYY-MM-DD");
            },
            //输入续期数量计算价格
            inputValue(e) {
                this.longCarRate.forEach((el) => {
                    if (el.id === this.renewalForm.rateId) {
                        this.renewalForm.price = (el.price * e).toFixed(2);
                        if (el.cycle == 1) {
                            this.renewalForm.endDate = this.dayjs(this.renewalForm.startDate).add(30 * e, 'day').format("YYYY-MM-DD");
                        } else if (el.cycle == 2) {
                            this.renewalForm.endDate = this.dayjs(this.renewalForm.startDate).add(90 * e, 'day').format("YYYY-MM-DD");
                        } else if (el.cycle == 3) {
                            this.renewalForm.endDate = this.dayjs(this.renewalForm.startDate).add(181 * e, 'day').format("YYYY-MM-DD");
                        } else if (el.cycle == 4) {
                            this.renewalForm.endDate = this.dayjs(this.renewalForm.startDate).add(365 * e, 'day').format("YYYY-MM-DD");
                        } else if (el.cycle == 5) {
                            let time = "2099-12-31";
                            this.renewalForm.endDate = this.dayjs(time).format("YYYY-MM-DD");
                        }
                    }
                })
                if (this.renewalForm.rateId == null) {
                    this.renewalForm.price = (this.renewalForm.paidAmount * e).toFixed(2);
                }
            },
            // 长租车续期表单提交
            confirmRenewal(renewalForm) {
                this.$refs[renewalForm].validate((valid) => {
                    if (valid) {
                        const date1 = this.dayjs(this.renewalForm.startDate, "yyyy-MM-dd");
                        const date2 = this.dayjs(this.renewalForm.endDate, "yyyy-MM-dd");
                        this.renewalForm.days = date2.diff(date1, 'day');
                        this.longCarRate.forEach((el) => {
                            if (el.id === this.renewalForm.rateId) {
                                this.renewalForm.cycleType = el.cycle;
                            }
                        })
                        let param = {
                            id: this.renewalForm.id,
                            parkingAreaId: this.renewalForm.parkingAreaId,//区域id
                            longCarRateId: this.renewalForm.rateId,//长租费率id
                            parkingPlaceNumber: this.renewalForm.parkingPlaceNumber,//车位编号
                            longCarInfoId: this.renewalForm.longCarInfoId,//长租车信息表
                            startTime: this.renewalForm.startDate,//有效期开始时间
                            endTime: this.renewalForm.endDate,//有效期结束时间
                            paidAmount: this.renewalForm.price,// 已缴金额
                            cycleType: this.renewalForm.cycleType,//周期类型
                            longCarRate: this.renewalForm.longCarRate,
                            days: this.renewalForm.days * this.renewalForm.renewalValue//天数
                        }
                        longCarRenewal(param).then((res) => {
                            if (res.data.code == "SUCCESS") {
                                this.renewalDialog = false;
                                this.$message.success(res.data.message);
                                this.reload();
                            } else if (res.data.code == "NEED_PAY") {
                                this.$router.push({
                                    path: '/parkingLot/centralCharge', query: {"licensePlate": res.data.data.licensePlate[0],"parkingId":res.data.data.parkingId}
                                });
                            } else {
                                this.reload();
                                this.$message.error(res.data.message);
                            }
                        }).catch(() => {
                            this.reload();
                        });
                    } else {
                        return false;
                    }
                });
            },
            //长租车位转让
            handleTransfer(data) {
                this.transferDialog = true;
                let licensePlate = [];
                for (var i = 0; i < this.editForm.longCarInfo.length; i++) {
                    licensePlate.push(this.editForm.longCarInfo[i].licensePlate);
                }
                this.transferForm.licensePlate = licensePlate.toString();
                this.transferForm.ownerPhone = this.editForm.ownerPhone;
                this.transferForm.ownerName = this.editForm.ownerName;
                this.transferForm.parkingAreaId = data.parkingAreaId;
                this.transferForm.longCarRateId = data.longCarRateId;
                this.transferForm.parkingPlaceNumber = data.parkingPlaceNumber;
                this.transferForm.endTime = data.endTime;
                this.transferForm.id = data.id;
                this.transferForm.longCarCharge = data;
                this.transferForm.carProperty = data.longCarRate.carProperty;
                this.transferForm.carTag = "";
                this.transferForm.idCard = "";
                this.transferForm.newOwnerPhone = "";
                this.transferForm.newOwnerName = "";
                this.transferForm.newLicensePlate = "";
            },
            //长租车位转让表单提交
            confirmTransfer(transferForm) {
                this.$refs[transferForm].validate((valid) => {
                    if (valid) {
                        let longCarVehicle = {
                            licensePlate: this.transferForm.newLicensePlate,
                            carProperty: this.transferForm.carProperty,
                            carTag: this.transferForm.carTag,
                        }
                        let longCarInAreasList = [];
                        this.transferForm.longCarCharge.longCarInAreas.forEach(e => {
                            longCarInAreasList.push({parkingAreaId: e});
                        })
                        this.transferForm.longCarCharge.longCarInAreas = longCarInAreasList;
                        let LongCarTransferVO = {
                            ownerPhone: this.transferForm.newOwnerPhone,
                            ownerName: this.transferForm.newOwnerName,
                            idCard: this.transferForm.idCard,
                            longCarChargeId: this.transferForm.id,
                            longCarCharge: this.transferForm.longCarCharge,
                            longCarVehicle: longCarVehicle
                        }
                        longCarTransfer(LongCarTransferVO).then((res) => {
                            if (res.data.code == "SUCCESS") {
                                this.transferDialog = false;
                                this.$message.success(res.data.message);
                                this.reload();
                            } else {
                                this.reload();
                                this.$message.error(res.data.message);
                            }
                        }).catch(() => {
                            this.reload();
                        });
                    } else {
                        return false;
                    }
                });
            },
            //计算退费天数
            inputRefundDay(e) {
                this.refundForm.dateTime1 = this.refundForm.startTime;
                this.refundForm.dateTime2 = this.dayjs(this.refundForm.endTime).subtract(e, 'day').format("YYYY-MM-DD");
            },
            //长租车退费
            handleRefund(data) {
                this.refundDialog = true;
                let licensePlate = [];
                for (var i = 0; i < this.editForm.longCarInfo.length; i++) {
                    licensePlate.push(this.editForm.longCarInfo[i].licensePlate);
                }
                this.refundForm.licensePlate = licensePlate.toString();
                this.refundForm.parkingAreaId = data.parkingAreaId;
                this.refundForm.longCarRateId = data.longCarRateId;
                this.refundForm.parkingPlaceNumber = data.parkingPlaceNumber;
                this.refundForm.date = data.startTime + " 至 " + data.endTime;
                this.refundForm.startTime = data.startTime;
                this.refundForm.endTime = data.endTime;
                this.refundForm.price = data.paidAmount;
                this.refundForm.id = data.id;
                this.refundForm.refundAmount = "";
                this.refundForm.refundDay = "";
                this.refundForm.dateTime1 = "";
                this.refundForm.dateTime2 = "";
            },
            //长租车退费表单提交
            confirmFefund(refundForm) {
                this.$refs[refundForm].validate((valid) => {
                    if (valid) {
                        if (this.refundForm.dateTime1 > this.refundForm.dateTime2) {
                            this.$message("结束时间不能超过开始时间")
                            return
                        }
                        let param = {
                            longCarChargeId: this.refundForm.id,
                            refundAfterDate: this.refundForm.dateTime2,
                            refundAmount: this.refundForm.refundAmount,
                            refundDay: this.refundForm.refundDay
                        }
                        longCarRefund(param).then((res) => {
                            if (res.data.code == "SUCCESS") {
                                this.refundDialog = false;
                                this.$message.success(res.data.message);
                                this.reload();
                            } else if (res.data.code == "SYSTEM_ERROR") {
                                this.reload();
                                this.refundDialog = false;
                                this.$message.info(res.data.message);
                            }
                        }).catch(() => {
                            this.reload();
                        });
                    } else {
                        return false;
                    }
                });
            },
            //长租车删除
            handleDelete(data) {
                this.$confirm('是否确认删除车位信息?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    longCarRateDel(data.id).then((res) => {
                        this.reload();
                        if (res.data.code == "SUCCESS") {
                            this.$message({
                                type: "success",
                                message: res.data.message,
                            });
                        } else {
                            this.reload();
                            this.$message({
                                type: "info",
                                message: res.data.message,
                            });
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
            },
        }
    }
</script>
<style lang="scss" scoped>
    .user-search {
        margin-top: 20px;
    }

    .app-license-keyboard {
        font-size: 0;
    }

    .app-license-keyboard /deep/ .icon {
        font-size: .3rem;
    }

    .app-license-keyboard /deep/ .license-container /deep/ .license-buttons {
        padding: 0 2rem;
    }

    .app-license-keyboard /deep/ .license-container /deep/ .license-buttons span {
        margin: .15rem .125rem;
        padding: .15rem 0;
        border: .0325rem solid #3d51aa
    }

    .app-license-keyboard /deep/ .license-container /deep/ .license-bar {
        padding: 0.112rem 2.1rem;
        font-weight: 700;
        font-size: .5rem;
    }
</style>
