<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>车场管理</el-breadcrumb-item>
            <el-breadcrumb-item>车场设置</el-breadcrumb-item>
        </el-breadcrumb>
        <el-tabs v-model="activeName" class="user-search">
            <el-tab-pane label="基本设置" name="1">
                <basic-settings v-if="activeName=='1'" :id="id"></basic-settings>
            </el-tab-pane>
            <el-tab-pane label="全场参数设置" name="2">
                <full-field-parameters :id="id" v-if="activeName=='2'" @change="handleChange"></full-field-parameters>
            </el-tab-pane>
            <el-tab-pane label="长租费率设置" name="3">
                <long-term-rent-rate :id="id" v-if="activeName=='3'"></long-term-rent-rate>
            </el-tab-pane>
            <el-tab-pane label="临停费率设置" name="4">
                <temporary-stop-rate :id="id" v-if="activeName=='4'"></temporary-stop-rate>
            </el-tab-pane>
            <el-tab-pane label="会员车费率设置" name="5">
                <membership-car-rate :id="id" v-if="activeName=='5'"></membership-car-rate>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    import FullFieldParameters from "./carSetting/fullFieldParameters";
    import LongTermRentRate from "./carSetting/longTermRentRate";
    import TemporaryStopRate from "./carSetting/temporaryStopRate";
    import MembershipCarRate from "./carSetting/membershipCarRate";
    import BasicSettings from "./carSetting/basicSettings";

    export default {
        name: "parkingSettings",
        components: {BasicSettings, MembershipCarRate, TemporaryStopRate, LongTermRentRate, FullFieldParameters},
        data() {
            return {
                activeName: '1',
                parkingName: '',
                id: '',
            };
        },
        watch: {
            '$route': function (to, from) { // 路由改变时执行
                if (to.query.activeName) {
                    this.activeName = to.query.activeName;
                }
            }
        },
        created() {
            this.activeName = this.$route.query.activeName || '1';
        },
        methods: {
            handleChange(e) {
                this.activeName = e.toString();
            }
        }
    }
</script>

<style scoped>
    .user-search {
        margin-top: 20px;
    }

    .dialog-footer {
        display: flex;
        /*justify-content: flex-end;*/
    }

    .el-tabs--card {
        height: calc(100vh - 178px);
        /* overflow-y: auto; */
    }

    .el-tab-pane {
        height: calc(100vh - 178px);
        overflow-y: auto;
    }
</style>
