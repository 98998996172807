<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>系统管理</el-breadcrumb-item>
            <el-breadcrumb-item>角色管理</el-breadcrumb-item>
        </el-breadcrumb>
        <el-row style="margin: 20px 0;line-height: 30px;">
            <el-col :span="2">
                <el-button icon="el-icon-search" type="primary" size="small" @click="handleSearch">刷新</el-button>
            </el-col>
            <el-col :span="1">
                <el-button icon="el-icon-plus" type="primary" size="small" @click="handleAdd">添加</el-button>
            </el-col>
        </el-row>
        <el-table
                size="small"
                :data="tableData"
                highlight-current-row
                border stripe
                style="width: 100%;"
                :row-style="{height:'45px'}"
                :cell-style="{padding:'0px'}"
                :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                height="500" max-height="500">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column prop="roleName" label="名称" align="center"></el-table-column>
            <el-table-column prop="roleCode" label="编码" align="center"></el-table-column>
            <el-table-column prop="notes" label="说明" align="center"></el-table-column>
            <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
            <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                    <el-button size="mini" type="primary" icon="el-icon-setting"
                               @click="handleEdit(scope.$index, scope.row)">编辑
                    </el-button>
                    <el-button size="mini" type="danger" class="el-icon-delete"
                               @click="handleDelete(scope.$index, scope.row)">删除
                    </el-button>
                    <el-button size="mini" type="warning" @click="handleRolePower(scope.row)">授权
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="filters.current"
                       :page-sizes="[10, 50, 200, 1000]"
                       :page-size="filters.size"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
        <el-dialog v-draggable title="角色管理" :visible.sync="visibleDialog" width="30%">
            <el-form :model="visibleForm" ref="visibleForm" size="small" :rules="rules" label-width="130px">
                <el-row>
                    <el-col :span="22">
                        <el-form-item label="角色名称" prop="roleName">
                            <el-input size="small" v-model="visibleForm.roleName"
                                      placeholder="请输入内容"/>
                        </el-form-item>
                        <el-form-item label="角色编码" prop="roleCode">
                            <el-input size="small" v-model="visibleForm.roleCode"
                                      placeholder="请输入内容"/>
                        </el-form-item>
                        <el-form-item label="角色说明" prop="notes">
                            <el-input size="small" v-model="visibleForm.notes"
                                      placeholder="请输入内容"/>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="visibleDialog = false">取 消</el-button>
                <el-button type="primary" @click="onSubmit('visibleForm')">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog v-draggable :close-on-click-modal="false" :visible.sync="rolePowerDialog" width="40%"
                   title="菜单权限管理">
            <el-tree
                    :data="rolePowerOptions"
                    ref="rolePowerTree"
                    show-checkbox
                    node-key="id"
                    :default-checked-keys="checkMenu"
                    :props="defaultProps">
                    <span class="custom-tree-node" slot-scope="{ node, data }">
                        <span>{{ node.label }}</span>
                        <span v-if="data.path=== 'enterpriseManager' ||data.path=== 'parkingLotManager'
                        || data.path=== 'personnelManager' || data.path=== 'speedMainManager' ">一级菜单</span>
                        <span v-else-if="data.path=== '/enterprise' || data.path=== '/parkingLot' ||
                        data.path=== '/personnel' || data.path=== '/speedMain/device'
                        || data.path=== '/speedMain/home'|| data.path=== '/personnel/indexPage'
                        || data.path=== '/parkingLot/homePage'|| data.path=== '/monitorPreview'
                        || data.path=== '/parkingSmartScreen' || data.path=== '/speedMain/position'
                        || data.path=== '/speedMain/radarSettings' || data.path=== '/speedMain/record'
                        || data.path=== '/speedMain/smartScreen'|| data.path=== '/personLargeDataScreen'">二级菜单</span>
                        <span v-else>三级菜单</span>
                    </span>
            </el-tree>
            <span slot="footer" class="dialog-footer">
                <el-button @click="rolePowerDialog = false" size="mini">取 消</el-button>
                <el-button type="primary" @click="saveRolePower" size="mini">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import {roleList, roleSave, roleUpdate, roleDel, rolePowerList, rolePowerSave} from "@/api/login";

    export default {
        name: "roleManagement",
        data() {
            return {
                loading: false,
                tableData: [],
                total: 0,
                filters: {
                    current: 1,
                    size: 10,
                },
                visibleDialog: false,
                visibleForm: {
                    roleName: '',
                    roleCode: '',
                    notes: '',
                },
                rules: {
                    roleName: [
                        {required: true, message: "角色名称不能为空", trigger: "blur"},
                    ],
                    roleCode: [
                        {required: true, message: "角色编码不能为空", trigger: "blur"},
                    ],
                },

                defaultProps: {
                    children: "children",
                    label: "menuName",
                },
                rolePowerDialog: false,
                rolePowerOptions: [],
                checkMenu: [],
                selectRoleId: '',
            }
        },
        created() {
            this.handleSearch();
        },
        methods: {
            handleSearch() {
                this.loading = true;
                roleList(this.filters).then((res) => {
                    this.loading = false;
                    this.tableData = res.data.data.records;
                    this.total = res.data.data.total;
                    this.filters.current = res.data.data.current;
                    this.filters.size = res.data.data.size;
                }).catch(error => {
                    this.loading = false;
                });
            },
            handleSizeChange(val) {
                this.filters.size = val;
                this.filters.current = 1;
                this.fetchData();
            },
            handleCurrentChange(val) {
                this.filters.current = val;
                this.fetchData();
            },
            handleAdd() {
                Object.keys(this.visibleForm).map(key => {
                    this.visibleForm[key] = ''
                })
                this.visibleDialog = true;
            },
            handleEdit(index, row) {
                this.visibleDialog = true;
                Object.keys(this.visibleForm).map(key => {
                    this.visibleForm[key] = row[key];
                });
                this.visibleForm.id = row.id;
            },
            onSubmit(visibleForm) {
                this.$refs[visibleForm].validate((valid) => {
                    if (valid) {
                        this.loading = true;
                        if (this.visibleForm.id) {
                            roleUpdate(this.visibleForm).then((res) => {
                                this.handleSearch();
                                this.visibleDialog = false;
                                if (res.data.code == "SUCCESS") {
                                    this.$message.success("编辑成功！");
                                } else {
                                    this.$message.info(res.data.message);
                                }
                            });
                        } else {
                            roleSave(this.visibleForm).then((res) => {
                                this.handleSearch();
                                this.visibleDialog = false;
                                if (res.data.code == "SUCCESS") {
                                    this.$message.success("添加成功！");
                                } else {
                                    this.$message.info(res.data.message);
                                }
                            });
                        }
                    }
                });
            },
            handleDelete(index, row) {
                this.$confirm(
                    '是否确认删除角色[' + row.roleName + ']?',
                    "警告",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    }
                ).then(function () {
                    roleDel(row.id).then((res) => {
                        if (res.data.code == "SUCCESS") {
                            this.handleSearch();
                            this.$message.success("删除成功!");
                        } else {
                            this.$message.error(res.data.message);
                        }
                    })
                }).catch(() => {
                    this.$message.error("已取消删除");
                });
            },
            handleRolePower(row) {
                this.selectRoleId = row.id;
                this.getRolePowerList(row.id);
                this.rolePowerDialog = true;
            },
            getRolePowerList(roleId) {
                rolePowerList(roleId).then((res) => {
                    let data = [{
                        'path': "enterpriseManager",
                        'menuName': '通用业务菜单权限',
                        'children': res.data.data.menuList.enterprise
                    }, {
                        'path': "enterpriseManager",
                        'menuName': '开放平台菜单权限',
                        'children': res.data.data.menuList.openCommon
                    }, {
                        'path': "parkingLotManager",
                        'menuName': '停车场菜单权限',
                        'children': res.data.data.menuList.parkingLot
                    }, {
                        'path': "personnelManager",
                        'menuName': '人行菜单权限',
                        'children': res.data.data.menuList.personnel
                    }, {
                        'path': "speedMainManager",
                        'menuName': '雷达测速菜单权限',
                        'children': res.data.data.menuList.speed
                    }];
                    this.rolePowerOptions = data;
                    this.checkMenu = res.data.data.checkMenu;
                });
            },
            saveRolePower() {
                let checkMenuList = this.$refs['rolePowerTree'].getCheckedNodes();
                let checkMenuId = [];
                checkMenuList.forEach(element => {
                    if (element.id) {
                        checkMenuId.push(element.id);
                    }
                });
                let parentArr = this.$refs['rolePowerTree'].getHalfCheckedNodes();
                parentArr.forEach(element => {
                    if (element.id) {
                        checkMenuId.push(element.id);
                    }
                });
                let param = {
                    id: this.selectRoleId,
                    menuIds: checkMenuId
                }
                rolePowerSave(param).then((res) => {
                    this.rolePowerDialog = false;
                    if (res.data.code == "SUCCESS") {
                        this.$message.success("添加成功！");
                    } else {
                        this.$message.info(res.data.message);
                    }
                });
            }
        }
    }
</script>

<style scoped>
    .custom-tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding-right: 8px;
    }
</style>
