<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>增值服务</el-breadcrumb-item>
            <el-breadcrumb-item>续费管理</el-breadcrumb-item>
        </el-breadcrumb>
        <el-button icon="el-icon-refresh" type="primary" size="small" @click="handleSearch">刷新</el-button>
        <!--列表-->
        <el-table
                size="small"
                :data="listData"
                highlight-current-row
                v-loading="loading"
                element-loading-text="拼命加载中"
                border style="width: 100%"
                :row-style="{height:'45px'}"
                :cell-style="{padding:'0px'}"
                :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                stripe height="500" max-height="500">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column prop="funcModuleName" label="功能" align="center"></el-table-column>
            <el-table-column prop="status" label="状态" align="center"></el-table-column>
            <el-table-column prop="remain" label="倒计时" align="center"></el-table-column>
            <el-table-column prop="billingUnit" label="付费方式" align="center"></el-table-column>
            <el-table-column prop="startTime" label="开始时间" align="center"></el-table-column>
            <el-table-column prop="endTime" label="截止时间" align="center"></el-table-column>
            <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                    <el-button size="mini" @click="handleRenew(scope.row)"
                               type="primary">续费
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
    import {instanceList} from "../../../api/basicServices";

    export default {
        name: "renewalManagement",
        data() {
            return {
                loading: false,
                //数据
                listData: [],
            }
        },
        mounted() {
            this.getInstanceList();
        },
        methods: {
            getInstanceList() {
                this.loading = true;
                instanceList().then(res => {
                    this.loading = false;
                    this.listData = res.data.data;
                }).catch(() => {
                    this.loading = false;
                })
            },
            handleSearch() {
                this.getInstanceList();
            },
            handleRenew(row) {
                this.$router.push({
                    path: "/enterprise/addRenewal",
                    query: {billingObjectCode: row.funcModuleCode, endTime: row.endTime, billingUnit: row.billingUnit}
                });
            }
        }
    }
</script>

<style scoped>
    .el-button {
        margin: 15px 0;
    }

</style>
