<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>人行管理</el-breadcrumb-item>
            <el-breadcrumb-item>通行授权</el-breadcrumb-item>
        </el-breadcrumb>
        <el-container>
            <el-aside>
                <el-tag style="cursor: pointer;" :type="expired || notGranted ? 'info' : ''"
                        @click="handleGetTreeData('', '')">所有
                </el-tag>
                <el-tag style="cursor: pointer;" :type="expired ? '' : 'info'" @click="handleGetTreeData(true, '')">
                    已过期
                </el-tag>
                <el-tag style="cursor: pointer;" :type="notGranted ? '' : 'info'" @click="handleGetTreeData('', true)">
                    未授权
                </el-tag>
                <div style="margin: 10px 0">
                    <el-input size="mini" style="width: 90%;" prefix-icon="el-icon-search"
                              placeholder="请输入"
                              v-model="filterText">
                    </el-input>
                </div>
                <el-tree
                        :data="treeData"
                        :highlight-current=true
                        show-checkbox
                        ref="tree"
                        :props="defaultProps"
                        @node-click="handleNodeClick"
                        @check-change="handleCheckClick"
                        :filter-node-method="filterNode"
                        node-key="id">
                </el-tree>
            </el-aside>
            <el-main>
                <el-form :inline="true" :model="filters" class="user-search">
                    <!--                    <el-form-item>-->
                    <!--                        <el-input v-model="filters.name" size="small" placeholder="请输入区域名称"-->
                    <!--                                  @keyup.enter.native="handleSearch"/>-->
                    <!--                    </el-form-item>-->
                    <el-form-item>
                        <!--                        <el-button icon="el-icon-search" type="primary" size="small" @click="handleSearch">查询-->
                        <!--                        </el-button>-->
                        <el-button icon="el-icon-plus" type="primary" size="small" @click="handleAuthorization">授权
                        </el-button>
                        <el-button type="primary" size="small" @click="handleRevoke">撤销授权
                        </el-button>
                    </el-form-item>
                </el-form>
                <el-table
                        v-loading="loading"
                        element-loading-text="拼命加载中"
                        ref="tableData"
                        @selection-change="handleSelectionChange"
                        size="small"
                        :data="tableData"
                        highlight-current-row
                        border stripe
                        style="width: 100%;"
                        :row-style="{height:'45px'}"
                        :cell-style="{padding:'0px'}"
                        :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                        height="500" max-height="500">
                    <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column type="index" label="序号"></el-table-column>
                    <el-table-column prop="doorwayName" label="通道名称" align="center"></el-table-column>
                    <el-table-column prop="direction" label="通道出入类型" align="center">
                        <template slot-scope="scope">
                            <el-tag v-show="scope.row.direction != null" :type="scope.row.direction == 1 ? '' : 'info'">
                                {{scope.row.direction == 1 ? '入口' : scope.row.direction == 2 ? '出口' : ''}}
                            </el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="areaName" label="区域名称" align="center"></el-table-column>
                    <el-table-column prop="direction" label="授权状态" align="center">
                        <template slot-scope="scope">
                            <el-tag v-show="scope.row.status != null"
                                    :type="scope.row.status == 1 ? 'success' : 'info'">
                                {{scope.row.status == 1 ? '授权成功' : scope.row.status == 0 ? '待授权' : ''}}
                            </el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="syncRemark" label="授权状态说明" align="center">
                        <template slot-scope="scope">
                            <div>{{scope.row.syncRemark}}
                                <span v-if="scope.row.syncCode == 12"
                                      style="color: #f93310;text-decoration: underline;cursor: pointer;font-weight: bold;"
                                      @click="handleRevokeDevice(scope.row.syncRemark)">去重</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="startTime" label="开始时间" align="center"></el-table-column>
                    <el-table-column prop="endTime" label="结束时间" align="center"></el-table-column>
                </el-table>
                <el-pagination background
                               @size-change="handleSizeChange"
                               @current-change="handleCurrentChange"
                               :current-page="filters.current"
                               :page-sizes="[10, 50, 200, 1000]"
                               :page-size="filters.size"
                               layout="total, sizes, prev, pager, next, jumper"
                               :total="total">
                </el-pagination>
            </el-main>
        </el-container>

        <el-dialog v-draggable title="添加授权" :visible.sync="visibleDialog">
            <el-container>
                <el-aside>
                    <el-tree
                            :data="childrenTreeData"
                            default-expand-all
                            ref="childrenTree"
                            node-key="id"
                            show-checkbox
                            :props="defaultChildrenProps"
                            @check-change="handleAddCheckClick">
                    </el-tree>
                </el-aside>
                <el-main>
                    <div class="block">
                        <span class="demonstration">有效期：</span>
                        <el-date-picker
                                size="small"
                                v-model="date"
                                type="datetimerange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                value-format="yyyy-MM-dd HH:mm:ss">
                        </el-date-picker>
                        <el-date-picker style="position: fixed;bottom: -9999px;"
                                        v-model="date"
                                        type="datetimerange"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        :default-time="['12:00:00']">
                        </el-date-picker>
                    </div>
                    <el-table
                            size="small"
                            :data="tableData"
                            highlight-current-row
                            border stripe
                            style="width: 100%;"
                            :row-style="{height:'45px'}"
                            :cell-style="{padding:'0px'}"
                            :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                            height="310" max-height="310">
                        <el-table-column type="index" label="序号"></el-table-column>
                        <el-table-column prop="doorwayName" label="通道名称" align="center"></el-table-column>
                        <el-table-column prop="direction" label="通道出入类型" align="center">
                            <template slot-scope="scope">
                                <el-tag v-show="scope.row.direction != null"
                                        :type="scope.row.direction == 1 ? '' : 'info'">
                                    {{scope.row.direction == 1 ? '入口' : scope.row.direction == 2 ? '出口' : ''}}
                                </el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="areaName" label="区域名称" align="center"></el-table-column>
                    </el-table>
                </el-main>
            </el-container>
            <span slot="footer" class="dialog-footer">
                <el-button @click="visibleDialog = false">取 消</el-button>
                <el-button type="primary" @click="onSubmit('visibleForm')">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import {getTreeData} from "../../../api/spaceApi";
    import {handleTreeData} from "../../../api/organizationApi";
    import {AddAuth, getUserAuthList, revoke, revokeDevice} from "../../../api/authorizationApi";
    import {formatDate} from "../../../utils";

    export default {
        name: "accessAuthorization",
        data() {
            return {
                type: "first",
                defaultProps: {
                    children: 'subList',
                    label: 'name',
                },
                treeData: [],
                loading: false,
                total: 0,
                tableData: [],
                filters: {
                    name: '',
                    current: 1,
                    size: 10,
                    userId: '',
                },
                multipleSelection: [],
                visibleDialog: false,
                visibleData: [],
                childrenTreeData: [],
                defaultChildrenProps: {
                    children: 'subList',
                    label: 'name',
                },
                date: [],
                userIds: [],
                doorwayIds: [],
                startTime: '',
                endTime: '',
                expired: '',
                notGranted: '',
                filterText: '',
                deviceStatus: [],
                flag: true,
                subList: [],
            };
        },
        watch: {
            filterText(val) {
                this.$refs.tree.filter(val);
            }
        },
        mounted() {
            this.handleGetTreeData();
        },
        methods: {
            filterNode(value, data) {
                if (!value) return true;
                return data.name.indexOf(value) !== -1;
            },
            handleGetTreeData(expired, notGranted) {
                let param = {
                    isUser: true,
                    expired: expired,
                    notGranted: notGranted
                }
                this.expired = expired;
                this.notGranted = notGranted;
                const loading = this.$loading({
                    lock: true,
                    text: '加载中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                handleTreeData(param).then((res) => {
                    loading.close();
                    this.treeData = res.data.data;
                    this.subList = [];
                    this.findSubList(res.data.data)
                }).catch(error => {
                    console.log(error);
                });
            },
            findSubList(data) {
                for (const item of data) {
                    if (!item.subList) {
                        if (item.type == 3) {
                            this.subList.push(item);
                            // console.log(this.subList);
                            // return this.subList;
                        }
                    } else {
                        const res = this.findSubList(item.subList); //（一直不满足if，一直else，直到满足了if）
                        if (res) {  //res的值是 一直递归循环 直到到最后满足if找到的item，赋值给res。
                            return res;
                        }
                    }
                }
                return null;
            },
            handleNodeClick(data) {
                if (data.type == 3) {
                    this.filters.userIds = data.id;
                    this.filters.current = 1;
                    this.fetchData();
                } else {
                    this.tableData = [];
                }
            },
            handleCheckClick(data) {
                let userInfo = this.$refs.tree.getCheckedNodes(true);
                this.userIds = [];
                userInfo.forEach(element => {
                    if (element.type === 3) {
                        this.userIds.push(element.id);
                    }
                });
                if (this.subList.length == this.userIds.length) {
                    this.$confirm('是否重新授权所有人员？如果是需要较长时间，请耐心等待。', '温馨提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {

                    }).catch(() => {
                    });
                }
            },
            fetchData() {
                if (!this.filters.userIds) {
                    this.$message.warning("请选择人员！");
                    return;
                }
                let params = {
                    name: this.filters.name,
                    size: this.filters.size,
                    current: this.filters.current,
                    userId: this.filters.userIds
                };
                this.loading = true;
                getUserAuthList(params).then((res) => {
                    this.loading = false;
                    this.tableData = res.data.data.records;
                    this.total = res.data.data.total;
                    this.filters.current = res.data.data.current;
                    this.filters.size = res.data.data.size;
                }).catch(error => {
                    console.log(error);
                });
            },
            handleSearch() {
                this.filters.current = 1;
                this.fetchData();
            },
            handleSizeChange(val) {
                this.filters.size = val;
                this.filters.current = 1;
                this.fetchData();
            },
            handleCurrentChange(val) {
                this.filters.current = val;
                this.fetchData();
            },
            handleAuthorization() {
                this.flag = true;
                this.handleTreeData();
            },
            handleAddCheckClick(data) {
                let doorwayInfo = this.$refs.childrenTree.getCheckedNodes(true);
                this.doorwayIds = [];
                doorwayInfo.forEach(element => {
                    if (element.type === 2) {
                        this.doorwayIds.push(element.id);
                        this.deviceStatus.push(element.deviceStatus);
                    }
                });
            },
            handleTreeData() {
                getTreeData().then((res) => {
                    this.childrenTreeData = res.data.data;
                    if (this.findItem(this.childrenTreeData) == 2) {
                        this.$confirm('有设备不在线，确定需要授权吗？', '温馨提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            this.visibleDialog = true;
                            this.date = [];
                            let arr = [];
                            this.tableData.forEach(item => {
                                arr.push(item.doorwayId);
                            });
                            this.$nextTick(() => {
                                this.doorwayIds = arr;
                                this.$refs.childrenTree.setCheckedKeys(arr);
                            });
                        }).catch(() => {
                            this.$message.info('已取消授权');
                        });
                        return;
                    } else {
                        this.visibleDialog = true;
                        this.date = [];
                        let arr = [];
                        this.tableData.forEach(item => {
                            arr.push(item.doorwayId);
                        });
                        this.$nextTick(() => {
                            this.doorwayIds = arr;
                            this.$refs.childrenTree.setCheckedKeys(arr);
                        });
                    }
                }).catch(error => {
                    console.log(error);
                });
            },
            findItem(data) {
                if (this.flag) {
                    for (const item of data) {
                        if (!item.subList) {
                            if (item.deviceStatus == 2) {
                                this.flag = false;
                                return item.deviceStatus;
                            }
                        } else {
                            const res = this.findItem(item.subList); //（一直不满足if，一直else，直到满足了if）
                            if (res) {  //res的值是 一直递归循环 直到到最后满足if找到的item，赋值给res。
                                return res;
                            }
                        }
                    }
                }
                return null;
            },
            onSubmit() {
                this.startTime = this.date && this.date.length ? this.date[0] : "";
                this.endTime = this.date && this.date.length ? this.date[1] : "";
                let param = {
                    startTime: this.startTime,
                    endTime: this.endTime,
                    doorwayIds: this.doorwayIds,
                    userIds: this.userIds
                }
                if (this.doorwayIds.length <= 0) {
                    this.$message.warning("请选择通道！");
                    return;
                }
                if (this.userIds.length <= 0) {
                    this.$message.warning("请选择人员！");
                    return;
                }
                const loading = this.$loading({
                    lock: true,
                    text: '拼命加载中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                AddAuth(param).then((res) => {
                    this.visibleDialog = false;
                    if (res.data.code == "SUCCESS") {
                        loading.close();
                        this.$refs.tree.setCheckedKeys([]);
                        this.$refs.childrenTree.setCheckedKeys([]);
                        this.$message.success("授权已执行！");
                    } else {
                        loading.close();
                        this.$message.info(res.data.message);
                    }
                }).catch(error => {
                    loading.close();
                    console.log(error);
                });
            },
            handleSelectionChange(selection) {
                this.multipleSelection = [];
                selection.forEach(element => {
                    this.multipleSelection.push(element.doorwayId);
                });
            },
            handleRevoke() {
                let param = {
                    doorwayIds: this.multipleSelection,
                    userIds: this.userIds
                }
                if (this.multipleSelection.length <= 0) {
                    this.$message.warning("请选择通道！");
                    return;
                }
                if (this.userIds.length <= 0) {
                    this.$message.warning("请选择人员！");
                    return;
                }
                this.$confirm('是否需要撤销授权？', '温馨提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    const loading = this.$loading({
                        lock: true,
                        text: '拼命加载中',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    revoke(param).then((res) => {
                        if (res.data.code == "SUCCESS") {
                            loading.close();
                            this.fetchData();
                            this.$refs.tree.setCheckedKeys([]);
                            this.$refs.tableData.clearSelection();
                            this.$message.success("撤销授权已执行！");
                        } else {
                            loading.close();
                            this.$message.info(res.data.message);
                        }
                    }).catch(error => {
                        loading.close();
                        console.log(error);
                    });
                }).catch(() => {
                    this.$message.info('已取消');
                });
            },
            handleRevokeDevice(value) {
                var arr = [];
                arr.push(Number(value.split("：")[1]));
                let param = {
                    doorwayIds: this.multipleSelection,
                    userIds: arr
                }
                if (this.multipleSelection.length <= 0) {
                    this.$message.warning("请选择通道！");
                    return;
                }
                this.$confirm('是否去除重复人员？', '温馨提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    const loading = this.$loading({
                        lock: true,
                        text: '拼命加载中',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    revokeDevice(param).then((res) => {
                        if (res.data.code == "SUCCESS") {
                            loading.close();
                            this.fetchData();
                            this.$refs.tree.setCheckedKeys([]);
                            this.$refs.tableData.clearSelection();
                            this.$message.success(res.data.message);
                        } else {
                            loading.close();
                            this.$message.info(res.data.message);
                        }
                    }).catch(error => {
                        loading.close();
                        console.log(error);
                    });
                }).catch(() => {
                    this.$message.info('已取消');
                });
            }
        }
    }
</script>

<style scoped>
    .el-aside {
        background-color: #ffffff !important;
        width: auto !important;
        margin-top: 20px;
    }

    .el-tree {
        max-height: 600px;
        overflow: auto;
    }

    /deep/ .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
        background-color: #f0f7ff;
        color: #66bbff;
    }

    .block {
        margin: 10px 0;
    }
</style>
