<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>增值服务</el-breadcrumb-item>
            <el-breadcrumb-item>续费记录</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 搜索筛选 -->
        <el-form :inline="true" size="small" :model="filters" class="user-search">
            <el-form-item>
                <el-select v-model="filters.billingObjCode" clearable placeholder="请选择功能名称">
                    <el-option v-for="item in funList" :key="item.id" :label="item.billingObjectName"
                               :value="item.billingObjectCode"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-search" type="primary" size="small" @click="handleSearch">查询</el-button>
            </el-form-item>
        </el-form>
        <el-table
                size="small"
                :data="listData"
                highlight-current-row
                v-loading="loading"
                element-loading-text="拼命加载中"
                border style="width: 100%"
                :row-style="{height:'45px'}"
                :cell-style="{padding:'0px'}"
                :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                stripe height="500" max-height="500">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column prop="funcModuleName" label="功能" align="center"></el-table-column>
            <el-table-column prop="amount" label="付费金额(元)" align="center"></el-table-column>
            <el-table-column prop="billingUnit" label="计费周期" align="center"></el-table-column>
            <el-table-column prop="buyCount" label="购买数量" align="center"></el-table-column>
            <el-table-column prop="payTime" label="付费时间" align="center"></el-table-column>
            <el-table-column prop="endTime" label="截止时间" align="center"></el-table-column>
        </el-table>
        <el-pagination background
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="filters.current"
                       :page-sizes="[10, 50, 200, 1000]"
                       :page-size="filters.size"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
    </div>
</template>

<script>
    import {adjustableList, renewalRecordList} from "../../../api/basicServices";

    export default {
        name: "renewalRecord",
        data() {
            return {
                loading: false,
                //用户数据
                listData: [],
                total: 0,
                filters: {
                    current: 1,
                    size: 10,
                    billingObjCode: ""
                },
                funList: []
            }
        },
        mounted() {
            this.getRenewalRecordList();
            this.getAdjustableList();
        },
        methods: {
            getRenewalRecordList() {
                this.loading = true;
                renewalRecordList(this.filters).then(res => {
                    this.loading = false;
                    this.listData = res.data.data.records;
                    this.total = res.data.data.total;
                    this.filters.current = res.data.data.current;
                    this.filters.size = res.data.data.size;
                }).catch(error => {
                    this.loading = false;
                });
            },
            getAdjustableList() {
                adjustableList().then(res => {
                    this.funList = res.data.data;
                })
            },
            handleSearch() {
                this.filters.current = 1;
                this.getRenewalRecordList();
            },
            handleSizeChange(val) {
                this.filters.size = val;
                this.filters.current = 1;
                this.getRenewalRecordList();
            },
            handleCurrentChange(val) {
                this.filters.current = val;
                this.getRenewalRecordList();
            },
        }
    }
</script>

<style scoped>
    .user-search {
        margin-top: 15px;
    }

</style>
