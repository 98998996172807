<template>
    <div>
        <el-button size="mini" icon="el-icon-plus" type="primary" @click="addDevice">添加设备</el-button>
        <el-table
                size="small"
                :data="tableData"
                highlight-current-row
                border style="width: 100%"
                :row-style="{height:'45px'}"
                :cell-style="{padding:'0px'}"
                :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                stripe height="500" max-height="500">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column prop="equipmentName" label="设备名称" align="center"></el-table-column>
            <el-table-column prop="equipmentType" label="设备类型" align="center">
                <template slot-scope="scope">
                    <span v-if="scope.row.equipmentType == 1">车牌相机</span>
                    <span v-else-if="scope.row.equipmentType == 3">车道监控相机</span>
                    <span v-else-if="scope.row.equipmentType == 5">门禁控制器</span>
                </template>
            </el-table-column>
            <el-table-column prop="equipmentSerialNo" label="设备标识" align="center"></el-table-column>
            <el-table-column prop="status" label="设备状态" align="center">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.status == 1">在线</el-tag>
                    <el-tag type="info" v-else>离线</el-tag>
                </template>
            </el-table-column>
            <el-table-column width="340" align="center" label="操作">
                <template slot-scope="scope">
                    <el-button @click="handleSetting(scope.$index, scope.row)" size="mini" type="warning"
                               v-if="scope.row.equipmentType === 1"
                    >远端设置
                    </el-button>
                    <el-button @click="handlePreview(scope.row)" size="mini" type="success"
                               v-if="scope.row.equipmentType === 1"
                    >相机IP
                    </el-button>
                    <el-button size="mini" type="primary" v-if="scope.row.equipmentType === 1"
                               @click="handleLED(scope.$index, scope.row)"
                    >屏显
                    </el-button>
                    <el-button size="mini" type="danger" v-if="scope.row.equipmentType === 1"
                               @click="handleEquipmentSnap()"
                    >拍照截图
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <!--   添加设备-->
        <el-dialog v-draggable :title="title" :visible.sync="addDialog" width="35%">
            <el-form :model="editForm" ref="editForm" size="small" label-width="150px" :rules="rules">
                <el-row>
                    <el-col :span="20">
                        <el-form-item label="设备类型" prop="equipmentType">
                            <el-select style="width: 100%" @change="changeEquipmentType" size="small"
                                       v-model="editForm.equipmentType"
                                       placeholder="请选择类型">
                                <el-option label="车牌相机" :value="1"></el-option>
                                <el-option label="车道监控相机" :value="3"></el-option>
                                <el-option label="门禁控制器" :value="5"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="设备名称" prop="equipmentName">
                            <el-select style="width: 100%" @change="equipmentChange" size="small"
                                       v-model="editForm.equipmentName"
                                       placeholder="请选择名称">
                                <el-option v-for="item in equipmentData" :label="item.equipmentName" :key="item.id"
                                           :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="设备标识">
                            <el-input size="small" v-model="editForm.equipmentSerialNo" readonly
                                      placeholder="请输入内容"/>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addDialog = false">取 消</el-button>
                <el-button type="primary" @click="confirm('editForm')">确 定</el-button>
            </span>
        </el-dialog>
        <!--   LED屏显设置-->
        <el-dialog v-draggable :title="title" :visible.sync="ledDialog">
            <el-form :model="displayForm" ref="displayForm" size="small" label-width="95px">
                <el-form-item label="余位设置" prop="remainingPlaceSet" :label-width="formLabelWidth">
                    <el-radio-group v-model="displayForm.remainingPlaceSet">
                        <el-radio :label="1">显示所有车位</el-radio>
                        <el-radio :label="2">显示本区域车位</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="屏幕类型" prop="displayType" :label-width="formLabelWidth">
                    <el-radio-group @change="changeRadio" v-model="displayForm.displayType">
                        <el-radio :label="1">LED屏幕</el-radio>
                        <el-radio :label="2">LCD屏幕</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item v-if="displayForm.displayType == 1" label="LED主板类型" :label-width="formLabelWidth">
                    <el-select @change="changeLedCardType" size="small" v-model="displayForm.ledCardType"
                               placeholder="请选择主板类型">
                        <el-option v-for="item in ledCardType" :label="item.code == 1? 'A款/万能语音/横向/多行': item.code == 2? 'B款/万能语音/横向/单行':
                        item.code == 3 ? 'C款/固定语音/竖向/多列' : item.code == 4 ? 'D款/固定语音/横向/多行' : item.code == 5 ? 'TIS款' : ''"
                                   :key="item.id"
                                   :value="item.code"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="音量设置" :label-width="formLabelWidth">
                    <div style="display: flex">
                        <el-input style="width: 35%;" min="0" max="10" oninput="value=value.replace(/[^\d]/g,'')"
                                  size="small"
                                  v-model="displayForm.volume" placeholder="请输入"/>
                        <span style="margin-left: 5px;width: 120px;">取值范围0-10</span>
                    </div>
                </el-form-item>
                <el-form-item v-if="displayForm.displayType == 1" label="语音播报类型" prop="" :label-width="formLabelWidth">
                    <span v-if="voiceType == 'powerful'">万能语音</span>
                    <span v-else-if="voiceType == 'rrc_fixed' || voiceType == 'rrc_h_fixed'">固定语音</span>
                </el-form-item>
                <el-form-item v-if="displayForm.displayType == 1" label="显示方向" prop="" :label-width="formLabelWidth">
                    <span v-if="direction == 'crosswise'">横向</span>
                    <span v-else-if="direction == 'vertical'">竖向</span>
                </el-form-item>
                <el-form-item v-if="displayForm.displayType == 2" label="LCD控制器名称" :label-width="formLabelWidth">
                    <el-col :span="10">
                        <el-select @change="changeLcdEquipment" size="small" v-model="lcdEquipment" placeholder="请选择类型">
                            <el-option v-for="item in LcdData" :label="item.equipmentName" :key="item.id"
                                       :value="item.id"></el-option>
                        </el-select>
                    </el-col>
                    <el-col class="line" :span="2"></el-col>
                    <el-col :span="10">
                        <el-input readonly size="small" v-model="displayForm.value" placeholder="请输入内容"/>
                    </el-col>
                </el-form-item>
                <el-form-item v-if="displayForm.displayType == 1" label="极性" prop="polarity"
                              :label-width="formLabelWidth">
                    <el-radio-group v-model="displayForm.polarity">
                        <el-radio :label="1">恒流模组</el-radio>
                        <el-radio :label="2">恒压模组</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item v-if="displayForm.displayType == 1 && displayForm.ledCardType != 2" label="屏幕行/列数"
                              prop="value" :label-width="formLabelWidth">
                    <el-select @change="change" size="small" v-model="value" placeholder="请选择屏幕行/列数">
                        <el-option label="2" value="1"></el-option>
                        <el-option label="4" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <div style="margin-left: 82px;margin-bottom: 10px;font-weight: bold">闲时内容</div>
                <el-row v-for="(domain, index) in displayForm.displayConfigLine" :key="index">
                    <el-form-item v-if="domain.line !== 0" :label-width="formLabelWidth" prop="index"
                                  :label="'第' + domain.line + (displayForm.ledCardType != 3?'行':'列')">
                        <el-row>
                            <el-col :span="6">
                                <el-select size="small" v-model="domain.lineType" placeholder="请选择类型">
                                    <el-option label="固定内容" :value="1"></el-option>
                                    <el-option :disabled="displayForm.displayType == 2 ? false : displayForm.ledCardType == 3 ? true : (displayForm.ledCardType == 1 && displayForm.ledCardType == 5 &&
                                     domain.line != 1) ? true : false" label="时间" :value="2"></el-option>
                                    <el-option
                                            :disabled="displayForm.displayType == 1 && displayForm.ledCardType == 4 ? true : displayForm.displayType == 2 ? false : displayForm.ledCardType != 3 ? false : false"
                                            label="车场余位" :value="3"></el-option>
                                </el-select>
                            </el-col>
                            <el-col class="line" :span="1"></el-col>
                            <el-col :span="8">
                                <el-input :disabled="domain.lineType == 2 || domain.lineType == 3" size="small"
                                          v-model="domain.lineText" placeholder="请输入内容"/>
                            </el-col>
                            <el-col class="line" :span="1"></el-col>
                            <el-col :span="6">
                                <el-select v-if="displayForm.displayType == 1" size="small" v-model="domain.lineColor"
                                           placeholder="请选择字体颜色">
                                    <el-option label="红色" :value="1"></el-option>
                                    <el-option label="绿色" :value="2"></el-option>
                                    <el-option label="黄色" :value="3"></el-option>
                                </el-select>
                            </el-col>
                        </el-row>
                    </el-form-item>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button @click="ledDialog = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit('displayForm')">确 定</el-button>
      </span>
        </el-dialog>
    </div>
</template>

<script>
    import {
        displaySave,
        equipmentList,
        equipmentLCD,
        ledCardType,
        settingRemote,
        carList, equipmentSave, equipmentUpdate, parkingEquipment, volume
    } from "../../../../../api/yardSetting";

    const synth = window.speechSynthesis;
    const msg = new SpeechSynthesisUtterance();
    export default {
        name: "third",
        props: {
            roadId: [Number, String],
        },
        watch: {
            'roadId'(newVal, oldVal) {
                this.roadId = newVal;
                this.getEquipmentList();
            }
        },
        data() {
            return {
                tableData: [],
                equipmentData: [],
                addDialog: false,
                editForm: {
                    equipmentId: "",
                    equipmentMark: "",
                    equipmentName: "",
                    equipmentType: "",
                    equipmentSerialNo: "",
                },
                rules: {
                    equipmentType: [
                        {required: true, message: '请选择', trigger: 'change'}
                    ],
                    equipmentName: [
                        {required: true, message: '请选择', trigger: 'change'}
                    ],
                },
                title: "",
                ledDialog: false,
                formLabelWidth: '140px',
                value: "",
                lcdEquipment: "",
                displayForm: {
                    id: "",
                    polarity: "",
                    remainingPlaceSet: "",
                    displayType: "",
                    equipmentId: "",
                    lcdEquipmentId: "",
                    ledCardType: "",
                    volume: "",
                    displayConfigLine: [
                        {
                            line: "",
                            lineText: "",
                            lineType: "",
                            lineColor: "",
                        },
                    ],
                    value: "",
                },
                show: false,
                showLCD: false,
                showLED: false,
                showPolarity: true,
                disabled: false,
                showLine: true,
                LcdData: [],
                ledCardType: [],
                direction: "",
                voiceType: "",
            }
        },
        mounted() {
            this.id = localStorage.getItem("subSysId")
            this.getEquipmentList();
        },
        methods: {
            //列表数据
            getEquipmentList() {
                equipmentList(this.roadId).then((res) => {
                    this.tableData = res.data.data
                })
            },
            //获取LCD信息
            getEquipmentLCD() {
                equipmentLCD(this.id).then((res) => {
                    this.LcdData = res.data.data;
                })
            },
            //设备管理编辑添加
            addDevice() {
                this.title = '设备管理';
                this.addDialog = true;
                Object.keys(this.editForm).map(key => {
                    this.editForm[key] = '';
                })
            },
            //点击获取设备类型信息
            changeEquipmentType(type) {
                carList(this.id).then((res) => {
                    this.equipmentData = [];
                    this.editForm.equipmentId = "";
                    this.editForm.equipmentName = "";
                    this.editForm.equipmentSerialNo = "";
                    res.data.data.forEach((e) => {
                        if (type === e.equipmentType) {
                            this.equipmentData.push(e)
                        }
                    })
                })
            },
            //点击获取设备信息
            equipmentChange(id) {
                this.equipmentData.forEach((element) => {
                    if (element.id == id) {
                        this.editForm.equipmentId = element.id;
                        this.editForm.equipmentName = element.equipmentName;
                        this.editForm.equipmentSerialNo = element.equipmentSerialNo;
                        return;
                    }
                })
            },
            //提交设备信息
            confirm(editForm) {
                this.$refs[editForm].validate((valid) => {
                    if (valid) {
                        let param = {
                            roadId: this.roadId,
                            equipmentId: this.editForm.equipmentId,
                        }
                        equipmentSave(param).then((res) => {
                            this.addDialog = false;
                            this.getEquipmentList();
                            if (res.data.code == "SUCCESS") {
                                this.$message({
                                    type: "success",
                                    message: "保存成功！",
                                });
                            } else {
                                this.$message({
                                    type: "info",
                                    message: res.data.message,
                                });
                            }
                        }).catch((err) => {
                            this.addDialog = false;
                            this.$message.error("编辑保存失败，请稍后再试！");
                        });
                    }
                })
            },
            //LED主板类型
            getLedCardType() {
                ledCardType().then(res => {
                    this.ledCardType = res.data.data;
                    this.ledCardType.forEach(el => {
                        if (this.displayForm.ledCardType == el.code) {
                            this.direction = el.direction;
                            this.voiceType = el.voiceType;
                        }
                    })
                })
            },
            changeLedCardType(e) {
                this.ledCardType.forEach(el => {
                    if (e == el.code) {
                        this.direction = el.direction;
                        this.voiceType = el.voiceType;
                    }
                })
                if (e === 3) {
                    this.displayForm.displayConfigLine.splice(2, 3);
                    this.displayForm.displayConfigLine.forEach(el => {
                        el.lineType = 1;
                    })
                    if (this.displayForm.displayConfigLine.length < 2) {
                        this.displayForm.displayConfigLine.push(
                            {
                                line: 2,
                                lineText: "",
                                lineType: "",
                                lineColor: 1,
                            }
                        )
                    }
                } else if (e === 2) {
                    this.value = 1;
                    this.displayForm.displayConfigLine.splice(1, 5);
                } else {
                    this.value = 2;
                    if (this.displayForm.displayConfigLine.length <= 1) {
                        this.displayForm.displayConfigLine.push(
                            {
                                line: 2,
                                lineText: "",
                                lineType: "",
                                lineColor: 1,
                            }
                        )
                    }
                }
            },
            changeLcdEquipment(e) {
                this.LcdData.forEach(el => {
                    if (e == el.id) {
                        this.displayForm.value = el.equipmentSerialNo;
                        this.displayForm.lcdEquipmentId = el.id;
                    }
                })
            },
            //点击显示屏幕行数
            change(e) {
                if (e == 1) {
                    this.displayForm.displayConfigLine.splice(2, 2)
                } else {
                    if (this.displayForm.displayConfigLine.length <= 2) {
                        this.displayForm.displayConfigLine.push(
                            {
                                line: 3,
                                lineText: "",
                                lineType: "",
                                lineColor: 1,
                            },
                            {
                                line: 4,
                                lineText: "",
                                lineType: "",
                                lineColor: 1,
                            }
                        )
                    }
                }
            },
            //屏显设置编辑
            handleLED(index, row) {
                this.title = '屏显设置';
                this.ledDialog = true;
                this.displayForm.id = row.id;
                this.displayForm.volume = "";
                if (row.displayConfig != null) {
                    this.getEquipmentList();
                    this.displayForm.lcdEquipmentId = row.displayConfig.lcdEquipmentId;
                    this.displayForm.displayConfigLine = row.displayConfig.displayConfigLine;
                    this.displayForm.displayType = row.displayConfig.displayType;
                    this.displayForm.polarity = row.displayConfig.polarity;
                    this.displayForm.remainingPlaceSet = row.displayConfig.remainingPlaceSet;
                    this.displayForm.ledCardType = row.displayConfig.ledCardType;
                    if (row.displayConfig.ledCardType === 3 && row.displayConfig.displayType == 1) {
                        this.displayForm.displayConfigLine.forEach(el => {
                            el.lineType = 1;
                        })
                        this.displayForm.displayConfigLine.splice(2, 10);
                    } else if (row.displayConfig.ledCardType === 2 && row.displayConfig.displayType == 1) {
                        this.showLine = false;
                        this.displayForm.displayConfigLine.splice(1, 4);
                    }
                    if (row.displayConfig.displayType != 2) {
                        this.displayForm.value = "";
                        this.lcdEquipment = "";
                        this.getLedCardType();
                    } else {
                        this.displayForm.value = row.displayConfig.lcdEquipment.equipmentSerialNo;
                        this.lcdEquipment = row.displayConfig.lcdEquipment.equipmentName;
                        this.direction = "";
                        this.voiceType = "";
                        this.getEquipmentLCD();
                    }
                } else {
                    this.displayForm.lcdEquipmentId = "";
                    this.displayForm.displayType = "";
                    this.displayForm.polarity = "";
                    this.displayForm.remainingPlaceSet = "";
                    this.displayForm.value = "";
                    this.lcdEquipment = "";
                    this.displayForm.displayConfigLine = [];
                    if (this.displayForm.displayConfigLine.length === 0) {
                        this.displayForm.displayConfigLine.push(
                            {
                                line: 1,
                                lineText: "",
                                lineType: "",
                                lineColor: 1,
                            },
                            {
                                line: 2,
                                lineText: "",
                                lineType: "",
                                lineColor: 1,
                            }
                        )
                    }
                }
                if (this.displayForm.displayConfigLine.length >= 4) {
                    this.value = 4;
                } else if (this.displayForm.displayConfigLine.length == 2) {
                    this.value = 2;
                }
            },
            //提交屏显数据
            onSubmit(displayForm) {
                this.$refs[displayForm].validate((valid) => {
                    if (valid) {
                        if (this.displayForm.displayType == 2) {
                            if (this.displayForm.lcdEquipmentId == 0) {
                                this.$message({
                                    message: '请选择LCD控制器名称',
                                    type: 'warning',
                                    duration: 3000
                                })
                                return;
                            }
                        }
                        if (this.displayForm.displayType != 2) {
                            this.displayForm.lcdEquipmentId = 0;
                        }
                        if (this.displayForm.ledCardType === 3) {
                            this.displayForm.displayConfigLine.push(
                                {
                                    line: 0,
                                    lineText: "",
                                    lineType: 3,
                                    lineColor: 1,
                                }
                            )
                        } else if (this.displayForm.ledCardType !== 3) {
                            this.displayForm.displayConfigLine.splice(4, 1)
                        }
                        let param;
                        if (this.displayForm.displayType !== 2) {
                            param = {
                                equipmentId: this.displayForm.id,
                                displayConfigLine: this.displayForm.displayConfigLine,
                                displayType: this.displayForm.displayType + '',
                                polarity: this.displayForm.polarity,
                                remainingPlaceSet: this.displayForm.remainingPlaceSet,
                                lcdEquipmentId: this.displayForm.lcdEquipmentId,
                                ledCardType: this.displayForm.ledCardType + ''
                            }
                        } else {
                            param = {
                                equipmentId: this.displayForm.id,
                                displayConfigLine: this.displayForm.displayConfigLine,
                                displayType: this.displayForm.displayType + '',
                                polarity: this.displayForm.polarity,
                                remainingPlaceSet: this.displayForm.remainingPlaceSet,
                                lcdEquipmentId: this.displayForm.lcdEquipmentId,
                            }
                        }
                        displaySave(param).then((res) => {
                            this.ledDialog = false;
                            this.getEquipmentList();
                            if (res.data.code == "SUCCESS") {
                                this.$message.success("保存成功！");
                            } else {
                                this.$message.info(res.data.message);
                            }
                        })
                        this.submitVolume();
                    }
                })
            },
            submitVolume(){
                if(this.displayForm.volume) {
                    let json = {
                        deviceId: this.displayForm.id,
                        volume: Number(this.displayForm.volume)
                    }
                    volume(json).then((res) => {
                        console.log(res.data.message)
                    })
                }
            },
            //点击显示LCD控制器
            changeRadio(e) {
                if (e === 1) {
                    this.displayForm.polarity = 1;
                    this.getLedCardType();
                    if (this.displayForm.ledCardType == 2) {
                        this.value = 1;
                        this.displayForm.displayConfigLine.splice(1, 5);
                    }
                } else if (e === 2) {
                    this.displayForm.polarity = "";
                    this.value = 2;
                    if (this.displayForm.displayConfigLine.length <= 1) {
                        this.displayForm.displayConfigLine.push(
                            {
                                line: 2,
                                lineText: "",
                                lineType: "",
                                lineColor: 1,
                            }
                        )
                    } else {
                        this.displayForm.displayConfigLine.splice(2, 2)
                    }
                    this.getEquipmentLCD();
                }
            },
            handleSetting(index, row) {
                let sn = row.equipmentSerialNo;
                let model = row.equipmentModel;
                settingRemote(sn, model).then(res => {
                    if (res.data.code == "SUCCESS") {
                        if (res.data.data) {
                            window.open(res.data.data, '_blank')
                        }
                    }
                })
            },
            handlePreview(row) {
                this.$prompt('请输入相机IP', '相机IP', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputValue: row.innerIp,
                    inputPlaceholder: "请输入",
                    inputValidator: (value) => {
                        // 点击按钮时，对文本框里面的值进行验证
                        if (!value) {
                            return 'IP地址不能为空';
                        }
                    },
                }).then(({value}) => {
                    let param = {
                        id: row.id,
                        projectId: localStorage.getItem('projectId'),
                        serialNo: row.equipmentSerialNo,
                        equipmentType: row.equipmentType.toString(),
                        innerIp: value
                    }
                    equipmentUpdate(param).then((res) => {
                        this.getEquipmentList();
                        if (res.data.code == "SUCCESS") {
                            this.$message.success("保存成功！");
                        } else {
                            this.$message.info(res.data.message);
                        }
                    }).catch((err) => {
                        this.addDialog = false;
                    });
                })
            },
            handleEquipmentSnap() {
                this.$confirm("是否确认拍照截图?", "温馨提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    parkingEquipment(this.roadId).then((res) => {
                        if (res.data.code == "SUCCESS") {
                            this.$message.success(res.data.message);
                        }
                    })
                })
            },
        }
    }
</script>

<style scoped>
    .el-table {
        margin-top: 10px;
    }

    .line {
        margin: 1px;
        width: 0.16667% !important;
    }
</style>
