<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>访客管理</el-breadcrumb-item>
            <el-breadcrumb-item>访客设置</el-breadcrumb-item>
        </el-breadcrumb>

        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="访客时间设置" name="first">
                <div style="margin-bottom: 20px;float: left;">
                    <el-button style="margin: auto" icon="el-icon-search" size="mini" type="primary"
                               @click="handleSearch"> 搜 索
                    </el-button>
                    <el-button size="mini" icon="el-icon-plus" type="primary" @click="handleAdd"> 添 加</el-button>
                </div>
                <el-table
                        v-loading="loading"
                        element-loading-text="拼命加载中"
                        size="small"
                        :data="tableData"
                        highlight-current-row
                        border stripe
                        style="width: 100%;"
                        :row-style="{height:'45px'}"
                        :cell-style="{padding:'0px'}"
                        :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                        height="500" max-height="500">
                    <el-table-column type="index" label="序号"></el-table-column>
                    <el-table-column prop="periodStart" label="起始时间" align="center"></el-table-column>
                    <el-table-column prop="periodEnd" label="截止时间" align="center"></el-table-column>
                    <el-table-column width="250" align="center" label="操作">
                        <template slot-scope="scope">
                            <el-button size="mini" type="danger" class="el-icon-delete"
                                       @click="handleDelete(scope.$index, scope.row)">删 除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background
                               @size-change="handleSizeChange"
                               @current-change="handleCurrentChange"
                               :current-page="filters.current"
                               :page-sizes="[10, 50, 200, 1000]"
                               :page-size="filters.size"
                               layout="total, sizes, prev, pager, next, jumper"
                               :total="total">
                </el-pagination>
                <el-dialog v-draggable  title="添加时间" :visible.sync="visibleDialog" width="30%">
                    <el-form :model="visibleForm" ref="visibleForm" size="small" :rules="rules">
                        <el-form-item label-width="30px">
                            <el-time-picker
                                    size="small"
                                    is-range
                                    v-model="date"
                                    range-separator="至"
                                    start-placeholder="开始时间"
                                    end-placeholder="结束时间"
                                    placeholder="选择时间范围"
                                    value-format="HH:mm:ss">
                            </el-time-picker>
                        </el-form-item>
                        <el-form-item label-width="80px">
                            <el-button @click="visibleDialog = false">取 消</el-button>
                            <el-button type="primary" @click="submitForm('visibleForm')">确 定</el-button>
                        </el-form-item>
                    </el-form>
                </el-dialog>
            </el-tab-pane>
            <el-tab-pane label="缺省通道设置" name="second">
                <div class="divInfo">
                    <div>门禁授权：</div>
                    <el-tree :data="treeData" :highlight-current=true ref="tree" node-key="id"
                             default-expand-all :props="defaultProps" show-checkbox
                             @check-change="handleCheckClick">
                    </el-tree>
                </div>
                <div class="div-button">
                    <el-button size="small" type="primary" @click="onSubmit()">保 存</el-button>
                </div>
            </el-tab-pane>
            <el-tab-pane label="是否需要审核" name="third">
                <el-checkbox @change="handleChange" v-model="checked">需要审核</el-checkbox>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    import {getTreeData} from "../../../api/spaceApi";
    import {
        delAvailable,
        editDefault,
        editProject,
        getAvailableList,
        saveAvailable
    } from "../../../api/visitorMachine";
    import {getCurrentProject} from "../../../api/login";

    export default {
        name: "visitorSettings",
        data() {
            return {
                activeName: 'first',
                tableData: [],
                filters: {
                    current: 1,
                    size: 10,
                },
                total: 0,
                loading: false,
                title: "",
                visibleForm: {},
                rules: {},
                visibleDialog: false,
                date: null,
                defaultProps: {
                    children: 'subList',
                    label: 'name'
                },
                treeData: [],
                visitorDoorwayIds: [],
                checked: "",
                projectId: "",
                projectType: [],
            };
        },
        mounted() {
            this.fetchData();
        },
        methods: {
            handleClick(tab) {
                if (tab.name == 'first') {
                    this.fetchData();
                } else if (tab.name == 'second') {
                    this.getCurrentProjectList();
                    this.handleTreeData();
                } else {
                    this.getCurrentProjectList();
                }
            },
            fetchData() {
                let params = {
                    size: this.filters.size,
                    current: this.filters.current,
                };
                this.loading = true;
                getAvailableList(params).then((res) => {
                    this.loading = false;
                    this.tableData = res.data.data.records;
                    this.total = res.data.data.total;
                    this.filters.current = res.data.data.current;
                    this.filters.size = res.data.data.size;
                })
            },
            handleSearch() {
                this.filters.current = 1;
                this.fetchData();
            },
            handleSizeChange(val) {
                this.filters.size = val;
                this.filters.current = 1;
                this.fetchData();
            },
            handleCurrentChange(val) {
                this.filters.current = val;
                this.fetchData();
            },
            handleAdd() {
                this.visibleDialog = true;
                this.date = null;
            },
            submitForm(visibleForm) {
                let param = {
                    periodStart: this.date && this.date.length ? this.date[0] : "",
                    periodEnd: this.date && this.date.length ? this.date[1] : ""
                }
                saveAvailable(param).then((res) => {
                    this.visibleDialog = false;
                    this.fetchData();
                    if (res.data.code == "SUCCESS") {
                        this.$message.success("编辑成功！");
                    } else {
                        this.$message.info(res.data.message);
                    }
                })
            },
            handleDelete(index, row) {
                this.$confirm("确定要删除访客时间吗?", "温馨提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    delAvailable(row.id).then((res) => {
                        if (res.data.code == "SUCCESS") {
                            this.filters.current = 1;
                            this.fetchData();
                            this.$message.success("删除成功！");
                        } else {
                            this.$message.info(res.data.message);
                        }
                    })
                }).catch(() => {
                    this.$message.info("已取消删除!");
                });
            },
            handleTreeData() {
                getTreeData().then((res) => {
                    this.treeData = res.data.data;
                }).catch(error => {
                    console.log(error);
                });
            },
            handleCheckClick(data) {
                let visitorDoorwayInfo = this.$refs.tree.getCheckedNodes(true);
                this.visitorDoorwayIds = [];
                visitorDoorwayInfo.forEach(element => {
                    if (element.type === 2) {
                        this.visitorDoorwayIds.push(element.id);
                    }
                });
            },
            getCurrentProjectList() {
                getCurrentProject().then((res) => {
                    this.checked = res.data.data.visitorNeedVerify;
                    this.projectId = res.data.data.id;
                    let visitorDoorwayIds = res.data.data.visitorDoorwayIds;
                    let arr = visitorDoorwayIds.split(',');
                    setTimeout(() => {
                        this.$nextTick(() => {
                            this.$refs.tree.setCheckedKeys(arr.map(Number));
                        });
                    }, 100)
                })
            },
            onSubmit() {
                let params = {
                    visitorDoorwayIds: this.visitorDoorwayIds.toString(),
                    id: localStorage.getItem("projectId")
                }
                editDefault(params).then((res) => {
                    if (res.data.code == "SUCCESS") {
                        this.$message.success(res.data.message);
                    } else {
                        this.$message.info(res.data.message);
                    }
                })
            },
            handleChange(value) {
                let title = "";
                if (value == true) {
                    title = "确定将访客申请设置为需要审核？";
                    this.checked = false;
                } else {
                    title = "确定将访客申请设置为免审核？";
                    this.checked = true;
                }
                this.$confirm(title, "温馨提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    let param = {
                        id: localStorage.getItem("projectId"),
                        visitorNeedVerify: value
                    }
                    editProject(param).then((res) => {
                        if (res.data.code == "SUCCESS") {
                            this.$message.success(res.data.message);
                        } else {
                            this.$message.info(res.data.message);
                        }
                    })
                }).catch(() => {
                    this.$message.info("已取消!");
                });
            },
        },
    }
</script>

<style scoped>
    .divInfo {
        display: flex;
        margin: 10px 0;
        font-size: 16px;
    }

    .divInfo div {
        margin-right: 20px;
    }

    .div-button {
        margin-left: 60px;
    }

    .el-button {
        margin: 0 40px;
    }
</style>
