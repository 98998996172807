<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>人行管理</el-breadcrumb-item>
            <el-breadcrumb-item>空间管理</el-breadcrumb-item>
        </el-breadcrumb>
        <el-container>
            <el-aside>
                <el-tree :data="tableData" :highlight-current=true
                         default-expand-all :props="defaultProps" @node-click="handleNodeClick">
                </el-tree>
            </el-aside>
            <el-main>
                <first v-if="type == 'first'" @handleTreeData="handleTreeData" :treeData="treeData"></first>
                <second v-if="type == 'second'" @handleTreeData="handleTreeData" :areaId="areaId"
                        :treeData="treeData"></second>
                <third v-if="type == 'third'" :treeData="treeData"></third>
            </el-main>
        </el-container>
    </div>
</template>

<script>
    import first from '../humanDecency/spaceLists/first'
    import second from '../humanDecency/spaceLists/second'
    import third from '../humanDecency/spaceLists/third'
    import {getTreeData} from "@/api/spaceApi";

    export default {
        components: {second, first, third},
        data() {
            return {
                type: "first",
                tableData: [],
                defaultProps: {
                    children: 'subList',
                    label: 'name'
                },
                areaId: "",
                treeData: [],
                childrenType: "",
            };
        },
        mounted() {
            this.handleTreeData("first");
        },
        methods: {
            handleTreeData(type, areaId) {
                getTreeData().then((res) => {
                    let data = {type: '0', 'name': localStorage.getItem('projectName'), 'subList': res.data.data};
                    this.tableData = [data];
                    this.type = type;
                    if (areaId != undefined) {
                        data.subList.forEach(item => {
                            if (areaId == item.id) {
                                this.treeData = item.subList;
                            }
                        })
                    } else {
                        this.treeData = data.subList;
                    }
                }).catch(error => {
                    console.log(error);
                });
            },
            handleNodeClick(data) {
                if (data.type == 0) {
                    this.type = "first";
                    this.treeData = data.subList;
                } else if (data.type == 1) {
                    this.type = "second";
                    this.areaId = data.id;
                    this.treeData = data.subList;
                } else if (data.type == 2) {
                    this.type = "third";
                    this.treeData = [data];
                }
            },
        }
    }
</script>

<style scoped>
    aside {
        background-color: #ffffff !important;
        width: auto !important;
        margin-top: 20px;
    }

    .el-tree {
        max-height: 600px;
        overflow: auto;
    }
</style>
